import { ActionInterface } from '../../../types';
import { ComplaintFilterList } from '../../../types/Complaint/FilterList';

const initialState: ComplaintFilterList = {
    central_complaint_no: '',
    department_complaint_no: '',
    complaint_date: '',
    contact_id: '',
    topic_id: '',
    area_id: '',
    status: '',
    contact_option : [],
    topic_option : [],
    area_option :[],
    full_name_technician : '',
    full_name : '',
    status_option : [],
    complaint_detail:''
};

const complaintFilterList = (state = initialState, action: ActionInterface): ComplaintFilterList => {
    switch (action.type) {
        case 'setStateByNameAndValueComplaintFilterList':
            return action.name
                ? {
                      ...state,
                      [action.name]: action.value,
                  }
                : { ...state };
        case 'appendFormComplaintFilterList':
            return {
                ...state,
                ...action.value,
            };
        case 'resetFormByReducerNameComplaintFilterList':
            return {
                ...state,
                ...{
                    central_complaint_no: '',
                    department_complaint_no: '',
                    complaint_date: '',
                    contact_id: '',
                    topic_id: '',
                    area_id: '',
                    status: '',
                    contact_option : [],
                    topic_option : [],
                    area_option :[],
                    full_name_technician : '',
                    full_name : '',
                    status_option : [],
                    complaint_detail:''
                },
            };
        default:
            return state;
    }
};

export default complaintFilterList;
