import { HOST } from '../router';

let DASHBOARD = `${HOST}/dashboard`;
export const GET_DASHBOARD_YEARS = `${DASHBOARD}/get_years`;
export const GET_DASHBOARD_COUNT_STATUS = `${DASHBOARD}/count_status`;
export const GET_DASHBOARD_GRADE_RESULT = `${DASHBOARD}/grade_result`;
export const GET_DASHBOARD_GRADE_RESULT_TYPE = `${DASHBOARD}/grade_result_type`;
export const GET_DASHBOARD_COUNT_CONTACT = `${DASHBOARD}/count_contact`;
export const GET_DASHBOARD_GRAPH_SUMMARY= `${DASHBOARD}/graph_summary`;
export const GET_DASHBOARD_COUNT_STATUS_UNSUCCESS= `${DASHBOARD}/count_status_unsuccess`;
export const GET_DASHBOARD_CALENDAR_ON_SITE = `${DASHBOARD}/calendar_on_site`;
export const GET_DASHBOARD_CALENDAR_COMPLAINT = `${DASHBOARD}/calendar_working`;
export const GET_USERS_TECHNICIAN = `${DASHBOARD}/all_users_technician`;

export const GET_DASHBOARD_COUNT_STATUS_TECHNICIAN = `${DASHBOARD}/count_status_technician`;
export const GET_DASHBOARD_COUNT_STATUS_TRACKING_TECHNICIAN = `${DASHBOARD}/count_status_tracking_technician`;
export const GET_DASHBOARD_COUNT_STATUS_UNSUCCESS_TECHNICIAN = `${DASHBOARD}/count_status_unsuccess_technician`;
export const GET_DASHBOARD_GRADE_RESULT_TECHNICIAN = `${DASHBOARD}/grade_result_technician`;
export const GET_DASHBOARD_GRADE_RESULT_TYPE_TECHNICIAN = `${DASHBOARD}/grade_result_type_technician`;
export const GET_DASHBOARD_COUNT_CONTACT_TECHNICIAN = `${DASHBOARD}/count_contact_technician`;



