import React, { useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Checkbox,
    Container,
    Form,
    Grid,
    Icon,
    Input,
    InputOnChangeData,
    Segment,
} from 'semantic-ui-react'
import CustomSegment from '../../../../../components/CustomSegment'
import { useHistory, useLocation } from 'react-router-dom'

interface InterfaceNotification {
    id: number
    name: string
}

interface InterfaceMunicipalityNotification {
    id: number
    time: string[]
}

const ManagementNotificationScheduleCronjob: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [notifications, setNotification] = useState<InterfaceNotification[]>([])
    let [edit_mode, setEditMode] = useState<boolean>(false)
    let [municipality_notification, setMunicipalityNotification] = useState<InterfaceMunicipalityNotification[]>([])

    let [municipality_id, setMunicipalityID] = useState<number>(0)

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            setMunicipalityID(municipality_id)
        }
    }, [location])
    useEffect(() => {
        setNotification([
            { id: 17, name: 'ใกล้กำหนด' },
            { id: 18, name: 'เกินกำหนด' },
        ])
    }, [])

    const handleClickEdit = () => {
        setEditMode(true)
    }

    const handleChangeCheckBox = (notification: InterfaceNotification, index: number) => {
        if (municipality_notification.find((type_notification) => type_notification.id === notification.id)) {
            setMunicipalityNotification(
                municipality_notification.filter((type_notification) => type_notification.id !== notification.id)
            )
        } else {
            setMunicipalityNotification([...municipality_notification, { id: notification.id, time: [''] }])
        }
    }

    const handleChangeTime = (index: number, index_time: number, value: string) => {
        let municipality = municipality_notification
        municipality[index].time[index_time] = value
        setMunicipalityNotification([...municipality])
    }
    const handleRemoveTime = (index: number, index_time_remove: number) => {
        let municipality = municipality_notification
        municipality[index].time = municipality[index].time.filter((_, index_time) => index_time !== index_time_remove)
        setMunicipalityNotification([...municipality])
    }

    const handleClickAddTime = (notification_id: number, index: number) => {
        let notification = municipality_notification.find(
            (type_notification) => type_notification.id === notification_id
        )
        if (notification) {
            notification?.time.push('')
            let municipality = municipality_notification
            municipality[index] = notification
            setMunicipalityNotification([...municipality])
        }
    }

    const handleSubmit = () => {
        console.log('notifications', municipality_notification)
        let body = {
            municipality_id: municipality_id,
            notifications: municipality_notification,
        }
        console.log(body)
        setEditMode(false)
    }
    return (
        <div className='background-container'>
            <Container>
                <Grid>
                    <Grid.Row only='computer tablet'>
                        <Grid.Column style={{ zIndex: 1 }}>
                            <Breadcrumb>
                                <Breadcrumb.Section
                                    link
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/municipalities`, {
                                            municipality_id: municipality_id,
                                        })
                                    }}
                                >
                                    รายการองค์กร
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section
                                    link
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                            municipality_id: municipality_id,
                                        })
                                    }}
                                >
                                    กำหนดการใช้งานองค์กร
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section
                                    link
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/management/notification`, {
                                            municipality_id: municipality_id,
                                        })
                                    }}
                                >
                                    กำหนดการใช้งานองค์กร
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />

                                <Breadcrumb.Section active>จัดการการแจ้งเตือนตามเวลา</Breadcrumb.Section>
                            </Breadcrumb>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <h2>รายการการแจ้งเตือนตามเวลา</h2>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column computer='4'></Grid.Column>
                        <Grid.Column computer='8'>
                            <CustomSegment header='การแจ้งเตือนตามเวลาทั้งหมด' icon='file alternate'>
                                <div style={{ margin: 10, color: '#283655' }}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <h3>
                                                    เลือกการแจ้งเตือน
                                                    {!edit_mode && (
                                                        <Button
                                                            icon
                                                            color='orange'
                                                            floated='right'
                                                            onClick={handleClickEdit}
                                                            size='tiny'
                                                        >
                                                            <Icon name='edit outline' />
                                                        </Button>
                                                    )}
                                                </h3>
                                            </Grid.Column>
                                        </Grid.Row>
                                        {notifications.map((notification, index) => (
                                            <Grid.Row key={index}>
                                                <Grid.Column>
                                                    <Segment>
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column>
                                                                    <Checkbox
                                                                        readOnly={!edit_mode}
                                                                        label={notification.name}
                                                                        checked={municipality_notification.some(
                                                                            (type_notifacation) =>
                                                                                type_notifacation.id === notification.id
                                                                        )}
                                                                        onChange={() =>
                                                                            handleChangeCheckBox(notification, index)
                                                                        }
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            {municipality_notification.map(
                                                                (type_notifacation) =>
                                                                    type_notifacation.id === notification.id &&
                                                                    type_notifacation.time.map((time, index_time) => (
                                                                        <Grid.Row key={index_time}>
                                                                            <Grid.Column computer='1'></Grid.Column>
                                                                            <Grid.Column computer='11'>
                                                                                <Form.Field>
                                                                                    <Input
                                                                                        readOnly={!edit_mode}
                                                                                        fluid
                                                                                        type='time'
                                                                                        value={time}
                                                                                        onChange={(
                                                                                            _: any,
                                                                                            { value }: InputOnChangeData
                                                                                        ) =>
                                                                                            handleChangeTime(
                                                                                                index,
                                                                                                index_time,
                                                                                                value
                                                                                            )
                                                                                        }
                                                                                    ></Input>
                                                                                </Form.Field>
                                                                            </Grid.Column>
                                                                            <Grid.Column computer='3'>
                                                                                {type_notifacation.time.length > 1 &&
                                                                                    edit_mode && (
                                                                                        <Button
                                                                                            icon
                                                                                            color='red'
                                                                                            fluid
                                                                                            onClick={() =>
                                                                                                handleRemoveTime(
                                                                                                    index,
                                                                                                    index_time
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <Icon name='trash alternate' />
                                                                                        </Button>
                                                                                    )}
                                                                            </Grid.Column>
                                                                            <Grid.Column computer='1'></Grid.Column>
                                                                        </Grid.Row>
                                                                    ))
                                                            )}

                                                            {edit_mode &&
                                                                municipality_notification.some(
                                                                    (type_notifacation) =>
                                                                        type_notifacation.id === notification.id
                                                                ) && (
                                                                    <Grid.Row>
                                                                        <Grid.Column computer='12'></Grid.Column>
                                                                        <Grid.Column computer='3'>
                                                                            <Button
                                                                                icon
                                                                                primary
                                                                                floated='right'
                                                                                fluid
                                                                                onClick={() =>
                                                                                    handleClickAddTime(
                                                                                        notification.id,
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Icon name='plus' />
                                                                            </Button>
                                                                        </Grid.Column>
                                                                        <Grid.Column computer='1'></Grid.Column>
                                                                    </Grid.Row>
                                                                )}
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        ))}

                                        {edit_mode && (
                                            <Grid.Row>
                                                <Grid.Column textAlign='center'>
                                                    <Button primary onClick={handleSubmit}>
                                                        บันทึก
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                    </Grid>
                                </div>
                            </CustomSegment>
                        </Grid.Column>
                        <Grid.Column computer='4'></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    )
}

export default ManagementNotificationScheduleCronjob
