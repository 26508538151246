// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging, isSupported } from 'firebase/messaging'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyC3dsx48FI3Zhif3VnyyIeRw2xGKKwXHvs',
    authDomain: 'prompt-chuay-23f17.firebaseapp.com',
    projectId: 'prompt-chuay-23f17',
    storageBucket: 'prompt-chuay-23f17.appspot.com',
    messagingSenderId: '287692169330',
    appId: '1:287692169330:web:3eb3f42ce3f91de09722eb',
    measurementId: 'G-HNRDFWH3VB',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const messaging = async () => ((await isSupported().then((response) => response)) ? getMessaging(app) : null)
export const VALID_KEY = `BAkor5f7n3q8-QAEZp17n1RA5X25KyO11ATKDGVHn_sbSjA7HoUcF-Ne8q78vj0zBOL9hYvwTHF42JYqJM-7Cqo`
