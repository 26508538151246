import React, { useEffect, useState } from 'react'
import {
    Button,
    Container,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Message,
    Segment,
} from 'semantic-ui-react'
import IconOnePlatform from '../../../../../assets/images/logo one platform.png'
import { useHistory, useLocation } from 'react-router-dom'
import '../styles.css'
import axios from 'axios'
import {
    APPLICATION_GET_OTP_RESET_PASSWORD,
    APPLICATION_GET_RESET_PASSWORD_FROM_EMAIL,
} from '../../../../../config/api/application'

const LineRegisterForgetPasswordRecoveryMethods: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [username, setUsername] = useState<string>('')
    let [method_phone_number, setMethodPhoneNumber] = useState<boolean>(false)
    let [method_email, setMethodEmail] = useState<boolean>(false)
    let [phone_number, setPhoneNumber] = useState<string>('')
    let [email, setEmail] = useState<string>('')
    let [phone_number_error, setPhoneNumberError] = useState<boolean>(false)
    let [email_error, setEmailError] = useState<boolean>(false)
    let [error, setError] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(false)
    let [error_text, setErrorText] = useState<string>('')

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { username } = location_state
            setUsername(username)
        }
    }, [location])

    const handleSelectPhoneNumber = () => {
        setMethodPhoneNumber(true)
        setMethodEmail(false)
        setError(false)
        setEmail('')
        setEmailError(false)
        setErrorText('')
    }

    const handleSelectEmail = () => {
        setMethodPhoneNumber(false)
        setMethodEmail(true)
        setError(false)
        setPhoneNumber('')
        setPhoneNumberError(false)
        setErrorText('')
    }

    const handleChangePhoneNumber = (_: any, { value }: InputOnChangeData) => {
        setPhoneNumber(value)
        setPhoneNumberError(false)
        setErrorText('')
    }
    const handleChangeEmail = (_: any, { value }: InputOnChangeData) => {
        setEmail(value)
        setEmailError(false)
        setErrorText('')
    }
    const handleClickGoBack = () => {
        history.goBack()
    }

    const handleClickGoNext = () => {
        if (method_phone_number || method_email) {
            if (method_phone_number && phone_number) {
                setLoading(true)
                axios
                    .post(APPLICATION_GET_OTP_RESET_PASSWORD, { username: username, phone_number: phone_number })
                    .then((response) => {
                        setLoading(false)
                        history.push('verify-otp', { username: username, phone_number: phone_number })
                    })
                    .catch((error) => {
                        setErrorText('ชื่อผู้ใช้งานและหมายเลขโทรศัพท์ไม่ถูกต้อง')
                        setLoading(false)
                        setPhoneNumberError(true)
                    })
            } else {
                setPhoneNumberError(true)
            }
            if (method_email && email) {
                setLoading(true)
                axios
                    .post(APPLICATION_GET_RESET_PASSWORD_FROM_EMAIL, { username: username, email: email })
                    .then((response) => {
                        setLoading(false)
                        history.push('complete')
                    })
                    .catch((error) => {
                        setErrorText('ชื่อผู้ใช้งานและอีเมลไม่ถูกต้อง')
                        setLoading(false)
                        setEmailError(true)
                    })
            } else {
                setEmailError(true)
            }
        } else {
            setError(true)
        }
    }

    return (
        <div className='background-container-no-sidebar'>
            <Container>
                <Segment className='segment-login-page'>
                    <div>
                        <div className='header-segment-login-page'>
                            <div className='image-one-platform'>
                                <Image src={IconOnePlatform} />
                            </div>
                        </div>
                        <div className='body-segment-login-page'>
                            <Form loading={loading}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column textAlign='center'>
                                            <h3>ลืมรหัสผ่าน</h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column textAlign='center'>
                                            <h4>เลือกวิธีการกู้คืนรหัสผ่าน</h4>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ alignSelf: 'center' }}>
                                        <Grid.Column computer='8' textAlign='center'>
                                            <Segment
                                                className={
                                                    method_phone_number
                                                        ? 'segment-selected'
                                                        : error
                                                        ? 'segment-error'
                                                        : 'segment-unselected'
                                                }
                                                onClick={handleSelectPhoneNumber}
                                            >
                                                <div style={{ padding: 10 }}>
                                                    <Icon name='mobile alternate' size='huge' />
                                                </div>
                                                <div>
                                                    ผ่านโทรศัพท์มือถือ <br />
                                                    (SMS)
                                                </div>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column computer='8' textAlign='center'>
                                            <Segment
                                                className={
                                                    method_email
                                                        ? 'segment-selected'
                                                        : error
                                                        ? 'segment-error'
                                                        : 'segment-unselected'
                                                }
                                                onClick={handleSelectEmail}
                                            >
                                                <div style={{ padding: 10 }}>
                                                    <Icon name='mail outline' size='huge' />
                                                </div>
                                                <div>
                                                    ผ่านอีเมล <br />
                                                    (Email)
                                                </div>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {method_phone_number && (
                                        <Grid.Row>
                                            <Grid.Column>
                                                <h5>หมายเลขโทรศัพท์</h5>
                                                <Form.Field error={phone_number_error}>
                                                    <Input
                                                        fluid
                                                        type='tel'
                                                        value={phone_number}
                                                        onChange={handleChangePhoneNumber}
                                                        maxLength={10}
                                                    />
                                                </Form.Field>
                                                {error_text && <Message negative>{error_text}</Message>}
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    {method_email && (
                                        <Grid.Row>
                                            <Grid.Column>
                                                <h5>อีเมล</h5>
                                                <Form.Field error={email_error}>
                                                    <Input
                                                        fluid
                                                        type='email'
                                                        value={email}
                                                        onChange={handleChangeEmail}
                                                    />
                                                </Form.Field>
                                                {error_text && <Message negative>{error_text}</Message>}
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </Form>
                        </div>
                        <div className='footer-segment-login-page'>
                            <Button disabled={loading} floated='left' compact icon primary onClick={handleClickGoBack}>
                                <Icon name='chevron left' />
                            </Button>
                            <Button
                                loading={loading}
                                disabled={loading}
                                floated='right'
                                compact
                                icon
                                primary
                                onClick={handleClickGoNext}
                            >
                                <Icon name='chevron right' />
                            </Button>
                        </div>
                    </div>
                </Segment>
            </Container>
        </div>
    )
}
export default LineRegisterForgetPasswordRecoveryMethods
