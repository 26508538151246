import { ActionInterface } from '../../../../types';
import { ManagementUsersFormUserState } from '../../../../types/Management/Users/Form/User';

const initialState: ManagementUsersFormUserState = {
    username: '',
    email: '',
    phone_number: undefined,
    username_error: false,
    email_error: false,
    phone_number_error: false,
};

const managementUsersFormUser = (state = initialState, action: ActionInterface): ManagementUsersFormUserState => {
    switch (action.type) {
        case 'setStateByNameAndValueManagementUsersFormUser':
            return action.name
                ? {
                      ...state,
                      [action.name]: action.value,
                  }
                : { ...state };
        case 'appendFormManagementUsersFormUser':
            return {
                ...state,
                ...action.value,
            };
        case 'resetFormByReducerNameManagementUsersFormUser':
            return {
                ...state,
                ...{
                    username: '',
                    email: '',
                    phone_number: undefined,
                    username_error: false,
                    email_error: false,
                    phone_number_error: false,
                },
            };
        default:
            return state;
    }
};

export default managementUsersFormUser;
