import React, { Fragment } from 'react'
import { useHistory } from 'react-router'
import { Icon, Image, Menu } from 'semantic-ui-react'
import Logo from '../../assets/images/main page/logo ogs.png'

const MenuListSuperAdmin: React.FC = () => {
    let history = useHistory()
    return (
        <Fragment>
            <Menu.Item>
                <Menu.Header style={{ margin: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Image src={Logo} size='mini' style={{ marginTop: 5 }} />
                        <label style={{ flexGrow: 1, cursor: 'pointer' }}> {localStorage.permission_th}</label>
                    </div>
                </Menu.Header>
            </Menu.Item>
            <Menu.Item>
                <Menu.Header>จัดการองค์กร</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name='municipalities'
                        onClick={() => {
                            history.push(`/${localStorage.permission_en}/municipalities`)
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name='cogs' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> รายการองค์กร</label>
                        </div>
                    </Menu.Item>
                </Menu.Menu>
            </Menu.Item>

            {/* <Menu.Item>
                <Menu.Header>การเข้าถึงเว็บไซต์</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name='consumer'
                        onClick={() => {
                            history.push(`/${localStorage.permission_en}/management/menus`)
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name='list' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> เมนู</label>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        name='consumer'
                        onClick={() => {
                            history.push(`/${localStorage.permission_en}/management/pages`)
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name='list' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> หน้าเว็บไซต์</label>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        name='consumer'
                        onClick={() => {
                            history.push(`/${localStorage.permission_en}/management/actions`)
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name='list' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> การใช้งาน</label>
                        </div>
                    </Menu.Item>
                </Menu.Menu>
            </Menu.Item> */}
            {/* <Menu.Item>
                <Menu.Header>จัดการคู่มือการใช้งาน</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name='consumer'
                        onClick={() => {
                            history.push(`/${localStorage.permission_en}/management/user-manual/topic`)
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name='info circle' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> หัวข้อคู่มือการใช้งาน</label>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        name='book'
                        onClick={() => {
                            history.push(`/${localStorage.permission_en}/management/user-manual/sub-topic`)
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name='book' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> หัวข้อย่อยคู่มือการใช้งาน</label>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        name='book'
                        onClick={() => {
                            history.push(`/${localStorage.permission_en}/management/user-manual/content`)
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name='book' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> เนื้อหาคู่มือการใช้งาน</label>
                        </div>
                    </Menu.Item>
                </Menu.Menu>
            </Menu.Item> */}

            {/* <Menu.Item>
                <Menu.Header>แจ้งปัญหา</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name='consumer'
                        onClick={() => {
                            history.push(`/${localStorage.permission_en}/defect`)
                        }}>
                        <div style={{ display: 'flex' }}>
                            <Icon name='question circle' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> จัดการ Defect</label>
                        </div>
                    </Menu.Item>
                </Menu.Menu>
            </Menu.Item> */}
            {/* <Menu.Item>
                <Menu.Header>Super Admin</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name='consumer'
                        onClick={() => {
                            history.push(`/${localStorage.permission_en}/management/municipality`)
                        }}>
                        <div style={{ display: 'flex' }}>
                            <Icon name='user secret' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> จัดการ Super Admin</label>
                        </div>
                    </Menu.Item>
                </Menu.Menu>
            </Menu.Item> */}
        </Fragment>
    )
}

export default MenuListSuperAdmin
