import { HOST } from '../router';

const ROLE = `${HOST}/role`;
export const GET_ROLES_LIST = `${ROLE}/list`;
export const POST_ROLES_CREATE = `${ROLE}/create`;
export const POST_ROLES_EDIT = `${ROLE}/edit`;
export const DELETE_ROLES = `${ROLE}/delete`;
export const GET_ROLE_BY_MUNICIPALITY = `${ROLE}/all`;
export const POST_CREATE_AND_EDIT_ROLE_HAS_PERMISSION_MENU = `${ROLE}/create_and_edit_roles_has_permission_menus`;
export const GET_ROLE_HAS_PERMISSION_MENU_DETAIL = `${ROLE}/roles_has_permission_menus_detail`;
export const GET_ROLE_HAS_PERMISSION_MENU_LIST = `${ROLE}/roles_has_permission_menus_list`;
export const DELETE_ROLE_HAS_PERMISSION_MENU = `${ROLE}/delete_roles_has_permission_menus`;
