import React, { Fragment, useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router'
import { Breadcrumb, Button, Container, Grid, Icon, Image, Segment } from 'semantic-ui-react'
import { alert } from '../../../../../components/alert'
import CustomSegment from '../../../../../components/CustomSegment'
import {
    GET_COMPLAINT_DETAIL_RESULT,
    POST_COMPLAINT_APPROVE_STATUS,
    POST_COMPLAINT_REJECT_STATUS,
} from '../../../../../config/api/complaint'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { HOST } from '../../../../../config/router'
import { getAuthenMenuList } from '../../../../../store/selectors'

interface InterfaceDetailResult {
    id: number
    color: string
    pdf_filses: InterfaceFileDetail[]
    photo_filses: InterfaceFileDetail[]
    processing_detail: string
    processing_end_date: string
    processing_fiscal_year: string
    processing_start_date: string
    processing_time: string
    status_id: number
    status_name: string
}

interface InterfaceFileDetail {
    complaints_id: number
    name: string
    path_file: string
    sequence: number
    type: string
}

const ComplaintListDetailSaveResult: React.FC = () => {
    const { menu_list } = useSelector(getAuthenMenuList)
    let complaint_action = menu_list
        ?.find((menu) => menu.name_th === 'รายการคำร้องทุกข์')
        ?.pages?.find((page) => page.name_th === 'รายละเอียดผลดำเนินการ')?.actions
    let location = useLocation()
    let history = useHistory()
    let [detail_result, setDetailResult] = useState<InterfaceDetailResult | undefined>(undefined)

    let { state } = location
    let location_state = JSON.parse(JSON.stringify(state, null, ' '))
    let { complaint_id } = location_state

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { complaint_id } = location_state
            const getDetailResult = () => {
                axiosAuthen.get(`${GET_COMPLAINT_DETAIL_RESULT}/${complaint_id}`).then((response) => {
                    let { value } = response.data
                    setDetailResult(value)
                })
            }
            getDetailResult()
        }
    }, [location])

    const handleApproveSubmit = () => {
        axiosAuthen
            .post(POST_COMPLAINT_APPROVE_STATUS, {
                complaint_id: complaint_id,
            })
            .then(() => {
                alert({
                    type: 'success',
                    title: 'บันทึกเสร็จสิ้น',
                    timer: 1500,
                    onClosed: () => {
                        history.goBack()
                    },
                })
            })
    }

    const handleDisapproveSubmit = () => {
        axiosAuthen
            .post(POST_COMPLAINT_REJECT_STATUS, {
                complaint_id: complaint_id,
                detail: '',
            })
            .then(() => {
                alert({
                    type: 'success',
                    title: 'บันทึกเสร็จสิ้น',
                    timer: 1500,
                    onClosed: () => {
                        history.goBack()
                    },
                })
            })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/complaint`)
                                        }}
                                    >
                                        รายการคำร้อง
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/complaint/detail`, {
                                                complaint_id: complaint_id,
                                            })
                                        }}
                                    >
                                        รายละเอียดคำร้อง
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>ผลการดำเนินการ</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 style={{ textAlign: 'center' }}>ผลการดำเนินการ</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <CustomSegment icon='file alternate' header='ข้อมูลการดำเนินการ'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='3'></Grid.Column>
                                            <Grid.Column computer='3' textAlign='right' style={{ alignSelf: 'center' }}>
                                                <h5>ผลการดำเนินการ</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='7'>
                                                <div style={{ display: 'flex' }}>
                                                    <Segment>
                                                        <Icon name='circle' style={{ color: detail_result?.color }} />{' '}
                                                        {detail_result?.status_name}
                                                    </Segment>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column computer='3'></Grid.Column>
                                        </Grid.Row>
                                        {detail_result?.status_id === 6 && (
                                            <Grid.Row>
                                                <Grid.Column computer='3'></Grid.Column>
                                                <Grid.Column
                                                    computer='3'
                                                    textAlign='right'
                                                    style={{ alignSelf: 'center' }}
                                                >
                                                    <h5>ระบุปีงบประมาณ</h5>
                                                </Grid.Column>
                                                <Grid.Column computer='7'>
                                                    {detail_result?.processing_fiscal_year}
                                                </Grid.Column>
                                                <Grid.Column computer='3'></Grid.Column>
                                            </Grid.Row>
                                        )}
                                        {detail_result?.status_id === 5 && (
                                            <Fragment>
                                                <Grid.Row>
                                                    <Grid.Column computer='2'></Grid.Column>
                                                    <Grid.Column
                                                        computer='4'
                                                        textAlign='right'
                                                        style={{ alignSelf: 'center' }}
                                                    >
                                                        <h5>ระยะเวลาดำเนินการให้เสร็จ</h5>
                                                    </Grid.Column>
                                                    <Grid.Column computer='7'>
                                                        {detail_result?.processing_time}
                                                    </Grid.Column>
                                                    <Grid.Column computer='3'></Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='3'></Grid.Column>
                                                    <Grid.Column
                                                        computer='3'
                                                        textAlign='right'
                                                        style={{ alignSelf: 'center' }}
                                                    >
                                                        <h5>วันที่เริ่มดำเนินการอีกครั้ง</h5>
                                                    </Grid.Column>
                                                    <Grid.Column computer='7'>
                                                        {detail_result?.processing_start_date} -{' '}
                                                        {detail_result?.processing_end_date}
                                                    </Grid.Column>
                                                    <Grid.Column computer='3'></Grid.Column>
                                                </Grid.Row>
                                            </Fragment>
                                        )}
                                        <Grid.Row>
                                            <Grid.Column computer='3'></Grid.Column>
                                            <Grid.Column computer='3' textAlign='right' style={{ alignSelf: 'center' }}>
                                                <h5>รายละเอียด</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='7'>{detail_result?.processing_detail}</Grid.Column>
                                            <Grid.Column computer='3'></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3'></Grid.Column>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>รูปภาพ</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='7'>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {detail_result?.photo_filses.length === 0 && '-'}
                                                    {detail_result?.photo_filses.map(
                                                        (file: InterfaceFileDetail, index: number) => (
                                                            <a
                                                                key={index}
                                                                href={`${HOST}${file.path_file}`}
                                                                rel='noreferrer'
                                                                target={'_blank'}
                                                            >
                                                                <Image
                                                                    bordered
                                                                    size='small'
                                                                    src={`${HOST}${file.path_file}`}
                                                                    style={{
                                                                        margin: 10,
                                                                        padding: 5,
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            </a>
                                                        )
                                                    )}
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column computer='3'></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3'></Grid.Column>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>เอกสาร</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='7'>
                                                {detail_result?.pdf_filses.length === 0 && '-'}
                                                {detail_result?.pdf_filses.map(
                                                    (file: InterfaceFileDetail, index: number) => (
                                                        <div key={index} style={{ margin: 5 }}>
                                                            <a
                                                                href={`${HOST}${file.path_file}`}
                                                                target={'_blank'}
                                                                rel='noreferrer'
                                                            >
                                                                <Icon
                                                                    name='file alternate'
                                                                    size='large'
                                                                    style={{ color: '#D85F53' }}
                                                                />
                                                                <label
                                                                    style={{
                                                                        color: '#283655',
                                                                        textDecoration: 'underline',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    {file.name}
                                                                </label>
                                                            </a>
                                                        </div>
                                                    )
                                                )}
                                            </Grid.Column>
                                            <Grid.Column computer='3'></Grid.Column>
                                        </Grid.Row>
                                        {detail_result?.status_id === 16 &&
                                            complaint_action?.find((action) => action.name_en === 'approve result') && (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Button
                                                                floated='right'
                                                                style={{ backgroundColor: '#293757', color: 'white' }}
                                                                onClick={handleApproveSubmit}
                                                            >
                                                                อนุมัติ
                                                            </Button>
                                                            <Button
                                                                floated='right'
                                                                style={{ backgroundColor: '#9F3A38', color: 'white' }}
                                                                onClick={handleDisapproveSubmit}
                                                            >
                                                                ไม่อนุมัติ
                                                            </Button>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}
export default ComplaintListDetailSaveResult
