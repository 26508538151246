import React, { Fragment, useState } from 'react'
import { Button, Container, Form, Grid, Input, InputOnChangeData, Message } from 'semantic-ui-react'
import CustomModal from '../../../components/CustomModal'
import { axiosAuthen } from '../../../config/axiosAuthen'
import { PUT_USER_CHANGE_PASSWORD } from '../../../config/api/user'
import { alert } from '../../../components/alert'

type FormProps = {
    open?: boolean
    onClose?: () => void
}

const UserDetailChangePassword: React.FC<FormProps> = (props) => {
    let [old_password, setOldPassword] = useState<string>('')
    let [old_password_error, setOldPasswordError] = useState<boolean>(false)
    let [new_password, setNewPassword] = useState<string>('')
    let [new_password_error, setNewPasswordError] = useState<boolean>(false)
    let [confirm_password, setConfirmPassword] = useState<string>('')
    let [confirm_password_error, setConfirmPasswordError] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(false)
    let [error_text, setErrorText] = useState<string>('')

    const handleChangeOldPassword = (_: any, { value }: InputOnChangeData) => {
        setOldPassword(value)
        setOldPasswordError(false)
    }
    const handleChangeNewPassword = (_: any, { value }: InputOnChangeData) => {
        setNewPassword(value)
        setNewPasswordError(false)
    }
    const handleChangeConfirmPassword = (_: any, { value }: InputOnChangeData) => {
        setConfirmPassword(value)
        setConfirmPasswordError(false)
    }

    const handleSubmit = () => {
        if (old_password && confirm_password === new_password) {
            setOldPasswordError(false)
            setNewPasswordError(false)
            setConfirmPasswordError(false)
            setLoading(true)
            let body = {
                old_password: old_password,
                new_password: new_password,
            }
            axiosAuthen
                .put(PUT_USER_CHANGE_PASSWORD, body)
                .then((response) => {
                    setLoading(false)
                    alert({
                        title: 'บันทึกเสร็จสิ้น',
                        timer: 1500,
                        onClosed: () => {
                            handleClose()
                        },
                        type: 'success',
                    })
                })
                .catch((error) => {
                    if (error.response) {
                        setErrorText('รหัสผ่านไม่ถูกต้อง')
                        setLoading(false)
                        setOldPassword('')
                        setNewPassword('')
                        setConfirmPassword('')
                        setOldPasswordError(true)
                    }
                })
        } else {
            if (!old_password) {
                setOldPassword('')
                setOldPasswordError(true)
            }
            if (!new_password) {
                setNewPasswordError(true)
            }
            if (!confirm_password) {
                setConfirmPasswordError(true)
            }
            if (confirm_password !== new_password) {
                setErrorText('รหัสผ่านใหม่ไม่ตรงกัน')
                setNewPassword('')
                setConfirmPassword('')
                setNewPasswordError(true)
                setConfirmPasswordError(true)
            }
        }
    }

    const handleClose = () => {
        props.onClose && props.onClose()
    }

    return (
        <Fragment>
            <CustomModal
                icon='key'
                header='เปลี่ยนรหัสผ่าน'
                open={props.open}
                closeIcon
                onClose={handleClose}
                closeOnEscape={true}
                closeOnDimmerClick={true}>
                <Container>
                    <Form loading={loading}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column computer='3' only='computer' />
                                <Grid.Column computer='10' tablet='16' mobile='16'>
                                    <label style={{ fontWeight: 'bold', color: '#283655' }}>รหัสผ่านปัจจุบัน</label>
                                    <Form.Field error={old_password_error}>
                                        <Input
                                            name='old_password'
                                            type='password'
                                            value={old_password}
                                            onChange={handleChangeOldPassword}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer='3' only='computer' />
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column computer='3' only='computer' />
                                <Grid.Column computer='10' tablet='16' mobile='16'>
                                    <label style={{ fontWeight: 'bold', color: '#283655' }}>รหัสผ่านใหม่</label>
                                    <Form.Field error={new_password_error}>
                                        <Input
                                            name='new_password'
                                            type='password'
                                            value={new_password}
                                            onChange={handleChangeNewPassword}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer='3' only='computer' />
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column computer='3' only='computer' />
                                <Grid.Column computer='10' tablet='16' mobile='16'>
                                    <label style={{ fontWeight: 'bold', color: '#283655' }}>ยืนยันรหัสผ่านใหม่</label>
                                    <Form.Field error={confirm_password_error}>
                                        <Input
                                            name='confirm_password'
                                            type='password'
                                            value={confirm_password}
                                            onChange={handleChangeConfirmPassword}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer='3' only='computer' />
                            </Grid.Row>
                            {error_text && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                            <Message negative>{error_text}</Message>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    <Button
                                        style={{
                                            width: 100,
                                            backgroundColor: '#283655',
                                            color: '#FFF',
                                            marginRight: 15,
                                        }}
                                        onClick={handleSubmit}>
                                        บันทึก
                                    </Button>
                                    <Button
                                        style={{
                                            width: 100,
                                            backgroundColor: '#C54C40',
                                            color: '#FFF',
                                            marginLeft: 15,
                                        }}
                                        onClick={handleClose}>
                                        ยกเลิก
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Container>
            </CustomModal>
        </Fragment>
    )
}

export default UserDetailChangePassword
