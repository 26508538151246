export function getLIFF() {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = `https://static.line-scdn.net/liff/edge/2/sdk.js`
        document.body.appendChild(script)
        script.onload = () => {
            resolve(window.liff)
        }
    })
}
