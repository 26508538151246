import React, { Fragment, useState } from 'react'
import {
    Button,
    Container,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    Label,
    Segment,
    TextArea,
    TextAreaProps,
} from 'semantic-ui-react'
import CustomModal from '../../../components/CustomModal'
import { axiosAuthen } from '../../../config/axiosAuthen'
import { POST_DEFECTS_CREATE_AND_EDIT, POST_UPLOAD_DEFECT } from '../../../config/api/defect'

type FormProps = {
    open?: boolean
    onClose?: () => void
}

const DefectModalForm: React.FC<FormProps> = (props) => {
    let [files, setFiles] = useState<File[]>([])
    let [detail, setDetail] = useState<string | number | undefined>('')
    const removeImage = (index: number) => {
        let list_file = files.filter((_, index_file) => index_file !== index)
        setFiles(list_file)
    }

    const handleChangeFile = (e: any) => {
        let file = e.target.files
        if (file) {
            setFiles([...files, ...file])
        }
    }

    const handleClose = () => {
        props.onClose && props.onClose()
    }

    const handleChangeDetail = (_: any, { value }: TextAreaProps) => {
        setDetail(value)
    }

    const handleSubmit = () => {
        let body = {
            detail: detail,
            mucipalities_id: Number(localStorage.getItem('municipality_id')),
            users_id: Number(localStorage.getItem('user_id')),
        }

        axiosAuthen.post(POST_DEFECTS_CREATE_AND_EDIT, body).then((response) => {
            if (files.length > 0) {
                let { id } = response.data.value
                let form_data = new FormData()
                files.map((file: any) => form_data.append('defect_file', file))
                axiosAuthen.post(`${POST_UPLOAD_DEFECT}/${id}`, form_data)
            }
            setDetail('')
            handleClose()
        })
    }

    return (
        <Fragment>
            <CustomModal
                icon='question circle'
                header='เนื้อหาการสอบถามและปัญหา'
                open={props.open}
                closeIcon
                onClose={props.onClose}
                closeOnEscape={true}
                closeOnDimmerClick={true}
            >
                <Container>
                    <Form>
                        <Grid>
                            {/* <Grid.Row>
                                <Grid.Column computer='3' only='computer' />
                                <Grid.Column computer='10'>
                                    <label style={{ fontWeight: 'bold', color: '#283655' }}>หมวดหมู่</label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Segment
                                            style={{
                                                width: 120,
                                                height: 40,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContents: 'center',
                                                padding: 5,
                                                margin: 5,
                                            }}>
                                            3
                                        </Segment>
                                        <Segment
                                            style={{
                                                width: 120,
                                                height: 40,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContents: 'center',
                                                padding: 5,
                                                margin: 5,
                                            }}>
                                            1
                                        </Segment>
                                        <Segment
                                            style={{
                                                width: 120,
                                                height: 40,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContents: 'center',
                                                padding: 5,
                                                margin: 5,
                                            }}>
                                            2
                                        </Segment>
                                    </div>
                                </Grid.Column>
                                <Grid.Column computer='3' only='computer' />
                            </Grid.Row> */}
                            <Grid.Row>
                                <Grid.Column computer='3' only='computer' />
                                <Grid.Column computer='10' tablet='16' mobile='16'>
                                    <label style={{ fontWeight: 'bold', color: '#283655' }}>
                                        เนื้อหาการสอบถามและปัญหา
                                    </label>
                                    <Form.Field>
                                        <TextArea name='detail' value={detail} onChange={handleChangeDetail} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer='3' only='computer' />
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column computer='3' only='computer' />
                                <Grid.Column computer='10' tablet='16' mobile='16'>
                                    <Button
                                        style={{
                                            width: 100,
                                            backgroundColor: '#283655',
                                            color: '#FFF',
                                            marginRight: 15,
                                        }}
                                        disabled={files.length > 3}
                                        onClick={() => {
                                            document.getElementById('file')!.click()
                                        }}
                                    >
                                        เลือกไฟล์
                                    </Button>
                                    <label style={{ fontWeight: 'bold', color: '#283655' }}>
                                        เฉพาะสกุลไฟล์ *.jpg หรือ *.png
                                    </label>
                                </Grid.Column>
                                <Grid.Column computer='3' only='computer' />
                            </Grid.Row>
                            {files.length > 0 && (
                                <Grid.Row>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10' tablet='16' mobile='16'>
                                        <div style={{ display: 'flex', marginTop: 15 }}>
                                            {files.map((file: File, index: number) => (
                                                <Segment
                                                    key={index}
                                                    style={{
                                                        width: 120,
                                                        height: 120,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContents: 'center',
                                                        padding: 5,
                                                        margin: 5,
                                                    }}
                                                >
                                                    <Label
                                                        floating
                                                        icon={
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    removeImage(index)
                                                                }}
                                                            >
                                                                <Icon name='delete' style={{ margin: 0 }} />
                                                            </div>
                                                        }
                                                        basic
                                                        circular
                                                    />
                                                    <Image
                                                        style={{ cursor: 'pointer', maxHeight: 110 }}
                                                        fluid
                                                        src={URL.createObjectURL(file)}
                                                    />
                                                </Segment>
                                            ))}
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Grid.Row>
                            )}
                            <Input>
                                <input
                                    type='file'
                                    id='file'
                                    hidden
                                    onChange={handleChangeFile}
                                    name='Cameramanage'
                                    accept='image/*'
                                />
                            </Input>
                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    <Button
                                        style={{
                                            width: 100,
                                            backgroundColor: '#283655',
                                            color: '#FFF',
                                            marginRight: 15,
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        บันทึก
                                    </Button>
                                    <Button
                                        style={{
                                            width: 100,
                                            backgroundColor: '#C54C40',
                                            color: '#FFF',
                                            marginLeft: 15,
                                        }}
                                        onClick={handleClose}
                                    >
                                        ยกเลิก
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Container>
            </CustomModal>
        </Fragment>
    )
}

export default DefectModalForm
