import React, { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Form, Grid, Input, InputOnChangeData, Icon } from 'semantic-ui-react'
import { InterfaceDistrictsAndCommunities } from '.'
import CustomModal from '../../../../../components/CustomModal'
import { POST_AREAS_AND_COUMMUNITIES } from '../../../../../config/api/areasAndCommunities'
import { axiosAuthen } from '../../../../../config/axiosAuthen'

type FormProps = {
    data?: InterfaceDistrictsAndCommunities
    open?: boolean
    onClose?: () => void
}

export interface InterfaceCommunities {
    areas_id: number
    id: string
    community_name: string
    errors_community_name: boolean
}

const CreateDistrictsAndCommunities: React.FC<FormProps> = (props) => {
    let municipalities_id = localStorage.municipality_id
    let location = useLocation()
    let { state } = location
    if (state) {
        let location_state = JSON.parse(JSON.stringify(state, null, ' '))
        let { municipality_id } = location_state
        if (municipality_id) municipalities_id = municipality_id
    }

    let [area, setArea] = useState<string | undefined>('')
    let [errors_area, setErrorsArea] = useState<boolean>(false)
    let [list_communities, setListCommunities] = useState<any[]>([])

    const validateForm = () => {
        let error = false

        if (!area) {
            error = true
            setErrorsArea(true)
        }

        list_communities.forEach((data, index) => {
            if (!data.community_name) {
                let form = list_communities
                error = true
                form[index].errors_community_name = true

                setListCommunities([...form])
            }
        })

        return error
    }

    const handleChangeCommunities = (name: any, value: any, index: number) => {
        let form = list_communities
        if (form) {
            form[index][name] = value
            form[index].errors_community_name = false

            setListCommunities([...form])
        }
    }

    const handleChangeArea = (e: any, { value }: InputOnChangeData) => {
        setArea(value)
        setErrorsArea(false)
    }

    const hanldeRemoveArea = (index: number, data: InterfaceCommunities) => {
        setListCommunities(list_communities.filter((select: any, index_data: number) => select !== data))
    }

    const handleAddCommuni = (e: any, { value }: any) => {
        var data = {
            areas_id: 0,
            id: 0,
            errors_community_name: false,
            community_name: '',
        }
        setListCommunities([...list_communities, data])
    }

    const handleSubmit = () => {
        if (!validateForm()) {
            let body = {
                communities: list_communities.map((data) => ({
                    areas_id: data.areas_id,
                    id: data.id,
                    name: data.community_name,
                })),
                municipality_id: Number(municipalities_id),
                name: area,
            }
            axiosAuthen.post(POST_AREAS_AND_COUMMUNITIES, body).then((response) => {
                handleClose()
            })
        }
    }

    const handleClose = () => {
        props.onClose && props.onClose()
        setArea('')
        setListCommunities([])
        setErrorsArea(false)
    }

    return (
        <Fragment>
            <CustomModal icon='users' header='เพิ่มชุมชน' open={props.open}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>เขต</label>
                                <Form.Field error={errors_area}>
                                    <Input fluid name='area' value={area} onChange={handleChangeArea} />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>

                        {list_communities.map(
                            (data: InterfaceCommunities, index: number) => (
                                // indexShow === index && (
                                <Grid.Row key={index}>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='8' mobile='16'>
                                        <label className='header-input-form'>ชุมชน</label>
                                        <Form.Field error={data.errors_community_name}>
                                            {/* <Form.Field > */}
                                            <Input
                                                fluid
                                                onChange={(e, { name, value }) => {
                                                    handleChangeCommunities(name, value, index)
                                                }}
                                                name='community_name'
                                                value={data.community_name}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='2' mobile='16'>
                                        <br />
                                        <Button
                                            basic
                                            color='red'
                                            icon='trash alternate'
                                            onClick={() => hanldeRemoveArea(index, data)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Grid.Row>
                            )
                            // )
                        )}

                        {/* ปุ่มเพิ่มชุมชน value */}
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <Button fluid basic color='blue' onClick={handleAddCommuni}>
                                    <Icon
                                        circular
                                        size='small'
                                        color='blue'
                                        name='plus'
                                        style={{ boxShadow: '0px 0px 0px 1px #1F7EC7' }}
                                    />
                                    เพิ่มชุมชน
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>

                        {/* ปุ่มบันทึกและยกเลิก value */}
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default CreateDistrictsAndCommunities
