import { ActionInterface } from '../../types'
import { MessageFirebase } from '../../types/Notification'

const initialState: MessageFirebase[] = []

const Toast = (state = initialState, action: ActionInterface): MessageFirebase[] => {
    switch (action.type) {
        case 'toast':
            return [...state, action.value]
        case 'removeToast':
            state && state.length > 0 && state.shift()
            return [...state]
        default:
            return state
    }
}

export default Toast
