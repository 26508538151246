import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Form, Grid, Segment, Icon, Image } from 'semantic-ui-react';
import ImagePattern from '../../../../../assets/images/pattern.png';
import { GET_NOTIFICATION_ONE_CHAT_DETAIL_PRE_COMPLAINT } from '../../../../../config/api/notification';
import { HOST } from '../../../../../config/router';
import { colorList } from '../../../../../constants';
import Timeline from './Timeline';

export interface InterfacePreComplaintDetail {
    alley: string;
    area_name: string;
    community_name: string;
    complaint_created_at: string;
    complaint_detail: string;
    complaint_id: number;
    complaint_subject: string;
    created_at: string;
    district: string;
    email: string;
    files: [
        {
            action: string;
            created_at: string;
            created_by: number;
            deleted_at: string;
            id: number;
            name: string;
            path_file: string;
            pre_complaints_id: number;
            sequence: number;
            type: string;
            updated_at: string;
            updated_by: number;
        }
    ];
    files_result: [
        {
            complaints_id: number;
            created_at: string;
            created_by: number;
            deleted_at: string;
            id: number;
            name: string;
            path_file: string;
            sequence: number;
            type: string;
            updated_at: string;
            updated_by: number;
        }
    ];
    first_name: string;
    grade: number;
    house_no: string;
    id: number;
    last_name: string;
    lat: string;
    lng: string;
    mobile_number: string;
    on_site_date: string;
    pre_complaint_date: string;
    pre_complaint_no: string;
    prefix: string;
    processing_detail: string;
    processing_end_date: string;
    processing_start_date: string;
    province: string;
    reject_detail: string;
    road: string;
    status_name: string;
    subdistrict: string;
    suggestion: string;
    topic_name: string;
    village_no: string;
    zipcode: string;
}

const OneChatComplaintDetail: React.FC = () => {
    let [complaint_detail, setComplaintDetail] = useState<InterfacePreComplaintDetail | undefined>(undefined);
    let [loading, setLoading] = useState<boolean>(true);
    let [go_back, setGoBack] = useState<boolean>(false);
    let [municipality_id, setMunicipalityId] = useState<string | null>('');
    let [pre_complaint_id, setPreComplaintId] = useState<string | null>('');
    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        const getComplaintDetail = (pre_complaint_id: string, municipality_id: string) => {
            axios
                .get(`${GET_NOTIFICATION_ONE_CHAT_DETAIL_PRE_COMPLAINT}/${pre_complaint_id}/${municipality_id}`)
                .then((response) => {
                    let { value } = response.data;
                    setComplaintDetail(value);
                    setLoading(false);
                });
        };
        const query = new URLSearchParams(location.search);
        const municipality_id = query.get('municipality_id');
        const pre_complaint_id = query.get('pre_complaint_id');
        setMunicipalityId(municipality_id);
        setPreComplaintId(pre_complaint_id);
        if (pre_complaint_id && municipality_id) getComplaintDetail(pre_complaint_id, municipality_id);
        if (location.state) {
            setGoBack(true);
        }
    }, [location]);

    const setColor = (status_name: string | undefined) => {
        let color = colorList.find((color) => color.key === status_name);
        if (!color) {
            return '';
        } else {
            return color.value;
        }
    };

    const handleClickEvaluate = () => {
        history.push(`evaluate?pre_complaint_id=${pre_complaint_id}&municipality_id=${municipality_id}`, {
            go_back: true,
        });
    };

    const handleClickEvaluateDetail = () => {
        history.push(`detail-evaluate?pre_complaint_id=${pre_complaint_id}&municipality_id=${municipality_id}`, {
            go_back: true,
        });
    };

    return (
        <Fragment>
            <div
                style={{
                    backgroundRepeat: 'repeat',
                    backgroundImage: `url(${ImagePattern})`,
                    position: 'absolute',
                    height: 240,
                    zIndex: 1,
                    width: '100%',
                }}
            />
            <div
                style={{
                    backgroundImage: 'linear-gradient(#3D97A7, #1D3853)',
                    position: 'absolute',
                    height: 240,
                    width: '100%',
                }}
            />
            <Container>
                <Form style={{ zIndex: 2 }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{
                                    color: 'white',
                                    paddingTop: 14,
                                }}>
                                <h2>รายละเอียด</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column tablet='16' mobile='16'>
                                <Segment loading={loading}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <h3>
                                                    {go_back && (
                                                        <div
                                                            style={{ display: 'contents' }}
                                                            onClick={() => {
                                                                history.goBack();
                                                            }}>
                                                            <Icon name='angle left' style={{ color: '#2B6379' }} /> กลับ
                                                        </div>
                                                    )}
                                                    <Timeline pre_complaint_detail={complaint_detail} />
                                                </h3>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column tablet='6'>
                                                <b>เลขที่รับ</b>
                                            </Grid.Column>
                                            <Grid.Column tablet='10'>{complaint_detail?.pre_complaint_no}</Grid.Column>
                                        </Grid.Row>
                                        {complaint_detail?.created_at && (
                                            <Grid.Row>
                                                <Grid.Column tablet='6'>
                                                    <b>วันที่ยื่นเรื่อง</b>
                                                </Grid.Column>
                                                <Grid.Column tablet='10'>{complaint_detail?.created_at}</Grid.Column>
                                            </Grid.Row>
                                        )}
                                        {/* {complaint_detail?.complaint_created_at && (
                                    <Grid.Row>
                                        <Grid.Column tablet='6'>
                                            <b>วันที่รับเรื่อง</b>
                                        </Grid.Column>
                                        <Grid.Column tablet='10'>{complaint_detail?.complaint_created_at}</Grid.Column>
                                    </Grid.Row>
                                )} */}

                                        {complaint_detail?.processing_start_date && (
                                            <Grid.Row>
                                                <Grid.Column tablet='6'>
                                                    <b>วันที่เริ่มดำเนินการ</b>
                                                </Grid.Column>
                                                <Grid.Column tablet='10'>
                                                    {complaint_detail?.processing_start_date}
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        {complaint_detail?.processing_end_date && (
                                            <Grid.Row>
                                                <Grid.Column tablet='6'>
                                                    <b>วันที่คาดว่าจะแล้วเสร็จ</b>
                                                </Grid.Column>
                                                <Grid.Column tablet='10'>
                                                    {complaint_detail?.processing_end_date}
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}

                                        <Grid.Row>
                                            <Grid.Column tablet='6'>
                                                <b>เรื่อง</b>
                                            </Grid.Column>
                                            <Grid.Column tablet='10'>
                                                {complaint_detail?.complaint_subject}{' '}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='6'>
                                                <b>เรื่องที่ยื่นคำร้อง</b>
                                            </Grid.Column>
                                            <Grid.Column tablet='10'>{complaint_detail?.topic_name}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='6'>
                                                <b>รายละเอียด</b>
                                            </Grid.Column>
                                            <Grid.Column tablet='10'>{complaint_detail?.complaint_detail}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='6'>
                                                <b>สถานะ</b>
                                            </Grid.Column>
                                            <Grid.Column tablet='10'>
                                                <Icon
                                                    name='circle'
                                                    style={{
                                                        color: setColor(complaint_detail?.status_name),
                                                    }}
                                                />
                                                <label
                                                    style={{
                                                        color: setColor(complaint_detail?.status_name),
                                                    }}>
                                                    {complaint_detail?.status_name}
                                                </label>
                                                {complaint_detail?.status_name === 'เสร็จสิ้น' && (
                                                    <div>
                                                        <label
                                                            style={{
                                                                color: '#03A9F4',
                                                                textDecoration: 'underline',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={handleClickEvaluate}>
                                                            ตรวจสอบและประเมินผล
                                                        </label>
                                                    </div>
                                                )}
                                                {complaint_detail?.status_name === 'ประเมินผลเสร็จสิ้น' && (
                                                    <div>
                                                        <label
                                                            style={{
                                                                color: '#03A9F4',
                                                                textDecoration: 'underline',
                                                            }}
                                                            onClick={handleClickEvaluateDetail}>
                                                            ดูผลการประเมิน
                                                        </label>
                                                    </div>
                                                )}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='6'>
                                                <b>หมายเหตุ</b>
                                            </Grid.Column>
                                            <Grid.Column tablet='10'>
                                                {complaint_detail?.processing_detail
                                                    ? complaint_detail?.processing_detail
                                                    : complaint_detail?.reject_detail
                                                    ? complaint_detail?.reject_detail
                                                        ? complaint_detail?.reject_detail
                                                        : '-'
                                                    : '-'}
                                            </Grid.Column>
                                        </Grid.Row>
                                        {complaint_detail?.files && complaint_detail?.files.length > 0 && (
                                            <Fragment>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <h4>รูปภาพ</h4>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    {complaint_detail?.files.map((file: any, index: number) => (
                                                        <Grid.Column
                                                            tablet='16'
                                                            key={index}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}>
                                                            <Segment
                                                                key={index}
                                                                style={{
                                                                    width: 200,
                                                                    height: 200,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    padding: 5,
                                                                    margin: 5,
                                                                }}>
                                                                <Image
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        maxHeight: 190,
                                                                        maxWidth: 190,
                                                                    }}
                                                                    src={`${HOST}${file.path_file}`}
                                                                    onClick={() => {
                                                                        // setState({
                                                                        //     show_image: true,
                                                                        //     image: file,
                                                                        // });
                                                                    }}
                                                                />
                                                            </Segment>
                                                        </Grid.Column>
                                                    ))}
                                                </Grid.Row>
                                            </Fragment>
                                        )}
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Container>
            {/* <Modal
                open={show_image}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                // centered={false}
                closeIcon
                onClose={() => {
                    setState({ show_image: false });
                }}>
                <Modal.Header>เอกสารแนบ</Modal.Header>
                <Modal.Content
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    {image && image.file_type.includes('application/pdf') && (
                        <iframe
                            title='img'
                            src={`${HOST}${image.file_path}`}
                            width='800'
                            height='400'
                            style={{
                                margin: 10,
                                cursor: 'pointer',
                            }}
                        />
                    )}
                    {image && image.file_type.includes('image') && (
                        <Segment
                            style={{
                                width: 400,
                                height: 400,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: 10,
                                // cursor: 'pointer'
                            }}>
                            <Image
                                src={`${HOST}${image.file_path}`}
                                fluid
                                style={{
                                    maxHeight: 380,
                                    maxWidth: 380,
                                }}
                            />
                        </Segment>
                    )}
                </Modal.Content>
            </Modal> */}
        </Fragment>
    );
};

export default OneChatComplaintDetail;
