import { HOST } from '../router'

const COMPALINT_TOPIC_AND_TYPES = `${HOST}/manage_topics_and_types`
export const LIST_COMPALINT_TOPIC_AND_TYPES = `${COMPALINT_TOPIC_AND_TYPES}/list`
export const POST_COMPALINT_TOPIC_AND_TYPES = `${COMPALINT_TOPIC_AND_TYPES}/create_and_edit`
export const DELETE_COMPALINT_TOPIC_AND_TYPES = `${COMPALINT_TOPIC_AND_TYPES}/delete`
export const GET_COMPLAINT_TOPIC_AND_TYPES_ALL = `${COMPALINT_TOPIC_AND_TYPES}/all`
export const GET_TECHNICIAN_BY_TOPIC = `${COMPALINT_TOPIC_AND_TYPES}/technician_by_topic`
export const GET_TECHNICIAN_BY_DIRECTORY = `${COMPALINT_TOPIC_AND_TYPES}/technician_by_directory`
export const GET_COMPLAINT_TOPIC_BY_MUNICIPALITY_ID = `${COMPALINT_TOPIC_AND_TYPES}/topic`
export const GET_COMPLAINT_TOPIC_BY_DIRECTORY = `${COMPALINT_TOPIC_AND_TYPES}/topic_by_directory`
