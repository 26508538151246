import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Container, Form, Grid, Icon, Image, Rating, Segment, TextArea } from 'semantic-ui-react';
import { InterfacePreComplaintDetail } from '..';
import ImagePattern from '../../../../../../assets/images/pattern.png';
import { GET_NOTIFICATION_ONE_CHAT_DETAIL_PRE_COMPLAINT } from '../../../../../../config/api/notification';
import { HOST } from '../../../../../../config/router';

const OneChatComplaintDetailEvaluate: React.FC = () => {
    let history = useHistory();
    let location = useLocation();
    let [complaint_detail, setComplaintDetail] = useState<InterfacePreComplaintDetail | undefined>(undefined);
    let [loading, setLoading] = useState<boolean>(true);
    let [go_back, setGoBack] = useState<boolean>(false);

    useEffect(() => {
        const getComplaintDetail = (pre_complaint_id: string, municipality_id: string) => {
            axios
                .get(`${GET_NOTIFICATION_ONE_CHAT_DETAIL_PRE_COMPLAINT}/${pre_complaint_id}/${municipality_id}`)
                .then((response) => {
                    let { value } = response.data;
                    setComplaintDetail(value);
                    setLoading(false);
                });
        };
        const query = new URLSearchParams(location.search);
        const municipality_id = query.get('municipality_id');
        const pre_complaint_id = query.get('pre_complaint_id');
        if (pre_complaint_id && municipality_id) getComplaintDetail(pre_complaint_id, municipality_id);

        if (location.state) {
            setGoBack(true);
        }
    }, [location]);

    const getDetailGrade = (grade: number | undefined) => {
        if (grade === 5) {
            return 'ดีมาก';
        } else if (grade === 4) {
            return 'ดี';
        } else if (grade === 3) {
            return 'ปานกลาง';
        } else if (grade === 2) {
            return 'พอใช้';
        } else if (grade === 1) {
            return 'ปรับปรุง';
        }
    };

    return (
        <Fragment>
            <div
                style={{
                    backgroundRepeat: 'repeat',
                    backgroundImage: `url(${ImagePattern})`,
                    position: 'absolute',
                    height: 240,
                    zIndex: 1,
                    width: '100%',
                }}
            />
            <div
                style={{
                    backgroundImage: 'linear-gradient(#3D97A7, #1D3853)',
                    position: 'absolute',
                    height: 240,
                    width: '100%',
                }}
            />
            <Container>
                <Form style={{ zIndex: 2 }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{
                                    color: 'white',
                                    paddingTop: 14,
                                }}>
                                <h2>ดูผลการประเมิน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column tablet='16' mobile='16'>
                                <Segment loading={loading}>
                                    <Grid>
                                        {go_back && (
                                            <Grid.Row>
                                                <Grid.Column tablet='16'>
                                                    <h3
                                                        onClick={() => {
                                                            history.goBack();
                                                        }}>
                                                        <Icon name='angle left' style={{ color: '#2B6379' }} /> กลับ
                                                    </h3>
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        <Grid.Row />
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <h3>
                                                    <Icon name='clipboard check' style={{ color: '#54ABB5' }} />{' '}
                                                    ผลการประเมิน
                                                </h3>
                                            </Grid.Column>
                                        </Grid.Row>
                                        {complaint_detail?.files_result && complaint_detail?.files_result.length > 0 && (
                                            <Grid.Row>
                                                <Grid.Column tablet='16'>
                                                    <h5>ภาพประกอบ</h5>
                                                    {complaint_detail?.files_result.map((file, index) => (
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Segment
                                                                key={index}
                                                                style={{
                                                                    width: 200,
                                                                    height: 200,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    padding: 5,
                                                                    margin: 5,
                                                                }}>
                                                                <Image
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        maxHeight: 190,
                                                                        maxWidth: 190,
                                                                    }}
                                                                    src={`${HOST}${file.path_file}`}
                                                                />
                                                            </Segment>
                                                        </div>
                                                    ))}
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Segment>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Rating
                                                            name='rating'
                                                            maxRating={5}
                                                            icon='star'
                                                            size='massive'
                                                            rating={complaint_detail?.grade}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            marginTop: 10,
                                                        }}>
                                                        <label style={{ fontWeight: 'bold' }}>ผลการประเมิน : </label>
                                                        <label>{getDetailGrade(complaint_detail?.grade)}</label>
                                                    </div>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <label style={{ fontWeight: 'bold' }}>หมายเหตุ</label>
                                                <br />
                                                5 ดาว = ดีมาก, 4 ดาว = ดี, 3 ดาว = ปานกลาง,
                                                <br />2 ดาว = พอใช้, 1 ดาว = ปรับปรุง
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <label style={{ fontWeight: 'bold' }}>ข้อเสนอแนะ</label>
                                                <TextArea value={complaint_detail?.suggestion} readOnly />
                                            </Grid.Column>
                                        </Grid.Row>
                                        {go_back && (
                                            <Grid.Row>
                                                <Grid.Column
                                                    tablet='16'
                                                    mobile='16'
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: 'white',
                                                            color: '#38869C',
                                                            border: '3px solid #38869C',
                                                        }}
                                                        onClick={() => {
                                                            history.goBack();
                                                        }}>
                                                        กลับ
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Container>
        </Fragment>
    );
};
export default OneChatComplaintDetailEvaluate;
