import React, { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Form, Grid, Input } from 'semantic-ui-react'

import CustomModal from '../../../../components/CustomModal'
import { POST_DIRECTORY_OF_MUNICIPALITY_CREATE_AND_EDIT_NEXT_TIRE } from '../../../../config/api/directoryOfMunicipality'

import { axiosAuthen } from '../../../../config/axiosAuthen'
import { InterfaceDirectory } from './ListChildren'

type FormProps = {
    level_directory_id?: number | undefined
    parent_directory_id?: number
    open?: boolean
    onClose?: () => void
    data?: InterfaceDirectory
}

const ManagementDirectoryModalEditLow: React.FC<FormProps> = (props) => {
    let location = useLocation()

    let [municipality_id, setMunicipalityID] = useState<number>(0)

    let [directory_name, setDirectoryName] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >('')
    let [errors_directory_name, setErrorsDirectorName] = useState<boolean>(false)
    let [directory_id, setDirectoryID] = useState<number>()

    useEffect(() => {
        let data = props.data
        if (data) {
            setDirectoryID(data.id)
            setDirectoryName(data.name)
            // setLevelDirectory(data.level)
        }
    }, [props.data])

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            setMunicipalityID(municipality_id)
        }
    }, [location])

    const handleChangeDirectory = (e: any, { value }: any) => {
        setDirectoryName(value)
    }

    const validateForm = () => {
        let error = false

        if (!directory_name) {
            error = true
            setErrorsDirectorName(true)
        }

        return error
    }

    const handleSubmit = () => {
        if (!validateForm()) {
            let body = {
                id: Number(directory_id),
                name: directory_name,
                municipality_id: Number(municipality_id),
            }
            axiosAuthen.post(POST_DIRECTORY_OF_MUNICIPALITY_CREATE_AND_EDIT_NEXT_TIRE, body).then(() => {
                handleClose()
            })
        }
    }

    const handleClose = () => {
        setDirectoryName('')
        props.onClose && props.onClose()
    }

    return (
        <Fragment>
            <CustomModal icon='users' header='เพิ่มหน่วยงาน' open={props.open}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <Form.Field error={errors_directory_name}>
                                    <label className='header-input-form'>ชื่อหน่วยงาน</label>
                                    <Input
                                        fluid
                                        name='directory_name'
                                        value={directory_name}
                                        onChange={handleChangeDirectory}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            {/* <Grid.Column computer='5' mobile='16'>
                                <Form.Field error={errors_level_directory}>
                                    <label className='header-input-form'>ระดับหน่วยงาน</label>
                                    <Dropdown
                                        fluid
                                        selection
                                        search
                                        name='level_directory'
                                        value={level_directory}
                                        options={level_directory_option}
                                        onChange={handleChangeLevelDirectory}
                                    />
                                </Form.Field>
                            </Grid.Column> */}
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default ManagementDirectoryModalEditLow
