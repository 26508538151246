import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Divider, Segment } from 'semantic-ui-react'
import CustomFullCalendar from '../../../components/CustomFullCalendar'
import { GET_DASHBOARD_CALENDAR_ON_SITE } from '../../../config/api/dashboard'
import { axiosAuthen } from '../../../config/axiosAuthen'

const DashboardCalendar: React.FC = () => {
    let [events, setEvents] = useState<{ title: string; allDay: boolean; start: Date }[]>([])
    let history = useHistory()
    useEffect(() => {
        axiosAuthen.get(GET_DASHBOARD_CALENDAR_ON_SITE).then((response) => {
            let { value } = response.data
            let events: any[] = []
            value.forEach((data: any) => {
                let date = data.on_site_date.split('/')
                let event = {
                    title: `${data.complaint_no} ${data.complaint_subject}`,
                    allDay: true,
                    start: new Date(`${date[1]}/${date[0]}/${String(Number(date[2]) - 543)}`),
                    id: data.id,
                }
                events.push(event)
            })
            setEvents(events)
        })
    }, [])

    const handleClickEvent = (event: any) => {
        history.push('complaint/detail', { complaint_id: event.id })
    }
    return (
        <Segment>
            <div
                style={{
                    padding: 15,
                }}
            >
                <h3>ปฏิทินกิจกรรม</h3>
                <Divider />
            </div>
            <CustomFullCalendar events={events} handleClickEvent={handleClickEvent} />
        </Segment>
    )
}
export default DashboardCalendar
