import React, { Fragment, useCallback, useEffect, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import HighchartsOrganization from 'highcharts/modules/organization'
import HighchartsSankey from 'highcharts/modules/sankey'
import Highcharts from 'highcharts'
import { Breadcrumb, Button, Checkbox, Container, Dimmer, Grid, Icon, Loader, Segment } from 'semantic-ui-react'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import {
    GET_DIRECTORY_OF_MUNICIPALITY_DIRECTORY_AND_ROLES,
    PUT_DIRECTORY_OF_MUNICIPALITY_MAPPING_DIRECTORY_AND_ROLES,
} from '../../../../config/api/directoryOfMunicipality'
import { useHistory, useLocation } from 'react-router-dom'
import CustomModal from '../../../../components/CustomModal'
import { GET_ROLE_BY_MUNICIPALITY } from '../../../../config/api/roles'

type Props = {
    municipality_id?: number
    current_directory?: number
}

interface InterfaceDirectory {
    id: number
    name: string
    level: number
    parent_id: number
    roles: InterfaceRolesInLevelDirecory[]
}

interface InterfaceRolesInLevelDirecory {
    id: number
    name: string
}
interface InterfaceValueRole {
    id: number
    name: string
    name_en: string
}

const ManagementDirectoryOrganizationChart: React.FC<Props> = (props) => {
    let location = useLocation()
    let history = useHistory()
    HighchartsSankey(Highcharts)
    HighchartsOrganization(Highcharts)
    let { state } = location
    let location_state = JSON.parse(JSON.stringify(state, null, ' '))
    let { municipality_id, current_directory } = location_state
    let [chart_options, setChartOptions] = useState<any>()

    let [roles, setRoles] = useState<[]>([])
    let [roles_selected, setRolesSelected] = useState<number[]>([])
    let [directory_id_selected, setDirectoryIDSelected] = useState<number>()
    let [directory_name_selected, setDirectoryNameSelected] = useState<string>('')
    let [open_modal, setOpenModal] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(true)

    const getDirectoryAndRoles = useCallback(() => {
        municipality_id !== 0 &&
            axiosAuthen
                .get(`${GET_DIRECTORY_OF_MUNICIPALITY_DIRECTORY_AND_ROLES}?municipality_id=${municipality_id}`)
                .then((response) => {
                    let { value } = response.data
                    if (value && value.length) {
                        let from_to: { from: string; to: string }[] = []
                        let nodes: any = []
                        let max_level = Math.max(...value.map((level: InterfaceDirectory) => level.level))
                        let max_children = 1
                        let max_level_length = 1
                        value.forEach((level: InterfaceDirectory) => {
                            let parent_name = localStorage.getItem('municipalities_name') || 'องค์กร'

                            if (level.parent_id) {
                                parent_name =
                                    (value &&
                                        value.find(
                                            (find_parent: InterfaceDirectory) => find_parent.id === level.parent_id
                                        )?.name) ||
                                    ''
                            }

                            from_to.push({ from: parent_name, to: level.name })
                            parent_name = level.name
                            let description_level_directory = ''
                            level.roles.forEach((role: InterfaceRolesInLevelDirecory, index: number) => {
                                if (index > 0) description_level_directory += ', '
                                description_level_directory += role.name
                            })
                            let child = value.find((directory: InterfaceDirectory) => directory.parent_id === level.id)
                            let child_length = value.filter(
                                (directory: InterfaceDirectory) => directory.parent_id === level.id
                            ).length

                            let offset = ''
                            let current_level_length = value.filter(
                                (directory: InterfaceDirectory) => directory.level === level.level
                            ).length

                            if (current_level_length > max_level_length) max_level_length = current_level_length

                            let layout = ''
                            if (child) {
                                layout = 'hanging'
                                if (child_length > max_children) {
                                    max_children = child_length
                                }

                                // let level_great_child_length = value.filter(
                                //     (directory: InterfaceDirectory) => directory.level === child.level
                                // ).length

                                let great_child_length = value.filter(
                                    (directory: InterfaceDirectory) => directory.parent_id === child.id
                                ).length

                                if (great_child_length > max_children) {
                                    max_children = great_child_length
                                }
                            }

                            nodes.push({
                                id: `${level.name}`,
                                name: `${level.name}`,
                                info: description_level_directory,
                                tier_id: level.id,
                                layout: layout,
                                offset: offset,
                                // column: level.level,
                                color: current_directory === level.id ? '#2b3653' : '#bababa',
                                dataLabels: {
                                    color: current_directory === level.id ? 'white' : 'black',
                                },
                                roles: level.roles.map((role: InterfaceRolesInLevelDirecory) => role.id),
                            })
                        })

                        let chart_oprtion = {
                            chart: {
                                height: (max_level + 1 + max_children + 1) * 70,
                                inverted: true,
                            },

                            title: {
                                text: 'แผนผังระดับหน่วยงาน',
                            },

                            series: [
                                {
                                    type: 'organization',
                                    name: 'แผนผังองค์กร',
                                    keys: ['from', 'to'],
                                    data: from_to,
                                    nodes: nodes,
                                    cursor: 'pointer',
                                    colorByPoint: false,
                                    color: 'silver',
                                    dataLabels: {
                                        color: 'black',
                                    },
                                    borderColor: 'white',
                                    nodeWidth: 45,
                                    point: {
                                        events: {
                                            click: ({ point }: any) => {
                                                let { tier_id, name, roles } = point
                                                tier_id && handleClickPoint(tier_id, name, roles)
                                            },
                                        },
                                    },
                                },
                            ],
                            tooltip: {
                                outside: true,
                                nodeFormat: '<b>{point.name}<br/> สิทธิ์ในหน่วยงาน<br/> {point.info}</b>',
                            },
                            credits: {
                                enabled: false,
                            },
                        }
                        setChartOptions(chart_oprtion)
                        setLoading(false)
                    }
                })
                .catch((error) => {
                    setLoading(false)
                })
    }, [current_directory, municipality_id])

    useEffect(() => {
        const getRoles = () => {
            axiosAuthen.get(`${GET_ROLE_BY_MUNICIPALITY}/${municipality_id}`).then((response) => {
                let { value } = response.data
                setRoles(value)
            })
        }

        getDirectoryAndRoles()
        getRoles()
    }, [getDirectoryAndRoles, municipality_id])

    const handleClickPoint = (directory_id: number, directory_name: string, roles: number[]) => {
        setDirectoryIDSelected(directory_id)
        setDirectoryNameSelected(directory_name)
        setRolesSelected(roles)
        setOpenModal(true)
    }

    const handleSelectRole = (role_id: number) => {
        if (roles_selected.some((select) => select === role_id)) {
            let role_select = roles_selected.filter((select) => select !== role_id)
            setRolesSelected(role_select)
        } else {
            setRolesSelected([...roles_selected, role_id])
        }
    }

    const handleClose = () => {
        setOpenModal(false)
        setRolesSelected([])
    }

    const handleSubmit = () => {
        let body = {
            directory_id: directory_id_selected,
            roles_id: roles_selected,
            municipality_id: municipality_id,
        }
        setLoading(true)
        axiosAuthen.put(PUT_DIRECTORY_OF_MUNICIPALITY_MAPPING_DIRECTORY_AND_ROLES, body).then(() => {
            getDirectoryAndRoles()
            handleClose()
        })
    }
    return (
        <Fragment>
            <Dimmer active={loading} inverted page>
                <Loader inverted content='รอสักครู่' />
            </Dimmer>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column>
                                <Breadcrumb>
                                    {localStorage.permission_en === 'super_admin' && (
                                        <Fragment>
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities`, {
                                                        municipality_id: municipality_id,
                                                    })
                                                }}
                                            >
                                                รายการองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                        municipality_id: municipality_id,
                                                    })
                                                }}
                                            >
                                                กำหนดการใช้งานองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </Fragment>
                                    )}
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(
                                                `/${localStorage.permission_en}/management/directory-of-municipality/list`,
                                                {
                                                    municipality_id: municipality_id,
                                                    current_directory: current_directory,
                                                }
                                            )
                                        }}
                                    >
                                        จัดการหน่วยงาน
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>แผนผังองค์กร</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Button
                                style={{ position: 'absolute', zIndex: 1 }}
                                icon
                                labelPosition='left'
                                primary
                                onClick={() => history.goBack()}
                            >
                                <Icon name='chevron left' /> กลับ
                            </Button>
                            <Grid.Column computer='16' textAlign='center'>
                                <h2>แผนผังองค์กร</h2>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <CustomModal
                        icon='sitemap'
                        centered={false}
                        header='จัดการสิทธิ์ในระดับหน่วยงาน'
                        size='tiny'
                        open={open_modal}
                        onClose={handleClose}
                        closeOnEscape={false}
                        closeOnDimmerClick={false}
                    >
                        <Grid>
                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    <h3>ระดับหน่วยงาน : {directory_name_selected}</h3>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment style={{ overflowX: 'auto', height: 500 }}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column textAlign='center'>
                                                    <h4>รายการสิทธิ์</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                            {roles.map((role: InterfaceValueRole) => (
                                                <Grid.Row key={role.id}>
                                                    <Grid.Column>
                                                        <Segment
                                                            style={{
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                            onClick={() => handleSelectRole(role.id)}
                                                        >
                                                            <Checkbox
                                                                checked={roles_selected.some(
                                                                    (selected) => selected === role.id
                                                                )}
                                                            />{' '}
                                                            <label style={{ paddingLeft: 10, cursor: 'pointer' }}>
                                                                {' '}
                                                                {role.name}
                                                            </label>
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            ))}
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    <Button
                                        style={{
                                            width: 100,
                                            backgroundColor: '#283655',
                                            color: '#FFF',
                                            marginRight: 15,
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        บันทึก
                                    </Button>
                                    <Button
                                        style={{
                                            width: 100,
                                            backgroundColor: '#C54C40',
                                            color: '#FFF',
                                            marginLeft: 15,
                                        }}
                                        onClick={handleClose}
                                    >
                                        ยกเลิก
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </CustomModal>
                </Container>
                {chart_options && (
                    <Segment style={{ overflow: 'auto', height: 620, zIndex: 1 }}>
                        <HighchartsReact highcharts={Highcharts} options={chart_options} />
                    </Segment>
                )}
            </div>
        </Fragment>
    )
}

export default ManagementDirectoryOrganizationChart
