import React, { useEffect, useState } from 'react';
import { Container, Dimmer, Loader, Segment } from 'semantic-ui-react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { axiosAuthen } from '../../../config/axiosAuthen';
import { GET_DASHBOARD_COUNT_STATUS_UNSUCCESS_TECHNICIAN } from '../../../config/api/dashboard';

const MakeData = [
{
    y: 0,
    name: "ส่งกลับส่วนกลาง",
    color: "#79B2C6",
    // percent: 0,
},
{
    y: 0,
    name: "แก้ไขคำร้อง",
    color: "#283655",
    // percent: 0,
},
{
    y: 0,
    name: "ไม่สามารถดำเนินการได้",
    color: "#2D83B6",
    // percent: 0,
},
{
    y: 0,
    name: "ส่งต่อหน่วยงานอื่น",
    color: "#9FCCEC",
    // percent: 0,
},
{
    y: 0,
    name: "อื่นๆ",
    color: "#4D648D",
    // percent: 0,
},
]

type DashboardProps = {
    technician_id?: string;
    year?: string | number | boolean | (string | number | boolean)[] | undefined;
};

interface InterfaceDashboardComplaintCanNotProcess {
    count: number;
    percent: string;
    status_en: string;
    status_th: string;
}
const DashboardTechnianComplaintCanNotProcess: React.FC<DashboardProps> = (props) => {
    let { year } = props;
    let { technician_id } = props;
    let [loading, setLoading] = useState<boolean>(true);
    let [chart_options, setChartOptions] = useState<any>(undefined);
    useEffect(() => {
        axiosAuthen.get(`${GET_DASHBOARD_COUNT_STATUS_UNSUCCESS_TECHNICIAN}/${technician_id}?year=${year ==='all'? '': year}`).then((response) => {
            let { value } = response.data;

            let data_series: any[] = [];
            value.forEach((data: InterfaceDashboardComplaintCanNotProcess) => {
                if (data.status_th === 'คำร้องทุกข์ที่ไม่สามารถดำเนินการได้ทั้งหมด') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#89CCF7',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'ส่งกลับส่วนกลาง') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#79B2C6',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'แก้ไขคำร้อง') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#283655',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'ไม่สามารถดำเนินการได้') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#9FCCEC',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'ส่งต่อหน่วยงานอื่น') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#53A6D8',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'อื่นๆ') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#4D648D',
                        percent: data.percent,
                    });
                }
            });
            let categories = [
                'ส่งกลับส่วนกลาง',
                'แก้ไขคำร้อง',
                'ไม่สามารถดำเนินการได้',
                'ส่งต่อหน่วยงานอื่น',
                'อื่น ๆ',
            ];

            let chart_options = {
                chart: {
                    type: 'pie',
                },
                title: {
                    text: 'สรุปคำร้องทุกข์ที่ไม่สามารถดำเนินการได้',
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: null,
                    },
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                series: [
                    {
                        name: 'สถานะคำร้องทุกข์',
                        data: data_series,
                    },
                ],
                credits: {
                    enabled: false,
                },
            };
            setChartOptions(chart_options);
            setLoading(false);

        }).catch(()=> {
            let categories = [
                'ส่งกลับส่วนกลาง',
                'แก้ไขคำร้อง',
                'ไม่สามารถดำเนินการได้',
                'ส่งต่อหน่วยงานอื่น',
                'อื่น ๆ',
            ];

            let chart_options = {
                chart: {
                    type: 'pie',
                },
                title: {
                    text: 'สรุปคำร้องทุกข์ที่ไม่สามารถดำเนินการได้',
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: null,
                    },
                },
                tooltip: {
                    pointFormat: '{series.name}: <b> : {point.y} คำร้อง ( {point.percentage:.1f}% )</b>',
                },
                accessibility: {
                    point: {
                        valueSuffix: '%',
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false,
                        },
                        showInLegend: true,
                    },
                },
                series: [
                    {
                        name: 'สถานะคำร้องทุกข์',
                        data: MakeData,
                    },
                ],
                credits: {
                    enabled: false,
                },
            };
            setChartOptions(chart_options);
            setLoading(false);
        });
    }, [year, technician_id]);

    if (loading) {
        return (
            <Segment style={{ height: 430 }}>
                <div>
                    <Container style={{ height: window.innerHeight }}>
                        <Dimmer active inverted>
                            <Loader size='huge' />
                        </Dimmer>
                    </Container>
                </div>
            </Segment>
        );
    } else {
        return (
            <Segment>
                <HighchartsReact highcharts={Highcharts} options={chart_options} />
            </Segment>
        );
    }
};
export default DashboardTechnianComplaintCanNotProcess;
