import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Container, Grid, Icon } from 'semantic-ui-react'
import CustomSegment from '../../../../components/CustomSegment'
import { GET_NOTIFICATION_LINE_PROFILE } from '../../../../config/api/notification'
import { getLIFF } from '../../../../config/liff'

interface InterfaceProfileOneChat {
    account_id: string
    alley: string
    birth_date: string
    created_at: string
    created_by: number
    deleted_at: string
    district: string
    email: string
    first_name_en: string
    first_name_th: string
    house_no: string
    id: number
    id_card_number: string
    id_card_type: string
    image: string
    last_name_en: string
    last_name_th: string
    line_user_id: string
    municipalities_id: number
    one_chat_user_id: string
    one_mail: string
    password: string
    phone_number: string
    prefix_en: string
    prefix_th: string
    province: string
    road: string
    sub_district: string
    updated_at: string
    updated_by: number
    username: string
    village_no: string
    zipcode: string
}

const LineProfile: React.FC = () => {
    let location = useLocation()
    let history = useHistory()
    let [loading, setLoading] = useState<boolean>(false)
    let [profile, setProfile] = useState<InterfaceProfileOneChat | undefined>()
    let [line_user_id, setLineUserId] = useState<string>('')
    let [municipality, setMunicipality] = useState<string>('')
    let [liff_id, setLiffId] = useState<string>('')
    let [line_display_name, setLineDisplayName] = useState<string>('')

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const municipality = query.get('municipality_id')
        municipality && setMunicipality(municipality)
        const liff_id = query.get('liff_id')
        liff_id && setLiffId(liff_id)
    }, [location])

    useEffect(() => {
        const getProfile = () => {
            axios.get(`${GET_NOTIFICATION_LINE_PROFILE}/${municipality}/${line_user_id}`).then((response) => {
                let { value } = response.data
                setProfile(value)
                setLoading(false)
            })
        }
        getLIFF().then((liff: any) => {
            liff.init({
                liffId: liff_id,
            }).then(async () => {
                let profile = await liff.getProfile()
                setLineUserId(profile.userId)
                setLineDisplayName(profile.displayName)
                // getCheckRegister(profile.userId)
            })
        })

        municipality && line_user_id && getProfile()
    }, [location, history, liff_id, line_user_id, municipality])

    const handleClickEditProfile = () => {
        history.push(
            `edit-profile?municipality_id=${municipality}&line_user_id=${line_user_id}&line_display_name=${line_display_name}`
        )
    }

    return (
        <Fragment>
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column mobile='16'>
                            <Button
                                floated='right'
                                style={{ marginTop: 20, zIndex: 5 }}
                                icon
                                color='orange'
                                labelPosition='left'
                                onClick={handleClickEditProfile}
                            >
                                <Icon name='edit' />
                                แก้ไขข้อมูล
                            </Button>
                        </Grid.Column>
                        <Grid.Column tablet='16' mobile='16'>
                            <CustomSegment loading={loading} icon='user circle' header='ข้อมูลผู้แจ้งคำร้อง'>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column mobile='6'>
                                            <h5>ชื่อ</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='10'>
                                            {profile?.prefix_th}
                                            {profile?.first_name_th} {profile?.last_name_th}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='6'>
                                            <h5>หมายเลขโทรศัพท์</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='10'>
                                            {profile?.phone_number ? profile.phone_number : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </CustomSegment>
                            <CustomSegment loading={loading} icon='user circle' header='ที่อยู่ผู้แจ้งคำร้อง'>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column mobile='6'>
                                            <h5>บ้านเลขที่</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='10'>
                                            {profile?.house_no ? profile.house_no : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='6'>
                                            <h5>หมู่ที่</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='10'>
                                            {profile?.village_no ? profile.village_no : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='6'>
                                            <h5>ซอย</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='10'>{profile?.alley ? profile.alley : '-'}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='6'>
                                            <h5>ถนน</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='10'>{profile?.road ? profile.road : '-'}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='6'>
                                            <h5>ตำบล</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='10'>
                                            {profile?.sub_district ? profile.sub_district : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='6'>
                                            <h5>อำเภอ</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='10'>
                                            {profile?.district ? profile.district : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='6'>
                                            <h5>จังหวัด</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='10'>
                                            {profile?.province ? profile.province : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='6'>
                                            <h5>รหัสไปรษณีย์</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='10'>
                                            {profile?.zipcode ? profile.zipcode : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </CustomSegment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Fragment>
    )
}
export default LineProfile
