import React, { Fragment, useMemo } from 'react'
import {
    Button,
    Form,
    Grid,
    TextArea,
    Dropdown,
    Input,
    DropdownProps,
    InputOnChangeData,
    TextAreaProps,
} from 'semantic-ui-react'
import CustomModal from '../../../../../components/CustomModal'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import { useState } from 'react'
import { useEffect } from 'react'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import {
    GET_COMPLAINT_STATUS_ACCEPT_ALL,
    POST_COMPLAINT_ACCEPT_COMPLAINT,
    PUT_COMPLAINT_CHANGE_DIRECTORY,
} from '../../../../../config/api/complaint'
import DatePicker from '../../../../../components/DatePicker'
import { GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE } from '../../../../../config/api/directoryOfMunicipality'
import { alert } from '../../../../../components/alert'
import { useHistory } from 'react-router-dom'

type FormProps = {
    complaint_id?: string | number
    reload?: () => void
}

const AcceptComplaint: React.FC<FormProps> = (props) => {
    let history = useHistory()
    let [open, setOpen] = useState<boolean>(false)
    let [action, setAction] = useState<string | number | boolean | (string | number | boolean)[] | undefined>(undefined)
    let [action_option, setActionOption] = useState<[]>([])
    let [duration, setDuration] = useState<{ value: number | string; text: string } | undefined>({
        value: '',
        text: '',
    })
    let [start_date, setStartDate] = useState<string>('')
    let [end_date, setEndDate] = useState<string>('')
    let [dis_start_date, setDisStartDate] = useState<string>('')
    let [on_site_date, setOnSiteDate] = useState<string>('')
    let [fiscal_year, setFiscalYear] = useState<string>('')
    let [detail, setDetail] = useState<string | number | undefined>('')
    let [directory, setDirectory] = useState<string | number | boolean | (string | number | boolean)[] | undefined>(
        undefined
    )
    let [directory_option, setDirectoryOption] = useState<[]>([])
    let [errors_duration, setErrorsDuration] = useState<boolean>(false)
    let [errors_start_date, setErrorStartDate] = useState<boolean>(false)
    let [errors_end_date, setErrorEndDate] = useState<boolean>(false)
    let [errors_on_site_date, setErrorOnSiteDate] = useState<boolean>(false)
    let [errors_fiscal_year, setErrorFiscalYear] = useState<boolean>(false)
    let [errors_directory, setErrorDirectory] = useState<boolean>(false)
    let [errors_detail, setErrorDetail] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(false)
    let duration_option = [
        { value: 7, text: '1-7 วัน' },
        { value: 15, text: '8-15 วัน' },
        { value: 30, text: '16-30 วัน' },
    ]
    const handleOpen = () => {
        setOpen(!open)
    }
    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        const getStatusAccpet = () => {
            axiosAuthen.get(GET_COMPLAINT_STATUS_ACCEPT_ALL).then((response) => {
                let { value } = response.data
                let action_option = value.map((data: any) => ({ value: data.id, text: data.name }))
                setActionOption(action_option)
            })
        }
        const getDirectory = () => {
            axiosAuthen.get(`${GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE}`).then((response) => {
                let { value } = response.data
                let directory_option = value.map((data: any) => ({ value: data.id, text: data.name }))
                setDirectoryOption(directory_option)
            })
        }
        getStatusAccpet()
        getDirectory()
    }, [])

    const handleChangeAction = (e: any, { value }: DropdownProps) => {
        setAction(value)
        setErrorsDuration(false)
        setErrorStartDate(false)
        setErrorOnSiteDate(false)
        setErrorFiscalYear(false)
        setErrorDirectory(false)
        setErrorDetail(false)
        setErrorEndDate(false)
    }

    const handleChangeDuration = (e: any, { value }: DropdownProps) => {
        setDuration(duration_option.find((data) => data.value === Number(value)))
        const current_date = new Date()
        const future_date = new Date(current_date)

        future_date.setDate(current_date.getDate() + Number(value) - 1)
      
        setDisStartDate(current_date.toLocaleDateString('th-TH'))
        // setDisEndDate(current_date.toLocaleDateString('th-TH'))
        // setDisEndDate(future_date.toLocaleDateString('th-TH'))
     
    }
    useMemo(() => {
    if(start_date){
        if (duration?.value) {
            let new_date_format = start_date.split('/')
            let new_date = `${new_date_format[1]}/${new_date_format[0]}/${String(Number(new_date_format[2]) - 543)}`
            let end_date = new Date(new_date)
            end_date.setDate(end_date.getDate() + Number(duration.value) - 1)

            let format_end_date = `${end_date.getDate() < 10 ? `0${end_date.getDate()}` : end_date.getDate()}/${
                end_date.getMonth() + 1 < 10 ? `0${end_date.getMonth() + 1}` : end_date.getMonth() + 1
            }/${end_date.getFullYear() + 543}`
            setEndDate(format_end_date)
        }
    }

    }, [duration, start_date])

    const handleChangeStartDate = (e: any, { value }: InputOnChangeData) => {
        setStartDate(value)
        if (duration?.value) {
            let new_date_format = value.split('/')
            let new_date = `${new_date_format[1]}/${new_date_format[0]}/${String(Number(new_date_format[2]) - 543)}`
            let end_date = new Date(new_date)
            end_date.setDate(end_date.getDate() + Number(duration.value) - 1)

            let format_end_date = `${end_date.getDate() < 10 ? `0${end_date.getDate()}` : end_date.getDate()}/${
                end_date.getMonth() + 1 < 10 ? `0${end_date.getMonth() + 1}` : end_date.getMonth() + 1
            }/${end_date.getFullYear() + 543}`
            setEndDate(format_end_date)
        }
        setErrorStartDate(false)
        setErrorEndDate(false)
    }


    const handleChangeOnSiteDate = (e: any, { value }: InputOnChangeData) => {
        setOnSiteDate(value)
        setErrorOnSiteDate(false)
    }

    const handleChangeFiscalYear = (e: any, { value }: InputOnChangeData) => {
        if (new RegExp('^[0-9]*$').test(value)) {
            setFiscalYear(value)
            setErrorFiscalYear(false)
        }
    }

    const handleChangeDetail = (e: any, { value }: TextAreaProps) => {
        setDetail(value)
        setErrorDetail(false)
    }

    const handleChangeDirectory = (e: any, { value }: DropdownProps) => {
        setDirectory(value)
        setErrorDirectory(false)
    }

    const validateForm = () => {
        let error = false

        if (action === 9) {
            if (!duration) {
                error = true
                setErrorsDuration(true)
            }

            if (!start_date) {
                error = true
                setErrorStartDate(true)
            }

            if (!end_date) {
                error = true
                setErrorEndDate(true)
            }

            if (!on_site_date) {
                error = true
                setErrorOnSiteDate(true)
            }
        }

        if (action === 6) {
            if (!fiscal_year) {
                error = true
                setErrorFiscalYear(true)
            }
        }

        if (action === 11) {
            if (!directory) {
                error = true
                setErrorDirectory(true)
            }
        }

        if (action && action !== 9) {
            if (!detail) {
                error = true
                setErrorDetail(true)
            }
        }
        return error
    }

    const handleSubmit = () => {
        if (!validateForm()) {
            setLoading(true)
            if (action === 3) {
                let body = {
                    action: 0,
                    complaints_id: props.complaint_id,
                    detail: detail,
                    directory_of_municipalities_id: Number(localStorage.directory_of_municipalities_id),
                }
                axiosAuthen
                    .put(PUT_COMPLAINT_CHANGE_DIRECTORY, body)
                    .then(() => {
                        setLoading(false)
                        alert({
                            type: 'success',
                            title: 'บันทึกเสร็จสิ้น',
                            timer: 1500,
                            onClosed: () => {
                                history.goBack()
                            },
                        })
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            } else {
                let body = {
                    complaint_status_id: action,
                    complaints_id: props.complaint_id,
                    directory_of_municipalities_id: directory,
                    on_site_date: on_site_date,
                    processing_end_date: end_date,
                    processing_start_date: start_date,
                    processing_time: duration?.text,
                    remark: detail,
                    year: fiscal_year,
                }
                axiosAuthen.post(POST_COMPLAINT_ACCEPT_COMPLAINT, body).then(() => {
                    setLoading(false)
                    alert({
                        type: 'success',
                        title: 'บันทึกเสร็จสิ้น',
                        timer: 1500,
                        onClosed: () => {
                            handleClose()
                            props.reload && props.reload()
                        },
                    })
                })
            }
        }
    }
    return (
        <Fragment>
            <CustomModal
                icon='file alternate'
                header='ตอบรับ - รับเรื่องร้องทุกข์'
                open={open}
                trigger={
                    <ButtonIconOver fluid label='รับเรื่อง' color='#70B996' icon='check' basic onClick={handleOpen} />
                }
            >
                <Form loading={loading}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                            <Grid.Column computer='12' tablet='16' mobile='16' only='computer'>
                                <label className='header-input-form'>ดำเนินการ</label>
                                <Dropdown
                                    fluid
                                    selection
                                    options={action_option}
                                    value={action}
                                    onChange={handleChangeAction}
                                />
                            </Grid.Column>
                            <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                        </Grid.Row>
                        {action === 9 && (
                            <Fragment>
                                <Grid.Row>
                                    <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                                    <Grid.Column computer='12' tablet='16' mobile='16' only='computer'>
                                        <Form.Field error={errors_duration}>
                                            <label className='header-input-form'>ระยะเวลาดำเนินการ</label>
                                            <Dropdown
                                                fluid
                                                selection
                                                options={duration_option}
                                                value={duration?.value}
                                                onChange={handleChangeDuration}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                                    <Grid.Column computer='6' tablet='16' mobile='16' only='computer'>
                                        <Form.Field error={errors_start_date}>
                                            <label className='header-input-form'>วันที่เริ่มดำเนินการ</label>
                                            <DatePicker
                                                fluid
                                                name='start_date'
                                                value={start_date}
                                                onChange={handleChangeStartDate}
                                                startDate={dis_start_date}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='6' tablet='16' mobile='16' only='computer'>
                                        <Form.Field error={errors_end_date}>
                                            <label className='header-input-form'>สิ้นสุดวันที่ดำเนินการ</label>
                                            <Input fluid readOnly value={end_date} />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                                    <Grid.Column computer='12' tablet='16' mobile='16' only='computer'>
                                        <Form.Field error={errors_on_site_date}>
                                            <label className='header-input-form'>วันที่ลงพื้นที่</label>
                                            <DatePicker
                                                fluid
                                                name='on_site_date'
                                                value={on_site_date}
                                                onChange={handleChangeOnSiteDate}
                                                startDate={start_date}
                                                endDate={end_date}
                                                showTodayBtn={false}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                                </Grid.Row>
                            </Fragment>
                        )}
                        {action === 6 && (
                            <Grid.Row>
                                <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                                <Grid.Column computer='12' tablet='16' mobile='16' only='computer'>
                                    <Form.Field error={errors_fiscal_year}>
                                        <label className='header-input-form'>ระบุปีงบประมาณ</label>
                                        <Input
                                            fluid
                                            value={fiscal_year}
                                            onChange={handleChangeFiscalYear}
                                            maxLength={4}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                            </Grid.Row>
                        )}
                        {action === 11 && (
                            <Grid.Row>
                                <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                                {directory_option.length > 0 && (
                                    <Grid.Column computer='12' tablet='16' mobile='16' only='computer'>
                                        <Form.Field error={errors_directory}>
                                            <label className='header-input-form'>หน่วยงาน</label>
                                            <Dropdown
                                                fluid
                                                selection
                                                options={directory_option}
                                                value={directory}
                                                onChange={handleChangeDirectory}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                )}
                                <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                            </Grid.Row>
                        )}
                        {action && action !== 9 && (
                            <Grid.Row>
                                <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />

                                <Grid.Column computer='12' tablet='16' mobile='16' only='computer'>
                                    <Form.Field error={errors_detail}>
                                        <label className='header-input-form'>รายละเอียด</label>
                                        <TextArea value={detail} onChange={handleChangeDetail} />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default AcceptComplaint
