import { HOST } from '../router'

// TODO: COMMON API
export const LOGIN = `${HOST}/login`
export const LOGIN_SUPER_ADMIN = `${HOST}/login_super_admin`
export const LOGIN_APPLICATION = `${HOST}/login_application`
export const LOGIN_ONE_ID = `${HOST}/login_one_id`
export const LOGOUT = `${HOST}/logout`
export const REFRESH_TOKEN = `${HOST}/refresh`

//manage department
export const GET_LIST_DEPARTMENT = `${HOST}/data_manage_departments`
export const GET_DEPARTMENT_BY_ID = `${HOST}/detail_manage_department`
export const POST_CREATE_AND_EDIT_DEPARTMENT = `${HOST}/manage_departments`
export const PUT_EDIT_DEPARTMENT = `${HOST}/manage_sub_division`
export const DELETE_DEPARTMENT = `${HOST}/delete_departments`
export const DELETE_SUBDIVISION = `${HOST}/delete_sub_divisions`
export const DATA_TOPIC_AND_TYPE = `${HOST}/data_topic_and_type_topic_all`
