import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Breadcrumb, Container, Grid, Image, Segment } from 'semantic-ui-react'
import { alert } from '../../../../../components/alert'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import CustomSegment from '../../../../../components/CustomSegment'
import { GET_USER_DETAIL_BY_ID, PUT_USER_APPROVE_AND_DISAPPROVE } from '../../../../../config/api/user'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { HOST } from '../../../../../config/router'

interface InterfaceUserDetail {
    alley: string
    birth_date: string
    directory_of_municipalities_name: string
    district: string
    email: string
    first_name_en: string
    first_name_th: string
    house_no: string
    id_card_number: string
    image: string
    last_name_en: string
    last_name_th: string
    municipalities_name: string
    one_mail: string
    phone_number: string
    position: string
    prefix_en: string
    prefix_th: string
    province: string
    road: string
    roles_name: string
    sub_district: string
    username: string
    village_no: string
    zipcode: string
}

const ManagementApproveUsersDetail: React.FC = () => {
    let location = useLocation()
    let history = useHistory()
    let [user_data, setUserDetail] = useState<InterfaceUserDetail>()
    let [loading, setLoading] = useState<boolean>(true)
    let [user_id, setUserId] = useState<number>()

    useEffect(() => {
        let { state } = location
        let obj = JSON.parse(JSON.stringify(state, null, ' '))
        let { user_id } = obj
        setUserId(user_id)
        axiosAuthen.get(`${GET_USER_DETAIL_BY_ID}/${user_id}`).then((response) => {
            let { value } = response.data
            setUserDetail(value)
            setLoading(false)
        })
        window.scrollTo(0, 0)
    }, [location])

    const handleApprove = (action: boolean) => {
        setLoading(true)
        axiosAuthen
            .put(PUT_USER_APPROVE_AND_DISAPPROVE, {
                approve: action,
                id: user_id,
            })
            .then(() => {
                setLoading(false)
                alert({
                    type: 'success',
                    title: 'บันทึกเสร็จสิ้น',
                    timer: 1500,
                    onClosed: () => {
                        history.goBack()
                    },
                })
            })
            .catch((error) => {
                setLoading(false)
                alert({
                    type: 'error',
                    title: 'บันทึกไม่สำเร็จ',
                    text: error.message,
                    timer: 1500,
                })
            })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/management/approve-users`, {
                                                // municipality_id: municipality_id,
                                            })
                                        }}>
                                        รายการอนุมัติสมาชิก
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>แก้ไขข้อมูลองค์กร</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16' textAlign='center'>
                                <h2>ข้อมูลสมาชิก</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16'>
                                <CustomSegment icon='user circle' header='รายละเอียดสมาชิก' loading={loading}>
                                    <Grid divided>
                                        <Grid.Row>
                                            <Grid.Column computer='12'>
                                                <Grid style={{ margin: 5 }}>
                                                    <Grid.Row>
                                                        <Grid.Column computer='4' only='computer'>
                                                            <div style={{ justifyContent: 'center' }}>
                                                                {user_data?.image && (
                                                                    <Image
                                                                        bordered
                                                                        style={{
                                                                            width: 150,
                                                                            height: 150,
                                                                            borderRadius: 500,
                                                                        }}
                                                                        src={`${HOST}${user_data?.image}`}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column tablet='4' mobile='16' only='tablet mobile'>
                                                            <Segment
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    zIndex: 1,
                                                                    marginTop: 30,
                                                                }}>
                                                                <div
                                                                    style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                                    <div style={{ justifyContent: 'center' }}>
                                                                        {user_data?.image && (
                                                                            <Image
                                                                                bordered
                                                                                style={{
                                                                                    width: 150,
                                                                                    height: 150,
                                                                                    borderRadius: 500,
                                                                                }}
                                                                                src={`${HOST}${user_data?.image}`}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column computer='12'>
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <h2>{`${user_data?.prefix_th}${user_data?.first_name_th} ${user_data?.last_name_th}`}</h2>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='4'>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            สิทธิ์การใช้งาน
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='12'>
                                                                        {user_data?.roles_name}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='4'>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            หมายเลขโทรศัพท์
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='12'>
                                                                        {user_data?.phone_number
                                                                            ? user_data.phone_number
                                                                            : '-'}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='4'>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            ตำแหน่งงาน
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='12'>
                                                                        {user_data?.position ? user_data.position : '-'}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='4'>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            องค์กร
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='12'>
                                                                        {user_data?.municipalities_name}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='4'>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            สังกัดหน่วยงาน
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='12'>
                                                                        {user_data?.directory_of_municipalities_name}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column computer='4'>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        height: '100%',
                                                    }}>
                                                    <div style={{ padding: 5 }}>
                                                        <ButtonIconOver
                                                            color='#75B9A5'
                                                            fluid
                                                            icon='check'
                                                            label='อนุมัติ'
                                                            onClick={() => {
                                                                handleApprove(true)
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ padding: 5 }}>
                                                        <ButtonIconOver
                                                            color='#9F3A38'
                                                            fluid
                                                            icon='remove'
                                                            label='ไม่อนุมัติ'
                                                            onClick={() => {
                                                                handleApprove(false)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}
export default ManagementApproveUsersDetail
