import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Button,
    Checkbox,
    Container,
    Dropdown,
    DropdownProps,
    Form,
    Grid,
    InputOnChangeData,
    Radio,
    Segment,
} from 'semantic-ui-react'
import { alert } from '../../../../components/alert'
import CustomSegment from '../../../../components/CustomSegment'
import DatePicker from '../../../../components/DatePicker'
import { GET_COMPLAINT_TOPIC_BY_DIRECTORY } from '../../../../config/api/complaintTopicAndTypes'
import { GET_CONTACT_ALL } from '../../../../config/api/contacts'
import { GET_DASHBOARD_YEARS } from '../../../../config/api/dashboard'
import {
    GET_DIRECTORY_OF_MUNICIPALITY_BY_ID,
    GET_DIRECTORY_OF_MUNICIPALITY_BY_ROLE,
    GET_TECHNICIAN_BY_DIRECTORY,
} from '../../../../config/api/directoryOfMunicipality'
import {
    GENERATE_EXCEL_SUMMARY,
    GENERATE_EXCEL_TECHNICIAN,
    GENERATE_EXCEL_FULL_YEAR,
    GENERATE_EXCEL_APPROVAL,
} from '../../../../config/api/export'
import { axiosAuthen } from '../../../../config/axiosAuthen'

const exportList = [
    { key: 'รายงานคำร้องทุกข์', value: 'complaint_report' },
    { key: 'เลือกรายงานบุคคล', value: 'technician_report' },
    { key: 'รายงานประจำปีงบประมาณ', value: 'fiscal_year_report' },
    { key: 'รายงานอนุมัติผลการดำเนินการ', value: 'approval_report' },
]

const statusApprovalList = [
    { key: 'คำร้องที่รออนุมัติ', value: 'waiting' },
    { key: 'คำร้องที่อนุมัติ', value: 'approve' },
    // { key: 'คำร้องที่ไม่อนุมัติ', value: 'disapproved' },
]

const exportTypeList = [
    { key: 'รายงานประจำปี', value: 'fiscal_year' },
    { key: 'รายงานช่วงเวลา', value: 'time_report' },
    { key: 'รายงานปีงบประมาณย้อนหลัง', value: 'previous_fiscal' },
]

const statusList = [
    { key: 'คำร้องทุกข์ที่ดำเนินการเสร็จสิ้น', value: 'status_success' },
    { key: 'คำร้องทุกข์ที่อยู่ระหว่างติดตาม', value: 'status_tracking' },
    { key: 'คำร้องทุกข์ที่เกินกำหนด', value: 'status_overdue' },
    { key: 'คำร้องทุกข์ที่ดำเนินการไม่เสร็จสิ้น', value: 'status_unsuccess' },
    { key: 'คำร้องทุกข์ที่ไม่สามารถดำเนินการได้', value: 'status_unable' },
    { key: 'คำร้องทุกข์ที่เข้าแผนปีงบประมาณ', value: 'status_fiscal_year' },
    { key: 'คำร้องทุกข์ที่ใกล้ครบกำหนด', value: 'status_deadline' },
]

const reportSummary = [
    { key: 'ผลการดำเนินการ', value: 'performance' },
    { key: 'ผลการอนุมัติคำร้องทุกข์', value: 'approval_result' },
    { key: 'แสดงผู้ที่รับผิดชอบคำร้อง', value: 'responsible' },
]

const headerDetail = [
    { text: 'ทั้งหมด', value: 'all' },
    { text: 'เลขที่รับส่วนกลาง', value: 'central_complaint_no' },
    { text: 'เลขที่รับหน่วยงาน', value: 'complaint_no' },
    { text: 'วัน / เดือน / ปี ร้องทุกข์', value: 'date' },
    { text: 'ชื่อผู้ร้องทุกข์', value: 'complainant_name' },
    { text: 'โทรศัพท์', value: 'phone_no' },
    { text: 'ที่อยู่', value: 'address' },
    { text: 'เขต', value: 'area' },
    { text: 'ชุมชน', value: 'community' },
    { text: 'หัวเรื่อง', value: 'topic' },
    { text: 'เรื่องร้องทุกข์', value: 'complaint_topics' },
    { text: 'ประเภทงาน', value: 'complaint_types' },
    { text: 'รายละเอียด', value: 'complaint_detail' },
    { text: 'สถานะ', value: 'complaint_status' },
    { text: 'หมายเหตุ', value: 'remark' },
]

// const optionMonthTH = [
//     { value: '01', text: 'มกราคม' },
//     { value: '02', text: 'กุมภาพันธ์' },
//     { value: '03', text: 'มีนาคม' },
//     { value: '04', text: 'เมษายน' },
//     { value: '05', text: 'พฤษภาคม' },
//     { value: '06', text: 'มิถุนายน' },
//     { value: '07', text: 'กรกฎาคม' },
//     { value: '08', text: 'สิงหาคม' },
//     { value: '09', text: 'กันยายน' },
//     { value: '10', text: 'ตุลาคม' },
//     { value: '11', text: 'พฤษจิกายน' },
//     { value: '12', text: 'ธันวาคม' },
// ]

const ExportExcel: React.FC = () => {
    let history = useHistory()
    let [loading, setLoading] = useState<boolean>(false)
    let [year, setYear] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [year_option, setYearOption] = useState<any[]>([])
    let [directory_id, setDirectoryId] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >('')
    let [low_directory_id, setLowDirectoryId] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >('')
    let [low_directory_id2, setLowDirectoryId2] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >('')
    let [directory_option, setDirectoryOption] = useState<any[]>([])
    let [low_directory_id_option, setLowDirectoryIdOption] = useState<any[]>([])
    let [low_directory_id_option2, setLowDirectoryIdOption2] = useState<any[]>([])
    let [technician_option, setTechnicianOption] = useState<any[]>([])
    let [technician, setTechnician] = useState<string | number | boolean | (string | number | boolean)[] | undefined>(
        []
    )
    let [topic, setTopic] = useState<string | number | boolean | (string | number | boolean)[] | undefined>([])
    let [topic_option, setTopicOption] = useState<any[]>([])
    let [contact, setContact] = useState<string | number | boolean | (string | number | boolean)[] | undefined>([])
    let [contact_option, setContactOption] = useState<any[]>([])
    // let [start_month, setStartMonth] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    // let [end_month, setEndMonth] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [start_date, setStartDate] = useState<string>('')
    let [end_date, setEndDate] = useState<string>('')
    let [header_detail, setHeaderDetail] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >([])
    let [select_export, setSelectExport] = useState<string>('')
    let [select_type_export, setSelectTypeExport] = useState<string>('')
    let [select_status, setSelectStatus] = useState<any[]>([])
    let [select_status_approve, setSelectStatusApprove] = useState<any[]>([])
    let [select_report_summary, setSelectReportSummary] = useState<any[]>([])
    let [error_select_export, setErrorSelectExport] = useState<boolean>(false)
    let [error_select_type_export, setErrorSelectTypeExport] = useState<boolean>(false)
    let [error_select_status, setErrorSelectStatus] = useState<boolean>(false)
    let [error_select_status_approve, setErrorSelectStatusApprove] = useState<boolean>(false)
    let [error_technician, setErrorTechnician] = useState<boolean>(false)
    let [error_start_date, setErrorStartDate] = useState<boolean>(false)
    let [error_end_date, setErrorEndDate] = useState<boolean>(false)
    let [error_year, setErrorYear] = useState<boolean>(false)
    let [error_topic, setErrorTopic] = useState<boolean>(false)
    let [error_contact, setErrorContact] = useState<boolean>(false)
    let [error_header_detail, setErrorHeaderDetail] = useState<boolean>(false)
    let [error_report_summary, setErrorReportSummary] = useState<boolean>(false)
    let [error_directory, setErrorDirectory] = useState<boolean>(false)
    let permission = localStorage.permission_en
    let text_error = 'กรุณากรอกข้อมูลให้ครบ'

    useEffect(() => {
        if (select_type_export === 'fiscal_year') {
            let date = new Date()
            let value = date.getFullYear() + 543
            setYearOption([{ text: String(value), value: value }])
        } else {
            axiosAuthen.get(GET_DASHBOARD_YEARS).then((response) => {
                let { value } = response.data
                let year_option = value.map((data: any) => ({ value: data, text: data }))
                setYearOption(year_option)
            })
        }
    }, [select_type_export])

    const handleSearchYear = (_: any, { value }: DropdownProps) => {
        setYear(value)
        setErrorYear(false)
    }

    const handleChangeExport = (data: string) => {
        setSelectExport(data)
        setSelectReportSummary([])
        setDirectoryId('')
        setLowDirectoryId('')
        setLowDirectoryId2('')
        setSelectStatus([])
        setSelectStatusApprove([])
        setTechnician([])
        setErrorSelectExport(false)
    }

    const handleChangeTypeExport = (data: string) => {
        setSelectTypeExport(data)
        setErrorSelectTypeExport(false)
    }

    const handleSelectTopic = (_: any, { value }: DropdownProps) => {
        if (Array.isArray(value)) {
            let check_all = value.some((item) => item === 'all')
            if (check_all) {
                let all_value = topic_option.map((option) => option.value)
                let data = all_value.filter((detail) => detail !== 'all')
                let option = topic_option.filter((option) => option.value !== 'all')
                setTopicOption(option)
                setTopic(data)
                setErrorTopic(false)
            } else {
                let option = topic_option.some((option) => option.value === 'all')
                if (!option) {
                    setTopicOption([{ text: 'ทั้งหมด', value: 'all' }, ...topic_option])
                }
                setTopic(value)
                setErrorTopic(false)
            }
        }
    }

    const handleSelectContact = (_: any, { value }: DropdownProps) => {
        if (Array.isArray(value)) {
            let check_all = value.some((item) => item === 'all')
            if (check_all) {
                let all_value = contact_option.map((option) => option.value)
                let data = all_value.filter((detail) => detail !== 'all')
                let option = contact_option.filter((option) => option.value !== 'all')
                setContactOption(option)
                setContact(data)
                setErrorContact(false)
            } else {
                let option = contact_option.some((option) => option.value === 'all')
                if (!option) {
                    setContactOption([{ text: 'ทั้งหมด', value: 'all' }, ...contact_option])
                }
                setContact(value)
                setErrorContact(false)
            }
        }
    }

    const handleChangeStatusApprove = (data: string) => {
        if (select_status_approve.some((select) => select === data)) {
            let select = select_status_approve.filter((select: string) => select !== data)
            setSelectStatusApprove(select)
            setErrorSelectStatusApprove(false)
        } else {
            setSelectStatusApprove([...select_status_approve, data])
            setErrorSelectStatusApprove(false)
        }
    }

    const handleChangeStatus = (data: string) => {
        if (select_status.some((select) => select === data)) {
            let select = select_status.filter((select: string) => select !== data)
            setSelectStatus(select)
            setErrorSelectStatus(false)
        } else {
            setSelectStatus([...select_status, data])
            setErrorSelectStatus(false)
        }
    }

    const handleChangeReportSummary = (data: string) => {
        if (select_report_summary.some((select) => select === data)) {
            let select = select_report_summary.filter((select: string) => select !== data)
            setSelectReportSummary(select)
            setErrorReportSummary(false)
        } else {
            setSelectReportSummary([...select_report_summary, data])
            setErrorReportSummary(false)
        }
    }

    const handleChangeDirectory = (_: any, { value }: DropdownProps) => {
        setDirectoryId(value)
        setLowDirectoryId('')
        setLowDirectoryId2('')
        setErrorDirectory(false)
    }

    const handleChangeDirectoryById = (_: any, { value }: DropdownProps) => {
        setLowDirectoryId(value)
    }

    const handleChangeDirectoryById2 = (_: any, { value }: DropdownProps) => {
        setLowDirectoryId2(value)
    }

    const handleChangeStartDate = (_: any, { value }: InputOnChangeData) => {
        setStartDate(value)
        setErrorStartDate(false)
    }

    const handleChangeEndDate = (_: any, { value }: InputOnChangeData) => {
        setEndDate(value)
        setErrorEndDate(false)
    }

    const handleChangeTechnician = (_: any, { value }: DropdownProps) => {
        if (permission !== 'technician') {
            if (Array.isArray(value)) {
                let check_all = value.some((item) => item === 'all')
                if (check_all) {
                    let all_value = technician_option.map((option) => option.value)
                    let data = all_value.filter((name) => name !== 'all')
                    let option = technician_option.filter((option) => option.value !== 'all')
                    setTechnicianOption(option)
                    setTechnician(data)
                    setErrorTechnician(false)
                } else {
                    let option = technician_option.some((option) => option.value === 'all')
                    if (!option) {
                        setTechnicianOption([{ text: 'ทั้งหมด', value: 'all' }, ...technician_option])
                    }
                    setTechnician(value)
                    setErrorTechnician(false)
                }
            }
        } else {
            setTechnicianOption([...technician_option])
            setTechnician(value)
            setErrorTechnician(false)
        }
    }

    const handleSelectHeaderDetail = (_: any, { value }: DropdownProps) => {
        if (Array.isArray(value)) {
            let check_all = value.some((item) => item === 'all')
            if (check_all) {
                let all_value = headerDetail.map((option) => option.value)
                let data = all_value.filter((detail) => detail !== 'all')
                setHeaderDetail(data)
                setErrorHeaderDetail(false)
            } else {
                let option = headerDetail.some((option) => option.value === 'all')
                if (!option) {
                    setContactOption([...headerDetail])
                }
                setHeaderDetail(value)
                setErrorHeaderDetail(false)
            }
        }
    }

    const handleClearFilter = () => {
        setYear('')
        // setStartMonth('')
        // setEndMonth('')
        setStartDate('')
        setEndDate('')
        setSelectExport('')
        setSelectTypeExport('')
        setDirectoryId('')
        setLowDirectoryId('')
        setLowDirectoryId2('')
        setContact([])
        setTopic([])
        setSelectStatus([])
        setHeaderDetail([])
        setSelectStatusApprove([])
        setSelectReportSummary([])
        setTechnician([])
        setErrorSelectExport(false)
        setErrorSelectTypeExport(false)
        setErrorSelectStatus(false)
        setErrorSelectStatusApprove(false)
        setErrorTechnician(false)
        setErrorStartDate(false)
        setErrorEndDate(false)
        setErrorYear(false)
        setErrorTopic(false)
        setErrorContact(false)
        setErrorHeaderDetail(false)
        setErrorReportSummary(false)
        setErrorDirectory(false)
    }

    useEffect(() => {
        const getDirectory = () => {
            axiosAuthen.get(`${GET_DIRECTORY_OF_MUNICIPALITY_BY_ROLE}`).then((response) => {
                let { value } = response.data
                let directory_option = value.map((data: any) => ({
                    value: data.id,
                    text: data.name,
                }))
                setDirectoryOption(directory_option)
            })
        }

        const getTopics = () => {
            axiosAuthen.get(`${GET_COMPLAINT_TOPIC_BY_DIRECTORY}`).then((response) => {
                setTopicOption([
                    { text: 'ทั้งหมด', value: 'all' },
                    ...response.data.value.map((data: any) => ({
                        value: data.id,
                        text: data.name,
                    })),
                ])
            })
        }

        const getContact = () => {
            axiosAuthen.get(`${GET_CONTACT_ALL}`).then((response) => {
                setContactOption([
                    { text: 'ทั้งหมด', value: 'all' },
                    ...response.data.value.map((data: any) => ({
                        value: data.id,
                        text: data.name,
                    })),
                ])
            })
        }
        getDirectory()
        getTopics()
        getContact()
    }, [])

    useEffect(() => {
        if (permission !== 'technician') {
            if (!!directory_id) {
                axiosAuthen.get(`${GET_DIRECTORY_OF_MUNICIPALITY_BY_ID}/${directory_id}`).then((response) => {
                    let { value } = response.data
                    let low_directory_option = value.map((data: any) => ({
                        value: data.id,
                        text: data.name,
                    }))
                    setLowDirectoryIdOption(low_directory_option)
                })
            }

            if (!!low_directory_id && !!directory_id) {
                axiosAuthen.get(`${GET_DIRECTORY_OF_MUNICIPALITY_BY_ID}/${low_directory_id}`).then((response) => {
                    let { value } = response.data
                    let low_directory_option2 = value.map((data: any) => ({
                        value: data.id,
                        text: data.name,
                    }))
                    setLowDirectoryIdOption2(low_directory_option2)
                })
            }

            if (!!low_directory_id2 || !!low_directory_id || !!directory_id) {
                axiosAuthen
                    .get(`${GET_TECHNICIAN_BY_DIRECTORY}/${low_directory_id2 || low_directory_id || directory_id}`)
                    .then((response) => {
                        setTechnicianOption([
                            { text: 'ทั้งหมด', value: 'all' },
                            ...response.data.value.map((data: any) => ({
                                value: data.id,
                                text: data.full_name_th,
                            })),
                        ])
                    })
            }
        } else {
            setTechnicianOption([{ text: localStorage.name, value: parseInt(localStorage.user_id, 10) }])
        }
    }, [low_directory_id2, low_directory_id, directory_id, permission])

    const validateForm = () => {
        let error = false
        if (!select_export) {
            error = true
            text_error = 'กรุณาเลือกรายงาน'
            setErrorSelectExport(error)
        }

        if (!select_type_export) {
            error = true
            text_error = 'กรุณาเลือกประเภทรายงาน'
            setErrorSelectTypeExport(error)
        }

        if (!year) {
            error = true
            text_error = 'กรุณาเลือกปีงบประมาณ'
            setErrorYear(error)
        }

        if (select_type_export === 'time_report') {
            // if (!start_month) {
            //     error = true
            //     text_error = 'กรุณาเลือกเดือนเริ่มต้น'
            //     setErrorStartDate(error)
            // }
            // if (!end_month) {
            //     error = true
            //     text_error = 'กรุณาเลือกเดือนสิ้นสุด'
            //     setErrorEndDate(error)
            // }
            // if (start_month === end_month) {
            //     error = true
            //     text_error = 'กรุณาเลือกเดือนต่างกัน'
            //     setErrorEndDate(error)
            // }
            if (start_date === '') {
                error = true
                text_error = 'กรุณาเลือกวันเริ่มต้น'
                setErrorStartDate(error)
            }
            if (end_date === '') {
                error = true
                text_error = 'กรุณาเลือกวันสิ้นสุด'
                setErrorEndDate(error)
            }
            if (start_date === end_date) {
                error = true
                text_error = 'กรุณาเลือกวันที่ต่างกัน'
                setErrorEndDate(error)
            }
        }

        if (select_export === 'complaint_report' || select_export === 'technician_report') {
            if (select_status.length === 0) {
                error = true
                text_error = 'กรุณาเลือกสถานะอย่างน้อย 1 ตัวเลือก'
                setErrorSelectStatus(error)
            }
        } else if (select_export === 'approval_report') {
            if (select_status_approve.length === 0) {
                error = true
                text_error = 'กรุณาเลือกหัวข้อรายงานอย่างน้อย 1 ตัวเลือก'
                setErrorSelectStatusApprove(error)
            }
        }

        if (Array.isArray(topic) && topic.length === 0) {
            error = true
            text_error = 'กรุณาเลือกเรื่องร้องทุกข์อย่างน้อย 1 ตัวเลือก'
            setErrorTopic(error)
        }

        if (Array.isArray(contact) && contact.length === 0) {
            error = true
            text_error = 'กรุณาเลือกช่องทางการรับเรื่องอย่างน้อย 1 ตัวเลือก'
            setErrorContact(error)
        }

        if (select_export === 'technician_report') {
            if (Array.isArray(technician) && technician.length === 0) {
                error = true
                setErrorTechnician(error)
            }
            if (permission !== 'technician') {
                if (!directory_id) {
                    error = true
                    text_error = 'กรุณาเลือกเจ้าหน้าที่อย่างน้อย 1 ตัวเลือก'
                    setErrorDirectory(error)
                }
            }
        }
        if (select_export !== 'fiscal_year_report') {
            if (Array.isArray(header_detail) && header_detail.length === 0) {
                error = true
                text_error = 'กรุณาเลือกรายละเอียดคำร้องอย่างน้อย 1 ตัวเลือก'
                setErrorHeaderDetail(error)
            }

            if (select_report_summary.length === 0) {
                error = true
                text_error = 'กรุณาผลสรุปรายงานอย่างน้อย 1 ตัวเลือก'
                setErrorReportSummary(error)
            }
        }

        return error
    }

    const FormExportReport = () => {
        let data_start_date = ''
        let data_end_date = ''
        // if (start_month) {
        //     data_start_date = String(start_month) + '/' + year
        // }

        // if (end_month) {
        //     data_end_date = String(end_month) + '/' + year
        // }
        if (start_date) {
            data_start_date = start_date
        }

        if (end_date) {
            data_end_date = end_date
        }

        let status_success = false
        let status_unsuccess = false
        let status_fiscal_year = false
        let status_tracking = false
        let status_deadline = false
        let status_overdue = false
        let status_unable = false

        select_status.forEach((data) => {
            if (data === 'status_success') {
                status_success = true
            }
            if (data === 'status_unsuccess') {
                status_unsuccess = true
            }
            if (data === 'status_fiscal_year') {
                status_fiscal_year = true
            }
            if (data === 'status_tracking') {
                status_tracking = true
            }
            if (data === 'status_deadline') {
                status_deadline = true
            }
            if (data === 'status_overdue') {
                status_overdue = true
            }
            if (data === 'status_unable') {
                status_unable = true
            }
        })

        let status_waiting = false
        let status_approve = false
        let status_disapproval = false
        select_status_approve.forEach((data) => {
            if (data === 'waiting') {
                status_waiting = true
            }
            if (data === 'approve') {
                status_approve = true
            }
            if (data === 'disapproved') {
                status_disapproval = true
            }
        })

        let mechanic = false
        let result = false
        let approve = false
        if (select_report_summary.length > 0) {
            select_report_summary.forEach((data) => {
                if (data === 'performance') {
                    result = true
                }
                if (data === 'responsible') {
                    mechanic = true
                }
                if (data === 'responsible') {
                    approve = true
                }
            })
        }

        if (!validateForm()) {
            setLoading(true)
            let body = {
                type_report: select_type_export,
                status_success: status_success,
                status_unsuccess: status_unsuccess,
                status_fiscal_year: status_fiscal_year,
                status_tracking: status_tracking,
                status_deadline: status_deadline,
                status_overdue: status_overdue,
                status_unable: status_unable,
                start_date: data_start_date,
                end_date: data_end_date,
                fiscal_year: String(year),
                topic_id: topic,
                contact_id: contact,
                header_detail: header_detail,
                result: result,
                mechanic: mechanic,
                approve: approve,
                technician_id: technician,
                status_waiting: status_waiting,
                status_approve: status_approve,
                status_disapproval: status_disapproval,
            }
            let url_report = ''
            if (select_export === 'complaint_report') {
                url_report = GENERATE_EXCEL_SUMMARY
            } else if (select_export === 'technician_report') {
                url_report = GENERATE_EXCEL_TECHNICIAN
            } else if (select_export === 'approval_report') {
                url_report = GENERATE_EXCEL_APPROVAL
            } else if (select_export === 'fiscal_year_report') {
                url_report = GENERATE_EXCEL_FULL_YEAR
            }
            axiosAuthen
                .post(url_report, body, { headers: { ContentType: 'text/plain' }, responseType: 'arraybuffer' })
                .then((response) => {
                    let data = response.data
                    let file_name = exportList.find((data) => data.value === select_export)?.key ?? ''
                    let blobFile = new Blob([data])
                    let newFile = new File([blobFile], file_name, {
                        type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
                    })
                    const url = window.URL.createObjectURL(newFile)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `สรุป${file_name}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                    setLoading(false)
                })
                .catch((error) => {
                    if (error.response) {
                        alert({
                            type: 'error',
                            title: error.response.data.message,
                            text: 'เกิดข้อผิดพลาดในการดาวน์โหลดไฟล์',
                            cancelText: 'ปิด',
                            onClosed: () => {
                                setLoading(false)
                            },
                        })
                    }
                })
        } else {
            alert({
                type: 'error',
                // title: 'ชนิดของไฟล์ไม่ถูกต้อง',
                text: text_error,
                confirmText: 'ปิด',
                onClosed: () => {
                    setLoading(false)
                },
            })
        }
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Form loading={loading}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    <h2>ออกรายงาน</h2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <CustomSegment icon='file alternate' header='เนื้อหารายงาน'>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='1' mobile='16' />
                                                <Grid.Column computer='14' mobile='16'>
                                                    <Grid.Row>
                                                        <label className='header-input-form'>เลือกรายงาน</label>
                                                        <Grid>
                                                            {permission !== 'technician' ? (
                                                                <Grid.Row>
                                                                    {exportList.map((data, index) => (
                                                                        <Grid.Column
                                                                            key={index}
                                                                            computer='5'
                                                                            mobile='16'
                                                                            style={{ marginTop: 5 }}
                                                                        >
                                                                            <Segment
                                                                                style={
                                                                                    !error_select_export
                                                                                        ? {
                                                                                              cursor: 'pointer',
                                                                                              margin: 3,
                                                                                              border: '1px solid #CCCDCE',
                                                                                          }
                                                                                        : {
                                                                                              cursor: 'pointer',
                                                                                              margin: 3,
                                                                                              border: '2px solid #E0B4B4',
                                                                                          }
                                                                                }
                                                                                onClick={() =>
                                                                                    handleChangeExport(data.value)
                                                                                }
                                                                            >
                                                                                <Grid>
                                                                                    <Grid.Row>
                                                                                        <Grid.Column
                                                                                            computer='2'
                                                                                            mobile='2'
                                                                                            style={{
                                                                                                alignSelf: 'center',
                                                                                            }}
                                                                                        >
                                                                                            <Radio
                                                                                                checked={
                                                                                                    select_export ===
                                                                                                    data.value
                                                                                                }
                                                                                            />
                                                                                        </Grid.Column>
                                                                                        <Grid.Column
                                                                                            computer='12'
                                                                                            mobile='12'
                                                                                            style={{
                                                                                                alignSelf: 'center',
                                                                                            }}
                                                                                        >
                                                                                            {data.key}
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                </Grid>
                                                                            </Segment>
                                                                        </Grid.Column>
                                                                    ))}
                                                                </Grid.Row>
                                                            ) : (
                                                                <Grid.Row>
                                                                    <Grid.Column
                                                                        computer='5'
                                                                        mobile='16'
                                                                        style={{ marginTop: 5 }}
                                                                    >
                                                                        <Segment
                                                                            style={
                                                                                !error_select_export
                                                                                    ? {
                                                                                          cursor: 'pointer',
                                                                                          margin: 3,
                                                                                          border: '1px solid #CCCDCE',
                                                                                      }
                                                                                    : {
                                                                                          cursor: 'pointer',
                                                                                          margin: 3,
                                                                                          border: '2px solid #E0B4B4',
                                                                                      }
                                                                            }
                                                                            onClick={() =>
                                                                                handleChangeExport('technician_report')
                                                                            }
                                                                        >
                                                                            <Grid>
                                                                                <Grid.Row>
                                                                                    <Grid.Column
                                                                                        computer='2'
                                                                                        mobile='2'
                                                                                        style={{
                                                                                            alignSelf: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <Radio
                                                                                            checked={
                                                                                                select_export ===
                                                                                                'technician_report'
                                                                                            }
                                                                                        />
                                                                                    </Grid.Column>
                                                                                    <Grid.Column
                                                                                        computer='12'
                                                                                        mobile='12'
                                                                                        style={{
                                                                                            alignSelf: 'center',
                                                                                        }}
                                                                                    >
                                                                                        รายงานบุคคล
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )}
                                                        </Grid>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <label className='header-input-form'>ประเภทรายงาน</label>
                                                        <Grid>
                                                            <Grid.Row>
                                                                {exportTypeList.map((data, index) => (
                                                                    <Grid.Column
                                                                        key={index}
                                                                        computer='5'
                                                                        mobile='16'
                                                                        style={{ marginTop: 5 }}
                                                                    >
                                                                        <Segment
                                                                            style={
                                                                                !error_select_type_export
                                                                                    ? {
                                                                                          cursor: 'pointer',
                                                                                          margin: 3,
                                                                                          border: '1px solid #CCCDCE',
                                                                                      }
                                                                                    : {
                                                                                          cursor: 'pointer',
                                                                                          margin: 3,
                                                                                          border: '2px solid #E0B4B4',
                                                                                      }
                                                                            }
                                                                            onClick={() =>
                                                                                handleChangeTypeExport(data.value)
                                                                            }
                                                                        >
                                                                            <Grid>
                                                                                <Grid.Row>
                                                                                    <Grid.Column
                                                                                        computer='2'
                                                                                        mobile='2'
                                                                                        style={{
                                                                                            alignSelf: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <Radio
                                                                                            checked={
                                                                                                select_type_export ===
                                                                                                data.value
                                                                                            }
                                                                                        />
                                                                                    </Grid.Column>
                                                                                    <Grid.Column
                                                                                        computer='12'
                                                                                        mobile='12'
                                                                                        style={{
                                                                                            alignSelf: 'center',
                                                                                        }}
                                                                                    >
                                                                                        {data.key}
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                ))}
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        {select_type_export === 'fiscal_year' && (
                                                            <Grid>
                                                                <Grid.Column computer='16' mobile='16'>
                                                                    <label className='header-input-form'>
                                                                        เลือกปีงบประมาณ
                                                                    </label>
                                                                    <Dropdown
                                                                        error={error_year}
                                                                        name='year'
                                                                        selection
                                                                        fluid
                                                                        value={year}
                                                                        options={year_option}
                                                                        onChange={handleSearchYear}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid>
                                                        )}

                                                        {select_type_export === 'previous_fiscal' && (
                                                            <Grid>
                                                                <Grid.Column computer='16' mobile='16'>
                                                                    <label className='header-input-form'>
                                                                        เลือกปีงบประมาณ
                                                                    </label>
                                                                    <Dropdown
                                                                        error={error_year}
                                                                        name='year'
                                                                        selection
                                                                        fluid
                                                                        value={year}
                                                                        options={year_option}
                                                                        onChange={handleSearchYear}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid>
                                                        )}

                                                        {select_type_export === 'time_report' && (
                                                            <Grid.Column computer='16' mobile='16'>
                                                                <Grid.Row>
                                                                    <label className='header-input-form'>
                                                                        เลือกปีงบประมาณ
                                                                    </label>
                                                                    <Dropdown
                                                                        name='year'
                                                                        error={error_year}
                                                                        selection
                                                                        fluid
                                                                        value={year}
                                                                        options={year_option}
                                                                        onChange={handleSearchYear}
                                                                    />
                                                                </Grid.Row>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column computer='8' mobile='8'>
                                                                            <Form.Field error={error_start_date}>
                                                                                <label className='header-input-form'>
                                                                                    เริ่ม
                                                                                </label>
                                                                                <DatePicker
                                                                                    name='start_date'
                                                                                    fluid
                                                                                    value={start_date}
                                                                                    onChange={handleChangeStartDate}
                                                                                />
                                                                                {/* <Dropdown
                                                                                    error={error_start_date}
                                                                                    selection
                                                                                    fluid
                                                                                    clearable
                                                                                    name='start_month'
                                                                                    onChange={handleSelectStartMonth}
                                                                                    options={optionMonthTH}
                                                                                    value={start_month}
                                                                                /> */}
                                                                            </Form.Field>
                                                                        </Grid.Column>

                                                                        <Grid.Column computer='8' mobile='8'>
                                                                            <label className='header-input-form'>
                                                                                ถึง
                                                                            </label>
                                                                            <Form.Field error={error_end_date}>
                                                                                <DatePicker
                                                                                    name='end_date'
                                                                                    fluid
                                                                                    value={end_date}
                                                                                    onChange={handleChangeEndDate}
                                                                                    disabled={
                                                                                        !Boolean(start_date) ||
                                                                                        start_date === ''
                                                                                    }
                                                                                />
                                                                                {/* <Dropdown
                                                                                    error={error_end_date}
                                                                                    selection
                                                                                    fluid
                                                                                    clearable
                                                                                    name='end_month'
                                                                                    disabled={
                                                                                        !Boolean(start_month) ||
                                                                                        start_month === ''
                                                                                    }
                                                                                    onChange={handleSelectEndMonth}
                                                                                    options={optionMonthTH}
                                                                                    value={end_month}
                                                                                /> */}
                                                                            </Form.Field>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Grid.Column>
                                                        )}
                                                    </Grid.Row>
                                                    {permission !== 'technician' && (
                                                        <Grid.Row>
                                                            {select_export === 'technician_report' && (
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column computer='5' mobile='16'>
                                                                            <Form.Field>
                                                                                <label className='header-input-form'>
                                                                                    หน่วยงาน
                                                                                </label>
                                                                                <Dropdown
                                                                                    error={error_directory}
                                                                                    selection
                                                                                    fluid
                                                                                    clearable
                                                                                    name='directory'
                                                                                    options={directory_option}
                                                                                    value={directory_id}
                                                                                    onChange={handleChangeDirectory}
                                                                                />
                                                                            </Form.Field>
                                                                        </Grid.Column>
                                                                        <Grid.Column computer='5' mobile='16'>
                                                                            <Form.Field>
                                                                                <label className='header-input-form'>
                                                                                    หน่วยงานย่อย (ถ้ามี)
                                                                                </label>
                                                                                <Dropdown
                                                                                    selection
                                                                                    fluid
                                                                                    clearable
                                                                                    name='low_directory'
                                                                                    disabled={
                                                                                        !Boolean(directory_id) ||
                                                                                        directory_id === ''
                                                                                    }
                                                                                    value={low_directory_id}
                                                                                    options={low_directory_id_option}
                                                                                    onChange={handleChangeDirectoryById}
                                                                                />
                                                                            </Form.Field>
                                                                        </Grid.Column>
                                                                        {low_directory_id_option2.length < 0 ? (
                                                                            <Grid.Column computer='5' mobile='16'>
                                                                                <Form.Field>
                                                                                    <label className='header-input-form'>
                                                                                        หน่วยงานย่อยระดับที่ 2 (ถ้ามี)
                                                                                    </label>
                                                                                    <Dropdown
                                                                                        selection
                                                                                        fluid
                                                                                        name='low_directory2'
                                                                                        disabled
                                                                                    />
                                                                                </Form.Field>
                                                                            </Grid.Column>
                                                                        ) : (
                                                                            <Grid.Column computer='5' mobile='16'>
                                                                                <Form.Field>
                                                                                    <label className='header-input-form'>
                                                                                        หน่วยงานย่อยระดับที่ 2 (ถ้ามี)
                                                                                    </label>
                                                                                    <Dropdown
                                                                                        selection
                                                                                        fluid
                                                                                        clearable={
                                                                                            !Boolean(low_directory_id)
                                                                                        }
                                                                                        name='low_directory2'
                                                                                        disabled={
                                                                                            !Boolean(
                                                                                                low_directory_id
                                                                                            ) || low_directory_id === ''
                                                                                        }
                                                                                        value={low_directory_id2}
                                                                                        onChange={
                                                                                            handleChangeDirectoryById2
                                                                                        }
                                                                                        options={
                                                                                            low_directory_id_option2
                                                                                        }
                                                                                    />
                                                                                </Form.Field>
                                                                            </Grid.Column>
                                                                        )}
                                                                    </Grid.Row>
                                                                </Grid>
                                                            )}
                                                        </Grid.Row>
                                                    )}
                                                    <Grid.Row>
                                                        <Grid>
                                                            {(select_export === 'complaint_report' ||
                                                                select_export === 'technician_report' ||
                                                                select_export === 'approval_report') && (
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <label className='header-input-form'>
                                                                            หัวข้อรายงาน
                                                                        </label>
                                                                        <label style={{ color: 'red' }}>
                                                                            {' '}
                                                                            *หมายเหตุ ต้องเลือกอย่างน้อย 1 ตัวเลือก
                                                                        </label>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )}
                                                            {select_export === 'approval_report' && (
                                                                <Grid.Row>
                                                                    {statusApprovalList.map((data, index) => (
                                                                        <Grid.Column
                                                                            key={index}
                                                                            computer='5'
                                                                            mobile='16'
                                                                        >
                                                                            <Segment
                                                                                style={
                                                                                    !error_select_status_approve
                                                                                        ? {
                                                                                              cursor: 'pointer',
                                                                                              margin: 3,
                                                                                              border: '1px solid #CCCDCE',
                                                                                          }
                                                                                        : {
                                                                                              cursor: 'pointer',
                                                                                              margin: 3,
                                                                                              border: '2px solid #E0B4B4',
                                                                                          }
                                                                                }
                                                                                onClick={() =>
                                                                                    handleChangeStatusApprove(
                                                                                        data.value
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Grid>
                                                                                    <Grid.Row>
                                                                                        <Grid.Column
                                                                                            computer='2'
                                                                                            mobile='2'
                                                                                            style={{
                                                                                                alignSelf: 'center',
                                                                                            }}
                                                                                        >
                                                                                            <Checkbox
                                                                                                checked={select_status_approve.some(
                                                                                                    (select: string) =>
                                                                                                        select ===
                                                                                                        data.value
                                                                                                )}
                                                                                            />
                                                                                        </Grid.Column>
                                                                                        <Grid.Column
                                                                                            computer='12'
                                                                                            mobile='12'
                                                                                            style={{
                                                                                                alignSelf: 'center',
                                                                                            }}
                                                                                        >
                                                                                            {data.key}
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                </Grid>
                                                                            </Segment>
                                                                        </Grid.Column>
                                                                    ))}
                                                                </Grid.Row>
                                                            )}

                                                            {(select_export === 'complaint_report' ||
                                                                select_export === 'technician_report') && (
                                                                <Grid.Row>
                                                                    {statusList.map((data, index) => (
                                                                        <Grid.Column
                                                                            key={index}
                                                                            computer='5'
                                                                            mobile='16'
                                                                        >
                                                                            <Segment
                                                                                style={
                                                                                    !error_select_status
                                                                                        ? {
                                                                                              cursor: 'pointer',
                                                                                              margin: 3,
                                                                                              border: '1px solid #CCCDCE',
                                                                                          }
                                                                                        : {
                                                                                              cursor: 'pointer',
                                                                                              margin: 3,
                                                                                              border: '2px solid #E0B4B4',
                                                                                          }
                                                                                }
                                                                                onClick={() =>
                                                                                    handleChangeStatus(data.value)
                                                                                }
                                                                            >
                                                                                <Grid>
                                                                                    <Grid.Row>
                                                                                        <Grid.Column
                                                                                            computer='2'
                                                                                            mobile='2'
                                                                                            style={{
                                                                                                alignSelf: 'center',
                                                                                            }}
                                                                                        >
                                                                                            <Checkbox
                                                                                                checked={select_status.some(
                                                                                                    (select: string) =>
                                                                                                        select ===
                                                                                                        data.value
                                                                                                )}
                                                                                            />
                                                                                        </Grid.Column>
                                                                                        <Grid.Column
                                                                                            computer='12'
                                                                                            mobile='12'
                                                                                            style={{
                                                                                                alignSelf: 'center',
                                                                                            }}
                                                                                        >
                                                                                            {data.key}
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                </Grid>
                                                                            </Segment>
                                                                        </Grid.Column>
                                                                    ))}
                                                                </Grid.Row>
                                                            )}
                                                        </Grid>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid>
                                                            <Grid.Column>
                                                                <label className='header-input-form'>
                                                                    เรื่องร้องทุกข์
                                                                </label>
                                                                <Dropdown
                                                                    error={error_topic}
                                                                    name='topic_name'
                                                                    multiple
                                                                    search
                                                                    selection
                                                                    fluid
                                                                    clearable
                                                                    value={topic}
                                                                    options={topic_option}
                                                                    onChange={handleSelectTopic}
                                                                />
                                                            </Grid.Column>
                                                        </Grid>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid>
                                                            <Grid.Column>
                                                                <label className='header-input-form'>
                                                                    ช่องทางการรับเรื่อง{' '}
                                                                </label>
                                                                <Dropdown
                                                                    name='receiving_channels'
                                                                    error={error_contact}
                                                                    multiple
                                                                    search
                                                                    selection
                                                                    fluid
                                                                    clearable
                                                                    value={contact}
                                                                    options={contact_option}
                                                                    onChange={handleSelectContact}
                                                                />
                                                            </Grid.Column>
                                                        </Grid>
                                                    </Grid.Row>
                                                    {permission !== 'technician' ? (
                                                        <Grid.Row>
                                                            {select_export === 'technician_report' && (
                                                                <Grid>
                                                                    <Grid.Column>
                                                                        <label className='header-input-form'>
                                                                            เลือกเจ้าหน้าที่
                                                                        </label>
                                                                        <Dropdown
                                                                            name='technicians'
                                                                            error={error_technician}
                                                                            multiple
                                                                            search
                                                                            selection
                                                                            fluid
                                                                            clearable
                                                                            disabled={
                                                                                !Boolean(directory_id) ||
                                                                                directory_id === ''
                                                                            }
                                                                            value={technician}
                                                                            options={technician_option}
                                                                            onChange={handleChangeTechnician}
                                                                        />
                                                                    </Grid.Column>
                                                                </Grid>
                                                            )}
                                                        </Grid.Row>
                                                    ) : (
                                                        <Grid.Row>
                                                            {select_export === 'technician_report' && (
                                                                <Grid>
                                                                    <Grid.Column>
                                                                        <label className='header-input-form'>
                                                                            เลือกเจ้าหน้าที่
                                                                        </label>
                                                                        <Dropdown
                                                                            name='technicians'
                                                                            error={error_technician}
                                                                            multiple
                                                                            search
                                                                            selection
                                                                            fluid
                                                                            clearable
                                                                            value={technician}
                                                                            options={technician_option}
                                                                            onChange={handleChangeTechnician}
                                                                        />
                                                                    </Grid.Column>
                                                                </Grid>
                                                            )}
                                                        </Grid.Row>
                                                    )}

                                                    <Grid.Row>
                                                        {select_export !== 'fiscal_year_report' && (
                                                            <Grid>
                                                                <Grid.Column>
                                                                    <label className='header-input-form'>
                                                                        รายละเอียดคำร้อง (ข้อมูลที่ต้องการเเสดง){' '}
                                                                    </label>
                                                                    <Dropdown
                                                                        name='header_detail'
                                                                        error={error_header_detail}
                                                                        multiple
                                                                        clearable
                                                                        search
                                                                        selection
                                                                        fluid
                                                                        value={header_detail}
                                                                        options={headerDetail}
                                                                        onChange={handleSelectHeaderDetail}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid>
                                                        )}
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid>
                                                            {select_export === 'complaint_report' && (
                                                                <Grid.Row>
                                                                    <Grid.Column computer='16' mobile='16'>
                                                                        <label className='header-input-form'>
                                                                            ผลสรุปรายงาน
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='5' mobile='16'>
                                                                        <Segment
                                                                            style={
                                                                                !error_report_summary
                                                                                    ? {
                                                                                          cursor: 'pointer',
                                                                                          margin: 3,
                                                                                          border: '1px solid #CCCDCE',
                                                                                      }
                                                                                    : {
                                                                                          cursor: 'pointer',
                                                                                          margin: 3,
                                                                                          border: '2px solid #E0B4B4',
                                                                                      }
                                                                            }
                                                                            onClick={() =>
                                                                                handleChangeReportSummary('performance')
                                                                            }
                                                                        >
                                                                            <Grid>
                                                                                <Grid.Row>
                                                                                    <Grid.Column
                                                                                        computer='2'
                                                                                        mobile='2'
                                                                                        style={{
                                                                                            alignSelf: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <Checkbox
                                                                                            checked={select_report_summary.some(
                                                                                                (select: string) =>
                                                                                                    select ===
                                                                                                    'performance'
                                                                                            )}
                                                                                        />
                                                                                    </Grid.Column>
                                                                                    <Grid.Column
                                                                                        computer='12'
                                                                                        mobile='12'
                                                                                        style={{
                                                                                            alignSelf: 'center',
                                                                                        }}
                                                                                    >
                                                                                        ผลการดำเนินการ
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='5' mobile='16'>
                                                                        <Segment
                                                                            style={
                                                                                !error_report_summary
                                                                                    ? {
                                                                                          cursor: 'pointer',
                                                                                          margin: 3,
                                                                                          border: '1px solid #CCCDCE',
                                                                                      }
                                                                                    : {
                                                                                          cursor: 'pointer',
                                                                                          margin: 3,
                                                                                          border: '2px solid #E0B4B4',
                                                                                      }
                                                                            }
                                                                            onClick={() =>
                                                                                handleChangeReportSummary('responsible')
                                                                            }
                                                                        >
                                                                            <Grid>
                                                                                <Grid.Row>
                                                                                    <Grid.Column
                                                                                        computer='2'
                                                                                        mobile='2'
                                                                                        style={{
                                                                                            alignSelf: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <Checkbox
                                                                                            checked={select_report_summary.some(
                                                                                                (select: string) =>
                                                                                                    select ===
                                                                                                    'responsible'
                                                                                            )}
                                                                                        />
                                                                                    </Grid.Column>
                                                                                    <Grid.Column
                                                                                        computer='12'
                                                                                        mobile='12'
                                                                                        style={{
                                                                                            alignSelf: 'center',
                                                                                        }}
                                                                                    >
                                                                                        แสดงผู้ที่รับผิดชอบคำร้อง
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )}
                                                        </Grid>
                                                        <Grid>
                                                            {select_export === 'technician_report' && (
                                                                <Grid.Row>
                                                                    <Grid.Column computer='16' mobile='16'>
                                                                        <label className='header-input-form'>
                                                                            ผลสรุปรายงาน
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='5' mobile='16'>
                                                                        <Segment
                                                                            style={
                                                                                !error_report_summary
                                                                                    ? {
                                                                                          cursor: 'pointer',
                                                                                          margin: 3,
                                                                                          border: '1px solid #CCCDCE',
                                                                                      }
                                                                                    : {
                                                                                          cursor: 'pointer',
                                                                                          margin: 3,
                                                                                          border: '2px solid #E0B4B4',
                                                                                      }
                                                                            }
                                                                            onClick={() =>
                                                                                handleChangeReportSummary('performance')
                                                                            }
                                                                        >
                                                                            <Grid>
                                                                                <Grid.Row>
                                                                                    <Grid.Column
                                                                                        computer='2'
                                                                                        mobile='2'
                                                                                        style={{
                                                                                            alignSelf: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <Checkbox
                                                                                            checked={select_report_summary.some(
                                                                                                (select: string) =>
                                                                                                    select ===
                                                                                                    'performance'
                                                                                            )}
                                                                                        />
                                                                                    </Grid.Column>
                                                                                    <Grid.Column
                                                                                        computer='12'
                                                                                        mobile='12'
                                                                                        style={{
                                                                                            alignSelf: 'center',
                                                                                        }}
                                                                                    >
                                                                                        ผลการดำเนินการ
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )}
                                                        </Grid>
                                                        <Grid>
                                                            {select_export === 'approval_report' && (
                                                                <Grid.Row>
                                                                    <Grid.Column computer='16' mobile='16'>
                                                                        <label className='header-input-form'>
                                                                            ผลสรุปรายงาน
                                                                        </label>
                                                                    </Grid.Column>
                                                                    {reportSummary.map((data, index) => (
                                                                        <Grid.Column
                                                                            key={index}
                                                                            computer='5'
                                                                            mobile='16'
                                                                        >
                                                                            <Segment
                                                                                style={
                                                                                    !error_report_summary
                                                                                        ? {
                                                                                              cursor: 'pointer',
                                                                                              margin: 3,
                                                                                              border: '1px solid #CCCDCE',
                                                                                          }
                                                                                        : {
                                                                                              cursor: 'pointer',
                                                                                              margin: 3,
                                                                                              border: '2px solid #E0B4B4',
                                                                                          }
                                                                                }
                                                                                onClick={() =>
                                                                                    handleChangeReportSummary(
                                                                                        data.value
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Grid>
                                                                                    <Grid.Row>
                                                                                        <Grid.Column
                                                                                            computer='2'
                                                                                            mobile='2'
                                                                                            style={{
                                                                                                alignSelf: 'center',
                                                                                            }}
                                                                                        >
                                                                                            <Checkbox
                                                                                                checked={select_report_summary.some(
                                                                                                    (select: string) =>
                                                                                                        select ===
                                                                                                        data.value
                                                                                                )}
                                                                                            />
                                                                                        </Grid.Column>
                                                                                        <Grid.Column
                                                                                            computer='12'
                                                                                            mobile='12'
                                                                                            style={{
                                                                                                alignSelf: 'center',
                                                                                            }}
                                                                                        >
                                                                                            {data.key}
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                </Grid>
                                                                            </Segment>
                                                                        </Grid.Column>
                                                                    ))}
                                                                </Grid.Row>
                                                            )}
                                                        </Grid>
                                                    </Grid.Row>
                                                    <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column textAlign='center'>
                                                                <Button
                                                                    style={{
                                                                        color: '#283655',
                                                                        marginTop: 15,
                                                                        border: '1px solid #CCCDCE',
                                                                        backgroundColor: 'white',
                                                                    }}
                                                                    onClick={handleClearFilter}
                                                                >
                                                                    ล้างข้อมูล
                                                                </Button>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Grid.Column>
                                                <Grid.Column computer='1' mobile='16' />
                                            </Grid.Row>
                                        </Grid>
                                    </CustomSegment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    <Button
                                        style={{
                                            backgroundColor: '#283655',
                                            color: '#FFF',
                                        }}
                                        onClick={FormExportReport}
                                    >
                                        ออกรายงาน
                                    </Button>
                                    <Button
                                        style={{
                                            backgroundColor: '#C54C40',
                                            color: '#FFF',
                                        }}
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/complaint`)
                                        }}
                                    >
                                        ยกเลิก
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Container>
            </div>
        </Fragment>
    )
}

export default ExportExcel
