import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { useCallback } from 'react'
import { Button, Checkbox, Container, Form, Grid, Icon, Image, Input, Segment } from 'semantic-ui-react'
import { getLIFF } from '../../../config/liff'
import {
    POST_NOTIFICATION_LINE_CHECK_REGISTER,
    POST_NOTIFICATION_LINE_USER_BIND_LINE_USER_ID,
} from '../../../config/api/notification'
import { axiosAuthen } from '../../../config/axiosAuthen'
import { GET_MUNICIPALITY_LOGO } from '../../../config/api/municipality'
import { HOST } from '../../../config/router'
// import { alert } from '../../../components/alert';

const LineLogin: React.FC = () => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [username_error, setUsernameError] = useState<boolean>(false)
    const [password_error, setPasswordError] = useState<boolean>(false)
    const [show_password, setShowPassword] = useState<boolean>(false)
    const [remember, setRemember] = useState<boolean>(false)
    const [, setErrorText] = useState<string>('')
    const [line_user_id, setLineUserId] = useState<string>('')
    const [liff, setLiff] = useState<any>()
    const [, setFullName] = useState<string>('')
    const [, setResult] = useState<boolean>(false)
    const [logo, setLogo] = useState<string | null>('')
    let municipality_id = localStorage.municipality_id

    const checkRegister = useCallback(() => {
        axios.post(POST_NOTIFICATION_LINE_CHECK_REGISTER, { line_user_id: line_user_id }).then((response) => {
            let value = response.data
            setFullName(value.full_name)
            setResult(value.result)
        })
    }, [line_user_id])

    useEffect(() => {
        // const queryString = decodeURIComponent(window.location.search).replace('?liff.state=', '');
        // const params = new URLSearchParams(queryString);
        // let action = params.get('action');
        getLIFF().then((liff: any) => {
            liff.init({
                liffId: '1656195839-kDpoB4vx',
            }).then(async () => {
                let profile = await liff.getProfile()
                setLineUserId(profile.userId)
                setLiff(liff)
                checkRegister()
            })
        })
    }, [checkRegister])

    useEffect(() => {
        axiosAuthen.get(`${GET_MUNICIPALITY_LOGO}/${municipality_id}`).then((response) => {
            let { logo } = response.data.value.logo
            setLogo(logo)
        })
    }, [municipality_id])

    const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target
        setUsername(value)
        setUsernameError(false)
    }

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target
        setPassword(value)
        setPasswordError(false)
    }

    const validate = () => {
        let error = false
        if (!username) {
            error = true
            setUsernameError(true)
        }
        if (!password) {
            error = true
            setPasswordError(true)
        }
        return error
    }

    const handleSubmit = () => {
        if (!validate()) {
            axios
                .post(`${POST_NOTIFICATION_LINE_USER_BIND_LINE_USER_ID}`, {
                    username: username,
                    password: password,
                    line_user_id: line_user_id,
                })
                .then((response) => {
                    // let value = response.data.value;

                    // if (line_user_id) {
                    liff.closeWindow()
                    // }
                })
                .catch((error) => {
                    if (error.response) {
                        let message = error.response.data.message
                        if (message === 'wrong username') {
                            setErrorText('ไม่พบบัญชีผู้ใช้งานในระบบ')
                        } else if (message === 'wrong password') {
                            setErrorText('รหัสผ่านไม่ถูกต้อง')
                        }
                    } else {
                        setErrorText('ระบบเกิดข้อผิดพลาด')
                    }
                    setLoading(false)
                })
        }
    }

    return (
        <Fragment>
            <div className='background-line-login-page'>
                <Container fluid>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                computer='16'
                                tablet='5'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Image src={`${HOST}/${logo}`} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16' style={{ textAlign: 'center', color: '#293757' }}>
                                <h1>ชื่อระบบไทย</h1>
                                <h3>ชื่อระบบอังกฤษ</h3>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='4' />
                            <Grid.Column computer='8' tablet='16'>
                                <Segment
                                    style={{
                                        // boxShadow: '0px 1px 40px #DCDCDC',
                                        borderRadius: 5,
                                        margin: 20,
                                        backgroundColor: '#293757',
                                        color: 'white',
                                    }}
                                >
                                    <Form loading={loading} onSubmit={handleSubmit}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='16'>
                                                    <h2 style={{ textAlign: 'center' }}>เข้าสู่ระบบ</h2>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16'>
                                                    <Form.Field error={username_error}>
                                                        <h4>อีเมล/หมายเลขโทรศัพท์</h4>
                                                        <Input
                                                            fluid
                                                            name='username'
                                                            value={username}
                                                            onChange={handleChangeUsername}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16'>
                                                    <Form.Field error={password_error}>
                                                        <h4>รหัสผ่าน</h4>
                                                        <Input
                                                            fluid
                                                            icon={
                                                                <Icon
                                                                    // name={show_password ? 'eye' : 'eye slash'}
                                                                    link
                                                                    onClick={() => {
                                                                        setShowPassword(!show_password)
                                                                    }}
                                                                />
                                                            }
                                                            type={show_password ? 'text' : 'password'}
                                                            name='password'
                                                            value={password}
                                                            onChange={handleChangePassword}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='8'>
                                                    <Checkbox
                                                        label='จดจำรหัสผ่าน'
                                                        name='remember'
                                                        style={{ color: 'white' }}
                                                        checked={remember}
                                                        onChange={() => {
                                                            setRemember(!remember)
                                                        }}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column computer='8'>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <Icon
                                                            name='lock'
                                                            style={{ color: 'white', cursor: 'pointer' }}
                                                        />{' '}
                                                        <label style={{ color: 'white', cursor: 'pointer' }}>
                                                            ลืมรหัสผ่าน
                                                        </label>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                            {/* {error_text && (
                                        <Grid.Row>
                                            <Grid.Column>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                    <Message negative>{error_text}</Message>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )} */}
                                            <Grid.Row>
                                                <Grid.Column computer='3' />
                                                <Grid.Column computer='10'>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Button
                                                            fluid
                                                            style={{
                                                                color: '#293757',
                                                                backgroundColor: 'white',
                                                            }}
                                                        >
                                                            เข้าสู่ระบบ
                                                        </Button>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column computer='3' />
                                                <br />
                                            </Grid.Row>
                                        </Grid>
                                    </Form>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer='4' />
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default LineLogin
