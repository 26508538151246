export const colorList = [
	{ key: 'รอช่างรับเรื่อง', value: '#767676' },
	{ key: 'รอดำเนินการ', value: '#767676' },
	{ key: 'กำลังดำเนินการ', value: '#2185D0' },
	{ key: 'ใกล้ครบกำหนด', value: '#F2711C' },
	{ key: 'เกินกำหนด', value: '#DB2828' },
	{ key: 'เสร็จสิ้น', value: '#21BA45' },
	{ key: 'รอการประเมินผล', value: '#21BA45' },
	{ key: 'ประเมินผลเสร็จสิ้น', value: '#21BA45' },
	{ key: 'อนุมัติ', value: '#21BA45' },
	{ key: 'รอการอนุมัติ', value: '#767676' },
	{ key: 'ไม่อนุมัติ', value: '#DB2828' },
	// { key: 'ชะลอเรื่อง', value: 'orange' },
	{ key: 'ส่งต่อหน่วยงานอื่น', value: 'orange' },
	// { key: 'เข้าแผนปีงบประมาณ', value: 'orange' },
	{ key: 'ไม่สามารถดำเนินการได้', value: 'orange' },
	{ key: 'อื่นๆ', value: 'orange' },
	{ key: 'แก้ไขคำร้อง', value: 'orange' },
	{ key: 'รอรับเรื่อง', value: '#3085D6' },
	{ key: 'ยกเลิกคำร้อง', value: '#FF6161' }
];

export const allSizeRow = [
	{ key: 1, text: 5, value: 5 },
	{ key: 2, text: 10, value: 10 },
	{ key: 3, text: 20, value: 20 }
];

export const statusList = [
	{ key: 'รอช่างรับเรื่อง', value: 'รอช่างรับเรื่อง' },
	{ key: 'รอดำเนินการ', value: 'รอช่างรับเรื่อง' },
	{ key: 'ติดตาม', value: 'ติดตาม' },
	{ key: 'กำลังดำเนินการ', value: 'กำลังดำเนินการ' },
	{ key: 'ใกล้ครบกำหนด', value: 'ใกล้ครบกำหนด' },
	{ key: 'เกินกำหนด', value: 'เกินกำหนด' },
	{ key: 'เสร็จสิ้น', value: 'เสร็จสิ้น' },
	{ key: 'รอการประเมินผล', value: 'เสร็จสิ้น' },
	{ key: 'ประเมินผลเสร็จสิ้น', value: 'เสร็จสิ้น' },
	{ key: 'ชะลอเรื่อง', value: 'ติดตาม' },
	{ key: 'ส่งต่อหน่วยงานอื่น', value: 'ทำไม่ได้' },
	{ key: 'เข้าแผนปีงบประมาณ', value: 'ติดตาม' },
	{ key: 'ไม่สามารถดำเนินการได้', value: 'ทำไม่ได้' },
	{ key: 'อื่นๆ', value: 'ทำไม่ได้' },
	{ key: 'แก้ไขคำร้อง', value: 'ทำไม่ได้' }
];

export const groupStatus = [
	{ key: 'กำลังดำเนินการ', value: 'กำลังดำเนินการ' },
	{ key: 'ส่งต่อหน่วยงานอื่น', value: 'ไม่สามารถดำเนินการได้' },
	{ key: 'ไม่สามารถดำเนินการได้', value: 'ไม่สามารถดำเนินการได้' },
	{ key: 'อื่นๆ', value: 'ไม่สามารถดำเนินการได้' },
	{ key: 'แก้ไขคำร้อง', value: 'ไม่สามารถดำเนินการได้' },
	{ key: 'ยกเลิกคำร้อง', value: 'ยกเลิกคำร้อง' },
	{ key: 'ชะลอเรื่อง', value: 'ติดตาม' },
	{ key: 'เข้าแผนปีงบประมาณ', value: 'ติดตาม' },
	{ key: 'ติดตาม', value: 'ติดตาม' },
	{ key: 'รอดำเนินการ', value: 'รอดำเนินการ' },
	{ key: 'รอช่างรับเรื่อง', value: 'รอดำเนินการ' },
	{ key: 'รอการประเมินผล', value: 'รอการประเมินผล' },
	{ key: 'ประเมินผลเสร็จสิ้น', value: 'ประเมินผลเสร็จสิ้น' },
	{ key: 'ใกล้ครบกำหนด', value: 'ใกล้ครบกำหนด' },
	{ key: 'เกินกำหนด', value: 'เกินกำหนด' }
];

export const statusNotWork = [
	'ชะลอเรื่อง',
	'ส่งต่อหน่วยงานอื่น',
	'เข้าแผนปีงบประมาณ',
	'ไม่สามารถดำเนินการได้',
	'อื่นๆ',
	'แก้ไขคำร้อง'
];

export const month_th = [
	{ key: '01', value: 'มกราคม' },
	{ key: '02', value: 'กุมภาพันธ์' },
	{ key: '03', value: 'มีนาคม' },
	{ key: '04', value: 'เมษายน' },
	{ key: '05', value: 'พฤษภาคม' },
	{ key: '06', value: 'มิถุนายน' },
	{ key: '07', value: 'กรกฎาคม' },
	{ key: '08', value: 'สิงหาคม' },
	{ key: '09', value: 'กันยายน' },
	{ key: '10', value: 'ตุลาคม' },
	{ key: '11', value: 'พฤศจิกายน' },
	{ key: '12', value: 'ธันวาคม' }
];
