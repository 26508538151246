import currentFiscalYear from '../../../../functions/CurrentFiscalyear'
import { ActionInterface } from '../../../types'
import { ComplaintFormStep1State } from '../../../types/Complaint/Form/Step1'

const initialState: ComplaintFormStep1State = {
    id: '',
    complaint_topic: { value: '', text: '' },
    complaint_type: { value: '', text: '' },
    complaint_subject: '',
    complaint_detail: '',
    contact: { value: '', text: '' },
    area: { value: '', text: '' },
    community: { value: '', text: '' },
    technician: { value: '', text: '' },
    file_complaint: [],
    complaint_no: '',
    central_complaint_no: '',
    year: currentFiscalYear(),
    complaint_date: '',
    directory: [],
    complaint_topic_option: [],
    complaint_type_option: [],
    list_complaint_type: [],
    contact_option: [],
    area_option: [],
    community_option: [],
    list_community: [],
    technician_option: [],
    directory_option: [],
    complaint_topic_error: false,
    complaint_type_error: false,
    complaint_subject_error: false,
    complaint_detail_error: false,
    technician_error: false,
    directory_error: false,
    children_directory_error: false,
    file_complaint_error: false,
    central_complaint_no_error: false,
    complaint_date_error:false
}

const ComplaintFormStep1 = (state = initialState, action: ActionInterface): ComplaintFormStep1State => {
    switch (action.type) {
        case 'setStateByNameAndValueComplaintFormStep1':
            return action.name
                ? {
                      ...state,
                      [action.name]: action.value,
                  }
                : { ...state }
        case 'appendFormComplaintFormStep1':
            return {
                ...state,
                ...action.value,
            }
        case 'resetFormByReducerNameComplaintFormStep1':
            return {
                ...state,
                ...{
                    id: '',
                    complaint_topic: { value: '', text: '' },
                    complaint_type: { value: '', text: '' },
                    complaint_subject: '',
                    complaint_detail: '',
                    contact: { value: '', text: '' },
                    area: { value: '', text: '' },
                    community: { value: '', text: '' },
                    technician: { value: '', text: '' },
                    file_complaint: [],
                    complaint_no: '',
                    central_complaint_no: '',
                    year: currentFiscalYear(),
                    complaint_date: '',
                    directory: [],
                    complaint_topic_option: [],
                    complaint_type_option: [],
                    list_complaint_type: [],
                    contact_option: [],
                    area_option: [],
                    community_option: [],
                    list_community: [],
                    technician_option: [],
                    directory_option: [],
                    children_directory_option: [],
                    complaint_topic_error: false,
                    complaint_type_error: false,
                    complaint_subject_error: false,
                    complaint_detail_error: false,
                    technician_error: false,
                    directory_error: false,
                    children_directory_error: false,
                    file_complaint_error: false,
                    central_complaint_no_error: false,  
                    complaint_date_error:false
                },
            }
        default:
            return state
    }
}

export default ComplaintFormStep1
