import React, { Fragment, useEffect, useState } from 'react'
import { Breadcrumb, Button, Checkbox, Container, Grid, Icon, Input, Radio, Segment } from 'semantic-ui-react'
import CustomSegment from '../../../../../components/CustomSegment'
import CustomStep from '../../../../../components/CustomStep'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import {
    GET_USER_MANUAL_ALL,
    POST_CREATE_HAS_USER_MANUAL,
    PUT_EDIT_HAS_USER_MANUAL,
} from '../../../../../config/api/userManual'
import { useHistory, useLocation } from 'react-router'
import { GET_MUNICIPALITY_LIST } from '../../../../../config/api/municipality'
import { alert } from '../../../../../components/alert'

interface InterfaceListTopic {
    id: number
    name: string
    sub_topics: InterfaceListSubTopic[]
}

interface InterfaceListSubTopic {
    id: number
    name: string
    contents: InterfaceListContent[]
}

interface InterfaceListContent {
    id: number
    name: string
}

interface InterfaceValueTopic {
    id: number
    name: string
}
interface InterfaceValueSubTopic {
    parent_id: number
    id: number
    name: string
}
interface InterfaceValueContent {
    super_parent_id: number
    parent_id: number
    id: number
    name: string
}

const ManagementUserManualMapping: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [municipality_id, setMunicipalityId] = useState<string>()
    let [municipality_name, setMunicipalityName] = useState<string>()
    let [active_step, setActiveStep] = useState<number>(1)
    let [active_segment, setActiveSegment] = useState<number>(1)
    let [list_user_manual, setListUserManual] = useState<InterfaceListTopic[]>([])
    let [list_topic, setListTopic] = useState<InterfaceValueTopic[]>([])
    let [list_sub_topic, setListSubTopic] = useState<InterfaceValueSubTopic[]>([])
    let [list_content, setListContent] = useState<InterfaceValueContent[]>([])
    let [list_checked_topic, setListCheckedTopic] = useState<InterfaceValueTopic[]>([])
    let [list_checked_sub_topic, setListCheckedSubTopic] = useState<InterfaceValueSubTopic[]>([])
    let [list_checked_content, setListCheckedContent] = useState<InterfaceValueContent[]>([])
    let [edit_user_manual, setEditUserManual] = useState<boolean>(false)

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id, topic } = location_state
            setMunicipalityId(municipality_id)
            axiosAuthen.get(`${GET_MUNICIPALITY_LIST}/${municipality_id}`).then((response) => {
                let { value } = response.data
                setMunicipalityName(value.name_th)
            })

            if (topic) {
                let list_checked_topic = []
                let list_checked_sub_topic = []
                let list_checked_content = []
                list_checked_topic.push({ id: topic.id, name: topic.name })
                for (const sub_topic of topic.sub_topics) {
                    list_checked_sub_topic.push({ parent_id: topic.id, id: sub_topic.id, name: sub_topic.name })
                    for (const content of sub_topic.contents) {
                        list_checked_content.push({
                            super_parent_id: topic.id,
                            parent_id: sub_topic.id,
                            id: content.id,
                            name: content.name,
                        })
                    }
                }

                setListCheckedTopic([...list_checked_topic])
                setListCheckedSubTopic([...list_checked_sub_topic])
                setListCheckedContent([...list_checked_content])
                setEditUserManual(true)
            }
        }
        const getUserManual = () => {
            axiosAuthen.get(GET_USER_MANUAL_ALL).then((response) => {
                let { value } = response.data
                setListUserManual(value)
                setListTopic(value.map((data: InterfaceValueTopic) => ({ id: data.id, name: data.name })))
            })
        }
        getUserManual()
    }, [location])

    const handleSelectTopic = (id: number) => {
        let topic = list_user_manual.find((data: InterfaceListTopic) => data.id === id)
        if (topic && topic.sub_topics)
            setListSubTopic(
                topic.sub_topics.map((data: InterfaceListSubTopic) => ({ parent_id: id, id: data.id, name: data.name }))
            )
        setListContent([])
    }

    const handleChangeTopic = (data_select: InterfaceValueTopic, checked: boolean | undefined) => {
        if (checked) {
            list_checked_topic.push(data_select)
        } else {
            list_checked_topic = list_checked_topic.filter((check: InterfaceValueTopic) => check.id !== data_select.id)
            let topic = list_user_manual.find((data: InterfaceListTopic) => data.id === data_select.id)
            if (topic && topic.sub_topics) {
                let sub_topic = topic.sub_topics.find((data: InterfaceListSubTopic) => data.id === data_select.id)
                list_checked_sub_topic = list_checked_sub_topic.filter(
                    (check: InterfaceValueSubTopic) =>
                        !topic?.sub_topics.map((data: InterfaceListSubTopic) => data.id).includes(check.id)
                )
                setListCheckedSubTopic([...list_checked_sub_topic])
                if (sub_topic && sub_topic.contents) {
                    list_checked_content = list_checked_content.filter(
                        (check: InterfaceValueContent) =>
                            !sub_topic?.contents.map((data: InterfaceListContent) => data.id).includes(check.id)
                    )
                    setListCheckedContent([...list_checked_content])
                }
            }
        }
        setListCheckedTopic([...list_checked_topic])
    }

    const handleSelectSubTopic = (data_select: InterfaceValueSubTopic) => {
        let topic = list_user_manual.find((data: InterfaceListTopic) => data.id === data_select.parent_id)
        if (topic && topic.sub_topics) {
            let sub_topic = topic.sub_topics.find((data: InterfaceListSubTopic) => data.id === data_select.id)
            if (sub_topic && sub_topic.contents) {
                setListContent(
                    sub_topic.contents.map((data: InterfaceListContent) => ({
                        super_parent_id: data_select.parent_id,
                        parent_id: data_select.id,
                        id: data.id,
                        name: data.name,
                    }))
                )
            }
        }
    }

    const handleChangeSubTopic = (data_select: InterfaceValueSubTopic, checked: boolean | undefined) => {
        if (checked) {
            list_checked_sub_topic.push(data_select)
            let topic = list_checked_topic.find((data: InterfaceValueTopic) => data.id === data_select.parent_id)
            if (!topic) {
                let topic = list_user_manual.find((data: InterfaceListTopic) => data.id === data_select.parent_id)
                if (topic && topic.sub_topics) {
                    list_checked_topic.push({
                        id: topic.id,
                        name: topic.name,
                    })
                    setListCheckedTopic([...list_checked_topic])
                }
            }
        } else {
            list_checked_sub_topic = list_checked_sub_topic.filter(
                (check: InterfaceValueTopic) => check.id !== data_select.id
            )
            let topic = list_user_manual.find((data: InterfaceListTopic) => data.id === data_select.parent_id)
            if (topic && topic.sub_topics) {
                let sub_topic = topic.sub_topics.find((data: InterfaceListSubTopic) => data.id === data_select.id)
                if (sub_topic && sub_topic.contents) {
                    list_checked_content = list_checked_content.filter(
                        (check: InterfaceValueContent) =>
                            !sub_topic?.contents.map((data: InterfaceListContent) => data.id).includes(check.id)
                    )
                    setListCheckedContent([...list_checked_content])
                }
            }
        }
        setListCheckedSubTopic([...list_checked_sub_topic])
    }

    const handleChangeContent = (data_select: InterfaceValueContent, checked: boolean | undefined) => {
        if (checked) {
            list_checked_content.push(data_select)
            let topic = list_checked_topic.find((data: InterfaceValueTopic) => data.id === data_select.super_parent_id)
            let sub_topic = list_checked_sub_topic.find(
                (data: InterfaceValueSubTopic) => data.id === data_select.parent_id
            )
            if (!topic) {
                let topic = list_user_manual.find((data: InterfaceListTopic) => data.id === data_select.super_parent_id)
                if (topic && topic.sub_topics) {
                    list_checked_topic.push({
                        id: topic.id,
                        name: topic.name,
                    })
                    setListCheckedTopic([...list_checked_topic])
                }
            }
            if (!sub_topic) {
                let topic = list_user_manual.find((data: InterfaceListTopic) => data.id === data_select.super_parent_id)
                if (topic && topic.sub_topics) {
                    let sub_topic = topic.sub_topics.find(
                        (data: InterfaceListSubTopic) => data.id === data_select.parent_id
                    )
                    if (sub_topic) {
                        list_checked_sub_topic.push({
                            parent_id: data_select.parent_id,
                            id: sub_topic.id,
                            name: sub_topic.name,
                        })
                        setListCheckedSubTopic([...list_checked_sub_topic])
                    }
                }
            }
        } else {
            list_checked_content = list_checked_content.filter(
                (check: InterfaceValueTopic) => check.id !== data_select.id
            )
        }
        setListCheckedContent([...list_checked_content])
    }

    const handleSubmit = () => {
        if (edit_user_manual) {
            let body = {
                municipalities_id: municipality_id,
                topics: list_checked_topic[0].id,
                sub_topics: list_checked_sub_topic.map((data: InterfaceValueSubTopic) => data.id),
                contents: list_checked_content.map((data: InterfaceValueContent) => data.id),
            }
            axiosAuthen.put(PUT_EDIT_HAS_USER_MANUAL, body).then((response) => {
                alert({
                    type: 'success',
                    title: 'บันทึกเสร็จสิ้น',
                    timer: 1500,
                    onClosed: () => {
                        history.goBack()
                    },
                })
            })
        } else {
            let body = {
                municipalities_id: municipality_id,
                topics: list_checked_topic.map((data: InterfaceValueTopic) => data.id),
                sub_topics: list_checked_sub_topic.map((data: InterfaceValueSubTopic) => data.id),
                contents: list_checked_content.map((data: InterfaceValueContent) => data.id),
            }
            axiosAuthen.post(POST_CREATE_HAS_USER_MANUAL, body).then((response) => {
                alert({
                    type: 'success',
                    title: 'บันทึกเสร็จสิ้น',
                    timer: 1500,
                    onClosed: () => {
                        history.goBack()
                    },
                })
            })
        }
    }

    const handleGoBack = () => {
        setActiveStep(active_step - 1)
        let element = document.getElementById('pusher')
        element?.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleForward = () => {
        setActiveStep(active_step + 1)
        let element = document.getElementById('pusher')
        element?.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities`, {
                                                municipality_id: municipality_id,
                                            })
                                        }}>
                                        รายการองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/management/user-manual`, {
                                                municipality_id: municipality_id,
                                            })
                                        }}>
                                        รายการคู่มือการใช้งาน
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>จัดการเนื้อหาคู่มือการใช้งาน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>จัดการเนื้อหาคู่มือการใช้งาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <CustomStep activeStep={active_step}>
                                    <CustomStep.Step
                                        icon='info circle'
                                        label='กำหนดคู่มือการใช้งาน'
                                        size='big'
                                        onClick={() => {
                                            setActiveStep(1)
                                        }}
                                    />
                                    <CustomStep.Step
                                        icon='check'
                                        label='ตรวจสอบข้อมูล'
                                        size='big'
                                        onClick={() => {
                                            setActiveStep(2)
                                        }}
                                    />
                                </CustomStep>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {active_step === 1 && (
                                    <CustomSegment icon='file alternate' header='คู่มือการใช้งาน'>
                                        <Grid>
                                            <Grid.Row style={{ marginTop: 40 }}>
                                                <Grid.Column computer={active_segment === 1 ? '6' : '5'}>
                                                    <Segment
                                                        style={
                                                            active_segment === 1
                                                                ? {
                                                                      border: '3.5px solid #383655',
                                                                      transform: 'scale(1.11)',
                                                                      zIndex: 1,
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                                : {
                                                                      border: '1px solid #C4C4C4',
                                                                      transform: 'scale(0.95)',
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                        }
                                                        onClick={() => {
                                                            setActiveSegment(1)
                                                        }}>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 50,
                                                                height: 50,
                                                                backgroundColor: '#293757',
                                                                borderRadius: 50,
                                                                textAlign: 'center',
                                                                top: -32,
                                                                left: '50%',
                                                                marginLeft: -30,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: '#fff',
                                                            }}>
                                                            <div>1</div>
                                                        </div>
                                                        <h3 style={{ textAlign: 'center' }}>หัวข้อ</h3>
                                                        <Input fluid icon='search' placeholder='ค้นหา' />
                                                        <div
                                                            style={{
                                                                overflowX: 'auto',
                                                                height: 240,
                                                                paddingRight: 5,
                                                                marginTop: 10,
                                                            }}>
                                                            {list_topic.map(
                                                                (data: InterfaceValueTopic, index: number) => (
                                                                    <Segment
                                                                        key={index}
                                                                        onClick={() => {
                                                                            handleSelectTopic(data.id)
                                                                        }}
                                                                        style={
                                                                            Boolean(
                                                                                list_checked_topic.find(
                                                                                    (check: InterfaceValueTopic) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )
                                                                                ? {
                                                                                      border: '2px solid #383655',
                                                                                  }
                                                                                : {
                                                                                      border: '1px solid #C4C4C4',
                                                                                  }
                                                                        }>
                                                                        {edit_user_manual ? (
                                                                            <Radio
                                                                                checked={Boolean(
                                                                                    list_checked_topic.find(
                                                                                        (check: InterfaceValueTopic) =>
                                                                                            check.id === data.id
                                                                                    )
                                                                                )}
                                                                                label={data.name}
                                                                            />
                                                                        ) : (
                                                                            <Checkbox
                                                                                checked={Boolean(
                                                                                    list_checked_topic.find(
                                                                                        (check: InterfaceValueTopic) =>
                                                                                            check.id === data.id
                                                                                    )
                                                                                )}
                                                                                label={data.name}
                                                                                onChange={(_, { checked }) =>
                                                                                    handleChangeTopic(data, checked)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Segment>
                                                                )
                                                            )}
                                                        </div>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column computer={active_segment === 2 ? '6' : '5'}>
                                                    <Segment
                                                        style={
                                                            active_segment === 2
                                                                ? {
                                                                      border: '3.5px solid #383655',
                                                                      transform: 'scale(1.11)',
                                                                      zIndex: 1,
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                                : {
                                                                      border: '1px solid #C4C4C4',
                                                                      transform: 'scale(0.95)',
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                        }
                                                        onClick={() => {
                                                            setActiveSegment(2)
                                                        }}>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 50,
                                                                height: 50,
                                                                backgroundColor: '#293757',
                                                                borderRadius: 50,
                                                                textAlign: 'center',
                                                                top: -32,
                                                                left: '50%',
                                                                marginLeft: -30,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: '#fff',
                                                            }}>
                                                            <div>2</div>
                                                        </div>
                                                        <h3 style={{ textAlign: 'center' }}>หัวข้อย่อย</h3>
                                                        <Input fluid icon='search' placeholder='ค้นหา' />
                                                        <div
                                                            style={{
                                                                overflowX: 'auto',
                                                                height: 240,
                                                                paddingRight: 5,
                                                                marginTop: 10,
                                                            }}>
                                                            {list_sub_topic.map(
                                                                (data: InterfaceValueSubTopic, index: number) => (
                                                                    <Segment
                                                                        key={index}
                                                                        onClick={() => {
                                                                            handleSelectSubTopic(data)
                                                                        }}
                                                                        style={
                                                                            Boolean(
                                                                                list_checked_sub_topic.find(
                                                                                    (check: InterfaceValueSubTopic) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )
                                                                                ? {
                                                                                      border: '2px solid #383655',
                                                                                  }
                                                                                : {
                                                                                      border: '1px solid #C4C4C4',
                                                                                  }
                                                                        }>
                                                                        <Checkbox
                                                                            checked={Boolean(
                                                                                list_checked_sub_topic.find(
                                                                                    (check: InterfaceValueSubTopic) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )}
                                                                            label={data.name}
                                                                            onChange={(_, { checked }) =>
                                                                                handleChangeSubTopic(data, checked)
                                                                            }
                                                                        />
                                                                    </Segment>
                                                                )
                                                            )}
                                                        </div>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column computer={active_segment === 3 ? '6' : '5'}>
                                                    <Segment
                                                        style={
                                                            active_segment === 3
                                                                ? {
                                                                      border: '3.5px solid #383655',
                                                                      transform: 'scale(1.11)',
                                                                      zIndex: 1,
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                                : {
                                                                      border: '1px solid #C4C4C4',
                                                                      transform: 'scale(0.95)',
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                        }
                                                        onClick={() => {
                                                            setActiveSegment(3)
                                                        }}>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 50,
                                                                height: 50,
                                                                backgroundColor: '#293757',
                                                                borderRadius: 50,
                                                                textAlign: 'center',
                                                                top: -32,
                                                                left: '50%',
                                                                marginLeft: -30,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: '#fff',
                                                            }}>
                                                            <div>3</div>
                                                        </div>
                                                        <h3 style={{ textAlign: 'center' }}>เนื้อหา</h3>
                                                        <Input fluid icon='search' placeholder='ค้นหา' />
                                                        <div
                                                            style={{
                                                                overflowX: 'auto',
                                                                height: 240,
                                                                paddingRight: 5,
                                                                marginTop: 10,
                                                            }}>
                                                            {list_content.map(
                                                                (data: InterfaceValueContent, index: number) => (
                                                                    <Segment
                                                                        key={index}
                                                                        style={
                                                                            Boolean(
                                                                                list_checked_content.find(
                                                                                    (check: InterfaceValueContent) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )
                                                                                ? {
                                                                                      border: '2px solid #383655',
                                                                                  }
                                                                                : {
                                                                                      border: '1px solid #C4C4C4',
                                                                                  }
                                                                        }>
                                                                        <Checkbox
                                                                            checked={Boolean(
                                                                                list_checked_content.find(
                                                                                    (check: InterfaceValueContent) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )}
                                                                            label={data.name}
                                                                            onChange={(_, { checked }) =>
                                                                                handleChangeContent(data, checked)
                                                                            }
                                                                        />
                                                                    </Segment>
                                                                )
                                                            )}
                                                        </div>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </CustomSegment>
                                )}

                                {active_step === 2 && (
                                    <CustomSegment icon='file alternate' header='ตรวจสอบข้อมูล'>
                                        <Container>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='3'>
                                                        <label className='header-input-form'>องค์กรปกครอง</label>
                                                    </Grid.Column>
                                                    <Grid.Column computer='7'>{municipality_name}</Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='3'>
                                                        <label className='header-input-form'>หัวข้อ</label>
                                                    </Grid.Column>
                                                    <Grid.Column computer='7'>
                                                        <Grid>
                                                            <Grid.Row>
                                                                {list_checked_topic.map((data: InterfaceValueTopic) => (
                                                                    <Grid.Column computer='8' key={data.id}>
                                                                        <Icon name='info circle' />
                                                                        {data.name}
                                                                    </Grid.Column>
                                                                ))}
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='3'>
                                                        <label className='header-input-form'>หัวข้อย่อย</label>
                                                    </Grid.Column>
                                                    <Grid.Column computer='7'>
                                                        <Grid>
                                                            <Grid.Row>
                                                                {list_checked_sub_topic.map(
                                                                    (data: InterfaceValueSubTopic) => (
                                                                        <Grid.Column computer='8' key={data.id}>
                                                                            <Icon name='book' />
                                                                            {data.name}
                                                                        </Grid.Column>
                                                                    )
                                                                )}
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='3'>
                                                        <label className='header-input-form'>เนื้อหา</label>
                                                    </Grid.Column>
                                                    <Grid.Column computer='7'>
                                                        <Grid>
                                                            <Grid.Row>
                                                                {list_checked_content.map(
                                                                    (data: InterfaceValueContent) => (
                                                                        <Grid.Column computer='8' key={data.id}>
                                                                            <Icon name='book' />
                                                                            {data.name}
                                                                        </Grid.Column>
                                                                    )
                                                                )}
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Grid.Row>
                                            </Grid>
                                        </Container>
                                    </CustomSegment>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {active_step > 1 && (
                                    <Button
                                        type='button'
                                        floated='left'
                                        style={{ backgroundColor: '#293757', color: 'white' }}
                                        icon
                                        onClick={handleGoBack}>
                                        <Icon name='arrow left' /> ก่อนหน้า
                                    </Button>
                                )}
                                {active_step === 1 && (
                                    <Button
                                        type='button'
                                        floated='right'
                                        style={{ backgroundColor: '#293757', color: 'white' }}
                                        icon
                                        onClick={handleForward}>
                                        ถัดไป <Icon name='arrow right' />
                                    </Button>
                                )}
                                {active_step === 2 && (
                                    <Button
                                        floated='right'
                                        style={{ backgroundColor: '#293757', color: 'white' }}
                                        onClick={handleSubmit}>
                                        บันทึก
                                    </Button>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default ManagementUserManualMapping
