import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPropsAlert } from '../../store/selectors';
import AlertSuccess from '../../assets/images/alert/success.png';
import AlertError from '../../assets/images/alert/error.png';
import AlertWarning from '../../assets/images/alert/warning.png';
import AlertConfirm from '../../assets/images/alert/confirm.png';
import { alertClose } from '../../store/actions';
import { propsAlert } from '../../store/types/Alert';
import store from '../../store';
import { Button, Header, Icon, Image, Modal } from 'semantic-ui-react';

// TODO : How to use
// ***************************************************************** //
// * 1. import alert from this path file
// * 2. call alert ?
// *    alert({});
// *    and can send props to function as object
// * 3. if have function ?
// *    - call in onConfirm, onCancle, onClosed as arrow function
// ***************************************************************** //

const typeAlert = [
    { type: 'success', icon: 'check', image: AlertSuccess, color: '#8AD39C' },
    { type: 'confirm', icon: 'check', image: AlertConfirm, color: '#869BCB' },
    { type: 'error', icon: 'exclamation', image: AlertError, color: '#EA9292' },
    { type: 'warning', icon: 'exclamation', image: AlertWarning, color: '#F2CC6B' },
];

export const Alert: React.FC = () => {
    const dispatch = useDispatch();
    const propsAlert = useSelector(getPropsAlert);
    let {
        type,
        icon,
        image,
        color,
        title,
        text,
        onConfirm,
        onCancel,
        onClosed,
        confirmText,
        cancelText,
        timer,
        alert,
    } = propsAlert;
    let props_alert = typeAlert.find((type_alert) => type_alert.type === type);
    if (timer) {
        setTimeout(() => {
            dispatch(alertClose());
            onClosed && onClosed();
            onCancel && onCancel();
        }, timer);
    }
    return (
        <Modal size='mini' open={alert}>
            <Modal.Header style={{ padding: 5, backgroundColor: color ? color : props_alert?.color }}>
                <Icon
                    name={
                        icon
                            ? icon
                            : ['success', 'confirm'].includes(type)
                            ? 'check'
                            : ['error', 'warning'].includes(type)
                            ? 'exclamation'
                            : undefined
                    }
                    circular
                    size='large'
                    style={{
                        position: 'absolute',
                        left: '42%',
                        top: -24,
                        backgroundColor: color ? color : props_alert?.color,
                        color: 'white',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        zIndex: 1,
                    }}
                />
            </Modal.Header>
            <Modal.Content>
                <div style={{ margin: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Image size='small' src={image ? image : props_alert?.image} />
                </div>
                <Modal.Description style={{ marginTop: 20, textAlign: 'center' }}>
                    {title && <Header>{title}</Header>}
                    {text && <p>{text}</p>}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {confirmText && (
                    <Button
                        style={{
                            margin: 0,
                            borderRadius: 0,
                        }}
                        basic
                        fluid
                        onClick={() => {
                            dispatch(alertClose());
                            onClosed && onClosed();
                            onConfirm && onConfirm();
                        }}>
                        {confirmText}
                    </Button>
                )}
                {cancelText && (
                    <Button
                        style={{
                            margin: 0,
                            borderRadius: 0,
                        }}
                        basic
                        fluid
                        onClick={() => {
                            dispatch(alertClose());
                            onClosed && onClosed();
                            onCancel && onCancel();
                        }}>
                        {cancelText}
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export const alert = (props: propsAlert) => {
    let props_alert = {
        type: props.type,
        icon: props.icon,
        image: props.image,
        color: props.color,
        title: props.title,
        text: props.text,
        onConfirm: props.onConfirm,
        onCancel: props.onCancel,
        onClosed: props.onClosed,
        confirmText: props.confirmText,
        cancelText: props.cancelText,
        timer: props.timer,
        alert: true,
    };
    store.dispatch({ type: 'alert', name: 'alert', value: props_alert });
};
