import { createStore, combineReducers } from 'redux'
import complaintFormStep1 from './reducers/Complaint/Form/Step1'
import complaintFormStep2 from './reducers/Complaint/Form/Step2'
import complaintFormStep3 from './reducers/Complaint/Form/Step3'
import managementUsersFormUser from './reducers/Management/Users/Form/User'
import managementUsersFormPersonal from './reducers/Management/Users/Form/Personal'
import managementUsersFormAdditional from './reducers/Management/Users/Form/Additional'
import alert from './reducers/Alert'
import authenMenuList from './reducers/Complaint/Menu/AuthenMenuList'
import complaintFilterList from './reducers/Complaint/FilterList'
import messageFirebase from './reducers/Notification'
import toast from './reducers/Notification/Toast'
import notification from './reducers/Notification'

const rootReducer = combineReducers({
    complaintFormStep1,
    complaintFormStep2,
    complaintFormStep3,
    managementUsersFormUser,
    managementUsersFormPersonal,
    managementUsersFormAdditional,
    alert,
    authenMenuList,
    complaintFilterList,
    messageFirebase,
    toast,
    notification,
})

export type RootState = ReturnType<typeof rootReducer>

export default createStore(rootReducer)
