import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Breadcrumb,
    Button,
    Container,
    Dropdown,
    DropdownProps,
    Form,
    Grid,
    Icon,
    Input,
    InputOnChangeData,
    Label,
    Pagination,
    Rating,
    Table,
} from 'semantic-ui-react'
import { GET_COMPLAINT_COMMENT_LIST } from '../../../config/api/complaint'
import { GET_DASHBOARD_YEARS } from '../../../config/api/dashboard'
import { GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE } from '../../../config/api/directoryOfMunicipality'
import { axiosAuthen } from '../../../config/axiosAuthen'

interface InterfaceComplaintComment {
    id: number
    central_complaint_no: string
    full_name: string
    updated_at: string
    suggestion: string
    grade: string
}

const CommentPeople: React.FC = () => {
    let history = useHistory()
    let [loading, setLoading] = useState<boolean>(false)
    let [year_option, setYearOption] = useState<{ value: string; text: string }[]>([])
    let [year, setYear] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [complaint_comment_list, setComplaintCommentList] = useState<InterfaceComplaintComment[]>([])
    let [object_complaint_comment_list, setObjectComplaintCommentList] = useState<InterfaceComplaintComment[]>([])
    let [directory_option, setDirectoryOption] = useState<[]>([])
    let [low_directory_id_option, setLowDirectoryIdOption] = useState<[]>([])
    let [directory_id, setDirectoryId] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >('')
    let [low_directory_id, setLowDirectoryId] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >('')
    let [search_technician, setSearchTechnician] = useState<string>('')
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    let permission = localStorage.getItem('permission_en')
    let parent = localStorage.getItem('parent_id')

    const getComplaintCommentList = useCallback(() => {
        setLoading(true)
        axiosAuthen
            .get(
                `${GET_COMPLAINT_COMMENT_LIST}?page=${current_page}&offset=${row_qty}&year=${year}&directory=${directory_id}&low_directory=${low_directory_id}`
            )
            .then((response) => {
                let { value } = response.data
                setRecordQty(value.count)
                setComplaintCommentList(value.complaints_comment)
                setObjectComplaintCommentList(value.complaints_comment)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }, [current_page, directory_id, low_directory_id, row_qty, year])

    useEffect(() => {
        getComplaintCommentList()
    }, [getComplaintCommentList])

    useEffect(() => {
        axiosAuthen.get(GET_DASHBOARD_YEARS).then((response) => {
            let { value } = response.data
            let year_option = value.map((data: any) => ({ value: data, text: data }))
            setYearOption(year_option)
        })
    }, [])

    useEffect(() => {
        const getDirectory = () => {
            axiosAuthen.get(`${GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE}`).then((response) => {
                let { value } = response.data
                let directory_option = value.map((data: any) => ({ value: data.id, text: data.name }))
                setDirectoryOption(directory_option)
            })
        }
        getDirectory()
    }, [])

    useEffect(() => {
        axiosAuthen
            .get(`${GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE}?directory_of_municipalities_id=${directory_id}`)
            .then((response) => {
                let { value } = response.data
                let low_directory_option = value.map((data: any) => ({ value: data.id, text: data.name }))
                setLowDirectoryIdOption(low_directory_option)
            })
    }, [directory_id])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const handleSearchTechnician = (_: any, { value }: InputOnChangeData) => {
        setSearchTechnician(value)
        let search_technician = complaint_comment_list.filter(
            (technician: InterfaceComplaintComment) => technician.full_name.indexOf(value) !== -1
        )
        setObjectComplaintCommentList(search_technician)
    }

    const handleClickDetail = (id: number) => {
        history.push('detail', { complaint_id: id })
    }

    const getDetailGrade = (grade: string | undefined) => {
        if (grade === '5') {
            return 'ดีมาก'
        } else if (grade === '4') {
            return 'ดี'
        } else if (grade === '3') {
            return 'ปานกลาง'
        } else if (grade === '2') {
            return 'พอใช้'
        } else if (grade === '1') {
            return 'ปรับปรุง'
        }
    }

    const handleChangeDirectory = (_: any, { value }: DropdownProps) => {
        setDirectoryId(value)
        setLowDirectoryId('')
    }

    const handleChangeDirectoryById = (_: any, { value }: DropdownProps) => {
        setLowDirectoryId(value)
    }

    const handleSearchYear = (_: any, { value }: DropdownProps) => {
        setYear(value)
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section active>ผลการประเมินจากประชาชน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>ผลการประเมินจากประชาชน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        {(permission === 'director' || (permission === 'operator' && parent === '0')) && (
                            <Grid.Row>
                                <Grid.Column computer='2' mobile='16' style={{ marginTop: 10 }}>
                                    <Dropdown
                                        selection
                                        fluid
                                        value={year}
                                        clearable
                                        options={year_option}
                                        onChange={handleSearchYear}
                                        placeholder='ปีงบประมาณ'
                                    />
                                </Grid.Column>
                                <Grid.Column computer='2' mobile='16' style={{ marginTop: 10 }} />
                                <Grid.Column computer='4' mobile='16' style={{ marginTop: 10 }}>
                                    <Input
                                        fluid
                                        placeholder='ชื่อ-สกุล'
                                        icon='search'
                                        value={search_technician}
                                        onChange={handleSearchTechnician}
                                    />
                                </Grid.Column>
                                <Grid.Column computer='4' mobile='16' style={{ marginTop: 10 }}>
                                    <Dropdown
                                        selection
                                        fluid
                                        clearable
                                        value={directory_id}
                                        options={directory_option}
                                        onChange={handleChangeDirectory}
                                        placeholder='สังกัดหน่วยงาน'
                                    />
                                </Grid.Column>
                                <Grid.Column computer='4' mobile='16' style={{ marginTop: 10 }}>
                                    <Dropdown
                                        selection
                                        fluid
                                        clearable
                                        placeholder='สังกัดหน่วยฝ่ายงาน'
                                        disabled={!Boolean(directory_id)}
                                        value={low_directory_id}
                                        options={low_directory_id_option}
                                        onChange={handleChangeDirectoryById}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )}

                        {(permission === 'division_director' || (permission === 'operator' && parent !== '0')) && (
                            <Grid.Row>
                                <Grid.Column computer='2' mobile='16' style={{ marginTop: 10 }}>
                                    <Dropdown
                                        selection
                                        fluid
                                        clearable
                                        value={year}
                                        options={year_option}
                                        onChange={handleSearchYear}
                                        placeholder='ปีงบประมาณ'
                                    />
                                </Grid.Column>
                                <Grid.Column computer='2' mobile='16' style={{ marginTop: 10 }} />
                                <Grid.Column computer='4' mobile='16' style={{ marginTop: 10 }}>
                                    <Input
                                        fluid
                                        placeholder='ชื่อ-สกุล'
                                        icon='search'
                                        value={search_technician}
                                        onChange={handleSearchTechnician}
                                    />
                                </Grid.Column>
                                <Grid.Column computer='4' mobile='16' style={{ marginTop: 10 }}>
                                    <Dropdown
                                        selection
                                        fluid
                                        clearable
                                        value={directory_id}
                                        options={directory_option}
                                        onChange={handleChangeDirectory}
                                        placeholder='สังกัดหน่วยงาน'
                                    />
                                </Grid.Column>
                                <Grid.Column computer='4' mobile='16' style={{ marginTop: 10 }}>
                                    <Dropdown
                                        selection
                                        fluid
                                        clearable
                                        placeholder='สังกัดหน่วยฝ่ายงาน'
                                        disabled={!Boolean(directory_id)}
                                        value={low_directory_id}
                                        options={low_directory_id_option}
                                        onChange={handleChangeDirectoryById}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Table attached='top' celled className='table-header'>
                                    <Table.Header className='table-header header'>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign='center' width='1'>
                                                ลำดับ
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width='4'>
                                                <div>
                                                    <Icon name='calendar' /> วัน / เดือน / ปี ที่ประเมิน
                                                </div>
                                                <div>
                                                    <Icon name='circle' /> เลขที่รับส่วนกลาง
                                                </div>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width='6' textAlign='center'>
                                                ผลดำเนินการ
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width='2' textAlign='center'>
                                                จัดการ
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                </Table>
                                <Form loading={loading}>
                                    <Table attached celled className='table-body'>
                                        <Table.Body className='table-body body'>
                                            {object_complaint_comment_list.length > 0 ? (
                                                object_complaint_comment_list.map(
                                                    (data: InterfaceComplaintComment, index: number) => (
                                                        <Table.Row key={index}>
                                                            <Table.Cell textAlign='center' width='1'>
                                                                <Label
                                                                    circular
                                                                    style={{
                                                                        backgroundColor: '#425679',
                                                                        color: '#FFFFFF',
                                                                    }}
                                                                >
                                                                    {row_qty * (current_page - 1) + index + 1}
                                                                </Label>
                                                            </Table.Cell>
                                                            <Table.Cell width='4'>
                                                                <div>{data.updated_at}</div>
                                                                <div>
                                                                    <label style={{ fontSize: '10px' }}>
                                                                        {' '}
                                                                        เลขที่รับส่วนกลาง : {
                                                                            data.central_complaint_no
                                                                        }{' '}
                                                                    </label>
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell width='6'>
                                                                <div>
                                                                    <label>{data.full_name}</label>
                                                                </div>
                                                                <div>
                                                                    <Rating
                                                                        icon='star'
                                                                        maxRating={5}
                                                                        rating={data.grade}
                                                                        disabled
                                                                        size='small'
                                                                    />
                                                                    &nbsp;{' '}
                                                                    <label style={{ fontSize: '10px' }}>
                                                                        {getDetailGrade(data?.grade)}
                                                                    </label>{' '}
                                                                    :
                                                                </div>
                                                                <div>
                                                                    {' '}
                                                                    <label style={{ fontSize: '10px' }}>
                                                                        {data.suggestion}
                                                                    </label>
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell width='2' textAlign='center'>
                                                                <Button
                                                                    onClick={() => handleClickDetail(data.id)}
                                                                    style={{
                                                                        backgroundColor: '#293757',
                                                                        color: '#ffffff',
                                                                    }}
                                                                >
                                                                    <Icon name='eye' style={{ color: '#ffffff' }} />
                                                                    ดูข้อมูล
                                                                </Button>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                )
                                            ) : (
                                                <Table.Row>
                                                    <Table.Cell textAlign='center' width='16'>
                                                        {'ไม่พบข้อมูล'}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                    paddingRight: 40,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column
                                            computer='11'
                                            tablet='16'
                                            mobile='16'
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}
export default CommentPeople
