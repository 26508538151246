import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import listPlugin from '@fullcalendar/list';
import { EventSourceInput } from '@fullcalendar/common';
import './index.css';

type CalendarProps = {
    events?: EventSourceInput;
    handleClickEvent?: (event: any) => void;
    handleClickDate?: (event: any) => void;
};

const CustomFullCalendar: React.FC<CalendarProps> = (props) => {
    let [event, setEvent] = useState<EventSourceInput | undefined>([]);
    useEffect(() => {
        setEvent(props.events);
    }, [props]);
    const handleClickDate = (event: any) => {
        if (props.handleClickDate) {
            let date = new Date(event.date);
            let value = `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${
                date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
            }/${date.getFullYear() + 543}`;
            props.handleClickDate(value);
        }
    };
    const handleClickEvent = (event: any) => {
        props.handleClickEvent && props.handleClickEvent(event.event);
    };
    return (
        <div className='demo-app'>
            <div className='demo-app-calendar'>
                <FullCalendar
                    initialView='dayGridMonth'
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,dayGridWeek,listMonth',
                    }}
                    buttonText={{
                        today: 'วันนี้',
                        month: 'เดือน',
                        week: 'สัปดาห์',
                        day: 'วัน',
                        list: 'รายการ',
                    }}
                    dayMaxEvents={true}
                    moreLinkText='เพิ่มเติม'
                    plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
                    locale='th'
                    weekends={true}
                    events={event}
                    eventClick={handleClickEvent}
                    dateClick={handleClickDate}
                />
            </div>
        </div>
    );
};
export default CustomFullCalendar;
