import React, { useEffect, useState, Fragment } from 'react'
import { useHistory } from 'react-router'
import {
    Grid,
    Segment,
    Dropdown,
    DropdownProps,
    Table,
    Icon,
    Label,
    Pagination,
    Image,
    Progress,
    Button,
} from 'semantic-ui-react'
import { GET_USERS_TECHNICIAN } from '../../../config/api/dashboard'
import { axiosAuthen } from '../../../config/axiosAuthen'
import { HOST } from '../../../config/router'
import { GET_TECHNICIAN_BY_DIRECTORY } from '../../../config/api/directoryOfMunicipality'

type DashboardProps = {
    year?: any
    start_date?: string
    end_date?: string
}

const DashboardResultTechnian: React.FC<DashboardProps> = (props) => {
    let { year, start_date, end_date } = props
    let history = useHistory()
    let directory_id = localStorage.directory_of_municipalities_id
    let [technician_id, setTechnicianId] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >('')

    let [full_name_technician] = useState<string | number | boolean | (string | number | boolean)[] | undefined>(
        undefined
    )
    let [technician_option, setTechnicianOption] = useState<[]>([])
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [list_data, setListData] = useState<[]>([])
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    const handleSearchTechnician = (e: any, { value }: DropdownProps) => {
        setTechnicianId(value)
    }

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleClickDetailTechnian = (
        id: number,
        full_name_th: string,
        directory_name: string,
        image: string,
        email: string,
        phon: string
    ) => {
        history.push(`/${localStorage.permission_en}/dashboard/detail-technician`, {
            technician_id: id,
            full_name_th: full_name_th,
            directory_name: directory_name,
            image: image,
            email: email,
            phon: phon,
        })
    }

    useEffect(() => {
        axiosAuthen
            .get(
                `${GET_USERS_TECHNICIAN}?year=${
                    year === 'all' ? '' : year
                }&technician_id=${technician_id}&directory_id=${directory_id}&page=${current_page}&offset=${row_qty}&start_date=${start_date}&end_date=${end_date}`
            )
            .then((response) => {
                let { value } = response.data
                setRecordQty(value.count)
                setListData(value.users)
            })

        axiosAuthen.get(`${GET_TECHNICIAN_BY_DIRECTORY}/${directory_id}`).then((response) => {
            let { value } = response.data
            let technician_option = value.map((data: any) => ({ value: data.id, text: data.full_name_th }))
            setTechnicianOption(technician_option)
        })
    }, [current_page, row_qty, year, technician_id, directory_id, start_date, end_date])

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Segment style={{ boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.25)' }}>
                        <div
                            style={{
                                padding: 15,
                            }}
                        >
                            <h3>ผลการปฏิบัติงานของเจ้าหน้าที่</h3>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column computer='16'>
                                        ชื่อ - นามสกุล
                                        <Dropdown
                                            fluid
                                            selection
                                            options={technician_option}
                                            value={full_name_technician}
                                            onChange={handleSearchTechnician}
                                            clearable
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column style={{ zIndex: 1 }}>
                                        <Fragment>
                                            <Table attached='top' celled className='table-header'>
                                                <Table.Header className='table-header header'>
                                                    <Table.Row>
                                                        <Table.HeaderCell textAlign='center' width='2'>
                                                            ลำดับ
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell width='5'>
                                                            <div>
                                                                <Icon name='user' /> ชื่อเจ้าหน้าที่
                                                            </div>
                                                            <div>สังกัดหน่วยงาน</div>
                                                        </Table.HeaderCell>

                                                        <Table.HeaderCell width='6' textAlign='center'>
                                                            ผลการดำเนินการ
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell width='3' textAlign='center'>
                                                            จัดการ
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                            </Table>
                                            <Table attached celled className='table-body'>
                                                <Table.Body className='table-body body'>
                                                    {list_data.length > 0 ? (
                                                        list_data.map((data: any, index: number) => (
                                                            <Table.Row
                                                                key={index}
                                                                onClick={() =>
                                                                    handleClickDetailTechnian(
                                                                        data.id,
                                                                        data.full_name_th,
                                                                        data.directory_name,
                                                                        data.image,
                                                                        data.email,
                                                                        data.phone
                                                                    )
                                                                }
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <Table.Cell textAlign='center' width='2'>
                                                                    <Label
                                                                        circular
                                                                        style={{
                                                                            backgroundColor: '#425679',
                                                                            color: '#FFFFFF',
                                                                        }}
                                                                    >
                                                                        {row_qty * (current_page - 1) + index + 1}
                                                                    </Label>
                                                                </Table.Cell>
                                                                <Table.Cell width='5'>
                                                                    <Grid>
                                                                        <Grid.Row>
                                                                            <Grid.Column computer='5'>
                                                                                <Image
                                                                                    size='tiny'
                                                                                    circular
                                                                                    src={
                                                                                        data.image
                                                                                            ? `${HOST}${data.image}`
                                                                                            : 'https://react.semantic-ui.com/images/avatar/large/patrick.png'
                                                                                    }
                                                                                    style={{
                                                                                        marginLeft: 20,
                                                                                        color: 'gray',
                                                                                        alignSeft: 'center',
                                                                                        marginTop: 15,
                                                                                        marginRight: 30,
                                                                                    }}
                                                                                />
                                                                            </Grid.Column>
                                                                            <Grid.Column computer='11'>
                                                                                <h4>{data.full_name_th}</h4>
                                                                                <span>
                                                                                    หน่วยงาน {data.directory_name}
                                                                                </span>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                </Table.Cell>
                                                                <Table.Cell width='6'>
                                                                    <Grid>
                                                                        <Grid.Row>
                                                                            <Grid.Column computer='5'>
                                                                                <span>คำร้องทั้งหมด</span>
                                                                                <br />
                                                                                <br />
                                                                                <span>ดำเนินการเสร็จสิ้น</span>
                                                                                <br />
                                                                                <br />
                                                                                <span>ยังไม่ดำเนินการ</span>
                                                                                <br />
                                                                                <br />
                                                                                <span>ไม่สามารถดำเนินการได้</span>
                                                                            </Grid.Column>
                                                                            <Grid.Column computer='1'>
                                                                                <span> {data.count_work}</span>
                                                                                <br />
                                                                                <br />
                                                                                <span> {data.count_work_success}</span>
                                                                                <br />
                                                                                <br />
                                                                                <span>
                                                                                    {' '}
                                                                                    {data.count_work_unsuccess}
                                                                                </span>
                                                                                <br />
                                                                                <br />
                                                                                <span> {data.count_work_unable}</span>
                                                                            </Grid.Column>
                                                                            <Grid.Column computer='2'>
                                                                                <span>รายการ</span>
                                                                                <br />
                                                                                <br />
                                                                                <span>รายการ</span>
                                                                                <br />
                                                                                <br />
                                                                                <span>รายการ</span>
                                                                                <br />
                                                                                <br />
                                                                                <span>รายการ</span>
                                                                            </Grid.Column>

                                                                            <Grid.Column computer='3'>
                                                                                <span> </span>
                                                                                <br />
                                                                                <br />
                                                                                <span>
                                                                                    {' '}
                                                                                    ({data.precent_work_success}%)
                                                                                </span>
                                                                                <br />
                                                                                <br />
                                                                                <span>
                                                                                    {' '}
                                                                                    ({data.precent_work_unsuccess}%)
                                                                                </span>
                                                                                <br />
                                                                                <br />
                                                                                <span>
                                                                                    {' '}
                                                                                    ({data.count_work_unable}%)
                                                                                </span>
                                                                            </Grid.Column>
                                                                            <Grid.Column computer='5'>
                                                                                <span> </span>
                                                                                <br />
                                                                                <br />
                                                                                <Progress
                                                                                    percent={data.precent_work_success}
                                                                                    success
                                                                                    size='tiny'
                                                                                    style={{ marginTop: '10px' }}
                                                                                />
                                                                                <Progress
                                                                                    percent={
                                                                                        data.precent_work_unsuccess
                                                                                    }
                                                                                    color='red'
                                                                                    size='tiny'
                                                                                />
                                                                                <Progress
                                                                                    percent={data.count_work_unable}
                                                                                    color='blue'
                                                                                    size='tiny'
                                                                                />
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                </Table.Cell>
                                                                <Table.Cell width='3' textAlign='center'>
                                                                    <Button
                                                                        onClick={() =>
                                                                            handleClickDetailTechnian(
                                                                                data.id,
                                                                                data.full_name_th,
                                                                                data.directory_name,
                                                                                data.image,
                                                                                data.email,
                                                                                data.phone
                                                                            )
                                                                        }
                                                                        style={{
                                                                            backgroundColor: '#293757',
                                                                            color: '#ffffff',
                                                                        }}
                                                                    >
                                                                        <Icon name='eye' style={{ color: '#ffffff' }} />
                                                                        ดูข้อมูล
                                                                    </Button>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        ))
                                                    ) : (
                                                        <Table.Row>
                                                            <Table.Cell textAlign='center' width='16'>
                                                                {'ไม่พบข้อมูล'}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                </Table.Body>
                                            </Table>
                                        </Fragment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column
                                        computer='5'
                                        mobile='16'
                                        tablet='5'
                                        style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label>
                                                แสดงข้อมูลลำดับที่{' '}
                                                {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                                {row_qty * (current_page - 1) + row_qty < record_qty
                                                    ? row_qty * (current_page - 1) + row_qty
                                                    : record_qty}{' '}
                                                จากทั้งหมด {record_qty} ข้อมูล
                                            </label>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column
                                        computer='11'
                                        mobile='16'
                                        tablet='11'
                                        style={{
                                            display: 'flex',
                                            marginTop: 10,
                                            justifyContent: 'flex-end',
                                        }}
                                        floated='right'
                                    >
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column
                                                    computer='5'
                                                    tablet='16'
                                                    mobile='16'
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <label style={{ alignSelf: 'center' }}>แสดง</label>
                                                    <Dropdown
                                                        placeholder='จำนวน'
                                                        selection
                                                        compact
                                                        fluid
                                                        name='row_qty'
                                                        value={row_qty}
                                                        options={size_row}
                                                        onChange={handleChangeRowSize}
                                                        style={{
                                                            marginLeft: 10,
                                                            marginRight: 10,
                                                            width: 80,
                                                            paddingRight: 40,
                                                        }}
                                                    />
                                                    <label
                                                        style={{
                                                            alignSelf: 'center',
                                                            minWidth: 'fit-content',
                                                        }}
                                                    >
                                                        {' แถว '}
                                                    </label>
                                                </Grid.Column>
                                                <Grid.Column
                                                    computer='11'
                                                    tablet='16'
                                                    mobile='16'
                                                    style={{ display: 'flex', justifyContent: 'center' }}
                                                >
                                                    <label
                                                        style={{
                                                            alignSelf: 'center',
                                                            minWidth: 'fit-content',
                                                        }}
                                                    >
                                                        หน้า
                                                    </label>
                                                    <Pagination
                                                        boundaryRange={1}
                                                        activePage={current_page}
                                                        siblingRange={1}
                                                        name='current_page'
                                                        size='small'
                                                        totalPages={page_qty}
                                                        firstItem={null}
                                                        lastItem={null}
                                                        onPageChange={(e: any, { activePage }: any) =>
                                                            handleChangePage(Math.ceil(Number(activePage)))
                                                        }
                                                        style={{ marginLeft: 10 }}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default DashboardResultTechnian
