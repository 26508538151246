import React, { Fragment, useState } from 'react'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import CustomModal from '../../../../../components/CustomModal'
import { Button, Dropdown, DropdownProps, Form, Grid, Input, InputOnChangeData } from 'semantic-ui-react'
import DatePicker from '../../../../../components/DatePicker'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { POST_COMPLAINT_CONTINUE_COMPLAINT } from '../../../../../config/api/complaint'
import { alert } from '../../../../../components/alert'

type FormProps = {
    complaint_id?: string | number
    reload?: () => void
}

const ContinueComplaint: React.FC<FormProps> = (props) => {
    let duration_option = [
        { value: 7, text: '1-7 วัน' },
        { value: 15, text: '8-15 วัน' },
        { value: 30, text: '16-30 วัน' },
    ]
    let [open, setOpen] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(false)
    let [duration, setDuration] = useState<{ value: number | string; text: string } | undefined>({
        value: '',
        text: '',
    })
    let [start_date, setStartDate] = useState<string>('')
    let [end_date, setEndDate] = useState<string>('')
    let [on_site_date, setOnSiteDate] = useState<string>('')
    let [errors_duration, setErrorDuration] = useState<boolean>(false)
    let [errors_start_date, setErrorStartDate] = useState<boolean>(false)
    let [errors_end_date, setErrorEndDate] = useState<boolean>(false)
    let [errors_on_site_date, setErrorOnSiteDate] = useState<boolean>(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setLoading(false)
        setDuration({ value: '', text: '' })
        setStartDate('')
        setEndDate('')
        setOnSiteDate('')
        setErrorDuration(false)
        setErrorStartDate(false)
        setErrorEndDate(false)
        setErrorOnSiteDate(false)
    }

    const handleChangeDuration = (e: any, { value }: DropdownProps) => {
        setDuration(duration_option.find((data) => data.value === Number(value)))
        setErrorDuration(false)
    }

    const handleChangeStartDate = (e: any, { value }: InputOnChangeData) => {
        setStartDate(value)
        if (duration?.value) {
            let new_date_format = value.split('/')
            let new_date = `${new_date_format[1]}/${new_date_format[0]}/${String(Number(new_date_format[2]) - 543)}`
            let end_date = new Date(new_date)
            end_date.setDate(end_date.getDate() + Number(duration.value) - 1)

            let format_end_date = `${end_date.getDate() < 10 ? `0${end_date.getDate()}` : end_date.getDate()}/${
                end_date.getMonth() + 1 < 10 ? `0${end_date.getMonth() + 1}` : end_date.getMonth() + 1
            }/${end_date.getFullYear() + 543}`
            setEndDate(format_end_date)
        }
        setErrorStartDate(false)
        setErrorEndDate(false)
    }

    const handleChangeOnSiteDate = (e: any, { value }: InputOnChangeData) => {
        setOnSiteDate(value)
        setErrorOnSiteDate(false)
    }
    const validate = () => {
        let error = false
        if (!duration?.value) {
            error = true
            setErrorDuration(true)
        }

        if (!start_date) {
            error = true
            setErrorStartDate(true)
        }

        if (!end_date) {
            error = true
            setErrorEndDate(true)
        }

        if (!on_site_date) {
            error = true
            setErrorOnSiteDate(true)
        }
        return !error
    }
    const handleSubmit = () => {
        setLoading(true)
        if (validate()) {
            let { complaint_id, reload } = props
            let body = {
                complaints_id: complaint_id,
                processing_time: duration?.text,
                processing_start_date: start_date,
                processing_end_date: end_date,
                on_site_date: on_site_date,
            }
            axiosAuthen
                .post(POST_COMPLAINT_CONTINUE_COMPLAINT, body)
                .then(() => {
                    setLoading(false)

                    alert({
                        type: 'success',
                        title: 'บันทึกเสร็จสิ้น',
                        timer: 1500,
                        onClosed: () => {
                            handleClose()
                            reload && reload()
                        },
                    })
                })
                .catch((error) => {
                    alert({
                        type: 'error',
                        title: 'ไม่สามารถบันทึกผลได้',
                        confirmText: 'ปิด',
                        onClosed: () => {
                            handleClose()
                            setLoading(false)
                        },
                    })
                })
        }
        setLoading(false)
    }

    return (
        <Fragment>
            <CustomModal
                icon='redo'
                header='ดำเนินการต่อ'
                open={open}
                trigger={
                    <ButtonIconOver fluid label='ดำเนินการต่อ' color='#51b2ac' icon='redo' basic onClick={handleOpen} />
                }
            >
                <Form loading={loading}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                            <Grid.Column computer='12' tablet='16' mobile='16' only='computer'>
                                <Form.Field error={errors_duration}>
                                    <label className='header-input-form'>ระยะเวลาดำเนินการ</label>
                                    <Dropdown
                                        fluid
                                        selection
                                        options={duration_option}
                                        value={duration?.value}
                                        onChange={handleChangeDuration}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                            <Grid.Column computer='6' tablet='16' mobile='16' only='computer'>
                                <Form.Field error={errors_start_date}>
                                    <label className='header-input-form'>วันที่เริ่มดำเนินการ</label>
                                    <DatePicker
                                        fluid
                                        name='start_date'
                                        value={start_date}
                                        onChange={handleChangeStartDate}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='6' tablet='16' mobile='16' only='computer'>
                                <Form.Field error={errors_end_date}>
                                    <label className='header-input-form'>สิ้นสุดวันที่ดำเนินการ</label>
                                    <Input fluid readOnly value={end_date} />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                            <Grid.Column computer='12' tablet='16' mobile='16' only='computer'>
                                <Form.Field error={errors_on_site_date}>
                                    <label className='header-input-form'>วันที่ลงพื้นที่</label>
                                    <DatePicker
                                        fluid
                                        name='on_site_date'
                                        value={on_site_date}
                                        onChange={handleChangeOnSiteDate}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='2' tablet='16' mobile='16' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default ContinueComplaint
