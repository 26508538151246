import React, { Fragment } from 'react'
import { Container, Grid, Icon, Segment } from 'semantic-ui-react'
import CustomSegment from '../../../../components/CustomSegment'
import GoogleMaps from '../../../../components/GoogleMaps'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import { GET_COMPLAINT_PRE_COMPLAINT_DETAIL } from '../../../../config/api/complaint'
import { useState } from 'react'
import FullFormatDateTH from '../../../../functions/FullFormatDateTH'
import { HOST } from '../../../../config/router'
import { useCallback } from 'react'
import AcceptPreComplaint from './AcceptPreComplaint'
import RejectPreComplaint from './RejectPreComplaint'
export interface InterfacePreComplaint {
    alley: string
    area_id: number
    area_name: string
    central_complaint_date: string
    central_complaint_no: string
    central_complaint_time: string
    community_id: number
    community_name: string
    complaint_date: string
    complaint_detail: string
    complaint_subject: string
    complaint_topics_id: number
    topic_id: number
    topic_name: string
    type_id: number
    type_name: string
    complaint_types_id: number
    complaint_types_name: string
    contact_id: number
    contact_name: string
    created_by_id: number
    pre_complaint_date: string
    pre_complaint_no: string
    directory_of_municipalities_id: number
    directory_of_municipalities_name: string
    district: string
    email: string
    files: InterfaceFileComplaint[]
    first_name: string
    full_name: string
    full_name_created: string
    full_name_technician: string
    grade: string
    house_no: string
    id: number
    last_name: string
    lat: string
    lng: string
    mobile_number: string
    on_site_date: string
    phone_number: string
    pre_complaint_id: number
    prefix: string
    processing_detail: string
    processing_end_date: string
    processing_start_date: string
    processing_time: string
    province: string
    road: string
    save_process_at: string
    status_id: number
    status_name: string
    subdistrict: string
    suggestion: string
    user_id: number
    village_no: string
    year: string
    zipcode: string
}

interface InterfaceFileComplaint {
    action: string
    complaints_id: number
    created_at: string
    created_by: number
    deleted_at: string
    id: number
    name: string
    path_file: string
    sequence: number
    type: string
    updated_at: string
    updated_by: number
}
const DetailPreComplaint: React.FC = () => {
    let location = useLocation()
    let [pre_complaint, setComplaintDetail] = useState<InterfacePreComplaint>()

    const getDetailPreComplaint = useCallback(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { pre_complaint_id } = location_state
            axiosAuthen.get(`${GET_COMPLAINT_PRE_COMPLAINT_DETAIL}/${pre_complaint_id}`).then((response) => {
                let { value } = response.data
                setComplaintDetail(value)
                let element = document.getElementById('pusher')
                element?.scrollTo({ top: 0, behavior: 'smooth' })
            })
        }
    }, [location])

    useEffect(() => {
        getDetailPreComplaint()
    }, [getDetailPreComplaint])

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 style={{ textAlign: 'center' }}>รายละเอียดคำร้อง</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='8' tablet='8' mobile='16'>
                                <Segment>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='5'>
                                                <h5>ข้อมูลคำร้องเลขที่รับ</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{pre_complaint?.pre_complaint_no}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='5'>
                                                <h5>สถานะคำร้อง</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{pre_complaint?.status_name}</Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer='8' tablet='8' mobile='16'>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column computer='8' tablet='8' mobile='16'>
                                            <RejectPreComplaint pre_complaint_id={pre_complaint?.id} />
                                        </Grid.Column>
                                        <Grid.Column computer='8' tablet='8' mobile='16'>
                                            <AcceptPreComplaint pre_complaint={pre_complaint} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <CustomSegment icon='file alternate' header='ข้อมูลคำร้อง'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>เรื่องที่ยื่นคำร้อง</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='5'>{pre_complaint?.complaint_subject}</Grid.Column>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>วัน / เดือน / ปี ที่ยื่นคำร้อง</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='5'>
                                                {FullFormatDateTH(pre_complaint?.pre_complaint_date)}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>ช่องทางการรับเรื่อง</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='5'>{pre_complaint?.contact_name}</Grid.Column>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>หัวข้อเรื่องร้องทุกข์</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='5'>{pre_complaint?.topic_name}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>รายละเอียด</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='5'>{pre_complaint?.complaint_detail}</Grid.Column>
                                            <Grid.Column computer='8' />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>ไฟล์แนบ</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='5'>
                                                <Grid>
                                                    {pre_complaint?.files && pre_complaint.files.length > 0 ? (
                                                        pre_complaint.files.map(
                                                            (file: InterfaceFileComplaint, index: number) => (
                                                                <Grid.Row key={index}>
                                                                    <Grid.Column
                                                                        computer='16'
                                                                        style={{ display: 'flex' }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                padding: '10px 3px',
                                                                                borderRadius: 8,
                                                                                backgroundColor: '#4D648D',
                                                                                marginRight: 5,
                                                                            }}
                                                                        />
                                                                        {file.type === 'PNG' ||
                                                                        file.type === 'png' ||
                                                                        file.type === 'jpg' ||
                                                                        file.type === 'JPG' ||
                                                                        file.type === 'JEPG' ||
                                                                        file.type === 'jepg' ? (
                                                                            <a
                                                                                href={`${HOST}${file.path_file}`}
                                                                                target={'_blank'}
                                                                                rel='noreferrer'
                                                                            >
                                                                                <Icon
                                                                                    name='file image outline'
                                                                                    size='large'
                                                                                    style={{ color: '#75B9A5' }}
                                                                                />
                                                                                <label
                                                                                    style={{
                                                                                        color: '#283655',
                                                                                        textDecoration: 'underline',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    {file.name}
                                                                                </label>
                                                                            </a>
                                                                        ) : (
                                                                            <a
                                                                                href={`${HOST}${file.path_file}`}
                                                                                target={'_blank'}
                                                                                rel='noreferrer'
                                                                            >
                                                                                <Icon
                                                                                    name='file alternate'
                                                                                    size='large'
                                                                                    style={{ color: '#D85F53' }}
                                                                                />
                                                                                <label
                                                                                    style={{
                                                                                        color: '#283655',
                                                                                        textDecoration: 'underline',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    {file.name}
                                                                                </label>
                                                                            </a>
                                                                        )}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )
                                                        )
                                                    ) : (
                                                        <Grid.Row>
                                                            <Grid.Column computer='16'>
                                                                <label>-</label>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <CustomSegment header='ข้อมูลผู้ร้อง' icon='user'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>ชื่อ - สกุล</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='5'>
                                                {pre_complaint?.prefix}
                                                {pre_complaint?.first_name} {pre_complaint?.last_name}
                                            </Grid.Column>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>โทรศัพท์ ( บ้าน )</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='5'>
                                                {pre_complaint?.phone_number ? pre_complaint.phone_number : '-'}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>ที่อยู่</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='5'>
                                                บ้านเลขที่ {pre_complaint?.house_no} หมู่ที่ {pre_complaint?.village_no}{' '}
                                                <br />
                                                ซอย {pre_complaint?.alley ? pre_complaint.alley : '-'} <br />
                                                ถนน {pre_complaint?.road ? pre_complaint.road : '-'} <br />
                                                ตำบล{pre_complaint?.subdistrict} อำเภอ{pre_complaint?.district}
                                                <br />
                                                จังหวัด{pre_complaint?.province} {pre_complaint?.zipcode}
                                            </Grid.Column>
                                            <Grid.Column computer='3' textAlign='right'>
                                                <h5>โทรศัพท์ ( มือถือ )</h5>
                                                <h5>อีเมล</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='5'>
                                                <h5 style={{ fontWeight: 'lighter' }}>
                                                    {pre_complaint?.mobile_number ? pre_complaint.mobile_number : '-'}
                                                </h5>
                                                <h5 style={{ fontWeight: 'lighter' }}>
                                                    {pre_complaint?.email ? pre_complaint.email : '-'}
                                                </h5>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                        {pre_complaint?.lat && pre_complaint?.lng && (
                            <Grid.Row>
                                <Grid.Column>
                                    <CustomSegment header='ตำแหน่งที่ตั้ง' icon='map marker alternate'>
                                        <GoogleMaps
                                            style={{ widht: 420, height: 420 }}
                                            value={{ lat: Number(pre_complaint.lat), lng: Number(pre_complaint.lng) }}
                                        />
                                    </CustomSegment>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default DetailPreComplaint
