import { ActionInterface } from '../../../types'
import { ComplaintFormStep3State } from '../../../types/Complaint/Form/Step3'

const initialState: ComplaintFormStep3State = {
    position: {
        lat: 0,
        lng: 0,
    },
    position_error: false,
}

const ComplaintFormStep3 = (state = initialState, action: ActionInterface): ComplaintFormStep3State => {
    switch (action.type) {
        case 'setStateByNameAndValueComplaintFormStep3':
            return action.name
                ? {
                      ...state,
                      [action.name]: action.value,
                  }
                : { ...state }
        case 'appendFormComplaintFormStep3':
            return {
                ...state,
                ...action.value,
            }
        case 'resetFormByReducerNameComplaintFormStep3':
            return {
                ...state,
                ...{
                    position: {
                        lat: 0,
                        lng: 0,
                    },
                    position_error: false,
                },
            }
        default:
            return state
    }
}

export default ComplaintFormStep3
