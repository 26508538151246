import React, { Fragment, useState } from 'react'
import { Button, Form, Grid, Segment, Rating } from 'semantic-ui-react'
import CustomModal from '../../../../../components/CustomModal'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { GET_COMPLAINT_EVALUATE_COMPLAINT_DETAIL } from '../../../../../config/api/complaint'
import { useEffect } from 'react'

type FormProps = {
    complaint_id?: string | number
}
const EvaluateComplaintDetail: React.FC<FormProps> = (props) => {
    let [open, setOpen] = useState<boolean>(false)
    let [rating, setRating] = useState<string | number | undefined>(1)
    let [detail, setDetail] = useState<string | number | undefined>('')
    let [pre_complaints_id, setPreComplaintId] = useState<number>(0)
    let [pre_complaints_rating, setPreComplaintRating] = useState<string>('')
    let [pre_complaints_suggestion, setPreComplaintSuggestion] = useState<string>('')

    useEffect(() => {
        axiosAuthen.get(`${GET_COMPLAINT_EVALUATE_COMPLAINT_DETAIL}/${props.complaint_id}`).then((response) => {
            let { value } = response.data
            setRating(value.grade)
            setDetail(value.suggestion)
            setPreComplaintId(value.pre_complaints_id)
            setPreComplaintRating(value.pre_complaints_grade)
            setPreComplaintSuggestion(value.pre_complaints_suggestion)
        })
    }, [props.complaint_id])
    const handleOpen = () => {
        setOpen(!open)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Fragment>
            <CustomModal
                icon='file alternate'
                header='ผลการประเมินการทำงาน'
                open={open}
                size={pre_complaints_id ? 'small' : 'tiny'}
                trigger={
                    <ButtonIconOver
                        fluid
                        label='ดูผลประเมินการปฏิบัติงาน'
                        color='#93BE52'
                        icon='clipboard'
                        basic
                        onClick={handleOpen}
                    />
                }>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={pre_complaints_id ? '8' : '16'} tablet='8' mobile='16'>
                            <Segment>
                                <Form>
                                    <h4>ผลประเมินจากธุรการ</h4>
                                    <Segment>
                                        <Rating icon='star' maxRating={5} rating={rating} disabled size='massive' />
                                    </Segment>
                                    <div>
                                        <h4>หมายเหตุ</h4>5 ดาว = ดีมาก, 4 ดาว = ดี, 3 ดาว = ปานกลาง, 2 ดาว = พอใช้, 1
                                        ดาว = ปรับปรุง
                                    </div>
                                    <h4>รายละเอียด</h4>
                                    <Segment>{detail ? detail : '-'}</Segment>
                                </Form>
                            </Segment>
                        </Grid.Column>
                        {pre_complaints_id > 0 && (
                            <Grid.Column computer='8' tablet='8' mobile='16'>
                                <Segment style={{ backgroundColor: '#E9F3FC' }}>
                                    <Form>
                                        <h4 style={{ color: '#425679' }}>ผลประเมินจากประชาชน</h4>
                                        <Segment>
                                            <Rating
                                                icon='star'
                                                maxRating={5}
                                                rating={pre_complaints_rating}
                                                disabled
                                                size='massive'
                                            />
                                        </Segment>
                                        <div>
                                            <h4>หมายเหตุ</h4>5 ดาว = ดีมาก, 4 ดาว = ดี, 3 ดาว = ปานกลาง, 2 ดาว = พอใช้,
                                            1 ดาว = ปรับปรุง
                                        </div>
                                        <h4 style={{ color: '#425679' }}>ข้อเสนอแนะ</h4>
                                        <div style={{ color: '#425679' }}>
                                            {pre_complaints_suggestion ? pre_complaints_suggestion : '-'}
                                        </div>
                                    </Form>
                                </Segment>
                            </Grid.Column>
                        )}
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Button
                                style={{
                                    width: 100,
                                    backgroundColor: '#283655',
                                    color: '#FFF',
                                }}
                                onClick={handleClose}>
                                ปิด
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </CustomModal>
        </Fragment>
    )
}

export default EvaluateComplaintDetail
