import { HOST } from '../router';

const USER_MANUAL = `${HOST}/user_manual`;
export const GET_USER_MANUAL_TOPIC_ALL = `${USER_MANUAL}/topic_all`;
export const GET_USER_MANUAL_TOPIC_LIST = `${USER_MANUAL}/topic_list`;
export const POST_USER_MANUAL_CREATE_TOPIC = `${USER_MANUAL}/create_topic`;
export const PUT_USER_MANUAL_ACTIVE_TOPIC = `${USER_MANUAL}/active_topic`;
export const PUT_USER_MANUAL_EDIT_TOPIC = `${USER_MANUAL}/edit_topic`;
export const DELETE_USER_MANUAL_DELETE_TOPIC = `${USER_MANUAL}/delete_topic`;
export const GET_USER_MANUAL_TOPIC_DETAIL = `${USER_MANUAL}/topic_detail`;
export const GET_USER_MANUAL_SUB_TOPIC_ALL = `${USER_MANUAL}/sub_topic_all`;
export const GET_USER_MANUAL_SUB_TOPIC_LIST = `${USER_MANUAL}/sub_topic_list`;
export const PUT_USER_MANUAL_ACTIVE_SUB_TOPIC = `${USER_MANUAL}/active_sub_topic`;
export const POST_USER_MANUAL_CREATE_SUB_TOPIC = `${USER_MANUAL}/create_sub_topic`;
export const PUT_USER_MANUAL_UPLOAD_FILE_SUB_TOPIC = `${USER_MANUAL}/upload_file_sub_topic`;
export const PUT_USER_MANUAL_EDIT_SUB_TOPIC = `${USER_MANUAL}/edit_sub_topic`;
export const DELETE_USER_MANUAL_DELETE_SUB_TOPIC = `${USER_MANUAL}/delete_sub_topic`;
export const GET_USER_MANUAL_SUB_TOPIC_DETAIL = `${USER_MANUAL}/sub_topic_detail`;
export const GET_USER_MANUAL_CONTENT_LIST = `${USER_MANUAL}/content_list`;
export const PUT_USER_MANUAL_ACTIVE_CONTENT = `${USER_MANUAL}/active_content`;
export const POST_USER_MANUAL_CREATE_CONTENT = `${USER_MANUAL}/create_content`;
export const PUT_USER_MANUAL_UPLOAD_FILE_CONTENT = `${USER_MANUAL}/upload_file_content`;
export const PUT_USER_MANUAL_EDIT_CONTENT = `${USER_MANUAL}/edit_content`;
export const DELETE_USER_MANUAL_DELETE_CONTENT = `${USER_MANUAL}/delete_content`;
export const GET_USER_MANUAL_ALL = `${USER_MANUAL}/user_manual_all`;
export const POST_CREATE_HAS_USER_MANUAL = `${USER_MANUAL}/create_has_user_manual`;
export const PUT_EDIT_HAS_USER_MANUAL = `${USER_MANUAL}/edit_has_user_manual`;
export const DELETE_HAS_USER_MANUAL = `${USER_MANUAL}/delete_has_user_manual`;
export const GET_HAS_USER_MANUAL_LIST = `${USER_MANUAL}/has_user_manual_list`;
export const GET_USER_MANUAL_CONTENT_ALL = `${USER_MANUAL}/content_all`;
