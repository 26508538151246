import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Button,
    Container,
    Dimmer,
    Dropdown,
    DropdownProps,
    Grid,
    Icon,
    Input,
    InputOnChangeData,
    Loader,
    Segment,
    Transition,
} from 'semantic-ui-react'
import CustomSegment from '../../../components/CustomSegment'
import GoogleMapListMarker from '../../../components/GoogleMapsListMarker'
import { GET_AREAS_AND_COUMMUNITIES_ALL } from '../../../config/api/areasAndCommunities'
import { GET_COMPLAINT_MAP, GET_COMPLAINT_STATUS_ALL } from '../../../config/api/complaint'
import { GET_COMPLAINT_TOPIC_AND_TYPES_ALL } from '../../../config/api/complaintTopicAndTypes'
import { GET_DASHBOARD_YEARS } from '../../../config/api/dashboard'
import { GET_DIRECTORY_OF_MUNICIPALITY_BY_ROLE } from '../../../config/api/directoryOfMunicipality'
import { axiosAuthen } from '../../../config/axiosAuthen'

interface InterfaceDataMarker {
    areas: string
    central_complaint_no: string
    communities: string
    complaint_subject: string
    department_complaint_no: string
    directory_of_municipalities_name: string
    full_name_technician: string
    id: number
    lat: string
    lng: string
    status_color: string
    status_name: string
    topic_name: string
    type_name: string
}

const DashboardMap: React.FC = () => {
    let history = useHistory()
    let [filter, setFilter] = useState<boolean>(false)
    let [area_option, setAreaOption] = useState<{ value: string; text: string }[]>([])
    let [area, setArea] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [year_option, setYearOption] = useState<{ value: string; text: string }[]>([])
    let [year, setYear] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [status_option, setStatusOption] = useState<{ value: string; text: string }[]>([])
    let [status, setStatus] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [directory_option, setDirectoryOption] = useState<{ value: string; text: string }[]>([])
    let [directory, setDirectory] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [topic, setTopic] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [topic_option, setTopicOption] = useState<{ value: string; text: string }[]>([])
    let [complaint_no, setComplaintNo] = useState('')
    let [list_marker, setListMarker] = useState<InterfaceDataMarker[]>([])
    let [loading, setLoading] = useState<boolean>(true)
    let [data_marker, setDataMarker] = useState<InterfaceDataMarker>()

    const handleClickFilter = () => {
        setFilter(!filter)
    }

    const handleClearFilter = () => {
        setArea('')
        setYear('')
        setStatus('')
        setDirectory('')
        setTopic('')
        setComplaintNo('')
    }

    const handleCloesFilter = () => {
        setFilter(false)
        handleClearFilter()
    }

    useEffect(() => {
        axiosAuthen.get(GET_DASHBOARD_YEARS).then((response) => {
            let { value } = response.data
            let year_option = value.map((data: any) => ({ value: data, text: data }))
            setYearOption(year_option)
        })

        axiosAuthen.get(GET_COMPLAINT_TOPIC_AND_TYPES_ALL).then((response) => {
            let { value } = response.data
            let topic_option = value.map((data: any) => ({ value: data.id, text: data.name }))
            setTopicOption(topic_option)
        })

        axiosAuthen.get(GET_AREAS_AND_COUMMUNITIES_ALL).then((response) => {
            let { value } = response.data
            let area_option = value.map((data: any) => ({ value: data.id, text: data.name }))
            setAreaOption(area_option)
        })

        axiosAuthen.get(GET_COMPLAINT_STATUS_ALL).then((response) => {
            let { value } = response.data
            let status_option = value.map((data: any) => ({ value: data.id, text: data.name }))
            setStatusOption(status_option)
        })

        axiosAuthen.get(GET_DIRECTORY_OF_MUNICIPALITY_BY_ROLE).then((response) => {
            let { value } = response.data
            let directory_option = value.map((data: any) => ({ value: data.id, text: data.name }))
            setDirectoryOption(directory_option)
        })
    }, [])

    const handleSearchTopic = (_: any, { value }: DropdownProps) => {
        setTopic(value)
    }

    const handleSearchArea = (_: any, { value }: DropdownProps) => {
        setArea(value)
    }

    const handleSearchStatus = (_: any, { value }: DropdownProps) => {
        setStatus(value)
    }

    const handleSearchYear = (_: any, { value }: DropdownProps) => {
        setYear(value)
    }

    const handleSearchDirectory = (_: any, { value }: DropdownProps) => {
        setDirectory(value)
    }

    const handleSearchComplaintNo = (e: any, { value }: InputOnChangeData) => {
        setComplaintNo(value)
    }

    const getComplaintList = useCallback(() => {
        let searh_option = `central_complaint_no=${complaint_no}&topic_id=${topic}&area_id=${area}&status_id=${status}&fiscal_year=${year}&directory_id=${directory}`
        axiosAuthen.get(`${GET_COMPLAINT_MAP}?${searh_option}`).then((response) => {
            // axiosAuthen.get(`${GET_COMPLAINT_LIST}`).then((response) => {
            let { value } = response.data
            // setComplaintDetail(value)
            setListMarker(value)
        })
    }, [area, complaint_no, status, topic, year, directory])

    useEffect(() => {
        getComplaintList()
    }, [getComplaintList])

    const handleClickMarker = (data: any) => {
        setDataMarker(data)
    }
    const loadMap = () => {
        setLoading(false)
    }
    return (
        <Fragment>
            <div className='background-container'>
                {loading && (
                    <Dimmer active inverted page>
                        <Loader inverted content='รอสักครู่' />
                    </Dimmer>
                )}
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>สรุปผลการดำเนินการตามสถานะคำร้องทุกข์</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='13' mobile='16' />
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        icon
                                        labelPosition='left'
                                        fluid
                                        style={{ backgroundColor: '#4D648D', color: 'white' }}
                                        onClick={handleClickFilter}>
                                        <Icon name='filter' /> กรองข้อมูล
                                    </Button>
                                    {(year || area || status || directory || topic || complaint_no) && (
                                        <Icon
                                            name='delete'
                                            style={{
                                                display: 'inline-flex',
                                                border: '1px solid rgb(182, 57, 45)',
                                                borderRadius: 10,
                                                height: 'auto',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgb(182, 57, 45)',
                                                zIndex: 1,
                                                position: 'absolute',
                                                right: 20,
                                                top: 7,
                                                paddingRight: 10,
                                                paddingLeft: 10,
                                                paddingBottom: 1,
                                                color: 'white',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleClearFilter}
                                        />
                                    )}
                                </div>
                            </Grid.Column>
                            <Grid.Column computer='16' mobile='16'>
                                <Transition.Group animation='slide down' duration='500'>
                                    {filter && (
                                        <Segment style={{ marginTop: 15 }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='5'>
                                                        ปีงบประมาณ
                                                        <Dropdown
                                                            name='year'
                                                            selection
                                                            fluid
                                                            value={year}
                                                            options={year_option}
                                                            onChange={handleSearchYear}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='5'>
                                                        เขต
                                                        <Dropdown
                                                            name='area'
                                                            selection
                                                            fluid
                                                            value={area}
                                                            options={area_option}
                                                            onChange={handleSearchArea}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='5'>
                                                        หน่วยงาน
                                                        <Dropdown
                                                            name='directory'
                                                            selection
                                                            fluid
                                                            value={directory}
                                                            options={directory_option}
                                                            onChange={handleSearchDirectory}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='5'>
                                                        สถานะคำร้อง
                                                        <Dropdown
                                                            name='status'
                                                            selection
                                                            fluid
                                                            value={status}
                                                            options={status_option}
                                                            onChange={handleSearchStatus}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='5'>
                                                        เรื่องร้องเรียน
                                                        <Dropdown
                                                            name='topic'
                                                            selection
                                                            fluid
                                                            value={topic}
                                                            options={topic_option}
                                                            onChange={handleSearchTopic}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='5'>
                                                        เลขที่ส่วนกลาง
                                                        <Input
                                                            name='complaint_no'
                                                            fluid
                                                            value={complaint_no}
                                                            onChange={handleSearchComplaintNo}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='12' />
                                                    <Grid.Column computer='2' style={{ marginTop: 10 }}>
                                                        <Button
                                                            icon
                                                            labelPosition='left'
                                                            fluid
                                                            style={{ backgroundColor: '#4D648D', color: 'white' }}
                                                            onClick={handleClearFilter}>
                                                            ล้างข้อมูล
                                                        </Button>
                                                    </Grid.Column>
                                                    <Grid.Column computer='2' style={{ marginTop: 10 }}>
                                                        <Button type='submit' onClick={handleCloesFilter}>
                                                            ปิด
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    )}
                                </Transition.Group>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <CustomSegment icon='map marker alternate' header='ตำแหน่งที่ตั้งสถานที่'>
                                    {data_marker && (
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='6' mobile='16'>
                                                    <Segment
                                                        style={{
                                                            position: 'absolute',
                                                            marginTop: 90,
                                                            zIndex: 1,
                                                            marginLeft: 15,
                                                            overflow: 'auto',
                                                            transition: '1s',
                                                            maxHeight: window.innerHeight / 1.2,
                                                            top: '15%',
                                                        }}>
                                                        <Grid>
                                                            <Grid.Column floated='right'>
                                                                <Button
                                                                    icon
                                                                    floated='right'
                                                                    style={{
                                                                        backgroundColor: 'white',
                                                                        position: 'absolute',
                                                                        right: 0,
                                                                        zIndex: 1000,
                                                                    }}>
                                                                    <Icon
                                                                        name='close'
                                                                        size='big'
                                                                        onClick={() => {
                                                                            setDataMarker(undefined)
                                                                        }}
                                                                    />
                                                                </Button>
                                                            </Grid.Column>
                                                            <Grid.Row>
                                                                <Grid.Column computer='6'>
                                                                    <b>เลขที่รับ</b>
                                                                </Grid.Column>
                                                                <Grid.Column computer='10'>
                                                                    {data_marker?.central_complaint_no}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column computer='6'>
                                                                    <b>หน่วยงาน</b>
                                                                </Grid.Column>
                                                                <Grid.Column computer='10'>
                                                                    {data_marker?.directory_of_municipalities_name}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column computer='6'>
                                                                    <b>พื้นที่ (เขต / ชุมชน)</b>
                                                                </Grid.Column>
                                                                <Grid.Column computer='10'>
                                                                    <b>เขต</b> :{' '}
                                                                    {data_marker?.areas ? data_marker?.areas : '-'}
                                                                    <br />
                                                                    <br />
                                                                    <b>ชุมชน</b> :{' '}
                                                                    {data_marker?.communities
                                                                        ? data_marker?.communities
                                                                        : '-'}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column computer='6'>
                                                                    <b>เรื่องร้องทุกข์</b>
                                                                </Grid.Column>
                                                                <Grid.Column computer='10'>
                                                                    {data_marker?.topic_name}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column computer='6'>
                                                                    <b>ประเภทงาน </b>
                                                                </Grid.Column>
                                                                <Grid.Column computer='10'>
                                                                    {data_marker?.type_name}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column computer='6'>
                                                                    <b>ผู้รับผิดชอบ</b>
                                                                </Grid.Column>
                                                                <Grid.Column computer='10'>
                                                                    {data_marker?.full_name_technician}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column computer='6'>
                                                                    <b>สถานะ</b>
                                                                </Grid.Column>
                                                                <Grid.Column computer='10'>
                                                                    {data_marker?.status_name}{' '}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column
                                                                    computer='16'
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                    }}>
                                                                    <Button
                                                                        primary
                                                                        onClick={() => {
                                                                            history.push(
                                                                                `/${localStorage.permission_en}/complaint/detail`,
                                                                                { complaint_id: data_marker?.id }
                                                                            )
                                                                        }}>
                                                                        รายละเอียด
                                                                    </Button>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    )}
                                    {/* {list_marker.length > 0 && ( */}
                                    <GoogleMapListMarker
                                        listData={list_marker}
                                        onLoaded={loadMap}
                                        onClick={handleClickMarker}
                                    />
                                    {/* )}  */}
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default DashboardMap
