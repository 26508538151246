import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Container, Grid, Image } from 'semantic-ui-react'
import ButtonIconOver from '../../../components/ButtonIconOver'
import CustomSegment from '../../../components/CustomSegment'
import { GET_USER_DETAIL, GET_USER_DETAIL_BY_ID } from '../../../config/api/user'
import { axiosAuthen } from '../../../config/axiosAuthen'
import { HOST } from '../../../config/router'
import ManagementUserDetailChangePassword from './ModalChangePassword'

interface InterfaceUserDetail {
    alley: string
    birth_date: string
    directory: InterfaceDirectory[]
    district: string
    email: string
    first_name_en: string
    first_name_th: string
    house_no: string
    id_card_type: string
    id_card_number: string
    image: string
    last_name_en: string
    last_name_th: string
    municipalities_name: string
    one_mail: string
    phone_number: string
    position: string
    prefix_en: string
    prefix_th: string
    province: string
    road: string
    roles_name: string
    sub_district: string
    username: string
    village_no: string
    zipcode: string
}

interface InterfaceDirectory {
    directory_id: number
    directory_name: string
    parent_id: number
}

const UserDetail: React.FC = () => {
    let [user_id, setUserId] = useState<number>()
    let [open_modal_form, setOpenModalForm] = useState<boolean>(false)
    let location = useLocation()
    let history = useHistory()

    let [user_data, setUserData] = useState<InterfaceUserDetail>()
    useEffect(() => {
        let { state } = location
        let user_id: number | undefined
        if (state) {
            let obj = JSON.parse(JSON.stringify(state, null, ' '))
            user_id = { user_id } = obj
        }
        axiosAuthen.get(user_id ? `${GET_USER_DETAIL_BY_ID}/${user_id}` : `${GET_USER_DETAIL}`).then((response) => {
            let { value } = response.data
            user_id && setUserId(user_id)
            setUserData(value)
        })
        window.scrollTo(0, 0)
    }, [location])

    const handleCloseModalForm = () => setOpenModalForm(false)

    const handleEditProfile = () => {
        history.push('user-detail/form-edit', { user_id: user_id })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    {open_modal_form && (
                        <ManagementUserDetailChangePassword open={open_modal_form} onClose={handleCloseModalForm} />
                    )}
                    <Grid>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>ข้อมูลส่วนตัว</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16' mobile='16'>
                                <CustomSegment icon='user circle' header='รายละเอียดข้อมูลส่วนตัว'>
                                    <Grid divided>
                                        <Grid.Row>
                                            <Grid.Column computer='11' tablet='16' mobile='16'>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column computer='1' tablet='16' mobile='16'></Grid.Column>
                                                        <Grid.Column computer='5' tablet='16' mobile='16'>
                                                            <div style={{ justifyContent: 'center' }}>
                                                                {user_data?.image && (
                                                                    <Image
                                                                        bordered
                                                                        style={{
                                                                            width: 150,
                                                                            height: 150,
                                                                            borderRadius: 500,
                                                                        }}
                                                                        src={`${HOST}${user_data?.image}`}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column computer='10' tablet='16' mobile='16'>
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <h3>{`${user_data?.prefix_th}${user_data?.first_name_th} ${user_data?.last_name_th}`}</h3>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='5' tablet='16' mobile='16'>
                                                                        <h4>สิทธิ์การใช้งาน</h4>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='10' tablet='16' mobile='16'>
                                                                        <h4>{`${user_data?.roles_name}`}</h4>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='5' tablet='16' mobile='16'>
                                                                        <h4>หมายเลขโทรศัพท์</h4>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='10' tablet='16' mobile='16'>
                                                                        <h4>{`${user_data?.phone_number}`}</h4>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='5' tablet='16' mobile='16'>
                                                                        <h4>ตำแหน่งงาน</h4>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='10' tablet='16' mobile='16'>
                                                                        <h4>{`${user_data?.position || '-'}`}</h4>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='5' tablet='16' mobile='16'>
                                                                        <h4>องค์กร</h4>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='10' tablet='16' mobile='16'>
                                                                        <h4>
                                                                            {`${user_data?.municipalities_name}` || '-'}
                                                                        </h4>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='5' tablet='16' mobile='16'>
                                                                        <h4>สังกัดหน่วยงาน</h4>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='10' tablet='16' mobile='16'>
                                                                        <h4>
                                                                            {(user_data?.directory &&
                                                                                `${
                                                                                    user_data?.directory.pop()
                                                                                        ?.directory_name
                                                                                }`) ||
                                                                                '-'}
                                                                        </h4>
                                                                    </Grid.Column>
                                                                </Grid.Row>

                                                                <Grid.Row>
                                                                    <Grid.Column computer='5' tablet='16' mobile='16'>
                                                                        <h4>อีเมล</h4>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='10' tablet='16' mobile='16'>
                                                                        <h4>{`${user_data?.email}`}</h4>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='5' tablet='16' mobile='16'>
                                                                        <h4>วันเดือนปีเกิด (พ.ศ.)</h4>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='10' tablet='16' mobile='16'>
                                                                        <h4>{`${user_data?.birth_date}`}</h4>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column computer='5' tablet='16' mobile='16'>
                                                <Grid>
                                                    <Grid.Row>
                                                        {Number(localStorage.getItem('user_id')) === user_id && (
                                                            <Fragment>
                                                                <Grid.Column computer='3' />
                                                                <Grid.Column textAlign='center' computer='10'>
                                                                    <ButtonIconOver
                                                                        fluid
                                                                        icon='key'
                                                                        label='เปลี่ยนรหัสผ่าน'
                                                                        color='#425679'
                                                                        onClick={() => setOpenModalForm(true)}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column computer='3' />
                                                            </Fragment>
                                                        )}

                                                        <Grid.Column computer='3' />
                                                        <Grid.Column textAlign='center' computer='10'>
                                                            <ButtonIconOver
                                                                fluid
                                                                icon='edit'
                                                                label='แก้ไขข้อมูลเจ้าหน้าที่'
                                                                color='#F7931E'
                                                                onClick={handleEditProfile}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='3' />
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>

                        {/* <Grid.Row stretched>
                            <Grid.Column computer='6'>
                                <Segment className='custom-segment' style={{ marginTop: '45px !important' }}>
                                    <Grid style={{ margin: 5 }}>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Modal
                                                size='small'
                                                open={show_modal_upload}
                                                onClose={closeModal}
                                                closeOnEscape={false}
                                                closeOnDimmerClick={false}>
                                                <Modal.Header
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: '50%',
                                                        width: ' 100%',
                                                        transform: 'translateX(-50%)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        zIndex: 1,
                                                    }}>
                                                    อัปโหลดรูปภาพ
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Segment
                                                        loading={loading_image}
                                                        style={{ padding: 0, marginTop: 40, paddingBottom: 30 }}>
                                                        <div style={{ height: 600 }}>
                                                            <Cropper
                                                                image={path_image}
                                                                crop={crop}
                                                                zoom={zoom}
                                                                aspect={3 / 3}
                                                                // style={{ height: 300 }}
                                                                onCropChange={onCropChange}
                                                                onCropComplete={onCropComplete}
                                                            />
                                                        </div>
                                                    </Segment>
                                                </Modal.Content>
                                                <Modal.Actions
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        left: '50%',
                                                        width: '100%',
                                                        transform: 'translateX(-50%)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                        padding: 0,
                                                        marginBottom: 10,
                                                    }}>
                                                    <div style={{ padding: 20 }}>
                                                        <Button
                                                            color='green'
                                                            onClick={selectImage}
                                                            disabled={loading_image}>
                                                            บันทึก
                                                        </Button>
                                                        <Button
                                                            color='red'
                                                            onClick={closeModal}
                                                            disabled={loading_image}>
                                                            ยกเลิก
                                                        </Button>
                                                    </div>
                                                </Modal.Actions>
                                            </Modal>
                                            <Grid.Column computer='10' only='computer'>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'flex-end',
                                                        justifyContent: 'center',
                                                    }}
                                                    onMouseOver={() => {
                                                        setShowUploadImage(true)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setShowUploadImage(false)
                                                    }}>
                                                    <div style={{ justifyContent: 'center' }}>
                                                        {path_image ||
                                                            (user_data?.image && (
                                                                <Image
                                                                    bordered
                                                                    onMouseOver={() => {
                                                                        setShowUploadImage(true)
                                                                    }}
                                                                    style={{
                                                                        width: 150,
                                                                        height: 150,
                                                                        borderRadius: 500,
                                                                    }}
                                                                    src={
                                                                        path_image
                                                                            ? path_image
                                                                            : `${HOST}${user_data?.image}`
                                                                    }
                                                                />
                                                            ))}
                                                    </div>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            width: 150,
                                                            opacity: 0.7,
                                                        }}>
                                                        <Transition
                                                            visible={show_upload_image}
                                                            animation='slide up'
                                                            duration={200}>
                                                            <div
                                                                style={{
                                                                    height: 70,
                                                                    backgroundColor: 'black',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '0px 0px 1000px 1000px',
                                                                }}
                                                                onClick={handleClickUpload}>
                                                                <div
                                                                    style={{
                                                                        justifyContent: 'center',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        height: '100%',
                                                                        flexDirection: 'column',
                                                                    }}>
                                                                    <div
                                                                        style={{
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                        }}>
                                                                        <Icon
                                                                            name='camera'
                                                                            size='big'
                                                                            style={{ color: 'white' }}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            marginTop: 10,
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                        }}>
                                                                        <label
                                                                            style={{
                                                                                color: 'white',
                                                                                cursor: 'pointer',
                                                                                paddingBottom: 5,
                                                                            }}>
                                                                            อัปโหลดรูปภาพ
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Transition>
                                                    </div>
                                                    <Input>
                                                        <input
                                                            type='file'
                                                            id='file'
                                                            hidden
                                                            onChange={handleChangeFile}
                                                            name='image_profile'
                                                            accept='image/png, image/jpeg, image/jpg'
                                                        />
                                                    </Input>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column tablet='10' mobile='16' only='tablet mobile'>
                                                <Segment
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        zIndex: 1,
                                                        marginTop: 30,
                                                    }}>
                                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <div style={{ justifyContent: 'center' }}>
                                                            {path_image ||
                                                                (user_data?.image && (
                                                                    <Image
                                                                        bordered
                                                                        onMouseOver={() => {
                                                                            setShowUploadImage(true)
                                                                        }}
                                                                        style={{
                                                                            width: 150,
                                                                            height: 150,
                                                                            borderRadius: 500,
                                                                        }}
                                                                        src={
                                                                            path_image
                                                                                ? path_image
                                                                                : `${HOST}${user_data?.image}`
                                                                        }
                                                                    />
                                                                ))}
                                                        </div>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 150,
                                                                opacity: 0.7,
                                                                marginBottom: 5,
                                                            }}
                                                            onMouseOver={() => {
                                                                setShowUploadImage(true)
                                                            }}>
                                                            <Transition
                                                                visible={show_upload_image}
                                                                animation='slide up'
                                                                duration={200}>
                                                                <div
                                                                    style={{
                                                                        height: 70,
                                                                        backgroundColor: 'black',
                                                                        cursor: 'pointer',
                                                                        borderRadius: '0px 0px 1000px 1000px',
                                                                    }}
                                                                    onClick={handleClickUpload}>
                                                                    <div
                                                                        style={{
                                                                            justifyContent: 'center',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            height: '100%',
                                                                            flexDirection: 'column',
                                                                        }}>
                                                                        <div
                                                                            style={{
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                            }}>
                                                                            <Icon
                                                                                name='camera'
                                                                                size='big'
                                                                                style={{ color: 'white' }}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                marginTop: 10,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                            }}>
                                                                            <label
                                                                                style={{
                                                                                    color: 'white',
                                                                                    cursor: 'pointer',
                                                                                    paddingBottom: 5,
                                                                                }}>
                                                                                อัปโหลดรูปภาพ
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Transition>
                                                        </div>
                                                        <Input>
                                                            <input
                                                                type='file'
                                                                id='file'
                                                                hidden
                                                                onChange={handleChangeFile}
                                                                name='image_profile'
                                                                accept='image/png, image/jpeg, image/jpg'
                                                            />
                                                        </Input>
                                                    </div>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='center'>
                                                <h3>{`${user_data?.prefix_th}${user_data?.first_name_th} ${user_data?.last_name_th}`}</h3>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='center'>
                                                <label>{`${user_data?.prefix_en}.${user_data?.first_name_en} ${user_data?.last_name_en}`}</label>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='8'>
                                                <label style={{ fontWeight: 'bold' }}>สิทธิ์การเข้าถึง</label>
                                            </Grid.Column>
                                            <Grid.Column computer='8'>{user_data?.roles_name}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' />
                                            <Grid.Column textAlign='center' computer='10'>
                                                <ButtonIconOver
                                                    fluid
                                                    icon='edit'
                                                    label='แก้ไขข้อมูลเจ้าหน้าที่'
                                                    color='#F26202'
                                                    onClick={handleEditProfile}
                                                />
                                            </Grid.Column>
                                            <Grid.Column computer='3' />
                                            {Number(localStorage.getItem('user_id')) === user_id && (
                                                <Fragment>
                                                    <Grid.Column computer='3' />
                                                    <Grid.Column textAlign='center' computer='10'>
                                                        <ButtonIconOver
                                                            fluid
                                                            icon='key'
                                                            label='เปลี่ยนรหัสผ่าน'
                                                            color='#425679'
                                                            onClick={() => setOpenModalForm(true)}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' />
                                                </Fragment>
                                            )}
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer='10'>
                                <CustomSegment icon='chat' header='ข้อมูล One ID'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='5'>
                                                <label style={{ fontWeight: 'bold' }}>ชื่อผู้ใช้งาน</label>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{user_data?.username}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='5'>
                                                <label style={{ fontWeight: 'bold' }}>ฮีเมล One ID</label>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{user_data?.one_mail}</Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                                <CustomSegment icon='user circle' header='ข้อมูล'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='5'>
                                                <label style={{ fontWeight: 'bold' }}>อีเมลยืนยันตัวตน</label>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{user_data?.email}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='5'>
                                                <label style={{ fontWeight: 'bold' }}>หมายเลขโทรศัพท์</label>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{user_data?.phone_number}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='5'>
                                                <label style={{ fontWeight: 'bold' }}>เลขประจำตัวประชาชน</label>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{user_data?.id_card_number}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='5'>
                                                <label style={{ fontWeight: 'bold' }}>วัน / เดือน / ปีเกิด</label>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>
                                                {FullFormatDateTH(user_data?.birth_date)}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='5'>
                                                <label style={{ fontWeight: 'bold' }}>องค์กร</label>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{user_data?.municipalities_name}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='5'>
                                                <label style={{ fontWeight: 'bold' }}>สิทธิ์การเข้าถึง</label>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>
                                                {user_data?.roles_name} {user_data?.directory_of_municipalities_name}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='5'>
                                                <label style={{ fontWeight: 'bold' }}>ตำแหน่งงาน</label>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{user_data?.position}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='right' computer='5'>
                                                <label style={{ fontWeight: 'bold' }}>ที่อยู่ (ปัจจุบัน) </label>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>
                                                <p>
                                                    บ้านเลขที่{user_data?.house_no} หมู่ที่{user_data?.house_no}
                                                </p>
                                                <p>
                                                    ซอย{user_data?.alley ? user_data?.alley : ' -'} ถนน
                                                    {user_data?.road ? user_data?.road : ' -'}
                                                </p>
                                                <p>
                                                    ตำบล{user_data?.sub_district} อำเภอ{user_data?.district}
                                                </p>
                                                <p>
                                                    จังหวัด{user_data?.province} {user_data?.zipcode}
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row> */}
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default UserDetail
