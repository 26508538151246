import React, { Fragment, useEffect, useState } from 'react'
import {
    Button,
    Form,
    Grid,
    TextArea,
    Icon,
    Segment,
    Dropdown,
    Image,
    DropdownProps,
    TextAreaProps,
} from 'semantic-ui-react'
import IconCentral from '../../../../../assets/images/central.png'
import IconDepartment from '../../../../../assets/images/department.png'
import CustomModal from '../../../../../components/CustomModal'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE } from '../../../../../config/api/directoryOfMunicipality'
import { POST_COMPLAINT_REJECT_COMPLAINT } from '../../../../../config/api/complaint'
import { alert } from '../../../../../components/alert'

type FormProps = {
    complaint_id?: string | number
    reload?: () => void
}

const RejectComplaint: React.FC<FormProps> = (props) => {
    let [open, setOpen] = useState<boolean>(false)
    let [action, setAction] = useState<number>(0)
    let [directory_option, setDirectoryOption] = useState<[]>([])
    let [directory, setDirectory] = useState<string | number | boolean | (string | number | boolean)[] | undefined>(
        undefined
    )
    let [detail, setDetail] = useState<string | number | undefined>('')

    useEffect(() => {
        const getDirectory = () => {
            axiosAuthen.get(`${GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE}`).then((response) => {
                let { value } = response.data
                let directory_option = value.map((data: any) => ({ value: data.id, text: data.name }))
                setDirectoryOption(directory_option)
            })
        }
        getDirectory()
    }, [])

    const handleOpen = () => {
        setOpen(!open)
    }
    const handleClose = () => {
        setOpen(false)
        setAction(0)
        setDirectory(undefined)
        setDetail('')
    }

    const handleClickAction = (action: number) => {
        setAction(action)
    }

    const handleChangeDirectory = (e: any, { value }: DropdownProps) => {
        setDirectory(value)
    }
    const handleChangeDetail = (e: any, { value }: TextAreaProps) => {
        setDetail(value)
    }

    const handleSubmit = () => {
        let body = {
            action: action,
            complaints_id: props.complaint_id,
            detail: detail,
            directory_of_municipalities_id: directory,
        }
        axiosAuthen.post(POST_COMPLAINT_REJECT_COMPLAINT, body).then(() => {
            alert({
                type: 'success',
                title: 'บันทึกเสร็จสิ้น',
                timer: 1500,
                onClosed: () => {
                    handleClose()
                    props.reload && props.reload()
                },
            })
        })
    }
    return (
        <Fragment>
            <CustomModal
                icon='file alternate'
                header='ปฏิเสธการร้องเรียน'
                open={open}
                trigger={
                    <ButtonIconOver
                        fluid
                        label='ปฏิเสธคำร้องทุกข์'
                        color='#D85F53'
                        icon='delete'
                        basic
                        onClick={handleOpen}
                    />
                }>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='2' only='computer' />
                            <Grid.Column computer='6' only='computer'>
                                {action === 0 && (
                                    <Icon
                                        name='check circle'
                                        size='big'
                                        style={{
                                            position: 'absolute',
                                            right: -5,
                                            zIndex: 1,
                                            color: 'rgb(66, 86, 121)',
                                            backgroundColor: '#fff',
                                            paddingBottom: 30,
                                        }}
                                    />
                                )}

                                <Segment
                                    style={
                                        action === 0
                                            ? { textAlign: 'center', cursor: 'pointer', border: '2px solid #293757' }
                                            : {
                                                  textAlign: 'center',
                                                  cursor: 'pointer',
                                                  marginTop: 15,
                                              }
                                    }
                                    onClick={() => handleClickAction(0)}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}>
                                        <Image src={IconCentral} style={{ margin: 10, width: 100, height: 100 }} />
                                    </div>
                                    <div style={{ color: '#1E1F26', fontSize: '24px' }}>ส่งกลับส่วนกลาง</div>
                                    <div style={{ color: '#8A8A8A', fontSize: '16px' }}>
                                        ส่วนกลางจัดการและดำเนินการต่อไป
                                    </div>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer='6' only='computer'>
                                {action === 1 && (
                                    <Icon
                                        name='check circle'
                                        size='big'
                                        style={{
                                            position: 'absolute',
                                            right: -5,
                                            zIndex: 1,
                                            color: 'rgb(66, 86, 121)',
                                            backgroundColor: '#fff',
                                            paddingBottom: 30,
                                        }}
                                    />
                                )}
                                <Segment
                                    disabled={directory_option.length === 0}
                                    style={
                                        action === 1
                                            ? { textAlign: 'center', cursor: 'pointer', border: '2px solid #293757' }
                                            : {
                                                  textAlign: 'center',
                                                  cursor: 'pointer',
                                                  marginTop: 15,
                                              }
                                    }
                                    onClick={() => directory_option.length > 0 && handleClickAction(1)}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}>
                                        <Image src={IconDepartment} style={{ margin: 10, width: 100, height: 100 }} />
                                    </div>
                                    <div style={{ color: '#1E1F26', fontSize: '24px' }}>ส่งต่อหน่วยงานอื่น</div>
                                    <div style={{ color: '#8A8A8A', fontSize: '16px' }}>ส่งต่อให้กับหน่วยงานภายใน</div>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer='2' only='computer' />
                        </Grid.Row>
                        {action === 1 && (
                            <Grid.Row>
                                <Grid.Column computer='2' only='computer' />
                                <Grid.Column computer='12' only='computer'>
                                    <div style={{ color: '#425679', fontSize: '14px' }}>เลือกหน่วยงาน</div>
                                    <Dropdown
                                        fluid
                                        selection
                                        options={directory_option}
                                        value={directory}
                                        onChange={handleChangeDirectory}
                                    />
                                </Grid.Column>
                                <Grid.Column computer='2' only='computer' />
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column computer='2' only='computer' />

                            <Grid.Column computer='12' only='computer'>
                                <div style={{ color: '#425679', fontSize: '14px' }}>รายละเอียด</div>
                                <TextArea value={detail} onChange={handleChangeDetail} />
                            </Grid.Column>
                            <Grid.Column computer='2' only='computer' />
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}>
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}>
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default RejectComplaint
