import React, { useEffect, useState } from 'react'
import {
    Button,
    Container,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Message,
    Segment,
} from 'semantic-ui-react'
import IconOnePlatform from '../../../../../assets/images/logo one platform.png'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import CustomCircleCheckbox from '../../../../../components/CustomCircleCheckbox'
import { APPLICATION_GET_RESET_PASSWORD_FROM_OTP } from '../../../../../config/api/application'

const LineRegisterForgetPasswordSetNewPassword: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [otp, setOTP] = useState<string>('')
    let [password, setPassword] = useState<string | null>('')
    let [confirm_password, setConfirmPassword] = useState<string>('')
    let [compare_password_error, setComparePasswordError] = useState<boolean>(false)
    let [show_password, setShowPassword] = useState<boolean>(false)
    let [show_confirm_password, setShowConfirmPassword] = useState<boolean>(false)
    let [password_have_upper_case, setPasswordHaveUpperCase] = useState<boolean>(false)
    let [password_have_lower_case, setPasswordHaveLowerCase] = useState<boolean>(false)
    let [password_least_eight, setPasswordLeastEight] = useState<boolean>(false)
    let [password_have_number, setPasswordHaveNumber] = useState<boolean>(false)
    let [password_have_special, setPasswordHaveSpecial] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(false)
    let [error_text, setErrorText] = useState<string>('')

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { otp } = location_state
            setOTP(otp)
        }
    }, [location])

    useEffect(() => {
        setPasswordHaveUpperCase(false)
        setPasswordHaveLowerCase(false)
        setPasswordHaveNumber(false)
        setPasswordLeastEight(false)
        setPasswordHaveSpecial(false)
        if (String(password).match(/[A-Z]/)) {
            setPasswordHaveUpperCase(true)
        }
        if (String(password).match(/[a-z]/)) {
            setPasswordHaveLowerCase(true)
        }
        if (String(password).match(/\d/)) {
            setPasswordHaveNumber(true)
        }
        if (String(password).length >= 8) {
            setPasswordLeastEight(true)
        }
        if (String(password).match(/[^a-zA-Z\d]/)) {
            setPasswordHaveSpecial(true)
        }
    }, [password])

    const handleChangePassword = (_: any, { value }: InputOnChangeData) => {
        setPassword(value)
        setComparePasswordError(false)
    }

    const handleChangeConfirmPassword = (_: any, { value }: InputOnChangeData) => {
        setConfirmPassword(value)
        setComparePasswordError(false)
    }

    const handleComparePassword = () => {
        if (password && confirm_password) {
            if (password !== confirm_password) {
                setComparePasswordError(true)
                setErrorText('รหัสผ่านไม่ตรงกัน')
            }
        }
    }

    const handleClickGoBack = () => {
        history.goBack()
    }

    const handleClickGoNext = () => {
        if (!compare_password_error) {
            setLoading(true)
            axios
                .post(APPLICATION_GET_RESET_PASSWORD_FROM_OTP, {
                    otp: otp,
                    new_password: password,
                    confirm_new_password: confirm_password,
                })
                .then(() => {
                    setLoading(false)
                    history.push('complete')
                })
                .catch((error) => {
                    if (error.response.data.message === 'Token Invalid') {
                        setErrorText('รหัส OTP ไม่ถูกต้อง โปรดกดกลับเพื่อขอ OTP ใหม่')
                    } else {
                        setErrorText(error.response.data.message)
                    }
                    setLoading(false)
                })
        }
    }

    return (
        <div className='background-container-no-sidebar'>
            <Container>
                <Segment className='segment-login-page'>
                    <div>
                        <div className='header-segment-login-page'>
                            <div className='image-one-platform'>
                                <Image src={IconOnePlatform} />
                            </div>
                        </div>
                        <div className='body-segment-login-page'>
                            <Form loading={loading}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column textAlign='center'>
                                            <h3>ลืมรหัสผ่าน</h3>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column computer='3' only='computer' />
                                        <Grid.Column computer='10' mobile='16'>
                                            <label className='header-input-form'>รหัสผ่านใหม่</label>
                                            <Form.Field error={compare_password_error}>
                                                <Input
                                                    fluid
                                                    name='password'
                                                    id='password'
                                                    value={password}
                                                    onChange={handleChangePassword}
                                                    onBlur={handleComparePassword}
                                                    icon={
                                                        <Icon
                                                            name={show_password ? 'eye slash' : 'eye'}
                                                            link
                                                            onClick={() => {
                                                                setShowPassword(!show_password)
                                                            }}
                                                        />
                                                    }
                                                    type={show_password ? 'text' : 'password'}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column computer='3' only='computer' />
                                        <Grid.Column computer='3' only='computer' />
                                        <Grid.Column computer='10' mobile='16'>
                                            <div style={{ display: 'flex', margin: 5 }}>
                                                <div style={{ paddingRight: 15, textDecoration: 'underline' }}>
                                                    เงื่อนไข
                                                </div>
                                                <div>
                                                    <CustomCircleCheckbox
                                                        label='กรอกตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ อย่างน้อย 1 ตัว'
                                                        checked={password_have_upper_case}
                                                    />
                                                    <CustomCircleCheckbox
                                                        label='กรอกตัวอักษรภาษาอังกฤษพิมพ์เล็ก อย่างน้อย 1 ตัว'
                                                        checked={password_have_lower_case}
                                                    />
                                                    <CustomCircleCheckbox
                                                        label='กรอกตัวเลข อย่างน้อย 1 ตัว'
                                                        checked={password_have_number}
                                                    />
                                                    <CustomCircleCheckbox
                                                        label=' รวมกันอย่างน้อย 8 ตัว'
                                                        checked={password_least_eight}
                                                    />
                                                    <CustomCircleCheckbox
                                                        label='กรอกอักขระพิเศษได้'
                                                        checked={password_have_special}
                                                    />
                                                </div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column computer='3' only='computer' />
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column computer='3' only='computer' />
                                        <Grid.Column computer='10' mobile='16'>
                                            <label className='header-input-form'>ยืนยันรหัสผ่านใหม่</label>
                                            <Form.Field error={compare_password_error}>
                                                <Input
                                                    fluid
                                                    name='confirm_password'
                                                    id='confirm_password'
                                                    value={confirm_password}
                                                    onChange={handleChangeConfirmPassword}
                                                    onBlur={handleComparePassword}
                                                    icon={
                                                        <Icon
                                                            name={show_confirm_password ? 'eye slash' : 'eye'}
                                                            link
                                                            onClick={() => {
                                                                setShowConfirmPassword(!show_confirm_password)
                                                            }}
                                                        />
                                                    }
                                                    type={show_confirm_password ? 'text' : 'password'}
                                                />
                                            </Form.Field>

                                            {error_text && (
                                                <Message size='tiny' negative>
                                                    {error_text}
                                                </Message>
                                            )}
                                        </Grid.Column>
                                        <Grid.Column computer='3' only='computer' />
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </div>
                        <div className='footer-segment-login-page'>
                            <Button disabled={loading} floated='left' compact icon primary onClick={handleClickGoBack}>
                                <Icon name='chevron left' />
                            </Button>
                            <Button
                                loading={loading}
                                disabled={loading}
                                floated='right'
                                compact
                                icon
                                primary
                                onClick={handleClickGoNext}
                            >
                                <Icon name='chevron right' />
                            </Button>
                        </div>
                    </div>
                </Segment>
            </Container>
        </div>
    )
}
export default LineRegisterForgetPasswordSetNewPassword
