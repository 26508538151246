import React, { useEffect, useState } from 'react'
import { Container, Dimmer, Loader, Segment } from 'semantic-ui-react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { axiosAuthen } from '../../../config/axiosAuthen'
import { GET_DASHBOARD_COUNT_CONTACT } from '../../../config/api/dashboard'

type DashboardProps = {
    year?: any
    start_date?: string
    end_date?: string
}

interface InterfaceDashboardContact {
    count: number
    percent: string
    contact_name: string
}

const MakeData = [
    {
        y: 0,
        name: 'ช่องทางการรับเรื่องทั้งหมด',
        color: '#283655',
        percent: 0,
    },
    {
        y: 0,
        name: 'line',
        color: '#D0E1F9',
        percent: 0,
    },
    {
        y: 0,
        name: 'facebook',
        color: '#53A6D8',
        percent: 0,
    },
    {
        y: 0,
        name: 'สำนักการช่าง',
        color: '#2D83B6',
        percent: 0,
    },
    {
        y: 0,
        name: 'โทรศัพท์',
        color: '#89CCF7',
        percent: 0,
    },
    {
        y: 0,
        name: 'อีเมล',
        color: '#79B2C6',
        percent: 0,
    },
    {
        y: 0,
        name: 'อื่น ๆ',
        color: '#7097A8',
        percent: 0,
    },
    {
        y: 0,
        name: 'LINE BOT',
        color: '#4D648D',
        percent: 0,
    },
    {
        y: 0,
        name: 'ONE CHAT',
        color: '#6FA3C5',
        percent: 0,
    },
]

const DashboardContact: React.FC<DashboardProps> = (props) => {
    let { year, start_date, end_date } = props
    let [loading, setLoading] = useState<boolean>(true)
    let [chart_options, setChartOptions] = useState<any>(undefined)
    useEffect(() => {
        setLoading(true)
        axiosAuthen
            .get(
                `${GET_DASHBOARD_COUNT_CONTACT}?year=${
                    year === 'all' ? '' : year
                }&start_date=${start_date}&end_date=${end_date}`
            )
            .then((response) => {
                let { value } = response.data
                let data_series: any[] = []

                value.forEach((data: InterfaceDashboardContact, index: number) => {
                    let color = ''
                    if (index === 1) {
                        color = '#D0E1F9'
                    }
                    if (index === 2) {
                        color = '#53A6D8'
                    }
                    if (index === 3) {
                        color = '#2D83B6'
                    }
                    if (index === 4) {
                        color = '#89CCF7'
                    }
                    if (index === 5) {
                        color = '#79B2C6'
                    }
                    if (index === 6) {
                        color = '#7097A8'
                    }
                    if (index === 7) {
                        color = '#4D648D'
                    }
                    if (index === 8) {
                        color = '#6FA3C5'
                    }
                    if (index > 8) {
                        color = '#283655'
                    }
                    if (data.contact_name === 'ช่องทางการรับเรื่องทั้งหมด') {
                        data_series.push({
                            y: data.count,
                            name: data.contact_name,
                            color: '#283655',
                            percent: data.percent,
                        })
                    } else {
                        data_series.push({
                            y: data.count,
                            name: data.contact_name,
                            color: color,
                            percent: data.percent,
                        })
                    }
                })
                let categories = [
                    // 'ช่องทางการรับเรื่องทั้งหมด',
                    'line',
                    'facebook',
                    'สำนักการช่าง',
                    'โทรศัพท์',
                    'อีเมล',
                    'อื่น ๆ',
                    'LINE BOT',
                    'ONE CHAT',
                ]

                let chart_options = {
                    chart: {
                        type: 'column',
                    },
                    title: {
                        text: 'สรุปช่องทางการรับเรื่อง',
                    },
                    xAxis: {
                        categories: categories,
                        title: {
                            text: null,
                        },
                    },
                    yAxis: {
                        title: {
                            text: 'จำนวนช่องทางการรับเรื่อง',
                        },
                        labels: {
                            overflow: 'justify',
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                format: '{y} คำร้อง <b>( {point.percent:.2f}% )</b>',
                            },
                        },
                    },
                    legend: {
                        enabled: false,
                    },
                    series: [
                        {
                            name: 'สถานะคำร้องทุกข์',
                            data: data_series,
                        },
                    ],
                    credits: {
                        enabled: false,
                    },
                }
                setChartOptions(chart_options)
                setLoading(false)
            })
            .catch(() => {
                let categories = [
                    // 'ช่องทางการรับเรื่องทั้งหมด',
                    'line',
                    'facebook',
                    'สำนักการช่าง',
                    'โทรศัพท์',
                    'อีเมล',
                    'อื่น ๆ',
                    'LINE BOT',
                    'ONE CHAT',
                ]

                let chart_options = {
                    chart: {
                        type: 'column',
                    },
                    title: {
                        text: 'สรุปช่องทางการรับเรื่อง',
                    },
                    xAxis: {
                        categories: categories,
                        title: {
                            text: null,
                        },
                    },
                    yAxis: {
                        title: {
                            text: 'จำนวนช่องทางการรับเรื่อง',
                        },
                        labels: {
                            overflow: 'justify',
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                format: '{y} คำร้อง <b>( {point.percent:.2f}% )</b>',
                            },
                        },
                    },
                    legend: {
                        enabled: false,
                    },
                    series: [
                        {
                            name: 'สถานะคำร้องทุกข์',
                            data: MakeData,
                        },
                    ],
                    credits: {
                        enabled: false,
                    },
                }
                setChartOptions(chart_options)
                setLoading(false)
            })
    }, [end_date, start_date, year])

    if (loading) {
        return (
            <Segment style={{ height: 430 }}>
                <div>
                    <Container style={{ height: window.innerHeight }}>
                        <Dimmer active inverted>
                            <Loader size='huge' />
                        </Dimmer>
                    </Container>
                </div>
            </Segment>
        )
    } else {
        return (
            <Segment>
                <HighchartsReact highcharts={Highcharts} options={chart_options} />
            </Segment>
        )
    }
}
export default DashboardContact
