import React, { Fragment, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import { useHistory, useLocation } from 'react-router'
import {
    Breadcrumb,
    Button,
    Container,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Label,
    Message,
    Modal,
    Radio,
    Segment,
    Transition,
} from 'semantic-ui-react'
import CustomSegment from '../../../../../components/CustomSegment'
import {
    GET_MUNICIPALITY_DETAIL,
    GET_MUNICIPALITY_PERSON_TYPES,
    GET_MUNICIPALITY_UPLOAD_QR_LINE,
    POST_MUNICIPALITY_CREATE_AND_EDIT,
    POST_MUNICIPALITY_UPLOAD_LOGO,
} from '../../../../../config/api/municipality'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import getCroppedImg from '../../../../../functions/CroppedImage'
import IconLineChat from '../../../../../assets/images/icon-line-chat.png'
// import IconOneChat from '../../../../../assets/images/icon-one-chat.png'
import { getFile } from '../../../../../functions/GetFile'
import { alert } from '../../../../../components/alert'
import IconSetting from '../../../../../assets/images/settings.png'
import IconSitemap from '../../../../../assets/images/sitemap.png'
import GoogleMap from '../../../../../components/GoogleMaps'
import axios from 'axios'
import UploadQRImage from '../../../../../assets/images/qr.png'

const ManagementUsersFormEdit: React.FC = () => {
    let [show_modal_upload, setShowModalUpload] = useState<boolean>(false)
    let [show_upload_image, setShowUploadImage] = useState<boolean>(false)
    let [loading_image, setLoadingImage] = useState<boolean>(false)
    let [crop, setCrop] = useState({ x: 0, y: 0 })
    let [pixel_crop, setPixelCrop] = useState<object>({})
    let [dump_path, setDumpPath] = useState<string>()
    let [path_image, setPathImage] = useState<string>()
    let [photo_upload, setPhotoUpload] = useState<any>()
    let [profile_image, setProfileImage] = useState<any>()
    let [image_qr_line_internal, setImageQRLineInternal] = useState<any>()
    let [image_qr_line_person, setImageQRLinePerson] = useState<any>()
    let [name_th, setNameTh] = useState<string>()
    let [name_en, setNameEn] = useState<string>()
    let [short_name, setShortName] = useState<string>()
    // let [theme_color, setThemeColor] = useState<string>()
    let [line_internal, setLineInternal] = useState<boolean>()
    let [one_internal, setOneInternal] = useState<boolean>()
    let [assign_technician, setAssignTechnician] = useState<boolean>()
    let [assign_directory, setAssignDirectory] = useState<boolean>()
    let [error_profile_image, setErrorProfileImage] = useState<boolean>()
    let [error_name_th, setErrorNameTh] = useState<boolean>()
    let [error_name_en, setErrorNameEn] = useState<boolean>()
    let [error_short_name, setErrorShortName] = useState<boolean>()
    // let [error_theme_color, setErrorThemeColor] = useState<boolean>()
    let [error_line_internal, setErrorLineInternal] = useState<boolean>()
    // let [error_one_internal, setErrorOneInternal] = useState<boolean>()
    let [error_assign_technician, setErrorAssignTechnician] = useState<boolean>()
    let [error_assign_directory, setErrorAssignDirectory] = useState<boolean>()
    let [loading, setLoading] = useState<boolean>()
    let history = useHistory()
    let location = useLocation()
    let zoom = 1
    let { state } = location
    let obj = JSON.parse(JSON.stringify(state, null, ' '))
    let { municipality_id } = obj
    let [detail, setDetail] = useState<string>('')
    let [phone_number, setPhoneNumber] = useState<string>('')
    let [phone_extension, setPhoneExtension] = useState<string>('')
    let [email, setEmail] = useState<string>('')
    let [extend_phone_number, setExtendPhoneNumber] = useState<string>('')
    let [extend_phone_extension, setExtendPhoneExtension] = useState<string>('')
    let [extend_email, setExtendEmail] = useState<string>('')
    let [lat, setLat] = useState<number>(0)
    let [lng, setLng] = useState<number>(0)
    let [error_detail, setErrorDetail] = useState<boolean>(false)
    let [error_phone_number, setErrorPhoneNumber] = useState<boolean>(false)
    let [error_email, setErrorEmail] = useState<boolean>(false)
    let [loading_map, setSetLoadingMap] = useState<boolean>(true)
    let [show_upload_image_qr_line_internal, setShowUploadImageQRLineInternal] = useState<boolean>(false)
    let [show_upload_image_qr_line_person, setShowUploadImageQRLinePerson] = useState<boolean>(false)
    let [person_types_option, setPersonTypesOption] = useState<{ value: number; text: string }[]>([])
    let [person_types, setPersonTypes] = useState<number>(0)
    let [error_person_types, setErrorPersonTypes] = useState<boolean>(false)
    let [no_notification, setNoNotification] = useState<boolean>(false)
    let [error_no_notification, setErrorNoNotifications] = useState<boolean>(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        const getPersonTypes = () => {
            axios.get(GET_MUNICIPALITY_PERSON_TYPES).then((response) => {
                let { value } = response.data
                setPersonTypesOption(value)
            })
        }
        getPersonTypes()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        const getDetailMunicipality = () => {
            axiosAuthen.get(`${GET_MUNICIPALITY_DETAIL}?municipality_id=${municipality_id}`).then(async (response) => {
                let { value } = response.data
                let {
                    line_internal,
                    logo,
                    name_en,
                    name_th,
                    one_internal,
                    short_name,
                    // theme_color,
                    assign_complaint,
                    detail,
                    email,
                    phone_number,
                    lat,
                    lng,
                    qr_line_internal,
                    qr_line_person,
                    person_types_id,
                    phone_extension,
                    extend_phone_number,
                    extend_phone_extension,
                    extend_email,
                } = value
                let path_file_logo = logo.split('/')
                let file_name = path_file_logo[path_file_logo.length - 1]
                let logo_image = await getFile(file_name, logo)
                logo_image && setPathImage(URL.createObjectURL(logo_image))

                let path_file_qr_line_internal = qr_line_internal.split('/')
                let file_name_qr_line_internal = path_file_qr_line_internal[path_file_qr_line_internal.length - 1]
                let file_qr_line_internal = await getFile(file_name_qr_line_internal, qr_line_internal)
                file_qr_line_internal && setImageQRLineInternal(file_qr_line_internal)

                let path_file_qr_line_person = qr_line_person.split('/')
                let file_name_qr_line_person = path_file_qr_line_person[path_file_qr_line_person.length - 1]
                let file_qr_line_person = await getFile(file_name_qr_line_person, qr_line_person)
                file_qr_line_person && setImageQRLinePerson(file_qr_line_person)
                setPersonTypes(person_types_id)
                setProfileImage(logo_image)
                setNameTh(name_th)
                setNameEn(name_en)
                setShortName(short_name)
                // setThemeColor(theme_color)
                setLineInternal(line_internal)
                setOneInternal(one_internal)
                setNoNotification(!one_internal && !line_internal)
                if (!assign_complaint) {
                    setAssignDirectory(true)
                } else {
                    setAssignTechnician(true)
                }
                setDetail(detail)
                setPhoneNumber(phone_number)
                setPhoneExtension(phone_extension)
                setEmail(email)
                setExtendPhoneNumber(extend_phone_number)
                setExtendPhoneExtension(extend_phone_extension)
                setExtendEmail(extend_email)
                setLat(lat)
                setLng(lng)
                setSetLoadingMap(false)
            })
        }
        getDetailMunicipality()
    }, [municipality_id])

    const handleChangeFile = (e: any) => {
        let file = e.target.files[0]
        if (file) {
            setPhotoUpload(file)
            setPathImage(URL.createObjectURL(file))
            setShowModalUpload(true)
            setDumpPath(path_image)
        }
    }

    const onCropComplete = (cropped_area: any, cropped_area_pixels: any) => {
        setPixelCrop(cropped_area_pixels)
    }

    const onCropChange = (value: any) => {
        setCrop(value)
    }

    const closeModal = () => {
        setPathImage(dump_path)
        setShowModalUpload(false)
    }

    const selectImage = async () => {
        setLoadingImage(true)
        let new_image = await getCroppedImg(photo_upload, pixel_crop)
        setPathImage(URL.createObjectURL(new_image))
        setLoadingImage(false)
        setShowModalUpload(false)
        setProfileImage(new_image)
        setErrorProfileImage(false)
    }

    const handleClickUpload = () => {
        document.getElementById('file')!.click()
    }
    const handleClickUploadQRLineInternal = () => {
        document.getElementById('file_qr_line_internal')!.click()
    }
    const handleChangeFileQRLineInternal = (e: any) => {
        let file = e.target.files[0]
        if (file) {
            setImageQRLineInternal(file)
        }
    }
    const handleClickUploadQRLinePerson = () => {
        document.getElementById('file_qr_line_person')!.click()
    }
    const handleChangeFileQRLinePerson = (e: any) => {
        let file = e.target.files[0]
        if (file) {
            setImageQRLinePerson(file)
        }
    }
    const handleChangePersonTypes = (id: any) => {
        setPersonTypes(id)
        setErrorPersonTypes(false)
    }

    const handleChangeNameTh = (_: any, { value }: InputOnChangeData) => {
        setNameTh(value)
        setErrorNameTh(false)
    }

    const handleChangeNameEn = (_: any, { value }: InputOnChangeData) => {
        setNameEn(value)
        setErrorNameEn(false)
    }

    const handleChangeDetail = (_: any, { value }: InputOnChangeData) => {
        setDetail(value)
        setErrorDetail(false)
    }

    const handleChangePhoneNumber = (_: any, { value }: InputOnChangeData) => {
        setPhoneNumber(value)
        setErrorPhoneNumber(false)
    }

    const handleChangePhoneExtension = (_: any, { value }: InputOnChangeData) => {
        setPhoneExtension(value)
    }
    const handleChangeEmail = (_: any, { value }: InputOnChangeData) => {
        setEmail(value)
        setErrorEmail(false)
    }
    const handleChangeExtendPhoneNumber = (_: any, { value }: InputOnChangeData) => {
        setExtendPhoneNumber(value)
    }

    const handleChangeExtendPhoneExtension = (_: any, { value }: InputOnChangeData) => {
        setExtendPhoneExtension(value)
    }

    const handleChangeExtendEmail = (_: any, { value }: InputOnChangeData) => {
        setExtendEmail(value)
    }

    const handleChangePosition = (_: any, { value }: any) => {
        setLat(value.lat)
        setLng(value.lng)
    }

    const handleChangeLineInternal = () => {
        setLineInternal(!line_internal)
        setNoNotification(false)
        setErrorLineInternal(false)
        setErrorNoNotifications(false)
        // setErrorOneInternal(false)
    }

    const handleChangeNoNotification = () => {
        setNoNotification(!no_notification)
        setPersonTypes(0)
        setLineInternal(false)
        setErrorNoNotifications(false)
        setErrorLineInternal(false)
    }

    const validation = () => {
        let error = false

        if (!profile_image) {
            let element = document.getElementById('pusher')
            element?.scrollTo({ top: 0, behavior: 'smooth' })
            error = true
            setErrorProfileImage(true)
        }
        if (!name_th) {
            error = true
            setErrorNameTh(true)
        }
        if (!name_en) {
            error = true
            setErrorNameEn(true)
        }
        if (!short_name) {
            error = true
            setErrorShortName(true)
        }
        // if (!theme_color) {
        //     error = true
        //     setErrorThemeColor(true)
        // }
        if (!line_internal && !one_internal && !no_notification) {
            error = true
            setErrorLineInternal(true)
            setErrorNoNotifications(true)
            // setErrorOneInternal(true)
        } else {
            if (!no_notification)
                if (!person_types) {
                    error = true
                    setErrorPersonTypes(true)
                }
        }

        if (!assign_directory && !assign_technician) {
            error = true
            setErrorAssignDirectory(true)
            setErrorAssignTechnician(true)
        }

        if (!phone_number) {
            error = true
            setErrorPhoneNumber(true)
        }

        if (!email) {
            error = true
            setErrorEmail(true)
        }

        if (!detail) {
            error = true
            setErrorDetail(true)
        }

        return error
    }
    const handleSubmit = () => {
        if (!validation()) {
            setLoading(true)
            let assign_complaint = false
            if (assign_directory) {
                assign_complaint = false
            } else if (assign_technician) {
                assign_complaint = true
            }
            let body = {
                id: municipality_id,
                name_th: name_th,
                name_en: name_en,
                short_name: short_name,
                // theme_color: theme_color,
                line_internal: line_internal,
                one_internal: one_internal,
                line_person: line_internal,
                one_person: one_internal,
                assign_complaint: assign_complaint,
                detail: detail,
                phone_number: phone_number,
                phone_extension: phone_extension,
                email: email,
                extend_phone_number: extend_phone_number,
                extend_phone_extension: extend_phone_extension,
                extend_email: extend_email,
                lat: String(lat),
                lng: String(lng),
                person_types_id: person_types,
            }

            axiosAuthen
                .post(POST_MUNICIPALITY_CREATE_AND_EDIT, body)
                .then((response) => {
                    let { value } = response.data
                    let form_data = new FormData()
                    form_data.append('logo_file', profile_image)
                    axiosAuthen.post(`${POST_MUNICIPALITY_UPLOAD_LOGO}/${value.id}`, form_data).then((response) => {
                        setLoading(false)
                        alert({
                            type: 'success',
                            title: 'บันทึกเสร็จสิ้น',
                            timer: 1500,
                            onClosed: () => {
                                history.goBack()
                            },
                        })
                    })
                    let form_file_qr = new FormData()
                    form_file_qr.append('qr_line_internal', image_qr_line_internal)
                    form_file_qr.append('qr_line_person', image_qr_line_person)
                    if (image_qr_line_internal || image_qr_line_person) {
                        axiosAuthen.put(`${GET_MUNICIPALITY_UPLOAD_QR_LINE}/${value.id}`, form_file_qr)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        alert({
                            type: 'error',
                            title: error.response.data.message,
                            cancelText: 'ปิด',
                            onClosed: () => {
                                setLoading(false)
                            },
                        })
                    }
                })
        }
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Form loading={loading}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Breadcrumb>
                                        <Breadcrumb.Section
                                            link
                                            onClick={() => {
                                                history.push(`/${localStorage.permission_en}/municipalities`, {
                                                    municipality_id: municipality_id,
                                                })
                                            }}
                                        >
                                            รายการองค์กร
                                        </Breadcrumb.Section>
                                        <Breadcrumb.Divider />
                                        <Breadcrumb.Section
                                            link
                                            onClick={() => {
                                                history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                    municipality_id: municipality_id,
                                                })
                                            }}
                                        >
                                            กำหนดการใช้งานองค์กร
                                        </Breadcrumb.Section>
                                        <Breadcrumb.Divider />
                                        <Breadcrumb.Section
                                            link
                                            onClick={() => {
                                                history.push(
                                                    `/${localStorage.permission_en}/management/municipality/detail`,
                                                    {
                                                        municipality_id: municipality_id,
                                                    }
                                                )
                                            }}
                                        >
                                            ข้อมูลองค์กร
                                        </Breadcrumb.Section>
                                        <Breadcrumb.Divider />
                                        <Breadcrumb.Section active>แก้ไขข้อมูลองค์กร</Breadcrumb.Section>
                                    </Breadcrumb>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    <h2>แก้ไขข้อมูลองค์กร</h2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <CustomSegment icon='building' header='ข้อมูลองค์กร'>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>รูปโลโก้องค์กร</label>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Modal
                                                    size='small'
                                                    open={show_modal_upload}
                                                    onClose={closeModal}
                                                    closeOnEscape={false}
                                                    closeOnDimmerClick={false}
                                                >
                                                    <Modal.Header
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: '50%',
                                                            width: ' 100%',
                                                            transform: 'translateX(-50%)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            zIndex: 1,
                                                        }}
                                                    >
                                                        อัปโหลดรูปภาพ
                                                    </Modal.Header>
                                                    <Modal.Content>
                                                        <Segment
                                                            loading={loading_image}
                                                            style={{ padding: 0, marginTop: 40, paddingBottom: 30 }}
                                                        >
                                                            <div style={{ height: 600 }}>
                                                                <Cropper
                                                                    image={path_image}
                                                                    crop={crop}
                                                                    zoom={zoom}
                                                                    aspect={3 / 3}
                                                                    onCropChange={onCropChange}
                                                                    onCropComplete={onCropComplete}
                                                                />
                                                            </div>
                                                        </Segment>
                                                    </Modal.Content>
                                                    <Modal.Actions
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            left: '50%',
                                                            width: '100%',
                                                            transform: 'translateX(-50%)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                            padding: 0,
                                                            marginBottom: 10,
                                                        }}
                                                    >
                                                        <div style={{ padding: 20 }}>
                                                            <Button
                                                                color='green'
                                                                onClick={selectImage}
                                                                disabled={loading_image}
                                                            >
                                                                บันทึก
                                                            </Button>
                                                            <Button
                                                                color='red'
                                                                onClick={closeModal}
                                                                disabled={loading_image}
                                                            >
                                                                ยกเลิก
                                                            </Button>
                                                        </div>
                                                    </Modal.Actions>
                                                </Modal>

                                                <Grid.Column computer='10' only='computer'>
                                                    <div
                                                        style={{ display: 'flex', alignItems: 'flex-end' }}
                                                        onMouseOver={() => {
                                                            setShowUploadImage(true)
                                                        }}
                                                        onMouseLeave={() => {
                                                            setShowUploadImage(false)
                                                        }}
                                                    >
                                                        <div style={{ justifyContent: 'center' }}>
                                                            <Image
                                                                bordered
                                                                onMouseOver={() => {
                                                                    // setShowUploadImage(true);
                                                                }}
                                                                style={{
                                                                    width: 150,
                                                                    height: 150,
                                                                    borderRadius: 500,
                                                                }}
                                                                src={
                                                                    path_image
                                                                        ? path_image
                                                                        : 'https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png'
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 150,
                                                                opacity: 0.7,
                                                            }}
                                                        >
                                                            <Transition
                                                                visible={show_upload_image}
                                                                animation='slide up'
                                                                duration={200}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: 70,
                                                                        backgroundColor: 'black',
                                                                        cursor: 'pointer',
                                                                        borderRadius: '0px 0px 1000px 1000px',
                                                                    }}
                                                                    onClick={handleClickUpload}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            justifyContent: 'center',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            height: '100%',
                                                                            flexDirection: 'column',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                name='camera'
                                                                                size='big'
                                                                                style={{ color: 'white' }}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                marginTop: 10,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                // paddingBottom: 20
                                                                            }}
                                                                        >
                                                                            <label
                                                                                style={{
                                                                                    color: 'white',
                                                                                    cursor: 'pointer',
                                                                                    paddingBottom: 5,
                                                                                }}
                                                                            >
                                                                                อัปโหลดรูปภาพ
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Transition>
                                                        </div>
                                                        <Input>
                                                            <input
                                                                type='file'
                                                                id='file'
                                                                hidden
                                                                onChange={handleChangeFile}
                                                                name='image_profile'
                                                                // label='เพิ่มรูปถ่าย'
                                                                accept='image/png, image/jpeg, image/jpg'
                                                            />
                                                        </Input>
                                                    </div>
                                                    {error_profile_image && (
                                                        <Message negative compact>
                                                            โปรดเพิ่มรูปภาพ
                                                        </Message>
                                                    )}
                                                </Grid.Column>
                                                <Grid.Column tablet='10' mobile='16' only='tablet mobile'>
                                                    <Segment
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            zIndex: 1,
                                                            marginTop: 30,
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                            <div style={{ justifyContent: 'center' }}>
                                                                <Image
                                                                    bordered
                                                                    onMouseOver={() => {
                                                                        setShowUploadImage(true)
                                                                    }}
                                                                    style={{
                                                                        width: 150,
                                                                        height: 150,
                                                                        borderRadius: 500,
                                                                    }}
                                                                    src={
                                                                        path_image
                                                                            ? path_image
                                                                            : 'https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png'
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    width: 150,
                                                                    opacity: 0.7,
                                                                    marginBottom: 5,
                                                                }}
                                                                onMouseOver={() => {
                                                                    setShowUploadImage(true)
                                                                }}
                                                            >
                                                                <Transition
                                                                    visible={show_upload_image}
                                                                    animation='slide up'
                                                                    duration={200}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            height: 70,
                                                                            backgroundColor: 'black',
                                                                            cursor: 'pointer',
                                                                            borderRadius: '0px 0px 1000px 1000px',
                                                                        }}
                                                                        onClick={handleClickUpload}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                justifyContent: 'center',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                height: '100%',
                                                                                flexDirection: 'column',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    display: 'flex',
                                                                                }}
                                                                            >
                                                                                <Icon
                                                                                    name='camera'
                                                                                    size='big'
                                                                                    style={{ color: 'white' }}
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 10,
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    display: 'flex',
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        color: 'white',
                                                                                        cursor: 'pointer',
                                                                                        paddingBottom: 5,
                                                                                    }}
                                                                                >
                                                                                    อัปโหลดรูปภาพ
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Transition>
                                                            </div>
                                                            <Input>
                                                                <input
                                                                    type='file'
                                                                    id='file'
                                                                    hidden
                                                                    onChange={handleChangeFile}
                                                                    name='image_profile'
                                                                    // label='เพิ่มรูปถ่าย'
                                                                    accept='image/png, image/jpeg, image/jpg'
                                                                />
                                                            </Input>
                                                        </div>
                                                    </Segment>
                                                    {error_profile_image && (
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Message negative compact>
                                                                โปรดเพิ่มรูปภาพ
                                                            </Message>
                                                        </div>
                                                    )}
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>ชื่อองค์กร (ภาษาไทย)</label>
                                                    <Form.Field error={error_name_th}>
                                                        <Input
                                                            fluid
                                                            name='name_th'
                                                            value={name_th}
                                                            onChange={handleChangeNameTh}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>ชื่อองค์กร (ภาษาอังกฤษ)</label>
                                                    <Form.Field error={error_name_en}>
                                                        <Input
                                                            fluid
                                                            name='name_en'
                                                            value={name_en}
                                                            onChange={handleChangeNameEn}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>
                                                        ชื่อองค์กร (กรอกเฉพาะอักขระภาษาอังกฤษตัวพิมพ์เล็กเท่านั้น)
                                                    </label>
                                                    <Form.Field error={error_short_name}>
                                                        <Input fluid name='short_name' value={short_name} readOnly />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            {/* <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>ธีมสีที่ใช้ในระบบ</label>
                                                    <Form.Field error={error_theme_color}>
                                                        <div
                                                            style={{ cursor: 'pointer', margin: 5 }}
                                                            onClick={() => handleChangeThemeColor('#283655')}>
                                                            <Radio checked={theme_color === '#283655'} />
                                                            <Icon
                                                                name='circle'
                                                                size='huge'
                                                                style={{ color: '#283655' }}
                                                            />
                                                        </div>
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row> */}
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <Label basic>
                                                        <label className='header-input-form'>
                                                            การรับข้อความแจ้งเตือนของระบบ
                                                        </label>
                                                    </Label>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                    <Segment
                                                        style={
                                                            line_internal
                                                                ? {
                                                                      border: '3.5px solid #383655',
                                                                      cursor: 'pointer',
                                                                      margin: 5,
                                                                  }
                                                                : error_line_internal
                                                                ? {
                                                                      border: '1px solid #E0B4B4',
                                                                      backgroundColor: '#FFF6F6',
                                                                      cursor: 'pointer',
                                                                      margin: 5,
                                                                  }
                                                                : {
                                                                      cursor: 'pointer',
                                                                      border: '1px solid #383655',
                                                                      margin: 5,
                                                                  }
                                                        }
                                                        onClick={handleChangeLineInternal}
                                                    >
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column
                                                                    computer='3'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <Radio checked={line_internal} />
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    computer='4'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <Image src={IconLineChat} />
                                                                </Grid.Column>

                                                                <Grid.Column
                                                                    computer='9'
                                                                    mobile='9'
                                                                    tablet='9'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <h5>แจ้งเตือนผ่านไลน์</h5>
                                                                    <label style={{ fontSize: 12, color: '#425679' }}>
                                                                        Line Bot
                                                                    </label>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>

                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                    <Segment
                                                        style={
                                                            no_notification
                                                                ? {
                                                                      border: '3.5px solid #383655',
                                                                      cursor: 'pointer',
                                                                      margin: 5,
                                                                  }
                                                                : error_no_notification
                                                                ? {
                                                                      border: '1px solid #E0B4B4',
                                                                      backgroundColor: '#FFF6F6',
                                                                      cursor: 'pointer',
                                                                      margin: 5,
                                                                  }
                                                                : {
                                                                      cursor: 'pointer',
                                                                      border: '1px solid #383655',
                                                                      margin: 5,
                                                                  }
                                                        }
                                                        onClick={handleChangeNoNotification}
                                                    >
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column
                                                                    computer='3'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <Radio checked={no_notification} />
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    computer='4'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <Icon size='big' name='alarm mute' />
                                                                    </div>
                                                                </Grid.Column>

                                                                <Grid.Column
                                                                    computer='9'
                                                                    mobile='9'
                                                                    tablet='9'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <h5>ไม่รับการแจ้งเตือน</h5>
                                                                    <label style={{ fontSize: 12, color: '#425679' }}>
                                                                        No Notification
                                                                    </label>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>

                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                    <Segment
                                                        disabled
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: '1px solid #383655',
                                                            margin: 5,
                                                        }}
                                                    >
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column
                                                                    computer='3'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <Radio checked={false} />
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    computer='4'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <Icon size='big' name='mobile alternate' />
                                                                    </div>
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    computer='9'
                                                                    mobile='9'
                                                                    tablet='9'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <h5>แอปพลิเคชันพร้อมช่วย</h5>
                                                                    <label style={{ fontSize: 12, color: '#425679' }}>
                                                                        Application Prompt-Chuay
                                                                    </label>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            {line_internal && (
                                                <Fragment>
                                                    <Grid.Row>
                                                        <Grid.Column computer='3' only='computer' />
                                                        <Grid.Column computer='5' mobile='16'>
                                                            <p className='header-input-form'>
                                                                QR Code Line ภายในองค์กร
                                                            </p>
                                                            <Segment style={{ width: 'fit-content' }}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'flex-end',
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        setShowUploadImageQRLineInternal(false)
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{ justifyContent: 'center' }}
                                                                        onMouseOver={() => {
                                                                            setShowUploadImageQRLineInternal(true)
                                                                        }}
                                                                    >
                                                                        <Image
                                                                            bordered
                                                                            src={
                                                                                image_qr_line_internal
                                                                                    ? URL.createObjectURL(
                                                                                          image_qr_line_internal
                                                                                      )
                                                                                    : UploadQRImage
                                                                            }
                                                                            size='small'
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            position: 'absolute',
                                                                            width: 150,
                                                                            opacity: 0.7,
                                                                        }}
                                                                    >
                                                                        <Transition
                                                                            visible={show_upload_image_qr_line_internal}
                                                                            animation='slide up'
                                                                            duration={200}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    height: 70,
                                                                                    backgroundColor: 'black',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onMouseOver={() => {
                                                                                    setShowUploadImageQRLineInternal(
                                                                                        true
                                                                                    )
                                                                                }}
                                                                                onClick={
                                                                                    handleClickUploadQRLineInternal
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        justifyContent: 'center',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        height: '100%',
                                                                                        flexDirection: 'column',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                            display: 'flex',
                                                                                        }}
                                                                                    >
                                                                                        <Icon
                                                                                            name='camera'
                                                                                            size='big'
                                                                                            style={{ color: 'white' }}
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            marginTop: 10,
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                            display: 'flex',
                                                                                            // paddingBottom: 20
                                                                                        }}
                                                                                    >
                                                                                        <label
                                                                                            style={{
                                                                                                color: 'white',
                                                                                                cursor: 'pointer',
                                                                                                paddingBottom: 5,
                                                                                            }}
                                                                                        >
                                                                                            อัปโหลดรูปภาพ
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Transition>
                                                                    </div>
                                                                    <Input>
                                                                        <input
                                                                            type='file'
                                                                            id='file_qr_line_internal'
                                                                            hidden
                                                                            onChange={handleChangeFileQRLineInternal}
                                                                            name='file_qr_line_internal'
                                                                            accept='image/png, image/jpeg, image/jpg'
                                                                        />
                                                                    </Input>
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' mobile='16'>
                                                            <p className='header-input-form'>
                                                                QR Code Line สำหรับแจ้งคำร้อง
                                                            </p>
                                                            <Segment style={{ width: 'fit-content' }}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'flex-end',
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        setShowUploadImageQRLinePerson(false)
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{ justifyContent: 'center' }}
                                                                        onMouseOver={() => {
                                                                            setShowUploadImageQRLinePerson(true)
                                                                        }}
                                                                    >
                                                                        <Image
                                                                            bordered
                                                                            src={
                                                                                image_qr_line_person
                                                                                    ? URL.createObjectURL(
                                                                                          image_qr_line_person
                                                                                      )
                                                                                    : UploadQRImage
                                                                            }
                                                                            size='small'
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            position: 'absolute',
                                                                            width: 150,
                                                                            opacity: 0.7,
                                                                        }}
                                                                    >
                                                                        <Transition
                                                                            visible={show_upload_image_qr_line_person}
                                                                            animation='slide up'
                                                                            duration={200}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    height: 70,
                                                                                    backgroundColor: 'black',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onMouseOver={() => {
                                                                                    setShowUploadImageQRLinePerson(true)
                                                                                }}
                                                                                onClick={handleClickUploadQRLinePerson}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        justifyContent: 'center',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        height: '100%',
                                                                                        flexDirection: 'column',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                            display: 'flex',
                                                                                        }}
                                                                                    >
                                                                                        <Icon
                                                                                            name='camera'
                                                                                            size='big'
                                                                                            style={{ color: 'white' }}
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            marginTop: 10,
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                            display: 'flex',
                                                                                            // paddingBottom: 20
                                                                                        }}
                                                                                    >
                                                                                        <label
                                                                                            style={{
                                                                                                color: 'white',
                                                                                                cursor: 'pointer',
                                                                                                paddingBottom: 5,
                                                                                            }}
                                                                                        >
                                                                                            อัปโหลดรูปภาพ
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Transition>
                                                                    </div>
                                                                    <Input>
                                                                        <input
                                                                            type='file'
                                                                            id='file_qr_line_person'
                                                                            hidden
                                                                            onChange={handleChangeFileQRLinePerson}
                                                                            name='file_qr_line_person'
                                                                            accept='image/png, image/jpeg, image/jpg'
                                                                        />
                                                                    </Input>
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column computer='3' only='computer' />
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column computer='3' only='computer' />
                                                        <Grid.Column computer='10' mobile='16'>
                                                            <p className='header-input-form'>
                                                                การบันทึกข้อมูลของผู้แจ้งคำร้อง
                                                            </p>
                                                            <Grid>
                                                                <Grid.Row>
                                                                    {person_types_option.map(
                                                                        (type: any, index: number) => (
                                                                            <Grid.Column
                                                                                key={index}
                                                                                computer='5'
                                                                                mobile='16'
                                                                            >
                                                                                <Segment
                                                                                    style={
                                                                                        type.id === person_types
                                                                                            ? {
                                                                                                  border: '3.5px solid #383655',
                                                                                                  cursor: 'pointer',
                                                                                              }
                                                                                            : error_person_types
                                                                                            ? {
                                                                                                  border: '1px solid #E0B4B4',
                                                                                                  backgroundColor:
                                                                                                      '#FFF6F6',
                                                                                                  cursor: 'pointer',
                                                                                              }
                                                                                            : {
                                                                                                  cursor: 'pointer',
                                                                                                  margin: 3,
                                                                                                  border: '1px solid #383655',
                                                                                              }
                                                                                    }
                                                                                    onClick={() =>
                                                                                        handleChangePersonTypes(type.id)
                                                                                    }
                                                                                >
                                                                                    <Grid>
                                                                                        <Grid.Row>
                                                                                            <Grid.Column
                                                                                                computer='1'
                                                                                                style={{
                                                                                                    alignSelf: 'center',
                                                                                                }}
                                                                                            >
                                                                                                <Radio
                                                                                                    checked={
                                                                                                        type.id ===
                                                                                                        person_types
                                                                                                    }
                                                                                                />
                                                                                            </Grid.Column>
                                                                                            <Grid.Column
                                                                                                computer='13'
                                                                                                style={{
                                                                                                    alignSelf: 'center',
                                                                                                }}
                                                                                            >
                                                                                                <h5>{type.name}</h5>
                                                                                            </Grid.Column>
                                                                                        </Grid.Row>
                                                                                    </Grid>
                                                                                </Segment>
                                                                            </Grid.Column>
                                                                        )
                                                                    )}
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Grid.Column>
                                                        <Grid.Column computer='3' only='computer' />
                                                    </Grid.Row>
                                                </Fragment>
                                            )}
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <Label basic>
                                                        <label className='header-input-form'>
                                                            กำหนดวิธีการมอบหมายคำร้อง
                                                        </label>
                                                    </Label>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                    <Segment
                                                        style={
                                                            assign_technician
                                                                ? {
                                                                      border: '3.5px solid #383655',
                                                                      cursor: 'pointer',
                                                                  }
                                                                : error_assign_technician
                                                                ? {
                                                                      border: '1px solid #E0B4B4',
                                                                      backgroundColor: '#FFF6F6',
                                                                      cursor: 'pointer',
                                                                  }
                                                                : {
                                                                      cursor: 'pointer',
                                                                      margin: 3,
                                                                      border: '1px solid #383655',
                                                                  }
                                                        }
                                                    >
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column
                                                                    computer='2'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <Radio checked={assign_technician} />
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    computer='4'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            border: '3px solid #394D79',
                                                                            borderRadius: 100,
                                                                            padding: 5,
                                                                        }}
                                                                    >
                                                                        <Image src={IconSetting} />
                                                                    </div>
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    computer='10'
                                                                    mobile='10'
                                                                    tablet='10'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <h5>ส่งคำร้องให้เจ้าหน้าที่โดยตรง</h5>
                                                                    <label
                                                                        style={{
                                                                            fontSize: 12,
                                                                            color: '#425679',
                                                                        }}
                                                                    >
                                                                        มอบหมายคำร้องให้กับเจ้าหน้าที่โดยไม่ผ่านหน่วยงาน
                                                                    </label>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                    <Segment
                                                        style={
                                                            assign_directory
                                                                ? {
                                                                      border: '3.5px solid #383655',
                                                                      cursor: 'pointer',
                                                                  }
                                                                : error_assign_directory
                                                                ? {
                                                                      border: '1px solid #E0B4B4',
                                                                      backgroundColor: '#FFF6F6',
                                                                      cursor: 'pointer',
                                                                  }
                                                                : {
                                                                      cursor: 'pointer',
                                                                      margin: 3,
                                                                      border: '1px solid #383655',
                                                                  }
                                                        }
                                                    >
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column
                                                                    computer='2'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <Radio checked={assign_directory} />
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    computer='4'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            border: '3px solid #394D79',
                                                                            borderRadius: 100,
                                                                            padding: 7,
                                                                        }}
                                                                    >
                                                                        <Image src={IconSitemap} />
                                                                    </div>
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    computer='10'
                                                                    mobile='10'
                                                                    tablet='10'
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                    }}
                                                                >
                                                                    <h5>ส่งคำร้องผ่านหน่วยงาน</h5>
                                                                    <label
                                                                        style={{
                                                                            fontSize: 12,
                                                                            color: '#425679',
                                                                        }}
                                                                    >
                                                                        มอบหมายคำร้องโดยให้หน่วยงานเลือกเจ้าหน้าที่ในสังกัด
                                                                    </label>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <Label basic>
                                                        <label className='header-input-form'>ข้อมูลติดต่อองค์กร</label>
                                                    </Label>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>
                                                        รายละเอียดที่ทำการองค์กร
                                                    </label>
                                                    <Form.Field error={error_detail}>
                                                        <Input
                                                            fluid
                                                            name='detail'
                                                            value={detail}
                                                            onChange={handleChangeDetail}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='3' mobile='16'>
                                                    <label className='header-input-form'>หมายเลขโทรศัพท์</label>
                                                    <Form.Field error={error_phone_number}>
                                                        <Input
                                                            fluid
                                                            name='phone_number'
                                                            value={phone_number}
                                                            onChange={handleChangePhoneNumber}
                                                            maxLength={10}
                                                            type='tel'
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='2' mobile='16'>
                                                    <label className='header-input-form'>ต่อ</label>
                                                    <Form.Field>
                                                        <Input
                                                            fluid
                                                            name='phone_extension'
                                                            value={phone_extension}
                                                            onChange={handleChangePhoneExtension}
                                                            maxLength={4}
                                                            type='tel'
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='5' mobile='16'>
                                                    <label className='header-input-form'>อีเมล</label>
                                                    <Form.Field error={error_email}>
                                                        <Input
                                                            fluid
                                                            name='email'
                                                            value={email}
                                                            onChange={handleChangeEmail}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>

                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='3' mobile='16'>
                                                    <label className='header-input-form'>
                                                        หมายเลขโทรศัพท์ (เพิ่มเติม)
                                                    </label>
                                                    <Form.Field>
                                                        <Input
                                                            fluid
                                                            name='extend_phone_number'
                                                            value={extend_phone_number}
                                                            onChange={handleChangeExtendPhoneNumber}
                                                            maxLength={10}
                                                            type='tel'
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='2' mobile='16'>
                                                    <label className='header-input-form'>ต่อ (เพิ่มเติม)</label>
                                                    <Form.Field>
                                                        <Input
                                                            fluid
                                                            name='extend_phone_extension'
                                                            value={extend_phone_extension}
                                                            onChange={handleChangeExtendPhoneExtension}
                                                            maxLength={4}
                                                            type='tel'
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='5' mobile='16'>
                                                    <label className='header-input-form'>อีเมล (เพิ่มเติม)</label>
                                                    <Form.Field>
                                                        <Input
                                                            fluid
                                                            name='extend_email'
                                                            value={extend_email}
                                                            onChange={handleChangeExtendEmail}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' tablet='16' mobile='16'>
                                                    <CustomSegment
                                                        icon='map marker alternate'
                                                        header='ตำแหน่งที่ตั้งสถานที่'
                                                        loading={loading_map}
                                                    >
                                                        {lat && lng && (
                                                            <GoogleMap
                                                                search
                                                                getCurrentPosition
                                                                style={{ widht: 420, height: 420 }}
                                                                value={{ lat: lat, lng: lng }}
                                                                onChange={handleChangePosition}
                                                            />
                                                        )}
                                                    </CustomSegment>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                        </Grid>
                                    </CustomSegment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    <Button
                                        style={{
                                            width: 100,
                                            backgroundColor: '#283655',
                                            color: '#FFF',
                                            marginRight: 15,
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        บันทึก
                                    </Button>
                                    <Button
                                        style={{
                                            width: 100,
                                            backgroundColor: '#C54C40',
                                            color: '#FFF',
                                            marginLeft: 15,
                                        }}
                                        onClick={() => history.goBack()}
                                    >
                                        ยกเลิก
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Container>
            </div>
        </Fragment>
    )
}

export default ManagementUsersFormEdit
