import React, { useEffect, useState } from 'react'
import {
    Button,
    Container,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Label,
    Message,
    Segment,
} from 'semantic-ui-react'
import IconOnePlatform from '../../../../assets/images/logo one platform.png'
import axios from 'axios'
import { LOGIN_ONE_ID } from '../../../../config/api/common'
import { useHistory, useLocation } from 'react-router-dom'

const LineRegisterLoginOneId = () => {
    let history = useHistory()
    let location = useLocation()
    let [username, setUsername] = useState<string>('')
    let [password, setPassword] = useState<string>('')
    let [username_error, setUsernameError] = useState<boolean>(false)
    let [password_error, setPasswordError] = useState<boolean>(false)
    let [show_password, setShowPassword] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(false)
    let [error_text, setErrorText] = useState<string>('')
    let [liff_id, setLiffId] = useState<string | null>('')

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { liff_id } = location_state
            setLiffId(liff_id)
        }
    }, [location])

    const handleChangeUsername = (_: any, { value }: InputOnChangeData) => {
        setUsername(value)
        setUsernameError(false)
    }
    const handleChangePassword = (_: any, { value }: InputOnChangeData) => {
        setPassword(value)
        setPasswordError(false)
    }

    const handleClickForgetPassword = () => {
        history.push(`forget-password`)
    }

    const validate = () => {
        let error = false
        if (!username) {
            error = true
            setUsernameError(true)
        }
        if (!password) {
            error = true
            setPasswordError(true)
        }
        return error
    }

    const handleSubmit = () => {
        setLoading(true)
        if (!validate()) {
            axios
                .post(LOGIN_ONE_ID, { username: username, password: password })
                .then(() => {
                    window.location.assign(`https://liff.line.me/${liff_id}?username=${username}&password=${password}`)
                    setLoading(false)
                })
                .catch((error) => {
                    setPassword('')
                    if (error.response) {
                        let message = error.response.data.message
                        if (message === 'wrong username and password') {
                            setErrorText('ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง')
                        } else if (message === 'wrong password') {
                            setErrorText('ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง')
                        } else if (message === 'wrong username') {
                            setErrorText('ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง')
                        }
                    } else {
                        setErrorText('ระบบเกิดข้อผิดพลาด')
                    }
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }

        //
    }

    return (
        <div className='background-container-no-sidebar'>
            <Container>
                <Segment className='segment-login-page' loading={loading}>
                    <div>
                        <div className='header-segment-login-page'>
                            <div className='image-one-platform'>
                                <Image src={IconOnePlatform} />
                            </div>
                        </div>
                        <div className='body-segment-login-page'>
                            <Form>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Field error={username_error}>
                                                <Input
                                                    label={
                                                        <Label basic>
                                                            <Icon name='user' fitted color='grey' />
                                                        </Label>
                                                    }
                                                    fluid
                                                    placeholder='ชื่อผู้ใช้งาน'
                                                    name='username'
                                                    value={username}
                                                    onChange={handleChangeUsername}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Field error={password_error}>
                                                <Input
                                                    label={
                                                        <Label basic>
                                                            <Icon name='lock' fitted color='grey' />
                                                        </Label>
                                                    }
                                                    fluid
                                                    icon={
                                                        <Icon
                                                            name={show_password ? 'eye slash' : 'eye'}
                                                            link
                                                            onClick={() => {
                                                                setShowPassword(!show_password)
                                                            }}
                                                        />
                                                    }
                                                    placeholder='รหัสผ่าน'
                                                    type={show_password ? 'text' : 'password'}
                                                    name='password'
                                                    value={password}
                                                    onChange={handleChangePassword}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column textAlign='right'>
                                            <label
                                                style={{ color: '#53A6D8', cursor: 'pointer' }}
                                                onClick={handleClickForgetPassword}
                                            >
                                                ลืมรหัสผ่าน
                                            </label>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {error_text && (
                                        <Grid.Row>
                                            <Grid.Column>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Message negative>{error_text}</Message>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Button
                                                    style={{ backgroundColor: '#4D648D', color: '#ffffff' }}
                                                    onClick={handleSubmit}
                                                >
                                                    ลงชื่อเข้าใช้
                                                </Button>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </div>
                        <div className='footer-segment-login-page' />
                    </div>
                </Segment>
            </Container>
        </div>
    )
}
export default LineRegisterLoginOneId
