import React, { useEffect, useState } from 'react';
import { Grid, Segment, Icon, Image } from 'semantic-ui-react';
import { GET_DASHBOARD_COUNT_STATUS_TECHNICIAN } from '../../../config/api/dashboard';
import { axiosAuthen } from '../../../config/axiosAuthen';
import IconTrack from '../../../../src/assets/images/track.png';
import IconProcess from '../../../../src/assets/images/process.png';

type FormProps = {
    technician_id?: string;
    year?: string | number | boolean | (string | number | boolean)[] | undefined;
};

export interface InterfaceStatus {
    count: number;
    percent: string;
    status_en: string;
    status_th: string;
}

const DashboardTechnianAllStatus: React.FC<FormProps> = (props) => {
    let { technician_id } = props;
    let { year } = props;
    let [list_data, setListData] = useState<any[]>([]);
    useEffect(() => {
        axiosAuthen.get(`${GET_DASHBOARD_COUNT_STATUS_TECHNICIAN}/${technician_id}?year=${year === 'all' ? '' : year}`).then((response) => {
            let { value } = response.data;
            setListData(value);
        });
    }, [technician_id, year]);

    const getIcon = (status_name: string) => {
        let list_icon = [
            { status_name: 'คำร้องทุกข์ทั้งหมด', icon: <Icon name='th large' size='big' /> },
            { status_name: 'รอรับเรื่อง', icon: <Icon name='hourglass one' size='big' /> },
            { status_name: 'เข้าแผนปีงบประมาณ', icon: <Icon name='hourglass half' size='big' /> },
            { status_name: 'อยู่ระหว่างการติดตาม', icon: <Image src={IconTrack} style={{ height: 28 }} /> },
            { status_name: 'กำลังดำเนินการ', icon: <Image src={IconProcess} style={{ height: 28 }} />,  },
            { status_name: 'เข้าแผนปีงบประมาณ', icon: <Icon name='hourglass half' size='big' /> },
            { status_name: 'ใกล้ครบกำหนด', icon: <Icon name='clock' size='big' /> },
            { status_name: 'เกินกำหนด', icon: <Icon name='calendar times' size='big' /> },
            { status_name: 'ไม่สามารถดำเนินการได้', icon: <Icon name='cancel' size='big' /> },
            { status_name: 'รอประเมินผล', icon: <Icon name='clipboard' size='big' /> },
            { status_name: 'ประเมินผลเสร็จสิ้น', icon: <Icon name='clipboard check' size='big' /> },
        ];
        let icon = list_icon.find((status) => status.status_name === status_name);
        if (!icon) {
            return 'None Icon';
        }
        return icon.icon;
    };

    const getColor = (status_name: string) => {
        let list_color = [
            { status_name: 'คำร้องทุกข์ทั้งหมด', color: '#283655' },
            { status_name: 'รอรับเรื่อง', color: '#FBBD08' },
            { status_name: 'เข้าแผนปีงบประมาณ', color: '#A5673F' },
            { status_name: 'อยู่ระหว่างการติดตาม', color: '#2C2C2C' },
            { status_name: 'กำลังดำเนินการ', color: '#017ED9' },
            { status_name: 'ใกล้ครบกำหนด', color: '#F2711C' },
            { status_name: 'เกินกำหนด', color: '#B6392D' },
            { status_name: 'ไม่สามารถดำเนินการได้', color: '#767676' },
            { status_name: 'รอประเมินผล', color: '#B5CC18' },
            { status_name: 'ประเมินผลเสร็จสิ้น', color: '#70B982 ' },
        ];

        let color = list_color.find((status) => status.status_name === status_name);
        if (!color) {
            return '#E8E8E8';
        }
        return color.color;
    };

    return (
        <Grid>
            <Grid.Row>
                {list_data.map((data: InterfaceStatus, index: number) => (
                    <Grid.Column key={index} computer='4' tablet='8' mobile='16' style={{ marginTop: 40 }}>
                        <Segment
                            size='large'
                            style={{ boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.25)', cursor: 'pointer' }}
                        // onClick={() => handleClick(data)}
                        >
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column computer='8'>
                                        <Segment
                                            style={{
                                                marginTop: -40,
                                                padding: 30,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                backgroundColor: getColor(data.status_th),
                                                color: '#FFF',
                                                boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.25)',
                                            }}>
                                            {getIcon(data.status_th)}
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column computer='8' textAlign='right'>
                                        <h2 style={{ color: '#293757' }}>{data.count}</h2>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row textAlign='right'>
                                    <Grid.Column>
                                        <h5>{data.status_th}</h5>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                ))}
            </Grid.Row>
        </Grid>
    );
};

export default DashboardTechnianAllStatus;
