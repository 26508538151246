import { HOST } from '../router'

const NOTIFICATION = `${HOST}/notification`
// TODO: MANAGE NOTIFICATION
export const GET_NOTIFICATION_TOPICS = `${NOTIFICATION}/notification_topics`
export const GET_NOTIFICATION_MUNICIPALITY = `${NOTIFICATION}/notification_municipality`
export const PUT_NOTIFICATION_CREATE_AND_EDIT_NOTIFICATION_MUNICIPALITY_AND_ROLES = `${NOTIFICATION}/create_and_edit_notification_municipality_and_roles`
export const GET_NOTIFICATION_COMPLAINTS = `${NOTIFICATION}/notification_complaints`
export const GET_NOTIFICATION_LINE = `${NOTIFICATION}/notification_line`

// TODO: LINE
const LINE = `${NOTIFICATION}/line`
export const POST_NOTIFICATION_LINE_CHECK_REGISTER = `${LINE}/check_register`
export const POST_NOTIFICATION_LINE_USER_BIND_LINE_USER_ID = `${LINE}/user_bind_line_user_id`
export const POST_NOTIFICATION_LINE_CREATE_PRE_COMPLAINT = `${LINE}/create_pre_complaint`
export const GET_NOTIFICATION_LINE_PROFILE = `${LINE}/profile`
export const PUT_NOTIFICATION_LINE_EDIT_PROFILE = `${LINE}/edit_profile`
export const GET_NOTIFICATION_LINE_DETAIL_PRE_COMPLAINT = `${LINE}/detail_pre_complaint`
export const GET_NOTIFICATION_LINE_HISTORY_PRE_COMPLAINT = `${LINE}/history_pre_complaint`
export const GET_NOTIFICATION_LINE_LIST_PRE_COMPLAINT = `${LINE}/list_pre_complaint`
export const PUT_NOTIFICATION_LINE_EVALUATE_PRE_COMPLAINT = `${LINE}/evaluate_pre_complaint`
export const GET_NOTIFICATION_LINE_DETAIL_EVALUATE_PRE_COMPLAINT = `${LINE}/detail_evaluate_pre_complaint`
export const POST_NOTIFICATION_LINE_REGISTER_PERSON = `${LINE}/register_person`
export const POST_NOTIFICATION_LINE_CONTRACT_MUNICIPALITY = `${LINE}/contact`

// TODO: ONE CHAT
const ONE_CHAT = `${NOTIFICATION}/one_chat`
export const POST_NOTIFICATION_ONE_CHAT_CHECK_REGISTER = `${ONE_CHAT}/check_register`
export const POST_NOTIFICATION_ONE_CHAT_USER_BIND_ONE_USER_ID = `${ONE_CHAT}/user_bind_one_user_id`
export const POST_NOTIFICATION_ONE_CHAT_CREATE_PRE_COMPLAINT = `${ONE_CHAT}/create_pre_complaint`
export const POST_NOTIFICATION_ONE_CHAT_ONE_ID_PERSON = `${ONE_CHAT}/one_id_person`
export const GET_NOTIFICATION_ONE_CHAT_TRACK_PRE_COMPLAINT_ALL = `${ONE_CHAT}/track_pre_complaint_all`
export const GET_NOTIFICATION_ONE_CHAT_PROFILE = `${ONE_CHAT}/profile`
export const PUT_NOTIFICATION_ONE_CHAT_EDIT_PROFILE = `${ONE_CHAT}/edit_profile`
export const GET_NOTIFICATION_ONE_CHAT_DETAIL_PRE_COMPLAINT = `${ONE_CHAT}/detail_pre_complaint`
export const GET_NOTIFICATION_ONE_CHAT_HISTORY_PRE_COMPLAINT = `${ONE_CHAT}/history_pre_complaint`
export const GET_NOTIFICATION_ONE_CHAT_LIST_PRE_COMPLAINT = `${ONE_CHAT}/list_pre_complaint`
export const PUT_NOTIFICATION_ONE_CHAT_EVALUATE_PRE_COMPLAINT = `${ONE_CHAT}/evaluate_pre_complaint`
export const GET_NOTIFICATION_ONE_CHAT_DETAIL_EVALUATE_PRE_COMPLAINT = `${ONE_CHAT}/detail_evaluate_pre_complaint`
