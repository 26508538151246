import axios from 'axios'
import { GET_CONTACT_ALL } from '../config/api/contacts'
import { InterfacePreComplaint } from '../containers/Complaint/List/DetailPreComplaint'
import store from '../store'
import { appendForm, resetFormByReducerName } from '../store/actions'

const SetFieldPreComplaint = async (pre_complaint: InterfacePreComplaint) => {
    let contact = await getAllContact().then((response) => response.data.value)
    let file_complaint: [] = []

    let option_contact: any[] = []

    contact.forEach((data: any) => {
        option_contact.push({ value: data.id, text: data.name })
    })
    let contact_value = option_contact.find((contact) => contact.text === pre_complaint.contact_name)

    let step1 = {
        pre_complaint_id: pre_complaint.id,
        complaint_topic: { value: pre_complaint.topic_id, text: pre_complaint.topic_name },
        complaint_type: { value: pre_complaint.type_id || '', text: pre_complaint.type_name || '' },
        complaint_subject: pre_complaint.complaint_subject,
        complaint_detail: pre_complaint.complaint_detail,
        contact: contact_value,
        file_complaint: file_complaint,
        year: String(new Date().getFullYear() + 543),
        complaint_date: pre_complaint.pre_complaint_date,
    }
    let step2 = {
        prefix: pre_complaint.prefix,
        first_name: pre_complaint.first_name,
        last_name: pre_complaint.last_name,
        house_no: pre_complaint.house_no,
        village_no: pre_complaint.village_no,
        alley: pre_complaint.alley,
        road: pre_complaint.road,
        sub_district: pre_complaint.subdistrict,
        district: pre_complaint.district,
        province: pre_complaint.province,
        zipcode: pre_complaint.zipcode,
        phone_number: pre_complaint.phone_number || '',
        mobile_number: pre_complaint.mobile_number,
        email: pre_complaint.email,
    }
    let step3 = {
        position: {
            lat: pre_complaint.lat,
            lng: pre_complaint.lng,
        },
    }
    store.dispatch(resetFormByReducerName('ComplaintFormStep1'))
    store.dispatch(resetFormByReducerName('ComplaintFormStep2'))
    store.dispatch(resetFormByReducerName('ComplaintFormStep3'))
    store.dispatch(appendForm('ComplaintFormStep1', step1))
    store.dispatch(appendForm('ComplaintFormStep2', step2))
    store.dispatch(appendForm('ComplaintFormStep3', step3))
    return true
}

const getAllContact = () => {
    return axios.get(GET_CONTACT_ALL)
}

export default SetFieldPreComplaint
