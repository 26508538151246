import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { Breadcrumb, Card, Container, Grid, Image } from 'semantic-ui-react'
import IconManagePage from '../../../../assets/images/menu admin/mapping pages.png'
import IconManageMappingRoles from '../../../../assets/images/menu admin/mapping roles.png'
import IconManageRoles from '../../../../assets/images/menu admin/roles.png'
// import IconManageUserManual from '../../../../assets/images/menu admin/user manual.png'
import IconManageUsers from '../../../../assets/images/menu admin/users.png'
import IconManageEdit from '../../../../assets/images/menu admin/edit.png'
import IconManageOrganization from '../../../../assets/images/menu admin/organization.png'
import IconManageSystem from '../../../../assets/images/menu admin/manage_system.png'
import IconManageNoti from '../../../../assets/images/menu admin/noti/noti.png'
import IconManageLine from '../../../../assets/images/menu admin/line.png'
// import IconManageTierDirectory from '../../../../assets/images/menu admin/tier directory.png'
// import IconManageMappingTierDirectory from '../../../../assets/images/menu admin/mapping tier directory.png'

const MunicipalitiesMenu: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let params = useParams()
    let [municipality_id, setMunicipality] = useState<number>()

    const handleClickMenu = (menu: string) => {
        let { state } = location
        let location_state = JSON.parse(JSON.stringify(state, null, ' '))
        let param = JSON.parse(JSON.stringify(params, null, ' '))
        let { permission } = param
        let { municipality_id } = location_state
        history.push(`/${permission}/management/${menu}`, { municipality_id: municipality_id })
        setMunicipality(municipality_id)
    }

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities`, {
                                                municipality_id: municipality_id,
                                            })
                                        }}
                                    >
                                        รายการองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>กำหนดการใช้งานองค์กร</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>กำหนดการใช้งานองค์กร</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ marginLeft: 84 }}>
                            <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('municipality/detail')}
                                >
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image src={IconManagePage} style={{ height: 120 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>ข้อมูลองค์กร</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('municipality/line')}
                                >
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image src={IconManageLine} style={{ height: 120 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>ตั้งค่า LINE OA</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            {/* <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('municipality/form-edit')}
                                >
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image src={IconManageEdit} style={{ height: 120,  }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>แก้ไขรายละเอียดองค์กร</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column> */}
                            {/* <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('level-directory/list')}
                                >
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image
                                                        src={IconManageTierDirectory}
                                                        style={{ height: 120,  }}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>กำหนดระดับหน่วยงาน</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column> */}
                            <Grid.Column computer='5'>
                                <Card className='card-menu-municipalities' onClick={() => handleClickMenu('roles')}>
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image src={IconManageRoles} style={{ height: 120 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>กำหนดสิทธิ์องค์กร</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            {/* <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('roles-in-level-directory')}
                                >
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image
                                                        src={IconManageMappingTierDirectory}
                                                        style={{ height: 120,  }}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>กำหนดสิทธิ์ในระดับหน่วยงาน</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column> */}
                            <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('directory-of-municipality/list')}
                                >
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image src={IconManageOrganization} style={{ height: 120 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>กำหนดโครงสร้างและหน่วยงาน</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('usage-municipality')}
                                >
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image src={IconManageEdit} style={{ height: 120 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>กำหนดการใช้งานองค์กร</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('usage-role')}
                                >
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image src={IconManageMappingRoles} style={{ height: 120 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>กำหนดสิทธิ์การเข้าใช้งาน</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column computer='5'>
                                <Card className='card-menu-municipalities' onClick={() => handleClickMenu('system')}>
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image src={IconManageSystem} style={{ height: 120 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>ตั้งค่าข้อมูลระบบ</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column computer='5'>
                                <Card className='card-menu-municipalities' onClick={() => handleClickMenu('users')}>
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image src={IconManageUsers} style={{ height: 120 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>สมาชิก</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('notification')}
                                >
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image src={IconManageNoti} style={{ height: 120 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>กำหนดการแจ้งเตือน</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            {/* <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('usage-user')}>
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image
                                                        src={IconManageMappingUsers}
                                                        style={{ height: 120,  }}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>กำหนดการใช้งานส่วนบุคคล</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column> */}
                            {/* <Grid.Column computer='5'>
                                <Card
                                    className='card-menu-municipalities'
                                    onClick={() => handleClickMenu('user-manual')}
                                >
                                    <Card.Content>
                                        <Grid textAlign='center'>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Image
                                                        src={IconManageUserManual}
                                                        style={{ height: 120,  }}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h4>กำหนดคู่มือการใช้งาน</h4>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column> */}
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}
export default MunicipalitiesMenu
