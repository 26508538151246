import React, { Component } from 'react'
import { getGoogleMaps } from '../../config/google-maps'
import MarkerClustererPlus from 'marker-clusterer-plus'
import { statusList } from '../../constants'

class GoogleMapListMarker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            google: '',
            map: '',
            cluster: '',
        }
    }
    importIconAll = (response) => {
        let icons = []
        response.keys().forEach((item) => {
            let objIcon = {
                name: item.replace('./', '').replace('.png', ''),
                path: response(item),
            }
            icons.push(objIcon)
        })
        return icons
    }

    mappingIcon = (status) => {
        let status_name = statusList.find((data) => data.key === status)

        let mapping_status = ''

        if (status_name) {
            mapping_status = status_name.value
        }
        return mapping_status
    }

    componentWillReceiveProps = (props) => {
        let { google, map, markers, cluster } = this.state
        if (props.listData !== this.props.listData) {
            if (google && map) {
                let icons = this.importIconAll(require.context('../../assets/markers', false, /\.(png|jpe?g|svg)$/))
                if (cluster) {
                    cluster.setMap(null)
                    cluster.clearMarkers()
                    cluster.removeMarkers(markers)
                }
                markers.forEach((marker) => {
                    marker.setMap(null)
                })
                markers = []
                props.listData.forEach((data, index) => {
                    let icon_name = this.mappingIcon(data.complaint_status_id)
                    let mapping_icon = icons.find((icon) => icon.name === icon_name)
                    let icon = ''
                    if (mapping_icon) {
                        icon = {
                            url: mapping_icon.path,
                            scaledSize: new google.maps.Size(40, 40),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(0, 0),
                        }
                    }

                    let marker = new google.maps.Marker({
                        map: map,
                        icon: icon,

                        position: { lat: Number(data.lat), lng: Number(data.lng) },
                    })
                    markers.push(marker)
                    marker.addListener('click', () => {
                        this.props.onClick(data, index)
                    })

                    this.setState({ markers: markers }, () => {
                        this.setMarkerCluster()
                    })
                })
            }
        }
    }

    setMarkerCluster = () => {
        let { markers, cluster, map } = this.state
        cluster.setMap(map)
        cluster.addMarkers(markers)
    }

    componentDidMount = () => {
        let icons = this.importIconAll(require.context('../../assets/markers', false, /\.(png|jpe?g|svg)$/))
        let googleMaps = getGoogleMaps()
        let center_lat = 16.42979567373359
        let center_lng = 102.82961169215218
        googleMaps.then((value) => {
            let google = value
            let map = new google.maps.Map(document.getElementById('map'), {
                zoom: 16,
                center: {
                    lat: center_lat,
                    lng: center_lng,
                },
                styles: [
                    {
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [{ visibility: 'off' }],
                    },
                ],
                scrollwheel: true,
            })
            let markers = []
            this.props.listData.forEach((data, index) => {
                let icon_name = this.mappingIcon(data.complaint_status_id)
                let mapping_icon = icons.find((icon) => icon.name === icon_name)
                let icon = ''
                if (mapping_icon) {
                    icon = {
                        url: mapping_icon.path,
                        scaledSize: new google.maps.Size(40, 40),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(0, 0),
                    }
                }

                let marker = new google.maps.Marker({
                    map: map,
                    icon: icon,
                    position: { lat: Number(data.lat), lng: Number(data.lng) },
                })

                marker.addListener('click', () => {
                    this.props.onClick(data, index)
                })
                markers.push(marker)
            })
            let cluster = new MarkerClustererPlus(map, markers, {
                imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
            })

            this.setState({ map: map, google: google, markers: markers, cluster: cluster }, () =>
                this.props?.onLoaded()
            )
        })
    }

    render() {
        return <div id='map' style={{ height: window.innerHeight / 1.2 }} />
    }
}

export default GoogleMapListMarker
