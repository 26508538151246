import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Form, Grid, Input, InputOnChangeData, Icon, Dropdown, DropdownProps } from 'semantic-ui-react'

import CustomModal from '../../../../../components/CustomModal'
import { POST_COMPALINT_TOPIC_AND_TYPES } from '../../../../../config/api/complaintTopicAndTypes'
import { GET_DIRECTORY_LIST_BY_MUNICIPALITY } from '../../../../../config/api/directoryOfMunicipality'
import { axiosAuthen } from '../../../../../config/axiosAuthen'

type FormProps = {
    // data?: InterfaceDistrictsAndComplaintType;
    open?: boolean
    onClose?: () => void
}

export interface InterfaceComplaintType {
    complaint_topic_id: number
    id: string
    jobs_departments: string
    errors_jobs_departments: boolean
}

const CreateComplaint: React.FC<FormProps> = (props) => {
    let municipalities_id = localStorage.municipality_id
    let location = useLocation()
    let { state } = location
    if (state) {
        let location_state = JSON.parse(JSON.stringify(state, null, ' '))
        let { municipality_id } = location_state
        if (municipality_id) municipalities_id = municipality_id
    }
    let [directory_of_municipality_option, setDirectoryOfMunicipalityOption] = useState<any[]>([])
    let [directory_of_municipality, setDirectoryOfMunicipality] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >('')
    let [complaint, setComplaint] = useState<string | undefined>()
    let [prefix_number, setPrefixNumber] = useState<string | undefined>()
    let [errors_directory_of_municipality, setErrorsDirectoryOfMunicipality] = useState<boolean>(false)
    let [errors_complaint, setErrorsComplaint] = useState<boolean>(false)
    let [errors_prefix_number, setErrorsPrefixNumber] = useState<boolean>(false)
    let [list_jobs_departments, setJobsDepartments] = useState<any[]>([
        {
            jobs_departments: '',
            errors_jobs_departments: false,
        },
    ])

    const handleChangeComplaintType = (name: any, value: any, index: number) => {
        let form = list_jobs_departments
        if (form) {
            form[index][name] = value
            form[index].errors_jobs_departments = false
            setJobsDepartments([...form])
        }
    }

    const getDirectoryOfMunicipality = useCallback(() => {
        axiosAuthen.get(`${GET_DIRECTORY_LIST_BY_MUNICIPALITY}/${municipalities_id}`).then((response) => {
            let { value } = response.data
            let option = value.map((data: any) => ({
                value: data.id,
                text: data.name,
            }))
            setDirectoryOfMunicipalityOption(option)
        })
    }, [municipalities_id])

    useEffect(() => {
        getDirectoryOfMunicipality()
    }, [getDirectoryOfMunicipality])

    const handleChangeDirectory = (_: any, { value }: DropdownProps) => {
        setDirectoryOfMunicipality(value)
        setErrorsDirectoryOfMunicipality(false)
    }

    const handleChangeComplaint = (e: any, { value }: InputOnChangeData) => {
        setComplaint(value)
        setErrorsComplaint(false)
    }

    const handleChangePrefixNumber = (e: any, { value }: InputOnChangeData) => {
        setPrefixNumber(value)
        setErrorsPrefixNumber(false)
    }

    const hanldeRemoveComplaintTopic = (index: number, data: InterfaceComplaintType) => {
        setJobsDepartments(list_jobs_departments.filter((select: any) => select !== data))
    }

    const handleAddCommuni = (e: any, { value }: any) => {
        var data = {
            jobs_departments: '',
            errors_jobs_departments: false,
        }
        setJobsDepartments([...list_jobs_departments, data])
    }

    const validateForm = () => {
        let error = false

        if (!directory_of_municipality) {
            error = true
            setErrorsDirectoryOfMunicipality(true)
        }

        if (!complaint) {
            error = true
            setErrorsComplaint(true)
        }

        if (!prefix_number) {
            error = true
            setErrorsPrefixNumber(true)
        }

        if (list_jobs_departments.length === 0) {
            error = true
        }

        list_jobs_departments.forEach((data, index) => {
            if (!data.jobs_departments) {
                let form = list_jobs_departments
                error = true
                form[index].errors_jobs_departments = true

                setJobsDepartments([...form])
            }
        })

        return error
    }

    const handleSubmit = () => {
        if (!validateForm()) {
            let body = {
                complaint_types: list_jobs_departments.map((data) => ({ name: data.jobs_departments })),
                municipality_id: Number(municipalities_id),
                name: complaint,
                short_name: prefix_number,
                directory_of_municipalities_id: directory_of_municipality,
            }

            axiosAuthen.post(POST_COMPALINT_TOPIC_AND_TYPES, body).then((response) => {
                handleClose()
            })
        }
    }

    const handleClose = () => {
        props.onClose && props.onClose()
        setDirectoryOfMunicipality('')
        setJobsDepartments([])
        setComplaint('')
        setPrefixNumber('')
        setErrorsDirectoryOfMunicipality(false)
        setErrorsComplaint(false)
        setErrorsPrefixNumber(false)
    }

    return (
        <Fragment>
            <CustomModal icon='users' header='เพิ่มเรื่องร้องทุกข์' open={props.open}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <Form.Field error={errors_directory_of_municipality}>
                                    <label className='header-input-form'>หน่วยงาน</label>
                                    <Dropdown
                                        fluid
                                        selection
                                        search
                                        name='directory_of_municipality'
                                        value={directory_of_municipality}
                                        options={directory_of_municipality_option}
                                        onChange={handleChangeDirectory}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>เรื่องร้องทุกข์</label>
                                <Form.Field error={errors_complaint}>
                                    <Input fluid name='complaint' value={complaint} onChange={handleChangeComplaint} />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>อักษรนำหน้าเลขคำร้อง</label>
                                <Form.Field error={errors_prefix_number}>
                                    <Input
                                        fluid
                                        name='prefix'
                                        value={prefix_number}
                                        onChange={handleChangePrefixNumber}
                                    />
                                </Form.Field>
                                <span>
                                    <label style={{ color: 'red' }}>*</label>{' '}
                                    <label style={{ color: 'gray' }}>
                                        อักษรย่อจะถูกนำไปใช้ในการระบุเลขคำร้อง ตัวอย่าง ฟ = ฟ.123/63 ( งานไฟฟ้า )
                                    </label>
                                </span>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>

                        {list_jobs_departments.map(
                            (data: InterfaceComplaintType, index: number) => (
                                // indexShow === index && (
                                <Grid.Row key={index}>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='8' mobile='16'>
                                        <label className='header-input-form'>ประเภทงาน</label>
                                        <Form.Field error={data.errors_jobs_departments}>
                                            <Input
                                                fluid
                                                onChange={(e, { name, value }) => {
                                                    handleChangeComplaintType(name, value, index)
                                                }}
                                                name='jobs_departments'
                                                value={data.jobs_departments}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='2' mobile='16'>
                                        <br />
                                        <Button
                                            basic
                                            color='red'
                                            icon='trash alternate'
                                            onClick={() => hanldeRemoveComplaintTopic(index, data)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Grid.Row>
                            )
                            // )
                        )}

                        {/* ปุ่มเพิ่มชุมชน value */}
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <Button fluid basic color='blue' onClick={handleAddCommuni}>
                                    <Icon
                                        circular
                                        size='small'
                                        color='blue'
                                        name='plus'
                                        style={{ boxShadow: '0px 0px 0px 1px #1F7EC7' }}
                                    />
                                    เพิ่มประเภทงานฝ่ายงาน
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>

                        {/* ปุ่มบันทึกและยกเลิก value */}
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default CreateComplaint
