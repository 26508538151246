import {
  Document,
  Font,
  Image,
  Page,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import Sarabun from "../../../../../assets/fonts/Sarabun-Light.ttf";
import React, { Fragment, useState } from "react";
import { Button, Container, Grid, Icon } from "semantic-ui-react";
import { InterfaceMunicipality } from "..";
import CustomModal from "../../../../../components/CustomModal";
import { HOST } from "../../../../../config/router";

Font.register({ family: "Sarabun", src: Sarabun });

const styles = StyleSheet.create({
  page: {
    margin: 25,
    marginLeft: 46,
    marginRight: 25,
    fontFamily: "Sarabun",
    fontSize: 12,
    width: 525,
  },
  header: {
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    marginRight: 160,
    fontWeight: 900,
    fontSize: 25,
  },
  font_bold: {
    fontWeight: "bold",
  },
  row: {
    marginTop: 3,
    flexDirection: "row",
    width: 510,
  },
});

interface InterfacePropsExportInternal {
  municipality?: InterfaceMunicipality;
}

const MunicipalitiesDetailExportInternal: React.FC<
  InterfacePropsExportInternal
> = (props) => {
  let { municipality } = props;
  let [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <CustomModal
        icon="file pdf"
        header="ข้อมูลองค์กรสำหรับเจ้าหน้าที่"
        open={open}
        trigger={
          <Button
            color="blue"
            fluid
            icon
            labelPosition="left"
            onClick={handleOpen}
          >
            <Icon name="file pdf" /> ไฟล์สำหรับเจ้าหน้าที่
          </Button>
        }
      >
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <div
                  style={{
                    backgroundColor: "#F1F1F1",
                    padding: 20,
                    marginTop: 10,
                    paddingLeft: 30,
                    paddingRight: 30,
                  }}
                >
                  <PDFDownloadLink
                    document={<DetailDocument municipality={municipality} />}
                    fileName={`รายละเอียดองค์กร${municipality?.name_th}.pdf`}
                  >
                    <Fragment>
                      <Button primary floated="right">
                        ดาวน์โหลดเอกสาร
                      </Button>
                      <PDFViewer
                        width="100%"
                        height={window.innerHeight - 300}
                        style={{ marginTop: 10 }}
                      >
                        <DetailDocument municipality={municipality} />
                      </PDFViewer>
                    </Fragment>
                  </PDFDownloadLink>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  style={{
                    width: 100,
                    backgroundColor: "#C54C40",
                    color: "#FFF",
                  }}
                  onClick={handleClose}
                >
                  ปิด
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </CustomModal>
    </Fragment>
  );
};

const DetailDocument: React.FC<InterfacePropsExportInternal> = (props) => {
  let { municipality } = props;

  if (municipality) {
    return (
      <Document title="ข้อมูลองค์กร">
        <Page wrap style={styles.page}>
          <View
            style={{
              ...styles.row,
              ...{
                display: "flex",
                justifyContent: "center",
                width: 510,
              },
            }}
          >
            <Image
              src={`${HOST}${municipality.logo}`}
              style={{
                maxWidth: 100,
                maxHeight: 100,
                minHeight: 50,
                minWidth: 50,
                margin: 10,
                objectFit: "cover",
              }}
            />
          </View>
          <Br />
          <View
            style={{
              ...styles.row,
              ...{
                display: "flex",
                justifyContent: "center",
                width: 510,
              },
            }}
          >
            <Text
              style={{
                ...styles.font_bold,
                ...{
                  fontSize: 18,
                },
              }}
            >
              {`${municipality.name_th} `}
            </Text>
          </View>
          <View
            style={{
              ...styles.row,
              ...{
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <Text
              style={{
                ...styles.font_bold,
                ...{
                  fontSize: 8,
                },
              }}
            >
              {`${municipality.name_en} `}
            </Text>{" "}
            <Text
              style={{
                ...styles.font_bold,
                ...{
                  fontSize: 8,
                },
              }}
            >
              {`(${municipality.short_name})`}
            </Text>
          </View>
          <Br />
          <View style={styles.row}>
            <Text
              style={{
                ...styles.font_bold,
                ...{
                  width: "20%",
                },
              }}
            >{`ข้อมูลองค์กร`}</Text>
            <Text
              style={{
                ...styles.font_bold,
                ...{
                  width: "70%",
                  fontSize: 12,
                  marginTop: 1,
                },
              }}
            >
              {`${municipality.detail}`}{" "}
            </Text>
          </View>
          <View style={styles.row}>
            <View style={{ width: "20%" }}>
              <Text style={styles.font_bold}>{`ติดต่อ`}</Text>
            </View>
            <View style={{ width: "70%" }}>
              <View style={styles.row}>
                <Text
                  style={{
                    ...styles.font_bold,
                    ...{
                      fontSize: 12,
                      marginTop: 1,
                      flexWrap: "wrap",
                    },
                  }}
                >
                  หมายเลขโทรศัพท์ : {`${municipality.phone_number}`}{" "}
                  {municipality?.phone_extension && (
                    <Fragment>
                      <Text> ต่อ </Text>
                      <Text>{municipality?.phone_extension}</Text>
                    </Fragment>
                  )}
                </Text>
              </View>
              <View style={styles.row}>
                <Text>อีเมล : </Text>
                <Text>{municipality?.email}</Text>
              </View>
            </View>
          </View>
          <Br />
          <View style={styles.row}>
            <Text
              style={styles.font_bold}
            >{`QR Code สำหรับเเจ้งเตือนภายในของระบบบริหารจัดการคำร้อง  `}</Text>
          </View>
          <View
            style={{
              ...styles.row,
              ...{
                display: "flex",
              },
            }}
          >
            <View style={{ width: "25%", margin: 10 }}>
              <Image
                src={`${HOST}${municipality.qr_line_internal}`}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </View>
            <View style={{ margin: 10, width: "65%" }}>
              <View style={styles.row}>
                <Text style={styles.font_bold}>{`ขั้นตอนการใช้งาน`}</Text>
              </View>

              <View
                style={{
                  ...styles.row,
                  ...{
                    width: "100%",
                  },
                }}
              >
                <Text>{`1. เปิดแอปพลิเคชัน “Line” `}</Text>
              </View>
              <View
                style={{
                  ...styles.row,
                  ...{
                    width: "100%",
                  },
                }}
              >
                <Text>{`2. ไปที่เมนู “เพิ่มเพื่อน” จากนั้นเข้าไปที่ “เพิ่มเพื่อนด้วย QR Code” หรือเปิดแอปพลิเคชัน “กล้อง” `}</Text>
              </View>
              <View style={styles.row}>
                <Text>{`3. สแกน QR Code แล้วกดปุ่ม “เพิ่มเพื่อน”`}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  } else {
    return (
      <Document title="รายละเอียดคำร้อง">
        <Page style={styles.page} />
      </Document>
    );
  }
};

const Br = ({ style }: any) => (
  <View style={{ ...styles.row, ...style }}>
    <Text> </Text>
  </View>
);

export default MunicipalitiesDetailExportInternal;
