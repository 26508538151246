import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Breadcrumb, Button, Container, Dropdown, Form, Grid, Icon, Label, Pagination, Table } from 'semantic-ui-react'
import { GET_ROLE_HAS_PERMISSION_MENU_LIST, DELETE_ROLE_HAS_PERMISSION_MENU } from '../../../../config/api/roles'
import { axiosAuthen } from '../../../../config/axiosAuthen'

interface InterfaceListRole {
    id: number
    name_th: string
    name_en: string
    menu: InterfaceListMenu[]
}
interface InterfaceListMenu {
    id: number
    name_th: string
    name_en: string
    page: InterfaceListPage[]
}

interface InterfaceListPage {
    id: number
    name_th: string
    name_en: string
}

const ManagementUsageRole: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [municipality_id, setMunicipality] = useState<number>()
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [loading, setLoading] = useState<boolean>(false)
    let [role_list, setRoleList] = useState<InterfaceListRole[]>([])
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    const getUserManualList = useCallback(() => {
        if (municipality_id)
            axiosAuthen
                .get(
                    `${GET_ROLE_HAS_PERMISSION_MENU_LIST}?municipality_id=${municipality_id}&page=${current_page}&offset=${row_qty}`
                )
                .then((response) => {
                    let { value } = response.data
                    setRecordQty(value.count)
                    setRoleList(value.role_has_permission_menus)
                    setLoading(false)
                })
    }, [current_page, municipality_id, row_qty])

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            setMunicipality(municipality_id)

            if (municipality_id) getUserManualList()
        }
    }, [getUserManualList, location])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const calculateRoleSpanRow = (role: InterfaceListRole) => {
        let row = role.menu
            .map((menu: InterfaceListMenu) => menu.page.length)
            .reduce((prev: number, next: number) => prev + next, 0)
        return row
    }

    const calculateMenuSpanRow = (menu: InterfaceListMenu) => {
        let row = menu.page.length
        return row
    }

    const handleClickEdit = (role: InterfaceListRole) => {
        history.push('usage-role/mapping', { municipality_id: municipality_id, role: role })
    }

    const handleClickDelete = (role_id: number) => {
        axiosAuthen.delete(`${DELETE_ROLE_HAS_PERMISSION_MENU}/${role_id}/${municipality_id}`).then(() => {
            getUserManualList()
        })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    {localStorage.permission_en === 'super_admin' && (
                                        <Fragment>
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities`)
                                                }}
                                            >
                                                รายการองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                        municipality_id: municipality_id,
                                                    })
                                                }}
                                            >
                                                กำหนดการใช้งานองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </Fragment>
                                    )}
                                    <Breadcrumb.Section active>รายการจัดการสิทธิ์และการใช้งาน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>รายการจัดการสิทธิ์และการใช้งาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='4' tablet='5' mobile='8' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={() =>
                                        history.push('usage-role/mapping', { municipality_id: municipality_id })
                                    }
                                >
                                    <Icon name='plus' />
                                    กำหนดการใช้งาน
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='6' tablet='6' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form loading={loading}>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='4'>
                                                    เมนู
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='4'>
                                                    หน้าเว็บไซต์
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='5'>
                                                    การใช้งาน
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='2'>
                                                    จัดการ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    {role_list.length > 0 &&
                                        role_list.map((role: InterfaceListRole, index_role: number) => (
                                            <Table attached celled className='table-body-merge' key={index_role}>
                                                <Table.Body>
                                                    {calculateRoleSpanRow(role) > 0 &&
                                                        role.menu.map((menu: InterfaceListMenu, index_menu: number) =>
                                                            menu.page.map(
                                                                (page: InterfaceListPage, index_page: number) => (
                                                                    <Table.Row key={index_page}>
                                                                        {index_menu === 0 && index_page === 0 && (
                                                                            <Table.Cell
                                                                                rowSpan={calculateRoleSpanRow(role)}
                                                                                textAlign='center'
                                                                                width='1'
                                                                            >
                                                                                <Label
                                                                                    circular
                                                                                    style={{
                                                                                        backgroundColor: '#425679',
                                                                                        color: '#FFFFFF',
                                                                                    }}
                                                                                >
                                                                                    {row_qty * (current_page - 1) +
                                                                                        index_role +
                                                                                        1}
                                                                                </Label>
                                                                            </Table.Cell>
                                                                        )}
                                                                        {index_menu === 0 && index_page === 0 && (
                                                                            <Table.Cell
                                                                                rowSpan={calculateRoleSpanRow(role)}
                                                                                width='4'
                                                                                textAlign='center'
                                                                            >
                                                                                {role.name_th}
                                                                            </Table.Cell>
                                                                        )}

                                                                        {index_page === 0 && (
                                                                            <Table.Cell
                                                                                rowSpan={calculateMenuSpanRow(menu)}
                                                                                width='4'
                                                                                textAlign='center'
                                                                            >
                                                                                {menu.name_th}
                                                                            </Table.Cell>
                                                                        )}
                                                                        <Table.Cell width='5' textAlign='center'>
                                                                            {page.name_th}
                                                                        </Table.Cell>
                                                                        {index_menu === 0 && index_page === 0 && (
                                                                            <Table.Cell
                                                                                rowSpan={calculateRoleSpanRow(role)}
                                                                                width='2'
                                                                                textAlign='center'
                                                                            >
                                                                                <Button
                                                                                    basic
                                                                                    style={{
                                                                                        marginTop: 10,
                                                                                    }}
                                                                                    icon
                                                                                    color='yellow'
                                                                                    onClick={() =>
                                                                                        handleClickEdit(role)
                                                                                    }
                                                                                >
                                                                                    <Icon name='edit' /> แก้ไข
                                                                                </Button>
                                                                                <Button
                                                                                    basic
                                                                                    style={{
                                                                                        marginTop: 10,
                                                                                    }}
                                                                                    icon
                                                                                    color='red'
                                                                                    onClick={() =>
                                                                                        handleClickDelete(role.id)
                                                                                    }
                                                                                >
                                                                                    <Icon name='trash alternate' /> ลบ
                                                                                </Button>
                                                                            </Table.Cell>
                                                                        )}
                                                                    </Table.Row>
                                                                )
                                                            )
                                                        )}
                                                </Table.Body>
                                            </Table>
                                        ))}
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column computer='11' style={{ display: 'flex' }} only='computer'>
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}
export default ManagementUsageRole
