import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
    Breadcrumb,
    Button,
    Card,
    Container,
    Dropdown,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
} from 'semantic-ui-react'
import { DELETE_MUNICIPALITY, GET_MUNICIPALITY_ALL } from '../../../config/api/municipality'
import { axiosAuthen } from '../../../config/axiosAuthen'
import { HOST } from '../../../config/router'
import IconLineChat from '../../../assets/images/icon-line-chat.png'
import IconOneChat from '../../../assets/images/icon-one-chat.png'
import { alert } from '../../../components/alert'

interface InterfaceMunicipality {
    id: number
    logo: string
    name_en: string
    name_th: string
    notification: string
}

const Municipalities: React.FC = () => {
    let history = useHistory()
    let [municipalities, setMunicipality] = useState<InterfaceMunicipality[]>([])
    let [keyword, setKeyword] = useState<string>('')

    const getMunicipality = () => {
        axiosAuthen.get(GET_MUNICIPALITY_ALL).then((response) => {
            let { value } = response.data
            setMunicipality(value || [])
        })
    }

    useEffect(() => {
        getMunicipality()
    }, [])

    const handleClick = (data: InterfaceMunicipality) => {
        localStorage.setItem('municipalities_name', data.name_th)
        history.push('municipalities/menu', { municipality_id: data.id })
    }

    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    const handleClickDelete = (municipality_id: number) => {
        alert({
            type: 'warning',
            title: 'ลบองค์กร',
            text: 'หากลบแล้วจะไม่สามารถนำข้อมูลกลับมาได้',
            confirmText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onConfirm: () => {
                deleteMunicipality(municipality_id)
            },
        })
    }

    const deleteMunicipality = (municipality_id: number) => {
        axiosAuthen.delete(`${DELETE_MUNICIPALITY}/${municipality_id}`).then(() => {
            getMunicipality()
        })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section active>รายการองค์กร</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>รายการองค์กร</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' tablet='5' mobile='8' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={() => history.push('management/municipality/form')}
                                >
                                    <Icon name='plus' /> เพิ่มองค์กร
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='10' tablet='6' only='computer tablet' />
                            <Grid.Column computer='3' tablet='5' mobile='8' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    placeholder='ค้นหาชื่อองค์กร'
                                    icon='search'
                                    onChange={handleChangeKeyword}
                                    value={keyword}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {municipalities.map((data: InterfaceMunicipality) => (
                                <Grid.Column computer='4' key={data.id}>
                                    <div style={{ zIndex: 9999, position: 'absolute', top: 40, right: 40 }}>
                                        <Dropdown icon='ellipsis vertical' pointing='right'>
                                            <Dropdown.Menu>
                                                {/* <Dropdown.Item onClick={() => handleClickEdit(data.id)}>
                                                    <Icon
                                                        name='edit'
                                                        circular
                                                        style={{
                                                            color: '#F2CC6B',
                                                            border: '1px solid #F2CC6B',
                                                            boxShadow: 'none',
                                                        }}
                                                    />
                                                    <label style={{ cursor: 'pointer' }}>แก้ไข</label>
                                                </Dropdown.Item>
                                                <Dropdown.Divider /> */}
                                                <Dropdown.Item onClick={() => handleClickDelete(data.id)}>
                                                    <Icon
                                                        name='trash alternate'
                                                        circular
                                                        style={{
                                                            color: '#9F3A38',
                                                            border: '1px solid #9F3A38',
                                                            boxShadow: 'none',
                                                        }}
                                                    />
                                                    <label style={{ cursor: 'pointer' }}>ลบ</label>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <Card className='card-menu-municipalities' onClick={() => handleClick(data)}>
                                        <Card.Content>
                                            <Grid textAlign='center'>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Image src={`${HOST}${data.logo}`} size='small' />
                                                        <div
                                                            style={{
                                                                zIndex: 9999,
                                                                position: 'absolute',
                                                                top: 12,
                                                                left: 20,
                                                            }}
                                                        >
                                                            {data.notification === 'line' && (
                                                                <Image
                                                                    style={{
                                                                        // marginLeft: 10,
                                                                        width: 25,
                                                                        height: 25,
                                                                    }}
                                                                    src={IconLineChat}
                                                                />
                                                            )}
                                                            {data.notification === 'one_chat' && (
                                                                <Image
                                                                    style={{
                                                                        // marginLeft: 10,
                                                                        width: 25,
                                                                        height: 25,
                                                                    }}
                                                                    src={IconOneChat}
                                                                />
                                                            )}
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <h4>{data.name_th}</h4>
                                                        <label style={{ color: '#8E8F92' }}>{data.name_en}</label>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            ))}
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default Municipalities
