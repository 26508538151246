import React, { Fragment } from 'react'
import { Icon } from 'semantic-ui-react'

const colorStatus = [
    { key: 'finish', color: '#015C93' },
    { key: 'process', color: '#53A6D8' },
    { key: 'wait', color: '#C4C4C4' },
]

const CustomStep = ({ activeStep = 1, children = [] }) => {
    children = children.filter((data) => data !== false)
    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    // padding: 30
                }}>
                {Array.isArray(children)
                    ? children.map((child, index) => (
                          <Fragment key={index}>
                              <CustomStepStep
                                  status={
                                      child.props.status
                                          ? child.props.status
                                          : activeStep > index + 1
                                          ? 'finish'
                                          : activeStep === index + 1
                                          ? 'process'
                                          : 'wait'
                                  }
                                  icon={child.props.icon}
                                  label={child.props.label}
                                  size={child.props.size}
                                  onClick={child.props.onClick}
                              />
                              {index + 1 < children.length && (
                                  <Fragment>
                                      <div
                                          style={
                                              activeStep === index + 2
                                                  ? {
                                                        borderWidth: 2.4,
                                                        borderStyle: 'solid',
                                                        width: '15%',
                                                        borderImageSource: `linear-gradient(90deg, rgb(1, 83, 132), rgb(75, 149, 194))`,
                                                        borderImageSlice: 1,
                                                    }
                                                  : {
                                                        borderWidth: 2.4,
                                                        borderStyle: 'solid',
                                                        width: '15%',
                                                        borderColor:
                                                            child.props.status === 'finish'
                                                                ? '#015C93'
                                                                : activeStep > index + 1
                                                                ? '#015C93'
                                                                : '#B0B0B0',
                                                    }
                                          }
                                      />
                                  </Fragment>
                              )}
                          </Fragment>
                      ))
                    : { children }}
            </div>
        </Fragment>
    )
}

const CustomStepStep = ({ icon = '', label = '', status = '', size = '', onClick = (e, props) => {} }) => {
    return (
        <div style={{ position: 'relative' }}>
            <div
                onClick={() => {
                    onClick()
                }}>
                <Icon
                    circular
                    name={icon}
                    size={size}
                    style={{
                        cursor: 'pointer',
                        margin: 0,
                        backgroundColor: colorStatus.find((color) => color.key === status).color,
                        color: 'white',
                    }}
                />
            </div>
            <span
                style={{
                    position: 'absolute',
                    display: 'inline-block',
                    // width: 'max-content',
                    textAlign: 'center',
                    // maxWidth: 100,
                    width: 100,
                    fontWeight: 'bold',
                    marginLeft: -20,
                    marginTop: 5,
                }}>
                {label}
            </span>
        </div>
    )
}

CustomStep.Step = CustomStepStep

export default CustomStep
