import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Grid, TextArea, Segment, Rating, RatingProps, TextAreaProps } from 'semantic-ui-react'

import CustomModal from '../../../../../components/CustomModal'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import {
    GET_COMPLAINT_EVALUATE_COMPLAINT_DETAIL,
    POST_COMPLAINT_EVALUATE_COMPLAINT,
} from '../../../../../config/api/complaint'

type FormProps = {
    complaint_id?: string | number
    reload?: () => void
}
const EvaluateComplaint: React.FC<FormProps> = (props) => {
    let [open, setOpen] = useState<boolean>(false)
    let [rating, setRating] = useState<string | number | undefined>(1)
    let [detail, setDetail] = useState<string | number | undefined>('')
    let [pre_complaints_id, setPreComplaintId] = useState<number>(0)
    let [pre_complaints_rating, setPreComplaintRating] = useState<string>('')
    let [pre_complaints_suggestion, setPreComplaintSuggestion] = useState<string>('')

    useEffect(() => {
        const getDetailEvaluate = () => {
            axiosAuthen.get(`${GET_COMPLAINT_EVALUATE_COMPLAINT_DETAIL}/${props.complaint_id}`).then((response) => {
                let { value } = response.data
                setPreComplaintId(value.pre_complaints_id)
                setPreComplaintRating(value.pre_complaints_grade)
                setPreComplaintSuggestion(value.pre_complaints_suggestion)
            })
        }
        getDetailEvaluate()
    }, [props.complaint_id])

    const handleOpen = () => {
        setOpen(!open)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleChangeRating = (e: any, { rating }: RatingProps) => {
        setRating(rating)
    }

    const handleChangeDetail = (e: any, { value }: TextAreaProps) => {
        setDetail(value)
    }

    const handleSubmit = () => {
        let body = {
            complaints_id: props.complaint_id,
            grade: rating,
            suggestion: detail,
        }
        axiosAuthen.post(POST_COMPLAINT_EVALUATE_COMPLAINT, body).then((response) => {
            handleClose()
            props.reload && props.reload()
        })
    }

    return (
        <Fragment>
            <CustomModal
                icon='file alternate'
                header='ประเมินผลการทำงาน'
                open={open}
                size={pre_complaints_id ? 'small' : 'tiny'}
                trigger={
                    <ButtonIconOver
                        fluid
                        label='ประเมินผลการทำงาน'
                        color='#93BE52'
                        icon='clipboard'
                        basic
                        onClick={handleOpen}
                    />
                }
            >
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={pre_complaints_id ? '8' : '16'} only='computer'>
                            <Segment>
                                <Form>
                                    <h4>ผลประเมินจากธุรการ</h4>
                                    <Segment style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Rating
                                            icon='star'
                                            maxRating={5}
                                            rating={rating}
                                            size='massive'
                                            onRate={handleChangeRating}
                                        />
                                    </Segment>
                                    <div>
                                        <h4>หมายเหตุ</h4>5 ดาว = ดีมาก, 4 ดาว = ดี, 3 ดาว = ปานกลาง, 2 ดาว = พอใช้, 1
                                        ดาว = ปรับปรุง
                                    </div>
                                    <h4>รายละเอียด</h4>
                                    <TextArea value={detail} onChange={handleChangeDetail} />
                                </Form>
                            </Segment>
                        </Grid.Column>
                        {pre_complaints_id > 0 && (
                            <Grid.Column computer='8' only='computer'>
                                <Segment style={{ backgroundColor: '#E9F3FC' }}>
                                    <Form>
                                        <h4 style={{ color: '#425679' }}>ผลประเมินจากประชาชน</h4>
                                        <Segment style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Rating
                                                icon='star'
                                                maxRating={5}
                                                rating={pre_complaints_rating}
                                                disabled
                                                size='massive'
                                            />
                                        </Segment>
                                        <div>
                                            <h4>หมายเหตุ</h4>5 ดาว = ดีมาก, 4 ดาว = ดี, 3 ดาว = ปานกลาง, 2 ดาว = พอใช้,
                                            1 ดาว = ปรับปรุง
                                        </div>
                                        <h4 style={{ color: '#425679' }}>ข้อเสนอแนะ</h4>
                                        <div style={{ color: '#425679' }}>
                                            {pre_complaints_suggestion ? pre_complaints_suggestion : '-'}
                                        </div>
                                    </Form>
                                </Segment>
                            </Grid.Column>
                        )}
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Button
                                style={{
                                    width: 100,
                                    backgroundColor: '#283655',
                                    color: '#FFF',
                                    marginRight: 15,
                                }}
                                onClick={handleSubmit}
                            >
                                บันทึก
                            </Button>
                            <Button
                                style={{
                                    width: 100,
                                    backgroundColor: '#C54C40',
                                    color: '#FFF',
                                    marginLeft: 15,
                                }}
                                onClick={handleClose}
                            >
                                ยกเลิก
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </CustomModal>
        </Fragment>
    )
}

export default EvaluateComplaint
