import { month_th } from '../constants';

const FullFormatDateTH = (date) => {
    if (date) {
        let part_date = date.split('/');
        let month = month_th.find((month) => month.key === part_date[1]);
        if (month) return `${Number(part_date[0])} ${month.value} ${part_date[2]}`;
    }
};
export default FullFormatDateTH;
