import React, { Fragment, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import HighchartsOrganization from 'highcharts/modules/organization'
import HighchartsSankey from 'highcharts/modules/sankey'
import Highcharts from 'highcharts'
import { Button, Container, Icon, Segment } from 'semantic-ui-react'
import CustomModal from '../../../../components/CustomModal'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import { GET_DIRECTORY_OF_MUNICIPALITY_LEVEL_DIRECTORY_ALL } from '../../../../config/api/directoryOfMunicipality'

type Props = {
    municipality_id?: number
}

interface InterfaceLevelDirectory {
    id: number
    name: string
    level: number
    parent_id: number
}

const ManagementLevelDirectoryOrganizationChart: React.FC<Props> = (props) => {
    HighchartsSankey(Highcharts)
    HighchartsOrganization(Highcharts)

    let [open, setOpen] = useState<boolean>(false)
    let [chart_options, setChartOptions] = useState<any>()

    const handleOpen = () => {
        setOpen(true)
        let { municipality_id } = props
        municipality_id !== 0 &&
            axiosAuthen
                .get(`${GET_DIRECTORY_OF_MUNICIPALITY_LEVEL_DIRECTORY_ALL}?municipality_id=${municipality_id}`)
                .then((response) => {
                    let { value } = response.data
                    if (value && value.length) {
                        let from_to: [string, string][] = []
                        let nodes: any = []
                        let max_level = 1
                        value.forEach((level: InterfaceLevelDirectory) => {
                            let parent_name = 'องค์กร'

                            if (max_level < level.level) {
                                max_level = level.level
                            }
                            if (level.parent_id) {
                                parent_name =
                                    (value &&
                                        value.find(
                                            (find_parent: InterfaceLevelDirectory) => find_parent.id === level.parent_id
                                        )?.name) ||
                                    ''
                            }

                            from_to.push([parent_name, level.name])
                            parent_name = level.name

                            nodes.push({
                                id: `${level.name}`,
                                name: `${level.name}`,
                                title: null,
                                tier_id: level.id,
                            })
                        })

                        let chart_oprtion = {
                            chart: {
                                height: (max_level + 1) * 100,
                                inverted: true,
                            },

                            title: {
                                text: 'แผนผังองค์กร',
                            },
                            attributes: {
                                id: 'arrow',
                                markerHeight: 10,
                                markerWidth: 10,
                                refX: 9,
                                refY: 5,
                                tagName: 'marker',
                            },

                            series: [
                                {
                                    type: 'organization',
                                    name: 'แผนผังองค์กร',
                                    keys: ['from', 'to'],
                                    data: from_to,
                                    nodes: nodes,
                                    colorByPoint: false,
                                    color: 'silver',
                                    dataLabels: {
                                        color: 'black',
                                    },
                                    borderColor: 'white',
                                },
                            ],
                            tooltip: {
                                outside: true,
                            },
                            credits: {
                                enabled: false,
                            },
                        }
                        setChartOptions(chart_oprtion)
                    }
                })
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Fragment>
            <CustomModal
                centered={false}
                icon='users'
                header={'แผนผังองค์กร'}
                open={open}
                closeIcon
                onClose={handleClose}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                trigger={
                    <Button fluid icon primary labelPosition='left' onClick={handleOpen}>
                        <Icon name='sitemap' /> ดูแผนผังองค์กร
                    </Button>
                }
            >
                <Container>
                    {chart_options && (
                        <Segment style={{ overflowX: 'auto', height: 550 }}>
                            <HighchartsReact highcharts={Highcharts} options={chart_options} />
                        </Segment>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={handleClose}>ปิด</Button>
                    </div>
                </Container>
            </CustomModal>
        </Fragment>
    )
}

export default ManagementLevelDirectoryOrganizationChart
