import React, { Fragment, useState } from 'react'
import { Button, Form, Grid, TextArea, TextAreaProps } from 'semantic-ui-react'
import CustomModal from '../../../../../components/CustomModal'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { PUT_COMPLAINT_REJECT_PRE_COMPLAINT } from '../../../../../config/api/complaint'
import { useHistory } from 'react-router-dom'

type FormProps = {
    pre_complaint_id?: string | number
    reload?: () => void
}

const RejectPreComplaint: React.FC<FormProps> = (props) => {
    let [open, setOpen] = useState<boolean>(false)
    let [detail, setDetail] = useState<string | number | undefined>('')
    let history = useHistory()

    const handleOpen = () => {
        setOpen(!open)
    }
    const handleClose = () => {
        setOpen(false)
        setDetail('')
    }

    const handleChangeDetail = (e: any, { value }: TextAreaProps) => {
        setDetail(value)
    }

    const handleSubmit = () => {
        let body = {
            pre_complaints_id: props.pre_complaint_id,
            reject_detail: detail,
        }
        axiosAuthen.put(PUT_COMPLAINT_REJECT_PRE_COMPLAINT, body).then((response) => {
            handleClose()
            history.goBack()
        })
    }
    return (
        <Fragment>
            <CustomModal
                icon='file alternate'
                header='ปฏิเสธการร้องเรียน'
                open={open}
                trigger={
                    <ButtonIconOver
                        fluid
                        label='ปฏิเสธคำร้องทุกข์'
                        color='#D85F53'
                        icon='delete'
                        basic
                        onClick={handleOpen}
                    />
                }
            >
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='2' only='computer' />
                            <Grid.Column computer='12' only='computer'>
                                <Form>
                                    <div style={{ color: '#425679', fontSize: '14px' }}>
                                        หมายเหตุการปฏิเสธคำร้องทุกข์
                                    </div>
                                    <TextArea value={detail} onChange={handleChangeDetail} />
                                </Form>
                            </Grid.Column>
                            <Grid.Column computer='2' only='computer' />
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default RejectPreComplaint
