import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    Breadcrumb,
    Button,
    Container,
    Dropdown,
    Form,
    Grid,
    Icon,
    Input,
    InputOnChangeData,
    Label,
    Pagination,
    Table,
} from 'semantic-ui-react'
import { alert } from '../../../../components/alert'
import CustomModal from '../../../../components/CustomModal'
import {
    DELETE_DIRECTORY_OF_MUNICIPALITY_DELETE_LEVEL_DIRECTORY,
    GET_DIRECTORY_OF_MUNICIPALITY_CHILDREN_LEVEL_DIRECTORY,
    POST_DIRECTORY_OF_MUNICIPALITY_CREATE_LEVEL_DIRECTORY,
    PUT_DIRECTORY_OF_MUNICIPALITY_EDIT_LEVEL_DIRECTORY,
} from '../../../../config/api/directoryOfMunicipality'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import ManagementLevelDirectoryOrganizationChart from './OrganizationChart'

const ManagementLevelDirectoryChildren: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [loading, setLoading] = useState<boolean>(false)

    let [municipality_id, setMunicipalityID] = useState<number>(0)
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [keyword, setKeyword] = useState<string>('')
    let [level_directory, setLevelDirectory] = useState<[]>([])
    let [parent_level_directory, setParentLevelDirectory] = useState<number>(0)
    let [open_modal_create, setOpenModalCreate] = useState<boolean>(false)
    let [open_modal_edit, setOpenModalEdit] = useState<boolean>(false)
    let [level_directory_id, setLevelDirectoryId] = useState<number>(0)
    let [level_directory_name, setLevelDirectoryName] = useState<string>('')
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id, level_directory_id } = location_state
            setMunicipalityID(municipality_id)
            setParentLevelDirectory(level_directory_id)
        }
    }, [location])

    const getLevelDirectory = useCallback(() => {
        municipality_id !== 0 &&
            parent_level_directory !== 0 &&
            axiosAuthen
                .get(
                    `${GET_DIRECTORY_OF_MUNICIPALITY_CHILDREN_LEVEL_DIRECTORY}?level_directory_id=${parent_level_directory}&municipality_id=${municipality_id}`
                )
                .then((response) => {
                    let { value } = response.data
                    setRecordQty(value.length)
                    setLevelDirectory(value)
                    setLoading(false)
                })
    }, [municipality_id, parent_level_directory])

    useEffect(() => {
        getLevelDirectory()
    }, [getLevelDirectory])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }
    const handleChangeName = (_: any, { value }: InputOnChangeData) => {
        setLevelDirectoryName(value)
    }
    const handleAddLevelDirectory = () => {
        setOpenModalCreate(!open_modal_create)
    }

    const handleCloseModalForm = () => {
        setOpenModalCreate(!open_modal_create)
        setLevelDirectoryId(0)
        setLevelDirectoryName('')
    }

    const handleSubmitForm = () => {
        //TODO: call api create
        let body = {
            parent_id: parent_level_directory,
            name: level_directory_name,
            municipality_id: municipality_id,
        }

        axiosAuthen.post(POST_DIRECTORY_OF_MUNICIPALITY_CREATE_LEVEL_DIRECTORY, body).then((response) => {
            getLevelDirectory()
            handleCloseModalForm()
        })
    }

    const handleSubmitFormEdit = () => {
        //TODO: call api edit
        let body = {
            id: level_directory_id,
            name: level_directory_name,
        }

        axiosAuthen.put(PUT_DIRECTORY_OF_MUNICIPALITY_EDIT_LEVEL_DIRECTORY, body).then(() => {
            getLevelDirectory()
            handleCloseModalEdit()
        })
    }

    const handleEditLevelDirectory = (data: any) => {
        setLevelDirectoryId(data?.id)
        setLevelDirectoryName(data?.name)
        setOpenModalEdit(!open_modal_create)
    }

    const handleCloseModalEdit = () => {
        setOpenModalEdit(!open_modal_edit)
        setLevelDirectoryId(0)
        setLevelDirectoryName('')
    }

    const handleDeleteLevelDirectory = (data: any) => {
        alert({
            type: 'confirm',
            title: 'ลบระดับหน่วยงาน',
            text: 'ยืนยันการลบระดับหน่วยงานหรือไม่',
            confirmText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onConfirm: () => {
                axiosAuthen.delete(`${DELETE_DIRECTORY_OF_MUNICIPALITY_DELETE_LEVEL_DIRECTORY}/${data.id}`).then(() => {
                    getLevelDirectory()
                })
            },
        })
    }
    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities`, {
                                                municipality_id: municipality_id,
                                            })
                                        }}
                                    >
                                        รายการองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                municipality_id: municipality_id,
                                            })
                                        }}
                                    >
                                        กำหนดการใช้งานองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>จัดการระดับหน่วยงาน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='2'>
                                <Button icon labelPosition='left' primary onClick={() => history.goBack()}>
                                    <Icon name='chevron left' /> กลับ
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='12' textAlign='center'>
                                <h2>จัดการระดับหน่วยงาน</h2>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={handleAddLevelDirectory}
                                >
                                    <Icon name='plus' /> เพิ่มระดับหน่วยงาน
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <ManagementLevelDirectoryOrganizationChart municipality_id={municipality_id} />
                            </Grid.Column>

                            <Grid.Column computer='7' only='computer' />
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    placeholder='ค้นหา'
                                    icon='search'
                                    name='keyword'
                                    value={keyword}
                                    onChange={handleChangeKeyword}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form loading={loading}>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='4' textAlign='center'>
                                                    ชื่อระดับหน่วยงาน
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='3' textAlign='center'>
                                                    จัดการ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    <Table attached celled className='table-body'>
                                        <Table.Body className='table-body body'>
                                            {level_directory.length === 0 ? (
                                                <Table.Row>
                                                    <Table.Cell textAlign='center' width='16'>
                                                        {'ไม่พบข้อมูล'}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ) : (
                                                level_directory.map((data: any, index: number) => (
                                                    <Table.Row
                                                        key={data.id}
                                                        style={{ color: '#1E1F26', cursor: 'pointer' }}
                                                    >
                                                        <Table.Cell
                                                            textAlign='center'
                                                            width='1'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                history.push(
                                                                    `/${localStorage.permission_en}/management/level-directory/list/children`,
                                                                    {
                                                                        municipality_id: municipality_id,
                                                                        level_directory_id: data.id,
                                                                        level: data.level,
                                                                        parent_id: data.parent_id,
                                                                        level_directory_name: data.name,
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <Label
                                                                circular
                                                                style={{
                                                                    backgroundColor: '#425679',
                                                                    color: '#FFFFFF',
                                                                }}
                                                            >
                                                                {row_qty * (current_page - 1) + index + 1}
                                                            </Label>
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            width='4'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                history.push(
                                                                    `/${localStorage.permission_en}/management/level-directory/list/children`,
                                                                    {
                                                                        municipality_id: municipality_id,
                                                                        level_directory_id: data.id,
                                                                        level: data.level,
                                                                        parent_id: data.parent_id,
                                                                        level_directory_name: data.name,
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            {data.name}
                                                        </Table.Cell>
                                                        <Table.Cell width='3' textAlign='center'>
                                                            <Button
                                                                basic
                                                                color='yellow'
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => handleEditLevelDirectory(data)}
                                                            >
                                                                <Icon name='edit' /> จัดการ
                                                            </Button>
                                                            <br />
                                                            <br />
                                                            <Button
                                                                basic
                                                                color='red'
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => handleDeleteLevelDirectory(data)}
                                                            >
                                                                <Icon name='trash alternate' /> ลบ
                                                            </Button>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))
                                            )}
                                        </Table.Body>
                                    </Table>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column computer='11' style={{ display: 'flex' }} only='computer'>
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
            <CustomModal
                icon='users'
                header='เพิ่มระดับหน่วยงาน'
                open={open_modal_create}
                closeIcon
                onClose={handleCloseModalForm}
                closeOnEscape={true}
                closeOnDimmerClick={true}
            >
                <Form loading={loading}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='3' tablet='16' mobile='16' only='computer' />
                            <Grid.Column computer='10' tablet='16' mobile='16' only='computer'>
                                <label className='header-input-form'>ชื่อระดับหน่วยงาน</label>
                                <Input
                                    fluid
                                    name='level_directory_name'
                                    value={level_directory_name}
                                    onChange={handleChangeName}
                                />
                            </Grid.Column>
                            <Grid.Column computer='3' tablet='16' mobile='16' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    type='button'
                                    onClick={handleSubmitForm}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    type='button'
                                    onClick={handleCloseModalForm}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
            <CustomModal
                icon='users'
                header='แก้ไขระดับหน่วยงาน'
                open={open_modal_edit}
                closeIcon
                onClose={handleCloseModalEdit}
                closeOnEscape={true}
                closeOnDimmerClick={true}
            >
                <Form loading={loading}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='3' tablet='16' mobile='16' only='computer' />
                            <Grid.Column computer='10' tablet='16' mobile='16' only='computer'>
                                <label className='header-input-form'>ชื่อระดับหน่วยงาน</label>
                                <Input
                                    fluid
                                    name='level_directory_name'
                                    value={level_directory_name}
                                    onChange={handleChangeName}
                                />
                            </Grid.Column>
                            <Grid.Column computer='3' tablet='16' mobile='16' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    type='button'
                                    onClick={handleSubmitFormEdit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    type='button'
                                    onClick={handleCloseModalEdit}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default ManagementLevelDirectoryChildren
