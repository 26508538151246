import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Breadcrumb, Button, Container, Dimmer, Grid, Image, Label, Loader, Segment } from 'semantic-ui-react'
import CustomSegment from '../../../../components/CustomSegment'
import GoogleMap from '../../../../components/GoogleMaps'
import { GET_MUNICIPALITY_DETAIL, GET_MUNICIPALITY_PERSON_TYPES } from '../../../../config/api/municipality'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import { HOST } from '../../../../config/router'
import MunicipalitiesDetailExportInternal from './ExportInternal'
import MunicipalitiesDetailExportPerson from './ExportPerson'

export interface InterfaceMunicipality {
    assign_complaint: boolean
    ciphertext: string
    detail: string
    email: string
    extend_email: string
    extend_phone_extension: string
    extend_phone_number: string
    id: number
    line_internal: boolean
    line_people: boolean
    lat: string
    lng: string
    logo: string
    name_en: string
    name_th: string
    one_internal: boolean
    one_people: boolean
    person_types_id: number
    phone_extension: string
    phone_number: string
    qr_line_internal: string
    qr_line_person: string
    qr_municipality: string
    short_name: string
    theme_color: string
}

const MunicipalitiesDetail: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let params = useParams()
    let [municipality_id, setMunicipalityId] = useState<string | number>('')
    let [municipality, setMunicipality] = useState<InterfaceMunicipality>()
    let [loading, setLoading] = useState<boolean>(true)
    let [person_type, setPersonType] = useState<string>('-')

    useEffect(() => {
        let { state } = location

        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            if (municipality_id) setMunicipalityId(municipality_id)
        }
    }, [location])

    useEffect(() => {
        const getDetailMunicipality = () => {
            axiosAuthen.get(`${GET_MUNICIPALITY_DETAIL}?municipality_id=${municipality_id}`).then((response) => {
                let { value } = response.data
                setLoading(false)
                setMunicipality(value)
            })
        }

        if (municipality_id || localStorage.permission_en === 'admin') getDetailMunicipality()
    }, [municipality_id])

    useEffect(() => {
        const getPersonTypes = () => {
            axios.get(GET_MUNICIPALITY_PERSON_TYPES).then((response) => {
                let { value } = response.data
                let person_type = value.find((type: any) => type.id === municipality?.person_types_id)
                person_type && setPersonType(person_type.name)
            })
        }
        municipality?.person_types_id && getPersonTypes()
    }, [municipality?.person_types_id])

    const handleClickEdit = () => {
        let { state } = location
        let location_state = JSON.parse(JSON.stringify(state, null, ' '))
        let param = JSON.parse(JSON.stringify(params, null, ' '))
        let { permission } = param
        let { municipality_id } = location_state
        history.push(`/${permission}/management/municipality/form-edit`, { municipality_id: municipality_id })
        setMunicipality(municipality_id)
    }
    // const handleClickCopyText = (text: string) => {
    //     navigator.clipboard.writeText(text)
    // }

    return (
        <Fragment>
            <div className='background-container'>
                <Dimmer active={loading} inverted page>
                    <Loader size='large'>รอสักครู่</Loader>
                </Dimmer>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    {localStorage.permission_en === 'super_admin' && (
                                        <Fragment>
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities`)
                                                }}
                                            >
                                                รายการองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                        municipality_id: municipality_id,
                                                    })
                                                }}
                                            >
                                                กำหนดการใช้งานองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </Fragment>
                                    )}
                                    <Breadcrumb.Section active>ข้อมูลองค์กร</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>ข้อมูลองค์กร</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16' mobile='16'>
                                <CustomSegment icon='building' header='รายละเอียดข้อมูลองค์กร'>
                                    <Grid divided>
                                        <Grid.Row>
                                            <Grid.Column computer='11' tablet='16' mobile='16'>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column computer='1' tablet='16' mobile='16' />
                                                        <Grid.Column
                                                            computer='5'
                                                            tablet='16'
                                                            mobile='16'
                                                            textAlign='center'
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    margin: 15,
                                                                }}
                                                            >
                                                                <Image
                                                                    bordered
                                                                    style={{
                                                                        width: 150,
                                                                        height: 150,
                                                                        borderRadius: 500,
                                                                    }}
                                                                    src={`${HOST}${municipality?.logo}`}
                                                                />
                                                            </div>
                                                            {localStorage.getItem('permission_en') ===
                                                                'super_admin' && (
                                                                <Button
                                                                    color='orange'
                                                                    style={{ margin: 15 }}
                                                                    onClick={handleClickEdit}
                                                                >
                                                                    แก้ไขข้อมูลองค์กร
                                                                </Button>
                                                            )}
                                                        </Grid.Column>
                                                        <Grid.Column computer='10' tablet='16' mobile='16'>
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <div style={{ marginTop: 20 }}>
                                                                            <label
                                                                                style={{
                                                                                    color: '#293757',
                                                                                    fontSize: 30,
                                                                                    fontWeight: 'bold',
                                                                                }}
                                                                            >
                                                                                {municipality?.name_th}
                                                                            </label>
                                                                        </div>
                                                                        <label style={{ color: ' #999999' }}>
                                                                            {municipality?.name_en} (
                                                                            {municipality?.short_name})
                                                                        </label>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer={6}>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            การรับข้อความแจ้งเตือน
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer={10}>
                                                                        {municipality?.line_internal && 'LINE BOT'}
                                                                        {municipality?.one_internal && 'ONE'}
                                                                        {!(
                                                                            municipality?.one_internal ||
                                                                            municipality?.line_internal
                                                                        ) && 'ไม่รับการแจ้งเตือน'}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer={6}>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            การกรอกข้อมูลประชาชน
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer={10}>
                                                                        <label>{person_type}</label>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer={6}>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            การมอบหมายคำร้อง
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer={10}>
                                                                        <label>
                                                                            {municipality?.assign_complaint
                                                                                ? 'ส่งคำร้องให้เจ้าหน้าที่โดยตรง'
                                                                                : 'ส่งคำร้องผ่านหน่วยงาน'}
                                                                        </label>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer={6}>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            ข้อมูลองค์กร
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer={10}>
                                                                        {municipality?.detail}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column computer={6}>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            ติดต่อ
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer={10}>
                                                                        <div>
                                                                            <label>หมายเลขโทรศัพท์ : </label>
                                                                            <label>{municipality?.phone_number}</label>
                                                                            {municipality?.phone_extension && (
                                                                                <Fragment>
                                                                                    <label> ต่อ </label>
                                                                                    <label>
                                                                                        {municipality?.phone_extension}
                                                                                    </label>
                                                                                </Fragment>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            <label>อีเมล : </label>
                                                                            <label>{municipality?.email}</label>
                                                                        </div>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                {(municipality?.extend_phone_number ||
                                                                    municipality?.extend_email) && (
                                                                    <Grid.Row>
                                                                        <Grid.Column computer={6}>
                                                                            <label>ติดต่อ (เพิ่มเติม)</label>
                                                                        </Grid.Column>
                                                                        <Grid.Column computer={10}>
                                                                            {municipality?.extend_phone_number && (
                                                                                <div>
                                                                                    <label>หมายเลขโทรศัพท์ : </label>
                                                                                    <label>
                                                                                        {
                                                                                            municipality?.extend_phone_number
                                                                                        }
                                                                                    </label>
                                                                                    {municipality?.phone_extension && (
                                                                                        <Fragment>
                                                                                            <label> ต่อ </label>
                                                                                            <label>
                                                                                                {
                                                                                                    municipality?.extend_phone_extension
                                                                                                }
                                                                                            </label>
                                                                                        </Fragment>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                            {municipality?.extend_email && (
                                                                                <div>
                                                                                    <label>อีเมล : </label>
                                                                                    <label>
                                                                                        {municipality?.extend_email}
                                                                                    </label>
                                                                                </div>
                                                                            )}
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                )}
                                                                <Grid.Row>
                                                                    <Grid.Column computer={16}>
                                                                        <label style={{ fontWeight: 'bold' }}>
                                                                            ที่ตั้ง
                                                                        </label>
                                                                        {municipality?.lat && municipality.lng && (
                                                                            <Segment
                                                                                style={{ widht: 450, height: 450 }}
                                                                            >
                                                                                <GoogleMap
                                                                                    style={{ widht: 420, height: 420 }}
                                                                                    value={{
                                                                                        lat: Number(municipality?.lat),
                                                                                        lng: Number(municipality.lng),
                                                                                    }}
                                                                                />
                                                                            </Segment>
                                                                        )}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column computer='5' tablet='16' mobile='16'>
                                                <Grid>
                                                    {municipality?.qr_line_internal && (
                                                        <Grid.Row>
                                                            <Grid.Column computer='2' />
                                                            <Grid.Column computer='12'>
                                                                <Segment>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            margin: '10px 0px',
                                                                        }}
                                                                    >
                                                                        <Label size='large' basic>
                                                                            QR Code สำหรับเจ้าหน้าที่
                                                                        </Label>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            margin: 10,
                                                                        }}
                                                                    >
                                                                        <Image
                                                                            style={{
                                                                                width: 150,
                                                                                height: 150,
                                                                            }}
                                                                            src={`${HOST}${municipality?.qr_line_internal}`}
                                                                        />
                                                                    </div>
                                                                    <MunicipalitiesDetailExportInternal
                                                                        municipality={municipality}
                                                                    />
                                                                </Segment>
                                                            </Grid.Column>
                                                            <Grid.Column computer='2' />
                                                        </Grid.Row>
                                                    )}
                                                    {municipality?.qr_line_person && (
                                                        <Grid.Row>
                                                            <Grid.Column computer='2' />
                                                            <Grid.Column computer='12'>
                                                                <Segment>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            margin: 10,
                                                                        }}
                                                                    >
                                                                        <Label size='large' basic>
                                                                            QR Code สำหรับประชาชน
                                                                        </Label>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            margin: '10px 0px',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                        }}
                                                                    >
                                                                        <Image
                                                                            style={{
                                                                                width: 150,
                                                                                height: 150,
                                                                            }}
                                                                            src={`${HOST}${municipality?.qr_line_person}`}
                                                                        />
                                                                    </div>
                                                                    <MunicipalitiesDetailExportPerson
                                                                        municipality={municipality}
                                                                    />
                                                                </Segment>
                                                            </Grid.Column>
                                                            <Grid.Column computer='2' />
                                                        </Grid.Row>
                                                    )}
                                                    {/* {municipality?.qr_municipality && (
                                                        <Grid.Row>
                                                            <Grid.Column computer='2' />
                                                            <Grid.Column computer='12'>
                                                                <Segment>
                                                                    <div
                                                                        style={{
                                                                            margin: '10px 0px',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                        }}
                                                                    >
                                                                        <Label size='large' basic>
                                                                            QR Code สำหรับแอปพลิเคชัน
                                                                        </Label>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                        }}
                                                                    >
                                                                        <Image
                                                                            style={{
                                                                                width: 150,
                                                                                height: 150,
                                                                            }}
                                                                            src={`${HOST}${municipality?.qr_municipality}`}
                                                                        />
                                                                    </div>
                                                                </Segment>
                                                            </Grid.Column>
                                                            <Grid.Column computer='2' />
                                                        </Grid.Row>
                                                    )} */}

                                                    {/* {municipality?.ciphertext && (
                                                        <Grid.Row>
                                                            <Grid.Column computer='2' />
                                                            <Grid.Column computer='12'>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                    }}
                                                                >
                                                                    <Label
                                                                        size='large'
                                                                        basic
                                                                        style={{ padding: '10px' }}
                                                                    >
                                                                        {' '}
                                                                        SECRET CODE{' '}
                                                                    </Label>{' '}
                                                                    <Button
                                                                        style={{
                                                                            backgroundColor: '#283655',
                                                                            color: '#FFF',
                                                                        }}
                                                                        onClick={() =>
                                                                            municipality?.ciphertext &&
                                                                            handleClickCopyText(municipality.ciphertext)
                                                                        }
                                                                        icon
                                                                    >
                                                                        <Icon name='copy' /> Copy
                                                                    </Button>
                                                                </div>
                                                            </Grid.Column>
                                                            <Grid.Column computer='2' />
                                                        </Grid.Row>
                                                    )} */}
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default MunicipalitiesDetail
