import React, { Fragment, useEffect, useState } from 'react'
import {
    Button,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Segment,
    TextArea,
    TextAreaProps,
} from 'semantic-ui-react'
import CustomStep from '../../../../../components/CustomStep'

type PropsManagementMunicipalityLinePerson = {
    municipality_id?: number
}
const ManagementMunicipalityLinePerson: React.FC<PropsManagementMunicipalityLinePerson> = (props) => {
    let [active_step, setActiveStep] = useState<number>(1)
    let [show_form, setShowForm] = useState<boolean>(false)
    let [show_detail, setShowDetail] = useState<boolean>(false)
    let [channel_secret, setChannelSecret] = useState<string>('')
    let [channel_access_token, setChannelAccessToken] = useState<string | number | undefined>('')
    let [rich_menu, setRichMenu] = useState<string[]>([])
    let [rich_menu_images, setRichMenuImages] = useState<any[]>([])
    let [body_json_register, setBodyJSONRegister] = useState<string | number | undefined>('')
    let [body_json_main, setBodyJSONMain] = useState<string | number | undefined>('')
    let [image_register, setImageRegister] = useState<any>()
    let [image_main, setImageMain] = useState<any>()
    let { municipality_id } = props
    useEffect(() => {
        const getDetailLine = () => {
            setShowDetail(false)
            setRichMenu([])
        }
        getDetailLine()
    }, [])

    useEffect(() => {
        let images: any[] = []
        rich_menu.forEach((id: string) => {
            let image = getFileImage(id)
            images = [...images, image]
        })
        setRichMenuImages(images)
    }, [rich_menu])

    const getFileImage = (id: string) => {
        // TODO: call api get richmenu by id
        console.log(id)
    }

    const handleShowForm = () => {
        setShowForm(true)
    }

    const handleChangeChannelSecret = (_: any, { value }: InputOnChangeData) => {
        setChannelSecret(value)
    }
    const handleChangeChannelAccessToken = (_: any, { value }: TextAreaProps) => {
        setChannelAccessToken(value)
    }
    const handleChangeBodyJSONRegister = (_: any, { value }: TextAreaProps) => {
        setBodyJSONRegister(value)
    }
    const handleChangeBodyJSONMain = (_: any, { value }: TextAreaProps) => {
        setBodyJSONMain(value)
    }
    const handleClickSelectImageRegister = () => {
        document.getElementById('image_register')!.click()
    }

    const handleChangeImageRegister = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { files } = event.currentTarget
        let file = files && files[0]
        if (file) {
            setImageRegister(file)
        }
    }

    const handleClickSelectImageMain = () => {
        document.getElementById('image_main')!.click()
    }

    const handleChangeImageMain = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { files } = event.currentTarget
        let file = files && files[0]
        if (file) {
            setImageMain(file)
        }
    }

    const handleGoNext = () => {
        setActiveStep(active_step + 1)
    }

    const handleGoBack = () => {
        setActiveStep(active_step - 1)
    }

    const handleSubmit = () => {
        let form_data = new FormData()
        form_data.append('channel_secret', channel_secret)
        form_data.append('channel_access_token', String(channel_access_token))
        form_data.append('body_json_register', String(body_json_register))
        form_data.append('image_register', image_register)
        form_data.append('body_json_main', String(body_json_main))
        form_data.append('image_main', image_main)
        form_data.append('municipality_id', String(municipality_id))
    }
    return (
        <Segment>
            <h2>แจ้งเตือนประชาชน</h2>

            {!show_form && !show_detail && (
                <div style={{ textAlign: 'center' }}>
                    <h5>ไม่มีข้อมูล</h5>
                    <Button primary icon labelPosition='left' onClick={handleShowForm}>
                        <Icon name='plus' />
                        เพิ่มข้อมูล
                    </Button>
                </div>
            )}

            {show_form && (
                <Fragment>
                    {active_step === 1 && (
                        <Segment>
                            <Form>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <CustomStep activeStep={active_step}>
                                                <CustomStep.Step
                                                    icon='key'
                                                    label='Channel key'
                                                    size='big'
                                                    onClick={() => {
                                                        setActiveStep(1)
                                                    }}
                                                />
                                                <CustomStep.Step
                                                    icon='image outline'
                                                    label='Rich Menu'
                                                    size='big'
                                                    onClick={() => {
                                                        setActiveStep(2)
                                                    }}
                                                />
                                            </CustomStep>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Field>
                                                <h5>Channel secret</h5>
                                                <Input
                                                    fluid
                                                    value={channel_secret}
                                                    onChange={handleChangeChannelSecret}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Field>
                                                <h5>Channel access token</h5>
                                                <TextArea
                                                    value={channel_access_token}
                                                    onChange={handleChangeChannelAccessToken}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button icon primary floated='right' onClick={handleGoNext}>
                                                <Icon name='chevron right' />
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </Segment>
                    )}

                    {active_step === 2 && (
                        <Segment>
                            <Form>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <CustomStep activeStep={active_step}>
                                                <CustomStep.Step
                                                    icon='key'
                                                    label='Channel key'
                                                    size='big'
                                                    onClick={() => {
                                                        setActiveStep(1)
                                                    }}
                                                />
                                                <CustomStep.Step
                                                    icon='image outline'
                                                    label='Rich Menu'
                                                    size='big'
                                                    onClick={() => {
                                                        setActiveStep(2)
                                                    }}
                                                />
                                            </CustomStep>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Field>
                                                <h3>ลงทะเบียน (default)</h3>
                                                <h5>Body Json</h5>
                                                <TextArea
                                                    rows={35}
                                                    value={body_json_register}
                                                    onChange={handleChangeBodyJSONRegister}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Field>
                                                <h5>Rich Menu Image</h5>
                                                {image_register && (
                                                    <Image
                                                        src={URL.createObjectURL(image_register)}
                                                        style={{ paddingBottom: 20 }}
                                                    />
                                                )}
                                                <label className='header-input-form'>เฉพาะไฟล์รูปภาพ</label>
                                                <Button
                                                    style={{
                                                        backgroundColor: '#425679',
                                                        color: '#ffffff',
                                                        marginRight: 15,
                                                    }}
                                                    onClick={handleClickSelectImageRegister}
                                                >
                                                    เลือกไฟล์
                                                </Button>
                                                {image_register?.name}
                                                <Input>
                                                    <input
                                                        type='file'
                                                        id='image_register'
                                                        hidden
                                                        onChange={handleChangeImageRegister}
                                                        name='image_register'
                                                        // label='เพิ่มรูปถ่าย'
                                                        accept='image/*'
                                                    />
                                                </Input>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <hr />
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Field>
                                                <h3>เมนูแจ้งคำร้อง</h3>
                                                <h5>Body Json</h5>
                                                <TextArea
                                                    rows={71}
                                                    value={body_json_main}
                                                    onChange={handleChangeBodyJSONMain}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Field>
                                                <h5>Rich Menu Image</h5>
                                                {image_main && (
                                                    <Image
                                                        src={URL.createObjectURL(image_main)}
                                                        style={{ paddingBottom: 20 }}
                                                    />
                                                )}
                                                <label className='header-input-form'>เฉพาะไฟล์รูปภาพ</label>
                                                <Button
                                                    style={{
                                                        backgroundColor: '#425679',
                                                        color: '#ffffff',
                                                        marginRight: 15,
                                                    }}
                                                    onClick={handleClickSelectImageMain}
                                                >
                                                    เลือกไฟล์
                                                </Button>
                                                {image_main?.name}
                                                <Input>
                                                    <input
                                                        type='file'
                                                        id='image_main'
                                                        hidden
                                                        onChange={handleChangeImageMain}
                                                        name='image_main'
                                                        // label='เพิ่มรูปถ่าย'
                                                        accept='image/*'
                                                    />
                                                </Input>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button type='button' icon primary onClick={handleGoBack}>
                                                <Icon name='chevron left' />
                                            </Button>
                                            <Button
                                                type='button'
                                                icon
                                                color='green'
                                                floated='right'
                                                onClick={handleSubmit}
                                            >
                                                <Icon name='save' />
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </Segment>
                    )}
                </Fragment>
            )}
            {show_detail && (
                <Segment>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='4'>
                                <h5>Channel secret</h5>
                            </Grid.Column>
                            <Grid.Column computer='12'>{channel_secret}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='4'>
                                <h5>Channel access token</h5>
                            </Grid.Column>
                            <Grid.Column computer='12'>
                                <div style={{ wordWrap: 'break-word' }}>{channel_access_token}</div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='4'>
                                <h5>Rich Menu</h5>
                            </Grid.Column>
                            <Grid.Column computer='12'>
                                {rich_menu_images.map((image) => (
                                    <Image src={image} />
                                ))}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            )}
        </Segment>
    )
}

export default ManagementMunicipalityLinePerson
