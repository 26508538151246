import React, { Fragment, useState } from 'react'
import {
    Button,
    Container,
    Dimmer,
    Dropdown,
    DropdownProps,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Loader,
    Segment,
    TextArea,
    TextAreaProps,
} from 'semantic-ui-react'
import CustomSegment from '../../../../../components/CustomSegment'
import IconPDF from '../../../../../assets/images/pdf.png'
import DatePicker from '../../../../../components/DatePicker'
import { useEffect } from 'react'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import {
    GET_COMPLAINT_STATUS_RESULT,
    POST_COMPLAINT_SAVE_RESULT,
    PUT_COMPLAINT_CHANGE_DIRECTORY,
} from '../../../../../config/api/complaint'
import { useHistory, useLocation } from 'react-router-dom'
import { alert } from '../../../../../components/alert'

const ComplaintListDetailSaveResult: React.FC = () => {
    let location = useLocation()
    let history = useHistory()
    let [action, setAction] = useState<string | number | boolean | (string | number | boolean)[] | undefined>(undefined)
    let [action_option, setActionOption] = useState<[]>([])
    let [start_date, setStartDate] = useState<string>('')
    let [end_date, setEndDate] = useState<string>('')
    let [duration, setDuration] = useState<string | number | boolean | (string | number | boolean)[] | undefined>(
        undefined
    )
    let [fiscal_year, setFiscalYear] = useState<string>('')
    let [detail, setDetail] = useState<string | number | undefined>('')
    let [detail_erorr, setDetailError] = useState<boolean>(false)
    let [photo_file, setPhotoFile] = useState<any[]>([])
    let [pdf_file, setPDFFile] = useState<any[]>([])
    let [loading, setLoading] = useState<boolean>(false)
    let [file_error, setFileError] = useState<boolean>(false)
    let [action_error, setActionError] = useState<boolean>(false)
    let duration_option = [
        { value: 7, text: '1-7 วัน' },
        { value: 15, text: '8-15 วัน' },
        { value: 30, text: '16-30 วัน' },
    ]

    useEffect(() => {
        const getAction = () => {
            axiosAuthen.get(GET_COMPLAINT_STATUS_RESULT).then((response) => {
                let { value } = response.data
                let action_option = value.map((data: any) => ({ id: data.id, name: data.name, color: data.color }))
                setActionOption(action_option)
            })
        }

        getAction()
    }, [])

    const handleClickAction = (id: number) => {
        setAction(id)
        setActionError(false)
    }

    const handleChangeFiscalYear = (e: any, { value }: InputOnChangeData) => {
        if (new RegExp('^[0-9]*$').test(value)) {
            setFiscalYear(value)
        }
    }

    const handleChangeDuration = (e: any, { value }: DropdownProps) => {
        setDuration(value)
    }

    const handleChangeStartDate = (e: any, { value }: InputOnChangeData) => {
        setStartDate(value)
        if (duration) {
            let new_date_format = value.split('/')
            let new_date = `${new_date_format[1]}/${new_date_format[0]}/${String(Number(new_date_format[2]) - 543)}`
            let end_date = new Date(new_date)
            if (duration === 90) {
                end_date.setMonth(end_date.getMonth() + 3)
            } else {
                end_date.setDate(end_date.getDate() + Number(duration))
            }
            let format_end_date = `${end_date.getDate() < 10 ? `0${end_date.getDate()}` : end_date.getDate()}/${
                end_date.getMonth() + 1 < 10 ? `0${end_date.getMonth() + 1}` : end_date.getMonth() + 1
            }/${end_date.getFullYear() + 543}`
            setEndDate(format_end_date)
        }
    }

    const handleChangeDetail = (e: any, { value }: TextAreaProps) => {
        setDetail(value)
        setDetailError(false)
    }

    const handleChangePhotoFile = (event: any) => {
        let files = event?.target.files
        let list_file: any[] = []
        for (let i = 0; i < files.length; i++) {
            list_file.push(files[i])
        }
        setPhotoFile([...photo_file, ...list_file])
        setFileError(false)
    }
    const handleChangePDFFile = (event: any) => {
        let files = event?.target.files
        let list_file: any[] = []
        for (let i = 0; i < files.length; i++) {
            list_file.push(files[i])
        }
        setPDFFile([...pdf_file, ...list_file])
        setFileError(false)
    }

    const validate = () => {
        let error = false
        if (!action) {
            setActionError(true)
            error = true
        }
        if (!detail) {
            setDetailError(true)
            error = true
        }
        if (photo_file.length === 0 && pdf_file.length === 0) {
            setFileError(true)
            error = true
        }

        return error
    }
    const handleSubmit = () => {
        let { state, pathname } = location
        let split_path = pathname.split('/')
        let permission = split_path[1]
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { complaint_id } = location_state

            if (action === 3) {
                setLoading(true)
                let body = {
                    action: 0,
                    complaints_id: complaint_id,
                    detail: detail,
                    directory_of_municipalities_id: Number(localStorage.directory_of_municipalities_id),
                }
                axiosAuthen
                    .put(PUT_COMPLAINT_CHANGE_DIRECTORY, body)
                    .then(() => {
                        setLoading(false)
                        alert({
                            type: 'success',
                            title: 'บันทึกเสร็จสิ้น',
                            timer: 1500,
                            onClosed: () => {
                                history.replace(`/${permission}/complaint`)
                            },
                        })
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            } else if (!validate()) {
                setLoading(true)
                let processing_time = duration_option.find((data: any) => duration === data.value)?.text
                if (!processing_time) {
                    processing_time = ''
                }
                let form_data = new FormData()
                form_data.append('id', complaint_id)
                form_data.append('status', String(action))
                form_data.append('processing_fiscal_year', fiscal_year)
                form_data.append('processing_detail', String(detail))
                form_data.append('processing_time', String(processing_time))
                form_data.append('processing_start_date', start_date)
                form_data.append('processing_end_date', end_date)
                photo_file.forEach((file: any) => {
                    form_data.append('photo_result', file)
                })
                pdf_file.forEach((file: any) => {
                    form_data.append('file_result', file)
                })

                axiosAuthen
                    .post(POST_COMPLAINT_SAVE_RESULT, form_data)
                    .then((response) => {
                        setLoading(false)
                        alert({
                            type: 'success',
                            title: 'บันทึกเสร็จสิ้น',
                            timer: 1500,
                            onClosed: () => {
                                history.goBack()
                            },
                        })
                    })
                    .catch((error) => {
                        setLoading(false)
                        // console.log(error.message)
                    })
            } else {
                setDetailError(true)
            }
        }
    }
    const handleGoBack = () => {
        history.goBack()
    }
    return (
        <Fragment>
            <div className='background-container'>
                <Dimmer active={loading} inverted page>
                    <Loader size='large'>รอสักครู่</Loader>
                </Dimmer>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 style={{ textAlign: 'center' }}>บันทึกผลการดำเนินการ</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <CustomSegment icon='file alternate' header='ข้อมูลการดำเนินการ'>
                                    <Form>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='3'></Grid.Column>
                                                <Grid.Column computer='10'>
                                                    <label>
                                                        ผลดำเนินการ{' '}
                                                        {action_error && (
                                                            <label style={{ color: 'red' }}>
                                                                {' '}
                                                                * กรุณาเลือกผลดำเนินการ
                                                            </label>
                                                        )}
                                                    </label>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {action_option.map((data: any, index: number) => (
                                                            <Segment
                                                                key={index}
                                                                style={
                                                                    action === data.id
                                                                        ? {
                                                                              cursor: 'pointer',
                                                                              padding: 10,
                                                                              margin: 10,
                                                                              borderColor: '#293757',
                                                                          }
                                                                        : {
                                                                              cursor: 'pointer',
                                                                              padding: 10,
                                                                              margin: 10,
                                                                          }
                                                                }
                                                                onClick={() => handleClickAction(data.id)}
                                                            >
                                                                <Icon name='circle' style={{ color: data.color }} />{' '}
                                                                {data.name}
                                                            </Segment>
                                                        ))}
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column computer='3'></Grid.Column>
                                            </Grid.Row>
                                            {action === 6 && (
                                                <Grid.Row>
                                                    <Grid.Column computer='3'></Grid.Column>
                                                    <Grid.Column computer='10'>
                                                        <label>ระบุปีงบประมาณ</label>
                                                        <Input
                                                            fluid
                                                            value={fiscal_year}
                                                            onChange={handleChangeFiscalYear}
                                                            maxLength={4}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='3'></Grid.Column>
                                                </Grid.Row>
                                            )}
                                            {action === 5 && (
                                                <Fragment>
                                                    <Grid.Row>
                                                        <Grid.Column computer='3'></Grid.Column>
                                                        <Grid.Column computer='10'>
                                                            <label>ระยะเวลาดำเนินการอีกครั้ง</label>
                                                            <Dropdown
                                                                selection
                                                                fluid
                                                                options={duration_option}
                                                                value={duration}
                                                                onChange={handleChangeDuration}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='3'></Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column computer='3'></Grid.Column>
                                                        <Grid.Column computer='5'>
                                                            <label>วันที่เริ่มดำเนินการอีกครั้ง</label>
                                                            <DatePicker
                                                                fluid
                                                                name='start_date'
                                                                value={start_date}
                                                                onChange={handleChangeStartDate}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5'>
                                                            <label>วันที่สิ้นสุดการดำเนินการ</label>
                                                            <Input fluid readOnly value={end_date} />
                                                        </Grid.Column>
                                                        <Grid.Column computer='3'></Grid.Column>
                                                    </Grid.Row>
                                                </Fragment>
                                            )}
                                            <Grid.Row>
                                                <Grid.Column computer='3'></Grid.Column>
                                                <Grid.Column computer='10'>
                                                    <Form.Field error={detail_erorr}>
                                                        <label>รายละเอียด</label>
                                                        <TextArea value={detail} onChange={handleChangeDetail} />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3'></Grid.Column>
                                            </Grid.Row>
                                            {action !== 3 && (
                                                <Fragment>
                                                    {file_error && (
                                                        <Grid.Row>
                                                            <Grid.Column computer='3' />
                                                            <Grid.Column computer='10'>
                                                                <label style={{ color: 'red' }}>
                                                                    {' '}
                                                                    * กรุณาเลือกไฟล์การบันทึกผลดำเนินการ
                                                                </label>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}
                                                    <Grid.Row>
                                                        <Grid.Column computer='3' />
                                                        <Grid.Column computer='10'>
                                                            <Button
                                                                style={{ backgroundColor: '#425679', color: '#ffffff' }}
                                                                onClick={() => {
                                                                    document.getElementById('photo_file')?.click()
                                                                }}
                                                                disabled={photo_file.length === 5}
                                                            >
                                                                เลือกไฟล์ภาพ
                                                            </Button>{' '}
                                                            <label style={{ color: '#425679' }}>
                                                                เฉพาะสกุลไฟล์ *.jpg หรือ *.png
                                                            </label>
                                                            <input
                                                                type='file'
                                                                id='photo_file'
                                                                style={{ display: 'none' }}
                                                                accept=',image/png, image/jpeg,image/jpg'
                                                                multiple
                                                                onChange={handleChangePhotoFile}
                                                            />
                                                            <div style={{ display: 'flex' }}>
                                                                {photo_file.map((file: any, index: number) => (
                                                                    <Image
                                                                        key={index}
                                                                        bordered
                                                                        src={URL.createObjectURL(file)}
                                                                        style={{
                                                                            margin: 10,
                                                                            padding: 20,
                                                                            maxWidth: 120,
                                                                            maxHeight: 110,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                ))}
                                                                {/* <Image
                                                            bordered
                                                            src={IconCamera}
                                                            style={{
                                                                margin: 10,
                                                                padding: 20,
                                                                maxWidth: 120,
                                                                maxHeight: 120,
                                                                cursor: 'pointer',
                                                            }}
                                                        /> */}
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column computer='3' />
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column computer='3' />
                                                        <Grid.Column computer='10'>
                                                            <Button
                                                                style={{ backgroundColor: '#425679', color: '#ffffff' }}
                                                                onClick={() => {
                                                                    document.getElementById('pdf_file')?.click()
                                                                }}
                                                                disabled={pdf_file.length === 5}
                                                            >
                                                                เลือกไฟล์เอกสาร
                                                            </Button>{' '}
                                                            <label style={{ color: '#425679' }}>
                                                                เฉพาะสกุลไฟล์ *.pdf
                                                            </label>
                                                            <input
                                                                type='file'
                                                                id='pdf_file'
                                                                style={{ display: 'none' }}
                                                                accept='.pdf'
                                                                multiple
                                                                onChange={handleChangePDFFile}
                                                            />
                                                            <div style={{ display: 'flex' }}>
                                                                {pdf_file.map((file: any, index: number) => (
                                                                    <Segment
                                                                        key={index}
                                                                        style={{
                                                                            margin: 10,
                                                                            cursor: 'pointer',
                                                                            maxWidth: 150,
                                                                        }}
                                                                    >
                                                                        <Image
                                                                            src={IconPDF}
                                                                            style={{
                                                                                padding: 20,
                                                                                maxWidth: 120,
                                                                                maxHeight: 120,
                                                                            }}
                                                                        />
                                                                        <label
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                wordBreak: 'break-word',
                                                                            }}
                                                                        >
                                                                            {file.name}
                                                                        </label>
                                                                    </Segment>
                                                                ))}
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column computer='3' />
                                                    </Grid.Row>
                                                </Fragment>
                                            )}
                                        </Grid>
                                    </Form>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleGoBack}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}
export default ComplaintListDetailSaveResult
