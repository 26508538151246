import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Dropdown,
    DropdownOnSearchChangeData,
    Form,
    Grid,
    Image,
    Input,
    InputOnChangeData,
    Radio,
    Segment,
} from 'semantic-ui-react'
import CustomSegment from '../../../../components/CustomSegment'
import { setStateByNameAndValue } from '../../../../store/actions'
import { ComplaintFormStep2State } from '../../../../store/types/Complaint/Form/Step2'
import IconMan from './../../../../assets//images/man.png'
import IconWoman from './../../../../assets//images/woman.png'
import IconWoman2 from './../../../../assets//images/woman1.png'
import ThaiAddress from '../../../../constants/ThaiAddress.json'

type Step2Props = {
    data: ComplaintFormStep2State
    field: string[]
}

const Step2: React.FC<Step2Props> = (props) => {
    let dispatch = useDispatch()
    let [option_sub_district, setOptionSubDistrict] = useState<object[]>()
    let [option_district, setOptionDistrict] = useState<object[]>([])
    let [option_province, setOptionProvince] = useState<object[]>([])
    let [option_zipcode, setOptionZipcode] = useState<object[]>([])
    let thai_address = ThaiAddress
    let { data, field } = props
    let {
        ssn,
        prefix,
        first_name,
        last_name,
        house_no,
        village_no,
        alley,
        road,
        sub_district,
        district,
        province,
        zipcode,
        phone_number,
        mobile_number,
        email,
        prefix_error,
        first_name_error,
        last_name_error,
        house_no_error,
        village_no_error,
        sub_district_error,
        district_error,
        province_error,
        zipcode_error,
        mobile_number_error,
    } = data

    useEffect(() => {
        if (sub_district && district && province && zipcode) {
            setOptionSubDistrict([{ value: sub_district, text: sub_district }])
            setOptionDistrict([{ value: district, text: district }])
            setOptionProvince([{ value: province, text: province }])
            setOptionZipcode([{ value: zipcode, text: zipcode }])
        }
    }, [district, province, sub_district, zipcode])

    const handleChangeSSN = (_: any, { name, value }: InputOnChangeData) => {
        value = value.replace(/-/g, '')
        let ssn = ''
        for (let position = 0; position < value.length; position++) {
            if (position === 1 || position === 5 || position === 10 || position === 10) {
                ssn += '-' + value.charAt(position)
            } else {
                ssn += value.charAt(position)
            }
        }
        if (!isNaN(Number(value))) {
            dispatch(setStateByNameAndValue('ComplaintFormStep2', name, ssn))
        }
    }

    const handleChangeNumber = (event: any, { name, value }: InputOnChangeData) => {
        if (!isNaN(Number(value))) {
            dispatch(setStateByNameAndValue('ComplaintFormStep2', name, value))
            dispatch(setStateByNameAndValue('ComplaintFormStep2', `${name}_error`, false))
        }
    }

    const handleChangeInput = (event: any, { name, value }: InputOnChangeData) => {
        dispatch(setStateByNameAndValue('ComplaintFormStep2', name, value))
        dispatch(setStateByNameAndValue('ComplaintFormStep2', `${name}_error`, false))
    }

    const handleChangePrefix = (value: string) => {
        dispatch(setStateByNameAndValue('ComplaintFormStep2', 'prefix', value))
        dispatch(setStateByNameAndValue('ComplaintFormStep2', `prefix_error`, false))
    }

    const handleSelectAddress = (e: any, { name, value }: any) => {
        let address = value.split(' >> ')

        dispatch(setStateByNameAndValue('ComplaintFormStep2', 'sub_district', address[0]))
        dispatch(setStateByNameAndValue('ComplaintFormStep2', 'district', address[1]))
        dispatch(setStateByNameAndValue('ComplaintFormStep2', 'province', address[2]))
        dispatch(setStateByNameAndValue('ComplaintFormStep2', 'zipcode', address[3]))

        dispatch(setStateByNameAndValue('ComplaintFormStep2', `sub_district_error`, false))
        dispatch(setStateByNameAndValue('ComplaintFormStep2', `district_error`, false))
        dispatch(setStateByNameAndValue('ComplaintFormStep2', `province_error`, false))
        dispatch(setStateByNameAndValue('ComplaintFormStep2', `zipcode_error`, false))
    }

    const searchSubDistrict = (data: any) => {
        if (data.searchQuery) {
            let option: object[] = []
            let row = 0
            thai_address.forEach((value) => {
                if (value.sub_district.includes(data.searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode
                        option.push({
                            text: text_address,
                            value: text_address,
                        })
                        row++
                    }
                }
            })
            setOptionSubDistrict(option)
        }
    }

    const searchDistrict = (data: any) => {
        if (data.searchQuery) {
            let option: object[] = []
            let row = 0
            thai_address.forEach((value) => {
                if (value.district.includes(data.searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode
                        option.push({
                            text: text_address,
                            value: text_address,
                        })
                        row++
                    }
                }
            })
            setOptionDistrict(option)
        }
    }

    const searchProvice = (data: any) => {
        if (data.searchQuery) {
            let option: object[] = []
            let row = 0
            thai_address.forEach((value) => {
                if (value.province.includes(data.searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode
                        option.push({
                            text: text_address,
                            value: text_address,
                        })
                        row++
                    }
                }
            })
            setOptionProvince(option)
        }
    }

    const searchZipcode = (data: any) => {
        if (data.searchQuery) {
            let option: object[] = []
            let row = 0
            thai_address.forEach((value) => {
                if (String(value.zipcode).includes(data.searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode
                        option.push({
                            text: text_address,
                            value: text_address,
                        })
                        row++
                    }
                }
            })
            setOptionZipcode(option)
        }
    }

    return (
        <Fragment>
            <Form>
                <CustomSegment icon='user' header='ข้อมูลผู้ยื่นคำร้อง'>
                    <Grid>
                        <Grid.Row>
                            {field.includes('ssn') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10' mobile='16'>
                                        <label className='header-input-form'>เลขประจำตัวประชาชน</label>
                                        <Input fluid name='ssn' value={ssn} onChange={handleChangeSSN} maxLength={17} />
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}
                            {field.includes('detail person') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10' mobile='16' style={{ marginTop: 20 }}>
                                        <label className='header-input-form'>คำนำหน้าชื่อ</label>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                    <Segment
                                                        className={
                                                            prefix === 'นาย'
                                                                ? 'selected-prefix'
                                                                : prefix_error
                                                                ? 'error-prefix'
                                                                : 'unselected-prefix'
                                                        }
                                                        onClick={() => {
                                                            handleChangePrefix('นาย')
                                                        }}
                                                        error={prefix_error}>
                                                        <Radio checked={prefix === 'นาย'} />
                                                        <Image
                                                            src={IconMan}
                                                            style={{ margin: 5, width: 56, height: 56 }}
                                                        />
                                                        <div style={{ margin: 5 }}>
                                                            <div style={{ paddingTop: 5, paddingBottom: 5 }}>นาย</div>
                                                            <div style={{ color: '#4D648D' }}>Mr.</div>
                                                        </div>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                    <Segment
                                                        className={
                                                            prefix === 'นาง'
                                                                ? 'selected-prefix'
                                                                : prefix_error
                                                                ? 'error-prefix'
                                                                : 'unselected-prefix'
                                                        }
                                                        onClick={() => {
                                                            handleChangePrefix('นาง')
                                                        }}
                                                        error={prefix_error}>
                                                        <Radio checked={prefix === 'นาง'} />
                                                        <Image
                                                            src={IconWoman2}
                                                            style={{ margin: 5, width: 56, height: 56 }}
                                                        />
                                                        <div style={{ margin: 5 }}>
                                                            <div style={{ paddingTop: 5, paddingBottom: 5 }}>นาง</div>
                                                            <div style={{ color: '#4D648D' }}>Mrs.</div>
                                                        </div>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                    <Segment
                                                        className={
                                                            prefix === 'นางสาว'
                                                                ? 'selected-prefix'
                                                                : prefix_error
                                                                ? 'error-prefix'
                                                                : 'unselected-prefix'
                                                        }
                                                        onClick={() => {
                                                            handleChangePrefix('นางสาว')
                                                        }}
                                                        error={prefix_error}>
                                                        <Radio checked={prefix === 'นางสาว'} />
                                                        <Image
                                                            src={IconWoman}
                                                            style={{ margin: 5, width: 56, height: 56 }}
                                                        />
                                                        <div style={{ margin: 5 }}>
                                                            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                                นางสาว
                                                            </div>
                                                            <div style={{ color: '#4D648D' }}>Ms.</div>
                                                        </div>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />

                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>ชื่อ</label>
                                        <Form.Field error={first_name_error}>
                                            <Input
                                                fluid
                                                name='first_name'
                                                value={first_name}
                                                onChange={handleChangeInput}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>สกุล</label>
                                        <Form.Field error={last_name_error}>
                                            <Input
                                                fluid
                                                name='last_name'
                                                value={last_name}
                                                onChange={handleChangeInput}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}
                            {field.includes('address person') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>บ้านเลขที่</label>
                                        <Form.Field error={house_no_error}>
                                            <Input
                                                fluid
                                                name='house_no'
                                                value={house_no}
                                                onChange={handleChangeInput}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>หมู่</label>
                                        <Form.Field error={village_no_error}>
                                            <Input
                                                fluid
                                                name='village_no'
                                                value={village_no}
                                                onChange={handleChangeInput}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />

                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>ซอย</label>
                                        <Input fluid name='alley' value={alley} onChange={handleChangeInput} />
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>ถนน</label>
                                        <Input fluid name='road' value={road} onChange={handleChangeInput} />
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />

                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>ตำบล</label>
                                        <Form.Field error={sub_district_error}>
                                            <Dropdown
                                                selection
                                                search
                                                fluid
                                                id='sub_district'
                                                name='sub_district'
                                                onChange={handleSelectAddress}
                                                options={option_sub_district}
                                                value={sub_district}
                                                onSearchChange={(e: any, data: DropdownOnSearchChangeData) =>
                                                    searchSubDistrict(data)
                                                }
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>อำเภอ</label>
                                        <Form.Field error={district_error}>
                                            <Dropdown
                                                selection
                                                search
                                                fluid
                                                id='district'
                                                name='district'
                                                onChange={handleSelectAddress}
                                                options={option_district}
                                                value={district}
                                                onSearchChange={(e: any, data: DropdownOnSearchChangeData) =>
                                                    searchDistrict(data)
                                                }
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />

                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>จังหวัด</label>
                                        <Form.Field error={province_error}>
                                            <Dropdown
                                                selection
                                                search
                                                fluid
                                                id='province'
                                                name='province'
                                                onChange={handleSelectAddress}
                                                options={option_province}
                                                value={province}
                                                onSearchChange={(e: any, data: DropdownOnSearchChangeData) =>
                                                    searchProvice(data)
                                                }
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>รหัสไปรษณีย์</label>
                                        <Form.Field error={zipcode_error}>
                                            <Dropdown
                                                selection
                                                search
                                                fluid
                                                id='zipcode'
                                                name='zipcode'
                                                onChange={handleSelectAddress}
                                                options={option_zipcode}
                                                value={zipcode}
                                                onSearchChange={(e: any, data: DropdownOnSearchChangeData) =>
                                                    searchZipcode(data)
                                                }
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}
                            {field.includes('detail person') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>โทรศัพท์ (บ้าน)</label>
                                        <Input
                                            fluid
                                            name='phone_number'
                                            value={phone_number}
                                            onChange={handleChangeNumber}
                                            maxLength='10'
                                        />
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 20 }} computer='5' mobile='16'>
                                        <label className='header-input-form'>โทรศัพท์ (มือถือ)</label>
                                        <Form.Field error={mobile_number_error}>
                                            <Input
                                                fluid
                                                name='mobile_number'
                                                value={mobile_number}
                                                onChange={handleChangeNumber}
                                                maxLength='10'
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />

                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column style={{ marginTop: 20 }} computer='10' mobile='16'>
                                        <label className='header-input-form'> อีเมล (ถ้ามี)</label>
                                        <Input fluid name='email' value={email} onChange={handleChangeInput} />
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}
                        </Grid.Row>
                    </Grid>
                </CustomSegment>
            </Form>
        </Fragment>
    )
}

export default Step2
