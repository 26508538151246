import axios from 'axios';
import { HOST } from '../config/router';

export const getFile = async (file_name: string, path: string) => {
    let newFile: File | undefined = undefined;
    let typeFile = file_name.split('.');
    let type = typeFile[typeFile.length - 1];
    if (path) {
        if (type === 'pdf') {
            await axios
                .get(HOST + path, {
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    let data = response.data;
                    let blobFile = new Blob([data]);
                    newFile = new File([blobFile], file_name, {
                        type: 'application/pdf',
                    });
                })
                .catch(() => {});
        } else {
            await axios
                .get(HOST + path, {
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    let data = response.data;
                    let blobFile = new Blob([data]);
                    newFile = new File([blobFile], file_name, {
                        type: `image/${type}`,
                    });
                })
                .catch(() => {});
        }
        return newFile;
    }
};
