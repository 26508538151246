import React, { Fragment } from 'react'
import { Breadcrumb, Container, Dimmer, Grid, Icon, Loader, Segment } from 'semantic-ui-react'
import ButtonIconOver from '../../../../components/ButtonIconOver'
import CustomSegment from '../../../../components/CustomSegment'
import GoogleMaps from '../../../../components/GoogleMaps'
import AcceptComplaint from './AcceptComplaint'
import AcceptComplaintCentral from './AcceptComplaintCentral'
import RejectComplaint from './RejectComplaint'
import ChangeTechnician from './ChangeTechnician'
import EvaluateComplaint from './EvaluateComplaint'
import ChangeDirectory from './ChangeDirectory'
import HistoryComplaint from './HistoryComplaint'
import ExportReport from './ExportReport'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import {
    GET_COMPLAINT_CHECK_ASSIGN_TECHNICIAN,
    GET_COMPLAINT_DETAIL,
    PUT_COMPLAINT_POSITION_MAP,
} from '../../../../config/api/complaint'
import { useState } from 'react'
import FullFormatDateTH from '../../../../functions/FullFormatDateTH'
import { HOST } from '../../../../config/router'
import { useCallback } from 'react'
import EvaluateComplaintDetail from './EvaluateComplaintDetail'
import { alert } from '../../../../components/alert'
import { useSelector } from 'react-redux'
import { getAuthenMenuList } from '../../../../store/selectors'
import ContinueComplaint from './ContinueComplaint'

export interface InterfaceComplaintDetail {
    alley: string
    area_id: number
    area_name: string
    central_complaint_date: string
    central_complaint_no: string
    central_complaint_time: string
    community_id: number
    community_name: string
    complaint_date: string
    complaint_detail: string
    complaint_subject: string
    complaint_topics_id: number
    complaint_topics_name: string
    complaint_types_id: number
    complaint_types_name: string
    contact_id: number
    contact_name: string
    created_by_id: number
    department_complaint_date: string
    department_complaint_no: string
    directory_of_municipalities_id: number
    directory_of_municipalities_name: string
    district: string
    email: string
    files: InterfaceFileComplaint[]
    pre_files: InterfaceFileComplaint[]
    first_name: string
    full_name: string
    full_name_created: string
    full_name_technician: string
    grade: string
    house_no: string
    id: number
    last_name: string
    lat: string
    lng: string
    mobile_number: string
    on_site_date: string
    phone_number: string
    pre_complaint_id: number
    prefix: string
    processing_detail: string
    processing_end_date: string
    processing_start_date: string
    processing_time: string
    province: string
    road: string
    save_process_at: string
    status_id: number
    status_name: string
    subdistrict: string
    suggestion: string
    user_id: number
    village_no: string
    year: string
    zipcode: string
    remark: string
}

interface InterfaceFileComplaint {
    action: string
    complaints_id: number
    created_at: string
    created_by: number
    deleted_at: string
    id: number
    name: string
    path_file: string
    sequence: number
    type: string
    updated_at: string
    updated_by: number
}

interface InterfacePosition {
    lat: number
    lng: number
}

const ComplaintListDetail: React.FC = () => {
    let location = useLocation()
    let history = useHistory()
    const { menu_list } = useSelector(getAuthenMenuList)
    let complaint_action = menu_list
        ?.find((menu) => menu.name_th === 'รายการคำร้องทุกข์')
        ?.pages?.find((page) => page.name_th === 'รายละเอียดคำร้อง')?.actions

    let [complaint_detail, setComplaintDetail] = useState<InterfaceComplaintDetail>()
    let [edit_map, setEditMap] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(true)
    let [position, setPosition] = useState<InterfacePosition>()
    let [assign_technician, setAssignTechnician] = useState<boolean>(false)
    let directory = localStorage.getItem('directory_of_municipalities_id')

    const getDetailComplaint = useCallback(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { complaint_id } = location_state
            setLoading(true)
            axiosAuthen.get(`${GET_COMPLAINT_DETAIL}/${complaint_id}`).then((response) => {
                let { value } = response.data
                setComplaintDetail(value)
                setPosition({ lat: Number(value?.lat), lng: Number(value?.lng) })
                setLoading(false)
                let element = document.getElementById('pusher')
                element?.scrollTo({ top: 0, behavior: 'smooth' })
            })
        }
    }, [location])

    useEffect(() => {
        const getAssignTechnician = () => {
            axiosAuthen.get(`${GET_COMPLAINT_CHECK_ASSIGN_TECHNICIAN}`).then((response) => {
                let { value } = response.data
                setAssignTechnician(value)
            })
        }
        getAssignTechnician()
    }, [])

    useEffect(() => {
        getDetailComplaint()
    }, [getDetailComplaint])

    const reloadData = () => {
        getDetailComplaint()
    }

    const handleClickSaveResult = () => {
        history.push('detail/save-result', { complaint_id: complaint_detail?.id })
    }
    const handleClickDetaileResult = () => {
        history.push('detail/detail-result', { complaint_id: complaint_detail?.id })
    }

    const handleEditMap = () => {
        setEditMap(true)
    }
    const handleCancelEditMap = () => {
        setEditMap(false)
    }

    const handleChangePosition = (_: any, { value }: any) => {
        setPosition(value)
    }

    const handleUpdatePositionMap = () => {
        axiosAuthen
            .put(PUT_COMPLAINT_POSITION_MAP, {
                complaint_id: complaint_detail?.id,
                lat: String(position?.lat),
                lng: String(position?.lng),
            })
            .then(() => {
                getDetailComplaint()
                alert({
                    type: 'success',
                    title: 'บันทึกเสร็จสิ้น',
                    timer: 1500,
                    onClosed: () => {
                        handleCancelEditMap()
                    },
                })
            })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Dimmer active={loading} inverted page>
                    <Loader size='large'>รอสักครู่</Loader>
                </Dimmer>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/complaint`)
                                        }}
                                    >
                                        รายการคำร้อง
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>รายละเอียดคำร้อง</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 style={{ textAlign: 'center' }}>รายละเอียดคำร้อง</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row reversed='computer'>
                            <Grid.Column computer='8' tablet='8' mobile='16'>
                                {edit_map ? (
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='8'>
                                                <ButtonIconOver
                                                    fluid
                                                    label='บันทึก'
                                                    color='#75B9A5'
                                                    icon='check'
                                                    basic
                                                    onClick={handleUpdatePositionMap}
                                                />
                                            </Grid.Column>
                                            <Grid.Column computer='8'>
                                                <ButtonIconOver
                                                    fluid
                                                    label='ยกเลิก'
                                                    color='#D85F53'
                                                    icon='delete'
                                                    basic
                                                    onClick={handleCancelEditMap}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                ) : (
                                    <Grid>
                                        <Grid.Row>
                                            {((assign_technician && complaint_detail?.status_id === 1) ||
                                                (!assign_technician &&
                                                    Number(directory) ===
                                                        Number(complaint_detail?.directory_of_municipalities_id) &&
                                                    complaint_detail?.status_id === 1)) && (
                                                <Fragment>
                                                    {complaint_action?.find(
                                                        (action) => action.name_th === 'ปฏิเสธคำร้องทุกข์'
                                                    ) && (
                                                        <Grid.Column computer='8'>
                                                            <RejectComplaint
                                                                complaint_id={complaint_detail?.id}
                                                                reload={reloadData}
                                                            />
                                                        </Grid.Column>
                                                    )}
                                                    {complaint_action?.find(
                                                        (action) =>
                                                            action.name_th === 'ตอบรับ - รับเรื่องร้องทุกข์จากส่วนกลาง'
                                                    ) && (
                                                        <Grid.Column computer='8'>
                                                            <AcceptComplaintCentral
                                                                complaint_id={complaint_detail?.id}
                                                                reload={reloadData}
                                                            />
                                                        </Grid.Column>
                                                    )}
                                                </Fragment>
                                            )}
                                            {(complaint_detail?.status_id === 2 ||
                                                complaint_detail?.status_id === 3) && (
                                                <Fragment>
                                                    {complaint_action?.find(
                                                        (action) => action.name_th === 'ตอบรับ - รับเรื่องร้องทุกข์'
                                                    ) && (
                                                        <Grid.Column computer='8'>
                                                            <AcceptComplaint
                                                                complaint_id={complaint_detail?.id}
                                                                reload={reloadData}
                                                            />
                                                        </Grid.Column>
                                                    )}
                                                    {complaint_action?.find(
                                                        (action) => action.name_th === 'มอบหมายเจ้าหน้าที่'
                                                    ) && (
                                                        <Grid.Column computer='8'>
                                                            <ChangeTechnician
                                                                complaint_id={complaint_detail?.id}
                                                                reload={reloadData}
                                                            />
                                                        </Grid.Column>
                                                    )}
                                                    {complaint_action?.find(
                                                        (action) => action.name_th === 'เปลี่ยนหน่วยงาน'
                                                    ) && (
                                                        <Grid.Column computer='8'>
                                                            <ChangeDirectory
                                                                complaint_detail={complaint_detail}
                                                                reload={reloadData}
                                                            />
                                                        </Grid.Column>
                                                    )}
                                                </Fragment>
                                            )}
                                            {complaint_action?.find(
                                                (action) => action.name_th === 'บันทึกผลการดำเนินการ'
                                            ) &&
                                                complaint_detail?.status_id &&
                                                [5, 6, 12].includes(complaint_detail?.status_id) && (
                                                    <Grid.Column computer='8'>
                                                        <ContinueComplaint
                                                            complaint_id={complaint_detail?.id}
                                                            reload={reloadData}
                                                        />
                                                    </Grid.Column>
                                                )}
                                            {complaint_action?.find((action) => action.name_th === 'แก้ไขแผนที่') &&
                                                ![1, 13, 14].includes(Number(complaint_detail?.status_id)) && (
                                                    <Grid.Column computer='8'>
                                                        <ButtonIconOver
                                                            fluid
                                                            label='แก้ไขแผนที่'
                                                            color='#FEB326'
                                                            icon='map marker alternate'
                                                            basic
                                                            onClick={handleEditMap}
                                                        />
                                                    </Grid.Column>
                                                )}
                                            {complaint_action?.find((action) => action.name_th === 'ประวัติคำร้อง') && (
                                                <Grid.Column computer='8'>
                                                    <HistoryComplaint />
                                                </Grid.Column>
                                            )}
                                            {complaint_action?.find((action) => action.name_th === 'นำทาง') && (
                                                <Grid.Column computer='8'>
                                                    <a
                                                        href={`https://www.google.com/maps/dir/?api=1&origin=&destination=${Number(
                                                            complaint_detail?.lat
                                                        )},${Number(complaint_detail?.lng)}`}
                                                        target={'_blank'}
                                                        rel='noreferrer'
                                                    >
                                                        <ButtonIconOver
                                                            fluid
                                                            label='นำทาง'
                                                            color='#61C4EA'
                                                            icon='location arrow'
                                                            basic
                                                        />
                                                    </a>
                                                </Grid.Column>
                                            )}
                                            {complaint_action?.find((action) => action.name_th === 'ออกเอกสาร') && (
                                                <Grid.Column computer='8'>
                                                    <ExportReport complaint_id={complaint_detail?.id} />
                                                </Grid.Column>
                                            )}
                                            {complaint_action?.find(
                                                (action) => action.name_th === 'บันทึกผลการดำเนินการ'
                                            ) &&
                                                complaint_detail?.status_id &&
                                                [9, 17, 18].includes(complaint_detail?.status_id) && (
                                                    <Grid.Column computer='8'>
                                                        <ButtonIconOver
                                                            fluid
                                                            label='บันทึกผลการดำเนินการ'
                                                            color='#93BE52'
                                                            icon='clipboard check'
                                                            basic
                                                            onClick={handleClickSaveResult}
                                                        />
                                                    </Grid.Column>
                                                )}

                                            {complaint_action?.find((action) => action.name_th === 'ผลการดำเนินการ') &&
                                                [5, 6, 7, 8, 12, 13, 14, 16].includes(
                                                    Number(complaint_detail?.status_id)
                                                ) && (
                                                    <Grid.Column computer='8'>
                                                        <ButtonIconOver
                                                            fluid
                                                            label='ดูผลการดำเนินการ'
                                                            color='#425679'
                                                            icon='book'
                                                            basic
                                                            onClick={handleClickDetaileResult}
                                                        />
                                                    </Grid.Column>
                                                )}
                                            {complaint_action?.find(
                                                (action) => action.name_th === 'ประเมินผลการทำงาน'
                                            ) &&
                                                complaint_detail?.status_id === 13 && (
                                                    <Grid.Column computer='8'>
                                                        <EvaluateComplaint
                                                            complaint_id={complaint_detail?.id}
                                                            reload={reloadData}
                                                        />
                                                    </Grid.Column>
                                                )}
                                            {complaint_action?.find(
                                                (action) => action.name_th === 'ผลการประเมินการทำงาน'
                                            ) &&
                                                complaint_detail?.status_id === 14 && (
                                                    <Grid.Column computer='8'>
                                                        <EvaluateComplaintDetail complaint_id={complaint_detail?.id} />
                                                    </Grid.Column>
                                                )}
                                        </Grid.Row>
                                    </Grid>
                                )}
                            </Grid.Column>
                            <Grid.Column computer='8' tablet='8' mobile='16'>
                                <Segment>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='5'>
                                                <h5>ข้อมูลคำร้องเลขที่รับ</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>
                                                {FullFormatDateTH(complaint_detail?.department_complaint_date)}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='5'>
                                                <h5>หน่วยงาน</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>
                                                {complaint_detail?.directory_of_municipalities_name}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='5'>
                                                <h5>สถานะคำร้อง</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{complaint_detail?.status_name}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='5'>
                                                <h5>หมายเหตุ</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='11'>{complaint_detail?.remark || '-'}</Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        {!edit_map ? (
                            <Fragment>
                                <Grid.Row>
                                    <Grid.Column>
                                        <CustomSegment icon='file alternate' header='ข้อมูลคำร้อง'>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' mobile='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>เลขที่รับจากส่วนกลาง</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.central_complaint_no || '-'}
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' mobile='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>เรื่องที่ยื่นคำร้อง</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.complaint_topics_name}
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' mobile='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>เลขที่รับ</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.department_complaint_no || '-'}
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' mobile='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>ประเภทงาน</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.complaint_types_name || '-'}
                                                        </div>
                                                    </Grid.Column>
                                                    {/* </Grid.Row>
                                                <Grid.Row> */}
                                                    <Grid.Column computer='3' mobile='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>วัน / เดือน / ปี ที่รับเรื่อง</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {FullFormatDateTH(
                                                                complaint_detail?.department_complaint_date
                                                            )}
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' mobile='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>พื้นที่ ( เขต / ชุมชน )</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.area_name || '-'} /{' '}
                                                            {complaint_detail?.community_name || '-'}
                                                        </div>
                                                    </Grid.Column>
                                                    {/* </Grid.Row>
                                                <Grid.Row> */}
                                                    <Grid.Column computer='3' mobile='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>หัวข้อเรื่องร้องทุกข์</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.complaint_subject || '-'}
                                                        </div>
                                                    </Grid.Column>

                                                    <Grid.Column computer='3' mobile='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>เจ้าหน้าที่รับผิดชอบ</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.full_name_technician || '-'}
                                                        </div>
                                                    </Grid.Column>
                                                    {/* </Grid.Row>
                                                <Grid.Row> */}
                                                    <Grid.Column computer='3' mobile='8' textAlign='right'>
                                                        {' '}
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>ช่องทางการรับเรื่อง</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.contact_name || '-'}
                                                        </div>
                                                    </Grid.Column>

                                                    <Grid.Column computer='3' mobile='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>วันที่ปฏิบัติการ</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.processing_start_date &&
                                                            complaint_detail?.processing_end_date
                                                                ? `${complaint_detail?.processing_start_date} - ${complaint_detail?.processing_end_date}`
                                                                : '-'}
                                                        </div>
                                                    </Grid.Column>
                                                    {/* </Grid.Row>
                                                <Grid.Row> */}
                                                    <Grid.Column computer='3' mobile='5' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>รายละเอียด</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='13' mobile='11'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.complaint_detail}
                                                        </div>
                                                    </Grid.Column>
                                                    {/* </Grid.Row>
                                                <Grid.Row> */}
                                                    <Grid.Column computer='3' mobile='5' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>ไฟล์แนบ</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='13' mobile='11'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <Grid>
                                                                {complaint_detail?.files &&
                                                                complaint_detail.files.length > 0 ? (
                                                                    complaint_detail.files.map(
                                                                        (
                                                                            file: InterfaceFileComplaint,
                                                                            index: number
                                                                        ) => (
                                                                            <Grid.Row key={index}>
                                                                                <Grid.Column
                                                                                    computer='16'
                                                                                    style={{ display: 'flex' }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            padding: '10px 3px',
                                                                                            borderRadius: 8,
                                                                                            backgroundColor: '#4D648D',
                                                                                            marginRight: 5,
                                                                                        }}
                                                                                    />
                                                                                    {file.type === 'PNG' ||
                                                                                    file.type === 'png' ||
                                                                                    file.type === 'jpg' ||
                                                                                    file.type === 'JPG' ||
                                                                                    file.type === 'JEPG' ||
                                                                                    file.type === 'jepg' ? (
                                                                                        <a
                                                                                            href={`${HOST}${file.path_file}`}
                                                                                            target={'_blank'}
                                                                                            rel='noreferrer'
                                                                                        >
                                                                                            <Icon
                                                                                                name='file image outline'
                                                                                                size='large'
                                                                                                style={{
                                                                                                    color: '#75B9A5',
                                                                                                }}
                                                                                            />
                                                                                            <label
                                                                                                style={{
                                                                                                    color: '#283655',
                                                                                                    textDecoration:
                                                                                                        'underline',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                            >
                                                                                                {file.name}
                                                                                            </label>
                                                                                        </a>
                                                                                    ) : (
                                                                                        <a
                                                                                            href={`${HOST}${file.path_file}`}
                                                                                            target={'_blank'}
                                                                                            rel='noreferrer'
                                                                                        >
                                                                                            <Icon
                                                                                                name='file alternate'
                                                                                                size='large'
                                                                                                style={{
                                                                                                    color: '#D85F53',
                                                                                                }}
                                                                                            />
                                                                                            <label
                                                                                                style={{
                                                                                                    color: '#283655',
                                                                                                    textDecoration:
                                                                                                        'underline',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                            >
                                                                                                {file.name}
                                                                                            </label>
                                                                                        </a>
                                                                                    )}
                                                                                </Grid.Column>
                                                                            </Grid.Row>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <Grid.Row>
                                                                        <Grid.Column computer='16'>
                                                                            <label>-</label>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                )}
                                                                {complaint_detail?.pre_files &&
                                                                complaint_detail.pre_files.length > 0 ? (
                                                                    complaint_detail.pre_files.map(
                                                                        (
                                                                            file: InterfaceFileComplaint,
                                                                            index: number
                                                                        ) => (
                                                                            <Grid.Row key={index}>
                                                                                <Grid.Column
                                                                                    computer='16'
                                                                                    style={{ display: 'flex' }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            padding: '10px 3px',
                                                                                            borderRadius: 8,
                                                                                            backgroundColor: '#4D648D',
                                                                                            marginRight: 5,
                                                                                        }}
                                                                                    />
                                                                                    {file.type === 'PNG' ||
                                                                                    file.type === 'png' ||
                                                                                    file.type === 'jpg' ||
                                                                                    file.type === 'JPG' ||
                                                                                    file.type === 'JEPG' ||
                                                                                    file.type === 'jepg' ? (
                                                                                        <a
                                                                                            href={`${HOST}${file.path_file}`}
                                                                                            target={'_blank'}
                                                                                            rel='noreferrer'
                                                                                        >
                                                                                            <Icon
                                                                                                name='file image outline'
                                                                                                size='large'
                                                                                                style={{
                                                                                                    color: '#75B9A5',
                                                                                                }}
                                                                                            />
                                                                                            <label
                                                                                                style={{
                                                                                                    color: '#283655',
                                                                                                    textDecoration:
                                                                                                        'underline',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                            >
                                                                                                {file.name}
                                                                                            </label>
                                                                                        </a>
                                                                                    ) : (
                                                                                        <a
                                                                                            href={`${HOST}${file.path_file}`}
                                                                                            target={'_blank'}
                                                                                            rel='noreferrer'
                                                                                        >
                                                                                            <Icon
                                                                                                name='file alternate'
                                                                                                size='large'
                                                                                                style={{
                                                                                                    color: '#D85F53',
                                                                                                }}
                                                                                            />
                                                                                            <label
                                                                                                style={{
                                                                                                    color: '#283655',
                                                                                                    textDecoration:
                                                                                                        'underline',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                            >
                                                                                                {file.name}
                                                                                            </label>
                                                                                        </a>
                                                                                    )}
                                                                                </Grid.Column>
                                                                            </Grid.Row>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <Grid.Row>
                                                                        <Grid.Column computer='16'>
                                                                            <label>-</label>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                )}
                                                            </Grid>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </CustomSegment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <CustomSegment header='ข้อมูลผู้ร้อง' icon='user'>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' mobile='8' tablet='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>ชื่อ - สกุล</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8' tablet='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.full_name}
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' mobile='8' tablet='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>โทรศัพท์ ( บ้าน )</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8' tablet='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.phone_number || '-'}
                                                        </div>
                                                    </Grid.Column>
                                                    {/* </Grid.Row>
                                                <Grid.Row> */}
                                                    <Grid.Column computer='3' mobile='8' tablet='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>ที่อยู่</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8' tablet='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            บ้านเลขที่ {complaint_detail?.house_no || ' -'} หมู่ที่{' '}
                                                            {complaint_detail?.village_no || ' -'} <br />
                                                            ซอย {complaint_detail?.alley || ' -'} <br />
                                                            ถนน {complaint_detail?.road || ' -'} <br />
                                                            ตำบล{complaint_detail?.subdistrict || ' -'} อำเภอ
                                                            {complaint_detail?.district || ' -'}
                                                            <br />
                                                            จังหวัด{complaint_detail?.province || ' -'}{' '}
                                                            {complaint_detail?.zipcode}
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' mobile='8' tablet='8' textAlign='right'>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5>โทรศัพท์ ( มือถือ )</h5>
                                                            <h5>อีเมล</h5>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='5' mobile='8' tablet='8'>
                                                        <div style={{ marginTop: 20 }}>
                                                            {complaint_detail?.mobile_number || '-'}
                                                            <br />
                                                            <br />
                                                            {complaint_detail?.email || '-'}
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </CustomSegment>
                                    </Grid.Column>
                                </Grid.Row>
                                {complaint_detail?.lat && complaint_detail?.lng && (
                                    <Grid.Row>
                                        <Grid.Column>
                                            <CustomSegment header='ตำแหน่งที่ตั้ง' icon='map marker alternate'>
                                                <GoogleMaps
                                                    style={{ widht: 420, height: 420 }}
                                                    value={{
                                                        lat: Number(complaint_detail.lat),
                                                        lng: Number(complaint_detail.lng),
                                                    }}
                                                />
                                            </CustomSegment>
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </Fragment>
                        ) : (
                            complaint_detail?.lat &&
                            complaint_detail?.lng && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <CustomSegment icon='map marker alternate' header='ตำแหน่งที่ตั้งสถานที่'>
                                            {position?.lat && position?.lng && (
                                                <GoogleMaps
                                                    style={{ widht: 420, height: 420 }}
                                                    search
                                                    getCurrentPosition
                                                    value={{ lat: position.lat, lng: position.lng }}
                                                    onChange={handleChangePosition}
                                                />
                                            )}
                                        </CustomSegment>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        )}
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default ComplaintListDetail
