import { HOST } from "../router"



const APPLICATION = `${HOST}/application`
export const APPLICATION_GET_ACCOUNT_DETAIL_FROM_LOGIN = `${APPLICATION}/get_account_detail_from_login`
export const APPLICATION_CHECK_USERNAME_REGISTER = `${APPLICATION}/check_register`
export const APPLICATION_CHECK_ID_CARD = `${APPLICATION}/check_id_card`
export const APPLICATION_CHECK_EMAIL = `${APPLICATION}/check_email`
export const APPLICATION_GET_USERNAME_FROM_ID_CARD = `${APPLICATION}/get_username_from_id_card`
export const APPLICATION_GET_OTP_RESET_PASSWORD= `${APPLICATION}/get_otp_reset_password`
export const APPLICATION_GET_VERIFY_OTP_RESET_PASSWORD = `${APPLICATION}/verify_otp_reset_password`
export const APPLICATION_GET_RESET_PASSWORD_FROM_OTP = `${APPLICATION}/reset_password_from_otp`
export const APPLICATION_GET_RESET_PASSWORD_FROM_EMAIL = `${APPLICATION}/reset_password_from_email`