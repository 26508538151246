import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Checkbox, Container, Form, Grid, Icon, Image, Input, Label, Message, Segment } from 'semantic-ui-react'
import { LOGIN } from '../../config/api/common'
import Logo from '../../assets/images/main page/logo ogs.png'
import './styles.css'
import { VALID_KEY, messaging } from '../../config/firebase'
import { getToken, onMessage } from 'firebase/messaging'
import { useDispatch } from 'react-redux'
import { removeToast, setToast } from '../../store/actions'
import { useHistory } from 'react-router-dom'
import LoginImage from '../../assets/images/main page/login-image-page.png'

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)
    const [username_error, setUsernameError] = useState<boolean>(false)
    const [password_error, setPasswordError] = useState<boolean>(false)
    const [show_password, setShowPassword] = useState<boolean>(false)
    const [remember, setRemember] = useState<boolean>(false)
    const [error_text, setErrorText] = useState<string>('')
    const [firebase_token, setFirebaseToken] = useState<string>('')
    let history = useHistory()
    let dispatch = useDispatch()

    useEffect(() => {
        messaging().then((response) => {
            if (response) {
                getToken(response, { vapidKey: VALID_KEY })
                    .then((currentToken) => {
                        if (currentToken) {
                            onMessage(response, (payload) => {
                                dispatch(setToast(payload.data))
                                setTimeout(() => {
                                    dispatch(removeToast())
                                }, 5000)
                            })

                            setLoading(false)
                            setFirebaseToken(currentToken)
                        } else {
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.log('cannot connect firebase cloud messaging')
                    })
            }
        })
    }, [dispatch])

    const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target
        setUsername(value)
        setUsernameError(false)
    }

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target
        setPassword(value)
        setPasswordError(false)
    }

    const validate = () => {
        let error = false
        if (!username) {
            error = true
            setUsernameError(true)
        }
        if (!password) {
            error = true
            setPasswordError(true)
        }
        return error
    }

    const handleSubmit = () => {
        setLoading(true)
        if (!validate()) {
            axios
                .post(
                    `${LOGIN}`,
                    {
                        username: username,
                        password: password,
                    },
                    {
                        headers: {
                            firebaseToken: firebase_token,
                        },
                    }
                )
                .then((response) => {
                    setLoading(false)
                    let { value } = response.data
                    localStorage.setItem('menu', JSON.stringify(value.menu))
                    let previous_permission = localStorage.getItem('previous_permission')
                    localStorage.setItem('username', username)
                    if (remember) {
                        document.cookie = `key_name=${password}`
                    } else {
                        document.cookie = `key_name=`
                    }

                    // let menu = JSON.parse(String(localStorage.getItem('menu')));
                    localStorage.setItem('user_id', value.users_id)
                    localStorage.setItem('token', value.access_token)
                    localStorage.setItem('refresh_token', value.refresh_token)
                    localStorage.setItem('name', value.name)
                    localStorage.setItem('permission_en', value.permission_en)
                    localStorage.setItem('permission_th', value.permission_th)
                    localStorage.setItem('municipality_id', value.municipalities_id)
                    localStorage.setItem('municipalities_name', value.municipalities_name)
                    localStorage.setItem('directory_of_municipalities_id', value.directory_of_municipalities_id)
                    localStorage.setItem('directory_of_municipalities_name', value.directory_of_municipalities_name)
                    localStorage.setItem('parent_id', value.parent_id)
                    value.profile_image && localStorage.setItem('profile_image', `${value.profile_image}`)
                    // this.props.dispatch(setProfileImage(`${HOST}${value.profile_id}`));

                    let check_permission
                    if (previous_permission === 'null') {
                        check_permission = false
                    } else if (previous_permission !== value.permission_en) {
                        check_permission = true
                    }
                    if (localStorage.getItem('logout') || check_permission || history.action === 'POP') {
                        localStorage.removeItem('logout')
                        history.push(value.permission_en)
                    } else {
                        history.goBack()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        let message = error.response.data.message
                        if (message === 'wrong username and password') {
                            setErrorText('ไม่พบบัญชีผู้ใช้งานในระบบ')
                        } else if (message === 'wrong password') {
                            setErrorText('รหัสผ่านไม่ถูกต้อง')
                        } else if (message === 'wrong username') {
                            setErrorText('ไม่พบบัญชีผู้ใช้งานในระบบ')
                        }
                    } else {
                        setErrorText('ระบบเกิดข้อผิดพลาด')
                    }
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        let cookie = document.cookie
        let password = cookie.split(';').find((cookie_key) => cookie_key.indexOf('password=') !== -1)
        localStorage.getItem('username') != null && setUsername(String(localStorage.getItem('username')))
        password && setPassword(password.substring(10))
        setRemember(Boolean(password && password.substring(10)))
    }, [])

    return (
        <Fragment>
            <div className='background-login-page'>
                <Container fluid>
                    <Segment className='segment-login-page'>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column
                                    computer='8'
                                    tablet='16'
                                    // only='computer'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Image
                                        src={LoginImage}
                                    />
                                </Grid.Column>
                                <Grid.Column computer='8' tablet='16'>
                                    <Segment
                                        style={{
                                            boxShadow: '0px 1px 40px #DCDCDC',
                                            borderRadius: 20,
                                            margin: 20,
                                        }}
                                    >
                                        <Form loading={loading} onSubmit={handleSubmit}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <h2 style={{ textAlign: 'center' }}>เข้าสู่ระบบ</h2>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <Form.Field error={username_error}>
                                                            <Input
                                                                label={
                                                                    <Label basic>
                                                                        <Icon name='user' fitted color='grey' />
                                                                    </Label>
                                                                }
                                                                fluid
                                                                name='username'
                                                                placeholder='อีเมล / หมายเลขโทรศัพท์'
                                                                value={username}
                                                                onChange={handleChangeUsername}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <Form.Field error={password_error}>
                                                            <Input
                                                                label={
                                                                    <Label basic>
                                                                        <Icon name='lock' fitted color='grey' />
                                                                    </Label>
                                                                }
                                                                fluid
                                                                icon={
                                                                    <Icon
                                                                        name={show_password ? 'eye slash' : 'eye'}
                                                                        link
                                                                        onClick={() => {
                                                                            setShowPassword(!show_password)
                                                                        }}
                                                                    />
                                                                }
                                                                type={show_password ? 'text' : 'password'}
                                                                name='password'
                                                                placeholder='รหัสผ่าน'
                                                                value={password}
                                                                onChange={handleChangePassword}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <Checkbox
                                                            label='จดจำรหัสผ่าน'
                                                            name='remember'
                                                            checked={remember}
                                                            onChange={() => {
                                                                setRemember(!remember)
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    {/* <Grid.Column computer='8'>
														<div
															style={{
																display: 'flex',
																justifyContent: 'flex-end',
																cursor: 'pointer'
															}}
														>
															<Icon
																name='lock'
																style={{ color: '#53A6D8', cursor: 'pointer' }}
															/>{' '}
															<label style={{ color: '#53A6D8', cursor: 'pointer' }}>
																ลืมรหัสผ่าน
															</label>
														</div>
													</Grid.Column> */}
                                                </Grid.Row>
                                                {error_text && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Message negative>{error_text}</Message>
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    color: 'white',
                                                                    backgroundColor: '#4D648D',
                                                                }}
                                                            >
                                                                เข้าสู่ระบบ
                                                            </Button>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='16' tablet='16'>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <label
                                                                style={{
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 600,
                                                                    fontSize: 10,
                                                                    textAlign: 'right',
                                                                    letterSpacing: -0.1,
                                                                    color: '#5B5B5B',
                                                                    flex: 'none',
                                                                    order: 0,
                                                                    alignSelf: 'center',
                                                                    margin: '15px 0px',
                                                                }}
                                                            >
                                                                Powered by
                                                            </label>
                                                            <Image src={Logo} style={{ maxWidth: 128, height: 64 }} />
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Form>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Container>
            </div>
        </Fragment>
    )
}

export default Login
