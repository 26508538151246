import React, { useState } from 'react'
import {
    Button,
    Container,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Message,
    Segment,
} from 'semantic-ui-react'
import IconOnePlatform from '../../../../../assets/images/logo one platform.png'
import { useHistory } from 'react-router-dom'
import '../styles.css'
import axios from 'axios'
import { APPLICATION_CHECK_USERNAME_REGISTER } from '../../../../../config/api/application'

const LineRegisterForgetPassword: React.FC = () => {
    let history = useHistory()
    let [username, setUsername] = useState<string>('')
    let [username_error, setUsernameError] = useState<boolean>(false)
    let [error_text, setErrorText] = useState<string>('')

    const handleChangeUsername = (_: any, { value }: InputOnChangeData) => {
        setUsername(value)
        setUsernameError(false)
        setErrorText(``)
    }

    const handleClickGoBack = () => {
        history.goBack()
    }

    const handleClickForgetUsername = () => {
        history.push('forget-username')
    }

    const handleClickGoNext = () => {
        if (username) {
            axios.get(`${APPLICATION_CHECK_USERNAME_REGISTER}?username=${username}`).then((response) => {
                let { value } = response.data
                if (!value.check_username) {
                    history.push('forget-password/recovery-methods', { username: username })
                } else {
                    setUsernameError(true)
                    setErrorText(`ไม่พบชื่อผู้ใข้งาน`)
                }
            })
        } else {
            setUsernameError(true)
        }
    }

    return (
        <div className='background-container-no-sidebar'>
            <Container>
                <Segment className='segment-login-page'>
                    <div>
                        <div className='header-segment-login-page'>
                            <div className='image-one-platform '>
                                <Image src={IconOnePlatform} />
                            </div>
                        </div>
                        <div className='body-segment-login-page'>
                            <Form>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column textAlign='center'>
                                            <h3>ลืมรหัสผ่าน</h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h5>ชื่อผู้ใช้งาน</h5>
                                            <Form.Field error={username_error}>
                                                <Input fluid value={username} onChange={handleChangeUsername} />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column textAlign='right'>
                                            <label className='label-link' onClick={handleClickForgetUsername}>
                                                ลืมชื่อผู้ใช้งาน
                                            </label>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {error_text && (
                                        <Grid.Row>
                                            <Grid.Column>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Message negative>{error_text}</Message>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </Form>
                        </div>
                        <div className='footer-segment-login-page'>
                            <Button floated='left' compact icon primary onClick={handleClickGoBack}>
                                <Icon name='chevron left' />
                            </Button>
                            <Button floated='right' compact icon primary onClick={handleClickGoNext}>
                                <Icon name='chevron right' />
                            </Button>
                        </div>
                    </div>
                </Segment>
            </Container>
        </div>
    )
}

export default LineRegisterForgetPassword
