import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

const ButtonIconOver = ({
    fluid = false,
    icon = '',
    label = '',
    onClick = (e, props) => {},
    color = '',
    basic = false,
}) => {
    // TODO: example <CustomButton icon='map marker alternate' label='แก้ไขแผนที่' color='#F26202' />
    return basic ? (
        <Button
            icon
            labelPosition='left'
            style={{
                backgroundColor: 'white',
                margin: '12px 10px',
                // color: 'white',
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                // textAlign: 'right'
            }}
            fluid={fluid}
            onClick={onClick}>
            <Icon
                name={icon}
                size='large'
                style={{
                    borderRadius: 50,
                    padding: 25,
                    top: -8,
                    left: -8,
                    backgroundColor: color,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    color: 'white',
                    opacity: 1,
                    width: 50,
                    height: 50,
                }}
            />
            <div style={{ paddingLeft: 30 }}>
                <label style={{ cursor: 'pointer' }}>{label}</label>
            </div>
        </Button>
    ) : (
        <Button
            icon
            labelPosition='left'
            style={{
                backgroundColor: color,
                margin: '12px 10px',
                color: 'white',
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                // textAlign: 'right'
            }}
            fluid={fluid}
            onClick={onClick}>
            <Icon
                name={icon}
                size='large'
                style={{
                    borderRadius: 50,
                    padding: 25,
                    top: -8,
                    left: -8,
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: color,
                    opacity: 1,
                    width: 50,
                    height: 50,
                }}
            />
            <div style={{ paddingLeft: 30 }}>
                <label style={{ cursor: 'pointer' }}>{label}</label>
            </div>
        </Button>
    );
};

export default ButtonIconOver;
