import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Container,
    Dropdown,
    DropdownProps,
    Form,
    Grid,
    Icon,
    Input,
    InputOnChangeData,
    Label,
    Pagination,
    Radio,
    Segment,
    Table,
    Transition,
} from 'semantic-ui-react'
import {
    DELETE_USER_MANUAL_DELETE_CONTENT,
    GET_USER_MANUAL_CONTENT_LIST,
    GET_USER_MANUAL_SUB_TOPIC_ALL,
    GET_USER_MANUAL_TOPIC_ALL,
    PUT_USER_MANUAL_ACTIVE_CONTENT,
} from '../../../../../config/api/userManual'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import UserManualContentModalForm from './ModalForm'

export interface InterfaceUserManualContent {
    active: boolean
    id: number
    name: string
    path_file: string
    path_file_name: string
    user_manual_sub_topics_id: number
    user_manual_sub_topics_name: string
    user_manual_topics_id: number
    user_manual_topics_name: string
}
const UserManualContent: React.FC = () => {
    let [open_modal, setOpenModal] = useState<boolean>(false)
    let [keyword, setKeyword] = useState<string>('')
    let [filter, setFilter] = useState<boolean>(false)
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [content, setContent] = useState<InterfaceUserManualContent[]>([])
    let [data_content, setDataContent] = useState<InterfaceUserManualContent | undefined>()
    let [topic_option, setTopicOption] = useState<[]>([])
    let [topic, setTopic] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [sub_topic_option, setSubTopicOption] = useState<[]>([])
    let [sub_topic, setSubTopic] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const getSubTopicAll = (topic_id: number) => {
        axiosAuthen.get(`${GET_USER_MANUAL_SUB_TOPIC_ALL}/${topic_id}`).then((response) => {
            let { value } = response.data
            let option = value.map((data: any) => ({ value: data.id, text: data.name }))
            setSubTopicOption(option)
        })
    }

    useEffect(() => {
        const getTopicAll = () => {
            axiosAuthen.get(GET_USER_MANUAL_TOPIC_ALL).then((response) => {
                let { value } = response.data
                let option = value.map((data: any) => ({ value: data.id, text: data.name }))
                setTopicOption(option)
            })
        }
        getTopicAll()
    }, [])

    const getContentList = useCallback(() => {
        axiosAuthen
            .get(
                `${GET_USER_MANUAL_CONTENT_LIST}?page=${current_page}&offset=${row_qty}&keyword=${keyword}&topic_id=${topic}`
            )
            .then((response) => {
                let { value } = response.data
                setRecordQty(value.count)
                setContent(value.user_manual_contents_list)
            })
    }, [current_page, row_qty, keyword, topic])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    useEffect(() => {
        getContentList()
    }, [getContentList])

    const handleChangeKeyword = (e: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    const handleClickFilter = () => {
        setFilter(!filter)
    }

    const handleClearFilter = () => {
        setFilter(false)
        setKeyword('')
        setTopic('')
    }

    const handleCloseModal = () => {
        setDataContent(undefined)
        getContentList()
        setOpenModal(false)
    }

    const handleAddSubTopic = () => {
        setOpenModal(true)
    }

    const handleChangeActiveContent = (id: number, active: boolean) => {
        axiosAuthen
            .put(PUT_USER_MANUAL_ACTIVE_CONTENT, {
                id: id,
                active: !active,
            })
            .then(() => {
                getContentList()
            })
    }

    const handleEditContent = (data: InterfaceUserManualContent) => {
        setDataContent(data)
        setOpenModal(true)
    }

    const handleDeleteContent = (id: number) => {
        axiosAuthen.delete(`${DELETE_USER_MANUAL_DELETE_CONTENT}/${id}`).then(() => {
            getContentList()
        })
    }

    const handleChangeTopic = (e: any, { value }: DropdownProps) => {
        setTopic(value)
        getSubTopicAll(Number(value))
    }

    const handleChangeSubTopic = (e: any, { value }: DropdownProps) => {
        setSubTopic(value)
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    {open_modal && (
                        <UserManualContentModalForm open={open_modal} onClose={handleCloseModal} data={data_content} />
                    )}
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section active>รายการเนื้อหาคู่มือการใช้งาน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>รายการเนื้อหาคู่มือการใช้งาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' tablet='5' mobile='8' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={handleAddSubTopic}
                                >
                                    <Icon name='plus' /> เพิ่มเนื้อหา
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='7' tablet='6' />
                            <Grid.Column computer='3' tablet='5' mobile='8' style={{ marginTop: 10 }}>
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        icon
                                        labelPosition='left'
                                        fluid
                                        style={{ backgroundColor: '#4D648D', color: 'white' }}
                                        onClick={handleClickFilter}
                                    >
                                        <Icon name='filter' /> กรองข้อมูล
                                    </Button>
                                    {keyword && (
                                        <Icon
                                            name='delete'
                                            style={{
                                                display: 'inline-flex',
                                                border: '1px solid rgb(182, 57, 45)',
                                                borderRadius: 10,
                                                height: 'auto',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgb(182, 57, 45)',
                                                zIndex: 1,
                                                position: 'absolute',
                                                right: 20,
                                                top: 7,
                                                paddingRight: 10,
                                                paddingLeft: 10,
                                                paddingBottom: 1,
                                                color: 'white',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleClearFilter}
                                        />
                                    )}
                                </div>
                            </Grid.Column>
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    icon='search'
                                    placeholder='ค้นหา'
                                    onChange={handleChangeKeyword}
                                    value={keyword}
                                />
                            </Grid.Column>
                            <Grid.Column computer='16' mobile='16'>
                                <Transition.Group animation='slide down' duration='500'>
                                    {filter && (
                                        <Segment style={{ marginTop: 15 }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='5'>
                                                        หัวข้อคู่มือ
                                                        <Dropdown
                                                            selection
                                                            fluid
                                                            value={topic}
                                                            options={topic_option}
                                                            onChange={handleChangeTopic}
                                                            clearable
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='5'>
                                                        หัวข้อย่อยคู่มือ
                                                        <Dropdown
                                                            disabled={!topic}
                                                            selection
                                                            fluid
                                                            value={sub_topic}
                                                            options={sub_topic_option}
                                                            onChange={handleChangeSubTopic}
                                                            clearable
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Button basic floated='right' onClick={handleClearFilter}>
                                                            ปิด
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    )}
                                </Transition.Group>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='4'>
                                                    หัวข้อ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='4'>
                                                    หัวข้อย่อย
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='4'>
                                                    เนื้อหา
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='2'>
                                                    สถานะ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='1' textAlign='center'>
                                                    จัดการ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    <Table attached celled className='table-body'>
                                        <Table.Body className='table-body body'>
                                            {content.map((data: InterfaceUserManualContent, index: number) => (
                                                <Table.Row key={index}>
                                                    <Table.Cell textAlign='center' width='1'>
                                                        <Label
                                                            circular
                                                            style={{
                                                                backgroundColor: '#425679',
                                                                color: '#FFFFFF',
                                                            }}
                                                        >
                                                            {row_qty * (current_page - 1) + index + 1}
                                                        </Label>
                                                    </Table.Cell>
                                                    <Table.Cell width='4'>{data.user_manual_topics_name}</Table.Cell>
                                                    <Table.Cell width='4'>
                                                        {data.user_manual_sub_topics_name}
                                                    </Table.Cell>
                                                    <Table.Cell width='4'>{data.name}</Table.Cell>
                                                    <Table.Cell width='2' textAlign='center'>
                                                        <p>สถานะ : {data.active ? 'เปิด' : 'ปิด'}</p>
                                                        <Radio
                                                            toggle
                                                            checked={data.active}
                                                            onChange={() =>
                                                                handleChangeActiveContent(data.id, data.active)
                                                            }
                                                        />
                                                    </Table.Cell>

                                                    <Table.Cell width='1' textAlign='center'>
                                                        <Dropdown icon='ellipsis vertical' pointing='right'>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleEditContent(data)}>
                                                                    <Icon
                                                                        name='edit'
                                                                        circular
                                                                        style={{
                                                                            color: '#F2CC6B',
                                                                            border: '1px solid #F2CC6B',
                                                                            boxShadow: 'none',
                                                                        }}
                                                                    />
                                                                    <label style={{ cursor: 'pointer' }}>แก้ไข</label>
                                                                </Dropdown.Item>
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item
                                                                    onClick={() => handleDeleteContent(data.id)}
                                                                >
                                                                    <Icon
                                                                        name='trash alternate outline'
                                                                        circular
                                                                        style={{
                                                                            color: '#9F3A38',
                                                                            border: '1px solid #9F3A38',
                                                                            boxShadow: 'none',
                                                                        }}
                                                                    />
                                                                    <label style={{ cursor: 'pointer' }}>ลบ</label>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                    paddingRight: 40,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column
                                            computer='11'
                                            tablet='16'
                                            mobile='16'
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default UserManualContent
