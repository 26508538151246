import React, { useEffect } from 'react';
import { Input } from 'semantic-ui-react';
import $ from 'jquery';
import './style.css';
import './script.js';
import './local.js';
const DatePicker = ({
    className = undefined,
    disabled = false,
    fluid = false,
    focus = undefined,
    icon = undefined,
    iconPosition = undefined,
    inverted = undefined,
    label = undefined,
    labelPosition = undefined,
    size = undefined,
    transparent = undefined,
    type = undefined,
    readOnly = false,
    // TODO: NEW VALUE
    onChange = (e, props) => {},
    id = undefined,
    name = '',
    placeholder = undefined,
    value = '',
    maxLength = undefined,
    minLength = undefined,
    startDate = '',
    endDate = '',
    showTodayBtn = true
}) => {
    useEffect(() => {
        let start_date = ''
        if (startDate) {
            start_date = startDate.split('/')
            start_date = `${start_date[1]}/${start_date[0]}/${String(Number(start_date[2]) - 543)}`
            start_date = new Date(start_date)
        }
        let end_date = ''
        if (endDate) {
            end_date = endDate.split('/')
            end_date = `${end_date[1]}/${end_date[0]}/${String(Number(end_date[2]) - 543)}`
            end_date = new Date(end_date)
        }
        $(`#${id ? id : name}`).datepicker('destroy')
        $(`#${id ? id : name}`)
            .datepicker({
                format: 'dd/mm/yyyy',
                todayBtn: showTodayBtn === true ? 'linked' : false,
                language: 'th',
                thaiyear: true,
                autoclose: true,
                todayHighlight: new Date() >= start_date ? true : false,
                // startDate: new Date()
            })
            .on('changeDate', (event) => {
                let error = false;
                let date = new Date(event.date);
                let value = `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${
                    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                }/${date.getFullYear() + 543}`;
                onChange && onChange(event, { name, value, error });
            });
            if (start_date) {
                $(`#${id ? id : name}`).datepicker('setStartDate', start_date)
            }
            if (end_date) {
                $(`#${id ? id : name}`).datepicker('setEndDate', end_date)
            }
    }, [id, name, onChange, startDate, endDate, showTodayBtn]);

    return (
        <Input
            disabled={disabled}
            id={id ? id : name}
            name={name}
            value={value}
            size={size}
            placeholder={placeholder}
            fluid={fluid}
            icon={icon}
            label={label}
            className={className}
            type={type === 'number' ? 'text' : type}
            transparent={transparent}
            labelPosition={labelPosition}
            iconPosition={iconPosition}
            inverted={inverted}
            focus={focus}
            maxLength={maxLength}
            minLength={minLength}
            readOnly={readOnly}>
            {label && label}
            <input id={name} type='text' className='datepicker' />
        </Input>
    );
};

export default DatePicker;
