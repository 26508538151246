import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
    Container,
    Dropdown,
    Grid,
    Input,
    Label,
    Pagination,
    Table,
    Breadcrumb,
    InputOnChangeData,
    Form,
    Button,
    Icon,
} from 'semantic-ui-react'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { useHistory, useLocation } from 'react-router'
import { DELETE_CONTACT_BY_ID, GET_CONTACT_LIST } from '../../../../../config/api/contacts'
import { alert } from '../../../../../components/alert'
import CreateContact from './ModalFormCreate'
import EditeContact from './ModalFormEdit'

export interface InterfaceContacts {
    id: number
    name: string
    municipalities_id: string
}

const ListContacts: React.FC = () => {
    let history = useHistory()
    let municipalities_id = localStorage.municipality_id
    let location = useLocation()
    let { state } = location
    if (state) {
        let location_state = JSON.parse(JSON.stringify(state, null, ' '))
        let { municipality_id } = location_state
        if (municipality_id) municipalities_id = municipality_id
    }
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [keyword, setKeyword] = useState<string>('')
    let [loading, setLoading] = useState<boolean>(false)
    let [open_modal, setOpenModal] = useState<boolean>(false)
    let [open_modal_edit, setOpenModalEdit] = useState<boolean>(false)
    let [contact_list, setContactList] = useState<InterfaceContacts[]>([])
    let [data_contact, setDataContant] = useState<InterfaceContacts>()
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    const handleCloseModalEdit = () => {
        setOpenModalEdit(false)
    }

    const handleEdit = (data: any) => {
        setDataContant(data)
        setOpenModalEdit(true)
    }

    const getContactList = useCallback(() => {
        setLoading(true)
        axiosAuthen
            .get(
                `${GET_CONTACT_LIST}?keyword=${keyword}&page=${current_page}&offset=${row_qty}&municipality_id=${municipalities_id}`
            )
            .then((response) => {
                let { value } = response.data
                setRecordQty(value.count)
                setContactList(value.contacts)
                setLoading(false)
            })
    }, [keyword, current_page, row_qty, municipalities_id])

    useEffect(() => {
        getContactList()
    }, [getContactList, open_modal, open_modal_edit])

    const handleAdd = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleClickDelete = (id: number) => {
        alert({
            type: 'warning',
            title: 'ลบช่องทางการติดต่อ',
            text: 'หากลบแล้วจะไม่สามารถนำข้อมูลกลับมาได้',
            confirmText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onConfirm: () => {
                axiosAuthen
                    .delete(`${DELETE_CONTACT_BY_ID}${id}`)
                    .then((response) => {
                        setLoading(true)
                    })
                    .then(() => {
                        alert({
                            type: 'success',
                            title: 'ลบข้อมูลเรียบร้อย',
                            timer: 1500,
                            onClosed: () => {
                                getContactList()
                            },
                        })
                    })
                    .catch((error) => {
                        if (error.response) {
                            alert({
                                type: 'error',
                                title: error.response.data.message,
                                cancelText: 'ปิด',
                                onClosed: () => {
                                    setLoading(false)
                                },
                            })
                        }
                    })
            },
        })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <CreateContact open={open_modal} onClose={handleCloseModal} />
                    {contact_list && (
                        <EditeContact open={open_modal_edit} onClose={handleCloseModalEdit} data={data_contact} />
                    )}
                    <Grid>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Breadcrumb>
                                    {localStorage.permission_en === 'super_admin' && (
                                        <Fragment>
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities`, {
                                                        municipality_id: municipalities_id,
                                                    })
                                                }}
                                            >
                                                รายการองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                        municipality_id: municipalities_id,
                                                    })
                                                }}
                                            >
                                                กำหนดการใช้งานองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </Fragment>
                                    )}
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/management/system`, {
                                                municipality_id: municipalities_id,
                                            })
                                        }}
                                    >
                                        จัดการระบบ
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>จัดการช่องทางรับเรื่อง</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>จัดการช่องทางรับเรื่อง</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={handleAdd}
                                >
                                    <Icon name='plus' /> เพิ่มช่องทางรับเรื่อง
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='10' only='computer' />
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    placeholder='ค้นหา'
                                    icon='search'
                                    name='keyword'
                                    value={keyword}
                                    onChange={handleChangeKeyword}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form loading={loading}>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='11' textAlign='center'>
                                                    ช่องทางการรับเรื่อง
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='4' textAlign='center'>
                                                    จัดการ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    <Table attached celled className='table-body'>
                                        <Table.Body className='table-body body'>
                                            {contact_list.length > 0 ? (
                                                contact_list.map((data: InterfaceContacts, index: number) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell textAlign='center' width='1'>
                                                            <Label
                                                                circular
                                                                style={{
                                                                    backgroundColor: '#425679',
                                                                    color: '#FFFFFF',
                                                                }}
                                                            >
                                                                {row_qty * (current_page - 1) + index + 1}
                                                            </Label>
                                                        </Table.Cell>
                                                        <Table.Cell width='11'>
                                                            <div>{data.name}</div>
                                                        </Table.Cell>
                                                        <Table.Cell width='4' textAlign='center'>
                                                            <Button
                                                                basic
                                                                style={{
                                                                    marginTop: 10,
                                                                }}
                                                                icon
                                                                color='yellow'
                                                                onClick={() => handleEdit(data)}
                                                            >
                                                                <Icon name='edit' /> แก้ไข
                                                            </Button>
                                                            <Button
                                                                basic
                                                                style={{
                                                                    marginTop: 10,
                                                                }}
                                                                icon
                                                                color='red'
                                                                onClick={() => handleClickDelete(data.id)}
                                                            >
                                                                <Icon name='trash alternate' /> ลบ
                                                            </Button>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))
                                            ) : (
                                                <Table.Row>
                                                    <Table.Cell textAlign='center' width='16'>
                                                        {'ไม่พบข้อมูล'}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column computer='11' style={{ display: 'flex' }} only='computer'>
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}
export default ListContacts
