import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Icon, Image, Menu } from 'semantic-ui-react'
import Logo from '../../assets/images/main page/logo ogs.png'
import { getAuthenMenuList } from '../../store/selectors'

const MenuListAdmin: React.FC = () => {
    let history = useHistory()
    let { menu_list } = useSelector(getAuthenMenuList)

    let { permission_en, municipality_id } = localStorage
    let menu = menu_list?.map((menu) => menu.name_en)
    let menu_management_municipality = menu_list?.find((menu_detail) => menu_detail.name_en === 'municipalities')
    return (
        <Fragment>
            <Menu.Item>
                <Menu.Header style={{ margin: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Image src={Logo} size='mini' style={{ marginTop: 5 }} />
                        <label style={{ flexGrow: 1, cursor: 'pointer' }}> {localStorage.permission_th}</label>
                    </div>
                </Menu.Header>
            </Menu.Item>
            <Menu.Item>
                <Menu.Header>ข้อมูลองค์กร</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name='management'
                        onClick={() => {
                            history.push(`/${permission_en}/management/municipality/detail`)
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name='building' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}>รายละเอียดองค์กร</label>
                        </div>
                    </Menu.Item>
                </Menu.Menu>
            </Menu.Item>
            {menu?.some((menu_detail) => menu_detail === 'users') && (
                <Menu.Item>
                    <Menu.Header>สมาชิก</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            name='municipality'
                            onClick={() => {
                                history.push(`/${permission_en}/management/users`, {
                                    municipality_id: municipality_id,
                                })
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Icon name='user' />
                                <label style={{ cursor: 'pointer', marginLeft: 5 }}> จัดการสมาชิก</label>
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            name='municipality'
                            onClick={() => {
                                history.push(`/${permission_en}/management/approve-users`, {
                                    municipality_id: municipality_id,
                                })
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Icon name='user plus' />
                                <label style={{ cursor: 'pointer', marginLeft: 5 }}> อนุมัติสมาชิก</label>
                            </div>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
            )}
            {menu?.some((menu_detail) => menu_detail === 'usage-role') && (
                <Menu.Item>
                    <Menu.Header>การเข้าถึงเว็บไซต์</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            name='consumer'
                            onClick={() => {
                                history.push(`/${permission_en}/management/usage-role`, {
                                    municipality_id: municipality_id,
                                })
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Icon name='list' />
                                <label style={{ cursor: 'pointer', marginLeft: 5 }}> กำหนดสิทธิ์การเข้าใช้งาน</label>
                            </div>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
            )}
            {menu?.some((menu_detail) => menu_detail === 'municipalities') && (
                <Menu.Item>
                    <Menu.Header>จัดการองค์กร</Menu.Header>
                    <Menu.Menu>
                        {menu_management_municipality?.pages?.some((page) => page.name_th === 'จัดการหน่วยงาน') && (
                            <Menu.Item
                                name='municipalities'
                                onClick={() => {
                                    history.push(`/${permission_en}/management/directory-of-municipality/list`, {
                                        municipality_id: municipality_id,
                                    })
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <Icon name='cogs' />
                                    <label style={{ cursor: 'pointer', marginLeft: 5 }}> จัดการหน่วยงาน</label>
                                </div>
                            </Menu.Item>
                        )}

                        {menu_management_municipality?.pages?.some((page) => page.name_th === 'จัดการระบบ') && (
                            <Menu.Item
                                name='management'
                                onClick={() => {
                                    history.push(`/${permission_en}/management/system`, {
                                        municipality_id: municipality_id,
                                    })
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <Icon name='cogs' />
                                    <label style={{ cursor: 'pointer', marginLeft: 5 }}>จัดการระบบ</label>
                                </div>
                            </Menu.Item>
                        )}
                        {menu_management_municipality?.pages?.some(
                            (page) => page.name_th === 'รายการสถานะที่ต้องผ่านการอนุมัติ'
                        ) && (
                            <Menu.Item
                                name='management'
                                onClick={() => {
                                    history.push(`/${permission_en}/management/status-approve`, {
                                        municipality_id: municipality_id,
                                    })
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <Icon name='cogs' />
                                    <label style={{ cursor: 'pointer', marginLeft: 5 }}>
                                        จัดการสถานะการอนุมัติการทำงาน
                                    </label>
                                </div>
                            </Menu.Item>
                        )}
                    </Menu.Menu>
                </Menu.Item>
            )}

            <Menu.Item>
                <Menu.Header>แจ้งปัญหา</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        as='a'
                        href='https://forms.gle/Ukk352JUPBMsQw7FA'
                        target={'_blank'}
                        rel='noopener noreferrer'
                        name='defect'
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name='question' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> แจ้งปัญหา</label>
                        </div>
                    </Menu.Item>
                </Menu.Menu>
            </Menu.Item>
        </Fragment>
    )
}

export default MenuListAdmin
