import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Dropdown,
    DropdownProps,
    Form,
    Grid,
    Input,
    InputOnChangeData,
    TextArea,
    TextAreaProps,
} from 'semantic-ui-react'
import CustomInputFile from '../../../../components/CustomInputFile'
import CustomSegment from '../../../../components/CustomSegment'
import DatePicker from '../../../../components/DatePicker'
import {
    GET_COMPLAINT_CHECK_ASSIGN_TECHNICIAN,
    GET_COMPLAINT_DEPARTMENT_NUMBER,
    GET_COMPLAINT_FISCAL_YEAR_ALL,
    GET_COMPLAINT_TECHNICIAN_MATCH_DIRECTORY,
} from '../../../../config/api/complaint'
import { GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE } from '../../../../config/api/directoryOfMunicipality'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import { setStateByNameAndValue } from '../../../../store/actions'
import { ComplaintFormStep1State } from '../../../../store/types/Complaint/Form/Step1'

type Step1Props = {
    data: ComplaintFormStep1State
    field: string[]
}

const Step1: React.FC<Step1Props> = (props) => {
    let dispatch = useDispatch()
    let { data, field } = props
    let {
        complaint_topic,
        complaint_type,
        complaint_subject,
        complaint_detail,
        contact,
        area,
        community,
        technician,
        file_complaint,
        complaint_no,
        central_complaint_no,
        year,
        complaint_date,
        directory,
        // children_directory,
        complaint_topic_option,
        complaint_type_option,
        list_complaint_type,
        contact_option,
        area_option,
        community_option,
        list_community,
        technician_option,
        directory_option,
        complaint_topic_error,
        complaint_type_error,
        complaint_subject_error,
        complaint_detail_error,
        technician_error,
        directory_error,
        file_complaint_error,
        central_complaint_no_error,
        complaint_date_error,
    } = data
    let [assign_technician, setAssignTechnician] = useState<boolean>(false)
    let [year_option, setYearOption] = useState<[]>([])

    useEffect(() => {
        const getAssignTechnician = () => {
            axiosAuthen.get(`${GET_COMPLAINT_CHECK_ASSIGN_TECHNICIAN}`).then((response) => {
                let { value } = response.data
                setAssignTechnician(value)
            })
        }
        const getFiscalYear = () => {
            axios.get(`${GET_COMPLAINT_FISCAL_YEAR_ALL}/${localStorage.municipality_id}`).then((response) => {
                let { value } = response.data
                let option = value.map((years: any) => ({ value: years.fiscal_year, text: years.fiscal_year }))
                if (!option.some((years: any) => years.value === year)) {
                    option.push({ value: year, text: year })
                }
                setYearOption(option)
            })
        }
        getFiscalYear()
        getAssignTechnician()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTechnician = (directory: string | number | true | (string | number | boolean)[]) => {
        axiosAuthen.get(`${GET_COMPLAINT_TECHNICIAN_MATCH_DIRECTORY}/${directory}`).then((response) => {
            let { value } = response.data
            let technician_option = value.map((data: any) => ({ value: data.id, text: data.full_name_th }))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'technician_option', technician_option))
            dispatch(
                setStateByNameAndValue(
                    'ComplaintFormStep1',
                    'technician',
                    technician_option[0] || { value: '', text: '' }
                )
            )
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'technician_error', false))
        })
    }

    useEffect(() => {
        const getDepartmentNo = () => {
            axiosAuthen.get(`${GET_COMPLAINT_DEPARTMENT_NUMBER}/${complaint_topic.value}/${year}`).then((response) => {
                let { value } = response.data
                dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_no', value.department_no))
            })
        }
        if (complaint_topic.value && year) getDepartmentNo()
    }, [complaint_topic.value, dispatch, year])

    const getDirectory = (directory_id: string | number | undefined, index: number) => {
        axiosAuthen
            .get(`${GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE}?directory_of_municipalities_id=${directory_id}`)
            .then((response) => {
                let { value } = response.data

                let options = value.map((data: any) => ({
                    value: data.id,
                    text: data.name,
                    level: data.level,
                }))
                let option_directory = directory_option.slice(0, index + 1)
                options.length > 0
                    ? dispatch(
                          setStateByNameAndValue('ComplaintFormStep1', `directory_option`, [
                              ...option_directory,
                              options,
                          ])
                      )
                    : dispatch(setStateByNameAndValue('ComplaintFormStep1', `directory_option`, [...option_directory]))
            })
    }

    const handleChangeTechnician = (event: any, { name, value }: DropdownProps) => {
        if (value) {
            let technician = technician_option.find((data: any) => data.value === value)
            dispatch(setStateByNameAndValue('ComplaintFormStep1', name, technician))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', `${name}_error`, false))
        } else {
            dispatch(setStateByNameAndValue('ComplaintFormStep1', name, value))
        }
    }

    const handleChangeInput = (event: any, { name, value }: InputOnChangeData) => {
        dispatch(setStateByNameAndValue('ComplaintFormStep1', name, value))
        dispatch(setStateByNameAndValue('ComplaintFormStep1', `${name}_error`, false))
    }

    const handleChangeComplaintDetail = (event: any, { name, value }: TextAreaProps) => {
        dispatch(setStateByNameAndValue('ComplaintFormStep1', name, value))
        dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_detail_error', false))
    }

    const handleChangeMultiFile = (event: any, { name, value, error }: any) => {
        if (value) {
            if (value.find((file: File) => file.size > 35000000)) {
                value.forEach((file: File, index: number) => {
                    if (file.size > 35000000) value.splice(index, 1)
                })
                // ?  console.log(`ขนาดไฟล์ต้องไม่เกิน 35 MB`)
            } else if (value.find((file: File) => !file.type.includes('image/'))) {
                value.forEach((file: File, index: number) => {
                    if (!file.type.includes('image/')) value.splice(index, 1)
                })
                // ?  console.log(`รองรับเฉพาะไฟล์รูปภาพหรือไฟล์ PDF เท่านั้น`)
            } else {
                dispatch(setStateByNameAndValue('ComplaintFormStep1', name, value))
                dispatch(setStateByNameAndValue('ComplaintFormStep1', `${name}_error`, false))
            }
        } else {
            dispatch(setStateByNameAndValue('ComplaintFormStep1', name, value))
        }
    }

    const handleChangeTopic = (event: any, { value }: DropdownProps) => {
        if (value) {
            let topic = complaint_topic_option.find((data: any) => data.value === value)
            let complaint_type_option = list_complaint_type.filter((data: any) => Number(data.parent) === value)

            // !central_complaint_no &&
            //     dispatch(setStateByNameAndValue('ComplaintFormStep1', 'central_complaint_no', topic?.short_name))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_topic', topic))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_type_option', complaint_type_option))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_topic_error', false))
        } else {
            let default_value = { value: '', text: '' }
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_topic', default_value))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_type_option', list_complaint_type))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_type', default_value))
        }
    }

    const handleChangeType = (event: any, { value }: DropdownProps) => {
        if (value) {
            let complaint_type = complaint_type_option.find((data) => data.value === value)
            let topic = complaint_topic_option.find((data: any) => data.value === complaint_type?.parent)

            // !central_complaint_no &&
            //     dispatch(setStateByNameAndValue('ComplaintFormStep1', 'central_complaint_no', topic?.short_name))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_topic', topic))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_type', complaint_type))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_topic_error', false))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_type_error', false))
        } else {
            let default_value = { value: '', text: '' }
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_type', default_value))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_type_error', false))
        }
    }

    const handleChangeArea = (event: any, { value }: DropdownProps) => {
        if (value) {
            let area = area_option.find((data: any) => data.value === value)
            let community_option = list_community.filter((data: any) => Number(data.parent) === value)
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'area', area))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'community_option', community_option))
        } else {
            let default_value = { value: '', text: '' }
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'area', default_value))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'community_option', list_community))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'community', default_value))
        }
    }

    const handleChangeCommunity = (event: any, { value }: DropdownProps) => {
        if (value) {
            let community = community_option.find((data) => data.value === value)
            let area = area_option.find((data: any) => data.value === community?.parent)
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'area', area))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'community', community))
        } else {
            let default_value = { value: '', text: '' }
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'community', default_value))
        }
    }

    const handleChangeContact = (event: any, { value }: DropdownProps) => {
        let contact
        if (value) {
            contact = contact_option.find((data: any) => data.value === value)
        } else {
            contact = { value: '', text: '' }
        }
        dispatch(setStateByNameAndValue('ComplaintFormStep1', 'contact', contact))
    }

    const handleChangeDirectory = (value: number, index: number) => {
        if (value) {
            let list_directory = directory.slice(0, index + 1)
            let select_directory = directory_option[index].find((data: any) => data.value === value)
            if (select_directory) {
                getTechnician(value)
                getDirectory(value, index)
                list_directory[index] = select_directory
                dispatch(setStateByNameAndValue('ComplaintFormStep1', 'directory', list_directory))
            }
        } else {
            let select_directory = directory.slice(0, index)
            let option_directory = directory_option.slice(0, index + 1)
            let last_select_directory = select_directory[select_directory.length - 1]
            last_select_directory
                ? getTechnician(last_select_directory.value)
                : dispatch(setStateByNameAndValue('ComplaintFormStep1', 'technician', { value: '', text: '' }))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'directory', select_directory))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'directory_option', [...option_directory]))
        }
        dispatch(setStateByNameAndValue('ComplaintFormStep1', 'directory_error', false))
    }

    const handleChangeYear = (event: any, { value }: DropdownProps) => {
        dispatch(setStateByNameAndValue('ComplaintFormStep1', 'year', value))
    }

    return (
        <Fragment>
            <Form>
                <CustomSegment icon='book' header='รายละเอียดคำร้อง'>
                    <Grid>
                        <Grid.Row>
                            {field.includes('topic and type') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='5' mobile='16'>
                                        <div style={{ marginTop: 20 }}>
                                            <label className='header-input-form'>เรื่องที่ยื่นคำร้อง</label>
                                            <Form.Field error={complaint_topic_error}>
                                                <Dropdown
                                                    fluid
                                                    clearable
                                                    selection
                                                    search
                                                    name='complaint_topic'
                                                    value={complaint_topic.value}
                                                    options={complaint_topic_option}
                                                    onChange={handleChangeTopic}
                                                />
                                            </Form.Field>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer='5' mobile='16'>
                                        <div style={{ marginTop: 20 }}>
                                            <label className='header-input-form'>ประเภทงาน</label>
                                            <Form.Field error={complaint_type_error}>
                                                <Dropdown
                                                    fluid
                                                    clearable
                                                    selection
                                                    search
                                                    name='complaint_type'
                                                    value={complaint_type.value}
                                                    options={complaint_type_option}
                                                    onChange={handleChangeType}
                                                />
                                            </Form.Field>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}

                            {field.includes('complaint subject') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10' mobile='16'>
                                        <div style={{ marginTop: 20 }}>
                                            <label className='header-input-form'>หัวข้อเรื่องร้องทุกข์</label>
                                            <Form.Field error={complaint_subject_error}>
                                                <Input
                                                    fluid
                                                    name='complaint_subject'
                                                    value={complaint_subject}
                                                    onChange={handleChangeInput}
                                                />
                                            </Form.Field>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}
                            {field.includes('area and community') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='5' mobile='16'>
                                        <div style={{ marginTop: 20 }}>
                                            <label className='header-input-form'>พื้นที่เขต</label>
                                            <Dropdown
                                                fluid
                                                clearable
                                                selection
                                                search
                                                name='area'
                                                value={area.value}
                                                options={area_option}
                                                onChange={handleChangeArea}
                                            />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer='5' mobile='16'>
                                        <div style={{ marginTop: 20 }}>
                                            <label className='header-input-form'>พื้นที่ชุมชน</label>
                                            <Dropdown
                                                fluid
                                                clearable
                                                selection
                                                search
                                                name='community'
                                                value={community.value}
                                                options={community_option}
                                                onChange={handleChangeCommunity}
                                            />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}

                            {field.includes('complaint detail') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10' mobile='16'>
                                        <div style={{ marginTop: 20 }}>
                                            <label className='header-input-form'>รายละเอียดเรื่องร้องทุกข์</label>
                                            <Form.Field error={complaint_detail_error}>
                                                <TextArea
                                                    name='complaint_detail'
                                                    value={complaint_detail}
                                                    onChange={handleChangeComplaintDetail}
                                                />
                                            </Form.Field>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}

                            {field.includes('contact') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10' mobile='16'>
                                        <div style={{ marginTop: 20 }}>
                                            <label className='header-input-form'>ช่องทางการรับเรื่อง</label>
                                            <Dropdown
                                                fluid
                                                clearable
                                                selection
                                                search
                                                name='contact'
                                                value={contact.value}
                                                options={contact_option}
                                                onChange={handleChangeContact}
                                            />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}

                            {field.includes('complaint file') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10' mobile='16'>
                                        <div style={{ marginTop: 20 }}>
                                            <label className='header-input-form'>เอกสารแนบ</label>
                                            <CustomInputFile
                                                labelText='เฉพาะสกุลไฟล์  *.jpg หรือ *.png'
                                                name='file_complaint'
                                                onChange={handleChangeMultiFile}
                                                multiple
                                                value={file_complaint}
                                                error={file_complaint_error}
                                                typeFile='image/png, image/jpeg'
                                            />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}
                        </Grid.Row>
                    </Grid>
                </CustomSegment>
                <CustomSegment icon='book' header='หน่วยงาน'>
                    <Grid>
                        <Grid.Row>
                            <Fragment>
                                <Grid.Column computer='3' only='computer' />
                                <Grid.Column computer='5' mobile='16'>
                                    <div>
                                        <label className='header-input-form'>เลขที่รับ</label>
                                        <Input fluid readOnly value={complaint_no} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column computer='5' mobile='16'>
                                    <div>
                                        <label className='header-input-form'>ปีงบประมาณ</label>
                                        <Dropdown
                                            selection
                                            options={year_option}
                                            fluid
                                            value={year}
                                            onChange={handleChangeYear}
                                        />
                                    </div>
                                </Grid.Column>
                                <Grid.Column computer='3' only='computer' />
                            </Fragment>
                            {field.includes('complaint no') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10' mobile='16'>
                                        <div style={{ marginTop: 20 }}>
                                            <Form.Field error={central_complaint_no_error}>
                                                <label className='header-input-form'>เลขที่รับจากส่วนกลาง</label>
                                                <Input
                                                    fluid
                                                    name='central_complaint_no'
                                                    value={central_complaint_no}
                                                    onChange={handleChangeInput}
                                                />
                                            </Form.Field>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}
                            {field.includes('complaint date') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10' mobile='16'>
                                        <div style={{ marginTop: 20 }}>
                                            <Form.Field error={complaint_date_error}>
                                                <label className='header-input-form'>
                                                    {' '}
                                                    วัน / เดือน / ปี ที่ได้รับแจ้ง
                                                </label>
                                                <DatePicker
                                                    fluid
                                                    name='complaint_date'
                                                    value={complaint_date}
                                                    onChange={handleChangeInput}
                                                />
                                            </Form.Field>
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}

                            {field.includes('directory') &&
                                directory_option.length > 0 &&
                                directory_option.map((options, index) => (
                                    <Fragment key={index}>
                                        <Grid.Column computer='3' only='computer' />
                                        <Grid.Column computer='10' mobile='16'>
                                            <div style={{ marginTop: 20 }}>
                                                <label className='header-input-form'>
                                                    หน่วยงาน{index > 0 && `ย่อยระดับที่ ${index}`}
                                                </label>
                                                <Form.Field error={directory_error}>
                                                    <Dropdown
                                                        fluid
                                                        selection
                                                        search
                                                        clearable
                                                        name='directory'
                                                        value={directory[index]?.value}
                                                        options={options}
                                                        onChange={(event: any, { value }: DropdownProps) =>
                                                            handleChangeDirectory(Number(value), index)
                                                        }
                                                    />
                                                </Form.Field>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column computer='3' only='computer' />
                                    </Fragment>
                                ))}

                            {(directory_option.length === 0 || assign_technician) && field.includes('technician') && (
                                <Fragment>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10' mobile='16'>
                                        <Form.Field>
                                            <div style={{ marginTop: 20 }}>
                                                <label className='header-input-form'>ผู้รับผิดชอบ</label>
                                                <Form.Field error={technician_error}>
                                                    <Dropdown
                                                        fluid
                                                        clearable
                                                        selection
                                                        search
                                                        name='technician'
                                                        value={technician.value}
                                                        options={technician_option}
                                                        onChange={handleChangeTechnician}
                                                    />
                                                </Form.Field>
                                            </div>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Fragment>
                            )}
                        </Grid.Row>
                    </Grid>
                </CustomSegment>
            </Form>
        </Fragment>
    )
}

export default Step1
