import React, { Fragment } from 'react'
import { Container, Divider, Grid, Header, Icon, Image, Segment } from 'semantic-ui-react'
import MainPageImage from '../../assets/images/main page/main page.png'

const MainPage: React.FC = () => {
    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='7' mobile='16' style={{ alignSelf: 'center' }}>
                                                <h1 style={{ color: '#283655' }}>Welcome</h1>
                                                <h2 style={{ color: '#283655' }}>ยินดีต้อนรับ</h2>
                                                <label>
                                                    ระบบจัดการคำร้องทุกข์สำหรับหน่วยงานภายในองค์กร
                                                    ที่แยกการทำงานทั้งผู้จัดการคำร้อง เจ้าหน้าที่ภาคสนาม
                                                    เจ้าหน้าที่ประจำหน่วยงาน พร้อมกับการติดตามผลงาน
                                                    การประเมินผลการปฏิบัติงานของบุคลากร
                                                </label>
                                            </Grid.Column>
                                            <Grid.Column computer='9' mobile='16'>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Image src={MainPageImage} size='large' />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: -15 }}>
                            <Grid.Column>
                                <Divider horizontal>
                                    <Header>
                                        <h1>ระบบ</h1>
                                    </Header>
                                </Divider>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid.Column computer='5' tablet='5' mobile='16'>
                                <Segment style={{ padding: '40px 60px', margin: `20px 10px` }}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: 60,
                                            height: 60,
                                            backgroundColor: '#293757',
                                            borderRadius: 50,
                                            textAlign: 'center',
                                            top: -33,
                                            left: '50%',
                                            marginLeft: -30,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#fff',
                                        }}
                                    >
                                        <div>
                                            <Icon name='cogs' size='large' />
                                        </div>
                                    </div>
                                    <h3 style={{ textAlign: 'center' }}>จัดการสะดวก</h3>
                                    <Divider />
                                    <div
                                        style={{
                                            minHeight: 40,
                                            marginTop: 10,
                                            textAlign: 'center',
                                        }}
                                    >
                                        ตอบรับ บันทึก ตรวจสอบคำร้อง ทำได้เสร็จบนแพลตฟอร์มสื่อสาร
                                    </div>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer='5' tablet='5' mobile='16'>
                                <Segment style={{ padding: '40px 60px', margin: `20px 10px` }}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: 60,
                                            height: 60,
                                            backgroundColor: '#293757',
                                            borderRadius: 50,
                                            textAlign: 'center',
                                            top: -33,
                                            left: '50%',
                                            marginLeft: -30,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#fff',
                                        }}
                                    >
                                        <div>
                                            <Icon name='computer' size='large' />
                                        </div>
                                    </div>
                                    <h3 style={{ textAlign: 'center' }}>สะดวกทุกสถานการณ์</h3>
                                    <Divider />
                                    <div
                                        style={{
                                            minHeight: 40,
                                            marginTop: 10,
                                            textAlign: 'center',
                                        }}
                                    >
                                        บันทึกผลการปฏิบัตงานได้ สะดวกทั้งนอกสถานที่ หรือหน้างาน
                                    </div>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer='5' tablet='5' mobile='16'>
                                <Segment style={{ padding: '40px 60px', margin: `20px 10px` }}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: 60,
                                            height: 60,
                                            backgroundColor: '#293757',
                                            borderRadius: 50,
                                            textAlign: 'center',
                                            top: -33,
                                            left: '50%',
                                            marginLeft: -30,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#fff',
                                        }}
                                    >
                                        <div>
                                            <Icon name='search' size='large' />
                                        </div>
                                    </div>
                                    <h3 style={{ textAlign: 'center' }}>ตรวจสอบการทำงาน</h3>
                                    <Divider />
                                    <div
                                        style={{
                                            minHeight: 40,
                                            marginTop: 10,
                                            textAlign: 'center',
                                        }}
                                    >
                                        ข้อมูลการปฏิบัติหน้าที่ และการประทับเวลาที่ตรวจสอบได้
                                    </div>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default MainPage
