import React, { useEffect, useState } from 'react'
import { Grid, Segment, Icon, Image, Container, Dimmer, Loader } from 'semantic-ui-react'
import { GET_DASHBOARD_COUNT_STATUS } from '../../../config/api/dashboard'
import { axiosAuthen } from '../../../config/axiosAuthen'
import IconTrack from '../../../../src/assets/images/track.png'
import IconProcess from '../../../../src/assets/images/process.png'
import { useHistory } from 'react-router-dom'
import store from '../../../store'
import { setStateByNameAndValue } from '../../../store/actions'

type FormProps = {
    year?: any
    start_date?: string
    end_date?: string
}

export interface InterfaceStatus {
    count: number
    percent: string
    status_en: string
    status_th: string
}

const MakeData = [
    {
        count: 0,
        percent: '0',
        status_en: 'Pending',
        status_th: 'รอรับเรื่อง',
    },
    {
        count: 0,
        percent: '0',
        status_en: 'FiscalYearPlan',
        status_th: 'เข้าแผนปีงบประมาณ',
    },
    {
        count: 0,
        percent: '0',
        status_en: 'Tracking',
        status_th: 'อยู่ระหว่างการติดตาม',
    },
    {
        count: 0,
        percent: '0',
        status_en: 'Doing',
        status_th: 'กำลังดำเนินการ',
    },
    {
        count: 0,
        percent: '0',
        status_en: 'Deadline',
        status_th: 'ใกล้ครบกำหนด',
    },
    {
        count: 0,
        percent: '0',
        status_en: 'Overdue',
        status_th: 'เกินกำหนด',
    },
    {
        count: 0,
        percent: '0',
        status_en: 'Unable',
        status_th: 'ไม่สามารถดำเนินการได้',
    },
    {
        count: 0,
        percent: '0',
        status_en: 'PendingEvaluation',
        status_th: 'รอประเมินผล',
    },
    {
        count: 0,
        percent: '0',
        status_en: 'Success',
        status_th: 'ประเมินผลเสร็จสิ้น',
    },
]

const DashboardAllStatus: React.FC<FormProps> = (props) => {
    let history = useHistory()
    let { year, start_date, end_date } = props
    let [loading, setLoading] = useState<boolean>(true)
    let [list_data, setListData] = useState<any[]>([])
    useEffect(() => {
        setLoading(true)
        axiosAuthen
            .get(
                `${GET_DASHBOARD_COUNT_STATUS}?year=${
                    year === 'all' ? '' : year
                }&start_date=${start_date}&end_date=${end_date}`
            )
            .then((response) => {
                let { value } = response.data
                setListData(value)
                setLoading(false)
            })
            .catch(() => {
                setListData(MakeData)
                setLoading(false)
            })
    }, [end_date, start_date, year])

    const getIcon = (status_name: string) => {
        let list_icon = [
            { status_name: 'คำร้องทุกข์ทั้งหมด', icon: <Icon name='th large' size='big' /> },
            { status_name: 'รอรับเรื่อง', icon: <Icon name='hourglass one' size='big' /> },
            { status_name: 'เข้าแผนปีงบประมาณ', icon: <Icon name='hourglass half' size='big' /> },
            { status_name: 'อยู่ระหว่างการติดตาม', icon: <Image src={IconTrack} style={{ height: 28 }} /> },
            { status_name: 'กำลังดำเนินการ', icon: <Image src={IconProcess} style={{ height: 28 }} /> },
            { status_name: 'เข้าแผนปีงบประมาณ', icon: <Icon name='hourglass half' size='big' /> },
            { status_name: 'ใกล้ครบกำหนด', icon: <Icon name='clock' size='big' /> },
            { status_name: 'เกินกำหนด', icon: <Icon name='calendar times' size='big' /> },
            { status_name: 'ไม่สามารถดำเนินการได้', icon: <Icon name='cancel' size='big' /> },
            { status_name: 'รอประเมินผล', icon: <Icon name='clipboard' size='big' /> },
            { status_name: 'ประเมินผลเสร็จสิ้น', icon: <Icon name='clipboard check' size='big' /> },
        ]
        let icon = list_icon.find((status) => status.status_name === status_name)
        if (!icon) {
            return 'None Icon'
        }
        return icon.icon
    }

    const getColor = (status_name: string) => {
        let list_color = [
            { status_name: 'คำร้องทุกข์ทั้งหมด', color: '#283655' },
            { status_name: 'รอรับเรื่อง', color: '#FBBD08' },
            { status_name: 'เข้าแผนปีงบประมาณ', color: '#A5673F' },
            { status_name: 'อยู่ระหว่างการติดตาม', color: '#2C2C2C' },
            { status_name: 'กำลังดำเนินการ', color: '#017ED9' },
            { status_name: 'ใกล้ครบกำหนด', color: '#F2711C' },
            { status_name: 'เกินกำหนด', color: '#B6392D' },
            { status_name: 'ไม่สามารถดำเนินการได้', color: '#767676' },
            { status_name: 'รอประเมินผล', color: '#B5CC18' },
            { status_name: 'ประเมินผลเสร็จสิ้น', color: '#70B982 ' },
        ]

        let color = list_color.find((status) => status.status_name === status_name)
        if (!color) {
            return '#E8E8E8'
        }
        return color.color
    }

    const handleClick = (status_th: string) => {
        store.dispatch(setStateByNameAndValue('ComplaintFilterList', 'status', status_th))
        history.push(`/${localStorage.permission_en}/complaint`)
    }

    if (loading) {
        return (
            <Segment style={{ height: 510 }}>
                <div>
                    <Container style={{ height: window.innerHeight }}>
                        <Dimmer active inverted>
                            <Loader size='huge' />
                        </Dimmer>
                    </Container>
                </div>
            </Segment>
        )
    } else {
        return (
            <Grid>
                <Grid.Row>
                    {list_data.map((data: InterfaceStatus, index: number) => (
                        <Grid.Column key={index} computer='4' tablet='8' mobile='16' style={{ marginTop: 40 }}>
                            <Segment
                                size='large'
                                style={{ boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.25)', cursor: 'pointer' }}
                                onClick={() => handleClick(data.status_th)}
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column computer='8'>
                                            <Segment
                                                style={{
                                                    marginTop: -40,
                                                    padding: 30,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    backgroundColor: getColor(data.status_th),
                                                    color: '#FFF',
                                                    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.25)',
                                                }}
                                            >
                                                {getIcon(data.status_th)}
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column computer='8' textAlign='right'>
                                            <h2 style={{ color: '#293757' }}>{data.count}</h2>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row textAlign='right'>
                                        <Grid.Column>
                                            <h5>{data.status_th}</h5>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    ))}
                </Grid.Row>
            </Grid>
        )
    }
}

export default DashboardAllStatus
