import { HOST } from '../router';

const MATCH_USERS_AND_TOPIC = `${HOST}/manage_topics_and_types`;

export const GET_MATCH_USERS_AND_TOPIC = `${MATCH_USERS_AND_TOPIC}/list_match_user_and_topic`;
export const GET_MATCH_USERS_AND_TOPIC_BY_USER = `${MATCH_USERS_AND_TOPIC}/data_match_user_and_topic_by_user`;

export const CREATE_MATCH_USERS_AND_TOPIC_BY_USER = `${MATCH_USERS_AND_TOPIC}/create_and_edit_match_user_and_topic`;
export const DELETE_MATCH_USERS_AND_TOPIC_BY_USER = `${MATCH_USERS_AND_TOPIC}/delete_match_user_and_topic`;

export const GET_DIRECTORY_MATCH_USERS_AND_TOPIC_BY_USER = `${MATCH_USERS_AND_TOPIC}/directory_match_topic_by_user`;




// /manage_topics_and_types/create_and_edit_match_user_and_topic เพิ่ม/ลบ/แก้ไข ผูกผุ้ใช้งานกับเรื่องร้องทุกข์
// ​/manage_topics_and_types​/data_match_user_and_topic_by_user​/ ดึงข้อมุลของผู้ใช้งานในการผูกงาน
// /manage_topics_and_types/delete_match_user_and_topic ลบผู้ใช้งานผูกกับเรื่องร้องทุกข์ตามไอดี
// /manage_topics_and_types/directory_match_topic_by_user ดึงข้อมูลส่วนงานผูกกับเรื่องร้องทุกข์ของผู้ใช้งาน
// /manage_topics_and_types/list_match_user_and_topic ดึงรายการข้อมูลผู้ใช้งานผูกกับเรื่องร้องทุกข์
// /manage_topics_and_types/list_match_user_and_topic/ ดึงข้อมุลรายการงานที่รับผิดชอบ

