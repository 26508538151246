import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Container, Form, Grid, Icon, Image, Input, InputOnChangeData, Segment } from 'semantic-ui-react'
import IconOnePlatform from '../../../../../assets/images/logo one platform.png'
import '../styles.css'
import axios from 'axios'
import { APPLICATION_GET_USERNAME_FROM_ID_CARD } from '../../../../../config/api/application'
const LineRegisterForgetUsername: React.FC = () => {
    let history = useHistory()
    let [id_card_number, setIdCardNumber] = useState<string>('')
    let [id_card_number_error, setIdCardNumberError] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(false)
    let [username, setUsername] = useState<string>('')
    let [username_error, setUsernameError] = useState<boolean>(false)
    let [username_selected, setUsernameSelected] = useState<string>('')

    const checkFormatIDCardNumber = () => {
        let id_card = id_card_number.replace(/-/g, '')
        let index = 0
        let summary_number = 0
        let error = false
        for (index; index < 12; index++) {
            summary_number += parseInt(id_card.charAt(index)) * (13 - index)
        }

        let mod = summary_number % 11
        let check = (11 - mod) % 10
        if (check !== parseInt(id_card.charAt(12))) {
            error = true
        }
        return error
    }

    const handleChangeIdCardNumber = (_: any, { value }: InputOnChangeData) => {
        value = value.replace(/-/g, '')
        let id_card_number = ''
        for (let position = 0; position < value.length; position++) {
            if (position === 1 || position === 5 || position === 10 || position === 12) {
                id_card_number += '-' + value.charAt(position)
            } else {
                id_card_number += value.charAt(position)
            }
        }
        if (!isNaN(Number(value))) {
            setIdCardNumber(id_card_number)
            setIdCardNumberError(false)
        }
    }

    const handleClickSearch = () => {
        if (id_card_number.length === 17 && !checkFormatIDCardNumber()) {
            // TODO: call api get username from id card number
            setLoading(true)
            axios
                .post(APPLICATION_GET_USERNAME_FROM_ID_CARD, {
                    id_card_number: id_card_number,
                })
                .then((response) => {
                    let { value } = response.data
                    setUsernameError(false)
                    setUsername(value.username)
                    setLoading(false)
                })
                .catch((error) => {
                    if (error.message) {
                        console.log(error.message)
                        setLoading(false)
                    }
                })
        } else {
            // TODO: set error
            setIdCardNumberError(true)
        }
    }

    const handleSelectUsername = () => {
        setUsernameSelected(username)
        setUsernameError(false)
    }

    const handleClickGoBack = () => {
        history.goBack()
    }

    const handleClickGoNext = () => {
        if (username_selected) {
            history.push('forget-password/recovery-methods', { username: username_selected })
        } else {
            setIdCardNumberError(true)
            setUsernameError(true)
        }
    }

    return (
        <div className='background-container-no-sidebar'>
            <Container>
                <Segment className='segment-login-page'>
                    <div>
                        <div className='header-segment-login-page'>
                            <div className='image-one-platform'>
                                <Image src={IconOnePlatform} />
                            </div>
                        </div>
                        <div className='body-segment-login-page'>
                            <Form>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column textAlign='center'>
                                            <h3>ลืมชื่อผู้ใช้งาน</h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h5>เลขประจำตัวประชาชน</h5>
                                            <Form.Field error={id_card_number_error}>
                                                <Input
                                                    fluid
                                                    value={id_card_number}
                                                    onChange={handleChangeIdCardNumber}
                                                    loading={loading}
                                                    icon={
                                                        <Icon
                                                            name='search'
                                                            link
                                                            circular
                                                            inverted
                                                            onClick={handleClickSearch}
                                                        />
                                                    }
                                                    maxLength={17}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {username && (
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Segment
                                                    className={
                                                        username_selected
                                                            ? 'segment-selected'
                                                            : username_error
                                                            ? 'segment-error'
                                                            : 'segment-unselected'
                                                    }
                                                    onClick={handleSelectUsername}
                                                >
                                                    <label style={{ fontWeight: 'bold' }}>ชื่อผู้ใช้งาน : </label>{' '}
                                                    <label>{username}</label>
                                                    {username_selected && (
                                                        <Icon style={{ float: 'right' }} name='check' color='green' />
                                                    )}
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </Form>
                        </div>
                        <div className='footer-segment-login-page'>
                            <Button floated='left' compact icon primary onClick={handleClickGoBack}>
                                <Icon name='chevron left' />
                            </Button>
                            <Button floated='right' compact icon primary onClick={handleClickGoNext}>
                                <Icon name='chevron right' />
                            </Button>
                        </div>
                    </div>
                </Segment>
            </Container>
        </div>
    )
}

export default LineRegisterForgetUsername
