import axios from 'axios'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Grid, Icon, Segment } from 'semantic-ui-react'
import { POST_NOTIFICATION_LINE_CONTRACT_MUNICIPALITY } from '../../../../config/api/notification'
import ImagePattern from '../../../../assets/images/pattern.png'
import CustomSegment from '../../../../components/CustomSegment'
import GoogleMaps from '../../../../components/GoogleMaps'

interface InterfaceContactMunicipality {
    logo: string
    name_en: string
    name_th: string
    detail: string
    email: string
    phone_number: string
    lat: string
    lng: string
    extend_email: string
    extend_phone_extension: string
    extend_phone_number: string
    phone_extension: string
}

const LineContactMunicipality: React.FC = () => {
    let location = useLocation()
    let [municipality, setMunicipality] = useState<string | null>('')
    let [contact, setContact] = useState<InterfaceContactMunicipality | undefined>()
    let [loading, setLoading] = useState<boolean>(false)

    const getContactMunicipality = useCallback(() => {
        axios.get(`${POST_NOTIFICATION_LINE_CONTRACT_MUNICIPALITY}/${municipality}`).then((response) => {
            let { value } = response.data
            setContact(value)
            setLoading(false)
        })
    }, [municipality])

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const municipality_id = query.get('municipality_id')
        setMunicipality(municipality_id)
        municipality && getContactMunicipality()
    }, [location, municipality, getContactMunicipality])

    return (
        <Fragment>
            <div
                style={{
                    backgroundRepeat: 'repeat',
                    backgroundImage: `url(${ImagePattern})`,
                    position: 'absolute',
                    height: 250,
                    zIndex: 1,
                    width: '100%',
                }}
            />
            <div
                style={{
                    backgroundImage: 'linear-gradient(#3D97A7, #1D3853)',
                    position: 'absolute',
                    height: 250,
                    width: '100%',
                }}
            />
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column tablet='16' mobile='16'>
                            <Segment loading={loading} style={{ marginTop: 20, zIndex: 5 }}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column mobile='16'>
                                            <h3>
                                                <Icon name='building' style={{ color: '#55ABB5' }} /> ข้อมูลองค์กร
                                            </h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>ชื่อองค์กร (ภาษาไทย)</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>{contact?.name_th}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>ชื่อองค์กร (ภาษาอังกฤษ)</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>{contact?.name_en}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='16'>
                                            <h3>
                                                <Icon name='call' style={{ color: '#55ABB5' }} /> ติดต่อองค์กร
                                            </h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>รายละเอียดที่ทำการองค์กร</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>{contact?.detail}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>เบอร์โทรศัพท์</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>
                                            <div>
                                                {contact?.phone_number}
                                                {contact?.phone_extension && ` ต่อ ${contact?.phone_extension}`}
                                            </div>
                                            {contact?.extend_phone_number && (
                                                <div>
                                                    ( หรือ {contact?.extend_phone_number}
                                                    {contact?.extend_phone_extension &&
                                                        ` ต่อ ${contact?.extend_phone_extension} `}
                                                    )
                                                </div>
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>อีเมล์</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>
                                            <div>{contact?.email}</div>
                                            {contact?.extend_email && <div>( หรือ {contact?.extend_email} )</div>}
                                        </Grid.Column>
                                    </Grid.Row>
                                    {contact?.lat && contact?.lng && (
                                        <Grid.Row>
                                            <Grid.Column>
                                                <CustomSegment header='ตำแหน่งที่ตั้ง' icon='map marker alternate'>
                                                    <GoogleMaps
                                                        style={{ widht: 420, height: 420 }}
                                                        value={{
                                                            lat: Number(contact?.lat),
                                                            lng: Number(contact?.lng),
                                                        }}
                                                    />
                                                </CustomSegment>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Fragment>
    )
}
export default LineContactMunicipality
