const currentFiscalYear = () => {
    let current_date = new Date()
    let current_month = current_date.getMonth() + 1
    let current_year = current_date.getFullYear() + 543
    if (current_month >= 10) {
        current_year += 1
    }
    return String(current_year)
}

export default currentFiscalYear
