import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import {
    Button,
    Checkbox,
    Dimmer,
    Dropdown,
    DropdownProps,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Loader,
    Modal,
    Radio,
    Segment,
    Transition,
} from 'semantic-ui-react'
import CustomSegment from '../../../../components/CustomSegment'
import getCroppedImg from '../../../../functions/CroppedImage'
import IconMan from '../../../../assets/images/man.png'
import IconWoman from '../../../../assets/images/woman.png'
import IconWoman3 from '../../../../assets/images/woman1.png'
import CustomDropdownDate from '../../../../components/CustomDropdownDate'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import { GET_ROLE_BY_MUNICIPALITY } from '../../../../config/api/roles'
import { GET_DIRECTORY_LIST_BY_MUNICIPALITY } from '../../../../config/api/directoryOfMunicipality'
import { useHistory, useLocation } from 'react-router-dom'
import { POST_REGISTER_LINE } from '../../../../config/api/user'
import { alert } from '../../../../components/alert'
import { getLIFF } from '../../../../config/liff'
import axios from 'axios'
import {
    APPLICATION_CHECK_EMAIL,
    APPLICATION_CHECK_ID_CARD,
    APPLICATION_CHECK_USERNAME_REGISTER,
    APPLICATION_GET_ACCOUNT_DETAIL_FROM_LOGIN,
    APPLICATION_GET_USERNAME_FROM_ID_CARD,
} from '../../../../config/api/application'
import CustomCircleCheckbox from '../../../../components/CustomCircleCheckbox'

const LineRegisterForm: React.FC = () => {
    let location = useLocation()
    const history = useHistory()
    let [account_id, setAccountId] = useState<string>('')
    let [username, setUsername] = useState<string | null>('')
    let [username_error, setUsernameError] = useState<boolean>(false)
    let [password, setPassword] = useState<string | null>('')
    let [confirm_password, setConfirmPassword] = useState<string>('')
    let [compare_password_error, setComparePasswordError] = useState<boolean>(false)
    let [email, setEmail] = useState<string>('')
    let [email_error, setEmailError] = useState<boolean>(false)
    let [phone_number, setPhoneNumber] = useState<string>('')
    let [phone_number_error, setPhoneNumberError] = useState<boolean>(false)
    let [show_modal_upload, setShowModalUpload] = useState<boolean>(false)
    let [show_upload_image, setShowUploadImage] = useState<boolean>(false)
    let [loading_image, setLoadingImage] = useState<boolean>(false)
    let [crop, setCrop] = useState({ x: 0, y: 0 })
    let [pixel_crop, setPixelCrop] = useState<object>({})
    let [dump_path, setDumpPath] = useState<string>()
    let [path_image, setPathImage] = useState<string>()
    let [photo_upload, setPhotoUpload] = useState()
    let zoom = 1
    let [profile_image, setProfileImage] = useState<any>()
    let [ssn, setSSN] = useState<string>('')
    let [ssn_error, setSSNError] = useState<boolean>(false)
    let [passport_number, setPassportNumber] = useState<string>('')
    let [passport_number_error, setPassportNumberError] = useState<boolean>(false)
    let [prefix_th, setPrefixTh] = useState<string>('')
    let [prefix_th_error, setPrefixThError] = useState<boolean>(false)
    let [first_name_th, setFirstNameTh] = useState<string>('')
    let [first_name_th_error, setFirstNameThError] = useState<boolean>(false)
    let [last_name_th, setLastNameTh] = useState<string>('')
    let [last_name_th_error, setLastNameThError] = useState<boolean>(false)
    let [birth_date, setBirthDate] = useState<string>('')
    let [birth_date_error, setBirthDateError] = useState<boolean>(false)
    let [municipality, setMunicipality] = useState<string | null>('')
    let [roles_id, setRolesId] = useState<number>()
    let [role_error, setRoleError] = useState<boolean>(false)
    let [position, setPosition] = useState<string>('')

    let [role_option, setRoleOption] = useState<{ value: string; text: string }[]>([])
    let [disabled_role, setDisabledRole] = useState<boolean>(true)
    let [liff, setLiff] = useState<any>()
    let [accept_term, setAcceptTerm] = useState<boolean>(false)
    let [show_terms, setShowTerms] = useState<boolean>(false)
    let [disabled_button_save, setDisabledButtonSave] = useState<boolean>(true)
    let [liff_id, setLiffId] = useState<string | null>('')
    let [loading, setLoading] = useState<boolean>(false)
    let [line_user_id, setLineUserId] = useState<string>('')
    let id_card_type = 'ID_CARD'
    let [directory, setDirectory] = useState<number[]>([])
    let [directory_option, setDirectoryOption] = useState<{ value: string; text: string }[][]>([[]])
    let [directory_error, setDirectoryError] = useState<boolean>(false)
    let [username_have_number, setUsernameHaveNumber] = useState<boolean>(false)
    let [username_least_six, setUsernameLeastSix] = useState<boolean>(false)
    let [username_have_special, setUsernameHaveSpecial] = useState<boolean>(false)
    let [username_duplicate, setUsernameDuplicate] = useState<boolean | undefined>(undefined)
    let [show_password, setShowPassword] = useState<boolean>(false)
    let [show_confirm_password, setShowConfirmPassword] = useState<boolean>(false)
    let [password_have_upper_case, setPasswordHaveUpperCase] = useState<boolean>(false)
    let [password_have_lower_case, setPasswordHaveLowerCase] = useState<boolean>(false)
    let [password_least_eight, setPasswordLeastEight] = useState<boolean>(false)
    let [password_have_number, setPasswordHaveNumber] = useState<boolean>(false)
    let [password_have_special, setPasswordHaveSpecial] = useState<boolean>(false)
    let [email_duplicate, setEmailDuplicate] = useState<boolean | undefined>(undefined)
    let [id_card_duplicate, setIDCardDuplicate] = useState<boolean | undefined>(undefined)
    let [matching_id_card, setMatchingIdCard] = useState<boolean | undefined>(undefined)
    let [id_card_one_id, setIDCardOneID] = useState<string>('')

    const getRoles = useCallback(() => {
        axiosAuthen.get(`${GET_ROLE_BY_MUNICIPALITY}/${municipality}`).then((response) => {
            let { value } = response.data
            let role_option = value.map((data: any) => ({ value: data.id, text: data.name }))
            setRoleOption(role_option)
        })
    }, [municipality])

    const getDirectory = useCallback(() => {
        axiosAuthen.get(`${GET_DIRECTORY_LIST_BY_MUNICIPALITY}/${municipality}`).then((response) => {
            let { value } = response.data
            let directory_option = value.map((data: any) => ({ value: data.id, text: data.name }))

            setDirectoryOption([directory_option])
        })
    }, [municipality])

    const getChildrenDirectory = (directory: number, index: number) => {
        if (directory)
            axios
                .get(`${GET_DIRECTORY_LIST_BY_MUNICIPALITY}/${municipality}?directory_id=${directory}`)
                .then((response) => {
                    let { value } = response.data
                    let options = value.map((data: any) => ({ value: data.id, text: data.name }))
                    let option_directory = directory_option.slice(0, index + 1)
                    options.length > 0
                        ? setDirectoryOption([...option_directory, options])
                        : setDirectoryOption([...option_directory])
                })
    }

    useEffect(() => {
        setDisabledRole(false)
        const query = new URLSearchParams(location.search)
        const municipality = query.get('municipality_id')
        const liff_id = query.get('liff_id')
        const username = query.get('username')
        const password = query.get('password')
        setMunicipality(municipality)
        setLiffId(liff_id)
        if (username && password) {
            setLoading(true)
            axios
                .post(APPLICATION_GET_ACCOUNT_DETAIL_FROM_LOGIN, { username: username, password: password })
                .then((response) => {
                    let { value } = response.data
                    setLoading(false)
                    setAccountId(value.id)
                    setUsername(username)
                    setEmail(value.email[0].email)
                    setPhoneNumber(value.mobile[0].mobile_no)
                    setPrefixTh(value.account_title_th)
                    setFirstNameTh(value.first_name_th)
                    setLastNameTh(value.last_name_th)
                    setPassword(password)
                    setIDCardOneID(value.id_card_num)
                    let split_birth_date = value.birth_date.split('-')
                    let birth_date = `${split_birth_date[2]}/${split_birth_date[1]}/${
                        Number(split_birth_date[0]) + 543
                    }`
                    setBirthDate(birth_date)
                })
                .catch((error) => {
                    setLoading(false)
                    history.goBack()
                })
        }
    }, [location, history])

    useEffect(() => {
        const connectLIFF = () => {
            setLoading(true)
            getLIFF().then((liff) => {
                liff.init({
                    liffId: liff_id,
                })
                    .then(() => {
                        if (liff.isLoggedIn()) {
                            liff.getProfile()
                                .then((profile: any) => {
                                    setLiff(liff)
                                    setLoading(false)
                                    setLineUserId(profile.userId)
                                    getFileProfile(profile.pictureUrl)
                                })
                                .catch((err: any) => {
                                    liff.login()
                                })
                        } else {
                            liff.login()
                        }
                    })
                    .catch((error: any) => {
                        liff.login()
                    })
            })
        }

        liff_id && connectLIFF()
    }, [liff_id])

    useEffect(() => {
        if (municipality) {
            getRoles()
            getDirectory()
        }
    }, [getDirectory, getRoles, municipality])

    const getFileProfile = async (path: any) => {
        await axiosAuthen
            .get(path, {
                responseType: 'arraybuffer',
            })
            .then((response) => {
                let data = response.data
                let blobFile = new Blob([data])
                let newFile = new File([blobFile], `image_profile`, {
                    type: `image/jpg`,
                })

                setProfileImage(newFile)
                setPathImage(URL.createObjectURL(newFile))
            })
            .catch(() => {})
    }

    useEffect(() => {
        setUsernameHaveNumber(false)
        setUsernameLeastSix(false)
        setUsernameHaveSpecial(false)
        if (String(username).match(/\d/)) {
            setUsernameHaveNumber(true)
        }
        if (String(username).length >= 6) {
            setUsernameLeastSix(true)
        }
        if (username && !username.match(/[^a-zA-Z\d]/)) {
            setUsernameHaveSpecial(true)
        }
    }, [username])

    const handleCheckUsername = () => {
        axios.get(`${APPLICATION_CHECK_USERNAME_REGISTER}?username=${username}`).then((response) => {
            let { value } = response.data
            setUsernameDuplicate(value.check_username)
        })
    }

    useEffect(() => {
        setPasswordHaveUpperCase(false)
        setPasswordHaveLowerCase(false)
        setPasswordHaveNumber(false)
        setPasswordLeastEight(false)
        setPasswordHaveSpecial(false)
        if (String(password).match(/[A-Z]/)) {
            setPasswordHaveUpperCase(true)
        }
        if (String(password).match(/[a-z]/)) {
            setPasswordHaveLowerCase(true)
        }
        if (String(password).match(/\d/)) {
            setPasswordHaveNumber(true)
        }
        if (String(password).length >= 8) {
            setPasswordLeastEight(true)
        }
        if (String(password).match(/[^a-zA-Z\d]/)) {
            setPasswordHaveSpecial(true)
        }
    }, [password])

    const handleComparePassword = () => {
        if (password && confirm_password) {
            if (password !== confirm_password) {
                setComparePasswordError(true)
            }
        }
    }

    const handleChangeUsername = (_: any, { value }: InputOnChangeData) => {
        setUsername(value)
        setUsernameError(false)
    }

    const handleChangePassword = (_: any, { value }: InputOnChangeData) => {
        setPassword(value)
        setComparePasswordError(false)
    }

    const handleChangeConfirmPassword = (_: any, { value }: InputOnChangeData) => {
        setConfirmPassword(value)
        setComparePasswordError(false)
    }

    useEffect(() => {
        setEmailDuplicate(undefined)
        const handleCheckEmail = () => {
            if (email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i))
                axios.get(`${APPLICATION_CHECK_EMAIL}?email=${email}`).then((response) => {
                    let { value } = response.data
                    setEmailDuplicate(value.check_email)
                })
        }
        handleCheckEmail()
    }, [email])

    const handleChangeEmail = (_: any, { value }: InputOnChangeData) => {
        setEmail(value)
        setEmailError(false)
    }

    const handleChangePhoneNumber = (event: any, { name, value }: InputOnChangeData) => {
        if (!isNaN(Number(value))) {
            setPhoneNumber(value)
            setPhoneNumberError(false)
        }
    }

    const closeModal = () => {
        setPathImage(dump_path)
        setShowModalUpload(false)
    }

    const onCropComplete = (_: any, cropped_area_pixels: any) => {
        setPixelCrop(cropped_area_pixels)
    }

    const onCropChange = (value: any) => {
        setCrop(value)
    }

    const selectImage = async () => {
        setLoadingImage(true)
        let new_image = await getCroppedImg(photo_upload, pixel_crop)
        setPathImage(URL.createObjectURL(new_image))
        setLoadingImage(false)
        setShowModalUpload(false)
        setProfileImage(new_image)
    }

    const handleClickUpload = () => {
        document.getElementById('file')!.click()
    }

    const handleClickUploadComputer = () => {
        document.getElementById('file_on_computer')!.click()
    }

    const handleChangeFile = (e: any) => {
        let file = e.target.files[0]
        if (file) {
            setPhotoUpload(file)
            setPathImage(URL.createObjectURL(file))
            setShowModalUpload(true)
            setDumpPath(path_image)
        }
    }

    const checkFormatIDCardNumber = useCallback(() => {
        let id_card = ssn.replace(/-/g, '')
        let index = 0
        let summary_number = 0
        let error = false
        for (index; index < 12; index++) {
            summary_number += parseInt(id_card.charAt(index)) * (13 - index)
        }

        let mod = summary_number % 11
        let check = (11 - mod) % 10
        if (check !== parseInt(id_card.charAt(12))) {
            error = true
        }
        return error
    }, [ssn])

    useEffect(() => {
        const handleCheckIDCardNumber = () => {
            setIDCardDuplicate(undefined)
            if (!checkFormatIDCardNumber() && ssn.length === 17) {
                axios.get(`${APPLICATION_CHECK_ID_CARD}?id_card_number=${ssn}`).then((response) => {
                    let { value } = response.data
                    setIDCardDuplicate(value.check_id_card)
                })
            }
        }
        const getUsernameFromIDCard = () => {
            setMatchingIdCard(id_card_one_id.substring(6) === ssn.replaceAll('-', '').substring(6))

            if (
                !checkFormatIDCardNumber() &&
                ssn.length === 17 &&
                id_card_one_id.substring(6) === ssn.replaceAll('-', '').substring(6)
            )
                axios
                    .post(APPLICATION_GET_USERNAME_FROM_ID_CARD, {
                        id_card_number: ssn,
                    })
                    .then((response) => {
                        let { value } = response.data
                        setUsername(value.username)
                    })
        }
        account_id ? getUsernameFromIDCard() : handleCheckIDCardNumber()
    }, [account_id, checkFormatIDCardNumber, id_card_one_id, ssn])

    const handleChangeSSN = (_: any, { name, value }: InputOnChangeData) => {
        value = value.replace(/-/g, '')
        let ssn = ''
        for (let position = 0; position < value.length; position++) {
            if (position === 1 || position === 5 || position === 10 || position === 12) {
                ssn += '-' + value.charAt(position)
            } else {
                ssn += value.charAt(position)
            }
        }
        if (!isNaN(Number(value))) {
            setSSN(ssn)
            setSSNError(false)
            checkFormatIDCardNumber()
        }
    }

    const handleChangePassportNumber = (_: any, { name, value }: InputOnChangeData) => {
        setPassportNumber(value)
        setPassportNumberError(false)
    }
    const handleChangeFirstNameTh = (_: any, { name, value }: InputOnChangeData) => {
        setFirstNameTh(value)
        setFirstNameThError(false)
    }
    const handleChangeLastNameTh = (_: any, { name, value }: InputOnChangeData) => {
        setLastNameTh(value)
        setLastNameThError(false)
    }

    const handleChangePrefix = (prefix_th: string) => {
        setPrefixTh(prefix_th)
        setPrefixThError(false)
    }

    const handleChangeDate = (e: any, { name, value }: any) => {
        setBirthDate(value)
        setBirthDateError(false)
    }

    const handleChangeDirectory = (value: number, index: number) => {
        if (value) {
            let select_directory = directory.slice(0, index + 1)
            select_directory[index] = value
            setDirectoryError(false)
            setDirectory(select_directory)
            getChildrenDirectory(Number(value), index)
        } else {
            let select_directory = directory.slice(0, index)

            setDirectory(select_directory)
            let option_directory = directory_option.slice(0, index + 1)

            setDirectoryOption([...option_directory])
        }
    }

    const handleChangeRole = (_: any, { name, value }: DropdownProps) => {
        setRolesId(Number(value))
        setRoleError(false)
    }

    const handleChangePosition = (_: any, { name, value }: InputOnChangeData) => {
        setPosition(value)
        // setPositionError(false)
    }

    const handleChangeAcceptTerm = () => {
        setAcceptTerm(!accept_term)
        setDisabledButtonSave(!disabled_button_save)
    }

    const handleChangeShowTerms = () => {
        setShowTerms(!show_terms)
    }

    const validateForm = () => {
        let error = false

        if (!roles_id) {
            error = true
            setRoleError(true)
            document.getElementById('roles_id')?.click()
        }

        if (!directory[directory.length - 1]) {
            error = true
            setDirectoryError(true)
        }

        if (!birth_date) {
            error = true
            setBirthDateError(true)
            document.getElementById('birth_date')?.focus()
        }

        if (!last_name_th || last_name_th.match(/[^\u0E00-\u0E7F]/)) {
            error = true
            setLastNameThError(true)
            document.getElementById('last_name_th')?.focus()
        }

        if (!first_name_th || first_name_th.match(/[^\u0E00-\u0E7F]/)) {
            error = true
            setFirstNameThError(true)
            document.getElementById('first_name_th')?.focus()
        }

        if (!prefix_th) {
            setPrefixThError(true)
            error = true
            document.getElementById('prefix_th')?.focus()
        }
        if (id_card_type === 'ID_CARD') {
            if (ssn === '') {
                error = true
                setSSNError(true)
                document.getElementById('ssn')?.focus()
            } else {
                if (checkFormatIDCardNumber()) {
                    error = true
                    setSSNError(true)
                    document.getElementById('ssn')?.focus()
                }
            }
        }
        if (id_card_type === 'PASSPORT') {
            if (passport_number === '') {
                error = true
                setPassportNumberError(true)
                document.getElementById('passport_number')?.focus()
            }
        }

        if (!phone_number || phone_number.length < 9) {
            error = true
            setPhoneNumberError(true)
            document.getElementById('phone_number')?.focus()
        }

        if (!line_user_id) {
            error = true
            setLoading(false)
            document.getElementById('line_user_id')?.focus()
        }

        if (!email || !email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
            error = true
            setEmailError(true)
            document.getElementById('email')?.focus()
        }
        if (!account_id && compare_password_error) {
            error = true
            document.getElementById('password')?.focus()
        }
        if (
            !account_id &&
            (!password ||
                !confirm_password ||
                !password_have_upper_case ||
                !password_have_lower_case ||
                !password_have_number ||
                !password_least_eight)
        ) {
            error = true
            setComparePasswordError(true)
            document.getElementById('password')?.focus()
        }
        if (
            !account_id &&
            (!username || !username_have_number || !username_have_special || !username_least_six || !username_duplicate)
        ) {
            error = true
            setUsernameError(true)
            document.getElementById('username')?.focus()
        }
        return error
    }

    const handleSubmit = () => {
        setLoading(true)
        if (!validateForm()) {
            let form_data = new FormData()
            form_data.append('account_id', account_id)
            form_data.append('username', String(username))
            form_data.append('password', String(password))
            form_data.append('prefix_th', prefix_th)
            form_data.append('first_name_th', first_name_th)
            form_data.append('last_name_th', last_name_th)
            form_data.append('id_card_type', id_card_type)
            form_data.append('id_card_number', ssn)
            form_data.append('email', email)
            form_data.append('phone_number', phone_number)
            form_data.append('birth_date', birth_date)
            form_data.append('directory_of_municipalities_id', String(directory[directory.length - 1]))
            form_data.append('roles_id', String(roles_id))
            form_data.append('position', position)
            form_data.append('municipalities_id', String(municipality))
            form_data.append('line_user_id', line_user_id)
            form_data.append('image', profile_image)

            axiosAuthen
                .post(POST_REGISTER_LINE, form_data)
                .then((response) => {
                    alert({
                        type: 'success',
                        title: 'บันทึกเสร็จสิ้น',
                        timer: 1500,
                        onClosed: () => {
                            setLoading(false)
                            if (line_user_id) {
                                liff.closeWindow()
                            }
                        },
                    })
                })
                .catch((error) => {
                    setLoading(false)
                    if (error.response) {
                        alert({
                            type: 'error',
                            title: error.response.data.message,
                            cancelText: 'ปิด',
                        })
                    }
                })
        } else {
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <Dimmer active inverted>
                <Loader inverted>รอสักครู่</Loader>
            </Dimmer>
        )
    } else {
        return (
            <Fragment>
                <Form loading={loading}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <h1>ลงทะเบียน</h1>
                    </div>

                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='1' only='computer' />
                            <Grid.Column computer='14' mobile='16'>
                                <CustomSegment icon='key' header='ผู้ใช้งาน'>
                                    <Grid>
                                        {!Boolean(account_id) && (
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>ชื่อผู้ใช้งาน</label>
                                                    <Form.Field error={username_error}>
                                                        <Input
                                                            readOnly={Boolean(account_id)}
                                                            fluid
                                                            name='username'
                                                            id='username'
                                                            value={username}
                                                            onChange={handleChangeUsername}
                                                            onBlur={handleCheckUsername}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <div style={{ display: 'flex', margin: 5 }}>
                                                        <div style={{ paddingRight: 15, textDecoration: 'underline' }}>
                                                            เงื่อนไข
                                                        </div>
                                                        <div>
                                                            <CustomCircleCheckbox
                                                                label='กรอกตัวเลข'
                                                                checked={username_have_number}
                                                            />
                                                            <CustomCircleCheckbox
                                                                label='รวมกันอย่างน้อย 6 ตัว'
                                                                checked={username_least_six}
                                                            />
                                                            <CustomCircleCheckbox
                                                                label='ห้ามกรอกอักขระพิเศษ'
                                                                checked={username_have_special}
                                                            />
                                                            <CustomCircleCheckbox
                                                                label='ห้ามซ้ำกับ Username ที่มีอยู่ใน One ID'
                                                                checked={username_duplicate}
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                        )}
                                        {!Boolean(account_id) && (
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>รหัสผ่าน</label>
                                                    <Form.Field error={compare_password_error}>
                                                        <Input
                                                            fluid
                                                            name='password'
                                                            id='password'
                                                            value={password}
                                                            onChange={handleChangePassword}
                                                            onBlur={handleComparePassword}
                                                            icon={
                                                                <Icon
                                                                    name={show_password ? 'eye slash' : 'eye'}
                                                                    link
                                                                    onClick={() => {
                                                                        setShowPassword(!show_password)
                                                                    }}
                                                                />
                                                            }
                                                            type={show_password ? 'text' : 'password'}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <div style={{ display: 'flex', margin: 5 }}>
                                                        <div style={{ paddingRight: 15, textDecoration: 'underline' }}>
                                                            เงื่อนไข
                                                        </div>
                                                        <div>
                                                            <CustomCircleCheckbox
                                                                label='กรอกตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ อย่างน้อย 1 ตัว'
                                                                checked={password_have_upper_case}
                                                            />
                                                            <CustomCircleCheckbox
                                                                label='กรอกตัวอักษรภาษาอังกฤษพิมพ์เล็ก อย่างน้อย 1 ตัว'
                                                                checked={password_have_lower_case}
                                                            />
                                                            <CustomCircleCheckbox
                                                                label='กรอกตัวเลข อย่างน้อย 1 ตัว'
                                                                checked={password_have_number}
                                                            />
                                                            <CustomCircleCheckbox
                                                                label=' รวมกันอย่างน้อย 8 ตัว'
                                                                checked={password_least_eight}
                                                            />
                                                            <CustomCircleCheckbox
                                                                label='กรอกอักขระพิเศษได้'
                                                                checked={password_have_special}
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                        )}
                                        {!Boolean(account_id) && (
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>ยืนยันรหัสผ่าน</label>
                                                    <Form.Field error={compare_password_error}>
                                                        <Input
                                                            fluid
                                                            name='confirm_password'
                                                            id='confirm_password'
                                                            value={confirm_password}
                                                            onChange={handleChangeConfirmPassword}
                                                            onBlur={handleComparePassword}
                                                            icon={
                                                                <Icon
                                                                    name={show_confirm_password ? 'eye slash' : 'eye'}
                                                                    link
                                                                    onClick={() => {
                                                                        setShowConfirmPassword(!show_confirm_password)
                                                                    }}
                                                                />
                                                            }
                                                            type={show_confirm_password ? 'text' : 'password'}
                                                        />
                                                    </Form.Field>
                                                    {compare_password_error && (
                                                        <Fragment>
                                                            <Icon name='delete' color='red' /> รหัสผ่านไม่ตรงกัน
                                                        </Fragment>
                                                    )}
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                        )}
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <label className='header-input-form'>อีเมล</label>
                                                <Form.Field error={email_error}>
                                                    <Input
                                                        readOnly={Boolean(account_id)}
                                                        fluid
                                                        name='email'
                                                        id='email'
                                                        value={email}
                                                        onChange={handleChangeEmail}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                            {!Boolean(account_id) && (
                                                <Fragment>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='10' mobile='16'>
                                                        <div style={{ display: 'flex', margin: 5 }}>
                                                            <div
                                                                style={{
                                                                    paddingRight: 15,
                                                                    textDecoration: 'underline',
                                                                }}
                                                            >
                                                                เงื่อนไข
                                                            </div>
                                                            <CustomCircleCheckbox
                                                                label='ห้ามซ้ำกับอีเมลที่มีอยู่ใน One ID'
                                                                checked={email_duplicate}
                                                            />
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Fragment>
                                            )}
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <label className='header-input-form'>หมายเลขโทรศัพท์</label>
                                                <Form.Field error={phone_number_error}>
                                                    <Input
                                                        readOnly={Boolean(account_id)}
                                                        fluid
                                                        name='phone_number'
                                                        maxLength={10}
                                                        id='phone_number'
                                                        value={phone_number}
                                                        type='tel'
                                                        onChange={handleChangePhoneNumber}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                            {!Boolean(account_id) && (
                                                <Fragment>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='10' mobile='16'>
                                                        <div style={{ display: 'flex', margin: 5, color: '#BEC3CC' }}>
                                                            <div
                                                                style={{
                                                                    paddingRight: 15,
                                                                    textDecoration: 'underline',
                                                                }}
                                                            >
                                                                เงื่อนไข
                                                            </div>
                                                            <div>ต้องเป็นหมายเลขโทรศัพท์ที่สามารถยืนยันตัวตนได้</div>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Fragment>
                                            )}
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column computer='1' only='computer' />
                            <Grid.Column computer='14' mobile='16'>
                                <CustomSegment icon='user circle' header='ข้อมูลส่วนตัว'>
                                    <h4 style={{ textAlign: 'center', color: '#9F3A38' }}>
                                        ** ข้อมูลในส่วนต่อไปนี้ไม่สามารถแก้ไขในภายหลังได้
                                        โปรดตรวจสอบความถูกต้องก่อนบันทึก **
                                    </h4>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <label className='header-input-form'>รูปประจำตัว</label>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Modal
                                                size='small'
                                                open={show_modal_upload}
                                                onClose={closeModal}
                                                closeOnEscape={false}
                                                closeOnDimmerClick={false}
                                            >
                                                <Modal.Header
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: '50%',
                                                        width: ' 100%',
                                                        transform: 'translateX(-50%)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    อัปโหลดรูปภาพ
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Segment
                                                        loading={loading_image}
                                                        style={{ padding: 0, marginTop: 40, paddingBottom: 30 }}
                                                    >
                                                        <div style={{ height: 600 }}>
                                                            <Cropper
                                                                image={path_image}
                                                                crop={crop}
                                                                zoom={zoom}
                                                                aspect={3 / 3}
                                                                // style={{ height: 300 }}
                                                                onCropChange={onCropChange}
                                                                onCropComplete={onCropComplete}
                                                            />
                                                        </div>
                                                    </Segment>
                                                </Modal.Content>
                                                <Modal.Actions
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        left: '50%',
                                                        width: '100%',
                                                        transform: 'translateX(-50%)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                        padding: 0,
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    <div style={{ padding: 20 }}>
                                                        <Button
                                                            color='green'
                                                            onClick={selectImage}
                                                            disabled={loading_image}
                                                        >
                                                            บันทึก
                                                        </Button>
                                                        <Button
                                                            color='red'
                                                            onClick={closeModal}
                                                            disabled={loading_image}
                                                        >
                                                            ยกเลิก
                                                        </Button>
                                                    </div>
                                                </Modal.Actions>
                                            </Modal>

                                            <Grid.Column computer='10' only='computer'>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'flex-end',
                                                        width: 'fit-content',
                                                    }}
                                                    onMouseOver={() => {
                                                        setShowUploadImage(true)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setShowUploadImage(false)
                                                    }}
                                                >
                                                    <div style={{ justifyContent: 'center' }}>
                                                        <Image
                                                            value={profile_image}
                                                            bordered
                                                            onMouseOver={() => {
                                                                setShowUploadImage(true)
                                                            }}
                                                            style={{
                                                                width: 150,
                                                                height: 150,
                                                                borderRadius: 500,
                                                            }}
                                                            src={
                                                                path_image
                                                                    ? path_image
                                                                    : 'https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png'
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            width: 150,
                                                            opacity: 0.7,
                                                        }}
                                                    >
                                                        <Transition
                                                            visible={show_upload_image}
                                                            animation='slide up'
                                                            duration={200}
                                                        >
                                                            <div
                                                                style={{
                                                                    height: 70,
                                                                    backgroundColor: 'black',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '0px 0px 1000px 1000px',
                                                                }}
                                                                onClick={handleClickUploadComputer}
                                                            >
                                                                <div
                                                                    style={{
                                                                        justifyContent: 'center',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        height: '100%',
                                                                        flexDirection: 'column',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name='camera'
                                                                            size='big'
                                                                            style={{ color: 'white' }}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            marginTop: 10,
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                            // paddingBottom: 20
                                                                        }}
                                                                    >
                                                                        <label
                                                                            style={{
                                                                                color: 'white',
                                                                                cursor: 'pointer',
                                                                                paddingBottom: 5,
                                                                            }}
                                                                        >
                                                                            อัปโหลดรูปภาพ
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Transition>
                                                    </div>
                                                    <Input>
                                                        <input
                                                            type='file'
                                                            id='file_on_computer'
                                                            hidden
                                                            onChange={handleChangeFile}
                                                            accept='image/png, image/jpeg, image/jpg'
                                                        />
                                                    </Input>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column tablet='10' mobile='16' only='tablet mobile'>
                                                <Segment
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        zIndex: 1,
                                                        marginTop: 30,
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <div style={{ justifyContent: 'center' }}>
                                                            <Image
                                                                bordered
                                                                onMouseOver={() => {
                                                                    setShowUploadImage(true)
                                                                }}
                                                                style={{
                                                                    width: 150,
                                                                    height: 150,
                                                                    borderRadius: 500,
                                                                }}
                                                                src={
                                                                    path_image
                                                                        ? path_image
                                                                        : 'https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png'
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 150,
                                                                opacity: 0.7,
                                                                marginBottom: 5,
                                                            }}
                                                            onMouseOver={() => {
                                                                setShowUploadImage(true)
                                                            }}
                                                        >
                                                            <Transition
                                                                visible={show_upload_image}
                                                                animation='slide up'
                                                                duration={200}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: 70,
                                                                        backgroundColor: 'black',
                                                                        cursor: 'pointer',
                                                                        borderRadius: '0px 0px 1000px 1000px',
                                                                    }}
                                                                    onClick={handleClickUpload}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            justifyContent: 'center',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            height: '100%',
                                                                            flexDirection: 'column',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                name='camera'
                                                                                size='big'
                                                                                style={{ color: 'white' }}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                marginTop: 10,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                            }}
                                                                        >
                                                                            <label
                                                                                style={{
                                                                                    color: 'white',
                                                                                    cursor: 'pointer',
                                                                                    paddingBottom: 5,
                                                                                }}
                                                                            >
                                                                                อัปโหลดรูปภาพ
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Transition>
                                                        </div>
                                                        <Input>
                                                            <input
                                                                type='file'
                                                                id='file'
                                                                hidden
                                                                onChange={handleChangeFile}
                                                                accept='image/png, image/jpeg, image/jpg'
                                                            />
                                                        </Input>
                                                    </div>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>

                                        {id_card_type === 'ID_CARD' && (
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>เลขประจำตัวประชาชน</label>
                                                    <Form.Field error={ssn_error}>
                                                        <Input
                                                            fluid
                                                            name='ssn'
                                                            id='ssn'
                                                            value={ssn}
                                                            onChange={handleChangeSSN}
                                                            maxLength={17}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <div style={{ display: 'flex', margin: 5 }}>
                                                        <div style={{ paddingRight: 15, textDecoration: 'underline' }}>
                                                            เงื่อนไข
                                                        </div>
                                                        {account_id ? (
                                                            <CustomCircleCheckbox
                                                                label='เลขประจำตัวประชาชนตรงกันกับ One ID'
                                                                checked={matching_id_card}
                                                            />
                                                        ) : (
                                                            <CustomCircleCheckbox
                                                                label='ห้ามซ้ำกับเลขประจำตัวประชาชนที่มีอยู่ใน One ID'
                                                                checked={id_card_duplicate}
                                                            />
                                                        )}
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                        )}
                                        {id_card_type === 'PASSPORT' && (
                                            <Grid.Row>
                                                <Grid.Column computer='3' only='computer' />
                                                <Grid.Column computer='10' mobile='16'>
                                                    <label className='header-input-form'>เลขหนังสือเดินทาง</label>
                                                    <Form.Field error={passport_number_error}>
                                                        <Input
                                                            fluid
                                                            id='passport_number'
                                                            name='passport_number'
                                                            value={passport_number}
                                                            onChange={handleChangePassportNumber}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer='3' only='computer' />
                                            </Grid.Row>
                                        )}

                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10'>
                                                <label className='header-input-form'>คำนำหน้าชื่อ</label>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column computer='5' tablet='8' mobile='16'>
                                                            <Segment
                                                                className={
                                                                    prefix_th === 'นาย'
                                                                        ? 'selected-prefix'
                                                                        : prefix_th_error
                                                                        ? 'error-prefix'
                                                                        : 'unselected-prefix'
                                                                }
                                                                onClick={() => {
                                                                    handleChangePrefix('นาย')
                                                                }}
                                                            >
                                                                <Radio checked={prefix_th === 'นาย'} />
                                                                <Image
                                                                    src={IconMan}
                                                                    style={{ margin: 5, width: 56, height: 56 }}
                                                                />
                                                                <div style={{ margin: 5 }}>
                                                                    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                                        นาย
                                                                    </div>
                                                                    <div style={{ color: '#4D648D' }}>Mr.</div>
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='16'>
                                                            <Segment
                                                                className={
                                                                    prefix_th === 'นาง'
                                                                        ? 'selected-prefix'
                                                                        : prefix_th_error
                                                                        ? 'error-prefix'
                                                                        : 'unselected-prefix'
                                                                }
                                                                onClick={() => {
                                                                    handleChangePrefix('นาง')
                                                                }}
                                                            >
                                                                <Radio checked={prefix_th === 'นาง'} />
                                                                <Image
                                                                    src={IconWoman3}
                                                                    style={{ margin: 5, width: 56, height: 56 }}
                                                                />
                                                                <div style={{ margin: 5 }}>
                                                                    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                                        นาง
                                                                    </div>
                                                                    <div style={{ color: '#4D648D' }}>Mrs.</div>
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='16'>
                                                            <Segment
                                                                className={
                                                                    prefix_th === 'นางสาว'
                                                                        ? 'selected-prefix'
                                                                        : prefix_th_error
                                                                        ? 'error-prefix'
                                                                        : 'unselected-prefix'
                                                                }
                                                                onClick={() => {
                                                                    handleChangePrefix('นางสาว')
                                                                }}
                                                            >
                                                                <Radio checked={prefix_th === 'นางสาว'} />
                                                                <Image
                                                                    src={IconWoman}
                                                                    style={{ margin: 5, width: 56, height: 56 }}
                                                                />
                                                                <div style={{ margin: 5 }}>
                                                                    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                                        นางสาว
                                                                    </div>
                                                                    <div style={{ color: '#4D648D' }}>Ms.</div>
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='5' mobile='16'>
                                                <label className='header-input-form'>ชื่อ (ภาษาไทย)</label>
                                                <Form.Field error={first_name_th_error}>
                                                    <Input
                                                        fluid
                                                        id='first_name_th'
                                                        name='first_name_th'
                                                        value={first_name_th}
                                                        onChange={handleChangeFirstNameTh}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column computer='5' mobile='16'>
                                                <label className='header-input-form'>สกุล (ภาษาไทย)</label>
                                                <Form.Field error={last_name_th_error}>
                                                    <Input
                                                        fluid
                                                        id='last_name_th'
                                                        name='last_name_th'
                                                        value={last_name_th}
                                                        onChange={handleChangeLastNameTh}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' tablet='16' mobile='16'>
                                                <label className='header-input-form'>วัน/เดือน/ปีเกิด (พ.ศ.)</label>
                                                <div style={{ marginTop: 5 }}>
                                                    <CustomDropdownDate
                                                        error={birth_date_error}
                                                        name='birth_date'
                                                        id='birth_date'
                                                        value={birth_date}
                                                        onChange={handleChangeDate}
                                                    />
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column computer='1' only='computer' />
                            <Grid.Column computer='14' mobile='16'>
                                <CustomSegment icon='file alternate' header='ข้อมูลเพิ่มเติม'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' tablet='16' mobile='16'>
                                                <Grid>
                                                    <Grid.Row>
                                                        {directory_option.map(
                                                            (
                                                                options: { value: string; text: string }[],
                                                                index: number
                                                            ) => (
                                                                <Grid.Column
                                                                    computer='5'
                                                                    tablet='8'
                                                                    mobile='16'
                                                                    key={index}
                                                                    style={{ margin: 5 }}
                                                                >
                                                                    <label className='header-input-form'>
                                                                        หน่วยงาน
                                                                        {index > 0 && `ย่อยระดับที่ ${index} (ถ้ามี)`}
                                                                    </label>
                                                                    <Dropdown
                                                                        error={directory_error}
                                                                        fluid
                                                                        selection
                                                                        search
                                                                        clearable
                                                                        value={directory[index]}
                                                                        options={options}
                                                                        onChange={(_: any, { value }: DropdownProps) =>
                                                                            handleChangeDirectory(Number(value), index)
                                                                        }
                                                                    />
                                                                </Grid.Column>
                                                            )
                                                        )}
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                        {/* <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <label className='header-input-form'>องค์กรที่สังกัด</label>
                                                <Dropdown
                                                    error={municipality_error}
                                                    fluid
                                                    selection
                                                    search
                                                    name='municipality'
                                                    value={municipality}
                                                    options={municipality_option}
                                                    onChange={handleChangeMunicipality}
                                                />
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row> */}

                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='5' mobile='16'>
                                                <label className='header-input-form'>ตำแหน่งงาน</label>
                                                {/* <Form.Field error={position_error}> */}
                                                <Input
                                                    fluid
                                                    name='position'
                                                    id='position'
                                                    value={position}
                                                    onChange={handleChangePosition}
                                                />
                                                {/* </Form.Field> */}
                                            </Grid.Column>
                                            <Grid.Column computer='5' mobile='16'>
                                                <label className='header-input-form'>สิทธิ์</label>
                                                <Dropdown
                                                    error={role_error}
                                                    fluid
                                                    selection
                                                    search
                                                    id='roles_id'
                                                    name='roles_id'
                                                    value={roles_id}
                                                    options={role_option}
                                                    onChange={handleChangeRole}
                                                    disabled={disabled_role}
                                                />
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    label='ยอมรับข้อตกลง'
                                    checked={accept_term}
                                    onClick={handleChangeAcceptTerm}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <label
                                    style={{ color: '#4183C4', textDecorationLine: 'underline' }}
                                    onClick={handleChangeShowTerms}
                                >
                                    ข้อตกลงการใช้งาน
                                </label>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                    disabled={disabled_button_save}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={() => history.goBack()}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                <Modal size='large' open={show_terms}>
                    <Modal.Header>
                        <label style={{ color: '#38869C' }}>ข้อตกลงการใช้งาน</label>
                    </Modal.Header>
                    <Modal.Content>
                        <div>
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <b>ประกาศ ที่ 1/2563</b>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <b>นโยบายคุ้มครองข้อมูลส่วนบุคคล (Data Protection Policy)</b>
                                </div>
                            </div>
                            <div>
                                <div>
                                    บริษัท วันจีโอเซอร์เวย์ จำกัด มีความมุ่งมั่นที่จะดำเนินการตาม
                                    พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                                    ให้สอดคล้องกับหลักเกณฑ์ของคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลเพื่อให้มีหลักเกณฑ์การคุ้มครองสิทธิของเจ้าของข้อมูลเกี่ยวกับข้อมูล
                                    ส่วนบุคคล สิทธิในความเป็นส่วนตัวและ เสรีภาพในข้อมูลส่วนบุคคลของผู้เจ้าของข้อมูล
                                    และพัฒนาปรับปรุงนโยบายระเบียบปฏิบัติของบริษัทให้ต่อเนื่อง
                                    สืบไปเพื่อให้เป็นไปตามนโยบายการ คุ้มครองข้อมูลส่วนบุคคล บริษัทจึงขอประกาศนโยบาย
                                    ดังนี้
                                </div>
                                <div>
                                    1. คำนิยาม “บริษัท” หมายถึง บริษัท วันจีโอเซอร์เวย์ จำกัด “บุคคล” ในที่นี้หมายถึง
                                    บุคคลธรรมดาที่มีชีวิตอยู่ ไม่รวมถึง “นิติบุคคล” ที่จัดตั้งขึ้นตามกฎหมาย
                                    “นโยบายการคุ้มครองข้อมูลส่วนบุคคล” หรือนโยบายความเป็นส่วนตัว เป็นสัญญาอย่างหนึ่ง
                                    โดยมีคู่สัญญา
                                    ฝ่ายหนึ่งคือผู้ให้บริการหรือเจ้าของเว็บไซต์หรือแอปพลิเคชันซึ่งเป็นผู้เก็บรวบรวม ใช้
                                    เปิดเผยข้อมูลส่วนบุคคล ของคู่สัญญาอีกฝ่ายหนึ่งซึ่งเรียกว่าผู้ใช้งานเว็บไซต์
                                    หรือแอปพลิเคชันซึ่งเข้ามาใช้บริการในเว็บไซต์หรือแอปพลิเคชันนั้น
                                    โดยเนื้อหาในสัญญาจะกล่าวถึงสิทธิและหน้าที่ของคู่สัญญาแต่ละฝ่ายเกี่ยวกับการให้ข้อมูล
                                    การเก็บรวบรวมข้อมูล การใช้ข้อมูล
                                    รวมถึงการเผยแพร่ข้อมูลของผู้ใช้งานเว็บไซต์หรือแอปพลิเคชัน “ข้อมูลส่วนบุคคล” หมายถึง
                                    ข้อมูลใด ๆ ที่ระบุไปถึง “เจ้าของข้อมูล” (Data Subject) ได้ไม่ว่าทางตรงหรือทางอ้อม
                                    โดยที่ข้อมูลส่วนบุคคลของผู้ใช้งาน ได้แก่
                                    ข้อมูลที่สามารถระบุตัวบุคคลซึ่งเป็นเจ้าของข้อมูลนั้นได้
                                    ไม่ว่าจะเป็นข้อมูลส่วนบุคคลทั่วไป เช่น ชื่อ นามสกุล ที่อยู่ วันเดือนปีเกิด
                                    เบอร์โทรศัพท์ อายุ วุฒิการศึกษา งานที่ทำ หรือข้อมูลส่วนบุคคลที่มีความอ่อนไหว ทั้งนี้
                                    ไม่รวมถึงข้อมูลดังต่อไปนี้ เช่น
                                    ข้อมูลสำหรับการติดต่อทางธุรกิจที่ไม่ได้ระบุถึงตัวบุคคล อาทิ ชื่อบริษัท
                                    ที่อยู่ของบริษัท เลขทะเบียนนิติบุคคลของบริษัท เช่น info@company.co.th เป็นต้น
                                    “ข้อมูลส่วนบุคคลอ่อนไหว” หมายถึง ข้อมูลที่เป็นเรื่องส่วนบุคคลโดยแท้ของบุคคล
                                    แต่มีความละเอียดอ่อนและอาจสุ่มเสี่ยงในการเลือกปฏิบัติอย่างไม่เป็นธรรม เช่น เชื้อชาติ
                                    เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อ (ลัทธิ ศาสนา ปรัชญา) พฤติกรรมทางเพศ
                                    ประวัติอาชญากรรม สุขภาพ ความพิการ พันธุกรรม ข้อมูลชีวภาพ ข้อมูลภาพจำลองใบหน้า ม่านตา
                                    หรือลายนิ้วมือ สหภาพแรงงานของผู้ใช้งาน เป็นต้น “เจ้าของข้อมูลส่วนบุคคล” (Data
                                    Subject) หมายถึง บุคคลที่ข้อมูลส่วนบุคคลนั้นระบุไปถึง
                                    ตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลนั้น
                                    แต่ไม่ใช่กรณีที่บุคคลมีความเป็นเจ้าของข้อมูล (Ownership)
                                    หรือเป็นผู้สร้างหรือเก็บรวบรวมข้อมูลนั้นเอง
                                    โดยเจ้าของข้อมูลส่วนบุคคลนี้จะหมายถึงบุคคลธรรมดาเท่านั้น และไม่รวมถึง “นิติบุคคล”
                                    (Juridical Person) ที่จัดตั้งขึ้นตามกฎหมาย เช่น บริษัท สมาคม มูลนิธิ
                                    หรือองค์กรอื่นใด “การประมวลผล” หมายถึง การดำเนินการใด ๆ เกี่ยวกับการใช้ การเปิดเผย
                                    หรือการแก้ไขซึ่งข้อมูลส่วนบุคคลของเจ้าของข้อมูล ไม่ว่าจะใช้วิธีการใด ๆ
                                    และให้หมายความรวมถึงการส่งหรือโอนข้อมูลส่วนบุคคลที่ใช้ในการประกอบกิจการนั้น ๆ
                                </div>
                                <div>
                                    2. การเก็บรวบรวม และวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
                                    บริษัทใช้วิธีการเก็บรวบรวม ใช้ เปิดเผย
                                    ข้อมูลส่วนบุคคลด้วยวิธีการที่ชอบด้วยกฎหมายและเป็นธรรม
                                    โดยจัดเก็บข้อมูลส่วนบุคคลเท่าที่จำเป็น ภายใต้วัตถุประสงค์ในการดำเนินงานของบริษัท
                                    หรือตามที่กฎหมายกำหนดเท่านั้น ซึ่งหากมีการเปลี่ยนแปลงวัตถุประสงค์
                                    บริษัทจะแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบก่อนหรือขณะเก็บรวบรวมข้อมูลส่วนบุคคล
                                    และบันทึกเพิ่มเติมไว้เป็นหลักฐาน โดยมีรายละเอียดดังนี้ 2.1
                                    วัตถุประสงค์ของการเก็บรวบรวม 2.2 ข้อมูลส่วนบุคคลที่ทำการเก็บรวบรวม 2.3
                                    กรณีที่เจ้าของข้อมูลต้องให้ข้อมูลส่วนบุคคล
                                    เพื่อปฏิบัติตามกฎหมายหรือสัญญาหรือเพื่อเข้าทำสัญญาโดยต้องแจ้งถึงผลกระทบที่เป็นไปได้จากการไม่ให้ข้อมูลส่วนบุคคลให้เจ้าของข้อมูลทราบด้วย
                                    2.4 ประเภทของบุคคลหรือหน่วยงานซึ่งข้อมูลส่วนบุคคลที่เก็บรวบรวมอาจถูกเปิดเผย 2.5
                                    สิทธิของเจ้าของข้อมูล
                                </div>
                                <div>
                                    3. การใช้หรือการเปิดเผยข้อมูลส่วนบุคคล การใช้หรือการเปิดเผยข้อมูลส่วนบุคคล
                                    บริษัทจะต้องดำเนินการตามความจำเป็น และตรงกับวัตถุประสงค์ของการเก็บรวบรวมข้อมูล
                                    และจะต้องได้รับความยินยอมจากเจ้าของข้อมูลจากเจ้าของข้อมูลที่ให้ไว้ก่อนหรือในขณะนั้น
                                    เว้นแต่กรณีดังต่อไปนี้ ไม่จำเป็นต้องขอความยินยอม 3.1
                                    เพื่อประโยชน์ในการศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสม
                                    เพื่อคุ้มครองสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคล 3.2
                                    เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล 3.3
                                    เพื่อความจำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งเจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญาหรือ
                                    เพื่อใช้ในการดำเนินการตามคำขอของเจ้าของข้อมูลส่วนบุคคลก่อนเข้าทำสัญญานั้น 3.4
                                    เพื่อประโยชน์สาธารณะของผู้ควบคุมโดยชอบด้วยกฎหมาย
                                </div>
                                <div>
                                    4. จัดให้มีมาตรการรักษาความมั่นคงปลอดภัยที่เหมาะสม เพื่อป้องกันการสูญหาย เข้าถึง ใช้
                                    เปลี่ยนแปลง แก้ไข หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ
                                </div>
                                <div>
                                    5. ดำเนินการทบทวนมาตรการรักษาความมั่นคงปลอดภัย
                                    เมื่อมีความจำเป็นหรือเมื่อเทคโนโลยีเปลี่ยนแปลงไปเพื่อให้มีประสิทธิภาพในการรักษาความมั่นคงปลอดภัยที่เหมาะสม
                                    ดำเนินการอย่างน้อยปีละ 1 ครั้ง
                                    หรือให้เป็นไปตามมาตรฐานขั้นต่ำที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
                                </div>
                                <div>
                                    6. จัดให้มีการกำหนดขั้นตอน
                                    ระเบียบในกรณีที่ต้องให้ข้อมูลส่วนบุคคลแก่บุคคลหรือนิติบุคคลอื่นที่ไม่ใช่ผู้ควบคุมข้อมูลส่วนบุคคล
                                    ต้องดำเนินการเพื่อป้องกันมิให้ผู้นั้นใช้หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ
                                </div>
                                <div>
                                    7. จัดให้มีระบบการตรวจสอบเพื่อดำเนินการลบหรือทำลายข้อมูลส่วนบุคคลเมื่อพ้นกำหนด
                                    ระยะเวลาการเก็บรักษา หรือที่
                                    ไม่เกี่ยวข้องหรือเกินความจำเป็นตามวัตถุประสงค์ในการเก็บรวบรวม ข้อมูลส่วนบุคคลนั้น
                                    หรือตามที่เจ้าของข้อมูลส่วนบุคคล ร้องขอ หรือที่เจ้าของข้อมูลส่วนบุคคล
                                    ได้ถอนความยินยอม
                                    เว้นแต่เก็บรักษาไว้เพื่อวัตถุประสงค์ในการใช้เสรีภาพในการแสดงความคิดเห็น
                                    การเก็บรักษาไว้เพื่อวัตถุประสงค์ ดังนี้
                                </div>
                                <div>
                                    8. จัดให้ขั้นตอนการแจ้งเหตุการณ์ละเมิดข้อมูลส่วนบุคคล (PII data Breach)
                                    แก่สำนักงานโดยไม่ชักช้าภายใน72
                                    ชั่วโมงตามมาตรฐานนับแต่ทราบเหตุเท่าที่จะสามารถกระทำได้
                                    เว้นแต่การละเมิดดังกล่าวไม่มีความเสี่ยงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของบุคคล
                                    ในกรณีที่การละเมิดข้อมูลส่วนบุคคล
                                    มีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของบุคคล
                                    ให้แจ้งเหตุการละเมิดให้เจ้าของข้อมูลส่วนบุคคลทราบพร้อมกับแนวทางการเยียวยา
                                    โดยไม่ชักช้าด้วย
                                    หรือการแจ้งดังกล่าวและข้อยกเว้นให้เป็นไปตามหลักเกณฑ์และวิธีการที่คณะกรรมการประกาศกำหนด
                                </div>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            style={{
                                backgroundColor: '#38869C',
                                color: 'white',
                                marginTop: '1rem',
                            }}
                            onClick={handleChangeShowTerms}
                        >
                            ปิด
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Fragment>
        )
    }
}

export default LineRegisterForm
