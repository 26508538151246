import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Dropdown, Icon, Label, Loader } from 'semantic-ui-react'
import { axiosAuthen } from '../../config/axiosAuthen'
import { GET_NOTIFICATION_COMPLAINTS } from '../../config/api/notification'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../store/actions'
import { getNotification, getToast } from '../../store/selectors'

const Notification: React.FC = () => {
    let [limit, setLimit] = useState<number>(5)
    let [loading, setLoading] = useState<boolean>(false)
    let notifications = useSelector(getNotification)
    let toast = useSelector(getToast)
    let dispatch = useDispatch()

    const getNotificationCompaint = useCallback(() => {
        setLoading(true)
        axiosAuthen.get(`${GET_NOTIFICATION_COMPLAINTS}?limit=${limit}`).then((response) => {
            let { value } = response.data
            setLoading(false)
            dispatch(setNotification(value || []))
        })
    }, [dispatch, limit])

    useEffect(() => {
        getNotificationCompaint()
    }, [getNotificationCompaint])

    const handleScroll = (event: any) => {
        let element = event.target
        if (element.scrollHeight - 10 < element.clientHeight + element.scrollTop) {
            setLimit(limit + 5)
        }
    }

    return (
        <div style={{ alignItems: 'flex-end', display: 'flex', marginRight: 5 }}>
            <Dropdown
                icon={
                    <Fragment>
                        <Icon
                            name='bell outline'
                            size='big'
                            style={{
                                color: 'gray',
                                alignSeft: 'center',
                            }}
                        />
                        {toast.length > 0 && (
                            <Label
                                circular
                                color='red'
                                style={{
                                    position: 'absolute',
                                    right: 4,
                                    top: -1,
                                    minWidth: 1,
                                    minHeight: 1,
                                }}
                            />
                        )}
                    </Fragment>
                }
                pointing='top right'
            >
                <div className='menu transition'>
                    <Dropdown.Menu scrolling onScroll={handleScroll}>
                        {notifications.map((notification, index: number) => (
                            <Fragment key={index}>
                                {index > 0 && <Dropdown.Divider />}
                                <Dropdown.Item>
                                    <div
                                        style={{
                                            display: 'block',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                            width: 200,
                                            lineHeight: 1.6,
                                        }}
                                    >
                                        <h4>{notification.action}</h4>
                                        เลขที่ : {notification.department_complaint_no} <br />
                                        เจ้าหน้าที่ : {notification.user_name}
                                    </div>
                                </Dropdown.Item>
                            </Fragment>
                        ))}
                        {notifications.length === 0 && <Dropdown.Item>ยังไม่มีการแจ้งเตือน</Dropdown.Item>}
                        {loading && (
                            <Dropdown.Item>
                                <Loader active size='mini' />
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </div>
            </Dropdown>
        </div>
    )
}
export default Notification
