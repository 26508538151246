import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    Container,
    // Dropdown,
    Grid,
    Input,
    Label,
    // Pagination,
    Table,
    Breadcrumb,
    InputOnChangeData,
    Form,
    Button,
    Icon,
    Dimmer,
    Loader,
} from 'semantic-ui-react'
import {
    DELETE_DIRECTORY_OF_MUNICIPALITY_TIER,
    GET_DIRECTORY_OF_MUNICIPALITY_LIST_NEXT_TIER,
} from '../../../../config/api/directoryOfMunicipality'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import ManagementDirectoryModalEditLow from './ModalEditLow'
import ManagementDirectorysLowModalForm from './ModalFormLow'
import ManagementDirectoryMappingRoles from './ModalMappingRoles'

export interface InterfaceDirectory {
    id: number
    name: string
    level: number
    sequence: number
    structure_tire: any
    tire: string
    municipality_id?: number
}

const ListChildrenDirectory: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let { state } = location
    let location_state = JSON.parse(JSON.stringify(state, null, ' '))
    let directory_id = location_state?.directory_id
    let tire_id = location_state?.tire_id
    let directory_name = location_state?.directory_name
    let level = location_state?.level
    // let [record_qty, setRecordQty] = useState<number>(0)
    // let [row_qty, setRowQty] = useState<number>(5)
    // let [current_page, setCurrentPage] = useState<number>(1)
    // let [page_qty, setPageQty] = useState<number>(1)
    let [keyword, setKeyword] = useState<string>('')
    let [loading, setLoading] = useState<boolean>(false)
    let [open_modal, setOpenModal] = useState<boolean>(false)
    let [open_modal_edit, setOpenModalEdit] = useState<boolean>(false)
    let [data_directory, setDataDirectory] = useState<InterfaceDirectory | undefined>()
    let [directory_municipality, setDirectoryOfMunicipality] = useState<InterfaceDirectory[]>([])
    let [municipality_id, setMunicipalityID] = useState<number>(0)

    // let size_row = [
    //     { value: 5, text: 5 },
    //     { value: 10, text: 10 },
    //     { value: 15, text: 15 },
    // ]

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            setMunicipalityID(municipality_id)
        }
    }, [location])

    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    useEffect(() => {
        const getDirectory = () => {
            setLoading(true)
            axiosAuthen
                .get(
                    `${GET_DIRECTORY_OF_MUNICIPALITY_LIST_NEXT_TIER}?keyword=${keyword}&directory_id=${directory_id}&tire_id=${tire_id}&level_directory_id=${level}`
                )
                .then((response) => {
                    let { value } = response.data
                    // setRecordQty(value.count)
                    setDirectoryOfMunicipality(value.directory_of_municipalities)
                    setLoading(false)
                })
        }
        getDirectory()
    }, [keyword, tire_id, directory_id, level, open_modal, open_modal_edit])

    const handleGetLowLevel = (data: any) => {
        history.push(`/${localStorage.permission_en}/management/directory-of-municipality/list/children`, {
            directory_id: data.id,
            level: data.level,
            tire_id: data.id,
            directory_name: data.name,
            municipality_id: municipality_id,
        })
    }

    const handleClickOrganization = () => {
        history.push(`/${localStorage.permission_en}/management/directory-of-municipality/organization`, {
            municipality_id: municipality_id,
            current_directory: directory_id,
        })
    }

    const handleAddDirectory = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => setOpenModal(false)

    const handleCloseModalEdit = () => setOpenModalEdit(false)

    const handleEdit = (data: any, index: number) => {
        setDataDirectory(data)
        setOpenModalEdit(true)
    }

    const deleteDirectory = (data: InterfaceDirectory) => {
        axiosAuthen
            .delete(`${DELETE_DIRECTORY_OF_MUNICIPALITY_TIER}/${data.id}?municipality_id=${municipality_id}`)
            .then((response) => {
                axiosAuthen
                    .get(
                        `${GET_DIRECTORY_OF_MUNICIPALITY_LIST_NEXT_TIER}?keyword=${keyword}&directory_id=${directory_id}&tire_id=${tire_id}&level_directory_id=${level}`
                    )
                    .then((response) => {
                        let { value } = response.data
                        // setRecordQty(value.count)
                        setDirectoryOfMunicipality(value.directory_of_municipalities)
                        setLoading(false)
                    })
            })
    }

    return (
        <Fragment>
            <Dimmer active={loading} inverted>
                <Loader>รอสักครู่</Loader>
            </Dimmer>
            <div className='background-container'>
                <Container>
                    <Grid>
                        {/* <ManagementDirectorysLowModalForm open={open_modal} onClose={handleCloseModal} level_directory_id={level_id} directory_id={directory_id} /> */}
                        <ManagementDirectorysLowModalForm
                            open={open_modal}
                            onClose={handleCloseModal}
                            directory_id={directory_id}
                        />

                        {open_modal_edit && (
                            <ManagementDirectoryModalEditLow
                                open={open_modal_edit}
                                onClose={handleCloseModalEdit}
                                parent_directory_id={directory_id}
                                data={data_directory}
                            />
                        )}

                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Breadcrumb>
                                    {localStorage.permission_en === 'super_admin' && (
                                        <Fragment>
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities`, {
                                                        municipality_id: municipality_id,
                                                    })
                                                }}
                                            >
                                                รายการองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                        municipality_id: municipality_id,
                                                    })
                                                }}
                                            >
                                                กำหนดการใช้งานองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </Fragment>
                                    )}
                                    <Breadcrumb.Section active> จัดการหน่วยงาน </Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='2'>
                                <Button icon labelPosition='left' primary onClick={() => history.goBack()}>
                                    <Icon name='chevron left' /> กลับ
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='12' textAlign='center'>
                                <h2>จัดการ{directory_name}</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='4' mobile='16' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={handleAddDirectory}
                                >
                                    <Icon name='plus' /> เพิ่มหน่วยงานย่อย
                                </Button>
                            </Grid.Column>

                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Button fluid icon primary labelPosition='left' onClick={handleClickOrganization}>
                                    <Icon name='sitemap' /> ดูแผนผังองค์กร
                                </Button>
                            </Grid.Column>

                            <Grid.Column computer='6' only='computer' />
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    placeholder='ค้นหา'
                                    icon='search'
                                    name='keyword'
                                    value={keyword}
                                    onChange={handleChangeKeyword}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h4>หน่วยงาน : {directory_name}</h4>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form loading={loading}>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='8' textAlign='center'>
                                                    ชื่อหน่วยงาน
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='7' textAlign='center'>
                                                    จัดการ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    <Table attached celled className='table-body'>
                                        <Table.Body className='table-body body'>
                                            {directory_municipality === null ? (
                                                <Table.Row>
                                                    <Table.Cell textAlign='center' width='16'>
                                                        {'ไม่พบข้อมูล'}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ) : (
                                                directory_municipality.map((data: any, index: number) => (
                                                    <Table.Row key={data.id} style={{ color: '#1E1F26' }}>
                                                        <Table.Cell
                                                            textAlign='center'
                                                            width='1'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleGetLowLevel(data)}
                                                        >
                                                            <Label
                                                                circular
                                                                style={{
                                                                    backgroundColor: '#425679',
                                                                    color: '#FFFFFF',
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Label>
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            width='8'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleGetLowLevel(data)}
                                                        >
                                                            {data.name}
                                                        </Table.Cell>
                                                        <Table.Cell width='7' textAlign='center'>
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ margin: 10 }}>
                                                                    <ManagementDirectoryMappingRoles
                                                                        directory_id={data.id}
                                                                        level_name_selected={data.name}
                                                                        municipality_id={municipality_id}
                                                                    />
                                                                </div>
                                                                <div style={{ margin: 10 }}>
                                                                    <Button
                                                                        basic
                                                                        color='yellow'
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => handleEdit(data, index)}
                                                                    >
                                                                        <Icon name='edit' /> แก้ไข
                                                                    </Button>
                                                                </div>

                                                                <div style={{ margin: 10 }}>
                                                                    <Button
                                                                        basic
                                                                        color='red'
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => deleteDirectory(data)}
                                                                    >
                                                                        <Icon name='trash alternate' /> ลบ
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))
                                            )}
                                        </Table.Body>
                                    </Table>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        {/* <Grid.Row only='computer tablet'>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column computer='11' style={{ display: 'flex' }} only='computer'>
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row> */}
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default ListChildrenDirectory
