import React from 'react'
import { Button, Container, Grid, Icon, Image, Segment } from 'semantic-ui-react'
import IconOnePlatform from '../../../../../assets/images/logo one platform.png'
import { useHistory } from 'react-router-dom'

const LineRegisterForgetPasswordComplete: React.FC = () => {
    let history = useHistory()

    const handleClickGoBack = () => {
        history.goBack()
    }

    const handleClickGoBackToLoinPage = () => {
        history.push('/line/register/login')
    }
    return (
        <div className='background-container-no-sidebar'>
            <Container>
                <Segment className='segment-login-page'>
                    <div>
                        <div className='header-segment-login-page'>
                            <div className='image-one-platform'>
                                <Image src={IconOnePlatform} />
                            </div>
                        </div>
                        <div className='body-segment-login-page'>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>
                                        <h3>การกู้คืนรหัสผ่านเสร็จสิ้น</h3>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>
                                        <Button onClick={handleClickGoBackToLoinPage}>กลับสู่หน้าเข้าสู่ระบบ</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                        <div className='footer-segment-login-page'>
                            <Button floated='left' compact icon primary onClick={handleClickGoBack}>
                                <Icon name='chevron left' />
                            </Button>
                        </div>
                    </div>
                </Segment>
            </Container>
        </div>
    )
}

export default LineRegisterForgetPasswordComplete
