import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import CustomSegment from '../../../../components/CustomSegment'
import GoogleMap from '../../../../components/GoogleMaps'
import { setStateByNameAndValue } from '../../../../store/actions'
import { ComplaintFormStep3State } from '../../../../store/types/Complaint/Form/Step3'
import { Dimmer, Loader } from 'semantic-ui-react'

type Step3Props = {
    data: ComplaintFormStep3State
    field: string[]
}

const Step3: React.FC<Step3Props> = (props) => {
    let dispatch = useDispatch()
    let { position } = props.data
    useEffect(() => {
        if (!position.lat && !position.lng) {
            navigator.geolocation.getCurrentPosition((event) => {
                let current = {
                    lat: Number(event.coords.latitude),
                    lng: Number(event.coords.longitude),
                }
                dispatch(setStateByNameAndValue('ComplaintFormStep3', 'position', current))
            })
        }
    }, [dispatch, position.lat, position.lng])

    const handleChangePosition = (_: any, { value }: any) => {
        dispatch(setStateByNameAndValue('ComplaintFormStep3', 'position', value))
        dispatch(setStateByNameAndValue('ComplaintFormStep3', 'position_error', false))
    }

    return (
        <Fragment>
            <CustomSegment icon='map marker alternate' header='ตำแหน่งที่ตั้งสถานที่'>
                {position.lat !== 0 && position.lat && position.lng ? (
                    <GoogleMap
                        style={{ widht: 420, height: 420 }}
                        search
                        getCurrentPosition
                        value={{ lat: position.lat, lng: position.lng }}
                        onChange={handleChangePosition}
                    />
                ) : (
                    <div style={{ height: 50 }}>
                        <Dimmer active inverted>
                            <Loader inverted />
                        </Dimmer>
                    </div>
                )}
            </CustomSegment>
        </Fragment>
    )
}

export default Step3
