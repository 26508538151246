/* eslint-disable no-unused-vars */

let ENV_LOCATION = ''

if (process.env.REACT_APP_ENVIRONMENT_VARIABLE === 'prod') {
    ENV_LOCATION = 'PROD'
} else if (process.env.REACT_APP_ENVIRONMENT_VARIABLE === 'test') {
    ENV_LOCATION = 'TEST'
} else if (process.env.REACT_APP_ENVIRONMENT_VARIABLE === 'dev') {
    ENV_LOCATION = 'DEV'
}

const LOCAL = 'http://localhost:1324/api/v1'
const SERVER_DEV = 'https://demo2-complaint.ogsdev.net/api/v1'
const SERVER_TEST = 'https://prompt-chuay.ogs.co.th/api/v1'
const SERVER = 'https://kkm-engineer.ogsdev.net/api/v1'

const SOCKET_LOCAL = 'ws://localhost:1324/api/v1/socket.io/'
const SOCKET_SERVER_DEV = 'ws://demo2-complaint.ogsdev.net/api/v1/socket.io/'
const SOCKET_SERVER_TEST = 'wss://https://prompt-chuay.ogs.co.th/api/v1/socket.io/'
const SOCKET_SERVER = 'wss://kkm-engineer.ogsdev.net/api/v1/socket.io/'

export const KEY_API_GOOGLE = 'AIzaSyBRHbUVy349nAwwjcyQJT3t5nEIy4deMuk'

export const HOST =
    ENV_LOCATION === 'PROD'
        ? SERVER
        : ENV_LOCATION === 'TEST'
        ? SERVER_TEST
        : ENV_LOCATION === 'DEV'
        ? SERVER_DEV
        : LOCAL

export const SOCKET =
    ENV_LOCATION === 'PROD'
        ? SOCKET_SERVER
        : ENV_LOCATION === 'TEST'
        ? SOCKET_SERVER_TEST
        : ENV_LOCATION === 'DEV'
        ? SOCKET_SERVER_DEV
        : SOCKET_LOCAL
