import jQuery from 'jquery';

// eslint-disable-next-line no-unused-expressions
!(function(a){
	a.fn.datepicker.dates.th = {
		days: [ 'อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์', 'อาทิตย์' ],
		daysShort: [ 'อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา' ],
		daysMin: [ 'อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา' ],
		months: [
			'มกราคม',
			'กุมภาพันธ์',
			'มีนาคม',
			'เมษายน',
			'พฤษภาคม',
			'มิถุนายน',
			'กรกฎาคม',
			'สิงหาคม',
			'กันยายน',
			'ตุลาคม',
			'พฤศจิกายน',
			'ธันวาคม'
		],
		monthsShort: [
			'ม.ค.',
			'ก.พ.',
			'มี.ค.',
			'เม.ย.',
			'พ.ค.',
			'มิ.ย.',
			'ก.ค.',
			'ส.ค.',
			'ก.ย.',
			'ต.ค.',
			'พ.ย.',
			'ธ.ค.'
		],
		today: 'วันนี้'
	};
})(jQuery);
