import React, { Fragment } from 'react'
import { Button, Grid } from 'semantic-ui-react'
import CustomModal from '../../../../../components/CustomModal'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import { useState } from 'react'
import { InterfacePreComplaint } from '..'
import SetFieldPreComplaint from '../../../../../functions/SetFieldPreComplaint'
import { useHistory } from 'react-router-dom'

type FormProps = {
    pre_complaint?: InterfacePreComplaint
    reload?: () => void
}

const AcceptPreComplaint: React.FC<FormProps> = (props) => {
    let [open, setOpen] = useState<boolean>(false)
    let history = useHistory()

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = async () => {
        if (props.pre_complaint) {
            await SetFieldPreComplaint(props.pre_complaint)
            history.push('form', true)
        }
        handleClose()
        props.reload && props.reload()
    }

    return (
        <Fragment>
            <CustomModal
                icon="file alternate"
                header="ตอบรับ - รับเรื่องร้องทุกข์"
                open={open}
                trigger={<ButtonIconOver fluid label="รับเรื่อง" color="#70B996" icon="check" basic onClick={handleOpen} />}
            >
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign="center">
                            <Button
                                style={{
                                    width: 100,
                                    backgroundColor: '#283655',
                                    color: '#FFF',
                                    marginRight: 15,
                                }}
                                onClick={handleSubmit}
                            >
                                ยืนยัน
                            </Button>
                            <Button
                                style={{
                                    width: 100,
                                    backgroundColor: '#C54C40',
                                    color: '#FFF',
                                    marginLeft: 15,
                                }}
                                onClick={handleClose}
                            >
                                ยกเลิก
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </CustomModal>
        </Fragment>
    )
}

export default AcceptPreComplaint
