import React, { useEffect } from 'react'
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Login from '../Login'
import ComplaintList from '../Complaint/List'
import ComplaintListForm from '../Complaint/List/Form'
import { Image } from 'semantic-ui-react'
import CustomSidebar from '../../components/CustomSidebar'
import './styles.css'
import SuperAdminLogin from '../SuperAdmin'
import ManagementUsers from '../Complaint/Management/Users'
import ManagementRoles from '../Complaint/Management/Roles'
import Defect from '../Complaint/Defect'
import ManagementUsersDetail from '../Complaint/Management/Users/Detail'
import ManagementActions from '../Complaint/Management/Actions'
import ManagementPages from '../Complaint/Management/Pages'
import ManagementMenus from '../Complaint/Management/Menus'
import ManagementMunicipalityForm from '../Complaint/Management/Municipality/Form'
import ManagementMunicipalityFormEdit from '../Complaint/Management/Municipality/FormEdit'
import { Alert } from '../../components/alert'
import System from '../Complaint/Management/System'
import ListDistrictsAndCommunities from '../Complaint/Management/System/DistrictsAndCommunities'
import ManagementUserManualTopic from '../Complaint/Management/UserManual/Topic'
import ManagementUserManualSubTopic from '../Complaint/Management/UserManual/SubTopic'
import ManagementUserManualContent from '../Complaint/Management/UserManual/Content'
import Municipalities from '../Complaint/Municipalities'
import MunicipalitiesMenu from '../Complaint/Municipalities/Menu'
import ManagementUserManual from '../Complaint/Management/UserManual'
import ManagementUserManualMapping from '../Complaint/Management/UserManual/Mapping'
import ListComplaint from '../Complaint/Management/System/Complaint'
import ManagementUsageMunicipality from '../Complaint/Management/UsageMunicipality'
import ManagementUsageRole from '../Complaint/Management/UsageRole'
import ManagementUsageUser from '../Complaint/Management/UsageUser'
import ManagementUsageRoleMapping from '../Complaint/Management/UsageRole/Mapping'
import ManagementUsageUserMapping from '../Complaint/Management/UsageUser/Mapping'
import ComplaintListDetail from '../Complaint/List/Detail'
import ComplaintListDetailSaveResult from '../Complaint/List/Detail/SaveResult'
import ComplaintListDetailDetailResult from '../Complaint/List/Detail/DetailResult'
import ListMember from '../Complaint/Management/System/Member'
import DetailStaff from '../Complaint/Management/System/Member/DetailStaff'
import DirectoryOfMunicipality from '../Complaint/Management/DirectoryOfMunicipality'
import ListChildrenDirectory from '../Complaint/Management/DirectoryOfMunicipality/ListChildren'
import LineRegisterForm from '../Mobile/Line/Register/Form'
import OneChatLogin from '../Mobile/OneChat'
import LineLogin from '../Mobile/Line'
import OneChatComplaintForm from '../Mobile/OneChat/Complaint/Form'
import OneChatComplaintDetail from '../Mobile/OneChat/Complaint/Detail'
import LineComplaintForm from '../Mobile/Line/Complaint/Form'
import ComplaintListDetailPreComplaint from '../Complaint/List/DetailPreComplaint'
import OneChatComplaintList from '../Mobile/OneChat/Complaint/List'
import OneChatComplaintEvaluate from '../Mobile/OneChat/Complaint/Detail/Evaluate'
import OneChatComplaintDetailEvaluate from '../Mobile/OneChat/Complaint/Detail/DetailEvaluate'
import OneChatProfile from '../Mobile/OneChat/Profile'
import OneChatEditProfile from '../Mobile/OneChat/EditProfile'
import LineComplaintList from '../Mobile/Line/Complaint/List'
import LineComplaintDetail from '../Mobile/Line/Complaint/Detail'
import LineComplaintDetailEvaluate from '../Mobile/Line/Complaint/Detail/DetailEvaluate'
import LineComplaintEvaluate from '../Mobile/Line/Complaint/Detail/Evaluate'
import LineProfile from '../Mobile/Line/Profile'
import LineEditProfile from '../Mobile/Line/EditProfile'
import Dashboard from '../Dashboard'
import DashboardTechnian from '../DashboardTechnian'
import UserDetail from '../Complaint/UserDetail'
import UserManual from '../Complaint/UserManual'
import UserManualDetail from '../Complaint/UserManual/Detail'
import CommentPeople from '../Complaint/CommentPeople'
import DashboardMap from '../Dashboard/Map'
import ExportExcel from '../Complaint/List/Export'
import ManagementApproveUsers from '../Complaint/Management/ApproveUsers'
import ManagementApproveUsersDetail from '../Complaint/Management/ApproveUsers/Detail'
import FormEditUser from '../Complaint/UserDetail/FormEdit'
import ListContacts from '../Complaint/Management/System/Contacts'
import ManagementStatusApprove from '../Complaint/Management/StatusApprove'
import RegisterPersonForm from '../Mobile/Line/RegisterPerson'
import LineContactMunicipality from '../Mobile/Line/ContactMunicipality'
import ComplaintListDetailExportReport from '../Complaint/List/Detail/ExportReport/Report'
import MainPage from '../MainPage'
import ManagementLevelDirectory from '../Complaint/Management/LevelDirectory'
import ManagementLevelDirectoryChildren from '../Complaint/Management/LevelDirectory/Children'
import ManagementRoleInLevelDirectory from '../Complaint/Management/RoleInLevelDirectory'
import MunicipalitiesDetail from '../Complaint/Municipalities/Detail'
import ManagementDirectoryOrganizationChart from '../Complaint/Management/DirectoryOfMunicipality/OrganizationChart'
import LineRegisterMenu from '../Mobile/Line/Register'
import LineRegisterLoginOne from '../Mobile/Line/Register/loginOne'
import LineRegisterForgetPassword from '../Mobile/Line/Register/ForgetPassword'
import LineRegisterForgetPasswordRecoveryMethods from '../Mobile/Line/Register/ForgetPassword/RecoveryMethods'
import LineRegisterForgetUsername from '../Mobile/Line/Register/ForgetUsername'
import LineRegisterForgetPasswordVerifyOTP from '../Mobile/Line/Register/ForgetPassword/VerifyOTP'
import LineRegisterForgetPasswordComplete from '../Mobile/Line/Register/ForgetPassword/Complete'
import LineRegisterForgetPasswordSetNewPassword from '../Mobile/Line/Register/ForgetPassword/SetNewPassword'
import ManagementNotification from '../Complaint/Management/Notification'
import ManagementNotificationScheduleCronjob from '../Complaint/Management/Notification/ScheduleCronjob'
import ManagementNotificationAccordingFlowProcess from '../Complaint/Management/Notification/AccrodingFlowProcess'
import ToastMessage from '../../components/ToastMessage'
import ManagementMunicipalityLine from '../Complaint/Management/Line'

const App: React.FC = () => {
    useEffect(() => {
        const clearCacheData = () => {
            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name)
                })
            })
        }
        clearCacheData()
    }, [])
    return (
        <Router>
            <Alert />

            <Switch>
                <Route exact path='/' component={Login} />
                <Route exact path='/super_admin' component={SuperAdminLogin} />
                <Route exact path='/line' component={LineLogin} />
                <Route exact path='/line/register' component={LineRegisterMenu} />
                <Route exact path='/line/register/form' component={LineRegisterForm} />
                <Route exact path='/line/register/login' component={LineRegisterLoginOne} />
                <Route exact path='/line/register/forget-password' component={LineRegisterForgetPassword} />
                <Route
                    exact
                    path='/line/register/forget-password/recovery-methods'
                    component={LineRegisterForgetPasswordRecoveryMethods}
                />
                <Route
                    exact
                    path='/line/register/forget-password/verify-otp'
                    component={LineRegisterForgetPasswordVerifyOTP}
                />
                <Route
                    exact
                    path='/line/register/forget-password/set-new-password'
                    component={LineRegisterForgetPasswordSetNewPassword}
                />
                <Route
                    exact
                    path='/line/register/forget-password/complete'
                    component={LineRegisterForgetPasswordComplete}
                />
                <Route exact path='/line/register/forget-username' component={LineRegisterForgetUsername} />
                <Route exact path='/line/register-person' component={RegisterPersonForm} />
                <Route exact path='/line/profile' component={LineProfile} />
                <Route exact path='/line/edit-profile' component={LineEditProfile} />
                <Route exact path='/line/contact-municipality' component={LineContactMunicipality} />
                <Route exact path='/line/complaint/list' component={LineComplaintList} />
                <Route exact path='/line/complaint/form' component={LineComplaintForm} />
                <Route exact path='/line/complaint/detail' component={LineComplaintDetail} />
                <Route exact path='/line/complaint/evaluate' component={LineComplaintEvaluate} />
                <Route exact path='/line/complaint/detail-evaluate' component={LineComplaintDetailEvaluate} />
                <Route exact path='/one-chat' component={OneChatLogin} />
                <Route exact path='/one-chat/profile' component={OneChatProfile} />
                <Route exact path='/one-chat/edit-profile' component={OneChatEditProfile} />
                <Route exact path='/one-chat/complaint/list' component={OneChatComplaintList} />
                <Route exact path='/one-chat/complaint/form' component={OneChatComplaintForm} />
                <Route exact path='/one-chat/complaint/detail' component={OneChatComplaintDetail} />
                <Route exact path='/one-chat/complaint/evaluate' component={OneChatComplaintEvaluate} />
                <Route exact path='/one-chat/complaint/detail-evaluate' component={OneChatComplaintDetailEvaluate} />
                <Route
                    path='/:permission'
                    render={(props) => (
                        <CustomSidebar {...props}>
                            <ToastMessage />
                            <Switch>
                                <Route exact path={'/:permission'} component={MainPage} />
                                <Route exact path='/:permission/complaint/form' component={ComplaintListForm} />
                                <Route exact path='/:permission/complaint' component={ComplaintList} />
                                <Route exact path='/:permission/complaint/detail' component={ComplaintListDetail} />
                                <Route exact path='/:permission/complaint/export' component={ExportExcel} />
                                <Route
                                    exact
                                    path='/:permission/complaint/detail/export'
                                    component={ComplaintListDetailExportReport}
                                />
                                <Route
                                    exact
                                    path='/:permission/complaint/detail-pre-complaint'
                                    component={ComplaintListDetailPreComplaint}
                                />
                                <Route
                                    exact
                                    path='/:permission/complaint/detail/save-result'
                                    component={ComplaintListDetailSaveResult}
                                />
                                <Route
                                    exact
                                    path='/:permission/complaint/detail/detail-result'
                                    component={ComplaintListDetailDetailResult}
                                />
                                <Route exact path='/:permission/complaint/comment' component={CommentPeople} />
                                <Route exact path='/:permission/defect' component={Defect} />
                                <Route exact path='/:permission/municipalities' component={Municipalities} />
                                <Route exact path='/:permission/municipalities/menu' component={MunicipalitiesMenu} />
                                <Route exact path='/:permission/user-detail' component={UserDetail} />
                                <Route exact path='/:permission/user-detail/form-edit' component={FormEditUser} />
                                <Route exact path='/:permission/management/roles' component={ManagementRoles} />
                                <Route exact path='/:permission/management/users' component={ManagementUsers} />
                                <Route
                                    exact
                                    path='/:permission/management/approve-users'
                                    component={ManagementApproveUsers}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/approve-users/detail'
                                    component={ManagementApproveUsersDetail}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/users/detail'
                                    component={ManagementUsersDetail}
                                />

                                <Route
                                    exact
                                    path='/:permission/management/status-approve'
                                    component={ManagementStatusApprove}
                                />
                                <Route exact path='/:permission/management/actions' component={ManagementActions} />
                                <Route exact path='/:permission/management/pages' component={ManagementPages} />
                                <Route exact path='/:permission/management/menus' component={ManagementMenus} />
                                <Route
                                    exact
                                    path='/:permission/management/usage-municipality'
                                    component={ManagementUsageMunicipality}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/usage-role'
                                    component={ManagementUsageRole}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/usage-role/mapping'
                                    component={ManagementUsageRoleMapping}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/usage-user'
                                    component={ManagementUsageUser}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/usage-user/mapping'
                                    component={ManagementUsageUserMapping}
                                />

                                <Route
                                    exact
                                    path='/:permission/management/municipality/form'
                                    component={ManagementMunicipalityForm}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/municipality/detail'
                                    component={MunicipalitiesDetail}
                                />
                                <Route exact path='/:permission/management/system' component={System} />
                                <Route
                                    exact
                                    path='/:permission/management/system/districts-and-communities/list'
                                    component={ListDistrictsAndCommunities}
                                />
                                <Route exact path='/:permission/management/system/contacts' component={ListContacts} />
                                <Route
                                    exact
                                    path='/:permission/management/municipality/form-edit'
                                    component={ManagementMunicipalityFormEdit}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/municipality/line'
                                    component={ManagementMunicipalityLine}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/user-manual'
                                    component={ManagementUserManual}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/user-manual/topic'
                                    component={ManagementUserManualTopic}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/user-manual/sub-topic'
                                    component={ManagementUserManualSubTopic}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/user-manual/content'
                                    component={ManagementUserManualContent}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/user-manual/mapping'
                                    component={ManagementUserManualMapping}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/system/complaint/list'
                                    component={ListComplaint}
                                />

                                <Route exact path='/:permission/management/system/member/list' component={ListMember} />

                                <Route
                                    exact
                                    path='/:permission/management/system/member/detail'
                                    component={DetailStaff}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/directory-of-municipality/list'
                                    component={DirectoryOfMunicipality}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/directory-of-municipality/list/children'
                                    component={ListChildrenDirectory}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/directory-of-municipality/organization'
                                    component={ManagementDirectoryOrganizationChart}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/level-directory/list'
                                    component={ManagementLevelDirectory}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/level-directory/list/children'
                                    component={ManagementLevelDirectoryChildren}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/roles-in-level-directory'
                                    component={ManagementRoleInLevelDirectory}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/notification'
                                    component={ManagementNotification}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/notification/schedule-cronjob'
                                    component={ManagementNotificationScheduleCronjob}
                                />
                                <Route
                                    exact
                                    path='/:permission/management/notification/according-flow-process'
                                    component={ManagementNotificationAccordingFlowProcess}
                                />

                                <Route exact path='/:permission/dashboard' component={Dashboard} />
                                <Route exact path='/:permission/dashboard/map' component={DashboardMap} />

                                <Route
                                    exact
                                    path='/:permission/dashboard/detail-technician'
                                    component={DashboardTechnian}
                                />

                                <Route exact path='/:permission/user-manual' component={UserManual}></Route>
                                <Route
                                    exact
                                    path='/:permission/user-manual/detail'
                                    component={UserManualDetail}
                                ></Route>

                                <Route path='*'>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Image src='https://cdn0.techbang.com/system/excerpt_images/55555/special_headline/d5045073e258563e2b62eed24605cd60.png?1512550227' />
                                    </div>
                                </Route>
                            </Switch>
                        </CustomSidebar>
                    )}
                />
                <Route path='*'>
                    <Image src='https://cdn0.techbang.com/system/excerpt_images/55555/special_headline/d5045073e258563e2b62eed24605cd60.png?1512550227' />
                </Route>
            </Switch>
        </Router>
    )
}

export default App
