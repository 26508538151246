import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Container, Grid, Icon, Segment } from 'semantic-ui-react';
import ImagePattern from '../../../../assets/images/pattern.png';
import {
    GET_NOTIFICATION_ONE_CHAT_PROFILE,
    POST_NOTIFICATION_ONE_CHAT_CHECK_REGISTER,
} from '../../../../config/api/notification';

interface InterfaceProfileOneChat {
    account_id: string;
    alley: string;
    birth_date: string;
    created_at: string;
    created_by: number;
    deleted_at: string;
    district: string;
    email: string;
    first_name_en: string;
    first_name_th: string;
    house_no: string;
    id: number;
    id_card_number: string;
    id_card_type: string;
    image: string;
    last_name_en: string;
    last_name_th: string;
    line_user_id: string;
    municipalities_id: number;
    one_chat_user_id: string;
    one_mail: string;
    password: string;
    phone_number: string;
    prefix_en: string;
    prefix_th: string;
    province: string;
    road: string;
    sub_district: string;
    updated_at: string;
    updated_by: number;
    username: string;
    village_no: string;
    zipcode: string;
}

const OneChatProfile: React.FC = () => {
    let location = useLocation();
    let history = useHistory();
    let [loading, setLoading] = useState<boolean>(false);
    let [one_id, setOneId] = useState<string | null>('');
    let [municipality_id, setMunicipalityId] = useState<string | null>('');
    let [profile, setProfile] = useState<InterfaceProfileOneChat | undefined>();

    useEffect(() => {
        const getOneId = (onechat_token: string | null, municipality_id: string | null) => {
            // TODO: Check register
            setLoading(true);
            axios
                .post(`${POST_NOTIFICATION_ONE_CHAT_CHECK_REGISTER}`, {
                    municipality_id: Number(municipality_id),
                    onechat_token: onechat_token,
                })
                .then((response) => {
                    let { value } = response.data;
                    setOneId(value.one_id);
                    getProfile(value.one_id);
                });
        };
        const getProfile = (one_id: number) => {
            axios.get(`${GET_NOTIFICATION_ONE_CHAT_PROFILE}/${one_id}`).then((response) => {
                let { value } = response.data;

                setProfile(value);
                setLoading(false);
            });
        };

        const query = new URLSearchParams(location.search);
        const onechat_token = query.get('onechat_token');
        const municipality_id = query.get('municipality_id');
        setMunicipalityId(municipality_id);
        if (onechat_token) getOneId(onechat_token, municipality_id);
    }, [location]);

    const handleClickEditProfile = () => {
        history.push(`edit-profile?one_id=${one_id}&municipality_id=${municipality_id}`);
    };

    return (
        <Fragment>
            <div
                style={{
                    backgroundRepeat: 'repeat',
                    backgroundImage: `url(${ImagePattern})`,
                    position: 'absolute',
                    height: 250,
                    zIndex: 1,
                    width: '100%',
                }}
            />
            <div
                style={{
                    backgroundImage: 'linear-gradient(#3D97A7, #1D3853)',
                    position: 'absolute',
                    height: 250,
                    width: '100%',
                }}
            />
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column mobile='16'>
                            <Button
                                floated='right'
                                style={{ marginTop: 20, zIndex: 5 }}
                                icon
                                color='orange'
                                labelPosition='left'
                                onClick={handleClickEditProfile}>
                                <Icon name='edit' />
                                แก้ไขข้อมูล
                            </Button>
                        </Grid.Column>
                        <Grid.Column tablet='16' mobile='16'>
                            <Segment loading={loading} style={{ marginTop: 20, zIndex: 5 }}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column mobile='16'>
                                            <h3>
                                                <Icon name='user' style={{ color: '#55ABB5' }} /> ข้อมูลผู้ใช้งาน
                                            </h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>ชื่อ</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>
                                            {profile?.prefix_th}
                                            {profile?.first_name_th} {profile?.last_name_th}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='16'>
                                            <h3>
                                                <Icon name='home' style={{ color: '#55ABB5' }} /> ที่อยู่
                                            </h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>บ้านเลขที่</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>
                                            {profile?.house_no ? profile.house_no : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>หมู่ที่</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>
                                            {profile?.village_no ? profile.village_no : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>ซอย</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>{profile?.alley ? profile.alley : '-'}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>ถนน</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>{profile?.road ? profile.road : '-'}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>ตำบล</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>
                                            {profile?.sub_district ? profile.sub_district : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>อำเภอ</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>
                                            {profile?.district ? profile.district : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>จังหวัด</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>
                                            {profile?.province ? profile.province : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>รหัสไปรษณีย์</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>
                                            {profile?.zipcode ? profile.zipcode : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>หมายเลขโทรศัพท์</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>
                                            {profile?.phone_number ? profile.phone_number : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column mobile='5'>
                                            <h5>อีเมล</h5>
                                        </Grid.Column>
                                        <Grid.Column mobile='11'>{profile?.email ? profile.email : '-'}</Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Fragment>
    );
};
export default OneChatProfile;
