import { KEY_API_GOOGLE } from './router';

export function getGoogleMaps(){
	return new Promise((resolve) => {
		window.resolveGoogleMapsPromise = () => {
			resolve(window.google);
			delete window.resolveGoogleMapsPromise;
		};
		const script = document.createElement('script');
		script.src = `https://maps.googleapis.com/maps/api/js?key=${KEY_API_GOOGLE}&callback=resolveGoogleMapsPromise&libraries=places,drawing`;
		script.async = true;
		document.body.appendChild(script);
	});
}
