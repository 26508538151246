import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Breadcrumb, Container, Grid, Image, Segment } from 'semantic-ui-react'
import IconManangeNotificationSchedule from '../../../../assets/images/menu admin/noti/clock_noti.png'
import IconManangeNotificationInDepertment from '../../../../assets/images/menu admin/noti/department_noti.png'

const ManagementNotification: React.FC = () => {
    let history = useHistory()
    let location = useLocation()

    let [municipality_id, setMunicipalityID] = useState<number>(0)

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            setMunicipalityID(municipality_id)
        }
    }, [location])

    const handleClickScheduleCronjob = () => {
        history.push('notification/schedule-cronjob', {
            municipality_id: municipality_id,
        })
    }

    const handleClickAccordingFlowProcess = () => {
        history.push('notification/according-flow-process', {
            municipality_id: municipality_id,
        })
    }
    return (
        <div className='background-container'>
            <Container>
                <Grid>
                    <Grid.Row only='computer tablet'>
                        <Grid.Column style={{ zIndex: 1 }}>
                            <Breadcrumb>
                                <Breadcrumb.Section
                                    link
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/municipalities`, {
                                            municipality_id: municipality_id,
                                        })
                                    }}
                                >
                                    รายการองค์กร
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section
                                    link
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                            municipality_id: municipality_id,
                                        })
                                    }}
                                >
                                    กำหนดการใช้งานองค์กร
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />

                                <Breadcrumb.Section active>จัดการการแจ้งเตือน</Breadcrumb.Section>
                            </Breadcrumb>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <h2>จัดการการแจ้งเตือน</h2>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column computer='2' />
                        <Grid.Column computer='6' textAlign='center'>
                            <Segment style={{ cursor: 'pointer' }} onClick={handleClickScheduleCronjob}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Image src={IconManangeNotificationSchedule} style={{ height: 140 }} />
                                </div>
                                <h3> การแจ้งเตือนตามเวลา </h3>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column computer='6' textAlign='center'>
                            <Segment style={{ cursor: 'pointer' }} onClick={handleClickAccordingFlowProcess}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Image src={IconManangeNotificationInDepertment} style={{ height: 140 }} />
                                </div>
                                <h3> การแจ้งเตือนตามกระบวนการทำงาน </h3>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column computer='2' />
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    )
}

export default ManagementNotification
