import { ActionInterface } from '../../types'
import { MessageFirebase } from '../../types/Notification'

const initialState: MessageFirebase[] = []

const Notification = (state = initialState, action: ActionInterface): MessageFirebase[] => {
    switch (action.type) {
        case 'notification':
            return [...action.value]
        default:
            return state
    }
}

export default Notification
