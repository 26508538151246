/* eslint-disable no-loop-func */
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
    Breadcrumb,
    Button,
    Checkbox,
    Container,
    Dimmer,
    Grid,
    Icon,
    Input,
    List,
    Loader,
    Segment,
} from 'semantic-ui-react'
import { alert } from '../../../../components/alert'
import CustomSegment from '../../../../components/CustomSegment'
import { GET_MENU_PAGE_ACTION_ACTIVE } from '../../../../config/api/manageMenu'
import {
    POST_CREATE_AND_EDIT_MUNICIPALITY_HAS_MENU,
    GET_MUNICIPALITY_HAS_MENU_ALL,
} from '../../../../config/api/municipality'
import { axiosAuthen } from '../../../../config/axiosAuthen'

interface InterfaceListMenu {
    id: number
    name_th: string
    name_en: string
    pages: InterfaceListPage[]
}

interface InterfaceListPage {
    id: number
    name_th: string
    name_en: string
    actions: InterfaceListAction[]
}

interface InterfaceListAction {
    id: number
    name_th: string
    name_en: string
}

const ManagementUsageMunicipality: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [municipality_id, setMunicipalityId] = useState<string>()
    let [loading, setLoading] = useState<boolean>(false)
    let [loading_menu, setLoadingMenu] = useState<boolean>(true)
    let [all_menu, setAllMenu] = useState<InterfaceListMenu[]>([])
    let [list_menu, setListMenu] = useState<InterfaceListMenu[]>([]) //TODO: set value on search
    let [list_checked_menu, setListCheckedMenu] = useState<InterfaceListMenu[]>([])
    let [list_checked_page, setListCheckedPage] = useState<InterfaceListPage[]>([])
    let [list_checked_action, setListCheckedAction] = useState<InterfaceListAction[]>([])
    let [list_selected, setListSelected] = useState<InterfaceListMenu[]>([])
    let [list_selected_menu, setListSelectedMenu] = useState<InterfaceListMenu[]>([])
    let [list_selected_page, setListSelectedPage] = useState<InterfaceListPage[]>([])
    let [list_selected_action, setListSelectedAction] = useState<InterfaceListAction[]>([])
    let [list_unselected_menu, setListUnSelectedMenu] = useState<InterfaceListMenu[]>([])
    let [list_unselected_page, setListUnSelectedPage] = useState<InterfaceListPage[]>([])
    let [list_unselected_action, setListUnSelectedAction] = useState<InterfaceListAction[]>([])

    useEffect(() => {
        let { state } = location
        const getMunicipalityHasMenu = () => {
            if (state && all_menu.length > 0) {
                let location_state = JSON.parse(JSON.stringify(state, null, ' '))
                let { municipality_id } = location_state
                setMunicipalityId(municipality_id)
                // TODO : Get muni_has_permission_all/${muni_id}
                // TODO : Get muni_has_permission_all/${muni_id}
                axiosAuthen.get(`${GET_MUNICIPALITY_HAS_MENU_ALL}/${municipality_id}`).then((response) => {
                    setLoadingMenu(false)
                    let { value } = response.data
                    setListSelected(value)
                    let list_menu: InterfaceListMenu[] = []
                    let list_page: InterfaceListPage[] = []
                    let list_action: InterfaceListAction[] = []
                    if (value) {
                        for (const menu of value) {
                            let list_page_check_menu: InterfaceListPage[] = []
                            for (const page of menu.pages) {
                                let list_action_check_page: InterfaceListAction[] = []
                                for (const action of page.actions) {
                                    list_action.push({
                                        id: action.id,
                                        name_th: action.name_th,
                                        name_en: action.name_en,
                                    })
                                    list_action_check_page.push({
                                        id: action.id,
                                        name_th: action.name_th,
                                        name_en: action.name_en,
                                    })
                                }

                                let check_page =
                                    all_menu[all_menu.map((data) => data.id).indexOf(menu.id)].pages[
                                        all_menu[all_menu.map((data) => data.id).indexOf(menu.id)].pages
                                            .map((data) => data.id)
                                            .indexOf(page.id)
                                    ].actions.length === list_action_check_page.length

                                list_page = [...list_page, page]
                                list_page_check_menu.push(page)
                                if (!check_page) {
                                    list_page = list_page.filter(
                                        (page_select: InterfaceListPage) => page_select.id !== page.id
                                    )
                                    list_page_check_menu.pop()
                                }
                            }

                            let check_menu =
                                all_menu[all_menu.map((data) => data.id).indexOf(menu.id)].pages.length ===
                                list_page_check_menu.length
                            list_menu = [...list_menu, menu]
                            if (!check_menu) {
                                list_menu = list_menu.filter(
                                    (menu_select: InterfaceListMenu) => menu_select.id !== menu.id
                                )
                            } else {
                            }
                        }

                        setListSelectedMenu(list_menu)
                        setListSelectedPage(list_page)
                        setListSelectedAction(list_action)
                    }
                })
            }
        }
        getMunicipalityHasMenu()
    }, [all_menu, location])

    useEffect(() => {
        const getMenuPageActionActive = () => {
            axiosAuthen.get(GET_MENU_PAGE_ACTION_ACTIVE).then((response) => {
                let { value } = response.data
                setAllMenu(value)
                setListMenu(value)
            })
        }
        getMenuPageActionActive()
    }, [])

    const handleSubmit = () => {
        let list_selected_menu: InterfaceListMenu[] = []
        let list_selected_page: InterfaceListPage[] = []
        let list_selected_action: InterfaceListAction[] = []
        list_selected.forEach((menu) => {
            list_selected_menu.push(menu)
            menu.pages.forEach((page) => {
                list_selected_page.push(page)
                page.actions.forEach((action) => {
                    list_selected_action.push(action)
                })
            })
        })
        let body = {
            municipalities_id: municipality_id,
            menus: list_selected_menu.map((data: InterfaceListMenu) => data.id),
            pages: list_selected_page.map((data: InterfaceListPage) => data.id),
            actions: list_selected_action.map((data: InterfaceListAction) => data.id),
        }
        setLoading(true)
        axiosAuthen.post(POST_CREATE_AND_EDIT_MUNICIPALITY_HAS_MENU, body).then((response) => {
            alert({
                type: 'success',
                title: 'บันทึกเสร็จสิ้น',
                timer: 1500,
                onClosed: () => {
                    setLoading(false)
                    history.goBack()
                },
            })
        })
    }

    const handleClickSelectMenu = (menu: InterfaceListMenu) => {
        if (list_checked_menu.some((menu_select: InterfaceListMenu) => menu_select.id === menu.id)) {
            setListCheckedMenu(list_checked_menu.filter((menu_select: InterfaceListMenu) => menu_select.id !== menu.id))
            let list_data_in_menu = all_menu.find((data: InterfaceListMenu) => data.id === menu.id)
            if (list_data_in_menu && list_data_in_menu.pages) {
                let list_data_in_page = list_data_in_menu.pages
                list_checked_page = list_checked_page.filter(
                    (check: InterfaceListPage) =>
                        !list_data_in_menu?.pages.map((data: InterfaceListPage) => data.id).includes(check.id)
                )
                setListCheckedPage([...list_checked_page])
                list_data_in_page.forEach((page) => {
                    list_checked_action = page.actions.filter(
                        (check: InterfaceListAction) =>
                            !page?.actions.map((data: InterfaceListAction) => data.id).includes(check.id)
                    )
                    setListCheckedAction([...list_checked_action])
                })
            }
        } else {
            setListCheckedMenu([...list_checked_menu, menu])
            setListCheckedPage([...list_checked_page, ...menu.pages])
            let action: InterfaceListAction[] = list_checked_action
            menu.pages.forEach((page) => {
                action.push(...page.actions)
            })
            setListCheckedAction(action)
        }
    }

    const handleClickSelectPage = (menu: InterfaceListMenu, page: InterfaceListPage) => {
        let list_page = list_checked_page
        if (list_checked_page.some((page_select: InterfaceListPage) => page_select.id === page.id)) {
            list_page = list_checked_page.filter((page_select: InterfaceListPage) => page_select.id !== page.id)
            setListCheckedPage(list_page)
            page.actions.forEach((action) => {
                if (list_checked_action.some((action_select) => action_select === action)) {
                    list_checked_action = list_checked_action.filter((action_select) => action_select !== action)
                }
            })
            setListCheckedAction([...list_checked_action])
        } else {
            list_page = [...list_checked_page, page]
            setListCheckedPage(list_page)
            page.actions.forEach((action) => {
                if (!list_checked_action.some((action_select: InterfaceListAction) => action_select === action)) {
                    list_checked_action.push(action)
                }
            })
            setListCheckedAction(list_checked_action)
        }
        let check_menu = menu.pages.every((page) => list_page.includes(page))

        if (check_menu) {
            setListCheckedMenu([...list_checked_menu, menu])
        } else {
            setListCheckedMenu(list_checked_menu.filter((menu_select: InterfaceListMenu) => menu_select.id !== menu.id))
        }
    }

    const handleClickSelectAction = (menu: InterfaceListMenu, page: InterfaceListPage, action: InterfaceListAction) => {
        let list_action = list_checked_action
        let list_page = list_checked_page
        if (list_checked_action.some((action_select: InterfaceListAction) => action_select.id === action.id)) {
            list_action = list_checked_action.filter((action_select) => action_select !== action)
            setListCheckedAction(list_action)
        } else {
            list_action = [...list_checked_action, action]
            setListCheckedAction(list_action)
        }

        let check_page = page.actions.every((action) => list_action.includes(action))

        if (check_page) {
            list_page = [...list_checked_page, page]
            setListCheckedPage(list_page)
        } else {
            list_page = list_checked_page.filter((page_select: InterfaceListPage) => page_select.id !== page.id)
            setListCheckedPage(list_page)
        }

        let check_menu = menu.pages.every((page) => list_page.includes(page))

        if (check_menu) {
            setListCheckedMenu([...list_checked_menu, menu])
        } else {
            setListCheckedMenu(list_checked_menu.filter((menu_select: InterfaceListMenu) => menu_select.id !== menu.id))
        }
    }

    const handleClickUnSelectMenu = (menu: InterfaceListMenu) => {
        if (list_unselected_menu.some((menu_select: InterfaceListMenu) => menu_select.id === menu.id)) {
            setListUnSelectedMenu(
                list_unselected_menu.filter((menu_select: InterfaceListMenu) => menu_select.id !== menu.id)
            )
            let list_data_in_menu = all_menu.find((data: InterfaceListMenu) => data.id === menu.id)
            if (list_data_in_menu && list_data_in_menu.pages) {
                let list_data_in_page = list_data_in_menu.pages
                list_unselected_page = list_unselected_page.filter(
                    (check: InterfaceListPage) =>
                        !list_data_in_menu?.pages.map((data: InterfaceListPage) => data.id).includes(check.id)
                )
                setListUnSelectedPage([...list_unselected_page])
                list_data_in_page.forEach((page) => {
                    list_unselected_action = page.actions.filter(
                        (check: InterfaceListAction) =>
                            !page?.actions.map((data: InterfaceListAction) => data.id).includes(check.id)
                    )
                    setListUnSelectedAction([...list_unselected_action])
                })
            }
        } else {
            setListUnSelectedMenu([...list_unselected_menu, menu])
            setListUnSelectedPage([...list_unselected_page, ...menu.pages])
            let action: InterfaceListAction[] = list_unselected_action
            menu.pages.forEach((page) => {
                action.push(...page.actions)
            })
            setListUnSelectedAction(action)
        }
    }

    const handleClickUnSelectPage = (menu: InterfaceListMenu, page: InterfaceListPage) => {
        let list_page = list_unselected_page
        if (list_unselected_page.some((page_select: InterfaceListPage) => page_select.id === page.id)) {
            list_page = list_unselected_page.filter((page_select: InterfaceListPage) => page_select.id !== page.id)
            setListUnSelectedPage(list_page)
            page.actions.forEach((action) => {
                if (list_unselected_action.some((action_select) => action_select === action)) {
                    list_unselected_action = list_unselected_action.filter((action_select) => action_select !== action)
                }
            })
            setListUnSelectedAction([...list_unselected_action])
        } else {
            list_page = [...list_unselected_page, page]
            setListUnSelectedPage(list_page)
            page.actions.forEach((action) => {
                if (!list_unselected_action.some((action_select: InterfaceListAction) => action_select === action)) {
                    list_unselected_action.push(action)
                }
            })
            setListUnSelectedAction(list_unselected_action)
        }
        let check_menu = menu.pages.every((page) => list_page.includes(page))

        if (check_menu) {
            setListUnSelectedMenu([...list_unselected_menu, menu])
        } else {
            setListUnSelectedMenu(
                list_unselected_menu.filter((menu_select: InterfaceListMenu) => menu_select.id !== menu.id)
            )
        }
    }

    const handleClickUnSelectAction = (
        menu: InterfaceListMenu,
        page: InterfaceListPage,
        action: InterfaceListAction
    ) => {
        let list_action = list_unselected_action
        let list_page = list_unselected_page
        if (list_unselected_action.some((action_select: InterfaceListAction) => action_select.id === action.id)) {
            list_action = list_unselected_action.filter((action_select) => action_select !== action)
            setListUnSelectedAction(list_action)
        } else {
            list_action = [...list_unselected_action, action]
            setListUnSelectedAction(list_action)
        }

        let check_page = page.actions.every((action) => list_action.includes(action))

        if (check_page) {
            list_page = [...list_unselected_page, page]
            setListSelectedPage(list_page)
        } else {
            list_page = list_unselected_page.filter((page_select: InterfaceListPage) => page_select.id !== page.id)
            setListSelectedPage(list_page)
        }

        let check_menu = menu.pages.every((page) => list_page.includes(page))

        if (check_menu) {
            setListSelectedMenu([...list_unselected_menu, menu])
        } else {
            setListSelectedMenu(
                list_unselected_menu.filter((menu_select: InterfaceListMenu) => menu_select.id !== menu.id)
            )
        }
    }

    const setValueListSelected = () => {
        let new_list_selected: InterfaceListMenu[] = list_selected
        //TODO: push menu
        for (const menu of list_menu) {
            let list_page: InterfaceListPage[] = []
            for (const page of menu.pages) {
                //TODO: push page
                if (page.actions.some((action) => list_checked_action.includes(action))) {
                    if (!list_page.some((selected_page) => selected_page === page)) {
                        list_page.push({
                            id: page.id,
                            name_th: page.name_th,
                            name_en: page.name_en,
                            actions: [],
                        })
                    }
                    if (!new_list_selected.some((selected_menu) => selected_menu.id === menu.id)) {
                        new_list_selected.push({
                            id: menu.id,
                            name_th: menu.name_th,
                            name_en: menu.name_en,
                            pages: list_page,
                        })
                    } else {
                        if (
                            !new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages.some(
                                (selected_page) => selected_page === page
                            ) &&
                            page.actions.length === 0
                        ) {
                            new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages.push({
                                id: page.id,
                                name_th: page.name_th,
                                name_en: page.name_en,
                                actions: [],
                            })
                        }
                    }
                } else if (list_checked_page.includes(page)) {
                    if (!new_list_selected.some((selected_menu) => selected_menu.id === menu.id)) {
                        list_page.push({
                            id: page.id,
                            name_th: page.name_th,
                            name_en: page.name_en,
                            actions: [],
                        })
                        new_list_selected.push({
                            id: menu.id,
                            name_th: menu.name_th,
                            name_en: menu.name_en,
                            pages: list_page,
                        })
                    } else if (
                        !new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages.some(
                            (selected_page) => selected_page === page
                        )
                    ) {
                        new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages.push({
                            id: page.id,
                            name_th: page.name_th,
                            name_en: page.name_en,
                            actions: [],
                        })
                    }
                }
                for (const action of page.actions) {
                    if (list_checked_action.includes(action)) {
                        if (
                            new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages
                                .map((data) => data.id)
                                .indexOf(page.id) === -1
                        ) {
                            new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages.push({
                                id: page.id,
                                name_th: page.name_th,
                                name_en: page.name_en,
                                actions: [action],
                            })
                        } else {
                            new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages[
                                new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages
                                    .map((data) => data.id)
                                    .indexOf(page.id)
                            ].actions.push(action)
                        }
                    }
                }
            }
        }

        setListSelected(new_list_selected)
    }

    const setValueListUnSelected = () => {
        let new_list_selected: InterfaceListMenu[] = list_selected
        //TODO: remove menu
        for (const menu of new_list_selected) {
            for (const page of menu.pages) {
                if (
                    list_unselected_action.length > 0 &&
                    ~new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages
                        .map((data) => data.id)
                        .indexOf(page.id)
                ) {
                    //TODO: remove action

                    new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages[
                        new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages
                            .map((data) => data.id)
                            .indexOf(page.id)
                    ].actions = new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages[
                        new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages
                            .map((data) => data.id)
                            .indexOf(page.id)
                    ].actions.filter((action) => !list_unselected_action.includes(action))
                    if (list_unselected_page.length > 0) {
                        //TODO: remove page
                        new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages =
                            new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages.filter(
                                (page) => !list_unselected_page.includes(page)
                            )
                    }
                } else if (list_unselected_page.length > 0) {
                    //TODO: remove page

                    if (~new_list_selected.map((data) => data.id).indexOf(menu.id))
                        new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages =
                            new_list_selected[new_list_selected.map((data) => data.id).indexOf(menu.id)].pages.filter(
                                (page) => !list_unselected_page.map((data) => data.id).includes(page.id)
                            )
                }
            }
            if (list_unselected_menu.length > 0) {
                new_list_selected = new_list_selected.filter(
                    (menu) => !list_unselected_menu.map((data) => data.id).includes(menu.id)
                )
            }
        }

        setListSelected(new_list_selected)
    }

    const addMenuSelected = () => {
        setValueListSelected()
        setListSelectedMenu([...list_selected_menu, ...list_checked_menu])
        setListSelectedPage([...list_selected_page, ...list_checked_page])
        setListSelectedAction([...list_selected_action, ...list_checked_action])
        setListCheckedMenu([])
        setListCheckedPage([])
        setListCheckedAction([])
    }

    const removeMenuSelected = () => {
        setValueListUnSelected()
        setListSelectedMenu(
            list_selected_menu.filter((menu) => !list_unselected_menu.map((data) => data.id).includes(menu.id))
        )
        setListSelectedPage(
            list_selected_page.filter((page) => !list_unselected_page.map((data) => data.id).includes(page.id))
        )
        setListSelectedAction(list_selected_action.filter((action) => !list_unselected_action.includes(action)))
        setListUnSelectedMenu([])
        setListUnSelectedPage([])
        setListUnSelectedAction([])
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Dimmer page active={loading} inverted>
                        <Loader />
                    </Dimmer>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities`)
                                        }}
                                    >
                                        รายการองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                municipality_id: municipality_id,
                                            })
                                        }}
                                    >
                                        กำหนดการใช้งานองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>จัดการการใช้งานขององค์กร</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>จัดการการใช้งานขององค์กร</h2>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column computer='16'>
                                <CustomSegment icon='file alternate' header='การใช้งานของระบบ'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='7'>
                                                <h3 style={{ textAlign: 'center' }}>
                                                    รายการเมนู หน้าเว็บไซต์และการใช้งาน
                                                </h3>
                                                <Input fluid icon='search' placeholder='ค้นหา' />
                                                <Segment
                                                    style={{
                                                        border: '1px solid #C4C4C4',
                                                    }}
                                                    loading={loading_menu}
                                                >
                                                    <div
                                                        style={{
                                                            overflowX: 'auto',
                                                            height: 320,
                                                            paddingRight: 5,
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        <List>
                                                            {list_menu.map(
                                                                (menu: InterfaceListMenu, index_menu: number) => (
                                                                    <List.Item key={index_menu}>
                                                                        <List.Icon
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() =>
                                                                                !list_selected_menu.some(
                                                                                    (menu_select: InterfaceListMenu) =>
                                                                                        menu_select.id === menu.id
                                                                                ) && handleClickSelectMenu(menu)
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                disabled={list_selected_menu.some(
                                                                                    (menu_select: InterfaceListMenu) =>
                                                                                        menu_select.id === menu.id
                                                                                )}
                                                                                checked={
                                                                                    list_checked_menu.some(
                                                                                        (
                                                                                            menu_select: InterfaceListMenu
                                                                                        ) => menu_select.id === menu.id
                                                                                    ) ||
                                                                                    list_selected_menu.some(
                                                                                        (
                                                                                            menu_select: InterfaceListMenu
                                                                                        ) => menu_select.id === menu.id
                                                                                    )
                                                                                }
                                                                            />
                                                                        </List.Icon>
                                                                        <List.Content>
                                                                            <List.Header
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() =>
                                                                                    !list_selected_menu.some(
                                                                                        (
                                                                                            menu_select: InterfaceListMenu
                                                                                        ) => menu_select.id === menu.id
                                                                                    ) && handleClickSelectMenu(menu)
                                                                                }
                                                                            >
                                                                                <h3>เมนู : {menu.name_th}</h3>
                                                                            </List.Header>
                                                                            <List.List>
                                                                                {menu.pages.map(
                                                                                    (
                                                                                        page: InterfaceListPage,
                                                                                        index_page: number
                                                                                    ) => (
                                                                                        <List.Item key={index_page}>
                                                                                            <List.Icon
                                                                                                style={{
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                    !list_selected_page.some(
                                                                                                        (
                                                                                                            page_select: InterfaceListPage
                                                                                                        ) =>
                                                                                                            page_select.id ===
                                                                                                            page.id
                                                                                                    ) &&
                                                                                                    handleClickSelectPage(
                                                                                                        menu,
                                                                                                        page
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <Checkbox
                                                                                                    checked={
                                                                                                        list_checked_page.some(
                                                                                                            (
                                                                                                                page_select: InterfaceListPage
                                                                                                            ) =>
                                                                                                                page_select.id ===
                                                                                                                page.id
                                                                                                        ) ||
                                                                                                        list_selected_page.some(
                                                                                                            (
                                                                                                                page_select: InterfaceListPage
                                                                                                            ) =>
                                                                                                                page_select.id ===
                                                                                                                page.id
                                                                                                        )
                                                                                                    }
                                                                                                    disabled={list_selected_page.some(
                                                                                                        (
                                                                                                            page_select: InterfaceListPage
                                                                                                        ) =>
                                                                                                            page_select.id ===
                                                                                                            page.id
                                                                                                    )}
                                                                                                />
                                                                                            </List.Icon>
                                                                                            <List.Content>
                                                                                                <List.Header
                                                                                                    style={{
                                                                                                        cursor: 'pointer',
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        !list_selected_page.some(
                                                                                                            (
                                                                                                                page_select: InterfaceListPage
                                                                                                            ) =>
                                                                                                                page_select.id ===
                                                                                                                page.id
                                                                                                        ) &&
                                                                                                        handleClickSelectPage(
                                                                                                            menu,
                                                                                                            page
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <h4>
                                                                                                        หน้าเว็บไซต์ :{' '}
                                                                                                        {page.name_th}
                                                                                                    </h4>
                                                                                                </List.Header>

                                                                                                <List.List>
                                                                                                    {page.actions.map(
                                                                                                        (
                                                                                                            action: InterfaceListAction,
                                                                                                            index_action: number
                                                                                                        ) => (
                                                                                                            <List.Item
                                                                                                                key={
                                                                                                                    index_action
                                                                                                                }
                                                                                                            >
                                                                                                                <List.Icon
                                                                                                                    style={{
                                                                                                                        cursor: 'pointer',
                                                                                                                    }}
                                                                                                                    onClick={() =>
                                                                                                                        !list_selected_action.some(
                                                                                                                            (
                                                                                                                                action_select: InterfaceListAction
                                                                                                                            ) =>
                                                                                                                                action_select.id ===
                                                                                                                                action.id
                                                                                                                        ) &&
                                                                                                                        handleClickSelectAction(
                                                                                                                            menu,
                                                                                                                            page,
                                                                                                                            action
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <Checkbox
                                                                                                                        checked={
                                                                                                                            list_checked_action.some(
                                                                                                                                (
                                                                                                                                    action_select: InterfaceListAction
                                                                                                                                ) =>
                                                                                                                                    action_select.id ===
                                                                                                                                    action.id
                                                                                                                            ) ||
                                                                                                                            list_selected_action.some(
                                                                                                                                (
                                                                                                                                    action_select: InterfaceListAction
                                                                                                                                ) =>
                                                                                                                                    action_select.id ===
                                                                                                                                    action.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                        disabled={list_selected_action.some(
                                                                                                                            (
                                                                                                                                action_select: InterfaceListAction
                                                                                                                            ) =>
                                                                                                                                action_select.id ===
                                                                                                                                action.id
                                                                                                                        )}
                                                                                                                    />
                                                                                                                </List.Icon>
                                                                                                                <List.Content>
                                                                                                                    <List.Description
                                                                                                                        style={{
                                                                                                                            cursor: 'pointer',
                                                                                                                        }}
                                                                                                                        onClick={() =>
                                                                                                                            !list_selected_action.some(
                                                                                                                                (
                                                                                                                                    action_select: InterfaceListAction
                                                                                                                                ) =>
                                                                                                                                    action_select.id ===
                                                                                                                                    action.id
                                                                                                                            ) &&
                                                                                                                            handleClickSelectAction(
                                                                                                                                menu,
                                                                                                                                page,
                                                                                                                                action
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        การใช้งาน
                                                                                                                        :{' '}
                                                                                                                        {
                                                                                                                            action.name_th
                                                                                                                        }
                                                                                                                    </List.Description>
                                                                                                                </List.Content>
                                                                                                            </List.Item>
                                                                                                        )
                                                                                                    )}
                                                                                                </List.List>
                                                                                            </List.Content>
                                                                                        </List.Item>
                                                                                    )
                                                                                )}
                                                                            </List.List>
                                                                        </List.Content>
                                                                    </List.Item>
                                                                )
                                                            )}
                                                        </List>
                                                    </div>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column
                                                computer='2'
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: 20,
                                                }}
                                            >
                                                <Grid>
                                                    <Grid.Row
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Grid.Column computer='8'>
                                                            <Button
                                                                primary={
                                                                    list_checked_menu.length > 0 ||
                                                                    list_checked_page.length > 0 ||
                                                                    list_checked_action.length > 0
                                                                }
                                                                disabled={
                                                                    list_checked_menu.length === 0 &&
                                                                    list_checked_page.length === 0 &&
                                                                    list_checked_action.length === 0
                                                                }
                                                                icon
                                                                fluid
                                                                onClick={addMenuSelected}
                                                            >
                                                                <Icon name='angle double right' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Grid.Column computer='8'>
                                                            <Button
                                                                primary={
                                                                    list_unselected_menu.length > 0 ||
                                                                    list_unselected_page.length > 0 ||
                                                                    list_unselected_action.length > 0
                                                                }
                                                                disabled={
                                                                    list_unselected_menu.length === 0 &&
                                                                    list_unselected_page.length === 0 &&
                                                                    list_unselected_action.length === 0
                                                                }
                                                                icon
                                                                fluid
                                                                onClick={removeMenuSelected}
                                                            >
                                                                <Icon name='angle double left' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column computer='7'>
                                                <h3 style={{ textAlign: 'center' }}>
                                                    รายการเมนู หน้าเว็บไซต์และการใช้งานที่เลือก
                                                </h3>
                                                <Input fluid icon='search' placeholder='ค้นหา' />
                                                <Segment
                                                    style={{
                                                        border: '1px solid #C4C4C4',
                                                    }}
                                                    loading={loading_menu}
                                                >
                                                    <div
                                                        style={{
                                                            overflowX: 'auto',
                                                            height: 320,
                                                            paddingRight: 5,
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        <List>
                                                            {list_selected.map(
                                                                (menu: InterfaceListMenu, index_menu: number) => (
                                                                    <List.Item key={index_menu}>
                                                                        <List.Icon
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() =>
                                                                                handleClickUnSelectMenu(menu)
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                checked={list_unselected_menu.some(
                                                                                    (menu_select: InterfaceListMenu) =>
                                                                                        menu_select.id === menu.id
                                                                                )}
                                                                            />
                                                                        </List.Icon>
                                                                        <List.Content>
                                                                            <List.Header
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() =>
                                                                                    handleClickUnSelectMenu(menu)
                                                                                }
                                                                            >
                                                                                <h3>เมนู : {menu.name_th}</h3>
                                                                            </List.Header>
                                                                            <List.List>
                                                                                {menu.pages.map(
                                                                                    (
                                                                                        page: InterfaceListPage,
                                                                                        index_page: number
                                                                                    ) => (
                                                                                        <List.Item key={index_page}>
                                                                                            <List.Icon
                                                                                                style={{
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                    handleClickUnSelectPage(
                                                                                                        menu,
                                                                                                        page
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <Checkbox
                                                                                                    checked={list_unselected_page.some(
                                                                                                        (
                                                                                                            page_select: InterfaceListPage
                                                                                                        ) =>
                                                                                                            page_select.id ===
                                                                                                            page.id
                                                                                                    )}
                                                                                                />
                                                                                            </List.Icon>
                                                                                            <List.Content>
                                                                                                <List.Header
                                                                                                    style={{
                                                                                                        cursor: 'pointer',
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        handleClickUnSelectPage(
                                                                                                            menu,
                                                                                                            page
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <h4>
                                                                                                        หน้าเว็บไซต์ :{' '}
                                                                                                        {page.name_th}
                                                                                                    </h4>
                                                                                                </List.Header>

                                                                                                <List.List>
                                                                                                    {page.actions.map(
                                                                                                        (
                                                                                                            action: InterfaceListAction,
                                                                                                            index_action: number
                                                                                                        ) => (
                                                                                                            <List.Item
                                                                                                                key={
                                                                                                                    index_action
                                                                                                                }
                                                                                                            >
                                                                                                                <List.Icon
                                                                                                                    style={{
                                                                                                                        cursor: 'pointer',
                                                                                                                    }}
                                                                                                                    onClick={() =>
                                                                                                                        handleClickUnSelectAction(
                                                                                                                            menu,
                                                                                                                            page,
                                                                                                                            action
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <Checkbox
                                                                                                                        checked={list_unselected_action.some(
                                                                                                                            (
                                                                                                                                action_select: InterfaceListAction
                                                                                                                            ) =>
                                                                                                                                action_select.id ===
                                                                                                                                action.id
                                                                                                                        )}
                                                                                                                    />
                                                                                                                </List.Icon>
                                                                                                                <List.Content>
                                                                                                                    <List.Description
                                                                                                                        style={{
                                                                                                                            cursor: 'pointer',
                                                                                                                        }}
                                                                                                                        onClick={() =>
                                                                                                                            handleClickUnSelectAction(
                                                                                                                                menu,
                                                                                                                                page,
                                                                                                                                action
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        การใช้งาน
                                                                                                                        :{' '}
                                                                                                                        {
                                                                                                                            action.name_th
                                                                                                                        }
                                                                                                                    </List.Description>
                                                                                                                </List.Content>
                                                                                                            </List.Item>
                                                                                                        )
                                                                                                    )}
                                                                                                </List.List>
                                                                                            </List.Content>
                                                                                        </List.Item>
                                                                                    )
                                                                                )}
                                                                            </List.List>
                                                                        </List.Content>
                                                                    </List.Item>
                                                                )
                                                            )}
                                                        </List>
                                                    </div>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16'>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        floated='right'
                                        style={{ backgroundColor: '#293757', color: 'white' }}
                                        onClick={handleSubmit}
                                    >
                                        บันทึก
                                    </Button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default ManagementUsageMunicipality
