import React, { useState } from 'react'
import { Button, Checkbox, Grid, Icon, Segment } from 'semantic-ui-react'
import CustomModal from '../../../../components/CustomModal'
import {
    GET_DIRECTORY_OF_MUNICIPALITY_ROLE_IN_DIRECTORY,
    PUT_DIRECTORY_OF_MUNICIPALITY_MAPPING_DIRECTORY_AND_ROLES,
} from '../../../../config/api/directoryOfMunicipality'
import { GET_ROLE_BY_MUNICIPALITY } from '../../../../config/api/roles'
import { axiosAuthen } from '../../../../config/axiosAuthen'

interface InterfaceValueRole {
    id: number
    name: string
    name_en: string
}
interface InterfaceValueRole {
    id: number
    name: string
    name_en: string
}
type ManagementDirectoryMappingRolesProps = {
    municipality_id?: number
    directory_id?: number
    level_name_selected?: string
}

const ManagementDirectoryMappingRoles: React.FC<ManagementDirectoryMappingRolesProps> = (props) => {
    let { level_name_selected, directory_id, municipality_id } = props
    let [roles, setRoles] = useState<[]>([])
    let [open_modal, setOpenModal] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(true)
    let [roles_selected, setRolesSelected] = useState<number[]>([])

    const handleSelectRole = (role_id: number) => {
        if (roles_selected.some((select) => select === role_id)) {
            let role_select = roles_selected.filter((select) => select !== role_id)
            setRolesSelected(role_select)
        } else {
            setRolesSelected([...roles_selected, role_id])
        }
    }

    const handleOpen = () => {
        setOpenModal(true)
        const getRolesInDirectory = () => {
            axiosAuthen
                .get(
                    `${GET_DIRECTORY_OF_MUNICIPALITY_ROLE_IN_DIRECTORY}?municipality_id=${municipality_id}&directory_id=${directory_id}`
                )
                .then((response) => {
                    let { value } = response.data
                    setRolesSelected(value.map((data: InterfaceValueRole) => data.id))
                    setLoading(false)
                })
        }
        const getRoles = () => {
            axiosAuthen.get(`${GET_ROLE_BY_MUNICIPALITY}/${municipality_id}`).then((response) => {
                let { value } = response.data
                setRoles(value)
                setLoading(false)
                getRolesInDirectory()
            })
        }

        getRoles()
    }

    const handleClose = () => {
        setOpenModal(false)
        setRolesSelected([])
    }

    const handleSubmit = () => {
        let body = {
            directory_id: directory_id,
            roles_id: roles_selected,
            municipality_id: municipality_id,
        }
        setLoading(true)
        axiosAuthen.put(PUT_DIRECTORY_OF_MUNICIPALITY_MAPPING_DIRECTORY_AND_ROLES, body).then(() => {
            handleClose()
        })
    }

    return (
        <CustomModal
            icon='sitemap'
            centered={false}
            header='จัดการสิทธิ์ในระดับหน่วยงาน'
            size='mini'
            open={open_modal}
            onClose={handleClose}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            trigger={
                <Button basic color='green' onClick={handleOpen}>
                    <Icon name='lock' /> กำหนดสิทธิ์ในหน่วยงาน
                </Button>
            }
        >
            <Grid>
                <Grid.Row>
                    <Grid.Column textAlign='center'>
                        <h3>ระดับหน่วยงาน : {level_name_selected}</h3>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Segment loading={loading} style={{ overflowX: 'auto', height: 500 }}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>
                                        <h4>รายการสิทธิ์</h4>
                                    </Grid.Column>
                                </Grid.Row>
                                {roles.map((role: InterfaceValueRole) => (
                                    <Grid.Row key={role.id}>
                                        <Grid.Column>
                                            <Segment
                                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                                onClick={() => handleSelectRole(role.id)}
                                            >
                                                <Checkbox
                                                    checked={roles_selected.some((selected) => selected === role.id)}
                                                />{' '}
                                                <label style={{ paddingLeft: 10, cursor: 'pointer' }}>
                                                    {' '}
                                                    {role.name}
                                                </label>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                ))}
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column textAlign='center'>
                        <Button
                            style={{
                                width: 100,
                                backgroundColor: '#283655',
                                color: '#FFF',
                                marginRight: 15,
                            }}
                            onClick={handleSubmit}
                        >
                            บันทึก
                        </Button>
                        <Button
                            style={{
                                width: 100,
                                backgroundColor: '#C54C40',
                                color: '#FFF',
                                marginLeft: 15,
                            }}
                            onClick={handleClose}
                        >
                            ยกเลิก
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </CustomModal>
    )
}

export default ManagementDirectoryMappingRoles
