import React, { Fragment, useEffect, useState } from 'react'

import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, PDFDownloadLink } from '@react-pdf/renderer'
import Sarabun from '../../../../../assets/fonts/Sarabun-Light.ttf'
import { Button, Container, Dimmer, Loader } from 'semantic-ui-react'
import { GET_COMPLAINT_DETAIL } from '../../../../../config/api/complaint'
import { useLocation } from 'react-router-dom'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { InterfaceComplaintDetail } from '..'
import FullFormatDate from '../../../../../functions/FullFormatDateTH'

Font.register({ family: 'Sarabun', src: Sarabun })

const styles = StyleSheet.create({
    page: {
        margin: 25,
        marginLeft: 46,
        marginRight: 25,
        fontFamily: 'Sarabun',
        fontSize: 12,
        width: 525,
    },
    header: {
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        marginRight: 160,
        fontWeight: 900,
        fontSize: 25,
    },
    font_bold: {
        fontWeight: 'bold',
    },
    row: {
        marginTop: 3,
        flexDirection: 'row',
    },
})

const ComplaintListDetailExportReport: React.FC = () => {
    let location = useLocation()
    const [data, setData] = useState<InterfaceComplaintDetail>()
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        const getDetailComplaint = () => {
            let { state } = location
            if (state) {
                let location_state = JSON.parse(JSON.stringify(state, null, ' '))
                let { complaint_id } = location_state
                setLoading(true)
                axiosAuthen.get(`${GET_COMPLAINT_DETAIL}/${complaint_id}`).then((response) => {
                    let { value } = response.data
                    setData(value)
                    setLoading(false)
                })
            }
        }
        getDetailComplaint()
    }, [location])

    if (loading) {
        return (
            <Container style={{ height: window.innerHeight }}>
                <Dimmer active inverted page>
                    <Loader size='huge'>รอสักครู่</Loader>
                </Dimmer>
            </Container>
        )
    } else {
        return (
            <div
                style={{
                    backgroundColor: '#F1F1F1',
                    padding: 20,
                    marginTop: 10,
                    paddingLeft: 30,
                    paddingRight: 30,
                }}
            >
                <PDFDownloadLink document={<FormDocument detail={data} />} fileName='รายละเอียดคำร้อง.pdf'>
                    {loading ? (
                        <Button primary disabled floated='right'>
                            กำลังโหลดเอกสาร
                        </Button>
                    ) : (
                        <Fragment>
                            <Button primary floated='right'>
                                ดาวน์โหลดเอกสาร
                            </Button>
                            <PDFViewer
                                width={window.innerWidth - 60}
                                height={window.innerHeight - 160}
                                style={{ marginTop: 10 }}
                            >
                                <FormDocument detail={data} />
                            </PDFViewer>
                        </Fragment>
                    )}
                </PDFDownloadLink>
            </div>
        )
    }
}

const FormDocument = ({ detail }: any) => {
    if (detail) {
        return (
            <Document title='รายละเอียดคำร้อง'>
                <Page style={styles.page}>
                    <View
                        style={{
                            ...styles.row,
                            ...{
                                display: 'flex',
                                justifyContent: 'center',
                                width: 510,
                            },
                        }}
                    >
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    fontSize: 20,
                                },
                            }}
                        >
                            {`รายละเอียดคำร้อง `}
                        </Text>
                    </View>
                    <Br />
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`เลขที่คำร้อง `}</Text>
                        <Text style={{ fontSize: 12, marginTop: 1 }}>{`${detail.department_complaint_no}`} </Text>
                    </View>
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`วัน/เดือน/ปี ที่รับเรื่อง`}</Text>
                        <Text style={{ fontSize: 12, marginTop: 1 }}>
                            {`${FullFormatDate(detail.complaint_date)}`}{' '}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`หน่วยงาน`}</Text>
                        <Text style={{ fontSize: 12, marginTop: 1 }}>
                            {`${detail.directory_of_municipalities_name}`}{' '}
                        </Text>
                    </View>

                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`เรื่อง`}</Text>
                        <Text style={{ fontSize: 12, marginTop: 1 }}>{`${detail.complaint_subject}`} </Text>
                    </View>
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`ช่องทางการรับเรื่อง`}</Text>
                        <Text style={{ fontSize: 12, marginTop: 1 }}>{`${detail.contact_name}`} </Text>
                    </View>
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`พื้นที่`}</Text>
                        <View>
                            <View style={styles.row}>
                                <Text
                                    style={{
                                        ...styles.font_bold,
                                        ...{},
                                    }}
                                >{`เขต : `}</Text>
                                <Text style={{ fontSize: 12, marginTop: 1 }}>{`${detail.area_name}`}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text
                                    style={{
                                        ...styles.font_bold,
                                        ...{},
                                    }}
                                >{`ชุมชน  : `}</Text>
                                <Text style={{ fontSize: 12, marginTop: 1 }}>{`${detail.community_name}`}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`ข้อมูลผู้ยื่นคำร้อง `}</Text>
                        <Text style={{ fontSize: 12, marginTop: 1 }}>
                            {`${detail.first_name} ${detail.last_name}`}{' '}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`ที่อยู่`}</Text>
                        <View>
                            <View style={styles.row}>
                                <Text style={{ fontSize: 12, marginTop: 1 }}>
                                    {`บ้านเลขที่ ${detail.house_no ? detail.house_no : '-'} หมู่ที่ ${
                                        detail.village_no ? detail.village_no : '-'
                                    } ซอย ${detail.alley ? detail.alley : '-'} ถนน ${detail.road ? detail.road : '-'}`}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={{ fontSize: 12, marginTop: 1 }}>
                                    {`ตำบล${detail.subdistrict ? detail.subdistrict : '-'} อำเภอ${
                                        detail.district ? detail.district : '-'
                                    } จังหวัด${detail.province ? detail.province : '-'} ${detail.zipcode}`}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`โทรศัพท์มือถือ`}</Text>
                        <Text style={{ fontSize: 12, marginTop: 1, width: '75%' }}>{`${detail.mobile_number}`} </Text>
                    </View>
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`เรื่องที่ยื่นคำร้อง `}</Text>
                        <View>
                            <View style={styles.row}>
                                <Text
                                    style={{
                                        ...styles.font_bold,
                                        ...{},
                                    }}
                                >{`เรื่องร้องทุกข์ : `}</Text>
                                <Text style={{ fontSize: 12, marginTop: 1 }}>{`${detail.complaint_topics_name}`}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text
                                    style={{
                                        ...styles.font_bold,
                                        ...{},
                                    }}
                                >{`ประเภทงาน  : `}</Text>
                                <Text style={{ fontSize: 12, marginTop: 1 }}>{`${detail.complaint_types_name}`}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`รายละเอียด`}</Text>
                        <Text style={{ fontSize: 12, marginTop: 1, width: '75%' }}>
                            {`${detail.complaint_detail}`}{' '}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text
                            style={{
                                ...styles.font_bold,
                                ...{
                                    width: '25%',
                                },
                            }}
                        >{`ช่างที่รับผิดชอบ`}</Text>
                        <Text style={{ fontSize: 12, marginTop: 1, width: '75%' }}>
                            {`${detail.full_name_technician}`}{' '}
                        </Text>
                    </View>
                </Page>
            </Document>
        )
    } else {
        return (
            <Document title='รายละเอียดคำร้อง'>
                <Page style={styles.page} />
            </Document>
        )
    }
}

const Br = ({ style }: any) => (
    <View style={{ ...styles.row, ...style }}>
        <Text> </Text>
    </View>
)

export default ComplaintListDetailExportReport
