import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Breadcrumb, Container, Grid, Image, Segment } from 'semantic-ui-react'
import { alert } from '../../../../../components/alert'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import CustomSegment from '../../../../../components/CustomSegment'
import { DELETE_USER_DELETE, GET_USER_DETAIL_BY_ID, PUT_USER_RESET_PASSWORD } from '../../../../../config/api/user'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { HOST } from '../../../../../config/router'
import ManagementUserDetailChangePassword from './ModalChangePassword'

interface InterfaceUserDetail {
    alley: string
    birth_date: string
    directory_of_municipalities_name: string
    district: string
    email: string
    first_name_en: string
    first_name_th: string
    house_no: string
    id_card_number: string
    image: string
    last_name_en: string
    last_name_th: string
    municipalities_name: string
    one_mail: string
    phone_number: string
    position: string
    prefix_en: string
    prefix_th: string
    province: string
    road: string
    roles_name: string
    sub_district: string
    username: string
    village_no: string
    zipcode: string
}
const ManagementUsersDetail: React.FC = () => {
    let [loading, setLoading] = useState<boolean>(true)
    let [user_id, setUserId] = useState<number>()
    let [open_modal_form, setOpenModalForm] = useState<boolean>(false)
    let location = useLocation()
    let history = useHistory()

    let [user_data, setUserData] = useState<InterfaceUserDetail>()
    let [municipality, setMunicipality] = useState<string | number | undefined>()

    useEffect(() => {
        let { state } = location
        let obj = JSON.parse(JSON.stringify(state, null, ' '))
        let { user_id, municipality_id } = obj
        setUserId(user_id)
        if (municipality_id) setMunicipality(municipality_id)
        axiosAuthen.get(`${GET_USER_DETAIL_BY_ID}/${user_id}`).then((response) => {
            let { value } = response.data
            setUserData(value)
            setLoading(false)
        })
        window.scrollTo(0, 0)
    }, [location])

    const handleCloseModalForm = () => setOpenModalForm(false)

    const handleResetPassword = () => {
        alert({
            type: 'warning',
            title: 'รีเซ็ตรหัสผ่าน',
            text: 'รีเซ็ตรหัสผ่านใหม่ตามที่ถูกกำหนดไว้โดยระบบ',
            confirmText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onConfirm: () => {
                axiosAuthen
                    .put(PUT_USER_RESET_PASSWORD, {
                        id: user_id,
                    })
                    .then(() => {
                        alert({
                            type: 'success',
                            title: 'บันทึกเสร็จสิ้น',
                            timer: 1500,
                            onClosed: () => {
                                history.goBack()
                            },
                        })
                    })
            },
        })
    }

    const handleDeleteUser = () => {
        alert({
            type: 'error',
            title: 'ลบข้อมูลผู้ใช้',
            text: 'หากลบแล้วจะไม่สามารถนำข้อมูลกลับมาได้',
            confirmText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onConfirm: () => {
                axiosAuthen.delete(`${DELETE_USER_DELETE}/${user_id}`).then(() => {
                    alert({
                        type: 'success',
                        title: 'บันทึกเสร็จสิ้น',
                        timer: 1500,
                        onClosed: () => {
                            history.goBack()
                        },
                    })
                })
            },
        })
    }

    return (
        <Fragment>
            <Fragment>
                <div className='background-container'>
                    <Container>
                        {open_modal_form && (
                            <ManagementUserDetailChangePassword open={open_modal_form} onClose={handleCloseModalForm} />
                        )}
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Breadcrumb>
                                        {localStorage.permission_en === 'super_admin' && (
                                            <Fragment>
                                                <Breadcrumb.Section
                                                    link
                                                    onClick={() => {
                                                        history.push(`/${localStorage.permission_en}/municipalities`)
                                                    }}
                                                >
                                                    รายการองค์กร
                                                </Breadcrumb.Section>
                                                <Breadcrumb.Divider />
                                                <Breadcrumb.Section
                                                    link
                                                    onClick={() => {
                                                        history.push(
                                                            `/${localStorage.permission_en}/municipalities/menu`,
                                                            {
                                                                municipality_id: municipality,
                                                            }
                                                        )
                                                    }}
                                                >
                                                    กำหนดการใช้งานองค์กร
                                                </Breadcrumb.Section>
                                                <Breadcrumb.Divider />
                                            </Fragment>
                                        )}
                                        <Breadcrumb.Section
                                            link
                                            onClick={() => {
                                                // history.push(`/${localStorage.permission_en}/management/users`)
                                                history.goBack()
                                            }}
                                        >
                                            รายการสมาชิก
                                        </Breadcrumb.Section>
                                        <Breadcrumb.Divider />
                                        <Breadcrumb.Section active>ข้อมูลสมาชิก</Breadcrumb.Section>
                                    </Breadcrumb>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column computer='16' textAlign='center'>
                                    <h2>ข้อมูลสมาชิก</h2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column computer='16'>
                                    <CustomSegment icon='user circle' header='รายละเอียดสมาชิก' loading={loading}>
                                        <Grid divided>
                                            <Grid.Row>
                                                <Grid.Column computer='12'>
                                                    <Grid style={{ margin: 5 }}>
                                                        <Grid.Row>
                                                            <Grid.Column
                                                                computer='4'
                                                                only='computer'
                                                                style={{ alignSelf: 'center' }}
                                                            >
                                                                {user_data?.image && (
                                                                    <Image
                                                                        bordered
                                                                        style={{
                                                                            width: 150,
                                                                            height: 150,
                                                                            borderRadius: 500,
                                                                        }}
                                                                        src={`${HOST}${user_data?.image}`}
                                                                    />
                                                                )}
                                                            </Grid.Column>
                                                            <Grid.Column
                                                                tablet='4'
                                                                mobile='16'
                                                                only='tablet mobile'
                                                                style={{ alignSelf: 'center' }}
                                                            >
                                                                <Segment
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        zIndex: 1,
                                                                        marginTop: 30,
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'flex-end',
                                                                        }}
                                                                    >
                                                                        <div style={{ justifyContent: 'center' }}>
                                                                            {user_data?.image && (
                                                                                <Image
                                                                                    bordered
                                                                                    style={{
                                                                                        width: 150,
                                                                                        height: 150,
                                                                                        borderRadius: 500,
                                                                                    }}
                                                                                    src={`${HOST}${user_data?.image}`}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </Segment>
                                                            </Grid.Column>
                                                            <Grid.Column computer='12'>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column>
                                                                            <h2>{`${user_data?.prefix_th}${user_data?.first_name_th} ${user_data?.last_name_th}`}</h2>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column computer='4'>
                                                                            <label style={{ fontWeight: 'bold' }}>
                                                                                สิทธิ์การใช้งาน
                                                                            </label>
                                                                        </Grid.Column>
                                                                        <Grid.Column computer='12'>
                                                                            {user_data?.roles_name}
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column computer='4'>
                                                                            <label style={{ fontWeight: 'bold' }}>
                                                                                หมายเลขโทรศัพท์
                                                                            </label>
                                                                        </Grid.Column>
                                                                        <Grid.Column computer='12'>
                                                                            {user_data?.phone_number || '-'}
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column computer='4'>
                                                                            <label style={{ fontWeight: 'bold' }}>
                                                                                ตำแหน่งงาน
                                                                            </label>
                                                                        </Grid.Column>
                                                                        <Grid.Column computer='12'>
                                                                            {user_data?.position || '-'}
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column computer='4'>
                                                                            <label style={{ fontWeight: 'bold' }}>
                                                                                องค์กร
                                                                            </label>
                                                                        </Grid.Column>
                                                                        <Grid.Column computer='12'>
                                                                            {user_data?.municipalities_name}
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column computer='4'>
                                                                            <label style={{ fontWeight: 'bold' }}>
                                                                                สังกัดหน่วยงาน
                                                                            </label>
                                                                        </Grid.Column>
                                                                        <Grid.Column computer='12'>
                                                                            {
                                                                                user_data?.directory_of_municipalities_name
                                                                            }
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Grid.Column>
                                                <Grid.Column computer='4'>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        {Number(localStorage.getItem('user_id')) === user_id && (
                                                            <div style={{ padding: 5 }}>
                                                                <ButtonIconOver
                                                                    fluid
                                                                    icon='key'
                                                                    label='เปลี่ยนรหัสผ่าน'
                                                                    color='#425679'
                                                                    onClick={() => setOpenModalForm(true)}
                                                                />
                                                            </div>
                                                        )}
                                                        <div style={{ padding: 5 }}>
                                                            <ButtonIconOver
                                                                fluid
                                                                icon='key'
                                                                label='รีเซตรหัสผ่าน'
                                                                color='#425679'
                                                                onClick={handleResetPassword}
                                                            />
                                                        </div>
                                                        <div style={{ padding: 5 }}>
                                                            <ButtonIconOver
                                                                fluid
                                                                icon='trash alternate'
                                                                label='ลบข้อมูลผู้ใช้'
                                                                color='#9F3A38'
                                                                onClick={handleDeleteUser}
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </CustomSegment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
            </Fragment>
        </Fragment>
    )
}

export default ManagementUsersDetail
