import React, { useEffect, useState } from 'react'
import {
    Button,
    Container,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Message,
    Segment,
} from 'semantic-ui-react'
import IconOnePlatform from '../../../../../assets/images/logo one platform.png'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import {
    APPLICATION_GET_OTP_RESET_PASSWORD,
    APPLICATION_GET_VERIFY_OTP_RESET_PASSWORD,
} from '../../../../../config/api/application'

const LineRegisterForgetPasswordVerifyOTP: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [username, setUsername] = useState<string>('')
    let [phone_number, setPhoneNumber] = useState<string>('')
    let [otp, setOTP] = useState<string>('')
    let [otp_error, setOTPError] = useState<boolean>(false)
    let [error_text, setErrorText] = useState<string>('')
    let [disable_resend, setDisableResend] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { phone_number, username } = location_state
            setUsername(username)
            setPhoneNumber(phone_number)
        }
    }, [location])

    const handleChangeOTP = (_: any, { value }: InputOnChangeData) => {
        setOTP(value)
    }

    const handleClickGoBack = () => {
        history.goBack()
    }

    const handleClickGoNext = () => {
        if (otp) {
            setLoading(true)
            axios
                .post(APPLICATION_GET_VERIFY_OTP_RESET_PASSWORD, {
                    username: username,
                    otp: otp,
                })
                .then((response) => {
                    history.push('set-new-password', { username: username, otp: otp })
                    setLoading(false)
                })
                .catch((error) => {
                    setErrorText('หมายเลขโทรศัพท์และ OTP ไม่ถูกต้อง')
                    setDisableResend(false)
                    setLoading(false)
                    setOTPError(true)
                })
        } else {
            setOTPError(true)
        }
    }

    const handleClickResendOTP = () => {
        setErrorText('')
        setOTPError(false)
        axios
            .post(APPLICATION_GET_OTP_RESET_PASSWORD, { username: username, phone_number: phone_number })
            .then((response) => {
                setDisableResend(true)
            })
    }

    return (
        <div className='background-container-no-sidebar'>
            <Container>
                <Segment className='segment-login-page'>
                    <div>
                        <div className='header-segment-login-page'>
                            <div className='image-one-platform'>
                                <Image src={IconOnePlatform} />
                            </div>
                        </div>
                        <div className='body-segment-login-page'>
                            <Form loading={loading}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column textAlign='center'>
                                            <h3>ลืมรหัสผ่าน</h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h5>หมายเลขโทรศัพท์</h5>
                                            <Form.Field>
                                                <Input fluid value={phone_number} readOnly />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h5>รหัส OTP</h5>
                                            <Form.Field error={otp_error}>
                                                <Input fluid value={otp} onChange={handleChangeOTP} />
                                            </Form.Field>
                                            {error_text && <Message negative>{error_text}</Message>}
                                            <Button
                                                disabled={disable_resend}
                                                primary
                                                size='mini'
                                                floated='right'
                                                onClick={handleClickResendOTP}
                                            >
                                                ส่งอีกครั้ง
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </div>
                        <div className='footer-segment-login-page'>
                            <Button disabled={loading} floated='left' compact icon primary onClick={handleClickGoBack}>
                                <Icon name='chevron left' />
                            </Button>
                            <Button
                                loading={loading}
                                disabled={loading}
                                floated='right'
                                compact
                                icon
                                primary
                                onClick={handleClickGoNext}
                            >
                                <Icon name='chevron right' />
                            </Button>
                        </div>
                    </div>
                </Segment>
            </Container>
        </div>
    )
}

export default LineRegisterForgetPasswordVerifyOTP
