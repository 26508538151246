import { HOST } from '../router'

const USER = `${HOST}/user`
export const POST_USER_CREATE = `${USER}/create`
export const POST_USER_UPLOAD_IMAGE = `${USER}/upload_image`
export const GET_USER_COUNT_LIST = `${USER}/count_list`
export const GET_USER_ACTIVE_LIST = `${USER}/active_list`
export const GET_USER_DETAIL_BY_ID = `${USER}/detail_by_id`
export const GET_USER_DETAIL = `${USER}/detail`
export const PUT_USER_CHANGE_PASSWORD = `${USER}/change_password`
export const PUT_USER_RESET_PASSWORD = `${USER}/reset_password`
export const PUT_USER_EDIT = `${USER}/edit`
export const DELETE_USER_DELETE = `${USER}/delete`
export const PUT_USER_ACTIVE_USER = `${USER}/active_user`
export const GET_USER_HAS_PERMISSION_MENU_LIST = `${USER}/users_has_permission_menus_list`
export const GET_USER_HAS_PERMISSION_MENU_DETAIL = `${USER}/users_has_permission_menus_detail`
export const POST_CREATE_AND_EDIT_USER_HAS_PERMISSION_MENU = `${USER}/create_and_edit_users_has_permission_menus`
export const DELETE_USER_HAS_PERMISSION_MENU = `${USER}/delete_users_has_permission_menus`
export const GET_USER_LIST = `${USER}/list`
export const POST_REGISTER_LINE = `${USER}/register_line`
export const GET_USER_WAIT_APPROVE_LIST = `${USER}/wait_approve_list`
export const PUT_USER_APPROVE_AND_DISAPPROVE = `${USER}/approve_and_disapprove_user`
export const GET_USER_TECHNICIAN_BY_DIRECTORY = `${USER}/technician_by_directory`
