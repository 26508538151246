import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form, Grid, Input, InputOnChangeData } from 'semantic-ui-react';
import CustomModal from '../../../../../components/CustomModal';
import { axiosAuthen } from '../../../../../config/axiosAuthen';
import { InterfaceUserManualTopic } from '.';
import { POST_USER_MANUAL_CREATE_TOPIC, PUT_USER_MANUAL_EDIT_TOPIC } from '../../../../../config/api/userManual';

type FormProps = {
    data?: InterfaceUserManualTopic;
    open?: boolean;
    onClose?: () => void;
};

const UserManualTopicModalForm: React.FC<FormProps> = (props) => {
    let { data } = props;

    let [topic_name, setTopicName] = useState<string | undefined>();
    let [topic_id, setTopicId] = useState<number | undefined>();

    useEffect(() => {
        setTopicName(data?.name);
        setTopicId(data?.id);
    }, [data]);

    const handleChangeTopicName = (e: any, { value }: InputOnChangeData) => {
        setTopicName(value);
    };

    const handleSubmit = () => {
        let body = {
            name: topic_name,
        };
        if (topic_id) {
            body = { ...body, ...{ id: topic_id } };
            axiosAuthen.put(PUT_USER_MANUAL_EDIT_TOPIC, body).then(() => {
                handleClose();
            });
        } else {
            axiosAuthen.post(POST_USER_MANUAL_CREATE_TOPIC, body).then(() => {
                handleClose();
            });
        }
    };

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    return (
        <Fragment>
            <CustomModal icon='list alternate outline' header='หัวข้อ' open={props.open}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>ชื่อหัวข้อ</label>
                                <Form.Field>
                                    <Input
                                        fluid
                                        name='topic_name'
                                        value={topic_name}
                                        onChange={handleChangeTopicName}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}>
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}>
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    );
};

export default UserManualTopicModalForm;
