import React, { Fragment, useState } from 'react'
import {
  Button,
  Form,
  Grid,
  Input,
  Icon,
  Segment,
  InputOnChangeData,
} from 'semantic-ui-react'
import CustomModal from '../../../../../components/CustomModal'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import { useEffect } from 'react'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE } from '../../../../../config/api/directoryOfMunicipality'
import {
  GET_COMPLAINT_TECHNICIAN_MATCH_DIRECTORY,
  POST_COMPLAINT_ACCEPT_COMPLAINT_FROM_CENTRAL,
} from '../../../../../config/api/complaint'
import { alert } from '../../../../../components/alert'

type FormProps = {
  complaint_id?: string | number
  reload?: () => void
}
interface InterfaceDirectoryOption {
  id: string
  name: string
}
interface InterfaceTechnicianOption {
  id: string
  name: string
  phone_number: string
}

const AcceptComplaintCentral: React.FC<FormProps> = (props) => {
  let [open, setOpen] = useState<boolean>(false)
  let [search_directory, setSearchDirectory] = useState<string>('')
  let [search_technician, setSearchTechnician] = useState<string>('')
  let [list_directory, setListDirectory] = useState<InterfaceDirectoryOption[]>(
    []
  )
  let [list_technician, setListTechnician] = useState<
    InterfaceTechnicianOption[]
  >([])
  let [directory_option, setDirectoryOption] = useState<
    InterfaceDirectoryOption[]
  >([])
  let [directory, setDirectory] = useState<number | undefined>(undefined)
  let [technician_option, setTechnicianOption] = useState<
    InterfaceTechnicianOption[]
  >([])
  let [technician, setTechnician] = useState<number | undefined>(undefined)
  let [loading, setLoading] = useState<boolean>(false)
  const handleOpen = () => {
    setOpen(!open)
  }
  const handleClose = () => {
    setOpen(false)
    setDirectory(undefined)
    setTechnician(undefined)
  }

  useEffect(() => {
    const getDirectory = () => {
      axiosAuthen
        .get(`${GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE}`)
        .then((response) => {
          let { value } = response.data
          let directory_option = value.map((data: any) => ({
            id: data.id,
            name: data.name,
          }))
          setListDirectory(directory_option)
          setDirectoryOption(directory_option)
          if (directory_option.length === 0)
            setDirectory(
              Number(localStorage.getItem('directory_of_municipalities_id'))
            )
        })
    }
    getDirectory()
  }, [])

  const handleSearchDirectory = (_: any, { value }: InputOnChangeData) => {
    setSearchDirectory(value)
    let search_directory_option = list_directory.filter(
      (directory: InterfaceDirectoryOption) =>
        directory.name.indexOf(value) !== -1
    )
    setDirectoryOption(search_directory_option)
  }

  const handleClickDirectory = (id: number) => {
    setDirectory(id)
  }

  useEffect(() => {
    const getTechnician = () => {
      axiosAuthen
        .get(`${GET_COMPLAINT_TECHNICIAN_MATCH_DIRECTORY}/${directory}`)
        .then((response) => {
          let { value } = response.data
          let technician_option = value.map((data: any) => ({
            id: data.id,
            name: data.full_name_th,
            phone_number: data.phone_number,
          }))
          setListTechnician(technician_option)
          setTechnicianOption(technician_option)
        })
    }
    if (directory) {
      getTechnician()
    }
  }, [directory])

  const handleSearchTechnician = (_: any, { value }: InputOnChangeData) => {
    setSearchTechnician(value)
    let search_technician_option = list_technician.filter(
      (technician: InterfaceTechnicianOption) =>
        technician.name.indexOf(value) !== -1
    )
    setTechnicianOption(search_technician_option)
  }

  const handleClickTechnician = (id: number) => {
    setTechnician(id)
  }

  const handleSubmit = () => {
    setLoading(true)
    let body = {
      complaints_id: props.complaint_id,
      directory_of_municipalities_id: directory,
      users_id: technician,
    }
    axiosAuthen
      .post(POST_COMPLAINT_ACCEPT_COMPLAINT_FROM_CENTRAL, body)
      .then(() => {
        setLoading(false)
        alert({
          type: 'success',
          title: 'บันทึกเสร็จสิ้น',
          timer: 1500,
          onClosed: () => {
            handleClose()
            props.reload && props.reload()
          },
        })
      })
  }
  return (
    <Fragment>
      <CustomModal
        icon='file alternate'
        header='ตอบรับ - รับเรื่องร้องทุกข์จากส่วนกลาง'
        open={open}
        trigger={
          <ButtonIconOver
            fluid
            label='รับเรื่องจากส่วนกลาง'
            color='#70B996'
            icon='check'
            basic
            onClick={handleOpen}
          />
        }
      >
        <Form loading={loading}>
          <Grid>
            {directory_option.length > 0 && (
              <Grid.Row>
                <Grid.Column computer='3' only='computer' />
                <Grid.Column computer='10' only='computer'>
                  <label className='header-input-form'>เลือกหน่วยงานย่อย</label>
                </Grid.Column>
                <Grid.Column computer='3' only='computer' />
                <Grid.Column computer='3' only='computer' />
                <Grid.Column computer='10' only='computer'>
                  <Segment>
                    <Input
                      icon='search'
                      fluid
                      value={search_directory}
                      onChange={handleSearchDirectory}
                    />
                    {directory_option.map((data: any, index: number) => (
                      <Segment
                        key={index}
                        style={
                          directory === data.id
                            ? { border: '2px solid #293757', cursor: 'pointer' }
                            : { cursor: 'pointer' }
                        }
                        onClick={() => handleClickDirectory(data.id)}
                      >
                        <Icon name='users' style={{ color: '#425679' }} />{' '}
                        {data.name}
                      </Segment>
                    ))}
                  </Segment>
                </Grid.Column>
                <Grid.Column computer='3' only='computer' />
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column computer='3' only='computer' />
              <Grid.Column computer='10' only='computer'>
                <label className='header-input-form'>เลือกเจ้าหน้าที่</label>
              </Grid.Column>
              <Grid.Column computer='3' only='computer' />
              <Grid.Column computer='3' only='computer' />
              <Grid.Column computer='10' only='computer'>
                <Segment>
                  <Input
                    icon='search'
                    fluid
                    value={search_technician}
                    onChange={handleSearchTechnician}
                  />
                  {technician_option.map((data: any, index: number) => (
                    <Segment
                      key={index}
                      style={
                        technician === data.id
                          ? { border: '2px solid #293757', cursor: 'pointer' }
                          : { cursor: 'pointer' }
                      }
                      onClick={() => handleClickTechnician(data.id)}
                    >
                      <div style={{ paddingBottom: 10 }}>
                        <Icon name='user' style={{ color: '#425679' }} />{' '}
                        {data.name}
                      </div>
                      <div>
                        <Icon name='phone' style={{ color: '#425679' }} />{' '}
                        {data.phone_number}
                      </div>
                    </Segment>
                  ))}
                </Segment>
              </Grid.Column>
              <Grid.Column computer='3' only='computer' />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                <Button
                  style={{
                    width: 100,
                    backgroundColor: '#283655',
                    color: '#FFF',
                    marginRight: 15,
                  }}
                  onClick={handleSubmit}
                >
                  บันทึก
                </Button>
                <Button
                  style={{
                    width: 100,
                    backgroundColor: '#C54C40',
                    color: '#FFF',
                    marginLeft: 15,
                  }}
                  onClick={handleClose}
                >
                  ยกเลิก
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </CustomModal>
    </Fragment>
  )
}

export default AcceptComplaintCentral
