import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Container, Grid, Image, Card, Icon, Breadcrumb } from 'semantic-ui-react'
import { useHistory, useLocation } from 'react-router'
import IconArea from '../../../../assets/images/Area.png'
import IconTopic from '../../../../assets/images/Topic.png'
import IconContact from '../../../../assets/images/Contact.png'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import { LIST_AREAS_AND_COUMMUNITIES } from '../../../../config/api/areasAndCommunities'
import { LIST_COMPALINT_TOPIC_AND_TYPES } from '../../../../config/api/complaintTopicAndTypes'
import { GET_CONTACT_LIST } from '../../../../config/api/contacts'
import { useSelector } from 'react-redux'
import { getAuthenMenuList } from '../../../../store/selectors'
// import IconPersons from '../../../../assets/images/Persons.png'

export interface InterfaceSystem {
    id: string
}

const System: React.FC = () => {
    let history = useHistory()

    let location = useLocation()
    const { menu_list } = useSelector(getAuthenMenuList)
    let management_system_action = menu_list
        ?.find((menu) => menu.name_en === 'municipalities')
        ?.pages?.find((page) => page.name_th === 'จัดการระบบ')?.actions
    let permission = localStorage.permission_en
    let [municipality_id, setMunicipalityID] = useState<number | string>('')
    let [record_district, setRecordDistrict] = useState<number>(0)
    let [record_topic_complaint, setRecordTopicComplaints] = useState<number>(0)
    let [record_contact, setRecordContact] = useState<number>(0)

    const getListDistrictsAndCommunities = useCallback(() => {
        axiosAuthen
            .get(`${LIST_AREAS_AND_COUMMUNITIES}?page=${1}&offset=${5}&municipality_id=${municipality_id}`)
            .then((response) => {
                let { value } = response.data
                setRecordDistrict(value.count)
            })
    }, [municipality_id])
    const getListTopicComplaint = useCallback(() => {
        axiosAuthen
            .get(`${LIST_COMPALINT_TOPIC_AND_TYPES}?page=${1}&offset=${5}&municipality_id=${municipality_id}`)
            .then((response) => {
                let { value } = response.data

                setRecordTopicComplaints(value.count)
            })
    }, [municipality_id])

    const getListContact = useCallback(() => {
        axiosAuthen
            .get(`${GET_CONTACT_LIST}?page=${1}&offset=${5}&municipality_id=${municipality_id}`)
            .then((response) => {
                let { value } = response.data
                setRecordContact(value.count)
            })
    }, [municipality_id])

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            municipality_id && setMunicipalityID(municipality_id)
        }
    }, [location])

    useEffect(() => {
        if (municipality_id) {
            getListDistrictsAndCommunities()
            getListTopicComplaint()
            getListContact()
        }
    }, [getListContact, getListDistrictsAndCommunities, getListTopicComplaint, municipality_id])

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    {localStorage.permission_en === 'super_admin' && (
                                        <Fragment>
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities`, {
                                                        municipality_id: municipality_id,
                                                    })
                                                }}
                                            >
                                                รายการองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                        municipality_id: municipality_id,
                                                    })
                                                }}
                                            >
                                                กำหนดการใช้งานองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </Fragment>
                                    )}
                                    <Breadcrumb.Section active>จัดการระบบ</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>จัดการระบบ</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='2' only='computer' />
                            {(management_system_action?.some((action) => action.name_th === 'เขต') ||
                                permission === 'super_admin') && (
                                <Grid.Column computer='4' tablet='8'>
                                    <Card
                                        className='card-system'
                                        style={{ cursor: 'pointer', boxShadow: '3px 5px 8px #888888' }}
                                        onClick={() => {
                                            history.push(
                                                `/${localStorage.permission_en}/management/system/districts-and-communities/list`,
                                                { municipality_id: municipality_id }
                                            )
                                        }}
                                    >
                                        <Card.Content>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' />
                                                    <Grid.Column computer='10'>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <Image
                                                                src={IconArea}
                                                                style={{
                                                                    height: 90,
                                                                    display: 'inline-block',
                                                                    width: 130,
                                                                }}
                                                            />
                                                        </div>

                                                        <div style={{ textAlign: 'center', paddingTop: 20 }}>
                                                            <h3>เขต</h3>
                                                            <span style={{ color: '#1E1F26' }}>
                                                                เขต และชุมชนในพื้นที่
                                                            </span>
                                                        </div>
                                                        {/* <Divider style={{ border: '2px solid #283655', widht: '20%' }} /> */}

                                                        <Icon
                                                            name='window minimize outline'
                                                            size='big'
                                                            style={{
                                                                display: 'inline-block',
                                                                width: '100%',
                                                            }}
                                                        />
                                                        <div style={{ textAlign: 'center', paddingTop: 14 }}>
                                                            <span style={{ fontSize: '32px' }}>{record_district}</span>
                                                            <br />
                                                            <span style={{ color: '#1E1F26' }}> เขต </span>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' />
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Content>
                                        <Card.Content style={{ backgroundColor: '#293757' }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <h3 style={{ textAlign: 'center', color: 'white' }}>
                                                            จัดการเขต
                                                        </h3>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            )}
                            {(management_system_action?.some((action) => action.name_th === 'ช่องการการรับคำร้อง') ||
                                permission === 'super_admin') && (
                                <Grid.Column computer='4' tablet='8'>
                                    <Card
                                        style={{ cursor: 'pointer', boxShadow: '3px 5px 8px #888888' }}
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/management/system/contacts`, {
                                                municipality_id: municipality_id,
                                            })
                                        }}
                                    >
                                        <Card.Content style={{ textAlign: 'center' }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='1' />
                                                    <Grid.Column computer='13'>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <Image
                                                                src={IconContact}
                                                                style={{
                                                                    height: 90,
                                                                    display: 'inline-block',
                                                                    width: 130,
                                                                }}
                                                            />
                                                        </div>

                                                        <div style={{ paddingTop: 20 }}>
                                                            <h3>ช่องทางการรับคำร้อง</h3>
                                                            <span style={{ color: '#1E1F26' }}>
                                                                แหล่งที่มาของคำร้อง
                                                            </span>
                                                        </div>

                                                        <Icon
                                                            name='window minimize outline'
                                                            size='big'
                                                            style={{
                                                                display: 'inline-block',
                                                                width: '100%',
                                                            }}
                                                        />
                                                        <div style={{ paddingTop: 14 }}>
                                                            <span style={{ textAlign: 'center', fontSize: '32px' }}>
                                                                {record_contact}
                                                            </span>
                                                            <br />
                                                            <span style={{ color: '#1E1F26' }}> รายการ </span>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='1' />
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Content>
                                        <Card.Content style={{ backgroundColor: '#293757' }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <h3 style={{ textAlign: 'center', color: 'white' }}>
                                                            จัดการช่องทางการรับคำร้อง
                                                        </h3>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            )}
                            {(management_system_action?.some((action) => action.name_th === 'เรื่องร้องทุกข์') ||
                                permission === 'super_admin') && (
                                <Grid.Column computer='4' tablet='8'>
                                    <Card
                                        style={{ cursor: 'pointer', boxShadow: '3px 5px 8px #888888' }}
                                        onClick={() => {
                                            history.push(
                                                `/${localStorage.permission_en}/management/system/complaint/list`,
                                                { municipality_id: municipality_id }
                                            )
                                        }}
                                    >
                                        <Card.Content style={{ textAlign: 'center' }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='1' />
                                                    <Grid.Column computer='13'>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <Image
                                                                src={IconTopic}
                                                                style={{
                                                                    height: 90,
                                                                    display: 'inline-block',
                                                                    width: 130,
                                                                }}
                                                            />
                                                        </div>

                                                        <div style={{ paddingTop: 20 }}>
                                                            <h3>เรื่องร้องทุกข์</h3>
                                                            <span style={{ color: '#1E1F26' }}>
                                                                เรื่องร้องทุกข์ และประเภท
                                                            </span>
                                                        </div>

                                                        <Icon
                                                            name='window minimize outline'
                                                            size='big'
                                                            style={{
                                                                display: 'inline-block',
                                                                width: '100%',
                                                            }}
                                                        />
                                                        <div style={{ paddingTop: 14 }}>
                                                            <span style={{ textAlign: 'center', fontSize: '32px' }}>
                                                                {record_topic_complaint}
                                                            </span>
                                                            <br />
                                                            <span style={{ color: '#1E1F26' }}> เรื่องร้องทุกข์ </span>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column computer='1' />
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Content>
                                        <Card.Content style={{ backgroundColor: '#293757' }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <h3 style={{ textAlign: 'center', color: 'white' }}>
                                                            จัดการเรื่องร้องทุกข์
                                                        </h3>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            )}
                            {/* <Grid.Column computer='4'>
                                <Card
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/management/system/member/list`)
                                    }}
                                    style={{ cursor: 'pointer', boxShadow: '3px 5px 8px #888888' }}>
                                    <Card.Content>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='1' />
                                                <Grid.Column computer='13'>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}>
                                                        <Image
                                                            src={IconPersons}
                                                            style={{
                                                                height: 90,
                                                                display: 'inline-block',
                                                                width: 95,
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ textAlign: 'center', paddingTop: 20 }}>
                                                        <h3>ผูกงานกับเจ้าหน้าที่</h3>
                                                        <span style={{ color: '#1E1F26' }}>
                                                            กำหนดเรื่องร้องทุกข์กับเจ้าหน้าที่
                                                        </span>
                                                    </div>

                                                    <Icon
                                                        name='window minimize outline'
                                                        size='big'
                                                        style={{
                                                            display: 'inline-block',
                                                            width: '100%',
                                                        }}
                                                    />
                                                    <div style={{ textAlign: 'center', paddingTop: 14 }}>
                                                        <span style={{ fontSize: '32px' }}>4</span>
                                                        <br />
                                                        <span style={{ color: '#1E1F26' }}> หน่วยงาน </span>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column computer='3' />
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                    <Card.Content style={{ backgroundColor: '#293757' }}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='16'>
                                                    <h3 style={{ textAlign: 'center', color: 'white' }}>
                                                        ผูกงานกับเจ้าหน้าที่
                                                    </h3>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            </Grid.Column> */}
                            <Grid.Column computer='2' only='computer' />
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default System
