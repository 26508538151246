import React, { Component, Fragment } from 'react'
import { Input, Button, Icon } from 'semantic-ui-react'
import { getGoogleMaps } from '../../config/google-maps'

const GoogleMap = ({
    id = '',
    name = '',
    value = { lat: 0, lng: 0 },
    search = false,
    getCurrentPosition = false,
    onChange = (e, props) => {},
    style = {},
}) => {
    return (
        <Parent
            id={id}
            name={name}
            value={value}
            search={search}
            getCurrentPosition={getCurrentPosition}
            onChange={onChange}
            style={style}
        />
    )
}
class Parent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            google: '',
            map: '',
            marker: '',
        }
    }

    componentDidMount = () => {
        let googleMaps = getGoogleMaps()
        googleMaps.then((value) => {
            let google = value
            let lat
            let lng
            if (this.props.value) {
                if (this.props.value.lat && this.props.value.lng) {
                    lat = Number(this.props.value.lat)
                    lng = Number(this.props.value.lng)
                }
            }
            let id = 'map'
            if (this.props.id) {
                id = this.props.id
            }
            let map = new google.maps.Map(document.getElementById(id), {
                zoom: 18,
                minZoom: 6,
                center: {
                    lat: lat,
                    lng: lng,
                },
                scrollwheel: true,
            })

            let marker = new google.maps.Marker({
                map: map,
                draggable: this.props.onChange && true,
                animation: google.maps.Animation.DROP,
                position: { lat: lat, lng: lng },
            })
            if (this.props.value) {
                if (!this.props.value.lat || !this.props.value.lng) {
                    this.setCurrentPosition()
                }
            } else {
                this.setCurrentPosition()
            }
            if (this.props.search) {
                let searchBox = new google.maps.places.SearchBox(document.getElementById('pac-input'))

                //TODO: Search box
                searchBox.addListener('places_changed', (event) => {
                    var places = searchBox.getPlaces()
                    let props = this.props
                    if (places.length === 0) {
                        return
                    }

                    // * For each place, get the icon, name and location.
                    var bounds = new google.maps.LatLngBounds()
                    places.forEach(function (place) {
                        if (!place.geometry) {
                            return
                        }

                        if (place.geometry.viewport) {
                            // * Only geocodes have viewport.
                            bounds.union(place.geometry.viewport)
                        } else {
                            bounds.extend(place.geometry.location)
                        }
                        marker.setPosition(place.geometry.location)
                        let name = props.name
                        let value = {
                            lat: marker.getPosition().lat(),
                            lng: marker.getPosition().lng(),
                        }
                        props.onChange && props.onChange(event, { name, value })
                    })
                    map.fitBounds(bounds)
                })
                google.maps.event.addListener(map, 'click', (event) => {
                    let name = this.props.name
                    let value = {
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                    }
                    marker.setPosition(event.latLng)
                    this.props.onChange && this.props.onChange(event, { name, value })
                })

                marker.addListener('drag', (event) => {
                    let name = this.props.name
                    let value = {
                        lat: marker.getPosition().lat(),
                        lng: marker.getPosition().lng(),
                    }
                    this.props.onChange && this.props.onChange(event, { name, value })
                })
                this.setState({
                    google: google,
                    map: map,
                    marker: marker,
                })
            }
        })
    }

    setCurrentPosition = () => {
        navigator.geolocation.getCurrentPosition((event) => {
            let { marker, map } = this.state
            let current = {
                lat: Number(event.coords.latitude),
                lng: Number(event.coords.longitude),
            }
            marker.setPosition(current)
            map.setCenter(current)
            map.setZoom(18)
            let name = this.props.name
            let value = current
            this.props.onChange && this.props.onChange(event, { name, value })
        })
    }

    render() {
        return (
            <Fragment>
                {/* <Container> */}
                {this.props.getCurrentPosition && (
                    <React.Fragment>
                        <Button primary fluid onClick={this.setCurrentPosition} style={{ marginBottom: 10 }}>
                            ใช้ตำแหน่งปัจจุบัน
                        </Button>
                    </React.Fragment>
                )}
                {this.props.search && (
                    <React.Fragment>
                        <Input fluid icon>
                            <input id='pac-input' type='text' placeholder='ค้นหาสถานที่' />
                            <Icon
                                name='delete'
                                // style={{ cursor: 'pointer' }}
                                link
                                onClick={() => {
                                    document.getElementById('pac-input').value = ''
                                }}
                            />
                        </Input>
                        <br />
                    </React.Fragment>
                )}

                <div
                    id={this.props.id ? this.props.id : 'map'}
                    style={
                        this.props.style
                            ? { ...{ height: window.innerHeight, width: '100%' }, ...this.props.style }
                            : { height: window.innerHeight, width: '100%' }
                    }
                />
                {/* </Container> */}
            </Fragment>
        )
    }
}

export default GoogleMap
