import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Breadcrumb, Button, Checkbox, Container, Dimmer, Grid, Loader, Segment } from 'semantic-ui-react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsOrganization from 'highcharts/modules/organization'
import HighchartsSankey from 'highcharts/modules/sankey'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import {
    GET_DIRECTORY_OF_MUNICIPALITY_LEVEL_DIRECTORY_AND_ROLES,
    PUT_DIRECTORY_OF_MUNICIPALITY_MAPPING_LEVEL_DIRECTORY_AND_ROLES,
} from '../../../../config/api/directoryOfMunicipality'
import { GET_ROLE_BY_MUNICIPALITY } from '../../../../config/api/roles'
import CustomModal from '../../../../components/CustomModal'

interface InterfaceValueRole {
    id: number
    name: string
    name_en: string
}
interface InterfaceLevelDirectory {
    id: number
    name: string
    level: number
    parent_id: number
    roles: []
}

interface InterfaceRolesInLevelDirecory {
    id: number
    level_directory_has_roles_id: number
    name: string
}

const ManagementRoleInLevelDirectory: React.FC = () => {
    HighchartsSankey(Highcharts)
    HighchartsOrganization(Highcharts)
    let history = useHistory()
    let location = useLocation()
    let [municipality_id, setMunicipalityID] = useState<number>(0)
    let [chart_options, setChartOptions] = useState<any>()
    let [roles, setRoles] = useState<[]>([])
    let [open_modal, setOpenModal] = useState<boolean>(false)
    let [loading, setLoading] = useState<boolean>(true)
    let [roles_selected, setRolesSelected] = useState<number[]>([])
    let [level_id_selected, setLevelIDSelected] = useState<number>()
    let [level_name_selected, setLevelNameSelected] = useState<string>('')

    const getRoleInLevelDirectory = useCallback(() => {
        axiosAuthen
            .get(`${GET_DIRECTORY_OF_MUNICIPALITY_LEVEL_DIRECTORY_AND_ROLES}?municipality_id=${municipality_id}`)
            .then((response) => {
                let { value } = response.data
                setLoading(false)
                if (value && value.length) {
                    let from_to: [string, string][] = []
                    let nodes: any = []
                    let max_level = 1
                    value.forEach((level: InterfaceLevelDirectory) => {
                        let parent_name = 'องค์กร'
                        if (max_level < level.level) {
                            max_level = level.level
                        }
                        if (level.parent_id) {
                            parent_name =
                                (value &&
                                    value.find(
                                        (find_parent: InterfaceLevelDirectory) => find_parent.id === level.parent_id
                                    )?.name) ||
                                ''
                        }

                        from_to.push([parent_name, level.name])
                        parent_name = level.name
                        let description_level_directory = ''
                        level.roles.forEach((role: InterfaceRolesInLevelDirecory, index: number) => {
                            if (index > 0) description_level_directory += ', '
                            description_level_directory += role.name
                        })
                        nodes.push({
                            id: `${level.name}`,
                            name: `${level.name}`,
                            description: description_level_directory,
                            tier_id: level.id,
                            roles: level.roles.map((role: InterfaceRolesInLevelDirecory) => role.id),
                        })
                    })

                    let chart_oprtion = {
                        chart: {
                            height: (max_level + 1) * 120,
                            inverted: true,
                        },

                        title: {
                            text: 'แผนผังระดับหน่วยงาน',
                        },
                        attributes: {
                            id: 'arrow',
                            markerHeight: 10,
                            markerWidth: 10,
                            refX: 9,
                            refY: 5,
                            tagName: 'marker',
                        },

                        series: [
                            {
                                type: 'organization',
                                name: 'แผนผังองค์กร',
                                keys: ['from', 'to'],
                                data: from_to,
                                nodes: nodes,
                                colorByPoint: false,
                                color: 'silver',
                                dataLabels: {
                                    color: 'black',
                                },
                                borderColor: 'white',
                                nodeWidth: 75,
                                point: {
                                    events: {
                                        click: ({ point }: any) => {
                                            let { tier_id, name, roles } = point
                                            tier_id && handleClickPoint(tier_id, name, roles)
                                        },
                                    },
                                },
                                levels: {
                                    height: 25,
                                },
                            },
                        ],
                        tooltip: {
                            outside: true,
                        },
                        credits: {
                            enabled: false,
                        },
                    }
                    setChartOptions(chart_oprtion)
                }
            })
    }, [municipality_id])

    useEffect(() => {
        getRoleInLevelDirectory()
    }, [getRoleInLevelDirectory])

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            setMunicipalityID(municipality_id)

            const getRoles = () => {
                axiosAuthen.get(`${GET_ROLE_BY_MUNICIPALITY}/${municipality_id}`).then((response) => {
                    let { value } = response.data
                    setRoles(value)
                })
            }
            getRoles()
        }
    }, [location])

    const handleClickPoint = (level_id: number, level_name: string, roles: number[]) => {
        setLevelIDSelected(level_id)
        setLevelNameSelected(level_name)
        setRolesSelected(roles)
        setOpenModal(true)
    }
    const handleSelectRole = (role_id: number) => {
        if (roles_selected.some((select) => select === role_id)) {
            let role_select = roles_selected.filter((select) => select !== role_id)
            setRolesSelected(role_select)
        } else {
            setRolesSelected([...roles_selected, role_id])
        }
    }

    const handleSubmit = () => {
        let body = {
            level_directories_id: level_id_selected,
            roles_id: roles_selected,
            municipality_id: municipality_id,
        }
        setLoading(true)
        axiosAuthen.put(PUT_DIRECTORY_OF_MUNICIPALITY_MAPPING_LEVEL_DIRECTORY_AND_ROLES, body).then(() => {
            getRoleInLevelDirectory()
            handleClose()
        })
    }
    const handleClose = () => {
        setOpenModal(false)
        setRolesSelected([])
    }

    return (
        <Fragment>
            <Dimmer active={loading} inverted page>
                <Loader inverted content='รอสักครู่' />
            </Dimmer>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities`, {
                                                municipality_id: municipality_id,
                                            })
                                        }}
                                    >
                                        รายการองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                municipality_id: municipality_id,
                                            })
                                        }}
                                    >
                                        กำหนดการใช้งานองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>กำหนดสิทธิ์ในระดับหน่วยงาน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>จัดการสิทธิ์ในระดับหน่วยงาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {chart_options && (
                                    <Segment>
                                        <HighchartsReact highcharts={Highcharts} options={chart_options} />
                                    </Segment>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
            <CustomModal
                icon='sitemap'
                centered={false}
                header='จัดการสิทธิ์ในระดับหน่วยงาน'
                size='mini'
                open={open_modal}
                onClose={handleClose}
                closeOnEscape={false}
                closeOnDimmerClick={false}
            >
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <h3>ระดับหน่วยงาน : {level_name_selected}</h3>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment style={{ overflowX: 'auto', height: 500 }}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column textAlign='center'>
                                            <h4>รายการสิทธิ์</h4>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {roles.map((role: InterfaceValueRole) => (
                                        <Grid.Row key={role.id}>
                                            <Grid.Column>
                                                <Segment
                                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                                    onClick={() => handleSelectRole(role.id)}
                                                >
                                                    <Checkbox
                                                        checked={roles_selected.some(
                                                            (selected) => selected === role.id
                                                        )}
                                                    />{' '}
                                                    <label style={{ paddingLeft: 10, cursor: 'pointer' }}>
                                                        {' '}
                                                        {role.name}
                                                    </label>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                    ))}
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Button
                                style={{
                                    width: 100,
                                    backgroundColor: '#283655',
                                    color: '#FFF',
                                    marginRight: 15,
                                }}
                                onClick={handleSubmit}
                            >
                                บันทึก
                            </Button>
                            <Button
                                style={{
                                    width: 100,
                                    backgroundColor: '#C54C40',
                                    color: '#FFF',
                                    marginLeft: 15,
                                }}
                                onClick={handleClose}
                            >
                                ยกเลิก
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </CustomModal>
        </Fragment>
    )
}

export default ManagementRoleInLevelDirectory
