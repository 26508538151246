import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Button, Checkbox, Container, Form, Grid, Icon, Image, Input, Message, Segment } from 'semantic-ui-react'
import { LOGIN_SUPER_ADMIN } from '../../config/api/common'
import './styles.css'

const SuperAdminLogin: React.FC = () => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [username_error, setUsernameError] = useState<boolean>(false)
    const [password_error, setPasswordError] = useState<boolean>(false)
    const [show_password, setShowPassword] = useState<boolean>(false)
    const [remember, setRemember] = useState<boolean>(false)
    const [error_text, setErrorText] = useState<string>('')
    let history = useHistory()

    const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target
        setUsername(value)
        setUsernameError(false)
    }

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target
        setPassword(value)
        setPasswordError(false)
    }

    const validate = () => {
        let error = false
        if (!username) {
            error = true
            setUsernameError(true)
        }
        if (!password) {
            error = true
            setPasswordError(true)
        }
        return error
    }

    const handleSubmit = () => {
        setLoading(true)
        if (!validate()) {
            axios
                .post(`${LOGIN_SUPER_ADMIN}`, {
                    username: username,
                    password: password,
                })
                .then((response) => {
                    setLoading(false)
                    let value = response.data.value
                    localStorage.setItem('menu', JSON.stringify(value.menu))
                    let previous_permission = localStorage.getItem('previous_permission')
                    localStorage.setItem('username', username)
                    if (remember) {
                        document.cookie = `password=${password}`
                    } else {
                        document.cookie = `password=`
                    }
                    // let menu = JSON.parse(String(localStorage.getItem('menu')));
                    localStorage.setItem('user_id', value.users_id)
                    localStorage.setItem('token', value.access_token)
                    localStorage.setItem('name', value.name)
                    localStorage.setItem('permission_en', value.permission_en)
                    localStorage.setItem('permission_th', value.permission_th)
                    localStorage.setItem('municipalities_id', value.municipalities_id)
                    localStorage.setItem('municipalities_name', value.municipalities_name)
                    localStorage.setItem('parent_id', value.parent_id)
                    // value.profile_id && localStorage.setItem('profile_image', `${HOST}${value.profile_id}`);
                    // this.props.dispatch(setProfileImage(`${HOST}${value.profile_id}`));
                    let check_permission
                    if (previous_permission == null) {
                        check_permission = false
                    } else if (previous_permission !== value.permission_en) {
                        check_permission = true
                    }
                    if (localStorage.getItem('logout') || check_permission || history.action === 'POP') {
                        localStorage.removeItem('logout')
                        history.push(`/${value.permission_en}/municipalities`)
                        // history.push(`${value.permission_en}/main`)
                    } else {
                        history.goBack()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    if (error.response) {
                        let message = error.response.data.message

                        if (message === 'wrong username') {
                            setErrorText('ไม่พบบัญชีผู้ใช้งานในระบบ')
                        } else if (message === 'wrong password') {
                            setErrorText('รหัสผ่านไม่ถูกต้อง')
                        }
                    } else {
                        setErrorText('ระบบเกิดข้อผิดพลาด')
                    }
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        let cookie = document.cookie
        let password = cookie.split(';').find((cookie_key) => cookie_key.indexOf('password=') !== -1)
        localStorage.getItem('username') !== null && setUsername(String(localStorage.getItem('username')))
        password && setPassword(password.substring(10))
        setRemember(Boolean(password && password.substring(10)))
    }, [])

    return (
        <Fragment>
            <div className='background-super-admin-login-page'>
                <Container fluid>
                    <Grid>
                        <Grid.Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid.Column computer='6' tablet='16'>
                                <Segment
                                    style={{
                                        boxShadow: '0px 1px 40px #283655',
                                        backgroundColor: '#4d648d',
                                        color: '#ffffff',
                                        borderRadius: 20,
                                        margin: 20,
                                    }}
                                >
                                    <Form loading={loading} onSubmit={handleSubmit}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='16'>
                                                    <h2 style={{ textAlign: 'center' }}>เข้าสู่ระบบ</h2>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16'>
                                                    <Form.Field error={username_error}>
                                                        <Input
                                                            fluid
                                                            name='username'
                                                            placeholder='อีเมล / หมายเลขโทรศัพท์'
                                                            value={username}
                                                            onChange={handleChangeUsername}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16'>
                                                    <Form.Field error={password_error}>
                                                        <Input
                                                            fluid
                                                            icon={
                                                                <Icon
                                                                    name={show_password ? 'eye' : 'eye slash'}
                                                                    link
                                                                    onClick={() => {
                                                                        setShowPassword(!show_password)
                                                                    }}
                                                                />
                                                            }
                                                            type={show_password ? 'text' : 'password'}
                                                            name='password'
                                                            placeholder='รหัสผ่าน'
                                                            value={password}
                                                            onChange={handleChangePassword}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16'>
                                                    <Checkbox
                                                        label={<label style={{ color: '#ffffff' }}>จดจำรหัสผ่าน</label>}
                                                        name='remember'
                                                        checked={remember}
                                                        onChange={() => {
                                                            setRemember(!remember)
                                                        }}
                                                    />
                                                </Grid.Column>
                                                {/* <Grid.Column computer='8'>
														<div
															style={{
																display: 'flex',
																justifyContent: 'flex-end',
																cursor: 'pointer'
															}}
														>
															<Icon
																name='lock'
																style={{ color: '#53A6D8', cursor: 'pointer' }}
															/>{' '}
															<label style={{ color: '#53A6D8', cursor: 'pointer' }}>
																ลืมรหัสผ่าน
															</label>
														</div>
													</Grid.Column> */}
                                            </Grid.Row>
                                            {error_text && (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Message negative>{error_text}</Message>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                            <Grid.Row>
                                                <Grid.Column computer='16'>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Button
                                                            style={{
                                                                color: '#4D648D',
                                                                backgroundColor: '#ffffff',
                                                            }}
                                                        >
                                                            เข้าสู่ระบบ
                                                        </Button>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16' tablet='16'>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <label
                                                            style={{
                                                                fontStyle: 'normal',
                                                                fontWeight: 600,
                                                                fontSize: 10,
                                                                textAlign: 'right',
                                                                letterSpacing: -0.1,
                                                                color: '#ffffff',
                                                                flex: 'none',
                                                                order: 0,
                                                                alignSelf: 'center',
                                                                margin: '15px 0px',
                                                            }}
                                                        >
                                                            Powered by
                                                        </label>{' '}
                                                        <Image
                                                            src='https://ogs.co.th/wp-content/uploads/2020/02/OGS_logo-1.png'
                                                            style={{ width: 64, height: 64, marginLeft: 15 }}
                                                        />
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Form>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default SuperAdminLogin
