import React, { Fragment, useEffect, useState } from 'react'

import { Container, Dropdown, DropdownProps, Grid, Segment, Image } from 'semantic-ui-react'
import { GET_DASHBOARD_YEARS } from '../../config/api/dashboard'
import { axiosAuthen } from '../../config/axiosAuthen'
import { useLocation } from 'react-router-dom'
import DashboardTechnianAllStatus from './AllStatus'
import DashboardTechnianAverageResults from './AverageEvaluate'
import DashboardTechnianStatusComplaint from './StatusComplaint'
import DashboardTechnianComplaintCanNotProcess from './ComplaintCanNotProcess'
import DashboardTechnianContact from './Contact'
import { HOST } from '../../config/router'
import { useSelector } from 'react-redux'
import { getAuthenMenuList } from '../../store/selectors'

const DashboardTechnian: React.FC = () => {
    let location = useLocation()

    const { menu_list } = useSelector(getAuthenMenuList)
    let dashbord_action = menu_list
        ?.find((menu) => menu.name_th === 'แดชบอร์ด')
        ?.pages?.find((page) => page.name_th === 'แดชบอร์ดรายละเอียดเจ้าหน้าที่')?.actions
    let [technician_id, setTechnianId] = useState<string>('')
    let [full_name_th, setFullNameTh] = useState<string>('')
    let [directory_name, setDirectoryName] = useState<string>('')
    let [image, setImage] = useState<string>('')
    let [phon, setPhon] = useState<string>('')
    let [year_option, setYearOption] = useState<{ value: string; text: string }[]>([])
    let [year, setYear] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('all')

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { technician_id } = location_state
            setTechnianId(technician_id)

            let { full_name_th } = location_state
            setFullNameTh(full_name_th)

            let { directory_name } = location_state
            setDirectoryName(directory_name)

            let { image } = location_state
            setImage(image)

            let { phon } = location_state
            setPhon(phon)
        }
    }, [location])

    useEffect(() => {
        axiosAuthen.get(GET_DASHBOARD_YEARS).then((response) => {
            let { value } = response.data
            let year_option = value.map((data: any) => ({ value: data, text: data }))
            setYearOption([{ text: 'ทั้งหมด', value: 'all' }, ...year_option])
            setYear('all')
        })
    }, [])

    const handleChangeYear = (_: any, { value }: DropdownProps) => {
        setYear(value)
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container fluid>
                    <Grid columns='16'>
                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16'>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Segment style={{ boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.25)' }}>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column
                                                            computer='8'
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <Image
                                                                src={
                                                                    image
                                                                        ? `${HOST}${image}`
                                                                        : 'https://react.semantic-ui.com/images/avatar/large/patrick.png'
                                                                }
                                                                size='small'
                                                                circular
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='8'>
                                                            <h1>{full_name_th}</h1>
                                                            <h2>ตำแหน่ง : {directory_name}</h2>
                                                            <h3>{phon}</h3>
                                                            {/* <h3>{email}</h3> */}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column computer='3' mobile='10' style={{ alignSelf: 'center' }}>
                                <h4>สรุปข้อมูลประจำปีงบประมาณ</h4>
                            </Grid.Column>
                            <Grid.Column computer='2' mobile='6'>
                                <Dropdown
                                    search
                                    selection
                                    fluid
                                    value={year}
                                    options={year_option}
                                    onChange={handleChangeYear}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {dashbord_action?.some((action) => action.name_th === 'สรุปข้อมูลประจำปีงบประมาณ') && (
                            <Grid.Row>
                                <Grid.Column computer='16' tablet='16'>
                                    <DashboardTechnianAllStatus technician_id={technician_id} year={year} />
                                </Grid.Column>
                            </Grid.Row>
                        )}

                        <Grid.Row>
                            {dashbord_action?.some((action) => action.name_th === 'สรุปผลการประเมินโดยเฉลี่ย') && (
                                <Grid.Column computer='6' mobile='10' style={{ alignSelf: 'center' }}>
                                    <DashboardTechnianAverageResults technician_id={technician_id} year={year} />
                                </Grid.Column>
                            )}
                            <Grid.Column computer='10' mobile='10' style={{ alignSelf: 'center' }}>
                                <Grid>
                                    {dashbord_action?.some((action) => action.name_th === 'สรุปสถานะคำร้องทุกข์') && (
                                        <Grid.Row>
                                            <Grid.Column computer='16' mobile='16' style={{ alignSelf: 'center' }}>
                                                <DashboardTechnianStatusComplaint
                                                    technician_id={technician_id}
                                                    year={year}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    <Grid.Row>
                                        {dashbord_action?.some(
                                            (action) => action.name_th === 'สรุปคำร้องทุกข์ที่ไม่สามารถดำเนินการได้'
                                        ) && (
                                            <Grid.Column computer='8' mobile='8' style={{ alignSelf: 'center' }}>
                                                <DashboardTechnianComplaintCanNotProcess
                                                    technician_id={technician_id}
                                                    year={year}
                                                />
                                            </Grid.Column>
                                        )}
                                        {dashbord_action?.some(
                                            (action) => action.name_th === 'สรุปช่องทางการรับเรื่อง'
                                        ) && (
                                            <Grid.Column computer='8' mobile='8' style={{ alignSelf: 'center' }}>
                                                <DashboardTechnianContact technician_id={technician_id} year={year} />
                                            </Grid.Column>
                                        )}
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default DashboardTechnian
