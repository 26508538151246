import { HOST } from '../router'

const MANEGE_MENU = `${HOST}/manage_menu`
export const GET_MANEGE_MENU_LIST_ACTIONS = `${MANEGE_MENU}/list_action`
export const GET_MANEGE_MENU_LIST_PAGES = `${MANEGE_MENU}/list_page`
export const GET_MANEGE_MENU_LIST_MENUS = `${MANEGE_MENU}/list_menu`

export const POST_MANEGE_ACTIVE_MENU = `${MANEGE_MENU}/active_menu`
export const POST_MANEGE_ACTIVE_PAGE = `${MANEGE_MENU}/active_page`
export const POST_MANEGE_ACTIVE_ACTION = `${MANEGE_MENU}/active_action`

export const GET_LIST_ACTIONS = `${MANEGE_MENU}/action_all`
export const GET_LIST_PAGES = `${MANEGE_MENU}/page_all`
export const GET_LIST_MENUS = `${MANEGE_MENU}/menu_all`
export const GET_MENU_BY_TOKEN = `${MANEGE_MENU}/menu`

export const GET_MENU_PAGE_ACTION_ACTIVE = `${MANEGE_MENU}/menu_page_action_active`
