import React, { Fragment, useEffect, useState } from 'react'
import { Container, Dropdown, Grid, Segment, Breadcrumb, Label, Button, Icon, Image } from 'semantic-ui-react'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { useHistory } from 'react-router'
import CustomSegment from '../../../../../components/CustomSegment'
import {
    GET_MATCH_USERS_AND_TOPIC_BY_USER,
    DELETE_MATCH_USERS_AND_TOPIC_BY_USER,
} from '../../../../../config/api/matchUsersAndTopic'
import AddMemberandComplaint from './ModalAdd'
import EditMemberandComplaint from './ModalEdit'

export interface InterfaceComplaint {
    municipalities_id: string
    directory_of_municipalities_id: number
    id: number
    name: string
    short_name: string
    updated_at?: string
    updated_by?: number
    directory_name: string
}

export interface InterfaceMatchUsersAndTopics {
    complaint_topic_name: string
    complaint_topics_id: number
    complaint_types: any
    id: number
}

const DetailStaff: React.FC = () => {
    let history = useHistory()
    let id = localStorage.user_id
    let [open_add, setOpenAdd] = useState<boolean>(false)
    let [open_edit, setOpenEdit] = useState<boolean>(false)
    let [complaints, setComplaints] = useState<any[]>([])
    let [name_th, setNameTh] = useState<string | undefined>()
    let [name_eng, setNameEng] = useState<string | undefined>()
    let [image, setImage] = useState<string | undefined>()
    let [role_th, setRoleTh] = useState<string | undefined>()
    let [directory_name, setDirectoryName] = useState<string | undefined>()
    let [index_show, setIndexShow] = useState<number | undefined>()
    let [match_users_and_topics, setMatchUsersAndTopics] = useState<any>([])

    const handleAdd = () => {
        setOpenAdd(true)
    }

    const handleSlide = (index: number, id: number) => {
        setIndexShow(index)
    }

    const handleCloseModalAdd = () => setOpenAdd(false)

    const handleCloseModalEdit = () => setOpenEdit(false)

    useEffect(() => {
        const getListMatchUsersAndTopicByUser = () => {
            axiosAuthen.get(`${GET_MATCH_USERS_AND_TOPIC_BY_USER}/?user_id=${id}`).then((response) => {
                let { value } = response.data

                setNameTh(value.full_name_th)
                setNameEng(value.first_name_en)
                setRoleTh(value.role_th)
                setDirectoryName(value.directory_name)
                setComplaints(value.match_users_and_topics)
                setImage(value.image)
            })
        }
        getListMatchUsersAndTopicByUser()
    })

    const handleEdit = (data: any) => {
        setMatchUsersAndTopics(data)
        setOpenEdit(true)
    }

    const handleDelete = (data_id: number) => {
        axiosAuthen.delete(`${DELETE_MATCH_USERS_AND_TOPIC_BY_USER}?match_id=${data_id}`).then((response) => {
            axiosAuthen.get(`${GET_MATCH_USERS_AND_TOPIC_BY_USER}/?user_id=${id}`).then((response) => {
                let { value } = response.data

                setNameTh(value.full_name_th)
                setNameEng(value.first_name_en)
                setRoleTh(value.role_th)
                setDirectoryName(value.directory_name)
                setComplaints(value.match_users_and_topics)
                setImage(value.image)
            })
        })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    {/* {match_users_and_topics && ( */}
                    <EditMemberandComplaint
                        open={open_edit}
                        onClose={handleCloseModalEdit}
                        data={match_users_and_topics}
                    />
                    {/* )} */}
                    <AddMemberandComplaint open={open_add} onClose={handleCloseModalAdd} />
                    <Grid>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Breadcrumb>
                                    {/* {localStorage.permission_en === 'super_admin' && (
                                        <Fragment>
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities`, {
                                                        municipality_id: municipality_id,
                                                    })
                                                }}
                                            >
                                                รายการองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                        municipality_id: municipality_id,
                                                    })
                                                }}
                                            >
                                                กำหนดการใช้งานองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </Fragment>
                                    )} */}
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/management/system`)
                                        }}
                                    >
                                        จัดการระบบ
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/management/system/member/list`)
                                        }}
                                    >
                                        รายการสมาชิก
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>ข้อมูลเจ้าหน้าที่</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>ข้อมูลเจ้าหน้าที่</h2>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='6'>
                                <br />
                                <Segment>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Image
                                            style={{
                                                display: 'inline-block',
                                                border: ' 3px solid #293757',
                                                padding: 3,
                                                height: '200px',
                                                width: '200px',
                                            }}
                                            src={image}
                                            size='medium'
                                            circular
                                        />
                                    </div>
                                    <div style={{ textAlign: 'center', paddingTop: 20 }}>
                                        <h2 style={{ color: '#283655' }}>{name_th}</h2>
                                        <h4 style={{ color: '#999999', marginTop: '-8px' }}>{name_eng}</h4>
                                        <span style={{ color: '#283655', fontWeight: 'bold', fontSize: '18px' }}>
                                            สิทธิ์เข้าใช้งาน
                                        </span>{' '}
                                        <span style={{ color: '#2C2C2C', fontSize: '18px' }}>{role_th}</span>
                                    </div>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer='10'>
                                <CustomSegment icon='file alternate' header='งานที่รับผิดชอบ'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='1' only='computer' />
                                            <Grid.Column computer='2' mobile='16'>
                                                <h4 style={{ color: '#283655' }}>หน่วยงาน</h4>
                                            </Grid.Column>
                                            <Grid.Column computer='12' mobile='16'>
                                                <span>{directory_name}</span>
                                            </Grid.Column>
                                            <Grid.Column computer='1' only='computer' />
                                        </Grid.Row>

                                        {complaints !== null ? (
                                            complaints.map((data: any, index: number) => (
                                                <Grid.Row>
                                                    <Grid.Column computer='1' only='computer' />
                                                    <Grid.Column computer='14' mobile='16'>
                                                        <Segment
                                                            piled
                                                            style={{
                                                                marginTop: '-10px',
                                                                border: ' 1px solid #2185D0',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleSlide(index, data.id)}
                                                            key={index}
                                                        >
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='1'>
                                                                        {index_show === index ? (
                                                                            <Icon
                                                                                name='chevron circle down'
                                                                                size='large'
                                                                                style={{ color: '#283655' }}
                                                                            />
                                                                        ) : (
                                                                            <Icon
                                                                                name='chevron circle right'
                                                                                size='large'
                                                                                style={{ color: '#283655' }}
                                                                            />
                                                                        )}
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='5'>
                                                                        <h4 style={{ color: '#283655' }}>
                                                                            เรื่องร้องเรียน
                                                                        </h4>
                                                                    </Grid.Column>

                                                                    <Grid.Column computer='8'>
                                                                        <span
                                                                            style={{
                                                                                fontSize: '15px',
                                                                                color: '#283655',
                                                                            }}
                                                                        >
                                                                            :{' '}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                marginLeft: '3px',
                                                                                color: '#2C2C2C',
                                                                                fontSize: '15px',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            {data.complaint_topic_name}
                                                                        </span>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='2'>
                                                                        <Dropdown
                                                                            icon='ellipsis vertical'
                                                                            pointing='right'
                                                                        >
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item
                                                                                    onClick={() => handleEdit(data)}
                                                                                >
                                                                                    <Icon
                                                                                        name='edit'
                                                                                        circular
                                                                                        style={{
                                                                                            color: '#F2CC6B',
                                                                                            border: '1px solid #F2CC6B',
                                                                                            boxShadow: 'none',
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >
                                                                                        แก้ไข
                                                                                    </label>
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider />
                                                                                <Dropdown.Item
                                                                                    onClick={() =>
                                                                                        handleDelete(data.id)
                                                                                    }
                                                                                >
                                                                                    <Icon
                                                                                        name='trash alternate'
                                                                                        circular
                                                                                        style={{
                                                                                            color: '#9F3A38',
                                                                                            border: '1px solid #9F3A38',
                                                                                            boxShadow: 'none',
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >
                                                                                        ลบ
                                                                                    </label>
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                {index_show === index ? (
                                                                    <Fragment>
                                                                        {data.complaint_types.length !== 0 ? (
                                                                            data.complaint_types.map(
                                                                                (data: any, index: number) => (
                                                                                    <Grid.Row>
                                                                                        <Grid.Column computer='1' />
                                                                                        <Grid.Column computer='5'>
                                                                                            <h4
                                                                                                style={{
                                                                                                    color: '#283655',
                                                                                                }}
                                                                                            >
                                                                                                ประเภทเรื่องร้องเรียน
                                                                                            </h4>
                                                                                        </Grid.Column>
                                                                                        <Grid.Column computer='8'>
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: '15px',
                                                                                                    color: '#283655',
                                                                                                }}
                                                                                            >
                                                                                                :{' '}
                                                                                            </span>
                                                                                            <span>
                                                                                                <Label
                                                                                                    circular
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            '#283655',
                                                                                                        color: 'white',
                                                                                                    }}
                                                                                                >
                                                                                                    {index + 1}
                                                                                                </Label>
                                                                                            </span>
                                                                                            <span
                                                                                                style={{
                                                                                                    color: '#2C2C2C',
                                                                                                    fontSize: '15px',
                                                                                                }}
                                                                                            >
                                                                                                {' '}
                                                                                                {
                                                                                                    data.complaint_type_name
                                                                                                }
                                                                                            </span>
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                )
                                                                            )
                                                                        ) : (
                                                                            <Grid.Row>
                                                                                <Grid.Column
                                                                                    computer='3'
                                                                                    only='computer'
                                                                                />
                                                                                <Grid.Column computer='10' mobile='16'>
                                                                                    <h4 style={{ textAlign: 'center' }}>
                                                                                        {
                                                                                            'ไม่พบข้อมูลประเภทเรื่องร้องเรียน'
                                                                                        }
                                                                                    </h4>
                                                                                </Grid.Column>
                                                                                <Grid.Column
                                                                                    computer='3'
                                                                                    only='computer'
                                                                                />
                                                                            </Grid.Row>
                                                                        )}
                                                                    </Fragment>
                                                                ) : (
                                                                    <div />
                                                                )}
                                                            </Grid>
                                                        </Segment>
                                                    </Grid.Column>
                                                    <Grid.Column computer='1' only='computer' />
                                                </Grid.Row>
                                            ))
                                        ) : (
                                            <Grid.Row>
                                                <Grid.Column computer='5' only='computer' />
                                                <Grid.Column computer='5' mobile='16'>
                                                    <h4 style={{ textAlign: 'center' }}>{'ไม่พบข้อมูล'}</h4>
                                                </Grid.Column>
                                                <Grid.Column computer='5' only='computer' />
                                            </Grid.Row>
                                        )}

                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16' style={{ padding: 10 }}>
                                                {/* ปุ่มเพิ่มชุมชน value */}
                                                <Button fluid basic color='blue' content='Blue' onClick={handleAdd}>
                                                    <Icon
                                                        circular
                                                        size='small'
                                                        color='blue'
                                                        name='plus'
                                                        style={{ boxShadow: '0px 0px 0px 1px #1F7EC7' }}
                                                    />
                                                    เพิ่มงานที่รับผิดชอบ
                                                </Button>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default DetailStaff
