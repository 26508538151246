import React, { Fragment, useEffect, useState } from 'react';
import {
    Button,
    Container,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    Label,
    Segment,
    TextArea,
    TextAreaProps,
} from 'semantic-ui-react';
import CustomModal from '../../../components/CustomModal';
import { InterfaceDefect } from '.';
import { axiosAuthen } from '../../../config/axiosAuthen';
import { GET_DEFECTS_LIST, POST_DEFECTS_APPROVE_DEFECT } from '../../../config/api/defect';
import { HOST } from '../../../config/router';

type FormProps = {
    data?: InterfaceDefect;
    open?: boolean;
    onClose?: () => void;
};

const DefectModalDetail: React.FC<FormProps> = (props) => {
    let { data } = props;
    let [approve_detail, setApproveDetail] = useState<string | number | undefined>();
    let [approve_detail_error, setApproveDetailError] = useState<boolean>(false);
    let [loading, setLoading] = useState<boolean>(false);
    let [files, setFiles] = useState<File[]>([]);
    const removeImage = (index: number) => {
        let list_file = files.filter((_, index_file) => index_file !== index);
        setFiles(list_file);
    };

    const handleChangeFile = (e: any) => {
        let file = e.target.files;
        if (file) {
            setFiles([...files, ...file]);
        }
    };

    const handleSubmit = () => {
        if (approve_detail) {
            setLoading(true);
            let form_data = new FormData();
            form_data.append('defect_id', String(data?.id));
            form_data.append('approve_detail', String(approve_detail));
            files.map((file) => form_data.append('defect_file', file));

            axiosAuthen.post(POST_DEFECTS_APPROVE_DEFECT, form_data).then((response) => {
                setLoading(false);
                handleClose();
            });
        } else {
            setApproveDetailError(true);
        }
    };

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const handleChangeApproveDetail = (e: any, { value }: TextAreaProps) => {
        setApproveDetail(value);
        setApproveDetailError(false);
    };

    useEffect(() => {
        if (data?.defect_status === 'ตอบรับแล้ว') {
            setLoading(true);
            axiosAuthen.get(`${GET_DEFECTS_LIST}/${data.id}`).then((response) => {
                let { value } = response.data;
                data!.approve_detail = value.approve_detail;
                data!.approve_time = value.approve_time;
                data!.created_at = value.created_at;
                data!.files_before = value.files_before.length > 0 ? value.files_before : undefined;
                data!.files_after = value.files_after.length > 0 ? value.files_after : undefined;
                setLoading(false);
            });
        }
    }, [data]);

    return (
        <Fragment>
            <CustomModal
                icon='question circle'
                header='รายละเอียดการแจ้งปัญหา'
                open={props.open}
                closeIcon
                onClose={handleClose}
                closeOnEscape={true}
                closeOnDimmerClick={true}>
                <Container>
                    <Form loading={loading}>
                        <h3 style={{ color: '#283655' }}>ข้อมูลผู้แจ้งปัญหา</h3>
                        <Segment style={{ borderColor: '#283655' }}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column computer='4' textAlign='right'>
                                        <label style={{ fontWeight: 'bold', color: '#283655' }}>ลำดับที่รับแจ้ง</label>
                                    </Grid.Column>
                                    <Grid.Column computer='12'>{data?.id}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column computer='4' textAlign='right'>
                                        <label style={{ fontWeight: 'bold', color: '#283655' }}>วันที่แจ้ง</label>
                                    </Grid.Column>
                                    <Grid.Column computer='12'>{data?.created_at}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column computer='4' textAlign='right'>
                                        <label style={{ fontWeight: 'bold', color: '#283655' }}>ผู้แจ้งปัญหา</label>
                                    </Grid.Column>
                                    <Grid.Column computer='12'>{data?.user_name}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column computer='4' textAlign='right'>
                                        <label style={{ fontWeight: 'bold', color: '#283655' }}>รายละเอียดปัญหา</label>
                                    </Grid.Column>
                                    <Grid.Column computer='12'>{data?.detail}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column computer='4' textAlign='right'>
                                        <label style={{ fontWeight: 'bold', color: '#283655' }}>รูปภาพ</label>
                                    </Grid.Column>
                                    <Grid.Column computer='12'>
                                        <Grid>
                                            {data && data.files_before ? (
                                                data?.files_before.map((file: any, index: number) => (
                                                    <Grid.Row key={index}>
                                                        <Grid.Column computer='16' style={{ display: 'flex' }}>
                                                            <div
                                                                style={{
                                                                    padding: '10px 3px',
                                                                    borderRadius: 8,
                                                                    backgroundColor: '#4D648D',
                                                                    marginRight: 5,
                                                                }}
                                                            />
                                                            {file.type_file.includes('image') ? (
                                                                <a
                                                                    href={`${HOST}${file.path_file}`}
                                                                    target={'_blank'}
                                                                    rel='noreferrer'>
                                                                    <Icon
                                                                        name='file image outline'
                                                                        size='large'
                                                                        style={{ color: '#75B9A5' }}
                                                                    />
                                                                    <label
                                                                        style={{
                                                                            color: '#283655',
                                                                            textDecoration: 'underline',
                                                                            cursor: 'pointer',
                                                                        }}>
                                                                        {file.name}
                                                                    </label>
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    href={`${HOST}${file.path_file}`}
                                                                    target={'_blank'}
                                                                    rel='noreferrer'>
                                                                    <Icon
                                                                        name='file alternate'
                                                                        size='large'
                                                                        style={{ color: '#D85F53' }}
                                                                    />
                                                                    <label
                                                                        style={{
                                                                            color: '#283655',
                                                                            textDecoration: 'underline',
                                                                            cursor: 'pointer',
                                                                        }}>
                                                                        {file.name}
                                                                    </label>
                                                                </a>
                                                            )}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                ))
                                            ) : (
                                                <Grid.Row>
                                                    <Grid.Column computer='11'>
                                                        <label>-</label>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                        {data?.defect_status !== 'ตอบรับแล้ว' ? (
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10'>
                                        <label style={{ fontWeight: 'bold', color: '#283655' }}>ข้อความตอบกลับ</label>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10'>
                                        <Form.Field error={approve_detail_error}>
                                            <TextArea value={approve_detail} onChange={handleChangeApproveDetail} />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column computer='3' only='computer' />
                                    <Grid.Column computer='10'>
                                        <Button
                                            style={{
                                                width: 100,
                                                backgroundColor: '#283655',
                                                color: '#FFF',
                                                marginRight: 15,
                                            }}
                                            disabled={files.length > 3}
                                            onClick={() => {
                                                document.getElementById('file')!.click();
                                            }}>
                                            เลือกไฟล์
                                        </Button>
                                        <label style={{ fontWeight: 'bold', color: '#283655' }}>
                                            เฉพาะสกุลไฟล์ *.jpg หรือ *.png
                                        </label>
                                    </Grid.Column>
                                    <Grid.Column computer='3' only='computer' />
                                </Grid.Row>
                                {files.length > 0 && (
                                    <Grid.Row>
                                        <Grid.Column computer='3' only='computer' />
                                        <Grid.Column computer='10'>
                                            <div style={{ display: 'flex', marginTop: 15 }}>
                                                {files.map((file: File, index: number) => (
                                                    <Segment
                                                        key={index}
                                                        style={{
                                                            width: 120,
                                                            height: 120,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContents: 'center',
                                                            padding: 5,
                                                            margin: 5,
                                                        }}>
                                                        <Label
                                                            floating
                                                            icon={
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => {
                                                                        removeImage(index);
                                                                    }}>
                                                                    <Icon name='delete' style={{ margin: 0 }} />
                                                                </div>
                                                            }
                                                            basic
                                                            circular
                                                        />
                                                        <Image
                                                            style={{ cursor: 'pointer', maxHeight: 110 }}
                                                            fluid
                                                            src={URL.createObjectURL(file)}
                                                        />
                                                    </Segment>
                                                ))}
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column computer='3' only='computer' />
                                    </Grid.Row>
                                )}
                                <Input>
                                    <input
                                        type='file'
                                        id='file'
                                        hidden
                                        onChange={handleChangeFile}
                                        name='Cameramanage'
                                        accept='image/*'
                                    />
                                </Input>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>
                                        <Button
                                            style={{
                                                width: 100,
                                                backgroundColor: '#283655',
                                                color: '#FFF',
                                                marginRight: 15,
                                            }}
                                            onClick={handleSubmit}>
                                            บันทึก
                                        </Button>
                                        <Button
                                            style={{
                                                width: 100,
                                                backgroundColor: '#C54C40',
                                                color: '#FFF',
                                                marginLeft: 15,
                                            }}
                                            onClick={handleClose}>
                                            ยกเลิก
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        ) : (
                            <Fragment>
                                <h3 style={{ color: '#283655' }}>การตอบกลับ</h3>
                                <Segment>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='4' textAlign='right'>
                                                <label style={{ fontWeight: 'bold', color: '#283655' }}>จาก</label>
                                            </Grid.Column>
                                            <Grid.Column computer='12'>ทีมพัฒนา</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='4' textAlign='right'>
                                                <label style={{ fontWeight: 'bold', color: '#283655' }}>ถึง</label>
                                            </Grid.Column>
                                            <Grid.Column computer='12'>{data.user_name}</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='4' textAlign='right'>
                                                <label style={{ fontWeight: 'bold', color: '#283655' }}>
                                                    รายละเอียด
                                                </label>
                                            </Grid.Column>
                                            <Grid.Column computer='12'>
                                                {data.approve_detail ? data.approve_detail : '-'}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='4' textAlign='right'>
                                                <label style={{ fontWeight: 'bold', color: '#283655' }}>ไฟล์แนบ</label>
                                            </Grid.Column>
                                            <Grid.Column computer='12'>
                                                <Grid>
                                                    {data && data.files_after ? (
                                                        data?.files_after.map((file: any, index: number) => (
                                                            <Grid.Row key={index}>
                                                                <Grid.Column computer='16' style={{ display: 'flex' }}>
                                                                    <div
                                                                        style={{
                                                                            padding: '10px 3px',
                                                                            borderRadius: 8,
                                                                            backgroundColor: '#4D648D',
                                                                            marginRight: 5,
                                                                        }}
                                                                    />
                                                                    {file.type_file.includes('image') ? (
                                                                        <a
                                                                            href={`${HOST}${file.path_file}`}
                                                                            target={'_blank'}
                                                                            rel='noreferrer'>
                                                                            <Icon
                                                                                name='file image outline'
                                                                                size='large'
                                                                                style={{ color: '#75B9A5' }}
                                                                            />
                                                                            <label
                                                                                style={{
                                                                                    color: '#283655',
                                                                                    textDecoration: 'underline',
                                                                                    cursor: 'pointer',
                                                                                }}>
                                                                                {file.name}
                                                                            </label>
                                                                        </a>
                                                                    ) : (
                                                                        <a
                                                                            href={`${HOST}${file.path_file}`}
                                                                            target={'_blank'}
                                                                            rel='noreferrer'>
                                                                            <Icon
                                                                                name='file alternate'
                                                                                size='large'
                                                                                style={{ color: '#D85F53' }}
                                                                            />
                                                                            <label
                                                                                style={{
                                                                                    color: '#283655',
                                                                                    textDecoration: 'underline',
                                                                                    cursor: 'pointer',
                                                                                }}>
                                                                                {file.name}
                                                                            </label>
                                                                        </a>
                                                                    )}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        ))
                                                    ) : (
                                                        <Grid.Row>
                                                            <Grid.Column computer='11'>
                                                                <label>-</label>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Fragment>
                        )}
                    </Form>
                </Container>
            </CustomModal>
        </Fragment>
    );
};

export default DefectModalDetail;
