import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    Breadcrumb,
    Button,
    Container,
    Dropdown,
    Grid,
    Icon,
    Input,
    InputOnChangeData,
    Label,
    Pagination,
    Table,
} from 'semantic-ui-react'
import { DELETE_ROLES, GET_ROLES_LIST } from '../../../../config/api/roles'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import ManagementRolesModalForm from './ModalForm'

export interface InterfaceRoles {
    id: number
    name_th: string
    name_en: string
}

const Roles: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [roles, setRoles] = useState<InterfaceRoles[]>([])
    let [open_modal, setOpenModal] = useState<boolean>(false)
    let [data_role, setDataRole] = useState<InterfaceRoles | undefined>()
    let [keyword, setKeyword] = useState<string>('')
    let [municipality, setMunicipality] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >('')
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            setMunicipality(municipality_id)
        }
    }, [location])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const getRolesList = useCallback(() => {
        if (municipality)
            axiosAuthen
                .get(
                    `${GET_ROLES_LIST}?municipality_id=${municipality}&page=${current_page}&offset=${row_qty}&keyword=${keyword}`
                )
                .then((response) => {
                    let { value } = response.data
                    setRecordQty(value.count)

                    setRoles(value.roles_list)
                })
    }, [current_page, municipality, row_qty, keyword])

    useEffect(() => {
        getRolesList()
    }, [getRolesList, open_modal])

    const handleCloseModal = () => setOpenModal(false)

    const handleAddRoles = () => {
        setDataRole(undefined)
        setOpenModal(true)
    }

    const handleEditRoles = (data: InterfaceRoles) => {
        setOpenModal(true)
        setDataRole(data)
    }

    const handleDeleteRoles = (data: InterfaceRoles) => {
        axiosAuthen.delete(`${DELETE_ROLES}/${data.id}`).then((response) => {
            getRolesList()
        })
    }

    const handleSearchKeyword = (e: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <ManagementRolesModalForm
                        open={open_modal}
                        onClose={handleCloseModal}
                        data={data_role}
                        municipality={municipality}
                    />
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities`, {
                                                municipality_id: municipality,
                                            })
                                        }}
                                    >
                                        รายการองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                municipality_id: municipality,
                                            })
                                        }}
                                    >
                                        กำหนดการใช้งานองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>รายการสิทธิ์การใช้งาน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>รายการสิทธิ์การใช้งาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={handleAddRoles}
                                >
                                    <Icon name='plus' /> เพิ่มสิทธิ์
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='10' only='computer' />
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    placeholder='ค้นหา'
                                    icon='search'
                                    value={keyword}
                                    onChange={handleSearchKeyword}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Table attached='top' celled className='table-header'>
                                    <Table.Header className='table-header header'>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign='center' width='1'>
                                                ลำดับ
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width='5' textAlign='center'>
                                                สิทธิ์การใช้งาน (ภาษาไทย)
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width='5' textAlign='center'>
                                                สิทธิ์การใช้งาน (ภาษาอังกฤษ)
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width='1' textAlign='center'>
                                                จัดการ
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                </Table>
                                <Table attached celled className='table-body'>
                                    <Table.Body className='table-body body'>
                                        {roles.map((data: InterfaceRoles, index: number) => (
                                            <Table.Row key={index}>
                                                <Table.Cell textAlign='center' width='1'>
                                                    <Label
                                                        circular
                                                        style={{ backgroundColor: '#425679', color: '#FFFFFF' }}
                                                    >
                                                        {index + 1}
                                                    </Label>
                                                </Table.Cell>
                                                <Table.Cell width='5'>{data.name_th}</Table.Cell>
                                                <Table.Cell width='5'>{data.name_en}</Table.Cell>
                                                <Table.Cell width='1' textAlign='center'>
                                                    <Dropdown icon='ellipsis vertical' pointing='right'>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => handleEditRoles(data)}>
                                                                <Icon
                                                                    name='edit'
                                                                    circular
                                                                    style={{
                                                                        color: '#F2CC6B',
                                                                        border: '1px solid #F2CC6B',
                                                                        boxShadow: 'none',
                                                                    }}
                                                                />
                                                                <label style={{ cursor: 'pointer' }}>แก้ไข</label>
                                                            </Dropdown.Item>
                                                            <Dropdown.Divider />
                                                            <Dropdown.Item onClick={() => handleDeleteRoles(data)}>
                                                                <Icon
                                                                    name='trash alternate'
                                                                    circular
                                                                    style={{
                                                                        color: '#9F3A38',
                                                                        border: '1px solid #9F3A38',
                                                                        boxShadow: 'none',
                                                                    }}
                                                                />
                                                                <label style={{ cursor: 'pointer' }}>ลบ</label>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column computer='11' style={{ display: 'flex' }} only='computer'>
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default Roles
