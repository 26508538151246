import axios from 'axios'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Container, Form, Grid, Icon, Image, Input, InputOnChangeData, Segment } from 'semantic-ui-react'
// import ImagePattern from '../../../../../assets/images/pattern.png';
import ImageWorking from '../../../../../assets/images/working.png'
import ImagePutOff from '../../../../../assets/images/put off.png'
import { GET_NOTIFICATION_LINE_LIST_PRE_COMPLAINT } from '../../../../../config/api/notification'
import { colorList } from '../../../../../constants'
import { useHistory } from 'react-router-dom'
import { getLIFF } from '../../../../../config/liff'

interface InterfaceListPreComplaint {
    id: number
    complaint_subject: string
    pre_complaint_no: string
    status_name: string
}
const LineComplaintList: React.FC = () => {
    let location = useLocation()
    let history = useHistory()
    let [keyword, setKeyword] = useState<string>('')
    let [offset, setOffset] = useState<number>(5)
    let [search_record, setSearchRecord] = useState<number>(0)
    let [total_record, setTotalRecord] = useState<number>(0)
    let [loading, setLoading] = useState<boolean>(false)
    let [municipality_id, setMunicipalityId] = useState<string | null>('')
    let [list_pre_complaint, setListPreComplaint] = useState<InterfaceListPreComplaint[]>([])
    let [line_user_id, setLineUserId] = useState<string>('')
    let [liff_id, setLiffId] = useState<string>('')

    const getPreComplaint = useCallback(() => {
        setLoading(true)
        axios
            .get(
                `${GET_NOTIFICATION_LINE_LIST_PRE_COMPLAINT}/${municipality_id}/${line_user_id}?offset=${offset}&keyword=${keyword}`
            )
            .then((response) => {
                let { value } = response.data
                setListPreComplaint(value.data)
                setTotalRecord(value.total_record)
                setSearchRecord(value.search_record)
                setLoading(false)
            })
    }, [keyword, line_user_id, offset, municipality_id])

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const municipality_id = query.get('municipality_id')
        setMunicipalityId(municipality_id)
        const liff_id = query.get('liff_id')
        liff_id && setLiffId(liff_id)
    }, [location])

    useEffect(() => {
        const connectLIFF = () => {
            getLIFF().then((liff: any) => {
                liff.init({
                    liffId: liff_id,
                }).then(async () => {
                    let profile = await liff.getProfile()
                    setLineUserId(profile.userId)
                    getPreComplaint()
                })
            })
        }
        liff_id && connectLIFF()
    }, [location, getPreComplaint, liff_id])

    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    const handleScroll = (event: any) => {
        let element = event.target
        if (element.scrollHeight - 10 < element.clientHeight + element.scrollTop) {
            if (offset < total_record) {
                setOffset(offset + 5)
            } else {
                setOffset(total_record)
            }
        }
    }

    const setColor = (status: string) => {
        let color = colorList.find((color: any) => color.key === status)
        if (!color) {
            return ''
        } else {
            return color.value
        }
    }

    const setIconStatus = (status: string) => {
        if (status === 'ยกเลิกคำร้อง') {
            return (
                <div
                    style={{
                        height: 50,
                        width: 50,
                        border: '2px solid #FF6161',
                        borderRadius: 100,
                        display: 'flex',
                        padding: 5,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Icon
                        name='remove'
                        size='big'
                        style={{
                            display: 'contents',
                            color: setColor(status),
                        }}
                    />
                </div>
            )
        } else if (status === 'รอรับเรื่อง') {
            return (
                <div
                    style={{
                        height: 50,
                        width: 50,
                        border: '2px solid #61ABE1',
                        borderRadius: 100,
                        display: 'flex',
                        padding: 5,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Icon
                        name='hourglass half'
                        size='big'
                        style={{
                            display: 'contents',
                            color: setColor(status),
                        }}
                    />
                </div>
            )
        } else if (status === 'รอดำเนินการ') {
            return (
                <div
                    style={{
                        height: 50,
                        width: 50,
                        border: '2px solid #767676',
                        borderRadius: 100,
                        display: 'flex',
                        padding: 5,
                    }}>
                    <Image src={ImagePutOff} fluid style={{ color: '#767676' }} />
                </div>
            )
        } else if (status === 'กำลังดำเนินการ') {
            return (
                <div
                    style={{
                        height: 50,
                        width: 50,
                        border: '2px solid #2185D0',
                        borderRadius: 100,
                        display: 'flex',
                        padding: 5,
                    }}>
                    <Image src={ImageWorking} fluid />
                </div>
            )
        } else if (status === 'ไม่สามารถดำเนินการได้') {
            return (
                <div
                    style={{
                        height: 50,
                        width: 50,
                        border: '2px solid orange',
                        borderRadius: 100,
                        display: 'flex',
                        padding: 5,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Icon
                        name='exclamation'
                        size='big'
                        style={{
                            display: 'contents',
                            color: setColor(status),
                        }}
                    />
                </div>
            )
        } else if (status === 'เสร็จสิ้น' || status === 'ประเมินผลเสร็จสิ้น') {
            return (
                <div
                    style={{
                        height: 50,
                        width: 50,
                        border: '2px solid #21BA45',
                        borderRadius: 100,
                        display: 'flex',
                        padding: 5,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Icon
                        name='clipboard check'
                        size='big'
                        style={{
                            display: 'contents',
                            color: setColor(status),
                        }}
                    />
                </div>
            )
        }
    }

    const handleClickPreComplaint = (pre_complaint_id: number) => {
        history.push(`detail?pre_complaint_id=${pre_complaint_id}&municipality_id=${municipality_id}`, {
            go_back: true,
        })
    }
    return (
        <Fragment>
            {/* <div
                style={{
                    backgroundRepeat: 'repeat',
                    backgroundImage: `url(${ImagePattern})`,
                    position: 'absolute',
                    height: 240,
                    zIndex: 1,
                    width: '100%',
                }}
            /> */}
            <div
                style={{
                    backgroundImage: 'linear-gradient(#1D3853, #1D3853)',
                    position: 'absolute',
                    height: 240,
                    width: '100%',
                }}
            />
            <Container>
                <Form style={{ zIndex: 2 }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{
                                    color: 'white',
                                    paddingTop: 14,
                                }}>
                                <h2>ติดตามการดำเนินงาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column tablet='16' mobile='16'>
                                <Segment>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <h4>ค้นหา</h4>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Input
                                                    fluid
                                                    icon='search'
                                                    placeholder='ค้นหาจากเลขที่รับหรือชื่อเรื่อง'
                                                    name='keyword'
                                                    value={keyword}
                                                    onChange={handleChangeKeyword}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <label>
                                                    แสดงข้อมูล {search_record} คำร้อง จากทั้งหมด {total_record} คำร้อง
                                                </label>
                                            </Grid.Column>
                                        </Grid.Row>
                                        {/*//TODO: Loop list data */}

                                        <Grid.Row>
                                            <Grid.Column
                                                tablet='16'
                                                style={{ overflow: 'auto', height: 480 }}
                                                onScroll={handleScroll}>
                                                <Form loading={loading}>
                                                    {list_pre_complaint.map(
                                                        (pre_complaint: InterfaceListPreComplaint, index: number) => (
                                                            <Segment
                                                                style={{ cursor: 'pointer' }}
                                                                key={index}
                                                                onClick={() => {
                                                                    handleClickPreComplaint(pre_complaint.id)
                                                                }}>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column
                                                                            tablet='4'
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}>
                                                                            {setIconStatus(pre_complaint.status_name)}
                                                                        </Grid.Column>
                                                                        <Grid.Column tablet='11'>
                                                                            <Grid>
                                                                                <Grid.Row style={{ padding: 5 }}>
                                                                                    <Grid.Column tablet='16'>
                                                                                        <b>เลขที่รับ</b>{' '}
                                                                                        {pre_complaint.pre_complaint_no}
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                                <Grid.Row style={{ padding: 5 }}>
                                                                                    <Grid.Column tablet='16'>
                                                                                        <b>เรื่อง</b>{' '}
                                                                                        {
                                                                                            pre_complaint.complaint_subject
                                                                                        }
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                                <Grid.Row style={{ padding: 5 }}>
                                                                                    <Grid.Column tablet='16'>
                                                                                        <div>
                                                                                            <Icon
                                                                                                name='circle'
                                                                                                style={{
                                                                                                    color: setColor(
                                                                                                        pre_complaint.status_name
                                                                                                    ),
                                                                                                }}
                                                                                            />
                                                                                            <label
                                                                                                style={{
                                                                                                    color: setColor(
                                                                                                        pre_complaint.status_name
                                                                                                    ),
                                                                                                }}>
                                                                                                {
                                                                                                    pre_complaint.status_name
                                                                                                }
                                                                                            </label>
                                                                                        </div>
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Segment>
                                                        )
                                                    )}
                                                </Form>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Container>
        </Fragment>
    )
}

export default LineComplaintList
