import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Breadcrumb, Container, Grid, Menu, MenuItemProps, Segment } from 'semantic-ui-react'
import ManagementMunicipalityLineInternal from './Internal'
import ManagementMunicipalityLinePerson from './Person'

const ManagementMunicipalityLine: React.FC = () => {
    let history = useHistory()
    let location = useLocation()

    let [municipality_id, setMunicipalityID] = useState<number>(0)
    let [active, setActive] = useState<string | undefined>('internal')

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            setMunicipalityID(municipality_id)
        }
    }, [location])

    const handleItemClick = (_: any, { name }: MenuItemProps) => {
        setActive(name)
    }

    return (
        <div className='background-container'>
            <Container>
                <Grid>
                    <Grid.Row only='computer tablet'>
                        <Grid.Column style={{ zIndex: 1 }}>
                            <Breadcrumb>
                                <Breadcrumb.Section
                                    link
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/municipalities`, {
                                            municipality_id: municipality_id,
                                        })
                                    }}
                                >
                                    รายการองค์กร
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section
                                    link
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                            municipality_id: municipality_id,
                                        })
                                    }}
                                >
                                    กำหนดการใช้งานองค์กร
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />

                                <Breadcrumb.Section active>จัดการการแจ้งเตือน</Breadcrumb.Section>
                            </Breadcrumb>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column computer='4'>
                                            <Menu pointing secondary vertical>
                                                <Menu.Item
                                                    name='internal'
                                                    active={active === 'internal'}
                                                    onClick={handleItemClick}
                                                >
                                                    <label style={{ cursor: 'pointer' }}>แจ้งเตือนเจ้าหน้าที่</label>
                                                </Menu.Item>
                                                <Menu.Item
                                                    name='person'
                                                    active={active === 'person'}
                                                    onClick={handleItemClick}
                                                >
                                                    <label style={{ cursor: 'pointer' }}>แจ้งเตือนประชาชน</label>
                                                </Menu.Item>
                                            </Menu>
                                        </Grid.Column>
                                        <Grid.Column computer='12'>
                                            {active === 'internal' && (
                                                <ManagementMunicipalityLineInternal municipality_id={municipality_id} />
                                            )}
                                            {active === 'person' && (
                                                <ManagementMunicipalityLinePerson municipality_id={municipality_id} />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    )
}

export default ManagementMunicipalityLine
