import React, { Fragment, useCallback, useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import {
    Button,
    Container,
    Dropdown,
    Grid,
    Icon,
    Input,
    Label,
    Table,
    Transition,
    Segment,
    InputOnChangeData,
    DropdownProps,
    TextArea,
    TextAreaProps,
    Form,
    Pagination,
    Breadcrumb,
} from 'semantic-ui-react'
import {
    GET_COMPLAINT_GROUP_STATUS,
    GET_COMPLAINT_LIST,
    GET_COMPLAINT_PRE_COMPLAINT_LIST,
} from '../../../config/api/complaint'
import { axiosAuthen } from '../../../config/axiosAuthen'
import DatePicker from '../../../components/DatePicker'
import FullFormatDateTH from '../../../functions/FullFormatDateTH'
import { GET_CONTACT_ALL } from '../../../config/api/contacts'
import { GET_AREAS_AND_COUMMUNITIES_ALL } from '../../../config/api/areasAndCommunities'
import { GET_COMPLAINT_TOPIC_AND_TYPES_ALL } from '../../../config/api/complaintTopicAndTypes'
import { useDispatch, useSelector } from 'react-redux'
import { resetFormByReducerName } from '../../../store/actions'
import { getAuthenMenuList, getComplaintFilterList } from '../../../store/selectors'

interface InterfaceComplaint {
    id: number
    pre_complaint_id: string
    central_complaint_no: string
    department_complaint_no: string
    complaint_date: string
    contact_name: string
    topic_name: string
    type_name: string
    full_name: string
    mobile_number: string
    phone_number: string
    status_name: string
    full_name_technician: string
    grade: string
}

interface InterfacePreComplaint {
    area_name: string
    community_name: string
    first_name: string
    id: number
    last_name: string
    mobile_number: string
    pre_complaint_date: string
    pre_complaint_no: string
    prefix: string
    status_name: string
    topic_name: string
}

const List: React.FC = () => {
    let history = useHistory()
    const { menu_list } = useSelector(getAuthenMenuList)
    const filter_list = useSelector(getComplaintFilterList)

    let complaint_action = menu_list
        ?.find((menu) => menu.name_en === 'complaint')
        ?.pages?.find((page) => page.name_th === 'รายการคำร้องทุกข์')?.actions
    let [complaint_list, setComplaintList] = useState<InterfaceComplaint[]>([])
    let [pre_complaint_list, setPreComplaintList] = useState<InterfacePreComplaint[]>([])
    let [filter, setFilter] = useState<boolean>(false)
    let [central_complaint_no, setCentralComplaintNo] = useState<string>('')
    let [department_complaint_no, setDepartmentNo] = useState<string>('')
    let [complaint_date, setComplaintDate] = useState<string>('')
    let [contact_id, setContactId] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [topic_id, setTopicId] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [contact_option, setContactOption] = useState<{ value: number; text: string }[]>([])
    let [topic_option, setTopicOption] = useState<{ value: number; text: string }[]>([])
    let [full_name_technician, setFullNameTechnician] = useState<string>('')
    let [area_id, setAreaId] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [area_option, setAreaOption] = useState<{ value: number; text: string }[]>()
    let [full_name, setFullName] = useState<string>('')
    let [status, setStatus] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined | string[]
    >(filter_list.status)
    let [status_option, setStatusOption] = useState<{ value: number; text: string }[]>()
    let [complaint_detail, setComplaintDetail] = useState<string | number | undefined>('')
    let [keyword, setKeyword] = useState<string>('')
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [complaint_type, setComplaintType] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >(1)
    let [loading, setLoading] = useState<boolean>(false)
    let option_complaint_type = [{ value: 1, text: 'คำร้องทั้งหมด' }]
    if (complaint_action?.some((action) => action.name_th === 'คำร้องจากประชาชน')) {
        option_complaint_type.push({ value: 2, text: 'คำร้องประชาชน' })
    }
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    let dispatch = useDispatch()

    const getComplaintList = useCallback(() => {
        setLoading(true)
        let searh_option = `central_complaint_no=${central_complaint_no}&department_complaint_no=${department_complaint_no}&complaint_date=${complaint_date}&contact_id=${contact_id}&topic_id=${topic_id}&full_name_technician=${full_name_technician}&area_id=${area_id}&full_name=${full_name}&status=${status}&complaint_detail=${complaint_detail}&page=${current_page}&offset=${row_qty}&keyword=${keyword}`
        axiosAuthen.get(`${GET_COMPLAINT_LIST}?${searh_option}`).then((response) => {
            let { value } = response.data
            setRecordQty(value.count)
            setComplaintList(value.complaints)
            setLoading(false)
            let element = document.getElementById('pusher')
            element?.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }, [
        area_id,
        central_complaint_no,
        complaint_date,
        complaint_detail,
        contact_id,
        current_page,
        department_complaint_no,
        full_name,
        full_name_technician,
        row_qty,
        status,
        topic_id,
        keyword,
    ])

    const getPreComplaintList = useCallback(() => {
        axiosAuthen
            .get(`${GET_COMPLAINT_PRE_COMPLAINT_LIST}?page=${current_page}&offset=${row_qty}`)
            .then((response) => {
                let { value } = response.data
                setRecordQty(value.count)
                setPreComplaintList(value.pre_complaints)
                let element = document.getElementById('pusher')
                element?.scrollTo({ top: 0, behavior: 'smooth' })
            })
    }, [current_page, row_qty])
    useEffect(() => {
        if (complaint_type === 1) {
            getComplaintList()
        }
        if (complaint_type === 2) {
            getPreComplaintList()
        }
    }, [complaint_type, getComplaintList, getPreComplaintList])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    useEffect(() => {
        const getContact = () => {
            axiosAuthen.get(GET_CONTACT_ALL).then((response) => {
                let { value } = response.data
                let contact_option = value.map((data: any) => ({
                    value: data.id,
                    text: data.name,
                }))
                setContactOption(contact_option)
            })
        }
        const getTopic = () => {
            axiosAuthen.get(GET_COMPLAINT_TOPIC_AND_TYPES_ALL).then((response) => {
                let { value } = response.data
                let topic_option = value.map((data: any) => ({
                    value: data.id,
                    text: data.name,
                }))
                setTopicOption(topic_option)
            })
        }
        const getArea = () => {
            axiosAuthen.get(GET_AREAS_AND_COUMMUNITIES_ALL).then((response) => {
                let { value } = response.data
                let area_option = value.map((data: any) => ({
                    value: data.id,
                    text: data.name,
                }))
                setAreaOption(area_option)
            })
        }
        const getStatus = () => {
            axiosAuthen.get(GET_COMPLAINT_GROUP_STATUS).then((response) => {
                let { value } = response.data
                let status_option = value.map((data: any) => ({
                    value: data,
                    text: data,
                }))
                setStatusOption(status_option)
            })
        }

        getContact()
        getTopic()
        getArea()
        getStatus()
    }, [])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const handleClickFilter = () => {
        setFilter(!filter)
    }

    const handleClearFilter = () => {
        setCentralComplaintNo('')
        setDepartmentNo('')
        setComplaintDate('')
        setContactId('')
        setTopicId('')
        setFullNameTechnician('')
        setAreaId('')
        setFullName('')
        setStatus('')
        setComplaintDetail('')
    }

    const handleSearchCentralComplaintNo = (e: any, { value }: InputOnChangeData) => {
        setCentralComplaintNo(value)
    }
    const handleSearchDepartmentComplaintNo = (e: any, { value }: InputOnChangeData) => {
        setDepartmentNo(value)
    }
    const handleSearchComplaintDate = (e: any, { value }: InputOnChangeData) => {
        setComplaintDate(value)
    }
    const handleSearchContactId = (e: any, { value }: DropdownProps) => {
        setContactId(value)
    }
    const handleSearchTopicId = (e: any, { value }: DropdownProps) => {
        setTopicId(value)
    }
    const handleSearchTechnician = (e: any, { value }: InputOnChangeData) => {
        setFullNameTechnician(value)
    }
    const handleSearchAreaId = (e: any, { value }: DropdownProps) => {
        setAreaId(value)
    }
    const handleSearchFullName = (e: any, { value }: InputOnChangeData) => {
        setFullName(value)
    }
    const handleSearchStatus = (e: any, { value }: DropdownProps) => {
        setStatus(value)
    }
    const handleChangeComplaintType = (e: any, { value }: DropdownProps) => {
        setComplaintType(value)
    }
    const handleSearchComplaintDetail = (e: any, { value }: TextAreaProps) => {
        setComplaintDetail(value)
    }
    const handleSearchKeyword = (e: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    const handleClickAddComplaint = () => {
        dispatch(resetFormByReducerName('ComplaintFormStep1'))
        dispatch(resetFormByReducerName('ComplaintFormStep2'))
        dispatch(resetFormByReducerName('ComplaintFormStep3'))
        history.push('complaint/form')
    }
    const handleClickDetail = (id: number) => {
        history.push('complaint/detail', { complaint_id: id })
    }

    const handleClickDetailPreComplaint = (id: number) => {
        history.push('complaint/detail-pre-complaint', { pre_complaint_id: id })
    }

    const handleClickExport = () => {
        history.push('complaint/export')
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section active>รายการคำร้อง</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 style={{ textAlign: 'center' }}>รายการคำร้อง</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='5' mobile='16' style={{ marginTop: 10 }}>
                                <Grid>
                                    <Grid.Row columns='equal'>
                                        {complaint_action?.find((action) => action.name_en === 'add complaint') && (
                                            <Grid.Column>
                                                <Button
                                                    fluid
                                                    icon
                                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                                    labelPosition='left'
                                                    onClick={handleClickAddComplaint}
                                                >
                                                    <Icon name='plus' /> เพิ่มคำร้อง
                                                </Button>
                                            </Grid.Column>
                                        )}
                                        {complaint_action?.find(
                                            (action) => action.name_th === 'ออกรายงานคำร้องทุกข์'
                                        ) && (
                                            <Grid.Column>
                                                <Button
                                                    fluid
                                                    icon
                                                    style={{ backgroundColor: '#484F5F', color: 'white' }}
                                                    labelPosition='left'
                                                    onClick={handleClickExport}
                                                >
                                                    <Icon name='file excel' color='green' />
                                                    ออกรายงาน
                                                </Button>
                                            </Grid.Column>
                                        )}
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column computer='2' only='computer' />
                            <Grid.Column computer='3' mobile='8' style={{ marginTop: 10 }}>
                                <Dropdown
                                    fluid
                                    selection
                                    options={option_complaint_type}
                                    value={complaint_type}
                                    onChange={handleChangeComplaintType}
                                />
                            </Grid.Column>
                            <Grid.Column computer='3' mobile='8' style={{ marginTop: 10 }}>
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        icon
                                        labelPosition='left'
                                        fluid
                                        style={{ backgroundColor: '#4D648D', color: 'white' }}
                                        onClick={handleClickFilter}
                                    >
                                        <Icon name='filter' /> กรองข้อมูล
                                    </Button>
                                    {(central_complaint_no ||
                                        department_complaint_no ||
                                        complaint_date ||
                                        contact_id ||
                                        topic_id ||
                                        full_name_technician ||
                                        area_id ||
                                        full_name ||
                                        status ||
                                        complaint_detail) && (
                                        <Icon
                                            name='delete'
                                            style={{
                                                display: 'inline-flex',
                                                border: '1px solid rgb(182, 57, 45)',
                                                borderRadius: 10,
                                                height: 'auto',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgb(182, 57, 45)',
                                                zIndex: 1,
                                                position: 'absolute',
                                                right: 20,
                                                top: 7,
                                                paddingRight: 10,
                                                paddingLeft: 10,
                                                paddingBottom: 1,
                                                color: 'white',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleClearFilter}
                                        />
                                    )}
                                </div>
                            </Grid.Column>
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    placeholder='ค้นหา'
                                    icon='search'
                                    value={keyword}
                                    onChange={handleSearchKeyword}
                                />
                            </Grid.Column>
                            <Grid.Column computer='16' mobile='16'>
                                <Form>
                                    <Transition.Group animation='slide down' duration='500'>
                                        {filter && (
                                            <Segment
                                                style={{
                                                    marginTop: 15,
                                                    border: '2px solid #293757',
                                                }}
                                            >
                                                <Grid>
                                                    <Grid.Row style={{ justifyContent: 'center' }}>
                                                        <Grid.Column computer='5' tablet='8' mobile='8'>
                                                            เลขส่วนกลาง
                                                            <Input
                                                                name='central_complaint_no'
                                                                fluid
                                                                value={central_complaint_no}
                                                                onChange={handleSearchCentralComplaintNo}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='8'>
                                                            เลขกอง
                                                            <Input
                                                                name='department_complaint_no'
                                                                fluid
                                                                value={department_complaint_no}
                                                                onChange={handleSearchDepartmentComplaintNo}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='8'>
                                                            วัน / เดือน / ปี ที่รับเรื่อง
                                                            <DatePicker
                                                                name='complaint_date'
                                                                fluid
                                                                value={complaint_date}
                                                                onChange={handleSearchComplaintDate}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='8'>
                                                            ช่องทางการรับเรื่อง
                                                            <Dropdown
                                                                name='contact_id'
                                                                fluid
                                                                selection
                                                                value={contact_id}
                                                                options={contact_option}
                                                                onChange={handleSearchContactId}
                                                                clearable
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='8'>
                                                            เรื่องร้องเรียน
                                                            <Dropdown
                                                                name='topic_id'
                                                                fluid
                                                                selection
                                                                value={topic_id}
                                                                options={topic_option}
                                                                onChange={handleSearchTopicId}
                                                                clearable
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='8'>
                                                            เจ้าหน้าที่รับผิดชอบ
                                                            <Input
                                                                name='full_name_technician'
                                                                fluid
                                                                value={full_name_technician}
                                                                onChange={handleSearchTechnician}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='8'>
                                                            พื้นที่เขต
                                                            <Dropdown
                                                                name='area_id'
                                                                fluid
                                                                selection
                                                                value={area_id}
                                                                options={area_option}
                                                                onChange={handleSearchAreaId}
                                                                clearable
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='8'>
                                                            ผู้ยื่นคำร้อง
                                                            <Input
                                                                name='full_name'
                                                                fluid
                                                                value={full_name}
                                                                onChange={handleSearchFullName}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='16' mobile='16'>
                                                            สถานะคำร้อง
                                                            <Dropdown
                                                                name='status'
                                                                fluid
                                                                selection
                                                                value={status}
                                                                options={status_option}
                                                                onChange={handleSearchStatus}
                                                                multiple
                                                                clearable
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='15' tablet='16' mobile='16'>
                                                            รายละเอียด
                                                            <TextArea
                                                                name='complaint_detail'
                                                                value={complaint_detail}
                                                                onChange={handleSearchComplaintDetail}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        )}
                                    </Transition.Group>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{ zIndex: 1 }}>
                                {complaint_type === 1 && (
                                    <Fragment>
                                        <Form loading={loading}>
                                            <Table attached='top' celled className='table-header'>
                                                <Table.Header className='table-header header'>
                                                    <Table.Row>
                                                        <Table.HeaderCell textAlign='center' width='1'>
                                                            ลำดับ
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell width='4'>
                                                            <div>
                                                                <Icon name='circle' /> เลขส่วนกลาง{' '}
                                                                <Icon name='circle' /> เลขรับ
                                                            </div>
                                                            <div>
                                                                <Icon name='calendar' /> วัน / เดือน / ปี ที่รับเรื่อง
                                                            </div>
                                                            <div>
                                                                <Icon name='sign in' /> ช่องทางการรับเรื่อง
                                                            </div>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell width='5'>
                                                            <div>
                                                                <Icon name='clipboard' /> เรื่องร้องทุกข์
                                                            </div>
                                                            <div>
                                                                <Icon name='user' /> ผู้ยื่นคำร้อง
                                                            </div>
                                                            <div>
                                                                <Icon name='phone' /> ติดต่อ
                                                            </div>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell width='3'>สถานะ</Table.HeaderCell>
                                                        <Table.HeaderCell width='3'>
                                                            เจ้าหน้าที่รับผิดชอบ
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                            </Table>
                                            <Table attached celled className='table-body'>
                                                <Table.Body className='table-body body'>
                                                    {complaint_list.map((data: InterfaceComplaint, index: number) => (
                                                        <Table.Row
                                                            key={index}
                                                            onClick={() => handleClickDetail(data.id)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <Table.Cell textAlign='center' width='1'>
                                                                <Label circular>{index + 1}</Label>
                                                            </Table.Cell>
                                                            <Table.Cell width='4'>
                                                                <div>
                                                                    <Icon name='circle' /> {data.central_complaint_no}{' '}
                                                                    <Icon name='circle' />{' '}
                                                                    {data.department_complaint_no}
                                                                </div>
                                                                <div>
                                                                    <Icon name='calendar' />{' '}
                                                                    {FullFormatDateTH(data.complaint_date)}
                                                                </div>
                                                                <div>
                                                                    <Icon name='sign in' /> {data.contact_name}
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell width='5'>
                                                                <div>
                                                                    <Icon name='clipboard' /> {data.topic_name} /{' '}
                                                                    {data.type_name}
                                                                </div>
                                                                <div>
                                                                    <Icon name='user' /> {data.full_name}
                                                                </div>
                                                                <div>
                                                                    <Icon name='phone' /> {data.mobile_number}
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell width='3'>
                                                                <Icon name='circle' />
                                                                {data.status_name}
                                                            </Table.Cell>
                                                            <Table.Cell width='3'>
                                                                {data.full_name_technician}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                        </Form>
                                    </Fragment>
                                )}
                                {complaint_type === 2 && (
                                    <Fragment>
                                        <Form loading={loading}>
                                            <Table attached='top' celled className='table-header'>
                                                <Table.Header className='table-header header'>
                                                    <Table.Row>
                                                        <Table.HeaderCell width='1'>ลำดับ</Table.HeaderCell>
                                                        <Table.HeaderCell width='4'>
                                                            <Icon name='circle' /> เลขที่รับจากคำร้องประชาชน <br />
                                                            <Icon name='calendar' /> วัน/เดือน/ปี ที่ร้องทุกข์
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell width='6'>
                                                            <Icon name='clipboard' /> เรื่องร้องทุกข์ <br />
                                                            <Icon name='user' /> ผู้ยื่นคำร้อง <br />
                                                            <Icon name='phone' /> ติดต่อ
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell width='3' textAlign='center'>
                                                            สถานะ
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                            </Table>
                                            <Table attached celled className='table-body'>
                                                <Table.Body className='table-body body'>
                                                    {pre_complaint_list.map(
                                                        (data: InterfacePreComplaint, index: number) => (
                                                            <Table.Row
                                                                key={index}
                                                                onClick={() => handleClickDetailPreComplaint(data.id)}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <Table.Cell textAlign='center' width='1'>
                                                                    <Label circular>{index + 1}</Label>
                                                                </Table.Cell>
                                                                <Table.Cell width='4'>
                                                                    <div>
                                                                        <Icon name='circle' /> {data.pre_complaint_no}
                                                                    </div>
                                                                    <div>
                                                                        <Icon name='calendar' />{' '}
                                                                        {FullFormatDateTH(data.pre_complaint_date)}
                                                                    </div>
                                                                </Table.Cell>
                                                                <Table.Cell width='6'>
                                                                    <div>
                                                                        <Icon name='clipboard' /> {data.topic_name}
                                                                    </div>
                                                                    <div>
                                                                        <Icon name='user' /> {data.prefix}
                                                                        {data.first_name} {data.last_name}
                                                                    </div>
                                                                    <div>
                                                                        <Icon name='phone' />{' '}
                                                                        {data.mobile_number ? data.mobile_number : '-'}
                                                                    </div>
                                                                </Table.Cell>
                                                                <Table.Cell width='3'>
                                                                    <Icon name='circle' />
                                                                    {data.status_name}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    )}
                                                </Table.Body>
                                            </Table>
                                        </Form>
                                    </Fragment>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10 }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                    paddingRight: 40,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column
                                            computer='11'
                                            tablet='16'
                                            mobile='16'
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default List
