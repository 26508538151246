import React, { Fragment, useState, useCallback, useEffect } from 'react'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import { Grid, Icon, Button } from 'semantic-ui-react'
import CustomModal from '../../../../../components/CustomModal'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { GET_COMPLAINT_HISTORY } from '../../../../../config/api/complaint'
import { useLocation } from 'react-router-dom'
import FullFormatDateTH from '../../../../../functions/FullFormatDateTH'

type FormProps = {
    complaint_id?: string | number
}

interface InterfaceHistoryDetail {
    action: string
    approve_by: string
    approve_created_date: string
    approve_created_time: string
    approve_status: string
    complaints_id: number
    created_by: string
    created_date: string
    created_time: string
    detail: string
    next_status_id: number
    next_status_name: string
}
const HistoryComplaint: React.FC<FormProps> = () => {
    let location = useLocation()
    let [open, setOpen] = useState<boolean>(false)
    let [history_detail, setHistoryDetail] = useState<InterfaceHistoryDetail[]>([])

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const getListHistory = useCallback(() => {
        let { state } = location
        if (state && open) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { complaint_id } = location_state

            axiosAuthen.get(`${GET_COMPLAINT_HISTORY}/${complaint_id}`).then((response) => {
                let { value } = response.data

                setHistoryDetail(value)
            })
        }
    }, [location, open])

    useEffect(() => {
        getListHistory()
    }, [getListHistory])

    return (
        <Fragment>
            <CustomModal
                icon='history'
                header='ประวัติคำร้อง'
                open={open}
                closeOnEscape
                closeOnDimmerClick
                onClose={handleClose}
                trigger={
                    <ButtonIconOver
                        fluid
                        label='ประวัติคำร้อง'
                        color='#7F58AF'
                        icon='history'
                        basic
                        onClick={handleOpen}
                    />
                }
            >
                <Grid style={{ backgroundColor: '#5B8CAF' }}>
                    <VerticalTimeline layout='1-column'>
                        {history_detail.map((data: InterfaceHistoryDetail, index: number) =>
                            data.next_status_name === 'รอเจ้าหน้าที่รับเรื่อง' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#00BFFF',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='shopping bag' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>

                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>
                                            <b>ดำเนินการโดย</b>
                                        </b>{' '}
                                        : {data.created_by}
                                    </p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'ชะลอเรื่อง' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#000000',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='write square' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                    <p>หมายเหตุ : {data.detail}</p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'ติดตาม' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#000000',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='write square' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                    <p>หมายเหตุ : {data.detail}</p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'เข้าแผนปีงบประมาณ' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#000000',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='hourglass two' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                    <p>หมายเหตุ : {data.detail}</p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'แก้ไขคำร้อง' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#F2CC6B',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='edit' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>

                                    <p>หมายเหตุ : {data.detail}</p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'ไม่สามารถดำเนินการได้' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#F2CC6B',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='cancel' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>

                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                    <p>หมายเหตุ : {data.detail}</p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'รอการประเมินผล' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#21BA45',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='clipboard' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'ประเมินผลเสร็จสิ้น' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    iconStyle={{
                                        background: '#21BA45',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='calendar check' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'รออนุมัติ' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#000000',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='hourglass half' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>

                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                    <p>ผู้อนุมัติคำร้อง : {data.approve_by}</p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'รอหน่วยงานรับเรื่อง' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    iconStyle={{
                                        background: '#1E90FF',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='hourglass half' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                </VerticalTimelineElement>
                            ) : data.action === 'เปลี่ยนหน่วยงาน(ส่งกลับส่วนกลาง)' ||
                              data.action === 'เปลี่ยนหน่วยงาน(ส่งต่อหน่วยงานอื่น)' ||
                              data.action === 'เปลี่ยนหน่วยงาน' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#FFBF00',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='share' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>

                                    <p>หมายเหตุ : {data.detail || '-'}</p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'ส่งต่อหน่วยงานอื่น' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    iconStyle={{
                                        background: '#7B68EE',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='hand point left outline' size='large' />}
                                >
                                    <h3 className='share'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'กำลังดำเนินการ' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#6495ED',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='write square' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                    {/* 
                                            <p>วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.approve_created_time} <b>ดำเนินการโดย</b> : { }</p>
                                            <p>หมายเหตุ : {data.detail}</p> */}
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'รอดำเนินการ' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#87CEEB',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='hourglass half' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                </VerticalTimelineElement>
                            ) : data.next_status_name === 'อนุมัติ' ? (
                                <VerticalTimelineElement
                                    key={index}
                                    className='vertical-timeline-element--work'
                                    iconStyle={{
                                        background: '#21BA45',
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    icon={<Icon name='calendar check' size='large' />}
                                >
                                    <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                    <p>
                                        วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                        <b>ดำเนินการโดย</b> : {data.created_by}
                                    </p>
                                    <p>ผู้อนุมัติคำร้อง : {data.approve_by}</p>
                                    <p>
                                        วันที่อนุมัติ {FullFormatDateTH(data.approve_created_date)} เวลา{' '}
                                        {data.approve_created_time}
                                    </p>
                                </VerticalTimelineElement>
                            ) : (
                                data.next_status_name === 'ไม่อนุมัติ' && (
                                    <VerticalTimelineElement
                                        key={index}
                                        className='vertical-timeline-element--work'
                                        iconStyle={{
                                            background: '#9F3A38',
                                            color: 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        icon={<Icon name='cancel' size='large' />}
                                    >
                                        <h3 className='vertical-timeline-element-title'>{data.action}</h3>
                                        <p>
                                            วันที่ {FullFormatDateTH(data.created_date)} เวลา {data.created_time}{' '}
                                            <b>ดำเนินการโดย</b> : {data.created_by}
                                        </p>
                                        <p>ผู้อนุมัติคำร้อง : {data.approve_by}</p>
                                        <p>
                                            วันที่อนุมัติ {FullFormatDateTH(data.approve_created_date)} เวลา{' '}
                                            {data.approve_created_time}
                                        </p>
                                    </VerticalTimelineElement>
                                )
                            )
                        )}
                    </VerticalTimeline>

                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Button
                                style={{
                                    width: 100,
                                    backgroundColor: '#C54C40',
                                    color: '#FFF',
                                    marginLeft: 15,
                                }}
                                onClick={handleClose}
                            >
                                ปิด
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </CustomModal>
        </Fragment>
    )
}

export default HistoryComplaint
