import React, { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Checkbox, Container, Form, Grid, Icon, Image, Input, Label, Message, Segment } from 'semantic-ui-react'
import { GET_MUNICIPALITY_LOGO } from '../../../config/api/municipality'
import {
    POST_NOTIFICATION_ONE_CHAT_CHECK_REGISTER,
    POST_NOTIFICATION_ONE_CHAT_USER_BIND_ONE_USER_ID,
} from '../../../config/api/notification'
import { axiosAuthen } from '../../../config/axiosAuthen'
import { HOST } from '../../../config/router'
import './styles.css'

const OneChatLogin: React.FC = () => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [username_error, setUsernameError] = useState<boolean>(false)
    const [password_error, setPasswordError] = useState<boolean>(false)
    const [show_password, setShowPassword] = useState<boolean>(false)
    const [remember, setRemember] = useState<boolean>(false)
    const [error_text, setErrorText] = useState<string>('')
    const [one_id, setOneId] = useState<string | null>('')
    const [, setRegistered] = useState<boolean | null>(false)
    const [logo, setLogo] = useState<string | null>('')
    let location = useLocation()

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const token = query.get('onechat_token')
        const municipality_id = query.get('municipality_id')
        axiosAuthen
            .post(POST_NOTIFICATION_ONE_CHAT_CHECK_REGISTER, {
                onechat_token: token,
                municipality_id: Number(municipality_id),
            })
            .then((response) => {
                let { value } = response.data
                setOneId(value.one_id)
                setRegistered(value.result)
            })
        axiosAuthen.get(`${GET_MUNICIPALITY_LOGO}/${municipality_id}`).then((response) => {
            let { logo } = response.data
            setLogo(logo)
        })
    }, [location])

    const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target
        setUsername(value)
        setUsernameError(false)
    }

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target
        setPassword(value)
        setPasswordError(false)
    }

    const validate = () => {
        let error = false
        if (!username) {
            error = true
            setUsernameError(true)
        }
        if (!password) {
            error = true
            setPasswordError(true)
        }
        return error
    }

    const handleSubmit = () => {
        if (!validate()) {
            setLoading(true)
            setErrorText('')
            setLoading(false)
            let body = {
                one_id: one_id,
                username: username,
                password: password,
            }
            axiosAuthen.post(POST_NOTIFICATION_ONE_CHAT_USER_BIND_ONE_USER_ID, body).then((response) => {})
        }
    }

    return (
        <Fragment>
            <div className='background-line-login-page'>
                <Container fluid>
                    <Segment className='segment-line-login-page'>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column
                                    computer='8'
                                    tablet='16'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <Image
                                        src={'https://demo-complaint.ogsdev.net/static/media/2384418.dbc254e2.png'}
                                    />
                                    <Image src={`${HOST}/${logo}`} />
                                </Grid.Column>
                                <Grid.Column computer='8' tablet='16'>
                                    <Segment
                                        style={{
                                            boxShadow: '0px 1px 40px #DCDCDC',
                                            borderRadius: 20,
                                            margin: 5,
                                        }}>
                                        <Form loading={loading} onSubmit={handleSubmit}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <h2 style={{ textAlign: 'center' }}>เข้าสู่ระบบ</h2>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <Form.Field error={username_error}>
                                                            <Input
                                                                label={
                                                                    <Label basic>
                                                                        <Icon name='user' fitted color='grey' />
                                                                    </Label>
                                                                }
                                                                fluid
                                                                name='username'
                                                                placeholder='อีเมล / หมายเลขโทรศัพท์'
                                                                value={username}
                                                                onChange={handleChangeUsername}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <Form.Field error={password_error}>
                                                            <Input
                                                                label={
                                                                    <Label basic>
                                                                        <Icon name='lock' fitted color='grey' />
                                                                    </Label>
                                                                }
                                                                fluid
                                                                icon={
                                                                    <Icon
                                                                        name={show_password ? 'eye' : 'eye slash'}
                                                                        link
                                                                        onClick={() => {
                                                                            setShowPassword(!show_password)
                                                                        }}
                                                                    />
                                                                }
                                                                type={show_password ? 'text' : 'password'}
                                                                name='password'
                                                                placeholder='รหัสผ่าน'
                                                                value={password}
                                                                onChange={handleChangePassword}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <Checkbox
                                                            label='จดจำรหัสผ่าน'
                                                            name='remember'
                                                            checked={remember}
                                                            onChange={() => {
                                                                setRemember(!remember)
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    {/* <Grid.Column computer='8'>
														<div
															style={{
																display: 'flex',
																justifyContent: 'flex-end',
																cursor: 'pointer'
															}}
														>
															<Icon
																name='lock'
																style={{ color: '#53A6D8', cursor: 'pointer' }}
															/>{' '}
															<label style={{ color: '#53A6D8', cursor: 'pointer' }}>
																ลืมรหัสผ่าน
															</label>
														</div>
													</Grid.Column> */}
                                                </Grid.Row>
                                                {error_text && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}>
                                                                <Message negative>{error_text}</Message>
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}
                                                <Grid.Row>
                                                    <Grid.Column computer='16'>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}>
                                                            <Button
                                                                style={{
                                                                    color: 'white',
                                                                    backgroundColor: '#4D648D',
                                                                }}>
                                                                เข้าสู่ระบบ
                                                            </Button>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='16' tablet='16'>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}>
                                                            <label
                                                                style={{
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 600,
                                                                    fontSize: 10,
                                                                    textAlign: 'right',
                                                                    letterSpacing: -0.1,
                                                                    color: '#5B5B5B',
                                                                    flex: 'none',
                                                                    order: 0,
                                                                    alignSelf: 'center',
                                                                    margin: '15px 0px',
                                                                }}>
                                                                Powered by
                                                            </label>{' '}
                                                            <Image
                                                                src='https://ogs.co.th/wp-content/uploads/2020/02/OGS_logo-1.png'
                                                                style={{ width: 64, height: 64, marginLeft: 15 }}
                                                            />
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Form>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Container>
            </div>
        </Fragment>
    )
}

export default OneChatLogin
