import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Grid, Input, Icon, Segment, InputOnChangeData, Dropdown, DropdownProps } from 'semantic-ui-react'
import CustomModal from '../../../../../components/CustomModal'
import ButtonIconOver from '../../../../../components/ButtonIconOver'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import {
    GET_COMPLAINT_TECHNICIAN_MATCH_DIRECTORY,
    POST_COMPLAINT_CHANGE_TECHNICIAN,
} from '../../../../../config/api/complaint'
import { alert } from '../../../../../components/alert'
import { GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE } from '../../../../../config/api/directoryOfMunicipality'

type FormProps = {
    complaint_id?: string | number
    users_id?: number
    reload?: () => void
}
interface InterfaceTechnician {
    id: number
    name: string
    phone_number: string
}

const ChangeTechnician: React.FC<FormProps> = (props) => {
    let [open, setOpen] = useState<boolean>(false)
    let [parent_directory_option, setParentDirectoryOption] = useState<[]>([])
    let [parent_directory, setParentDirectory] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >(undefined)
    let [directory, setDirectory] = useState<string | number | boolean | (string | number | boolean)[] | undefined>(
        undefined
    )
    let [children_directory_option, setChildrenDirectoryOption] = useState<[]>([])
    let [children_directory, setChildrenDirectory] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >(undefined)

    let [data_technician, setDataTechnician] = useState<InterfaceTechnician[]>([])
    let [object_data_technician, setObjectDataTechnician] = useState<InterfaceTechnician[]>([])
    let [search_technician, setSearchTechnician] = useState<string>('')
    let [technician_id, setTechnicianId] = useState<number | undefined>(undefined)
    let [loading, setLoading] = useState<boolean>(false)

    const getChildrenDirectory = (
        directory_id: string | number | boolean | (string | number | boolean)[] | undefined
    ) => {
        directory_id &&
            axiosAuthen
                .get(`${GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE}?directory_of_municipalities_id=${directory_id}`)
                .then((response) => {
                    let { value } = response.data
                    let children_directory_option = value.map((data: any) => ({ value: data.id, text: data.name }))
                    setChildrenDirectoryOption(children_directory_option)
                })
    }

    // useEffect(() => {
    //     let directory = localStorage.getItem('directory_of_municipalities_id')
    //     directory && setDirectory(directory)
    // }, [])

    useEffect(() => {
        axiosAuthen.get(`${GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE}`).then((response) => {
            let { value } = response.data
            let directory_option = value.map((data: any) => ({ value: data.id, text: data.name }))
            setParentDirectoryOption(directory_option)
        })
    }, [])

    useEffect(() => {
        const getDataTechnician = () => {
            axiosAuthen.get(`${GET_COMPLAINT_TECHNICIAN_MATCH_DIRECTORY}/${directory}`).then((response) => {
                let { value } = response.data
                let data_technician = value.map((data: any) => ({
                    id: data.id,
                    name: data.full_name_th,
                    phone_number: data.phone_number,
                }))
                setDataTechnician(data_technician)
                setObjectDataTechnician(data_technician)
            })
        }
        getDataTechnician()
    }, [directory])

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleClose = () => {
        setOpen(false)
        setParentDirectory(undefined)
        setDirectory(undefined)
        setChildrenDirectoryOption([])
        setChildrenDirectory(undefined)
        setSearchTechnician('')
        setTechnicianId(undefined)
    }

    const handleSearchTechnician = (_: any, { value }: InputOnChangeData) => {
        setSearchTechnician(value)
        let search_technician = data_technician.filter(
            (technician: InterfaceTechnician) => technician.name.indexOf(value) !== -1
        )
        setObjectDataTechnician(search_technician)
    }

    const handleClickTechnician = (id: number) => {
        setTechnicianId(id)
    }

    const handleChangeDirectory = (e: any, { value }: DropdownProps) => {
        setDirectory(value)
        setParentDirectory(value)
        getChildrenDirectory(value)
    }

    const handleChangeChildrenDirectory = (e: any, { value }: DropdownProps) => {
        setDirectory(value)
        setChildrenDirectory(value)
    }

    const handleSubmit = () => {
        let body = {
            complaints_id: props.complaint_id,
            users_id: technician_id,
        }
        setLoading(true)
        axiosAuthen
            .post(POST_COMPLAINT_CHANGE_TECHNICIAN, body)
            .then(() => {
                alert({
                    type: 'success',
                    title: 'บันทึกเสร็จสิ้น',
                    timer: 1500,
                    onClosed: () => {
                        setLoading(false)
                        handleClose()
                        props.reload && props.reload()
                    },
                })
            })
            .catch(() => {
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <CustomModal
                icon='file alternate'
                header='มอบหมายเจ้าหน้าที่'
                open={open}
                trigger={
                    <ButtonIconOver
                        fluid
                        label='เปลี่ยนเจ้าหน้าที่'
                        color='#FEB326'
                        icon='refresh'
                        basic
                        onClick={handleOpen}
                    />
                }
            >
                <Form loading={loading}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='4' tablet='16' mobile='16' only='computer'>
                                <h4 style={{ textAlign: 'right' }}>เลือกหน่วยงาน</h4>
                            </Grid.Column>
                            <Grid.Column computer='12' tablet='16' mobile='16'>
                                <Dropdown
                                    fluid
                                    selection
                                    options={parent_directory_option}
                                    value={parent_directory}
                                    onChange={handleChangeDirectory}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {children_directory_option.length > 0 && (
                            <Grid.Row>
                                <Grid.Column computer='4' tablet='16' mobile='16' only='computer'>
                                    <h4 style={{ textAlign: 'right' }}>เลือกหน่วยงาน</h4>
                                </Grid.Column>
                                <Grid.Column computer='12' tablet='16' mobile='16'>
                                    <Dropdown
                                        fluid
                                        selection
                                        options={children_directory_option}
                                        value={children_directory}
                                        onChange={handleChangeChildrenDirectory}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column computer='4' tablet='16' mobile='16' only='computer'>
                                <h4 style={{ textAlign: 'right' }}>เลือกเจ้าหน้าที่</h4>
                            </Grid.Column>
                            <Grid.Column computer='12' tablet='16' mobile='16' only='computer'>
                                <Segment>
                                    <Input
                                        fluid
                                        placeholder='ค้นหา'
                                        icon='search'
                                        value={search_technician}
                                        onChange={handleSearchTechnician}
                                    />
                                    {object_data_technician.length > 0 ? (
                                        object_data_technician.map((data: InterfaceTechnician, index: number) => (
                                            <Segment
                                                key={index}
                                                style={
                                                    technician_id === data.id
                                                        ? { border: '2px solid #293757', cursor: 'pointer' }
                                                        : { cursor: 'pointer' }
                                                }
                                                onClick={() => handleClickTechnician(data.id)}
                                            >
                                                <Segment>
                                                    <div style={{ paddingBottom: 10 }}>
                                                        {' '}
                                                        <Icon name='user' style={{ color: '#425679' }} /> {data.name}
                                                        <br />
                                                    </div>
                                                    <div>
                                                        {' '}
                                                        <Icon name='phone' style={{ color: '#425679' }} />{' '}
                                                        {data.phone_number} <br />
                                                    </div>
                                                </Segment>
                                            </Segment>
                                        ))
                                    ) : (
                                        <Segment style={{ border: '2px solid #293757' }}>{'ไม่พบข้อมูล'}</Segment>
                                    )}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer='2' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default ChangeTechnician
