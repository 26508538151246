import { ActionInterface } from '../../../../types';
import { ManagementUsersFormPersonalState } from '../../../../types/Management/Users/Form/Personal';

const initialState: ManagementUsersFormPersonalState = {
    profile_image: undefined,
    id_card_type: '',
    ssn: '',
    passport_number: '',
    prefix_th: '',
    prefix_en: '',
    first_name_th: '',
    last_name_th: '',
    first_name_en: '',
    last_name_en: '',
    birth_date: '',
    house_no: '',
    village_no: '',
    alley: '',
    road: '',
    sub_district: '',
    district: '',
    province: '',
    zipcode: '',
    id_card_type_error: false,
    ssn_error: false,
    passport_number_error: false,
    prefix_error: false,
    first_name_th_error: false,
    last_name_th_error: false,
    first_name_en_error: false,
    last_name_en_error: false,
    birth_date_error: false,
};

const managementUsersFormPersonal = (
    state = initialState,
    action: ActionInterface
): ManagementUsersFormPersonalState => {
    switch (action.type) {
        case 'setStateByNameAndValueManagementUsersFormPersonal':
            return action.name
                ? {
                      ...state,
                      [action.name]: action.value,
                  }
                : { ...state };
        case 'appendFormManagementUsersFormPersonal':
            return {
                ...state,
                ...action.value,
            };
        case 'resetFormByReducerNameManagementUsersFormPersonal':
            return {
                ...state,
                ...{
                    profile_image: undefined,
                    id_card_type: '',
                    ssn: '',
                    passport_number: '',
                    prefix_th: '',
                    prefix_en: '',
                    first_name_th: '',
                    last_name_th: '',
                    first_name_en: '',
                    last_name_en: '',
                    birth_date: '',
                    house_no: '',
                    village_no: '',
                    alley: '',
                    road: '',
                    sub_district: '',
                    district: '',
                    province: '',
                    zipcode: '',
                    id_card_type_error: false,
                    ssn_error: false,
                    passport_number_error: false,
                    prefix_error: false,
                    first_name_th_error: false,
                    last_name_th_error: false,
                    first_name_en_error: false,
                    last_name_en_error: false,
                    birth_date_error: false,
                },
            };
        default:
            return state;
    }
};

export default managementUsersFormPersonal;
