import React, { useEffect, useState } from 'react';
import { Container, Dimmer, Loader, Segment } from 'semantic-ui-react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { axiosAuthen } from '../../../config/axiosAuthen';
import { GET_DASHBOARD_COUNT_STATUS_TECHNICIAN } from '../../../config/api/dashboard';

type DashboardProps = {
    technician_id?: string;
    year?: string | number | boolean | (string | number | boolean)[] | undefined;
};

const MakeData = [{
        count: 0,
        percent: "0",
        status_en: "Pending",
        status_th: "รอรับเรื่อง",
    },
    {
        count: 0,
        percent: "0",
        status_en: "FiscalYearPlan",
        status_th: "เข้าแผนปีงบประมาณ",
    },
    {
        count: 0,
        percent: "0",
        status_en: "Tracking",
        status_th: "อยู่ระหว่างการติดตาม",
    },
    {
        count: 0,
        percent: "0",
        status_en: "Doing",
        status_th: "กำลังดำเนินการ",
    },
    {
        count: 0,
        percent: "0",
        status_en: "Deadline",
        status_th: "ใกล้ครบกำหนด",
    },
    {
        count: 0,
        percent: "0",
        status_en: "Overdue",
        status_th: "เกินกำหนด",
    },
    {
        count: 0,
        percent: "0",
        status_en: "Unable",
        status_th: "ไม่สามารถดำเนินการได้",
    },
    {
        count: 0,
        percent: "0",
        status_en: "PendingEvaluation",
        status_th: "รอประเมินผล",
    },
    {
        count: 0,
        percent: "0",
        status_en: "Success",
        status_th: "ประเมินผลเสร็จสิ้น",
    },
]

interface InterfaceDashboardStatusComplaint {
    count: number;
    percent: string;
    status_en: string;
    status_th: string;
}
const DashboardTechnianStatusComplaint: React.FC<DashboardProps> = (props) => {
    let { year } = props;
    let { technician_id } = props;
    let [loading, setLoading] = useState<boolean>(true);
    let [chart_options, setChartOptions] = useState<any>(undefined);
    useEffect(() => {
        axiosAuthen.get(`${GET_DASHBOARD_COUNT_STATUS_TECHNICIAN}/${technician_id}?year=${year ==='all'? '': year}`).then((response) => {
            let { value } = response.data;
            let data_series: any[] = [];
            value.forEach((data: InterfaceDashboardStatusComplaint) => {
                if (data.status_th === 'รอรับเรื่อง') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#FBBD08',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'เข้าแผนปีงบประมาณ') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#A5673F',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'อยู่ระหว่างการติดตาม') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#000000',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'กำลังดำเนินการ') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#017ED9',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'ใกล้ครบกำหนด') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#F2711C',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'เกินกำหนด') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#FF0000',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'ไม่สามารถดำเนินการได้') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#808080',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'รอประเมินผล') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#B5CC18',
                        percent: data.percent,
                    });
                } else if (data.status_th === 'ประเมินผลเสร็จสิ้น') {
                    data_series.push({
                        y: data.count,
                        name: data.status_th,
                        color: '#21BA45',
                        percent: data.percent,
                    });
                }
            });

            let categories = [
                'รอรับเรื่อง',
                'เข้าแผนปีงบประมาณ',
                'อยู่ระหว่างการติดตาม',
                'กำลังดำเนินการ',
                'ใกล้ครบกำหนด',
                'เกินกำหนด',
                'ไม่สามารถดำเนินการได้',
                'รอประเมินผล',
                'ประเมินผลเสร็จสิ้น',
            ];
            let chart_options = {
                chart: {
                    type: 'column',
                },
                title: {
                    text: 'สถานะคำร้องทุกข์',
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: null,
                    },
                },
                yAxis: {
                    title: {
                        text: 'จำนวนคำร้องทุกข์',
                    },
                    labels: {
                        overflow: 'justify',
                    },
                },
                tooltip: {
                    enabled: false,
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            format: '{y} คำร้อง <b>( {point.percent:.2f}% )</b>',
                        },
                    },
                },
                legend: {
                    enabled: false,
                },
                series: [
                    {
                        name: 'สถานะคำร้องทุกข์',
                        data: data_series,
                    },
                ],
                credits: {
                    enabled: false,
                },
            };
            setChartOptions(chart_options);
            setLoading(false);
        }).catch(() => {
            let categories = [
                'รอรับเรื่อง',
                'เข้าแผนปีงบประมาณ',
                'อยู่ระหว่างการติดตาม',
                'กำลังดำเนินการ',
                'ใกล้ครบกำหนด',
                'เกินกำหนด',
                'ไม่สามารถดำเนินการได้',
                'รอประเมินผล',
                'ประเมินผลเสร็จสิ้น',
            ];

            let chart_options = {
                chart: {
                    type: 'column',
                },
                title: {
                    text: 'สถานะคำร้องทุกข์',
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: null,
                    },
                },
                yAxis: {
                    title: {
                        text: 'จำนวนคำร้องทุกข์',
                    },
                    labels: {
                        overflow: 'justify',
                    },
                },
                tooltip: {
                    enabled: false,
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            format: '{y} คำร้อง <b>( {point.percent:.2f}% )</b>',
                        },
                    },
                },
                legend: {
                    enabled: false,
                },
                series: [
                    {
                        name: 'สถานะคำร้องทุกข์',
                        data: MakeData,
                    },
                ],
                credits: {
                    enabled: false,
                },
            };
            setChartOptions(chart_options);
            setLoading(false);
        });
    }, [year, technician_id]);

    if (loading) {
        return (
            <Segment style={{ height: 430 }}>
                <div>
                    <Container style={{ height: window.innerHeight }}>
                        <Dimmer active inverted>
                            <Loader size='huge' />
                        </Dimmer>
                    </Container>
                </div>
            </Segment>
        );
    } else {
        return (
            <Segment>
                <HighchartsReact highcharts={Highcharts} options={chart_options} />
            </Segment>
        );
    }
};
export default DashboardTechnianStatusComplaint;
