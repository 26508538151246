import React, { Component, Fragment } from 'react';
import { Dropdown, Grid } from 'semantic-ui-react';

class CustomDropdownDate extends Component {
    constructor(props) {
        super(props);
        let list_date = [];
        for (let day = 1; day <= 31; day++) {
            if (day < 10) {
                list_date.push({ value: `0${day}`, text: `0${day}` });
            } else {
                list_date.push({ value: String(day), text: String(day) });
            }
        }
        let error = Boolean(props.error);
        this.state = {
            active_date: '',
            active_month: '',
            active_year: '',
            disable_date: [],
            disable_month: [],
            show_calendar: false,
            date: '',
            date_error: error,
            list_date: list_date,
            list_month: [
                { value: '01', text: 'มกราคม' },
                { value: '02', text: 'กุมภาพันธ์' },
                { value: '03', text: 'มีนาคม' },
                { value: '04', text: 'เมษายน' },
                { value: '05', text: 'พฤษภาคม' },
                { value: '06', text: 'มิถุนายน' },
                { value: '07', text: 'กรกฎาคม' },
                { value: '08', text: 'สิงหาคม' },
                { value: '09', text: 'กันยายน' },
                { value: '10', text: 'ตุลาคม' },
                { value: '11', text: 'พฤศจิกายน' },
                { value: '12', text: 'ธันวาคม' },
            ],
            list_year: [],
        };
    }

    componentDidMount = () => {
        this.getListYear();
    };

    componentWillReceiveProps = (props) => {
        let { value } = props;
        if (value) {
            let date = value.split('/');
            let active_date = date[0];
            let active_month = date[1];
            let active_year = date[2];
            this.setState({ active_date: active_date, active_month: active_month, active_year: active_year });
        }
    };
    getListYear = () => {
        let current_year = new Date().getFullYear() + 543;
        let list_year = [];
        for (let year = current_year; year > current_year - 120; year--) {
            list_year.push({ value: String(year), text: String(year) });
        }
        this.setState({ list_year: list_year });
    };

    handleChangeDate = (e, { name, value }) => this.setState({ [name]: value }, () => this.checkRealDate(e));

    checkRealDate = (event) => {
        let { active_date, active_month, active_year } = this.state;
        if (active_month) {
            if (['04', '06', '09', '11'].includes(active_month) && active_date > 30) {
                this.setState({ active_date: '' });
            }
            if (['02'].includes(active_month) && active_date > 28) {
                if (active_year) {
                    if (new Date(active_year - 543, 1, 29).getDate() !== 29) {
                        this.setState({ active_date: '' });
                    }
                } else {
                    this.setState({ active_date: '' });
                }
            }
        }
        if (active_date && active_month && active_year) {
            let day = active_date;
            let month = active_month;
            let year = active_year;
            let value = [day, month, year].join('/');
            let error = false;
            let name = this.props.name;
            this.props.onChange && this.props.onChange(event, { name, value, error });
        }
    };

    render() {
        let { list_date, list_month, list_year, active_date, active_month, active_year } = this.state;
        let { error } = this.props;
        return (
            <Fragment>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer='5' tablet='16' mobile='16'>
                            <Dropdown
                                error={error}
                                search
                                selection
                                fluid
                                placeholder='วัน'
                                options={list_date}
                                name='active_date'
                                value={active_date}
                                onChange={this.handleChangeDate}
                            />
                        </Grid.Column>
                        <Grid.Column computer='5' tablet='16' mobile='16'>
                            <Dropdown
                                error={error}
                                search
                                selection
                                fluid
                                placeholder='เดือน'
                                options={list_month}
                                name='active_month'
                                value={active_month}
                                onChange={this.handleChangeDate}
                            />
                        </Grid.Column>
                        <Grid.Column computer='5' tablet='16' mobile='16'>
                            <Dropdown
                                error={error}
                                search
                                selection
                                fluid
                                placeholder='ปี'
                                options={list_year}
                                name='active_year'
                                value={active_year}
                                onChange={this.handleChangeDate}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Fragment>
        );
    }
}

export default CustomDropdownDate;
