import React, { Component } from 'react'
import { Dropdown, Icon, Image, Menu, Segment, Sidebar } from 'semantic-ui-react'
import { HOST } from '../../config/router'
import MenuList from '../MenuList'
import MenuListSuperAdmin from '../MenuList/SuperAdmin'
import MenuListAdmin from '../MenuList/Admin'
import { axiosAuthen } from '../../config/axiosAuthen'
import { GET_MENU_BY_TOKEN } from '../../config/api/manageMenu'
import { connect } from 'react-redux'
import { appendForm } from '../../store/actions'
import userImage from '../../assets/images/icon-user.png'
import Notification from '../Notification'
import { LOGOUT } from '../../config/api/common'

class CustomSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showSideBar: false,
        }
    }

    UNSAFE_componentWillReceiveProps = (props) => {
        if (props.location.pathname === '/super_admin/municipalities') {
            localStorage.removeItem('municipalities_name')
        }

        if (props.location !== this.props.location) {
            this.getMenuByToken()
        }
    }

    componentDidMount = () => {
        if (this.props.match.params.permission !== localStorage.permission_en) {
            this.handleLogout()
        } else {
            this.getMenuByToken()
        }
    }

    getMenuByToken = async () => {
        await axiosAuthen.get(GET_MENU_BY_TOKEN).then((response) => {
            let { value } = response.data
            this.props.dispatch(appendForm('AuthenMenuList', { menu_list: value }))
        })
    }

    handleSideBar = () => this.setState({ showSideBar: !this.state.showSideBar })

    handleLogout = () => {
        axiosAuthen
            .delete(LOGOUT)
            .then(() => {
                let username = localStorage.getItem('username')
                document.title = 'บริหารจัดการคำร้อง'
                localStorage.clear()
                let permission_param = this.props.match.params.permission

                if (permission_param !== 'super_admin') {
                    localStorage.setItem('previous_permission', permission_param)
                    localStorage.setItem('previous_url', this.props.location.pathname)
                    localStorage.setItem('username', username)
                } else {
                    document.cookie = `password=`
                }

                this.props.history.push('/')
            })
            .catch((error) => {
                console.log(error.message)
            })
    }

    render() {
        let { showSideBar } = this.state
        let permission = localStorage.permission_en
        return (
            <Sidebar.Pushable as={Segment.Group} raised>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    inverted
                    vertical
                    onHide={() => this.setState({ showSideBar: false })}
                    visible={showSideBar}
                >
                    {permission === 'super_admin' ? (
                        <MenuListSuperAdmin />
                    ) : permission === 'admin' ? (
                        <MenuListAdmin />
                    ) : (
                        <MenuList />
                    )}
                </Sidebar>

                <Sidebar.Pusher
                    id='pusher'
                    style={{ overflowY: 'scroll', height: 'calc(100vh)' }}
                    visible={showSideBar.toString()}
                >
                    <Menu style={{ margin: 0 }}>
                        <div style={{ display: 'flex', width: '100vw', paddingBottom: 16 }}>
                            <div style={{ cursor: 'pointer' }}>
                                <Icon
                                    name='align justify'
                                    size='big'
                                    onClick={() => this.handleSideBar()}
                                    style={{
                                        marginLeft: 10,
                                        color: 'gray',
                                        justifyContents: 'center',
                                        alignSeft: 'center',
                                        marginTop: 15,
                                        cursor: 'pointer',
                                    }}
                                />
                            </div>
                            <div style={{ flexGrow: 1, alignItems: 'flex-end', display: 'flex', marginLeft: 20 }}>
                                <h3>{localStorage.getItem('municipalities_name')}</h3>
                            </div>

                            {!(permission === 'super_admin' || permission === 'admin') && <Notification />}
                            <Dropdown
                                icon={
                                    <Image
                                        avatar
                                        src={
                                            localStorage.getItem('profile_image')
                                                ? `${HOST}${localStorage.getItem('profile_image')}`
                                                : userImage
                                        }
                                        style={{
                                            marginLeft: 20,
                                            color: 'gray',
                                            alignSeft: 'center',
                                            marginTop: 15,
                                            marginRight: 30,
                                        }}
                                    />
                                }
                                pointing='top right'
                            >
                                <Dropdown.Menu style={{ marginRight: 25 }}>
                                    <Dropdown.Header>
                                        <Icon name='user' />
                                        <label style={{ marginLeft: 10 }}>{localStorage.getItem('name')}</label>
                                    </Dropdown.Header>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={() => this.props.history.push(`/${permission}/user-detail`)}
                                    >
                                        <Icon name='setting' />
                                        <label>ข้อมูลส่วนตัว</label>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={this.handleLogout}>
                                        <Icon name='shutdown' />
                                        <label style={{ color: 'black' }}>ออกจากระบบ</label>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Menu>

                    <div>{this.props.children}</div>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        )
    }
}

export default connect()(CustomSidebar)
