import React, { useEffect, useState } from 'react';
import { Container, Dimmer, Loader, Segment } from 'semantic-ui-react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { axiosAuthen } from '../../../config/axiosAuthen';
import { GET_DASHBOARD_GRADE_RESULT_TYPE_TECHNICIAN } from '../../../config/api/dashboard';

const MakeData = [
  {
    y: 0,
    name: "ดีมาก",
    color: "#70B982",
  },
  {
    y: 0,
    name: "ดี",
    color: "#B5CC18",
  },
  {
    y: 0,
    name: "ปานกลาง",
    color: "#FBBD08",
  },
  {
    y: 0,
    name: "พอใช้",
    color: "#F2711C",
  },
  {
    y: 0,
    name: "ปรับปรุง",
    color: "#B6392D",
  },
];

type DashboardProps = {
    technician_id?: string;
    year?: string | number | boolean | (string | number | boolean)[] | undefined;
};

interface InterfaceDashboardGraphSummary {
    count_complanits: number
    grade_name: string
    percent: string
}

const DashboardTechnianGraphSummary: React.FC<DashboardProps> = (props) => {
    let { year } = props;
    let { technician_id } = props;
    let [loading, setLoading] = useState<boolean>(true);
    let [chart_options, setChartOptions] = useState<any>(undefined);
    useEffect(() => {
        axiosAuthen.get(`${GET_DASHBOARD_GRADE_RESULT_TYPE_TECHNICIAN}/${technician_id}?year=${year ==='all'? '': year}`).then((response) => {
            let { value } = response.data;
            let data_series: any[] = [];
            value.forEach((data: InterfaceDashboardGraphSummary) => {
                if (data.grade_name === 'ดีมาก') {
                    data_series.push({
                        y: data.count_complanits,
                        name: data.grade_name,
                        color: '#70B982',
                        percent: data.percent,

                    });
                } else if (data.grade_name === 'ดี') {
                    data_series.push({
                        y: data.count_complanits,
                        name: data.grade_name,
                        color: '#B5CC18',
                        percent: data.percent,
                    });
                } else if (data.grade_name === 'ปานกลาง') {
                    data_series.push({
                        y: data.count_complanits,
                        name: data.grade_name,
                        color: '#FBBD08',
                        percent: data.percent,

                    });
                } else if (data.grade_name === 'พอใช้') {
                    data_series.push({
                        y: data.count_complanits,
                        name: data.grade_name,
                        color: '#F2711C',
                        percent: data.percent,

                    });
                } else if (data.grade_name === 'ปรับปรุง') {
                    data_series.push({
                        y: data.count_complanits,
                        name: data.grade_name,
                        color: '#B6392D',
                        percent: data.percent,

                    });
                }
            });
            let categories = [
                'ดีมาก',
                'ดี',
                'ปานกลาง',
                'พอใช้',
                'ปรับปรุง',

            ];

            let chart_options = {
                chart: {
                    type: 'pie',
                },
                title: {
                    text: 'ผลการประเมิน',
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: null,
                    },
                },
                tooltip: {
                    enabled: false,
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: true,
                            format: '<b> {point.name:.2f} </b> : {y} คำร้อง <b>( {point.percent:.2f}% )</b>',
                        },
                    },
                },
                legend: {
                    enabled: false,
                },
                series: [
                    {
                        name: 'สถานะคำร้องทุกข์',
                        data: data_series,
                    },
                ],
                credits: {
                    enabled: false,
                },
            };
            setChartOptions(chart_options);
            setLoading(false);

        }).catch(() => {
        let categories = ["ดีมาก", "ดี", "ปานกลาง", "พอใช้", "ปรับปรุง"];
        let chart_options = {
          chart: {
            type: "pie",
          },
          title: {
            text: "ผลการประเมิน",
          },
          xAxis: {
            categories: categories,
            title: {
              text: null,
            },
          },
          tooltip: {
            pointFormat:
              "{series.name}: <b> : {point.y} คำร้อง ( {point.percentage:.1f}% )</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: false,
              },
              showInLegend: true,
            },
          },
          series: [
            {
              name: "สถานะคำร้องทุกข์",
              data: MakeData,
            },
          ],
          credits: {
            enabled: false,
          },
        };
        setChartOptions(chart_options);
        setLoading(false);
      });
    }, [year, technician_id]);

    if (loading) {
        return (
            <Segment style={{ height: 430 }}>
                <div>
                    <Container style={{ height: window.innerHeight }}>
                        <Dimmer active inverted>
                            <Loader size='huge' />
                        </Dimmer>
                    </Container>
                </div>
            </Segment>
        );
    } else {
        return (

            <HighchartsReact highcharts={Highcharts} options={chart_options} />

        );
    }
};
export default DashboardTechnianGraphSummary;
