import React, { Component, Fragment } from 'react'
import { Segment, Icon, Grid } from 'semantic-ui-react'
import './styles.css'

class CustomSegment extends Component {
    render() {
        let { icon, header, loading } = this.props
        return (
            <Fragment>
                <Segment className='custom-segment' loading={loading}>
                    <div className='header-segment'>
                        <Icon name={icon} style={{ margin: 5 }} />
                        <label>{header}</label>
                    </div>
                    <div style={{ margin: 15 }}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column columns='16'>
                                    {Array.isArray(this.props.children)
                                        ? this.props.children.map((child, index) => (
                                              <Fragment key={index}>{child}</Fragment>
                                          ))
                                        : this.props.children}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Segment>
            </Fragment>
        )
    }
}

export default CustomSegment
