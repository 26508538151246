import { ActionInterface } from '../../../types';
import { ComplaintFormStep2State } from '../../../types/Complaint/Form/Step2';

const initialState: ComplaintFormStep2State = {
    ssn: '',
    prefix: '',
    other_prefix: '',
    first_name: '',
    last_name: '',
    house_no: '',
    village_no: '',
    alley: '',
    road: '',
    sub_district: '',
    district: '',
    province: '',
    zipcode: '',
    phone_number: '',
    mobile_number: '',
    email: '',
    prefix_error: false,
    first_name_error: false,
    last_name_error: false,
    house_no_error: false,
    village_no_error: false,
    sub_district_error: false,
    district_error: false,
    province_error: false,
    zipcode_error: false,
    mobile_number_error: false,
};

const complaintFormStep2 = (state = initialState, action: ActionInterface): ComplaintFormStep2State => {
    switch (action.type) {
        case 'setStateByNameAndValueComplaintFormStep2':
            return action.name
                ? {
                      ...state,
                      [action.name]: action.value,
                  }
                : { ...state };
        case 'appendFormComplaintFormStep2':
            return {
                ...state,
                ...action.value,
            };
        case 'resetFormByReducerNameComplaintFormStep2':
            return {
                ...state,
                ...{
                    ssn: '',
                    prefix: '',
                    other_prefix: '',
                    first_name: '',
                    last_name: '',
                    house_no: '',
                    village_no: '',
                    alley: '',
                    road: '',
                    sub_district: '',
                    district: '',
                    province: '',
                    zipcode: '',
                    phone_number: '',
                    mobile_number: '',
                    email: '',
                    prefix_error: false,
                    first_name_error: false,
                    last_name_error: false,
                    house_no_error: false,
                    village_no_error: false,
                    sub_district_error: false,
                    district_error: false,
                    province_error: false,
                    zipcode_error: false,
                    mobile_number_error: false,
                },
            };
        default:
            return state;
    }
};

export default complaintFormStep2;
