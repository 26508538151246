import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Breadcrumb, Button, Checkbox, Container, Grid, Icon, Segment, Table } from 'semantic-ui-react'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import {
    GET_NOTIFICATION_MUNICIPALITY,
    GET_NOTIFICATION_TOPICS,
    PUT_NOTIFICATION_CREATE_AND_EDIT_NOTIFICATION_MUNICIPALITY_AND_ROLES,
} from '../../../../../config/api/notification'
import { GET_ROLE_BY_MUNICIPALITY } from '../../../../../config/api/roles'

interface InterfaceNotification {
    id: number
    name: string
}

interface InterfaceRole {
    id: number
    name: string
}

interface InterfaceCheckedNotification {
    notification_topics_id: number
    role_id: number[]
}

const ManagementNotificationAccordingFlowProcess: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [edit_mode, setEditMode] = useState<boolean>(false)
    let [list_notification, setListNotification] = useState<InterfaceNotification[]>([])
    let [list_role, setLisRole] = useState<InterfaceRole[]>([])
    let [list_checked_notification, setListCheckedNotification] = useState<InterfaceCheckedNotification[]>([])
    let [municipality_id, setMunicipalityID] = useState<number>(0)
    let [loading, setLoading] = useState<boolean>(false)

    const getNotificationMunicipality = useCallback(() => {
        municipality_id &&
            axiosAuthen.get(`${GET_NOTIFICATION_MUNICIPALITY}?municipality_id=${municipality_id}`).then((response) => {
                let { value } = response.data
                setListCheckedNotification(value || [])
                setLoading(false)
            })
    }, [municipality_id])

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            setMunicipalityID(municipality_id)
        }
    }, [location])

    useEffect(() => {
        const getRoles = () => {
            axiosAuthen.get(`${GET_ROLE_BY_MUNICIPALITY}/${municipality_id}`).then((response) => {
                let { value } = response.data
                let list_role = value.filter((role: InterfaceRole) => role.name !== 'แอดมิน')
                setLisRole([...list_role.slice(1), list_role[0]])
            })
        }
        const getNotificationTopics = () => {
            axiosAuthen.get(GET_NOTIFICATION_TOPICS).then((response) => {
                let { value } = response.data
                setListNotification(value || [])
            })
        }

        if (municipality_id) {
            setLoading(true)
            getRoles()
            getNotificationTopics()
            getNotificationMunicipality()
        }
    }, [getNotificationMunicipality, municipality_id])

    const handleEditMode = () => {
        setEditMode(true)
    }

    const handleChangeCheckbox = (notification_topics_id: number, role_id: number) => {
        if (
            list_checked_notification.find(
                (checked_notification) => checked_notification.notification_topics_id === notification_topics_id
            )
        ) {
            let checked_notification = list_checked_notification.find(
                (checked_notification) => checked_notification.notification_topics_id === notification_topics_id
            )
            if (checked_notification) {
                if (checked_notification?.role_id.find((role) => role === role_id)) {
                    checked_notification.role_id = checked_notification.role_id.filter((role) => role !== role_id)
                } else {
                    checked_notification.role_id.push(role_id)
                }
                setListCheckedNotification([
                    ...list_checked_notification.filter((notification) =>
                        notification.notification_topics_id === notification_topics_id
                            ? checked_notification
                            : notification
                    ),
                ])
            }
        } else {
            setListCheckedNotification([
                ...list_checked_notification,
                { notification_topics_id: notification_topics_id, role_id: [role_id] },
            ])
        }
    }

    const handleSubmit = () => {
        axiosAuthen.put(PUT_NOTIFICATION_CREATE_AND_EDIT_NOTIFICATION_MUNICIPALITY_AND_ROLES, {
            municipality_id: municipality_id,
            notification_topics: list_checked_notification,
        })
        setEditMode(false)
    }

    return (
        <div className='background-container'>
            <Container>
                <Grid>
                    <Grid.Row only='computer tablet'>
                        <Grid.Column style={{ zIndex: 1 }}>
                            <Breadcrumb>
                                <Breadcrumb.Section
                                    link
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/municipalities`, {
                                            municipality_id: municipality_id,
                                        })
                                    }}
                                >
                                    รายการองค์กร
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section
                                    link
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                            municipality_id: municipality_id,
                                        })
                                    }}
                                >
                                    กำหนดการใช้งานองค์กร
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section
                                    link
                                    onClick={() => {
                                        history.push(`/${localStorage.permission_en}/management/notification`, {
                                            municipality_id: municipality_id,
                                        })
                                    }}
                                >
                                    จัดการการแจ้งเตือน
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />

                                <Breadcrumb.Section active>จัดการการแจ้งเตือนตามกระบวนการทำงาน</Breadcrumb.Section>
                            </Breadcrumb>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <h2>จัดการการแจ้งเตือนตามกระบวนการทำงาน</h2>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Segment loading={loading}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h3>
                                                รายการการแจ้งเตือนตามกระบวนการทำงาน
                                                {!edit_mode && (
                                                    <Button
                                                        icon
                                                        color='orange'
                                                        floated='right'
                                                        onClick={handleEditMode}
                                                        size='tiny'
                                                    >
                                                        <Icon name='edit outline' />
                                                    </Button>
                                                )}
                                            </h3>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Table celled structured>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell rowSpan='2' textAlign='center'>
                                                            หัวข้อการแจ้งเตือน
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell
                                                            colSpan={list_notification.length}
                                                            textAlign='center'
                                                        >
                                                            แจ้งเตือน
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        {/* <Table.HeaderCell textAlign='center'>ทั้งหมด</Table.HeaderCell> */}
                                                        {list_role.map((role) => (
                                                            <Table.HeaderCell key={role.id} textAlign='center'>
                                                                {role.name}
                                                            </Table.HeaderCell>
                                                        ))}
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {list_notification.map((notification) => (
                                                        <Table.Row key={notification.id}>
                                                            <Table.Cell>{notification.name}</Table.Cell>
                                                            {/* <Table.Cell textAlign='center'>
                                                                <Checkbox
                                                                    readOnly={!edit_mode}
                                                                    checked={
                                                                        list_checked_notification.some(
                                                                            (checked_notification) =>
                                                                                checked_notification.notification_topics_id ===
                                                                                notification.id
                                                                        ) &&
                                                                        list_checked_notification.find(
                                                                            (checked_notification) =>
                                                                                checked_notification.notification_topics_id ===
                                                                                notification.id
                                                                        )?.role_id.length === list_role.length
                                                                    }
                                                                    onChange={() =>
                                                                        handleChangeCheckboxTotal(notification.id)
                                                                    }
                                                                />
                                                            </Table.Cell> */}
                                                            {list_role.map((role) => (
                                                                <Table.Cell key={role.id} textAlign='center'>
                                                                    <Checkbox
                                                                        readOnly={!edit_mode}
                                                                        checked={
                                                                            list_checked_notification.some(
                                                                                (checked_notification) =>
                                                                                    checked_notification.notification_topics_id ===
                                                                                    notification.id
                                                                            ) &&
                                                                            list_checked_notification
                                                                                .find(
                                                                                    (checked_notification) =>
                                                                                        checked_notification.notification_topics_id ===
                                                                                        notification.id
                                                                                )
                                                                                ?.role_id.some(
                                                                                    (roles) => roles === role.id
                                                                                )
                                                                        }
                                                                        onChange={() =>
                                                                            handleChangeCheckbox(
                                                                                notification.id,
                                                                                role.id
                                                                            )
                                                                        }
                                                                    />
                                                                </Table.Cell>
                                                            ))}
                                                        </Table.Row>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {edit_mode && (
                                        <Grid.Row>
                                            <Grid.Column textAlign='center'>
                                                <Button primary onClick={handleSubmit}>
                                                    บันทึก
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    )
}

export default ManagementNotificationAccordingFlowProcess
