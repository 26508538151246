import { HOST } from '../router';

const MUNICIPALITY = `${HOST}/municipalities`;
export const GET_MUNICIPALITY_LIST = `${MUNICIPALITY}/list`;
export const GET_MUNICIPALITY_DETAIL = `${MUNICIPALITY}/detail`;
export const GET_MUNICIPALITY_ALL = `${MUNICIPALITY}/all`;
export const POST_MUNICIPALITY_CREATE_AND_EDIT = `${MUNICIPALITY}/create_and_edit`;
export const POST_MUNICIPALITY_UPLOAD_LOGO = `${MUNICIPALITY}/upload_logo`;
export const DELETE_MUNICIPALITY = `${MUNICIPALITY}/delete`;
export const POST_CREATE_AND_EDIT_MUNICIPALITY_HAS_MENU = `${MUNICIPALITY}/create_and_edit_municipalities_has_permission_menus`;
export const GET_MUNICIPALITY_HAS_MENU_ALL = `${MUNICIPALITY}/municipalities_has_permission_menus_all`;
export const GET_MUNICIPALITY_LOGO = `${MUNICIPALITY}/logo`;
export const GET_MUNICIPALITY_UPLOAD_QR_LINE = `${MUNICIPALITY}/upload_qr_line`;
export const GET_MUNICIPALITY_PERSON_TYPES = `${MUNICIPALITY}/person_types`;
export const GET_MUNICIPALITY_PERSON_TYPES_BY_MUNICIPALITY_ID = `${MUNICIPALITY}/person_types_by_municipality_id`;
