import React, { Fragment, useEffect, useState } from 'react';
import {
    Button,
    Container,
    Dropdown,
    DropdownOnSearchChangeData,
    DropdownProps,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Segment,
} from 'semantic-ui-react';
import ThaiAddress from '../../../../constants/ThaiAddress.json';
import ImagePattern from '../../../../assets/images/pattern.png';
import IconMan from '../../../../assets/images/man.png';
import IconWoman from '../../../../assets/images/woman.png';
import IconWoman2 from '../../../../assets/images/woman1.png';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    GET_NOTIFICATION_ONE_CHAT_PROFILE,
    PUT_NOTIFICATION_ONE_CHAT_EDIT_PROFILE,
} from '../../../../config/api/notification';
import { alert } from '../../../../components/alert';

const OneChatEditProfile: React.FC = () => {
    let [loading, setLoading] = useState<boolean>(false);
    let [one_id, setOneId] = useState<string | null>('');
    let [prefix, setPrefix] = useState<string>('');
    let [first_name, setFirstName] = useState<string>('');
    let [last_name, setLastName] = useState<string>('');
    let [mobile_number, setMobileNumber] = useState<string>('');
    let [house_no, setHouseNo] = useState<string>('');
    let [village_no, setVillageNo] = useState<string>('');
    let [alley, setAlley] = useState<string>('');
    let [road, setRoad] = useState<string>('');
    let [sub_district, setSubDistrict] = useState<string>('');
    let [district, setDistrict] = useState<string>('');
    let [province, setProvince] = useState<string>('');
    let [zipcode, setZipCode] = useState<string>('');
    let [option_sub_district, setOptionSubDistrict] = useState<{ value: string; text: string }[]>();
    let [option_district, setOptionDistrict] = useState<{ value: string; text: string }[]>();
    let [option_province, setOptionProvince] = useState<{ value: string; text: string }[]>();
    let [option_zipcode, setOptionZipCode] = useState<{ value: string; text: string }[]>();
    let [prefix_error, setPrefixError] = useState<boolean>(false);
    let [first_name_error, setFirstNameError] = useState<boolean>(false);
    let [last_name_error, setLastNameError] = useState<boolean>(false);
    let [mobile_number_error, setMobileNumberError] = useState<boolean>(false);
    let [house_no_error, setHouseNoError] = useState<boolean>(false);
    let [village_no_error, setVillageNoError] = useState<boolean>(false);
    let [sub_district_error, setSubDistrictError] = useState<boolean>(false);
    let [district_error, setDistrictError] = useState<boolean>(false);
    let [province_error, setProvinceError] = useState<boolean>(false);
    let [zipcode_error, setZipCodeError] = useState<boolean>(false);
    let thai_address = ThaiAddress;
    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        const getProfile = (one_id: string) => {
            setLoading(true);
            axios.get(`${GET_NOTIFICATION_ONE_CHAT_PROFILE}/${one_id}`).then((response) => {
                let { value } = response.data;
                setPrefix(value.prefix_th);
                setFirstName(value.first_name_th);
                setLastName(value.last_name_th);
                setMobileNumber(value.phone_number);
                setHouseNo(value.house_no);
                setVillageNo(value.village_no);
                setAlley(value.alley);
                setRoad(value.road);
                setSubDistrict(value.sub_district);
                setDistrict(value.district);
                setProvince(value.province);
                setZipCode(value.zipcode);
                setOptionSubDistrict([{ value: value.sub_district, text: value.sub_district }]);
                setOptionDistrict([{ value: value.district, text: value.district }]);
                setOptionProvince([{ value: value.province, text: value.province }]);
                setOptionZipCode([{ value: value.zipcode, text: value.zipcode }]);
                setLoading(false);
            });
        };

        const query = new URLSearchParams(location.search);
        const one_id = query.get('one_id');
        setOneId(one_id);
        // const municipality_id = query.get('municipality_id');
        // setMunicipalityId(municipality_id);
        if (one_id) getProfile(one_id);
    }, [location]);

    const handleChangePrefix = (prefix: string) => {
        setPrefix(prefix);
        setPrefixError(false);
    };

    const handleChangeFirstName = (_: any, { value }: InputOnChangeData) => {
        setFirstName(value);
        setFirstNameError(false);
    };
    const handleChangeLastname = (_: any, { value }: InputOnChangeData) => {
        setLastName(value);
        setLastNameError(false);
    };
    const handleChangeMobileNumber = (_: any, { value }: InputOnChangeData) => {
        setMobileNumber(value);
        setMobileNumberError(false);
    };
    const handleChangeHouseNo = (_: any, { value }: InputOnChangeData) => {
        setHouseNo(value);
        setHouseNoError(false);
    };
    const handleChangeVillageNo = (_: any, { value }: InputOnChangeData) => {
        setVillageNo(value);
        setVillageNoError(false);
    };
    const handleChangeAlley = (_: any, { value }: InputOnChangeData) => {
        setAlley(value);
    };
    const handleChangeRoad = (_: any, { value }: InputOnChangeData) => {
        setRoad(value);
    };

    const handleChangeAddress = (_: any, { name, value }: DropdownProps) => {
        let address = String(value).split(' >> ');
        setSubDistrict(address[0]);
        setDistrict(address[1]);
        setProvince(address[2]);
        setZipCode(address[3]);
        setOptionSubDistrict([{ value: address[0], text: address[0] }]);
        setOptionDistrict([{ value: address[1], text: address[1] }]);
        setOptionProvince([{ value: address[2], text: address[2] }]);
        setOptionZipCode([{ value: address[3], text: address[3] }]);
        setSubDistrictError(false);
        setDistrictError(false);
        setProvinceError(false);
        setZipCodeError(false);
    };

    const searchSubDistrict = (_: any, { searchQuery }: DropdownOnSearchChangeData) => {
        if (searchQuery) {
            let option: { value: string; text: string }[] = [];
            let row = 0;
            thai_address.forEach((value: any) => {
                if (value.sub_district.includes(searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode;

                        option.push({
                            value: text_address,
                            text: text_address,
                        });

                        row++;
                    }
                }
            });
            setOptionSubDistrict(option);
        }
    };

    const searchDistrict = (_: any, { searchQuery }: DropdownOnSearchChangeData) => {
        if (searchQuery) {
            let option: { value: string; text: string }[] = [];
            let row = 0;
            thai_address.forEach((value: any) => {
                if (value.district.includes(searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode;

                        option.push({
                            text: text_address,
                            value: text_address,
                        });

                        row++;
                    }
                }
            });
            setOptionDistrict(option);
        }
    };

    const searchProvince = (_: any, { searchQuery }: DropdownOnSearchChangeData) => {
        if (searchQuery) {
            let option: { value: string; text: string }[] = [];
            let row = 0;
            thai_address.forEach((value: any) => {
                if (value.province.includes(searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode;

                        option.push({
                            text: text_address,
                            value: text_address,
                        });

                        row++;
                    }
                }
            });
            setOptionProvince(option);
        }
    };

    const searchZipcode = (_: any, { searchQuery }: DropdownOnSearchChangeData) => {
        if (searchQuery) {
            let option: { value: string; text: string }[] = [];
            let row = 0;
            thai_address.forEach((value: any) => {
                if (String(value.zipcode).includes(searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode;

                        option.push({
                            text: text_address,
                            value: text_address,
                        });
                        row++;
                    }
                }
            });
            setOptionZipCode(option);
        }
    };

    const validation = () => {
        let error = false;
        if (!prefix) {
            setPrefixError(true);
            error = true;
        }
        if (!first_name) {
            setFirstNameError(true);
            error = true;
        }
        if (!last_name) {
            setLastNameError(true);
            error = true;
        }
        if (!mobile_number || mobile_number.length !== 10 || isNaN(Number(mobile_number))) {
            setMobileNumberError(true);
            error = true;
        }
        if (!house_no) {
            setHouseNoError(true);
            error = true;
        }
        if (!sub_district) {
            setSubDistrictError(true);
            error = true;
        }
        if (!district) {
            setDistrictError(true);
            error = true;
        }
        if (!province) {
            setProvinceError(true);
            error = true;
        }
        if (!zipcode) {
            setZipCodeError(true);
            error = true;
        }

        return error;
    };
    const handleSubmit = () => {
        if (!validation()) {
            let body = {
                one_id: one_id,
                prefix: prefix,
                first_name: first_name,
                last_name: last_name,
                mobile_number: mobile_number,
                house_no: house_no,
                village_no: village_no,
                alley: alley,
                road: road,
                subdistrict: sub_district,
                district: district,
                province: province,
                zipcode: zipcode,
            };
            axios.put(PUT_NOTIFICATION_ONE_CHAT_EDIT_PROFILE, body).then(() => {
                alert({
                    type: 'success',
                    title: 'บันทึกเสร็จสิ้น',
                    timer: 1500,
                    onClosed: () => {
                        history.goBack();
                    },
                });
            });
        }
    };
    return (
        <Fragment>
            <div
                style={{
                    backgroundRepeat: 'repeat',
                    backgroundImage: `url(${ImagePattern})`,
                    position: 'absolute',
                    height: 240,
                    zIndex: 1,
                    width: '100%',
                }}
            />
            <div
                style={{
                    backgroundImage: 'linear-gradient(#3D97A7, #1D3853)',
                    position: 'absolute',
                    height: 250,
                    width: '100%',
                }}
            />
            <Container>
                <Form style={{ zIndex: 2 }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{
                                    color: 'white',
                                    paddingTop: 14,
                                }}>
                                <h2>จัดการข้อมูลผู้ใช้งาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column tablet='16' mobile='16'>
                                <Segment loading={loading}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <h4>
                                                    <Icon name='user' style={{ color: '#54ABB5' }} /> ข้อมูลผู้ใช้งาน
                                                </h4>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <h5>คำนำหน้า</h5>
                                                <Grid>
                                                    <Grid.Row columns='equal'>
                                                        <Grid.Column>
                                                            <Segment
                                                                style={
                                                                    prefix === 'นาย'
                                                                        ? {
                                                                              border: '4px solid #3D97A7',
                                                                              borderRadius: 10,
                                                                          }
                                                                        : {}
                                                                }
                                                                onClick={() => {
                                                                    handleChangePrefix('นาย');
                                                                }}>
                                                                <Image fluid src={IconMan} />
                                                                <div style={{ marginTop: 5, textAlign: 'center' }}>
                                                                    นาย
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Segment
                                                                style={
                                                                    prefix === 'นาง'
                                                                        ? {
                                                                              border: '4px solid #3D97A7',
                                                                              borderRadius: 10,
                                                                          }
                                                                        : {}
                                                                }
                                                                onClick={() => {
                                                                    handleChangePrefix('นาง');
                                                                }}>
                                                                <Image fluid src={IconWoman2} />
                                                                <div style={{ marginTop: 5, textAlign: 'center' }}>
                                                                    นาง
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Segment
                                                                style={
                                                                    prefix === 'นางสาว'
                                                                        ? {
                                                                              border: '4px solid #3D97A7',
                                                                              borderRadius: 10,
                                                                          }
                                                                        : {}
                                                                }
                                                                onClick={() => {
                                                                    handleChangePrefix('นางสาว');
                                                                }}>
                                                                <Image fluid src={IconWoman} />
                                                                <div style={{ marginTop: 5, textAlign: 'center' }}>
                                                                    นางสาว
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    {prefix_error && (
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <span style={{ color: '#db2828' }}>* โปรดเลือก</span>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={first_name_error}>
                                                    <h5>
                                                        ชื่อ{' '}
                                                        <span style={{ color: '#db2828', fontSize: '20px' }}>*</span>
                                                    </h5>
                                                    <Input
                                                        fluid
                                                        name='first_name'
                                                        value={first_name}
                                                        onChange={handleChangeFirstName}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={last_name_error}>
                                                    <h5>
                                                        นามสกุล{' '}
                                                        <span style={{ color: '#db2828', fontSize: '20px' }}>*</span>
                                                    </h5>
                                                    <Input
                                                        fluid
                                                        name='last_name'
                                                        value={last_name}
                                                        onChange={handleChangeLastname}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={mobile_number_error}>
                                                    <h5>
                                                        โทรศัพท์ (มือถือ){' '}
                                                        <span style={{ color: '#db2828', fontSize: '20px' }}>*</span>
                                                    </h5>
                                                    <Input
                                                        fluid
                                                        name='mobile_number'
                                                        value={mobile_number}
                                                        onChange={handleChangeMobileNumber}
                                                        type='tel'
                                                        maxLength={10}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column tablet='16' mobile='16'>
                                <Segment loading={loading}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <h4>
                                                    <Icon name='home' style={{ color: '#54ABB5' }} /> ที่อยู่
                                                </h4>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={house_no_error}>
                                                    <h5>
                                                        บ้านเลขที่{' '}
                                                        <span style={{ color: '#db2828', fontSize: '20px' }}>*</span>
                                                    </h5>
                                                    <Input
                                                        fluid
                                                        name='house_no'
                                                        value={house_no}
                                                        onChange={handleChangeHouseNo}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={village_no_error}>
                                                    <h5>
                                                        หมู่ที่{' '}
                                                        {/* <span style={{ color: '#db2828', fontSize: '20px' }}>*</span> */}
                                                    </h5>
                                                    <Input
                                                        fluid
                                                        name='village_no'
                                                        value={village_no}
                                                        onChange={handleChangeVillageNo}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <h5>ซอย</h5>
                                                <Input fluid name='alley' value={alley} onChange={handleChangeAlley} />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <h5>ถนน</h5>
                                                <Input fluid name='road' value={road} onChange={handleChangeRoad} />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={sub_district_error}>
                                                    <h5>
                                                        ตำบล{' '}
                                                        <span style={{ color: '#db2828', fontSize: '20px' }}>*</span>
                                                    </h5>
                                                    <Dropdown
                                                        selection
                                                        search
                                                        fluid
                                                        id='sub_district'
                                                        name='sub_district'
                                                        onChange={handleChangeAddress}
                                                        options={option_sub_district}
                                                        value={sub_district}
                                                        onSearchChange={searchSubDistrict}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={district_error}>
                                                    <h5>
                                                        อำเภอ{' '}
                                                        <span style={{ color: '#db2828', fontSize: '20px' }}>*</span>
                                                    </h5>
                                                    <Dropdown
                                                        selection
                                                        search
                                                        fluid
                                                        id='district'
                                                        name='district'
                                                        onChange={handleChangeAddress}
                                                        options={option_district}
                                                        value={district}
                                                        onSearchChange={searchDistrict}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={province_error}>
                                                    <h5>
                                                        จังหวัด{' '}
                                                        <span style={{ color: '#db2828', fontSize: '20px' }}>*</span>
                                                    </h5>

                                                    <Dropdown
                                                        selection
                                                        search
                                                        fluid
                                                        id='province'
                                                        name='province'
                                                        onChange={handleChangeAddress}
                                                        options={option_province}
                                                        value={province}
                                                        onSearchChange={searchProvince}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={zipcode_error}>
                                                    <h5>
                                                        รหัสไปรษณีย์{' '}
                                                        <span style={{ color: '#db2828', fontSize: '20px' }}>*</span>
                                                    </h5>
                                                    <Dropdown
                                                        selection
                                                        search
                                                        fluid
                                                        id='zipcode'
                                                        name='zipcode'
                                                        onChange={handleChangeAddress}
                                                        options={option_zipcode}
                                                        value={zipcode}
                                                        onSearchChange={searchZipcode}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    style={{
                                        marginLeft: 10,
                                        backgroundColor: '#38869C',
                                        color: 'white',
                                        border: '3px solid #38869C',
                                    }}
                                    onClick={handleSubmit}>
                                    ยืนยัน
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Container>
        </Fragment>
    );
};

export default OneChatEditProfile;
