import React, { Fragment, useEffect, useState } from 'react'
import CameraIcon from '../../../../../assets/images/camera2.png'
import {
    Container,
    Grid,
    Segment,
    Input,
    Icon,
    TextArea,
    Form,
    Image,
    Label,
    Button,
    Checkbox,
    TextAreaProps,
    Dimmer,
    Loader,
} from 'semantic-ui-react'
import axios from 'axios'
import { POST_NOTIFICATION_LINE_CREATE_PRE_COMPLAINT } from '../../../../../config/api/notification'
import { useLocation } from 'react-router-dom'
import GoogleMap from '../../../../../components/GoogleMaps'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { GET_COMPLAINT_TOPIC_BY_MUNICIPALITY_ID } from '../../../../../config/api/complaintTopicAndTypes'
import { alert } from '../../../../../components/alert'
import { getLIFF } from '../../../../../config/liff'

const LineComplaintForm: React.FC = () => {
    let location = useLocation()
    let [municipality_id, setMunicipalityId] = useState<string | null>('')
    let [line_user_id, setLineUserId] = useState<string>('')
    let [complaint_topic, setComplaintTopic] = useState<number | undefined>(undefined)
    let [complaint_detail, setComplaintDetail] = useState<string | number | undefined>('')
    let [option_complaint_topic, setOptionComplaintTopic] = useState<any[]>([])
    let [files, setFile] = useState<any[]>([])
    let [position, setPosition] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 })
    let [loading, setLoading] = useState<boolean>(true)
    let [complaint_detail_error, setComplaintDetailError] = useState<boolean>(false)
    let [complaint_topic_error, setComplaintTopicError] = useState<boolean>(false)
    let [liff, setLiff] = useState<any>()
    let [liff_id, setLiffId] = useState<string>('')
    let [list_directory, setListDirectory] = useState<[]>([])
    let [index_expand_type, setIndexExpandType] = useState<number | null>()
    let [complaint_type, setComplaintType] = useState<number | undefined>(undefined)

    useEffect(() => {
        const getComplaintTopicAndType = (municipality_id: string | null) => {
            axiosAuthen.get(`${GET_COMPLAINT_TOPIC_BY_MUNICIPALITY_ID}/${municipality_id}`).then((response) => {
                let { value } = response.data
                let topic_option: any[] = []
                value.forEach((topic: any) => {
                    topic_option.push({
                        value: topic.id,
                        text: topic.name,
                        types: topic.complaint_types,
                        directory: topic.directory_of_municipalities_name,
                    })
                })
                let directory = value.map((topic: any) => topic.directory_of_municipalities_name)
                const list_directory = directory.filter(
                    (directory: any, index: number, list_directory: any) => list_directory.indexOf(directory) === index
                )
                setOptionComplaintTopic(topic_option)
                setListDirectory(list_directory)
                setLoading(false)
            })
        }
        const query = new URLSearchParams(location.search)
        const municipality_id = query.get('municipality_id')
        setMunicipalityId(municipality_id)
        if (municipality_id) getComplaintTopicAndType(municipality_id)
        const liff_id = query.get('liff_id')
        if (liff_id) {
            setLiffId(liff_id)
            setCurrentPosition()
        }
    }, [location])

    useEffect(() => {
        getLIFF().then((liff: any) => {
            liff.init({
                liffId: liff_id,
            }).then(async () => {
                let profile = await liff.getProfile()
                setLineUserId(profile.userId)
                setLiff(liff)
            })
        })
    }, [liff_id])

    const setCurrentPosition = () => {
        navigator.geolocation.getCurrentPosition((event) => {
            let current = {
                lat: Number(event.coords.latitude),
                lng: Number(event.coords.longitude),
            }
            setPosition(current)
        })
    }

    const handleChangeTopic = (value: number) => {
        if (value !== complaint_topic) {
            setComplaintType(undefined)
        }
        setComplaintTopic(value)
        setComplaintTopicError(false)
    }

    const handleChangeDetail = (e: any, { value }: TextAreaProps) => {
        setComplaintDetail(value)
        setComplaintDetailError(false)
    }

    const handleChangeFile = (event: any) => {
        let file_complaint = event.target.files[0]
        if (file_complaint) {
            if (file_complaint.size > 35000000) {
                alert({
                    type: 'error',
                    title: 'ขนาดไฟล์ใหญ่เกินไป',
                    text: 'ขนาดไฟล์ต้องไม่เกิน 35 MB',
                    confirmText: 'ตกลง',
                })
            } else if (!file_complaint.type.includes('image/')) {
                alert({
                    type: 'error',
                    title: 'ชนิดของไฟล์ไม่ถูกต้อง',
                    text: 'รองรับเฉพาะไฟล์รูปภาพเท่านั้น',
                    confirmText: 'ตกลง',
                })
            } else {
                setFile([...files, file_complaint])
            }
        } else {
            setFile([...files, file_complaint])
        }
    }

    const handleChangePosition = (_: any, { value }: any) => {
        setPosition(value)
    }

    const removeImage = (index: number) => {
        let current_file = files.filter((_, index_file: number) => index_file !== index)

        setFile([...current_file])
    }

    const handleSubmit = () => {
        alert({
            type: 'confirm',
            title: 'ยื่นคำร้อง',
            text: 'ยืนยันการยื่นคำร้องหรือไม่',
            confirmText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onConfirm: () => {
                if (!validate()) {
                    let form_data = new FormData()
                    form_data.append(
                        'complaint_subject',
                        `แจ้งผ่าน Line เรื่อง ${
                            option_complaint_topic.find((topic) => topic.value === complaint_topic).text
                        }`
                    )
                    form_data.append('complaint_detail', String(complaint_detail))
                    form_data.append('complaint_topics_id', String(complaint_topic))
                    form_data.append('complaint_types_id', String(complaint_type || ''))
                    form_data.append('lat', String(position.lat))
                    form_data.append('lng', String(position.lng))
                    form_data.append('line_user_id', String(line_user_id))
                    form_data.append('municipalities_id', String(municipality_id))
                    files.forEach((file: any) => {
                        form_data.append('file_pre_complaint', file)
                    })
                    setLoading(true)
                    axios
                        .post(POST_NOTIFICATION_LINE_CREATE_PRE_COMPLAINT, form_data)
                        .then((response) => {
                            alert({
                                type: 'success',
                                title: 'บันทึกเสร็จสิ้น',
                                timer: 1500,
                                onClosed: () => {
                                    setLoading(false)
                                    liff && liff.closeWindow()
                                },
                            })
                        })
                        .catch((error) => {
                            setLoading(false)
                        })
                }
            },
        })
    }

    const validate = () => {
        let error = false
        if (!complaint_detail) {
            complaint_detail_error = true
            error = true
        }
        if (!complaint_topic) {
            complaint_topic_error = true
            error = true
        }

        setComplaintDetailError(complaint_detail_error)
        setComplaintTopicError(complaint_topic_error)
        if (error) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
        return error
    }
    const handleExpandType = (index: number | null) => {
        setIndexExpandType(index)
    }
    const handleSelectedType = (type_id: number | undefined) => {
        setComplaintType(type_id)
    }

    return (
        <Fragment>
            <div
                style={{
                    backgroundImage: 'linear-gradient(#1D3853, #1D3853)',
                    position: 'absolute',
                    height: 240,
                    width: '100%',
                }}
            />
            <Dimmer active={loading} inverted page>
                <Loader size='large'>รอสักครู่</Loader>
            </Dimmer>
            <Container>
                <Form style={{ zIndex: 2 }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{
                                    color: 'white',
                                    paddingTop: 14,
                                }}
                            >
                                <h2>แจ้งเรื่องร้องทุกข์</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column tablet='16' mobile='16'>
                                <Segment loading={loading}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <h4>
                                                    <Icon name='wrench' style={{ color: '#1E3B56' }} />{' '}
                                                    แจ้งเรื่องร้องทุกข์
                                                </h4>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={complaint_topic_error}>
                                                    <h5>
                                                        เรื่องที่ยื่นคำร้อง{' '}
                                                        <label style={{ color: 'red' }}>*กรุณาเลือกตัวเลือก</label>
                                                    </h5>
                                                    {list_directory.map((directory: string) => (
                                                        <Segment>
                                                            <Label color='blue'>{directory}</Label>
                                                            {option_complaint_topic.map(
                                                                (topic: any, index: number) =>
                                                                    directory === topic.directory && (
                                                                        <Segment
                                                                            key={index}
                                                                            style={
                                                                                complaint_topic === topic.value
                                                                                    ? {
                                                                                          border: '4px solid #1E3B56',
                                                                                          borderRadius: 10,
                                                                                          cursor: 'pointer',
                                                                                      }
                                                                                    : {
                                                                                          cursor: 'pointer',
                                                                                      }
                                                                            }
                                                                            onClick={() =>
                                                                                handleChangeTopic(topic.value)
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                radio
                                                                                label={
                                                                                    <label
                                                                                        style={
                                                                                            !complaint_topic_error
                                                                                                ? { color: 'black' }
                                                                                                : {}
                                                                                        }
                                                                                    >
                                                                                        {topic.text}
                                                                                    </label>
                                                                                }
                                                                                checked={
                                                                                    complaint_topic === topic.value
                                                                                }
                                                                            />
                                                                            {index_expand_type === index ? (
                                                                                <Icon
                                                                                    name='chevron circle up'
                                                                                    color='blue'
                                                                                    style={{
                                                                                        float: 'right',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleExpandType(null)
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <Icon
                                                                                    name='chevron circle down'
                                                                                    color='blue'
                                                                                    style={{
                                                                                        float: 'right',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleExpandType(index)
                                                                                    }}
                                                                                />
                                                                            )}

                                                                            {index_expand_type === index && (
                                                                                <div>
                                                                                    <div style={{ margin: 20 }}>
                                                                                        <Label color='teal'>
                                                                                            ประเภทงาน
                                                                                        </Label>
                                                                                    </div>
                                                                                    {topic.types.map((type: any) => (
                                                                                        <div style={{ margin: 20 }}>
                                                                                            <Checkbox
                                                                                                radio
                                                                                                label={
                                                                                                    <label
                                                                                                        style={
                                                                                                            !complaint_topic_error
                                                                                                                ? {
                                                                                                                      color: 'black',
                                                                                                                  }
                                                                                                                : {}
                                                                                                        }
                                                                                                    >
                                                                                                        {type.name}
                                                                                                    </label>
                                                                                                }
                                                                                                onChange={() => {
                                                                                                    handleSelectedType(
                                                                                                        type.id
                                                                                                    )
                                                                                                }}
                                                                                                checked={
                                                                                                    complaint_type ===
                                                                                                    type.id
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                        </Segment>
                                                                    )
                                                            )}
                                                        </Segment>
                                                    ))}
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={complaint_detail_error}>
                                                    <h5>รายละเอียด</h5>
                                                    <TextArea
                                                        name='complaint_detail'
                                                        value={complaint_detail}
                                                        onChange={handleChangeDetail}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <h5>แนบรูปภาพ</h5>
                                                <Grid>
                                                    <Grid.Row>
                                                        {files.map((file, index) => (
                                                            <Grid.Column tablet='5' key={index}>
                                                                <Segment
                                                                    key={index}
                                                                    style={{
                                                                        width: 80,
                                                                        height: 80,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        padding: 5,
                                                                        margin: 5,
                                                                    }}
                                                                >
                                                                    <Label
                                                                        floating
                                                                        icon={
                                                                            <div style={{ cursor: 'pointer' }}>
                                                                                <Icon
                                                                                    name='delete'
                                                                                    style={{ margin: 0 }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        basic
                                                                        circular
                                                                        onClick={() => {
                                                                            removeImage(index)
                                                                        }}
                                                                    />
                                                                    <Image
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            maxHeight: 70,
                                                                            maxWidth: 70,
                                                                        }}
                                                                        src={URL.createObjectURL(file)}
                                                                        // onClick={() => handleShowImage(index)}
                                                                    />
                                                                </Segment>
                                                            </Grid.Column>
                                                        ))}
                                                        <Grid.Column tablet='5'>
                                                            <Image
                                                                style={{ cursor: 'pointer', margin: 5 }}
                                                                label={
                                                                    <Label
                                                                        floating
                                                                        icon={
                                                                            <div style={{ cursor: 'pointer' }}>
                                                                                <Icon
                                                                                    name='plus'
                                                                                    style={{ marginRight: 0 }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        basic
                                                                        circular
                                                                    />
                                                                }
                                                                size='tiny'
                                                                src={CameraIcon}
                                                                onClick={() => {
                                                                    document.getElementById('file')?.click()
                                                                }}
                                                                bordered
                                                            />
                                                        </Grid.Column>

                                                        <Input>
                                                            <input
                                                                type='file'
                                                                id='file'
                                                                hidden
                                                                onChange={handleChangeFile}
                                                                name='Cameramanage'
                                                                accept='image/*'
                                                            />
                                                        </Input>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <h5>
                                                    <label style={{ color: 'red' }}>
                                                        เพื่อความสะดวกในการปฏิบัติงานของเจ้าหน้าที่ <br />
                                                        กรุณาปักหมุด
                                                    </label>
                                                </h5>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row style={{ padding: 1 }}>
                                            <Grid.Column tablet='16' style={{ padding: 1 }}>
                                                <GoogleMap
                                                    name='position'
                                                    onChange={handleChangePosition}
                                                    value={position}
                                                    getCurrentPosition
                                                    style={{ height: window.innerHeight / 1.5 }}
                                                    search
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column
                                                tablet='16'
                                                mobile='16'
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        // marginLeft: 10,
                                                        backgroundColor: '#38869C',
                                                        color: 'white',
                                                        border: '3px solid #38869C',
                                                    }}
                                                    onClick={handleSubmit}
                                                >
                                                    ยืนยัน
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Container>
        </Fragment>
    )
}

export default LineComplaintForm
