import React, { Fragment, useEffect, useState } from 'react'
import { Breadcrumb, Container, Grid, Segment, Image, Input, Button, InputOnChangeData } from 'semantic-ui-react'
import ImageUserManual from '../../../assets/images/user manual/user-manual.png'
import ImageTopicMenu from '../../../assets/images/user manual/topic-menu.png'
import ImageSubTopicMenu from '../../../assets/images/user manual/sub-topic-menu.png'
import ImageDownloadPDFMenu from '../../../assets/images/user manual/download-pdf-menu.png'
import ImageTopic from '../../../assets/images/user manual/topic.png'
import { axiosAuthen } from '../../../config/axiosAuthen'
import { GET_USER_MANUAL_TOPIC_ALL } from '../../../config/api/userManual'
import { useHistory } from 'react-router-dom'

export interface InterfaceTopic {
    id: number
    name: string
}

const UserManual: React.FC = () => {
    let history = useHistory()
    let [topic_all, setTopicAll] = useState<[]>([])
    let [keyword, setKeyword] = useState<string>('')
    useEffect(() => {
        axiosAuthen.get(GET_USER_MANUAL_TOPIC_ALL).then((response) => {
            setTopicAll(response.data.value)
        })
    }, [])
    const getDetailTopic = (topic_id: number, topic_name: string) => {
        history.push('user-manual/detail', { topic_id: topic_id, topic_name: topic_name })
    }
    const handleClickDetail = (topic_id: number, topic_name: string) => {
        getDetailTopic(topic_id, topic_name)
    }
    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    const handleClickSearch = () => {
        axiosAuthen.get(`${GET_USER_MANUAL_TOPIC_ALL}?keyword=${keyword}`).then((response) => {
            setTopicAll(response.data.value)
        })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section active>คู่มือการใช้งาน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>คู่มือการใช้งาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16' mobile='16'>
                                <Segment
                                    style={{
                                        boxShadow:
                                            '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
                                    }}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='7' tablet='16' mobile='16'>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Image src={ImageUserManual} size='medium'></Image>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column
                                                computer='7'
                                                tablet='16'
                                                mobile='16'
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                <div>
                                                    <h3>คู่มือการใช้งาน</h3>
                                                    <label>
                                                        คู่มือการใช้งานนี้เป็นการรวบรวมเอกสารการใช้งานเว็บไซต์การจัดการภายใน
                                                        โดยมีวัตถุประสงค์
                                                        ให้ผู้ใช้งานเว็บไซต์ได้รับทราบและเข้าใจขั้นตอนการใช้งานของเว็บไซต์ได้ถูกต้องทั้งนี้ได้แนบ
                                                        เอกสารคู่มือการใช้งานไว้บนเว็บไซต์แล้วโดยการเลือกเนื้อหาที่ต้องการและดาวน์โหลดเอกสารคู่มือการใช้งานในรูปแบบของ
                                                        PDF
                                                    </label>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16' mobile='16'>
                                <Segment
                                    style={{
                                        boxShadow:
                                            '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
                                    }}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='16' tablet='16' mobile='16'>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <h3>การใช้งานคู่มือเอกสาร</h3>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='5' tablet='16' mobile='16' textAlign='center'>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Image
                                                        src={ImageTopicMenu}
                                                        style={{ cursor: 'pointer', height: 140, weight: 150 }}></Image>
                                                </div>
                                                <h4>เลือกสารบัญ</h4>
                                                <label>ค้นหาและเลือกหัวข้อคู่มือการใช้งานจากสารบัญ</label>
                                            </Grid.Column>
                                            <Grid.Column computer='5' tablet='16' mobile='16' textAlign='center'>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Image
                                                        src={ImageSubTopicMenu}
                                                        style={{ cursor: 'pointer', height: 140, weight: 150 }}></Image>
                                                </div>
                                                <h4>เลือกหัวข้อย่อย</h4>
                                                <label>
                                                    เลือกหัวข้อย่อย หรือเลือกดูรายละเอียดของเนื้อหาคู่มือการใช้งาน
                                                </label>
                                            </Grid.Column>
                                            <Grid.Column computer='5' tablet='16' mobile='16' textAlign='center'>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Image
                                                        src={ImageDownloadPDFMenu}
                                                        style={{ cursor: 'pointer', height: 140, weight: 150 }}></Image>
                                                </div>
                                                <h4>ดาวน์โหลด PDF</h4>
                                                <label>ดาวโหลดเอกสารคู่มือการใช้งานในรูปแบบของ ไฟล์ PDF</label>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16' mobile='16'>
                                <Segment
                                    style={{
                                        boxShadow:
                                            '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
                                    }}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='1' tablet='16' mobile='16'></Grid.Column>
                                            <Grid.Column computer='8' tablet='16' mobile='16'>
                                                <h3>สารบัญ</h3>
                                                <label>
                                                    เลือกหัวข้อเอกสารด้านล่างหรือค้นหาหัวข้อเอกสารโดยการเลือกเอกสารที่กล่องข้อความด้านขวามือของผู้ใช้งาน
                                                    และกดปุ่ม “ค้นหา” เพื่อเลือกหัวข้อที่ต้องการ
                                                </label>
                                            </Grid.Column>
                                            <Grid.Column
                                                computer='4'
                                                tablet='16'
                                                mobile='16'
                                                style={{ alignSelf: 'flex-end' }}>
                                                <label>ค้นหาเอกสาร</label>

                                                <Input
                                                    fluid
                                                    placeholder='ค้นหา'
                                                    icon='search'
                                                    onChange={handleChangeKeyword}
                                                    value={keyword}
                                                />
                                            </Grid.Column>
                                            <Grid.Column
                                                computer='2'
                                                tablet='16'
                                                mobile='16'
                                                style={{ alignSelf: 'flex-end' }}>
                                                <Button
                                                    fluid
                                                    style={{ backgroundColor: '#293757', color: 'white' }}
                                                    onClick={handleClickSearch}>
                                                    ค้นหา
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            {topic_all.length > 0 ? (
                                                topic_all.map((data: InterfaceTopic, index: number) => (
                                                    <Grid.Column computer='5' tablet='16' mobile='16' key={index}>
                                                        <Segment
                                                            style={{
                                                                boxShadow: '0px 11px 24px rgba(0, 0, 0, 0.2)',
                                                                margin: 10,
                                                                height: 283.15,
                                                            }}>
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='16' tablet='16' mobile='16'>
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                            }}>
                                                                            <Image
                                                                                src={ImageTopic}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                size={'small'}></Image>
                                                                        </div>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column
                                                                        computer='4'
                                                                        tablet='16'
                                                                        mobile='16'
                                                                        textAlign='right'>
                                                                        <label
                                                                            style={{
                                                                                background: 'rgba(40, 54, 85, 0.12)',
                                                                                borderRadius: 200,
                                                                                padding: '5px 15px',
                                                                                fontWeight: 'bold',
                                                                            }}>
                                                                            {index + 1}
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column
                                                                        computer='12'
                                                                        tablet='16'
                                                                        mobile='16'
                                                                        textAlign='left'>
                                                                        <h5>{data.name}</h5>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column
                                                                        computer='16'
                                                                        tablet='16'
                                                                        mobile='16'
                                                                        textAlign='center'>
                                                                        <Button
                                                                            style={{
                                                                                backgroundColor: '#293757',
                                                                                color: 'white',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleClickDetail(data.id, data.name)
                                                                            }>
                                                                            ดูเพิ่มเติม
                                                                        </Button>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Segment>
                                                    </Grid.Column>
                                                ))
                                            ) : (
                                                <Grid.Column>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            color: 'rgba(30, 31, 38, 0.5)',
                                                        }}>
                                                        <h5>ไม่พบข้อมูล</h5>
                                                    </div>
                                                </Grid.Column>
                                            )}
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default UserManual
