import React, { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Form, Grid, Input, InputOnChangeData } from 'semantic-ui-react'
import { alert } from '../../../../../components/alert'
import CustomModal from '../../../../../components/CustomModal'
import { POST_CREATE_AND_EDITE_CONTACT } from '../../../../../config/api/contacts'
import { axiosAuthen } from '../../../../../config/axiosAuthen'

type FormProps = {
    open?: boolean
    onClose?: () => void
}

const CreateContact: React.FC<FormProps> = (props) => {
    let municipalities_id = localStorage.municipality_id
    let location = useLocation()
    let { state } = location
    if (state) {
        let location_state = JSON.parse(JSON.stringify(state, null, ' '))
        let { municipality_id } = location_state
        if (municipality_id) municipalities_id = municipality_id
    }
    let [contact, setContact] = useState<string | undefined>('')
    let [errors_contact, setErrorsContact] = useState<boolean>(false)

    const handleChangeContact = (e: any, { value }: InputOnChangeData) => {
        setContact(value)
        setErrorsContact(false)
    }

    const validateForm = () => {
        let error = false
        if (!contact) {
            error = true
            setErrorsContact(true)
        }

        return error
    }

    const handleSubmit = () => {
        if (!validateForm()) {
            let body = {
                municipality_id: Number(municipalities_id),
                name: contact,
            }
            axiosAuthen
                .post(`${POST_CREATE_AND_EDITE_CONTACT}`, body)
                .then((response) => {
                    handleClose()
                })
                .catch((error) => {
                    if (error.response) {
                        alert({
                            type: 'error',
                            title: error.response.data.message,
                            cancelText: 'ปิด',
                            onClosed: () => {
                                handleClose()
                            },
                        })
                    }
                })
        }
    }

    const handleClose = () => {
        setContact('')
        setErrorsContact(false)
        props.onClose && props.onClose()
    }

    return (
        <Fragment>
            <CustomModal icon='file alternate' header='เพิ่มช่องทางการรับเรื่อง' open={props.open}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>ชื่อช่องทางการรับเรื่อง</label>
                                <Form.Field error={errors_contact}>
                                    <Input fluid name='contact' value={contact} onChange={handleChangeContact} />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default CreateContact
