import React, { Fragment, useEffect, useState } from 'react'
import {
    Container,
    Dropdown,
    Grid,
    Input,
    Label,
    Pagination,
    Table,
    Breadcrumb,
    InputOnChangeData,
    Form,
    Button,
    Icon,
} from 'semantic-ui-react'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { useHistory, useLocation } from 'react-router'

import {
    DELETE_COMPALINT_TOPIC_AND_TYPES,
    LIST_COMPALINT_TOPIC_AND_TYPES,
} from '../../../../../config/api/complaintTopicAndTypes'
import CreateComplaint from './ModalCreate'

import EditComplaint from './ModalEdit'
import { alert } from '../../../../../components/alert'

export interface InterfaceComplaint {
    municipalities_id: string
    directory_of_municipalities_id: number
    id: number
    name: string
    short_name: string
    updated_at?: string
    updated_by?: number
    directory_name: string
    complaint_types: InterfaceComplaintTypes[]
}

export interface InterfaceComplaintTypes {
    complaints_id: number
    id: string
    name: string
    updated_at?: string
    updated_by?: number
    complaint_topics_id: number
    created_at?: string
    created_by?: number
}

const ListComplaint: React.FC = () => {
    let history = useHistory()
    let municipalities_id = localStorage.municipality_id
    let location = useLocation()
    let { state } = location
    if (state) {
        let location_state = JSON.parse(JSON.stringify(state, null, ' '))
        let { municipality_id } = location_state
        if (municipality_id) municipalities_id = municipality_id
    }
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [keyword, setKeyword] = useState<string>('')
    let [loading, setLoading] = useState<boolean>(false)
    let [open_modal, setOpenModal] = useState<boolean>(false)
    let [open_modal_edit, setOpenModalEdit] = useState<boolean>(false)
    let [complaints, setComplaints] = useState<any[]>([])
    let [data_complaint, setDataComplaint] = useState<InterfaceComplaint>()

    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    const handleAdd = () => {
        setOpenModal(true)
    }

    const handleEdit = (data: any) => {
        setDataComplaint(data)
        setOpenModalEdit(true)
    }

    useEffect(() => {
        const getListComplaint = () => {
            setLoading(true)
            axiosAuthen
                .get(
                    `${LIST_COMPALINT_TOPIC_AND_TYPES}?keyword=${keyword}&page=${current_page}&offset=${row_qty}&municipality_id=${municipalities_id}`
                )
                .then((response) => {
                    let { value } = response.data

                    setRecordQty(value.count)
                    setComplaints(value.complaint_topics)
                    setLoading(false)
                })
        }
        getListComplaint()
    }, [keyword, current_page, row_qty, open_modal, open_modal_edit, municipalities_id])

    const handleCloseModal = () => setOpenModal(false)

    const handleCloseModalEdit = () => setOpenModalEdit(false)

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleDelete = (data: any) => {
        alert({
            type: 'warning',
            title: 'ลบเรื่องร้องทุกข์',
            text: 'ยืนยันการลบข้อมูล',
            confirmText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onConfirm: () => {
                axiosAuthen
                    .delete(`${DELETE_COMPALINT_TOPIC_AND_TYPES}?topic_id=${data.id}`)
                    .then((response) => {
                        setLoading(true)
                    })
                    .then(() => {
                        alert({
                            type: 'success',
                            title: 'บันทึกเสร็จสิ้น',
                            timer: 1500,
                            onClosed: () => {
                                axiosAuthen
                                    .get(
                                        `${LIST_COMPALINT_TOPIC_AND_TYPES}?keyword=${keyword}&page=${current_page}&offset=${row_qty}`
                                    )
                                    .then((response) => {
                                        let { value } = response.data

                                        setRecordQty(value.count)
                                        setComplaints(value.complaint_topics)
                                        setLoading(false)
                                    })
                            },
                        })
                    })
            },
        })
    }

    const calculateComplaintSpanRow = (area: InterfaceComplaint) => {
        let row = area.complaint_types.length
        return row
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <CreateComplaint open={open_modal} onClose={handleCloseModal} />
                        {data_complaint && (
                            <EditComplaint
                                open={open_modal_edit}
                                onClose={handleCloseModalEdit}
                                data={data_complaint}
                            />
                        )}

                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Breadcrumb>
                                    {localStorage.permission_en === 'super_admin' && (
                                        <Fragment>
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities`, {
                                                        municipality_id: municipalities_id,
                                                    })
                                                }}
                                            >
                                                รายการองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                        municipality_id: municipalities_id,
                                                    })
                                                }}
                                            >
                                                กำหนดการใช้งานองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </Fragment>
                                    )}
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/management/system`, {
                                                municipality_id: municipalities_id,
                                            })
                                        }}
                                    >
                                        จัดการระบบ
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>รายการเรื่องร้องทุกข์</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>จัดการเรื่องร้องทุกข์</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={handleAdd}
                                >
                                    <Icon name='plus' /> เพิ่มเรื่องร้องทุกข์
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='10' only='computer' />
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    placeholder='ค้นหา'
                                    icon='search'
                                    name='keyword'
                                    value={keyword}
                                    onChange={handleChangeKeyword}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form loading={loading}>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='4' textAlign='center'>
                                                    หน่วยงาน
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='4' textAlign='center'>
                                                    เรื่องร้องทุกข์
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='4' textAlign='center'>
                                                    ประเภทเรื่องร้องทุกข์
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='3' textAlign='center'>
                                                    จัดการ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    {complaints.map((complaint: InterfaceComplaint, index_complaint: number) => (
                                        <Table attached celled className='table-body-merge' key={index_complaint}>
                                            <Table.Body>
                                                {calculateComplaintSpanRow(complaint) > 0 ? (
                                                    complaint.complaint_types.map(
                                                        (
                                                            complaint_types: InterfaceComplaintTypes,
                                                            index_complaint_types: number
                                                        ) => (
                                                            <Table.Row key={index_complaint_types}>
                                                                {index_complaint_types === 0 && (
                                                                    <Table.Cell
                                                                        rowSpan={calculateComplaintSpanRow(complaint)}
                                                                        textAlign='center'
                                                                        width='1'
                                                                    >
                                                                        <Label
                                                                            circular
                                                                            style={{
                                                                                backgroundColor: '#425679',
                                                                                color: '#FFFFFF',
                                                                            }}
                                                                        >
                                                                            {row_qty * (current_page - 1) +
                                                                                index_complaint +
                                                                                1}
                                                                        </Label>
                                                                    </Table.Cell>
                                                                )}
                                                                {index_complaint_types === 0 && (
                                                                    <Table.Cell
                                                                        rowSpan={calculateComplaintSpanRow(complaint)}
                                                                        textAlign='center'
                                                                        width='4'
                                                                    >
                                                                        {complaint.directory_name}
                                                                    </Table.Cell>
                                                                )}

                                                                {index_complaint_types === 0 && (
                                                                    <Table.Cell
                                                                        rowSpan={calculateComplaintSpanRow(complaint)}
                                                                        width='4'
                                                                        textAlign='center'
                                                                    >
                                                                        {complaint.name}
                                                                    </Table.Cell>
                                                                )}

                                                                <Table.Cell width='4' textAlign='center'>
                                                                    {complaint_types.name}
                                                                </Table.Cell>

                                                                {index_complaint_types === 0 && (
                                                                    <Table.Cell
                                                                        rowSpan={calculateComplaintSpanRow(complaint)}
                                                                        width='3'
                                                                        textAlign='center'
                                                                    >
                                                                        <Button
                                                                            basic
                                                                            style={{
                                                                                marginTop: 10,
                                                                            }}
                                                                            icon
                                                                            color='yellow'
                                                                            onClick={() => handleEdit(complaint)}
                                                                        >
                                                                            <Icon name='edit' /> แก้ไข
                                                                        </Button>
                                                                        <Button
                                                                            basic
                                                                            style={{
                                                                                marginTop: 10,
                                                                            }}
                                                                            icon
                                                                            color='red'
                                                                            onClick={() => handleDelete(complaint)}
                                                                        >
                                                                            <Icon name='trash alternate' /> ลบ
                                                                        </Button>
                                                                    </Table.Cell>
                                                                )}
                                                            </Table.Row>
                                                        )
                                                    )
                                                ) : (
                                                    <Table.Row>
                                                        <Table.Cell
                                                            rowSpan={calculateComplaintSpanRow(complaint)}
                                                            textAlign='center'
                                                            width='1'
                                                        >
                                                            <Label
                                                                circular
                                                                style={{
                                                                    backgroundColor: '#425679',
                                                                    color: '#FFFFFF',
                                                                }}
                                                            >
                                                                {row_qty * (current_page - 1) + index_complaint + 1}
                                                            </Label>
                                                        </Table.Cell>

                                                        <Table.Cell
                                                            rowSpan={calculateComplaintSpanRow(complaint)}
                                                            width='4'
                                                            textAlign='center'
                                                        >
                                                            {complaint.directory_name}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            rowSpan={calculateComplaintSpanRow(complaint)}
                                                            width='4'
                                                            textAlign='center'
                                                        >
                                                            {complaint.name}
                                                        </Table.Cell>

                                                        <Table.Cell width='4' textAlign='center'>
                                                            -
                                                        </Table.Cell>

                                                        {
                                                            <Table.Cell
                                                                rowSpan={calculateComplaintSpanRow(complaint)}
                                                                width='3'
                                                                textAlign='center'
                                                            >
                                                                <Button
                                                                    basic
                                                                    style={{
                                                                        marginTop: 10,
                                                                    }}
                                                                    icon
                                                                    color='yellow'
                                                                    onClick={() => handleEdit(complaint)}
                                                                >
                                                                    <Icon name='edit' /> แก้ไข
                                                                </Button>
                                                                <Button
                                                                    basic
                                                                    style={{
                                                                        marginTop: 10,
                                                                    }}
                                                                    icon
                                                                    color='red'
                                                                    onClick={() => handleDelete(complaint)}
                                                                >
                                                                    <Icon name='trash alternate' /> ลบ
                                                                </Button>
                                                            </Table.Cell>
                                                        }
                                                    </Table.Row>
                                                )}
                                            </Table.Body>
                                        </Table>
                                    ))}
                                </Form>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row only='computer tablet'>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column computer='11' style={{ display: 'flex' }} only='computer'>
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default ListComplaint
