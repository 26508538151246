import { ManagementUsersFormAdditionalState } from '../../../../types/Management/Users/Form/Additional';
import { ActionInterface } from '../../../../types';

const initialState: ManagementUsersFormAdditionalState = {
    roles_id: undefined,
    directory_of_municipality: undefined,
    role_option: [],
    directory_of_municipality_option: [],
    position: '',
    role_error: false,
    department_error: false,
    sub_division_error: false,
    municipality: undefined,
    municipality_option: [],
    municipality_error: false,
};

const managementUsersFormAdditional = (
    state = initialState,
    action: ActionInterface
): ManagementUsersFormAdditionalState => {
    switch (action.type) {
        case 'setStateByNameAndValueManagementUsersFormAdditional':
            return action.name
                ? {
                      ...state,
                      [action.name]: action.value,
                  }
                : { ...state };
        case 'appendFormManagementUsersFormAdditional':
            return {
                ...state,
                ...action.value,
            };
        case 'resetFormByReducerNameManagementUsersFormAdditional':
            return {
                ...state,
                ...{
                    roles_id: undefined,
                    directory_of_municipality: undefined,
                    role_option: [],
                    directory_of_municipality_option: [],
                    position: '',
                    role_error: false,
                    department_error: false,
                    sub_division_error: false,
                    municipality: undefined,
                    municipality_option: [],
                    municipality_error: false,
                },
            };
        default:
            return state;
    }
};

export default managementUsersFormAdditional;
