import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, Grid, Icon, Image, Label, Segment } from 'semantic-ui-react'
import IconOnePlatformDarkBackground from '../../../../assets/images/logo one platform dark background.png'
import IconOnePlatform from '../../../../assets/images/logo one platform.png'

const LineRegisterMenu = () => {
    const history = useHistory()
    let location = useLocation()
    let [liff_id, setLiffId] = useState<string | null>('')
    let [municipality, setMunicipalityId] = useState<string | null>('')
    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const municipality = query.get('municipality_id')
        const liff_id = query.get('liff_id')
        setLiffId(liff_id)
        setMunicipalityId(municipality)
    }, [location])

    const handleClickLoginPage = () => {
        console.log(liff_id)
        history.push('register/login', { municipality_id: municipality, liff_id: liff_id })
    }
    const handleClickRegisterPage = () => {
        window.location.assign(`https://liff.line.me/${liff_id}`)
    }
    return (
        <div className='background-container-no-sidebar'>
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Segment compact style={{ backgroundColor: '#264863', color: '#ffffff' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Image src={IconOnePlatformDarkBackground} size='tiny' style={{ margin: 10 }} />{' '}
                                        <label style={{ fontWeight: 'bold', fontSize: 16 }}>
                                            ลงทะเบียน <br />
                                            ข้อมูล ONE ID
                                        </label>
                                    </div>
                                </Segment>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column computer='8' textAlign='center'>
                            <Segment style={{ cursor: 'pointer' }} onClick={handleClickLoginPage}>
                                <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
                                    <Label corner color='green'>
                                        <Icon name='check' />
                                    </Label>
                                    <Image src={IconOnePlatform} size='tiny' />
                                </div>
                                <div>มีบัญชี One ID แล้ว</div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column computer='8' textAlign='center'>
                            <Segment style={{ cursor: 'pointer' }} onClick={handleClickRegisterPage}>
                                <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
                                    <Label corner color='red'>
                                        <Icon name='delete' />
                                    </Label>
                                    <Image src={IconOnePlatform} size='tiny' />
                                </div>
                                <div>ยังไม่ลงทะเบียน One ID</div>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    )
}

export default LineRegisterMenu
