import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Container, Dropdown, DropdownProps, Grid, Icon, InputOnChangeData } from 'semantic-ui-react'
import DatePicker from '../../components/DatePicker'

import { GET_DASHBOARD_YEARS } from '../../config/api/dashboard'
import { axiosAuthen } from '../../config/axiosAuthen'
import { getAuthenMenuList } from '../../store/selectors'
import DashboardAllStatus from './AllStatus'
import DashboardAverageResults from './AverageEvaluate'
import DashboardCalendar from './Calendar'
import DashboardComplaintCanNotProcess from './ComplaintCanNotProcess'
import DashboardComplaintSummary from './ComplaintSummary'
import DashboardContact from './Contact'
import DashboardResultTechnian from './ResultTechnian'
import DashboardStatusComplaint from './StatusComplaint'

const Dashboard: React.FC = () => {
    let history = useHistory()
    const { menu_list } = useSelector(getAuthenMenuList)
    let dashbord_action = menu_list
        ?.find((menu) => menu.name_th === 'แดชบอร์ด')
        ?.pages?.find((page) => page.name_th === 'แดชบอร์ด')?.actions
    let [year_option, setYearOption] = useState<{ value: string; text: string }[]>([])
    let [year, setYear] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('all')
    let [start_date, setStartDate] = useState<string>('')
    let [end_date, setEndDate] = useState<string>('')
    let permission = localStorage.getItem('permission_en')

    useEffect(() => {
        axiosAuthen.get(GET_DASHBOARD_YEARS).then((response) => {
            let { value } = response.data
            let year_option = value.map((data: any) => ({ value: data, text: data }))
            setYearOption([{ text: 'ทั้งหมด', value: 'all' }, ...year_option])
            setYear('all')
        })
    }, [])

    const handleSearchYear = (e: any, { value }: DropdownProps) => {
        setYear(value)
    }

    const handleChangeStartDate = (_: any, { value }: InputOnChangeData) => {
        setStartDate(value)
    }
    const handleChangeEndDate = (_: any, { value }: InputOnChangeData) => {
        setEndDate(value)
    }
    const handleClickMap = () => {
        history.push('dashboard/map')
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container fluid>
                    <Grid columns='16'>
                        <Grid.Row>
                            <Grid.Column computer='3' mobile='4' style={{ alignSelf: 'center' }}>
                                <h4>แดชบอร์ด</h4>
                            </Grid.Column>
                            <Grid.Column computer='2' mobile='4'>
                                <label>ปีงบประมาณ</label>
                                <br />
                                <Dropdown
                                    search
                                    selection
                                    fluid
                                    name='year'
                                    value={year}
                                    options={year_option}
                                    onChange={handleSearchYear}
                                />
                            </Grid.Column>
                            <Grid.Column computer='2' mobile='4'>
                                <label>วันที่เริ่มต้น</label>
                                <br />
                                <DatePicker
                                    fluid
                                    name='start_date'
                                    value={start_date}
                                    onChange={handleChangeStartDate}
                                />
                            </Grid.Column>
                            <Grid.Column computer='2' mobile='4'>
                                <label>วันที่สิ้นสุด</label>
                                <br />
                                <DatePicker fluid name='end_date' value={end_date} onChange={handleChangeEndDate} />
                            </Grid.Column>
                            <Grid.Column computer='4' />
                            <Grid.Column computer='2' mobile='4' style={{ alignSelf: 'center' }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={handleClickMap}
                                >
                                    <Icon name='map' /> แผนที่
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        {dashbord_action?.some((action) => action.name_th === 'สรุปข้อมูลประจำปีงบประมาณ') && (
                            <Grid.Row>
                                <Grid.Column computer='16' tablet='16'>
                                    <DashboardAllStatus year={year} start_date={start_date} end_date={end_date} />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            {dashbord_action?.some((action) => action.name_th === 'สรุปผลการประเมินโดยเฉลี่ย') && (
                                <Grid.Column computer='6' mobile='16' style={{ alignSelf: 'center' }}>
                                    <DashboardAverageResults year={year} start_date={start_date} end_date={end_date} />
                                </Grid.Column>
                            )}
                            <Grid.Column computer='10' mobile='16' style={{ alignSelf: 'center' }}>
                                <Grid>
                                    {dashbord_action?.some((action) => action.name_th === 'สรุปสถานะคำร้องทุกข์') && (
                                        <Grid.Row>
                                            <Grid.Column computer='16' mobile='16' style={{ alignSelf: 'center' }}>
                                                <DashboardStatusComplaint
                                                    year={year}
                                                    start_date={start_date}
                                                    end_date={end_date}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    <Grid.Row>
                                        {dashbord_action?.some(
                                            (action) => action.name_th === 'สรุปคำร้องทุกข์ที่ไม่สามารถดำเนินการได้'
                                        ) && (
                                            <Grid.Column computer='8' mobile='16' style={{ alignSelf: 'center' }}>
                                                <DashboardComplaintCanNotProcess
                                                    year={year}
                                                    start_date={start_date}
                                                    end_date={end_date}
                                                />
                                            </Grid.Column>
                                        )}
                                        {dashbord_action?.some(
                                            (action) => action.name_th === 'สรุปช่องทางการรับเรื่อง'
                                        ) && (
                                            <Grid.Column computer='8' mobile='16' style={{ alignSelf: 'center' }}>
                                                <DashboardContact
                                                    year={year}
                                                    start_date={start_date}
                                                    end_date={end_date}
                                                />
                                            </Grid.Column>
                                        )}
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {permission !== 'technician' &&
                                dashbord_action?.some((action) => action.name_th === 'สรุปเรื่องร้องทุกข์') && (
                                    <Grid.Column computer='16' tablet='16'>
                                        <DashboardComplaintSummary
                                            year={year}
                                            start_date={start_date}
                                            end_date={end_date}
                                        />
                                    </Grid.Column>
                                )}
                        </Grid.Row>
                        <Grid.Row>
                            {permission !== 'technician' &&
                                dashbord_action?.some(
                                    (action) => action.name_th === 'ผลการปฏิบัติงานของเจ้าหน้าที่'
                                ) && (
                                    <Grid.Column computer='16' tablet='16'>
                                        <DashboardResultTechnian
                                            year={year}
                                            start_date={start_date}
                                            end_date={end_date}
                                        />
                                    </Grid.Column>
                                )}
                        </Grid.Row>
                        {dashbord_action?.some((action) => action.name_th === 'ปฏิทินกิจกรรม') && (
                            <Grid.Row>
                                <Grid.Column computer='16' tablet='16'>
                                    <DashboardCalendar />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default Dashboard
