import React, { Fragment, useEffect, useState } from 'react';
import { Button, Dropdown, DropdownProps, Form, Grid, Icon, Input, InputOnChangeData } from 'semantic-ui-react';
import CustomModal from '../../../../../components/CustomModal';
import { axiosAuthen } from '../../../../../config/axiosAuthen';
import {
    GET_USER_MANUAL_SUB_TOPIC_ALL,
    GET_USER_MANUAL_TOPIC_ALL,
    POST_USER_MANUAL_CREATE_CONTENT,
    PUT_USER_MANUAL_EDIT_CONTENT,
    PUT_USER_MANUAL_UPLOAD_FILE_CONTENT,
} from '../../../../../config/api/userManual';
import { InterfaceUserManualContent } from '.';
import { getFile } from '../../../../../functions/GetFile';

type FormProps = {
    data?: InterfaceUserManualContent;
    open?: boolean;
    onClose?: () => void;
};

const UserManualContentModalForm: React.FC<FormProps> = (props) => {
    let [topic_option, setTopicOption] = useState<[]>([]);
    let [topic, setTopic] = useState<string | number | boolean | (string | number | boolean)[] | undefined>(undefined);
    let [content_id, setContentId] = useState<number | undefined>(undefined);
    let [content_name, setContentName] = useState<string | undefined>('');
    let [sub_topic_option, setSubTopicOption] = useState<[]>([]);
    let [sub_topic, setSubTopic] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('');
    let [file, setFile] = useState<any>(undefined);
    let { data } = props;

    useEffect(() => {
        if (data) {
            setContentName(data.name);
            setContentId(data.id);
            setTopic(data.user_manual_topics_id);
            getSubTopicAll(data.user_manual_topics_id);
            setSubTopic(data.user_manual_sub_topics_id);
            const getFileContent = async () => {
                let file_content = await getFile(data!.path_file_name, data!.path_file);
                setFile(file_content);
            };

            if (data?.path_file && data.path_file_name) getFileContent();
        }
    }, [data]);

    useEffect(() => {
        const getTopicAll = () => {
            axiosAuthen.get(GET_USER_MANUAL_TOPIC_ALL).then((response) => {
                let { value } = response.data;
                let option = value.map((data: any) => ({ value: data.id, text: data.name }));
                setTopicOption(option);
            });
        };
        getTopicAll();
    }, []);

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const handleChangeContentName = (_: any, { value }: InputOnChangeData) => {
        setContentName(value);
    };

    const handleClickSelectFile = () => {
        document.getElementById('file')!.click();
    };

    const handleChangeFile = (e: any) => {
        let file = e.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleSubmit = () => {
        let body = {
            name: content_name,
            user_manual_topics_id: topic,
            user_manual_sub_topics_id: sub_topic,
        };
        let form_data = new FormData();
        form_data.append('file', file);
        if (content_id) {
            body = { ...body, ...{ id: content_id } };
            axiosAuthen.put(PUT_USER_MANUAL_EDIT_CONTENT, body).then(() => {
                if (file) {
                    axiosAuthen.put(`${PUT_USER_MANUAL_UPLOAD_FILE_CONTENT}/${content_id}`, form_data).then(() => {
                        handleClose();
                    });
                } else {
                    handleClose();
                }
            });
        } else {
            axiosAuthen.post(POST_USER_MANUAL_CREATE_CONTENT, body).then((response) => {
                let { value } = response.data;
                let content_id = value.id;
                if (file) {
                    axiosAuthen.put(`${PUT_USER_MANUAL_UPLOAD_FILE_CONTENT}/${content_id}`, form_data).then(() => {
                        handleClose();
                    });
                } else {
                    handleClose();
                }
            });
        }
    };

    const getSubTopicAll = (topic_id: number) => {
        axiosAuthen.get(`${GET_USER_MANUAL_SUB_TOPIC_ALL}/${topic_id}`).then((response) => {
            let { value } = response.data;
            let option = value.map((data: any) => ({ value: data.id, text: data.name }));
            setSubTopicOption(option);
        });
    };

    const handleChangeTopic = (e: any, { value }: DropdownProps) => {
        setTopic(value);
        getSubTopicAll(Number(value));
    };

    const handleChangeSubTopic = (e: any, { value }: DropdownProps) => {
        setSubTopic(value);
    };

    return (
        <Fragment>
            <CustomModal icon='list alternate outline' header='หัวข้อย่อย' open={props.open}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>ชื่อหัวข้อ</label>
                                <Form.Field>
                                    <Dropdown
                                        selection
                                        fluid
                                        name='topic_name'
                                        options={topic_option}
                                        value={topic}
                                        onChange={handleChangeTopic}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>ชื่อหัวข้อ</label>
                                <Form.Field>
                                    <Dropdown
                                        selection
                                        fluid
                                        disabled={!topic}
                                        name='sub_topic_name'
                                        options={sub_topic_option}
                                        value={sub_topic}
                                        onChange={handleChangeSubTopic}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>ชื่อเนื้อหา</label>
                                <Form.Field>
                                    <Input
                                        fluid
                                        name='content_name'
                                        value={content_name}
                                        onChange={handleChangeContentName}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <Button
                                    style={{ backgroundColor: '#425679', color: '#ffffff', marginRight: 15 }}
                                    onClick={handleClickSelectFile}>
                                    เลือกไฟล์
                                </Button>
                                <label className='header-input-form'>เฉพาะสกุลไฟล์ *.pdf</label>
                                <Input>
                                    <input
                                        type='file'
                                        id='file'
                                        hidden
                                        onChange={handleChangeFile}
                                        name='file_sub_topic'
                                        // label='เพิ่มรูปถ่าย'
                                        accept='application/pdf'
                                    />
                                </Input>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>
                        {file && (
                            <Grid.Row>
                                <Grid.Column computer='3' only='computer' />
                                <Grid.Column computer='10' style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            padding: '10px 3px',
                                            borderRadius: 8,
                                            backgroundColor: '#4D648D',
                                            marginRight: 5,
                                        }}
                                    />
                                    <a href={URL.createObjectURL(file)} target={'_blank'} rel='noreferrer'>
                                        <Icon name='file alternate' size='large' style={{ color: '#D85F53' }} />
                                        <label
                                            style={{
                                                color: '#283655',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            }}>
                                            {file?.name}
                                        </label>
                                    </a>
                                </Grid.Column>
                                <Grid.Column computer='3' only='computer' />
                            </Grid.Row>
                        )}

                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}>
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}>
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    );
};

export default UserManualContentModalForm;
