import React, { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Form, Grid, Input, InputOnChangeData, Icon } from 'semantic-ui-react'
import { InterfaceDistrictsAndCommunities } from '.'
import CustomModal from '../../../../../components/CustomModal'
import { POST_AREAS_AND_COUMMUNITIES } from '../../../../../config/api/areasAndCommunities'
import { axiosAuthen } from '../../../../../config/axiosAuthen'

type FormProps = {
    data: InterfaceDistrictsAndCommunities
    open?: boolean
    onClose?: () => void
}

export interface InterfaceCommunities {
    areas_id: number
    id: string
    name: string
    updated_at?: string
    updated_by?: number
    errors_community_name: boolean
}

const EditeDistrictsAndCommunities: React.FC<FormProps> = (props) => {
    let { data } = props
    let municipalities_id = localStorage.municipality_id
    let location = useLocation()
    let { state } = location
    if (state) {
        let location_state = JSON.parse(JSON.stringify(state, null, ' '))
        let { municipality_id } = location_state
        if (municipality_id) municipalities_id = municipality_id
    }
    let [id, setId] = useState<number | undefined>()
    let [area, setArea] = useState<string | undefined>()
    let [list_communities, setListCommunities] = useState<any[]>([])
    let [errors_area, setErrorsArea] = useState<boolean>(false)
    let [option] = useState<any[]>([])

    const validateForm = () => {
        let error = false

        if (!area) {
            error = true
            setErrorsArea(true)
        }

        list_communities.forEach((data, index) => {
            if (!data.name) {
                let form = list_communities
                error = true
                form[index].errors_community_name = true

                setListCommunities([...form])
            }
        })

        return error
    }

    useEffect(() => {
        setArea(data?.name)
        setId(data?.id)
        setListCommunities(data.communities)
        data?.communities.forEach((data: any) =>
            option.push({ id: data.id, areas_id: data.areas_id, community_name: data.name })
        )
    }, [data.communities, data?.id, data?.name, option])

    const handleChangeCommunities = (value: string, index: number) => {
        let form = list_communities
        if (form) {
            form[index].name = value
            form[index].errors_community_name = false
            setListCommunities([...form])
        }
    }

    const handleChangeArea = (e: any, { value }: InputOnChangeData) => {
        setArea(value)
        setErrorsArea(false)
    }

    const hanldeRemoveArea = (index: number, data: InterfaceCommunities) => {
        setListCommunities(list_communities.filter((select: any, index_data: number) => select !== data))
    }

    const handleAddCommuni = (e: any, { value }: any) => {
        var data = {
            areas_id: id,
            id: 0,
            name: '',
            errors_community_name: false,
        }

        setListCommunities([...list_communities, data])
    }

    const handleSubmit = () => {
        if (!validateForm()) {
            let body = {
                communities: list_communities.map((data) => ({
                    areas_id: data.areas_id,
                    id: data.id,
                    name: data.name,
                })),
                municipality_id: Number(municipalities_id),
                name: area,
                id: data.id,
            }
            axiosAuthen.post(POST_AREAS_AND_COUMMUNITIES, body).then((response) => {
                handleClose()
            })
        }
    }

    const handleClose = () => {
        props.onClose && props.onClose()
        setErrorsArea(false)
    }

    return (
        <Fragment>
            <CustomModal icon='users' header='เพิ่มชุมชน' open={props.open}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>เขต</label>
                                <Form.Field error={errors_area}>
                                    <Input fluid name='area' value={area} onChange={handleChangeArea} />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>

                        {list_communities.map((data: InterfaceCommunities, index: number) => (
                            <Grid.Row key={data.id}>
                                <Grid.Column computer='3' only='computer' />
                                <Grid.Column computer='8' mobile='16'>
                                    <label className='header-input-form'>ชุมชน</label>
                                    <Form.Field error={data.errors_community_name}>
                                        <Input
                                            fluid
                                            onChange={(_: any, { value }: InputOnChangeData) => {
                                                handleChangeCommunities(value, index)
                                            }}
                                            value={data.name}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer='2' mobile='16'>
                                    <br />
                                    <Button
                                        basic
                                        color='red'
                                        icon='trash alternate'
                                        onClick={() => hanldeRemoveArea(index, data)}
                                    />
                                </Grid.Column>
                                <Grid.Column computer='3' only='computer' />
                            </Grid.Row>
                        ))}

                        {/* ปุ่มเพิ่มชุมชน value */}
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <Button fluid basic color='blue' onClick={handleAddCommuni}>
                                    <Icon
                                        circular
                                        size='small'
                                        color='blue'
                                        name='plus'
                                        style={{ boxShadow: '0px 0px 0px 1px #1F7EC7' }}
                                    />
                                    เพิ่มชุมชน
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>

                        {/* ปุ่มบันทึกและยกเลิก value */}
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default EditeDistrictsAndCommunities
