import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb, Button, Container, Dimmer, Grid, Icon, Loader } from 'semantic-ui-react'
import CustomStep from '../../../../components/CustomStep'
import { getAuthenMenuList, getComplaintFormStep1, getComplaintFormStep2, getComplaintFormStep3 } from '../../../../store/selectors/index'
import './styles.css'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import { useEffect } from 'react'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import {
    GET_COMPLAINT_CHECK_ASSIGN_TECHNICIAN,
    GET_COMPLAINT_TECHNICIAN_MATCH_DIRECTORY,
    POST_COMPLAINT_CREATE_AND_EDIT,
} from '../../../../config/api/complaint'
import { GET_COMPLAINT_TOPIC_AND_TYPES_ALL } from '../../../../config/api/complaintTopicAndTypes'
import { GET_AREAS_AND_COUMMUNITIES_ALL } from '../../../../config/api/areasAndCommunities'
import { resetFormByReducerName, setStateByNameAndValue } from '../../../../store/actions'
import { GET_CONTACT_ALL } from '../../../../config/api/contacts'
import { GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE } from '../../../../config/api/directoryOfMunicipality'
import { useHistory, useLocation } from 'react-router-dom'
import { alert } from '../../../../components/alert'
import axios from 'axios'
import { GET_MUNICIPALITY_PERSON_TYPES_BY_MUNICIPALITY_ID } from '../../../../config/api/municipality'
import currentFiscalYear from '../../../../functions/CurrentFiscalyear'

const Form: React.FC = () => {
    const { menu_list } = useSelector(getAuthenMenuList)
    let complaint_form = menu_list
        ?.find((menu) => menu.name_th === 'รายการคำร้องทุกข์')
        ?.pages?.find((page) => page.name_th === 'แบบฟอร์มเพิ่มคำร้อง')?.actions

    const step1 = useSelector(getComplaintFormStep1)
    const step2 = useSelector(getComplaintFormStep2)
    const step3 = useSelector(getComplaintFormStep3)
    const [activeStep, setActiveStep] = useState<number>(1)
    const [field, setField] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [assign_technician, setAssignTechnician] = useState<boolean>(false)
    let [person_type, setPersonTypes] = useState<number>(1)

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const recieve_status = location.state

    useEffect(() => {
        const getAssignTechnician = () => {
            axiosAuthen.get(`${GET_COMPLAINT_CHECK_ASSIGN_TECHNICIAN}`).then((response) => {
                let { value } = response.data
                setAssignTechnician(value)
            })
        }
        const getPersonType = () => {
            axios.get(`${GET_MUNICIPALITY_PERSON_TYPES_BY_MUNICIPALITY_ID}/${localStorage.municipality_id}`).then((response) => {
                let { value } = response.data
                value && setPersonTypes(value.id)
            })
        }
        getPersonType()
        getAssignTechnician()
    }, [])

    const handleGoBack = () => {
        setActiveStep(activeStep - 1)
    }

    const handleForward = () => {
        if (!field.includes('position map') && activeStep === 2) {
            setActiveStep(activeStep + 2)
        } else {
            setActiveStep(activeStep + 1)
        }
    }

    useEffect(() => {
        if (complaint_form) setField(complaint_form.map((data) => String(data.name_en)))
    }, [complaint_form])
    useEffect(() => {
        setLoading(true)
        let element = document.getElementById('pusher')
        element?.scrollTo({ top: 0, behavior: 'smooth' })
        setLoading(false)
    }, [activeStep])

    useEffect(() => {
        if (step1.complaint_topic.value) {
            let type_option = step1.list_complaint_type.filter((type) => Number(type.parent) === Number(step1.complaint_topic.value))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', `complaint_type_option`, type_option))
            dispatch(setStateByNameAndValue('ComplaintFormStep1', `year`, currentFiscalYear()))
        }
    }, [dispatch, step1.complaint_topic, step1.list_complaint_type])

    useEffect(() => {
        const getComplaintTopicAndType = () => {
            axiosAuthen.get(GET_COMPLAINT_TOPIC_AND_TYPES_ALL).then((response) => {
                let { value } = response.data
                let topic_option: any[] = []
                let type_option: any[] = []
                value.forEach((topic: any) => {
                    topic_option.push({
                        value: topic.id,
                        text: topic.name,
                        short_name: topic.short_name,
                    })
                    topic.complaint_types.forEach((type: any) => {
                        type_option.push({
                            value: type.id,
                            text: type.name,
                            parent: topic.id,
                        })
                    })
                })

                dispatch(setStateByNameAndValue('ComplaintFormStep1', `complaint_topic_option`, topic_option))
                dispatch(setStateByNameAndValue('ComplaintFormStep1', `complaint_type_option`, type_option))
                dispatch(setStateByNameAndValue('ComplaintFormStep1', `list_complaint_type`, type_option))
            })
        }

        const getAreaAndCommunity = () => {
            axiosAuthen.get(GET_AREAS_AND_COUMMUNITIES_ALL).then((response) => {
                let { value } = response.data
                let area_option: any[] = []
                let community_option: any[] = []
                value.forEach((area: any) => {
                    area_option.push({ value: area.id, text: area.name })
                    area.communities.forEach((communities: any) => {
                        community_option.push({
                            value: communities.id,
                            text: communities.name,
                            parent: area.id,
                        })
                    })
                })
                dispatch(setStateByNameAndValue('ComplaintFormStep1', `area_option`, area_option))
                dispatch(setStateByNameAndValue('ComplaintFormStep1', `community_option`, community_option))
                dispatch(setStateByNameAndValue('ComplaintFormStep1', `list_community`, community_option))
            })
        }

        const getContact = () => {
            axiosAuthen.get(GET_CONTACT_ALL).then((response) => {
                let { value } = response.data
                let contact_option = value.map((data: any) => ({ value: data.id, text: data.name }))
                dispatch(setStateByNameAndValue('ComplaintFormStep1', `contact_option`, contact_option))
            })
        }

        const getDirectory = () => {
            axiosAuthen.get(`${GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE}`).then((response) => {
                let { value } = response.data
                let directory_option = value.map((data: any) => ({
                    value: data.id,
                    text: data.name,
                    level: data.level,
                }))
                if (value.length === 0) {
                    axiosAuthen
                        .get(`${GET_COMPLAINT_TECHNICIAN_MATCH_DIRECTORY}/${localStorage.getItem('directory_of_municipalities_id')}`)
                        .then((response) => {
                            let { value } = response.data
                            let technician_option = value.map((data: any) => ({
                                value: data.id,
                                text: data.full_name_th,
                            }))
                            dispatch(
                                setStateByNameAndValue('ComplaintFormStep1', 'directory', {
                                    value: localStorage.getItem('directory_of_municipalities_id'),
                                    text: localStorage.getItem('directory_of_municipalities_name'),
                                    level: 4,
                                })
                            )
                            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'technician_option', technician_option))

                            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'technician', technician_option[0] || { value: '', text: '' }))
                            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'technician_error', false))
                        })
                }

                dispatch(setStateByNameAndValue('ComplaintFormStep1', `directory_option`, [directory_option]))
            })
        }

        getComplaintTopicAndType()
        getAreaAndCommunity()
        getContact()
        getDirectory()
    }, [dispatch])

    const validationStep1 = async () => {
        // await waitingSetPropsStep1();
        let error = false
        if (field.includes('topic and type')) {
            if (!step1.complaint_topic.value) {
                dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_topic_error', true))
                error = true
            }
            if (!step1.complaint_type.value) {
                dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_type_error', true))
                error = true
            }
        }

        if (field.includes('complaint subject') && !step1.complaint_subject) {
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_subject_error', true))
            error = true
        }
        if (field.includes('complaint detail') && !step1.complaint_detail) {
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_detail_error', true))
            error = true
        }
        if (field.includes('technician') && !step1.technician.value && (step1.directory_option.length === 0 || assign_technician)) {
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'technician_error', true))
            error = true
        }
        if (field.includes('complaint date') && !step1.complaint_date) {
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'complaint_date_error', true))
            error = true
        }
        if (field.includes('complaint no') && !step1.central_complaint_no) {
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'central_complaint_no_error', true))
            error = true
        }
        if (field.includes('directory') && !step1.directory[step1.directory.length - 1]) {
            dispatch(setStateByNameAndValue('ComplaintFormStep1', 'directory_error', true))
            error = true
        }

        if (!recieve_status) {
            if (field.includes('complaint file') && step1.file_complaint.length === 0) {
                dispatch(setStateByNameAndValue('ComplaintFormStep1', 'file_complaint_error', true))
                error = true
            }
        }

        return error
    }

    const waitingSetPropsStep1 = () => {
        return new Promise((resolve, reject) => {
            setActiveStep(1)
            setTimeout(() => {
                resolve('finish')
            }, 500)
        })
    }
    const validationStep2 = async () => {
        // await waitingSetPropsStep2();
        let error = false
        if (field.includes('detail person')) {
            if (!step2.prefix) {
                dispatch(setStateByNameAndValue('ComplaintFormStep2', 'prefix_error', true))
                error = true
            }
            if (!step2.first_name) {
                dispatch(setStateByNameAndValue('ComplaintFormStep2', 'first_name_error', true))
                error = true
            }
            if (person_type !== 2 && !step2.last_name) {
                dispatch(setStateByNameAndValue('ComplaintFormStep2', 'last_name_error', true))
                error = true
            }

            if (!step2.mobile_number || step2.mobile_number.length !== 10) {
                dispatch(setStateByNameAndValue('ComplaintFormStep2', 'mobile_number_error', true))
                error = true
            }
        }
        if (field.includes('address person')) {
            if (!step2.house_no) {
                dispatch(setStateByNameAndValue('ComplaintFormStep2', 'house_no_error', true))
                error = true
            }
            if (!step2.village_no) {
                dispatch(setStateByNameAndValue('ComplaintFormStep2', 'village_no_error', true))
                error = true
            }
            if (!step2.sub_district) {
                dispatch(setStateByNameAndValue('ComplaintFormStep2', 'sub_district_error', true))
                error = true
            }
            if (!step2.district) {
                dispatch(setStateByNameAndValue('ComplaintFormStep2', 'district_error', true))
                error = true
            }
            if (!step2.province) {
                dispatch(setStateByNameAndValue('ComplaintFormStep2', 'province_error', true))
                error = true
            }
            if (!step2.zipcode) {
                dispatch(setStateByNameAndValue('ComplaintFormStep2', 'zipcode_error', true))
                error = true
            }
        }

        return error
    }

    const waitingSetPropsStep2 = () => {
        return new Promise((resolve, reject) => {
            setActiveStep(2)
            setTimeout(() => {
                resolve('finish')
            }, 500)
        })
    }
    const validationStep3 = async () => {
        // await waitingSetPropsStep3();
        let error = false
        if (field.includes('position map')) {
            if (!step3.position.lat) {
                dispatch(setStateByNameAndValue('ComplaintFormStep3', 'position_error', true))
                error = true
            }
            if (!step3.position.lng) {
                dispatch(setStateByNameAndValue('ComplaintFormStep3', 'position_error', true))
                error = true
            }
        }

        return error
    }

    const waitingSetPropsStep3 = () => {
        return new Promise((resolve, reject) => {
            setActiveStep(3)
            setTimeout(() => {
                resolve('finish')
            }, 500)
        })
    }

    const validation = async () => {
        let error = false
        let error_step3 = await validationStep3()
        let error_step2 = await validationStep2()
        let error_step1 = await validationStep1()
        if (error_step1) {
            await waitingSetPropsStep1()
        } else if (error_step2) {
            await waitingSetPropsStep2()
        } else if (error_step3) {
            await waitingSetPropsStep3()
        }

        error = error_step1 || error_step2 || error_step3

        return error
    }

    const handleSubmit = async () => {
        setLoading(true)
        if (!(await validation())) {
            let form_data = new FormData()
            form_data.append('pre_complaint_id', step1.pre_complaint_id || '')
            form_data.append('id', step1.id)
            form_data.append('complaint_topics_id', step1.complaint_topic.value)
            form_data.append('complaint_types_id', step1.complaint_type.value)
            form_data.append('complaint_subject', step1.complaint_subject)
            form_data.append('areas_id', step1.area.value)
            form_data.append('communities_id', step1.community.value)
            form_data.append('complaint_detail', step1.complaint_detail)
            form_data.append('contact_id', step1.contact.value)
            form_data.append('users_id', step1.technician.value)
            step1.file_complaint.forEach((file) => {
                form_data.append('file_complaints', file)
            })
            form_data.append('central_complaint_no', step1.central_complaint_no)
            form_data.append('complaint_date', step1.complaint_date)
            form_data.append('year', step1.year)
            form_data.append('directory_of_municipalities_id', String(step1.directory[step1.directory.length - 1].value))
            form_data.append('prefix', step2.prefix)
            form_data.append('first_name', step2.first_name)
            form_data.append('last_name', step2.last_name)
            form_data.append('house_no', step2.house_no)
            form_data.append('village_no', step2.village_no)
            form_data.append('alley', step2.alley)
            form_data.append('road', step2.road)
            form_data.append('subdistrict', step2.sub_district)
            form_data.append('district', step2.district)
            form_data.append('province', step2.province)
            form_data.append('zipcode', step2.zipcode)
            form_data.append('phone_number', step2.phone_number)
            form_data.append('mobile_number', step2.mobile_number)
            form_data.append('email', step2.email)
            form_data.append('lat', String(step3.position.lat))
            form_data.append('lng', String(step3.position.lng))
            axiosAuthen
                .post(POST_COMPLAINT_CREATE_AND_EDIT, form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    setLoading(false)
                    alert({
                        type: 'success',
                        title: 'บันทึกเสร็จสิ้น',
                        timer: 1500,
                        onClosed: () => {
                            dispatch(resetFormByReducerName('ComplaintFormStep1'))
                            dispatch(resetFormByReducerName('ComplaintFormStep2'))
                            dispatch(resetFormByReducerName('ComplaintFormStep3'))
                            history.push(`/${localStorage.getItem('permission_en')}/complaint`)
                        },
                    })
                })
                .catch((error) => {
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }
    }

    return (
        <Fragment>
            <div id="background-container" className="background-container">
                <Container>
                    <Dimmer active={loading} inverted page>
                        <Loader size="large">รอสักครู่</Loader>
                    </Dimmer>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/complaint`)
                                        }}
                                    >
                                        รายการคำร้อง
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>เพิ่มคำร้อง</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 style={{ textAlign: 'center' }}>เพิ่มคำร้อง</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <CustomStep activeStep={activeStep}>
                                    <CustomStep.Step
                                        icon="file alternate"
                                        label="เรื่องร้องทุกข์"
                                        size="big"
                                        onClick={() => {
                                            setActiveStep(1)
                                        }}
                                    />
                                    {(field.includes('detail person') || field.includes('address person')) && (
                                        <CustomStep.Step
                                            icon="info circle"
                                            label="ข้อมูลผู้ร้องทุกข์"
                                            size="big"
                                            onClick={() => {
                                                setActiveStep(2)
                                            }}
                                        />
                                    )}
                                    {field.includes('position map') && (
                                        <CustomStep.Step
                                            icon="map"
                                            label="ระบุตำแหน่งที่ตั้ง"
                                            size="big"
                                            onClick={() => {
                                                setActiveStep(3)
                                            }}
                                        />
                                    )}
                                    <CustomStep.Step
                                        icon="check"
                                        label="ตรวจสอบข้อมูล"
                                        size="big"
                                        onClick={() => {
                                            setActiveStep(4)
                                        }}
                                    />
                                </CustomStep>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {activeStep === 1 && <Step1 data={step1} field={field} />}
                                {activeStep === 2 && <Step2 data={step2} field={field} />}
                                {activeStep === 3 && <Step3 data={step3} field={field} />}
                                {activeStep === 4 && <Step4 data={{ ...step1, ...step2, ...step3 }} field={field} />}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{ marginTop: 10 }}>
                                {activeStep > 1 && activeStep <= 4 && (
                                    <Button type="button" floated="left" primary icon onClick={handleGoBack}>
                                        <Icon name="arrow left" /> ก่อนหน้า
                                    </Button>
                                )}
                                {activeStep < 4 && (
                                    <Button type="button" floated="right" primary icon onClick={handleForward}>
                                        ถัดไป <Icon name="arrow right" />
                                    </Button>
                                )}
                                {activeStep === 4 && (
                                    <Button
                                        floated="right"
                                        color="green"
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                    >
                                        บันทึก
                                    </Button>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default Form
