import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Icon, Image, Menu } from 'semantic-ui-react'
import Logo from '../../assets/images/main page/logo ogs.png'
import { getAuthenMenuList } from '../../store/selectors'

const MenuList: React.FC = () => {
    let history = useHistory()
    let { menu_list } = useSelector(getAuthenMenuList)

    let menu = menu_list?.map((menu) => menu.name_en)

    return (
        <Fragment>
            <Menu.Item>
                <Menu.Header style={{ margin: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Image src={Logo} size='mini' style={{ marginTop: 5 }} />
                        <label style={{ flexGrow: 1, cursor: 'pointer' }}> {localStorage.permission_th}</label>
                    </div>
                </Menu.Header>
            </Menu.Item>
            <Menu.Item>
                <Menu.Header>คำร้องทุกข์</Menu.Header>
                <Menu.Menu>
                    {menu?.some((menu_detail) => menu_detail === 'dashboard') && (
                        <Menu.Item
                            name='dashboard'
                            onClick={() => {
                                history.push(`/${localStorage.permission_en}/dashboard`)
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Icon name='block layout' />
                                <label style={{ cursor: 'pointer', marginLeft: 5 }}> แดชบอร์ด</label>
                            </div>
                        </Menu.Item>
                    )}

                    {menu?.some((menu_detail) => menu_detail === 'complaint') && (
                        <Menu.Item
                            name='complaint'
                            onClick={() => {
                                history.push(`/${localStorage.permission_en}/complaint`)
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Icon name='list' />
                                <label style={{ cursor: 'pointer', marginLeft: 5 }}> รายการคำร้องทุกข์</label>
                            </div>
                        </Menu.Item>
                    )}
                    {menu?.some((menu_detail) => menu_detail === 'comment') && (
                        <Menu.Item
                            name='complaint-comment'
                            onClick={() => {
                                history.push(`/${localStorage.permission_en}/complaint/comment`)
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Icon name='list' />
                                <label style={{ cursor: 'pointer', marginLeft: 5 }}> รายการแสดงความคิดเห็น</label>
                            </div>
                        </Menu.Item>
                    )}
                    {menu?.some((menu_detail) => menu_detail === 'map') && (
                        <Menu.Item
                            name='map'
                            onClick={() => {
                                history.push(`/${localStorage.permission_en}/map`)
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Icon name='map marker alternate' />
                                <label style={{ cursor: 'pointer', marginLeft: 5 }}> แผนที่คำร้องทุกข์</label>
                            </div>
                        </Menu.Item>
                    )}

                    <Menu.Item
                        as='a'
                        href='https://forms.gle/Ukk352JUPBMsQw7FA'
                        target={'_blank'}
                        rel='noopener noreferrer'
                        name='defect'
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name='question' />
                            <label style={{ cursor: 'pointer', marginLeft: 5 }}> แจ้งปัญหา</label>
                        </div>
                    </Menu.Item>
                    {menu?.some((menu_detail) => menu_detail === 'user-manual') && (
                        <Menu.Item
                            name='user-manual'
                            onClick={() => {
                                history.push(`/${localStorage.permission_en}/user-manual`)
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Icon name='book' />
                                <label style={{ cursor: 'pointer', marginLeft: 5 }}> คู่มือการใช้งาน</label>
                            </div>
                        </Menu.Item>
                    )}
                </Menu.Menu>
            </Menu.Item>
        </Fragment>
    )
}

export default MenuList
