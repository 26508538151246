import React, { Fragment, useEffect, useState } from 'react'
import {
    Button,
    Checkbox,
    Dimmer,
    Dropdown,
    DropdownOnSearchChangeData,
    DropdownProps,
    Form,
    Grid,
    Image,
    Input,
    InputOnChangeData,
    Loader,
    Modal,
    Radio,
    Segment,
} from 'semantic-ui-react'
import CustomSegment from '../../../../components/CustomSegment'
import IconMan from '../../../../assets/images/man.png'
import IconWoman from '../../../../assets/images/woman.png'
import IconWoman3 from '../../../../assets/images/woman1.png'
import ThaiAddress from '../../../../constants/ThaiAddress.json'
import { getLIFF } from '../../../../config/liff'
import { useLocation } from 'react-router-dom'
import { POST_NOTIFICATION_LINE_REGISTER_PERSON } from '../../../../config/api/notification'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import { alert } from '../../../../components/alert'
import { GET_MUNICIPALITY_PERSON_TYPES_BY_MUNICIPALITY_ID } from '../../../../config/api/municipality'
import axios from 'axios'

const RegisterPersonForm: React.FC = () => {
    let location = useLocation()
    let [prefix_th, setPrefixTh] = useState<string>('')
    let [prefix_th_error, setPrefixThError] = useState<boolean>(false)
    let [first_name_th, setFirstNameTh] = useState<string>('')
    let [first_name_th_error, setFirstNameThError] = useState<boolean>(false)
    let [last_name_th, setLastNameTh] = useState<string>('')
    let [last_name_th_error, setLastNameThError] = useState<boolean>(false)
    let [phone_number, setPhoneNumber] = useState<string>('')
    let [phone_number_error, setPhoneNumberError] = useState<boolean>(false)
    let [house_no, setHouseNo] = useState<string>('')
    let [house_no_error, setHouseNoError] = useState<boolean>(false)
    let [village_no, setVillageNo] = useState<string>('')
    let [village_no_error, setVillageNoError] = useState<boolean>(false)
    let [alley, setAlley] = useState<string>('')
    let [alley_error, setAlleyError] = useState<boolean>(false)
    let [road, setRoad] = useState<string>('')
    let [road_error, setRoadError] = useState<boolean>(false)
    let [sub_district, setSubDistrict] = useState<string>('')
    let [sub_district_error, setSubDistrictError] = useState<boolean>(false)
    let [district, setDistrict] = useState<string>('')
    let [district_error, setDistrictError] = useState<boolean>(false)
    let [province, setProvince] = useState<string>('')
    let [province_error, setProvinceError] = useState<boolean>(false)
    let [zipcode, setZipcode] = useState<string>('')
    let [zipcode_error, setZipcodeError] = useState<boolean>(false)
    let [accept_term, setAcceptTerm] = useState<boolean>(false)
    let [show_terms, setShowTerms] = useState<boolean>(false)
    let [sub_district_option, setSubDistrictOption] = useState<{ value: string; text: string }[]>([])
    let [district_option, setDistrictOption] = useState<{ value: string; text: string }[]>([])
    let [province_option, setProvinceOption] = useState<{ value: string; text: string }[]>([])
    let [zipcode_option, setZipcodeOption] = useState<{ value: string; text: string }[]>([])
    let thai_address = ThaiAddress
    let [line_user_id, setLineUserId] = useState<string>('')
    let [liff, setLiff] = useState<any>()
    let [loading, setLoading] = useState<boolean>(false)
    let [loading_form, setLoadingForm] = useState<boolean>(false)
    let [municipality_id, setMunicipalityId] = useState<string>('')
    let [liff_id, setLiffId] = useState<string>('')
    let [disabled_button_save, setDisabledButtonSave] = useState<boolean>(true)
    let [person_type, setPersonTypes] = useState<number>(0)

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const municipality_id = query.get('municipality_id')
        municipality_id && setMunicipalityId(municipality_id)
        const liff_id = query.get('liff_id')
        liff_id && setLiffId(liff_id)
        const getPersonType = () => {
            axios.get(`${GET_MUNICIPALITY_PERSON_TYPES_BY_MUNICIPALITY_ID}/${municipality_id}`).then((response) => {
                let { value } = response.data
                setPersonTypes(value.id)
            })
        }
        getPersonType()
    }, [location])

    useEffect(() => {
        const connectLIFF = () => {
            setLoading(true)
            getLIFF().then((liff) => {
                liff.init({
                    liffId: liff_id,
                })
                    .then(() => {
                        if (liff.isLoggedIn()) {
                            liff.getProfile()
                                .then((profile: any) => {
                                    setLiff(liff)
                                    setLoading(false)
                                    setLineUserId(profile.userId)
                                    setFirstNameTh(profile.displayName)
                                })
                                .catch((err: any) => {
                                    alert({
                                        type: 'error',
                                        title: `cann't get profile`,
                                        timer: 1500,
                                        cancelText: 'ปิด',
                                    })
                                })
                        } else {
                            alert({
                                type: 'error',
                                title: `cann't connect login: ${liff_id}`,
                                cancelText: 'ปิด',
                            })
                            liff.login()
                        }
                    })
                    .catch((error: any) => {
                        alert({
                            type: 'error',
                            title: `cann't connect line: ${liff_id}`,
                            text: `${error.code}`,
                        })
                        liff.login()
                    })
            })
        }

        liff_id && connectLIFF()
    }, [liff_id])

    const handleChangeFirstNameTh = (_: any, { value }: InputOnChangeData) => {
        setFirstNameTh(value)
        setFirstNameThError(false)
    }
    const handleChangeLastNameTh = (_: any, { value }: InputOnChangeData) => {
        setLastNameTh(value)
        setLastNameThError(false)
    }

    const handleChangePrefix = (prefix_th: string) => {
        setPrefixTh(prefix_th)
        setPrefixThError(false)
    }

    const handleChangePhoneNumber = (event: any, { value }: InputOnChangeData) => {
        if (!isNaN(Number(value))) {
            setPhoneNumber(value)
            setPhoneNumberError(false)
        }
    }

    const handleChangeHouseNo = (_: any, { value }: InputOnChangeData) => {
        setHouseNo(value)
        setHouseNoError(false)
    }
    const handleChangeVillageNo = (_: any, { value }: InputOnChangeData) => {
        setVillageNo(value)
        setVillageNoError(false)
    }

    const handleChangeAlley = (_: any, { value }: InputOnChangeData) => {
        setAlley(value)
        setAlleyError(false)
    }

    const handleChangeRoad = (_: any, { value }: InputOnChangeData) => {
        setRoad(value)
        setRoadError(false)
    }

    const handleChangeAcceptTerm = () => {
        setAcceptTerm(!accept_term)
        setDisabledButtonSave(!disabled_button_save)
    }

    const handleChangeShowTerms = () => {
        setShowTerms(!show_terms)
    }

    const searchSubDistrict = (data: DropdownOnSearchChangeData) => {
        if (data.searchQuery) {
            let option: { value: string; text: string }[] = []
            let row = 0
            thai_address.forEach((value) => {
                if (value.sub_district.includes(data.searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode

                        option.push({
                            text: text_address,
                            value: text_address,
                        })

                        row++
                    }
                }
            })
            setSubDistrictOption(option)
        }
    }

    const searchDistrict = (data: DropdownOnSearchChangeData) => {
        if (data.searchQuery) {
            let option: { value: string; text: string }[] = []
            let row = 0
            thai_address.forEach((value) => {
                if (value.district.includes(data.searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode

                        option.push({
                            text: text_address,
                            value: text_address,
                        })

                        row++
                    }
                }
            })
            setDistrictOption(option)
        }
    }

    const searchProvince = (data: DropdownOnSearchChangeData) => {
        if (data.searchQuery) {
            let option: { value: string; text: string }[] = []
            let row = 0
            thai_address.forEach((value) => {
                if (value.province.includes(data.searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode

                        option.push({
                            text: text_address,
                            value: text_address,
                        })

                        row++
                    }
                }
            })
            setProvinceOption(option)
        }
    }

    const searchZipcode = (data: DropdownOnSearchChangeData) => {
        if (data.searchQuery) {
            let option: { value: string; text: string }[] = []
            let row = 0
            thai_address.forEach((value) => {
                if (String(value.zipcode).includes(data.searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode

                        option.push({
                            text: text_address,
                            value: text_address,
                        })

                        row++
                    }
                }
            })
            setZipcodeOption(option)
        }
    }

    const handleChangeAddress = (_: any, { value }: DropdownProps) => {
        let address = String(value).split(' >> ')
        setSubDistrict(address[0])
        setDistrict(address[1])
        setProvince(address[2])
        setZipcode(address[3])
        setSubDistrictOption([{ value: address[0], text: address[0] }])
        setDistrictOption([{ value: address[1], text: address[1] }])
        setProvinceOption([{ value: address[2], text: address[2] }])
        setZipcodeOption([{ value: address[3], text: address[3] }])
        setSubDistrictError(false)
        setDistrictError(false)
        setProvinceError(false)
        setZipcodeError(false)
    }

    const validateForm = () => {
        let error = false

        if (!last_name_th) {
            error = true
            setLastNameThError(true)
        }

        if (!first_name_th) {
            error = true
            setFirstNameThError(true)
        }

        if (!prefix_th) {
            error = true
            setPrefixThError(true)
        }

        if (!phone_number) {
            error = true
            setPhoneNumberError(true)
        }

        if (!house_no) {
            error = true
            setHouseNoError(true)
        }

        if (!village_no) {
            error = true
            setVillageNoError(true)
        }

        if (!sub_district) {
            error = true
            setSubDistrictError(true)
        }

        if (!district) {
            error = true
            setDistrictError(true)
        }

        if (!province) {
            error = true
            setProvinceError(true)
        }

        if (!zipcode) {
            error = true
            setZipcodeError(true)
        }

        if (!line_user_id) {
            error = true
            setLoading(false)
        }

        return error
    }

    const handleSubmit = () => {
        setLoadingForm(true)
        if (person_type === 1) {
            if (!validateForm()) {
                let form_data = new FormData()

                form_data.append('prefix_th', prefix_th)
                form_data.append('first_name_th', first_name_th)
                form_data.append('last_name_th', last_name_th)
                form_data.append('phone_number', phone_number)
                form_data.append('house_no', house_no)
                form_data.append('village_no', village_no)
                form_data.append('alley', alley)
                form_data.append('road', road)
                form_data.append('sub_district', sub_district)
                form_data.append('district', district)
                form_data.append('province', province)
                form_data.append('zipcode', zipcode)
                form_data.append('municipality_id', municipality_id)
                form_data.append('line_user_id', line_user_id)
                // POST_REGISTER_LINE

                axiosAuthen
                    .post(POST_NOTIFICATION_LINE_REGISTER_PERSON, form_data)
                    .then((response) => {
                        alert({
                            type: 'success',
                            title: 'บันทึกเสร็จสิ้น',
                            timer: 1500,
                            onClosed: () => {
                                setLoadingForm(false)
                                if (line_user_id) {
                                    liff.closeWindow()
                                }
                            },
                        })
                    })
                    .catch((error) => {
                        setLoadingForm(false)
                        if (line_user_id) {
                            liff.closeWindow()
                        }

                        if (error.response) {
                            alert({
                                type: 'error',
                                title: error.response.data.message,
                                cancelText: 'ปิด',
                            })
                        }
                    })
            } else {
                setLoadingForm(false)
            }
        } else if (person_type === 2) {
            let form_data = new FormData()

            form_data.append('prefix_th', 'นาย')
            form_data.append('first_name_th', first_name_th)
            form_data.append('last_name_th', '')
            form_data.append('phone_number', phone_number)
            form_data.append('house_no', house_no)
            form_data.append('village_no', village_no)
            form_data.append('alley', alley)
            form_data.append('road', road)
            form_data.append('sub_district', sub_district)
            form_data.append('district', district)
            form_data.append('province', province)
            form_data.append('zipcode', zipcode)
            form_data.append('municipality_id', municipality_id)
            form_data.append('line_user_id', line_user_id)
            // POST_REGISTER_LINE

            axiosAuthen
                .post(POST_NOTIFICATION_LINE_REGISTER_PERSON, form_data)
                .then((response) => {
                    alert({
                        type: 'success',
                        title: 'บันทึกเสร็จสิ้น',
                        timer: 1500,
                        onClosed: () => {
                            setLoadingForm(false)
                            if (line_user_id) {
                                liff.closeWindow()
                            }
                        },
                    })
                })
                .catch((error) => {
                    setLoadingForm(false)
                    if (line_user_id) {
                        liff.closeWindow()
                    }

                    if (error.response) {
                        alert({
                            type: 'error',
                            title: error.response.data.message,
                            cancelText: 'ปิด',
                        })
                    }
                })
        } else if (person_type === 3) {
            let form_data = new FormData()

            form_data.append('prefix_th', 'นาย')
            form_data.append('first_name_th', 'ไม่ระบุ')
            form_data.append('last_name_th', 'ไม่ระบุ')
            form_data.append('phone_number', phone_number)
            form_data.append('house_no', house_no)
            form_data.append('village_no', village_no)
            form_data.append('alley', alley)
            form_data.append('road', road)
            form_data.append('sub_district', sub_district)
            form_data.append('district', district)
            form_data.append('province', province)
            form_data.append('zipcode', zipcode)
            form_data.append('municipality_id', municipality_id)
            form_data.append('line_user_id', line_user_id)
            axiosAuthen
                .post(POST_NOTIFICATION_LINE_REGISTER_PERSON, form_data)
                .then((response) => {
                    alert({
                        type: 'success',
                        title: 'บันทึกเสร็จสิ้น',
                        timer: 1500,
                        onClosed: () => {
                            setLoadingForm(false)
                            if (line_user_id) {
                                liff.closeWindow()
                            }
                        },
                    })
                })
                .catch((error) => {
                    setLoadingForm(false)
                    if (line_user_id) {
                        liff.closeWindow()
                    }

                    if (error.response) {
                        alert({
                            type: 'error',
                            title: error.response.data.message,
                            cancelText: 'ปิด',
                        })
                    }
                })
        }
    }

    if (loading) {
        return (
            <Dimmer active inverted>
                <Loader inverted>รอสักครู่</Loader>
            </Dimmer>
        )
    } else {
        return (
            <Fragment>
                <Form loading={loading_form}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <h1>ลงทะเบียน</h1>
                    </div>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='1' only='computer' />
                            <Grid.Column computer='14' mobile='16'>
                                <CustomSegment icon='user circle' header='ข้อมูลผู้แจ้งคำร้อง'>
                                    <Grid>
                                        {person_type === 1 && (
                                            <Fragment>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='10'>
                                                        <label className='header-input-form'>คำนำหน้าชื่อ</label>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='10' mobile='16'>
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                                    <Segment
                                                                        className={
                                                                            prefix_th === 'นาย'
                                                                                ? 'selected-prefix'
                                                                                : prefix_th_error
                                                                                ? 'error-prefix'
                                                                                : 'unselected-prefix'
                                                                        }
                                                                        onClick={() => {
                                                                            handleChangePrefix('นาย')
                                                                        }}
                                                                    >
                                                                        <Radio checked={prefix_th === 'นาย'} />
                                                                        <Image
                                                                            src={IconMan}
                                                                            style={{ margin: 5, width: 56, height: 56 }}
                                                                        />
                                                                        <div style={{ margin: 5 }}>
                                                                            <div
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5,
                                                                                }}
                                                                            >
                                                                                นาย
                                                                            </div>
                                                                            <div style={{ color: '#4D648D' }}>Mr.</div>
                                                                        </div>
                                                                    </Segment>
                                                                </Grid.Column>
                                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                                    <Segment
                                                                        className={
                                                                            prefix_th === 'นาง'
                                                                                ? 'selected-prefix'
                                                                                : prefix_th_error
                                                                                ? 'error-prefix'
                                                                                : 'unselected-prefix'
                                                                        }
                                                                        onClick={() => {
                                                                            handleChangePrefix('นาง')
                                                                        }}
                                                                    >
                                                                        <Radio checked={prefix_th === 'นาง'} />
                                                                        <Image
                                                                            src={IconWoman3}
                                                                            style={{ margin: 5, width: 56, height: 56 }}
                                                                        />
                                                                        <div style={{ margin: 5 }}>
                                                                            <div
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5,
                                                                                }}
                                                                            >
                                                                                นาง
                                                                            </div>
                                                                            <div style={{ color: '#4D648D' }}>Mrs.</div>
                                                                        </div>
                                                                    </Segment>
                                                                </Grid.Column>
                                                                <Grid.Column computer='5' tablet='8' mobile='16'>
                                                                    <Segment
                                                                        className={
                                                                            prefix_th === 'นางสาว'
                                                                                ? 'selected-prefix'
                                                                                : prefix_th_error
                                                                                ? 'error-prefix'
                                                                                : 'unselected-prefix'
                                                                        }
                                                                        onClick={() => {
                                                                            handleChangePrefix('นางสาว')
                                                                        }}
                                                                    >
                                                                        <Radio checked={prefix_th === 'นางสาว'} />
                                                                        <Image
                                                                            src={IconWoman}
                                                                            style={{ margin: 5, width: 56, height: 56 }}
                                                                        />
                                                                        <div style={{ margin: 5 }}>
                                                                            <div
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5,
                                                                                }}
                                                                            >
                                                                                นางสาว
                                                                            </div>
                                                                            <div style={{ color: '#4D648D' }}>Ms.</div>
                                                                        </div>
                                                                    </Segment>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='16' mobile='16'>
                                                        <label className='header-input-form'>ชื่อ (ภาษาไทย)</label>
                                                        <Form.Field error={first_name_th_error}>
                                                            <Input
                                                                fluid
                                                                name='first_name_th'
                                                                value={first_name_th}
                                                                onChange={handleChangeFirstNameTh}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='16' mobile='16'>
                                                        <label className='header-input-form'>สกุล (ภาษาไทย)</label>
                                                        <Form.Field error={last_name_th_error}>
                                                            <Input
                                                                fluid
                                                                name='last_name_th'
                                                                value={last_name_th}
                                                                onChange={handleChangeLastNameTh}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Fragment>
                                        )}
                                        <Grid.Row>
                                            <Grid.Column computer='16' mobile='16'>
                                                <label className='header-input-form'>หมายเลขโทรศัพท์</label>
                                                <Form.Field error={phone_number_error}>
                                                    <Input
                                                        fluid
                                                        name='phone_number'
                                                        maxLength={10}
                                                        value={phone_number}
                                                        type='tel'
                                                        onChange={handleChangePhoneNumber}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column computer='16' mobile='16'>
                                                <div style={{ display: 'flex', margin: 5, color: '#BEC3CC' }}>
                                                    <div style={{ paddingRight: 15, textDecoration: 'underline' }}>
                                                        เงื่อนไข
                                                    </div>
                                                    <div>ต้องเป็นหมายเลขโทรศัพท์ที่สามารถยืนยันตัวตนได้</div>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                        {person_type === 1 && (
                            <Grid.Row>
                                <Grid.Column computer='1' only='computer' />
                                <Grid.Column computer='14' mobile='16'>
                                    <CustomSegment icon='user circle' header='ที่อยู่ผู้แจ้งคำร้อง'>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='16' mobile='16'>
                                                    <label className='header-input-form'>บ้านเลขที่</label>
                                                    <Form.Field error={house_no_error}>
                                                        <Input
                                                            fluid
                                                            name='house_no'
                                                            value={house_no}
                                                            onChange={handleChangeHouseNo}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16' mobile='16'>
                                                    <label className='header-input-form'>หมู่</label>
                                                    <Form.Field error={village_no_error}>
                                                        <Input
                                                            fluid
                                                            name='village_no'
                                                            value={village_no}
                                                            onChange={handleChangeVillageNo}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16' mobile='16'>
                                                    <label className='header-input-form'>ซอย</label>
                                                    <Form.Field error={alley_error}>
                                                        <Input
                                                            fluid
                                                            name='alley'
                                                            value={alley}
                                                            onChange={handleChangeAlley}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16' mobile='16'>
                                                    <label className='header-input-form'>ถนน</label>
                                                    <Form.Field error={road_error}>
                                                        <Input
                                                            fluid
                                                            name='road'
                                                            value={road}
                                                            onChange={handleChangeRoad}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16' mobile='16'>
                                                    <label className='header-input-form'>ตำบล</label>
                                                    <Form.Field error={sub_district_error}>
                                                        <Dropdown
                                                            selection
                                                            search
                                                            fluid
                                                            id='sub_district'
                                                            name='sub_district'
                                                            onChange={handleChangeAddress}
                                                            options={sub_district_option}
                                                            value={sub_district}
                                                            onSearchChange={(e, data) => searchSubDistrict(data)}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16' mobile='16'>
                                                    <label className='header-input-form'>อำเภอ</label>
                                                    <Form.Field error={district_error}>
                                                        <Dropdown
                                                            selection
                                                            search
                                                            fluid
                                                            id='district'
                                                            name='district'
                                                            onChange={handleChangeAddress}
                                                            options={district_option}
                                                            value={district}
                                                            onSearchChange={(e, data) => searchDistrict(data)}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16' mobile='16'>
                                                    <label className='header-input-form'>จังหวัด</label>
                                                    <Form.Field error={province_error}>
                                                        <Dropdown
                                                            selection
                                                            search
                                                            fluid
                                                            id='province'
                                                            name='province'
                                                            onChange={handleChangeAddress}
                                                            options={province_option}
                                                            value={province}
                                                            onSearchChange={(e, data) => searchProvince(data)}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16' mobile='16'>
                                                    <label className='header-input-form'>รหัสไปรษณีย์</label>
                                                    <Form.Field error={zipcode_error}>
                                                        <Dropdown
                                                            selection
                                                            search
                                                            fluid
                                                            id='zipcode'
                                                            name='zipcode'
                                                            onChange={handleChangeAddress}
                                                            options={zipcode_option}
                                                            value={zipcode}
                                                            onSearchChange={(e, data) => searchZipcode(data)}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </CustomSegment>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    label='ยอมรับข้อตกลง'
                                    checked={accept_term}
                                    onClick={handleChangeAcceptTerm}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <label
                                    style={{ color: '#4183C4', textDecorationLine: 'underline' }}
                                    onClick={handleChangeShowTerms}
                                >
                                    ข้อตกลงการใช้งาน
                                </label>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                    disabled={disabled_button_save}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                <Modal size='large' open={show_terms}>
                    <Modal.Header>
                        <label style={{ color: '#38869C' }}>ข้อตกลงการใช้งาน</label>
                    </Modal.Header>
                    <Modal.Content>
                        <div>
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <b>ประกาศ ที่ 1/2563</b>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <b>นโยบายคุ้มครองข้อมูลส่วนบุคคล (Data Protection Policy)</b>
                                </div>
                            </div>
                            <div>
                                <div>
                                    บริษัท วันจีโอเซอร์เวย์ จำกัด มีความมุ่งมั่นที่จะดำเนินการตาม
                                    พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                                    ให้สอดคล้องกับหลักเกณฑ์ของคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลเพื่อให้มีหลักเกณฑ์การคุ้มครองสิทธิของเจ้าของข้อมูลเกี่ยวกับข้อมูล
                                    ส่วนบุคคล สิทธิในความเป็นส่วนตัวและ เสรีภาพในข้อมูลส่วนบุคคลของผู้เจ้าของข้อมูล
                                    และพัฒนาปรับปรุงนโยบายระเบียบปฏิบัติของบริษัทให้ต่อเนื่อง
                                    สืบไปเพื่อให้เป็นไปตามนโยบายการ คุ้มครองข้อมูลส่วนบุคคล บริษัทจึงขอประกาศนโยบาย
                                    ดังนี้
                                </div>
                                <div>
                                    1. คำนิยาม “บริษัท” หมายถึง บริษัท วันจีโอเซอร์เวย์ จำกัด “บุคคล” ในที่นี้หมายถึง
                                    บุคคลธรรมดาที่มีชีวิตอยู่ ไม่รวมถึง “นิติบุคคล” ที่จัดตั้งขึ้นตามกฎหมาย
                                    “นโยบายการคุ้มครองข้อมูลส่วนบุคคล” หรือนโยบายความเป็นส่วนตัว เป็นสัญญาอย่างหนึ่ง
                                    โดยมีคู่สัญญา
                                    ฝ่ายหนึ่งคือผู้ให้บริการหรือเจ้าของเว็บไซต์หรือแอปพลิเคชันซึ่งเป็นผู้เก็บรวบรวม ใช้
                                    เปิดเผยข้อมูลส่วนบุคคล ของคู่สัญญาอีกฝ่ายหนึ่งซึ่งเรียกว่าผู้ใช้งานเว็บไซต์
                                    หรือแอปพลิเคชันซึ่งเข้ามาใช้บริการในเว็บไซต์หรือแอปพลิเคชันนั้น
                                    โดยเนื้อหาในสัญญาจะกล่าวถึงสิทธิและหน้าที่ของคู่สัญญาแต่ละฝ่ายเกี่ยวกับการให้ข้อมูล
                                    การเก็บรวบรวมข้อมูล การใช้ข้อมูล
                                    รวมถึงการเผยแพร่ข้อมูลของผู้ใช้งานเว็บไซต์หรือแอปพลิเคชัน “ข้อมูลส่วนบุคคล” หมายถึง
                                    ข้อมูลใด ๆ ที่ระบุไปถึง “เจ้าของข้อมูล” (Data Subject) ได้ไม่ว่าทางตรงหรือทางอ้อม
                                    โดยที่ข้อมูลส่วนบุคคลของผู้ใช้งาน ได้แก่
                                    ข้อมูลที่สามารถระบุตัวบุคคลซึ่งเป็นเจ้าของข้อมูลนั้นได้
                                    ไม่ว่าจะเป็นข้อมูลส่วนบุคคลทั่วไป เช่น ชื่อ นามสกุล ที่อยู่ วันเดือนปีเกิด
                                    เบอร์โทรศัพท์ อายุ วุฒิการศึกษา งานที่ทำ หรือข้อมูลส่วนบุคคลที่มีความอ่อนไหว ทั้งนี้
                                    ไม่รวมถึงข้อมูลดังต่อไปนี้ เช่น
                                    ข้อมูลสำหรับการติดต่อทางธุรกิจที่ไม่ได้ระบุถึงตัวบุคคล อาทิ ชื่อบริษัท
                                    ที่อยู่ของบริษัท เลขทะเบียนนิติบุคคลของบริษัท เช่น info@company.co.th เป็นต้น
                                    “ข้อมูลส่วนบุคคลอ่อนไหว” หมายถึง ข้อมูลที่เป็นเรื่องส่วนบุคคลโดยแท้ของบุคคล
                                    แต่มีความละเอียดอ่อนและอาจสุ่มเสี่ยงในการเลือกปฏิบัติอย่างไม่เป็นธรรม เช่น เชื้อชาติ
                                    เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อ (ลัทธิ ศาสนา ปรัชญา) พฤติกรรมทางเพศ
                                    ประวัติอาชญากรรม สุขภาพ ความพิการ พันธุกรรม ข้อมูลชีวภาพ ข้อมูลภาพจำลองใบหน้า ม่านตา
                                    หรือลายนิ้วมือ สหภาพแรงงานของผู้ใช้งาน เป็นต้น “เจ้าของข้อมูลส่วนบุคคล” (Data
                                    Subject) หมายถึง บุคคลที่ข้อมูลส่วนบุคคลนั้นระบุไปถึง
                                    ตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลนั้น
                                    แต่ไม่ใช่กรณีที่บุคคลมีความเป็นเจ้าของข้อมูล (Ownership)
                                    หรือเป็นผู้สร้างหรือเก็บรวบรวมข้อมูลนั้นเอง
                                    โดยเจ้าของข้อมูลส่วนบุคคลนี้จะหมายถึงบุคคลธรรมดาเท่านั้น และไม่รวมถึง “นิติบุคคล”
                                    (Juridical Person) ที่จัดตั้งขึ้นตามกฎหมาย เช่น บริษัท สมาคม มูลนิธิ
                                    หรือองค์กรอื่นใด “การประมวลผล” หมายถึง การดำเนินการใด ๆ เกี่ยวกับการใช้ การเปิดเผย
                                    หรือการแก้ไขซึ่งข้อมูลส่วนบุคคลของเจ้าของข้อมูล ไม่ว่าจะใช้วิธีการใด ๆ
                                    และให้หมายความรวมถึงการส่งหรือโอนข้อมูลส่วนบุคคลที่ใช้ในการประกอบกิจการนั้น ๆ
                                </div>
                                <div>
                                    2. การเก็บรวบรวม และวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
                                    บริษัทใช้วิธีการเก็บรวบรวม ใช้ เปิดเผย
                                    ข้อมูลส่วนบุคคลด้วยวิธีการที่ชอบด้วยกฎหมายและเป็นธรรม
                                    โดยจัดเก็บข้อมูลส่วนบุคคลเท่าที่จำเป็น ภายใต้วัตถุประสงค์ในการดำเนินงานของบริษัท
                                    หรือตามที่กฎหมายกำหนดเท่านั้น ซึ่งหากมีการเปลี่ยนแปลงวัตถุประสงค์
                                    บริษัทจะแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบก่อนหรือขณะเก็บรวบรวมข้อมูลส่วนบุคคล
                                    และบันทึกเพิ่มเติมไว้เป็นหลักฐาน โดยมีรายละเอียดดังนี้ 2.1
                                    วัตถุประสงค์ของการเก็บรวบรวม 2.2 ข้อมูลส่วนบุคคลที่ทำการเก็บรวบรวม 2.3
                                    กรณีที่เจ้าของข้อมูลต้องให้ข้อมูลส่วนบุคคล
                                    เพื่อปฏิบัติตามกฎหมายหรือสัญญาหรือเพื่อเข้าทำสัญญาโดยต้องแจ้งถึงผลกระทบที่เป็นไปได้จากการไม่ให้ข้อมูลส่วนบุคคลให้เจ้าของข้อมูลทราบด้วย
                                    2.4 ประเภทของบุคคลหรือหน่วยงานซึ่งข้อมูลส่วนบุคคลที่เก็บรวบรวมอาจถูกเปิดเผย 2.5
                                    สิทธิของเจ้าของข้อมูล
                                </div>
                                <div>
                                    3. การใช้หรือการเปิดเผยข้อมูลส่วนบุคคล การใช้หรือการเปิดเผยข้อมูลส่วนบุคคล
                                    บริษัทจะต้องดำเนินการตามความจำเป็น และตรงกับวัตถุประสงค์ของการเก็บรวบรวมข้อมูล
                                    และจะต้องได้รับความยินยอมจากเจ้าของข้อมูลจากเจ้าของข้อมูลที่ให้ไว้ก่อนหรือในขณะนั้น
                                    เว้นแต่กรณีดังต่อไปนี้ ไม่จำเป็นต้องขอความยินยอม 3.1
                                    เพื่อประโยชน์ในการศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสม
                                    เพื่อคุ้มครองสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคล 3.2
                                    เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล 3.3
                                    เพื่อความจำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งเจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญาหรือ
                                    เพื่อใช้ในการดำเนินการตามคำขอของเจ้าของข้อมูลส่วนบุคคลก่อนเข้าทำสัญญานั้น 3.4
                                    เพื่อประโยชน์สาธารณะของผู้ควบคุมโดยชอบด้วยกฎหมาย
                                </div>
                                <div>
                                    4. จัดให้มีมาตรการรักษาความมั่นคงปลอดภัยที่เหมาะสม เพื่อป้องกันการสูญหาย เข้าถึง ใช้
                                    เปลี่ยนแปลง แก้ไข หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ
                                </div>
                                <div>
                                    5. ดำเนินการทบทวนมาตรการรักษาความมั่นคงปลอดภัย
                                    เมื่อมีความจำเป็นหรือเมื่อเทคโนโลยีเปลี่ยนแปลงไปเพื่อให้มีประสิทธิภาพในการรักษาความมั่นคงปลอดภัยที่เหมาะสม
                                    ดำเนินการอย่างน้อยปีละ 1 ครั้ง
                                    หรือให้เป็นไปตามมาตรฐานขั้นต่ำที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
                                </div>
                                <div>
                                    6. จัดให้มีการกำหนดขั้นตอน
                                    ระเบียบในกรณีที่ต้องให้ข้อมูลส่วนบุคคลแก่บุคคลหรือนิติบุคคลอื่นที่ไม่ใช่ผู้ควบคุมข้อมูลส่วนบุคคล
                                    ต้องดำเนินการเพื่อป้องกันมิให้ผู้นั้นใช้หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ
                                </div>
                                <div>
                                    7. จัดให้มีระบบการตรวจสอบเพื่อดำเนินการลบหรือทำลายข้อมูลส่วนบุคคลเมื่อพ้นกำหนด
                                    ระยะเวลาการเก็บรักษา หรือที่
                                    ไม่เกี่ยวข้องหรือเกินความจำเป็นตามวัตถุประสงค์ในการเก็บรวบรวม ข้อมูลส่วนบุคคลนั้น
                                    หรือตามที่เจ้าของข้อมูลส่วนบุคคล ร้องขอ หรือที่เจ้าของข้อมูลส่วนบุคคล
                                    ได้ถอนความยินยอม
                                    เว้นแต่เก็บรักษาไว้เพื่อวัตถุประสงค์ในการใช้เสรีภาพในการแสดงความคิดเห็น
                                    การเก็บรักษาไว้เพื่อวัตถุประสงค์ ดังนี้
                                </div>
                                <div>
                                    8. จัดให้ขั้นตอนการแจ้งเหตุการณ์ละเมิดข้อมูลส่วนบุคคล (PII data Breach)
                                    แก่สำนักงานโดยไม่ชักช้าภายใน72
                                    ชั่วโมงตามมาตรฐานนับแต่ทราบเหตุเท่าที่จะสามารถกระทำได้
                                    เว้นแต่การละเมิดดังกล่าวไม่มีความเสี่ยงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของบุคคล
                                    ในกรณีที่การละเมิดข้อมูลส่วนบุคคล
                                    มีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของบุคคล
                                    ให้แจ้งเหตุการละเมิดให้เจ้าของข้อมูลส่วนบุคคลทราบพร้อมกับแนวทางการเยียวยา
                                    โดยไม่ชักช้าด้วย
                                    หรือการแจ้งดังกล่าวและข้อยกเว้นให้เป็นไปตามหลักเกณฑ์และวิธีการที่คณะกรรมการประกาศกำหนด
                                </div>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            style={{
                                backgroundColor: '#38869C',
                                color: 'white',
                                marginTop: '1rem',
                            }}
                            onClick={handleChangeShowTerms}
                        >
                            ปิด
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Fragment>
        )
    }
}

export default RegisterPersonForm
