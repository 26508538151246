import { HOST } from '../router'

const DIRECTORY_OF_MUNICIPALITY = `${HOST}/directory_of_municipality`

// export const GET_DIRECTORY_OF_MUNICIPALITY_LIST = `${DIRECTORY_OF_MUNICIPALITY}/list`
export const GET_DIRECTORY_OF_MUNICIPALITY_LIST_TIER = `${DIRECTORY_OF_MUNICIPALITY}/list_tire`
export const GET_DIRECTORY_OF_MUNICIPALITY_LIST_NEXT_TIER = `${DIRECTORY_OF_MUNICIPALITY}/list_next_tire`
export const GET_DIRECTORY_OF_MUNICIPALITY_LOWER_TIRE = `${DIRECTORY_OF_MUNICIPALITY}/lower_tier`
export const GET_DIRECTORY_OF_MUNICIPALITY_BY_ID = `${DIRECTORY_OF_MUNICIPALITY}/directory_by_id`
export const GET_NAME_DIRECTORY_OF_MUNICIPALITY_BY_ID = `${DIRECTORY_OF_MUNICIPALITY}/name_directory_by_id`
export const GET_DIRECTORY_OF_MUNICIPALITY_BY_ROLE = `${DIRECTORY_OF_MUNICIPALITY}/directory_by_role`
export const GET_DIRECTORY_OF_MUNICIPALITY_LIST_BY_MUNICIPALITY = `${DIRECTORY_OF_MUNICIPALITY}/list_by_municipality`
export const POST_DIRECTORY_OF_MUNICIPALITY_CREATE_AND_EDIT_TIRE = `${DIRECTORY_OF_MUNICIPALITY}/create_and_edit_tire`
export const GET_DIRECTORY_OF_MUNICIPALITY_LOWER_LEVEL_DIRECTORY_ALL = `${DIRECTORY_OF_MUNICIPALITY}/lower_level_directory_all`
export const POST_DIRECTORY_OF_MUNICIPALITY_CREATE_AND_EDIT_NEXT_TIRE = `${DIRECTORY_OF_MUNICIPALITY}/create_and_edit_next_tire`
export const DELETE_DIRECTORY_OF_MUNICIPALITY_TIER = `${DIRECTORY_OF_MUNICIPALITY}/delete_tier`
// export const DELETE_DIRECTORY_OF_MUNICIPALITY_BY_ID = `${DIRECTORY_OF_MUNICIPALITY}/delete_by_id`
export const GET_DIRECTORY_LIST_BY_MUNICIPALITY = `${DIRECTORY_OF_MUNICIPALITY}/directory_list`
export const GET_TECHNICIAN_BY_DIRECTORY = `${DIRECTORY_OF_MUNICIPALITY}/technician`
export const POST_DIRECTORY_OF_MUNICIPALITY_CREATE_LEVEL_DIRECTORY = `${DIRECTORY_OF_MUNICIPALITY}/create_level_directory`
export const PUT_DIRECTORY_OF_MUNICIPALITY_EDIT_LEVEL_DIRECTORY = `${DIRECTORY_OF_MUNICIPALITY}/edit_level_directory`
export const DELETE_DIRECTORY_OF_MUNICIPALITY_DELETE_LEVEL_DIRECTORY = `${DIRECTORY_OF_MUNICIPALITY}/delete_level_directory`
export const GET_DIRECTORY_OF_MUNICIPALITY_LEVEL_DIRECTORY = `${DIRECTORY_OF_MUNICIPALITY}/level_directory`
export const GET_DIRECTORY_OF_MUNICIPALITY_CHILDREN_LEVEL_DIRECTORY = `${DIRECTORY_OF_MUNICIPALITY}/children_level_directory`
export const GET_DIRECTORY_OF_MUNICIPALITY_LEVEL_DIRECTORY_ALL = `${DIRECTORY_OF_MUNICIPALITY}/level_directory_all`
export const PUT_DIRECTORY_OF_MUNICIPALITY_MAPPING_LEVEL_DIRECTORY_AND_ROLES = `${DIRECTORY_OF_MUNICIPALITY}/mapping_level_directory_and_roles`
export const GET_DIRECTORY_OF_MUNICIPALITY_LEVEL_DIRECTORY_AND_ROLES = `${DIRECTORY_OF_MUNICIPALITY}/level_directory_and_roles`
export const GET_DIRECTORY_OF_MUNICIPALITY_LIST_TIER_ALL = `${DIRECTORY_OF_MUNICIPALITY}/list_tire_all`
export const PUT_DIRECTORY_OF_MUNICIPALITY_MAPPING_DIRECTORY_AND_ROLES = `${DIRECTORY_OF_MUNICIPALITY}/mapping_directory_and_roles`
export const GET_DIRECTORY_OF_MUNICIPALITY_DIRECTORY_AND_ROLES = `${DIRECTORY_OF_MUNICIPALITY}/directory_and_roles`
export const GET_DIRECTORY_OF_MUNICIPALITY_ROLE_IN_DIRECTORY = `${DIRECTORY_OF_MUNICIPALITY}/role_in_directory`
