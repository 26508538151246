import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    Button,
    Dropdown,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Modal,
    Radio,
    Segment,
    Transition,
} from 'semantic-ui-react'
import CustomSegment from '../../../../components/CustomSegment'
import { GET_USER_DETAIL, GET_USER_DETAIL_BY_ID, PUT_USER_EDIT } from '../../../../config/api/user'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import IconMan from '../../../../assets/images/man.png'
import IconWoman from '../../../../assets/images/woman.png'
import IconWoman3 from '../../../../assets/images/woman1.png'
import CustomDropdownDate from '../../../../components/CustomDropdownDate'
import getCroppedImg from '../../../../functions/CroppedImage'
import Cropper from 'react-easy-crop'
import { alert } from '../../../../components/alert'
import { HOST } from '../../../../config/router'

const FormEditUser: React.FC = () => {
    const history = useHistory()
    let location = useLocation()
    let [username, setUsername] = useState<string>('')
    let [email, setEmail] = useState<string>('')
    let [phone_number, setPhoneNumber] = useState<string>('')
    let [prefix_th, setPrefixTh] = useState<string>('')
    let [prefix_th_error, setPrefixThError] = useState<boolean>(false)
    let [first_name_th, setFirstNameTh] = useState<string>('')
    let [first_name_th_error, setFirstNameThError] = useState<boolean>(false)
    let [last_name_th, setLastNameTh] = useState<string>('')
    let [last_name_th_error, setLastNameThError] = useState<boolean>(false)
    let [birth_date, setBirthDate] = useState<string>('')
    let [birth_date_error, setBirthDateError] = useState<boolean>(false)
    let [municipality, setMunicipality] = useState<number>()
    let [municipality_option, setMunicipalityOption] = useState<{ value: string; text: string }[]>([])
    let [directory, setDirectory] = useState<number>()
    let [directory_option, setDirectoryOption] = useState<{ value: string; text: string }[]>([])
    let [department, setDepartment] = useState<number>()
    let [department_option, setDepartmentOption] = useState<{ value: string; text: string }[]>([])
    let [sub_division, setSubDivision] = useState<number>()
    let [sub_division_option, setSubDivisionOption] = useState<{ value: string; text: string }[]>([])
    let [role_option, setRoleOption] = useState<{ value: string; text: string }[]>([])
    let [roles_id, setRolesId] = useState<number>()
    let [position, setPosition] = useState<string>('')
    let [profile_image, setProfileImage] = useState<any>()
    let [show_modal_upload, setShowModalUpload] = useState<boolean>(false)
    let [show_upload_image, setShowUploadImage] = useState<boolean>(false)
    let [loading_image, setLoadingImage] = useState<boolean>(false)
    let [crop, setCrop] = useState({ x: 0, y: 0 })
    let [pixel_crop, setPixelCrop] = useState<object>({})
    let [dump_path, setDumpPath] = useState<string>()
    let [path_image, setPathImage] = useState<string>()
    let [photo_upload, setPhotoUpload] = useState()
    let zoom = 1

    const getDetailUser = useCallback(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ''))
            let { user_id } = location_state

            axiosAuthen.get(user_id ? `${GET_USER_DETAIL_BY_ID}/${user_id}` : `${GET_USER_DETAIL}`).then((response) => {
                let { value } = response.data
                setEmail(value.email)
                setUsername(value.username)
                setPhoneNumber(value.phone_number)
                setPrefixTh(value.prefix_th)
                setFirstNameTh(value.first_name_th)
                setLastNameTh(value.last_name_th)
                setBirthDate(value.birth_date)
                setMunicipality(value.municipalities_id)
                setRolesId(value.roles_id)
                setMunicipalityOption([{ value: value.municipalities_id, text: value.municipalities_name }])
                setRoleOption([{ value: value.roles_id, text: value.roles_name }])
                setDirectory(value.directory_id)
                setDirectoryOption([{ value: value.directory_id, text: value.directory_name }])
                setDepartment(value.department_id)
                setDepartmentOption([{ value: value.department_id, text: value.department_name }])
                setSubDivision(value.sub_division_id)
                setSubDivisionOption([{ value: value.sub_division_id, text: value.sub_division_name }])
                getFileProfile(value.image)
                setPosition(value.position)
            })
        }
    }, [location])

    useEffect(() => {
        getDetailUser()
    }, [getDetailUser])

    const closeModal = () => {
        setPathImage(dump_path)
        setShowModalUpload(false)
    }

    const onCropComplete = (cropped_area: any, cropped_area_pixels: any) => {
        setPixelCrop(cropped_area_pixels)
    }

    const onCropChange = (value: any) => {
        setCrop(value)
    }

    const selectImage = async () => {
        setLoadingImage(true)
        let new_image = await getCroppedImg(photo_upload, pixel_crop)
        setPathImage(URL.createObjectURL(new_image))
        setLoadingImage(false)
        setShowModalUpload(false)
        setProfileImage(new_image)
    }

    const handleClickUpload = () => {
        document.getElementById('file')!.click()
    }

    const handleChangeFile = (e: any) => {
        let file = e.target.files[0]
        if (file) {
            setPhotoUpload(file)
            setPathImage(URL.createObjectURL(file))
            setShowModalUpload(true)
            setDumpPath(path_image)
        }
    }

    const getFileProfile = async (path: any) => {
        await axiosAuthen
            .get(`${HOST}${path}`, {
                responseType: 'arraybuffer',
            })
            .then((response) => {
                let data = response.data
                let blobFile = new Blob([data])
                let newFile = new File([blobFile], `image_profile`, {
                    type: `image/jpg`,
                })
                setProfileImage(newFile)
                localStorage.setItem('profile_image', `${path}`)
                setPathImage(URL.createObjectURL(newFile))
            })
    }

    const handleChangeFirstNameTh = (_: any, { name, value }: InputOnChangeData) => {
        setFirstNameTh(value)
        setFirstNameThError(false)
    }
    const handleChangeLastNameTh = (_: any, { name, value }: InputOnChangeData) => {
        setLastNameTh(value)
        setLastNameThError(false)
    }

    const handleChangePrefix = (prefix_th: string) => {
        setPrefixTh(prefix_th)
        setPrefixThError(false)
    }

    const handleChangeDate = (e: any, { name, value }: any) => {
        setBirthDate(value)
        setBirthDateError(false)
    }

    const handleChangePosition = (_: any, { name, value }: InputOnChangeData) => {
        setPosition(value)
    }

    const validateForm = () => {
        let error = false

        if (!birth_date) {
            error = true
            setBirthDateError(true)
        }

        if (!last_name_th) {
            error = true
            setLastNameThError(true)
        }

        if (!first_name_th) {
            error = true
            setFirstNameThError(true)
        }

        if (!prefix_th) {
            setPrefixThError(true)
            error = true
        }

        return error
    }

    const handleSubmit = () => {
        if (!validateForm()) {
            let birth_date_split: any = birth_date.split('/')
            birth_date = `${birth_date_split[2] - 543}-${birth_date_split[1]}-${birth_date_split[0]}`
            let form_data = new FormData()
            form_data.append('prefix_th', prefix_th)
            form_data.append('first_name_th', first_name_th)
            form_data.append('last_name_th', last_name_th)
            form_data.append('birth_date', birth_date)
            form_data.append('position', position)
            profile_image && form_data.append('image', profile_image)
            axiosAuthen
                .put(PUT_USER_EDIT, form_data)
                .then((response) => {
                    alert({
                        type: 'success',
                        title: 'บันทึกเสร็จสิ้น',
                        timer: 1500,
                        onClosed: () => {
                            getDetailUser()
                        },
                    })
                })
                .catch((error) => {
                    if (error.response) {
                        alert({
                            type: 'error',
                            title: error.response.data.message,
                            cancelText: 'ปิด',
                        })
                    }
                })
        }
    }

    return (
        <Fragment>
            <Form>
                <div className='background-container'>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>แก้ไขข้อมูลส่วนตัว</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16' mobile='16'>
                                <CustomSegment icon='key' header='ผู้ใช้งาน'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <label className='header-input-form'>ชื่อผู้ใช้งาน</label>
                                                <Form.Field>
                                                    <Input fluid name='username' value={username} readOnly />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <label className='header-input-form'>อีเมล</label>
                                                <Form.Field>
                                                    <Input fluid name='email' value={email} readOnly />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <div style={{ display: 'flex', margin: 5, color: '#BEC3CC' }}>
                                                    <div style={{ paddingRight: 15, textDecoration: 'underline' }}>
                                                        เงื่อนไข
                                                    </div>
                                                    <div>ต้องเป็นอีเมลที่สามารถยืนยันตัวตนได้</div>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <label className='header-input-form'>หมายเลขโทรศัพท์</label>
                                                <Form.Field>
                                                    <Input fluid name='phone_number' value={phone_number} readOnly />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <div style={{ display: 'flex', margin: 5, color: '#BEC3CC' }}>
                                                    <div style={{ paddingRight: 15, textDecoration: 'underline' }}>
                                                        เงื่อนไข
                                                    </div>
                                                    <div>ต้องเป็นหมายเลขโทรศัพท์ที่สามารถยืนยันตัวตนได้</div>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16' mobile='16'>
                                <CustomSegment icon='user circle' header='ข้อมูลส่วนตัว'>
                                    <h4 style={{ textAlign: 'center', color: '#9F3A38' }}>
                                        ** ข้อมูลในส่วนต่อไปนี้ไม่สามารถแก้ไขในภายหลังได้
                                        โปรดตรวจสอบความถูกต้องก่อนบันทึก **
                                    </h4>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Modal
                                                size='small'
                                                open={show_modal_upload}
                                                onClose={closeModal}
                                                closeOnEscape={false}
                                                closeOnDimmerClick={false}
                                            >
                                                <Modal.Header
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: '50%',
                                                        width: ' 100%',
                                                        transform: 'translateX(-50%)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    อัปโหลดรูปภาพ
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Segment
                                                        loading={loading_image}
                                                        style={{ padding: 0, marginTop: 40, paddingBottom: 30 }}
                                                    >
                                                        <div style={{ height: 600 }}>
                                                            <Cropper
                                                                image={path_image}
                                                                crop={crop}
                                                                zoom={zoom}
                                                                aspect={3 / 3}
                                                                // style={{ height: 300 }}
                                                                onCropChange={onCropChange}
                                                                onCropComplete={onCropComplete}
                                                            />
                                                        </div>
                                                    </Segment>
                                                </Modal.Content>
                                                <Modal.Actions
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        left: '50%',
                                                        width: '100%',
                                                        transform: 'translateX(-50%)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                        padding: 0,
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    <div style={{ padding: 20 }}>
                                                        <Button
                                                            color='green'
                                                            onClick={selectImage}
                                                            disabled={loading_image}
                                                        >
                                                            บันทึก
                                                        </Button>
                                                        <Button
                                                            color='red'
                                                            onClick={closeModal}
                                                            disabled={loading_image}
                                                        >
                                                            ยกเลิก
                                                        </Button>
                                                    </div>
                                                </Modal.Actions>
                                            </Modal>

                                            <Grid.Column computer='10' only='computer'>
                                                <div
                                                    style={{ display: 'flex', alignItems: 'flex-end' }}
                                                    onMouseOver={() => {
                                                        setShowUploadImage(true)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setShowUploadImage(false)
                                                    }}
                                                >
                                                    <div style={{ justifyContent: 'center' }}>
                                                        <Image
                                                            value={profile_image}
                                                            bordered
                                                            onMouseOver={() => {
                                                                setShowUploadImage(true)
                                                            }}
                                                            style={{
                                                                width: 150,
                                                                height: 150,
                                                                borderRadius: 500,
                                                            }}
                                                            src={
                                                                path_image
                                                                    ? path_image
                                                                    : 'https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png'
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            width: 150,
                                                            opacity: 0.7,
                                                        }}
                                                    >
                                                        <Transition
                                                            visible={show_upload_image}
                                                            animation='slide up'
                                                            duration={200}
                                                        >
                                                            <div
                                                                style={{
                                                                    height: 70,
                                                                    backgroundColor: 'black',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '0px 0px 1000px 1000px',
                                                                }}
                                                                onClick={handleClickUpload}
                                                            >
                                                                <div
                                                                    style={{
                                                                        justifyContent: 'center',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        height: '100%',
                                                                        flexDirection: 'column',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name='camera'
                                                                            size='big'
                                                                            style={{ color: 'white' }}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            marginTop: 10,
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                            // paddingBottom: 20
                                                                        }}
                                                                    >
                                                                        <label
                                                                            style={{
                                                                                color: 'white',
                                                                                cursor: 'pointer',
                                                                                paddingBottom: 5,
                                                                            }}
                                                                        >
                                                                            อัปโหลดรูปภาพ
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Transition>
                                                    </div>
                                                    <Input>
                                                        <input
                                                            type='file'
                                                            id='file'
                                                            hidden
                                                            onChange={handleChangeFile}
                                                            name='image_profile'
                                                            // label='เพิ่มรูปถ่าย'
                                                            accept='image/png, image/jpeg, image/jpg'
                                                        />
                                                    </Input>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column tablet='10' mobile='16' only='tablet mobile'>
                                                <Segment
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        zIndex: 1,
                                                        marginTop: 30,
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <div style={{ justifyContent: 'center' }}>
                                                            <Image
                                                                bordered
                                                                onMouseOver={() => {
                                                                    setShowUploadImage(true)
                                                                }}
                                                                style={{
                                                                    width: 150,
                                                                    height: 150,
                                                                    borderRadius: 500,
                                                                }}
                                                                src={
                                                                    path_image
                                                                        ? path_image
                                                                        : 'https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png'
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 150,
                                                                opacity: 0.7,
                                                                marginBottom: 5,
                                                            }}
                                                            onMouseOver={() => {
                                                                setShowUploadImage(true)
                                                            }}
                                                        >
                                                            <Transition
                                                                visible={show_upload_image}
                                                                animation='slide up'
                                                                duration={200}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: 70,
                                                                        backgroundColor: 'black',
                                                                        cursor: 'pointer',
                                                                        borderRadius: '0px 0px 1000px 1000px',
                                                                    }}
                                                                    onClick={handleClickUpload}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            justifyContent: 'center',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            height: '100%',
                                                                            flexDirection: 'column',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                name='camera'
                                                                                size='big'
                                                                                style={{ color: 'white' }}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                marginTop: 10,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                            }}
                                                                        >
                                                                            <label
                                                                                style={{
                                                                                    color: 'white',
                                                                                    cursor: 'pointer',
                                                                                    paddingBottom: 5,
                                                                                }}
                                                                            >
                                                                                อัปโหลดรูปภาพ
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Transition>
                                                        </div>
                                                        <Input>
                                                            <input
                                                                type='file'
                                                                id='file'
                                                                hidden
                                                                onChange={handleChangeFile}
                                                                name='image_profile'
                                                                // label='เพิ่มรูปถ่าย'
                                                                accept='image/png, image/jpeg, image/jpg'
                                                            />
                                                        </Input>
                                                    </div>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <label className='header-input-form'>คำนำหน้าชื่อ</label>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column computer='5' tablet='8' mobile='16'>
                                                            <Segment
                                                                className={
                                                                    prefix_th === 'นาย'
                                                                        ? 'selected-prefix'
                                                                        : prefix_th_error
                                                                        ? 'error-prefix'
                                                                        : 'unselected-prefix'
                                                                }
                                                                onClick={() => {
                                                                    handleChangePrefix('นาย')
                                                                }}
                                                            >
                                                                <Radio checked={prefix_th === 'นาย'} />
                                                                <Image
                                                                    src={IconMan}
                                                                    style={{ margin: 5, width: 56, height: 56 }}
                                                                />
                                                                <div style={{ margin: 5 }}>
                                                                    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                                        นาย
                                                                    </div>
                                                                    <div style={{ color: '#4D648D' }}>Mr.</div>
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='16'>
                                                            <Segment
                                                                className={
                                                                    prefix_th === 'นาง'
                                                                        ? 'selected-prefix'
                                                                        : prefix_th_error
                                                                        ? 'error-prefix'
                                                                        : 'unselected-prefix'
                                                                }
                                                                onClick={() => {
                                                                    handleChangePrefix('นาง')
                                                                }}
                                                            >
                                                                <Radio checked={prefix_th === 'นาง'} />
                                                                <Image
                                                                    src={IconWoman3}
                                                                    style={{ margin: 5, width: 56, height: 56 }}
                                                                />
                                                                <div style={{ margin: 5 }}>
                                                                    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                                        นาง
                                                                    </div>
                                                                    <div style={{ color: '#4D648D' }}>Mrs.</div>
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='16'>
                                                            <Segment
                                                                className={
                                                                    prefix_th === 'นางสาว'
                                                                        ? 'selected-prefix'
                                                                        : prefix_th_error
                                                                        ? 'error-prefix'
                                                                        : 'unselected-prefix'
                                                                }
                                                                onClick={() => {
                                                                    handleChangePrefix('นางสาว')
                                                                }}
                                                            >
                                                                <Radio checked={prefix_th === 'นางสาว'} />
                                                                <Image
                                                                    src={IconWoman}
                                                                    style={{ margin: 5, width: 56, height: 56 }}
                                                                />
                                                                <div style={{ margin: 5 }}>
                                                                    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                                        นางสาว
                                                                    </div>
                                                                    <div style={{ color: '#4D648D' }}>Ms.</div>
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='5' mobile='16'>
                                                <label className='header-input-form'>ชื่อ (ภาษาไทย)</label>
                                                <Form.Field error={first_name_th_error}>
                                                    <Input
                                                        fluid
                                                        name='first_name_th'
                                                        value={first_name_th}
                                                        onChange={handleChangeFirstNameTh}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column computer='5' mobile='16'>
                                                <label className='header-input-form'>สกุล (ภาษาไทย)</label>
                                                <Form.Field error={last_name_th_error}>
                                                    <Input
                                                        fluid
                                                        name='last_name_th'
                                                        value={last_name_th}
                                                        onChange={handleChangeLastNameTh}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' tablet='16' mobile='16'>
                                                <label className='header-input-form'>วัน/เดือน/ปีเกิด (พ.ศ.)</label>
                                                <div style={{ marginTop: 5 }}>
                                                    <CustomDropdownDate
                                                        error={birth_date_error}
                                                        name='birth_date'
                                                        value={birth_date}
                                                        onChange={handleChangeDate}
                                                    />
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16' mobile='16'>
                                <CustomSegment icon='book' header='ข้อมูลเพิ่มเติม'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <label className='header-input-form'>องค์กรที่สังกัด</label>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    search
                                                    name='municipality'
                                                    value={municipality}
                                                    options={municipality_option}
                                                    disabled={true}
                                                />
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='10' mobile='16'>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column computer='5' tablet='8' mobile='16'>
                                                            <label className='header-input-form'>หน่วยงาน</label>
                                                            <Dropdown
                                                                fluid
                                                                selection
                                                                search
                                                                name='directory'
                                                                value={directory}
                                                                options={directory_option}
                                                                disabled={true}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='16'>
                                                            <label className='header-input-form'>
                                                                หน่วยงานย่อยระดับที่ 1 (ถ้ามี)
                                                            </label>
                                                            <Dropdown
                                                                fluid
                                                                selection
                                                                search
                                                                name='department'
                                                                value={department}
                                                                options={department_option}
                                                                disabled={true}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column computer='5' tablet='8' mobile='16'>
                                                            <label className='header-input-form'>
                                                                หน่วยงานย่อยระดับที่ 2 (ถ้ามี)
                                                            </label>
                                                            <Dropdown
                                                                fluid
                                                                selection
                                                                search
                                                                name='sub_devision'
                                                                value={sub_division}
                                                                options={sub_division_option}
                                                                disabled={true}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column computer='3' only='computer' />
                                            <Grid.Column computer='5' mobile='16'>
                                                <label className='header-input-form'>ตำแหน่งงาน</label>
                                                <Input
                                                    fluid
                                                    name='position'
                                                    value={position}
                                                    onChange={handleChangePosition}
                                                />
                                            </Grid.Column>
                                            <Grid.Column computer='5' mobile='16'>
                                                <label className='header-input-form'>สิทธิ์</label>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    name='roles_id'
                                                    value={roles_id}
                                                    options={role_option}
                                                    disabled={true}
                                                />
                                            </Grid.Column>
                                            <Grid.Column computer='3' only='computer' />
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={() => history.goBack()}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Form>
        </Fragment>
    )
}

export default FormEditUser
