import React, { Fragment } from 'react'
import { Container, Grid, Icon } from 'semantic-ui-react'
import CustomSegment from '../../../../components/CustomSegment'
import GoogleMap from '../../../../components/GoogleMaps'
import { ComplaintFormStep1State } from '../../../../store/types/Complaint/Form/Step1'
import { ComplaintFormStep2State } from '../../../../store/types/Complaint/Form/Step2'
import { ComplaintFormStep3State } from '../../../../store/types/Complaint/Form/Step3'

type Step4Props = {
    data: ComplaintFormStep1State & ComplaintFormStep2State & ComplaintFormStep3State
    field: string[]
}

const Step4: React.FC<Step4Props> = (props) => {
    let { data, field } = props
    return (
        <Fragment>
            <CustomSegment icon='book' header='รายละเอียดคำร้อง'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='8' tablet='16' style={{ padding: 14 }}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column computer='2' only='computer' />
                                        <Grid.Column computer='6' textAlign='right'>
                                            <h5>เลขที่รับ</h5>
                                        </Grid.Column>
                                        <Grid.Column computer='8'>{data.complaint_no || '-'}</Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column computer='2' only='computer' />
                                        <Grid.Column computer='6' textAlign='right'>
                                            <h5>เลขที่รับจากส่วนกลาง</h5>
                                        </Grid.Column>
                                        <Grid.Column computer='8'>{data.central_complaint_no || '-'}</Grid.Column>
                                    </Grid.Row>

                                    {field.includes('complaint date') && (
                                        <Grid.Row>
                                            <Grid.Column computer='2' only='computer' />
                                            <Grid.Column computer='6' textAlign='right'>
                                                <h5>วัน / เดือน / ปี ที่รับเรื่อง</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='8'>{data.complaint_date || '-'}</Grid.Column>
                                        </Grid.Row>
                                    )}
                                    {field.includes('area and community') && (
                                        <Grid.Row>
                                            <Grid.Column computer='2' only='computer' />
                                            <Grid.Column computer='6' textAlign='right'>
                                                <h5>พื้นที่ ( เขต / ชุมชน )</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='8'>
                                                เขต {data.area.text || '-'} ชุมชน {data.community.text || '-'}
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    {field.includes('contact') && (
                                        <Grid.Row>
                                            <Grid.Column computer='2' only='computer' />
                                            <Grid.Column computer='6' textAlign='right'>
                                                <h5>ช่องทางการรับเรื่อง</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='8'>{data.contact.text || '-'}</Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </Grid.Column>
                            <Grid.Column computer='8' tablet='16' style={{ padding: 14 }}>
                                <Grid>
                                    {field.includes('directory') &&
                                        data.directory.map((directory, index) => (
                                            <Grid.Row key={index}>
                                                <Grid.Column computer='6' textAlign='right'>
                                                    <h5>หน่วยงาน{index > 0 && `ย่อยระดับที่ ${index}`}</h5>
                                                </Grid.Column>
                                                <Grid.Column computer='9'>{directory.text || '-'}</Grid.Column>
                                                <Grid.Column computer='1' only='computer' />
                                            </Grid.Row>
                                        ))}
                                    {field.includes('topic and type') && (
                                        <Grid.Row>
                                            <Grid.Column computer='6' textAlign='right'>
                                                <h5>เรื่องที่ยื่นคำร้อง</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='9'>{data.complaint_topic.text || '-'}</Grid.Column>
                                            <Grid.Column computer='1' only='computer' />
                                        </Grid.Row>
                                    )}
                                    {field.includes('topic and type') && (
                                        <Grid.Row>
                                            <Grid.Column computer='6' textAlign='right'>
                                                <h5>ประเภทงาน</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='9'>{data.complaint_type.text || '-'}</Grid.Column>
                                            <Grid.Column computer='1' only='computer' />
                                        </Grid.Row>
                                    )}
                                    {field.includes('technician') && (
                                        <Grid.Row>
                                            <Grid.Column computer='6' textAlign='right'>
                                                <h5>เจ้าหน้าที่รับผิดชอบ</h5>
                                            </Grid.Column>
                                            <Grid.Column computer='9'>{data.technician.text || '-'}</Grid.Column>
                                            <Grid.Column computer='1' only='computer' />
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                        {field.includes('complaint subject') && (
                            <Grid.Row>
                                <Grid.Column computer='1' only='computer' />
                                <Grid.Column computer='3' tablet='6' textAlign='right'>
                                    <h5>หัวข้อเรื่องร้องทุกข์</h5>
                                </Grid.Column>
                                <Grid.Column computer='11' tablet='8' mobile='10'>
                                    {data.complaint_subject || '-'}
                                </Grid.Column>
                                <Grid.Column computer='1' only='computer' />
                            </Grid.Row>
                        )}
                        {field.includes('complaint detail') && (
                            <Grid.Row>
                                <Grid.Column computer='1' only='computer' />
                                <Grid.Column computer='3' tablet='6' textAlign='right'>
                                    <h5>รายละเอียด</h5>
                                </Grid.Column>
                                <Grid.Column computer='11' tablet='8' mobile='10'>
                                    {data.complaint_detail || '-'}
                                </Grid.Column>
                                <Grid.Column computer='1' only='computer' />
                            </Grid.Row>
                        )}
                        {field.includes('complaint file') && (
                            <Grid.Row>
                                <Grid.Column computer='1' only='computer' />
                                <Grid.Column computer='3' tablet='6' textAlign='right'>
                                    <h5>เอกสารแนบ</h5>
                                </Grid.Column>
                                <Grid.Column computer='11' tablet='8' mobile='10'>
                                    <Grid>
                                        {data.file_complaint.length !== 0 ? (
                                            data.file_complaint.map((file: File, index: number) => (
                                                <Grid.Row key={index}>
                                                    <Grid.Column computer='16' style={{ display: 'flex' }}>
                                                        <div
                                                            style={{
                                                                padding: '10px 3px',
                                                                borderRadius: 8,
                                                                backgroundColor: '#4D648D',
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                        {file.type.includes('image') ? (
                                                            <a
                                                                href={URL.createObjectURL(file)}
                                                                target={'_blank'}
                                                                rel='noreferrer'
                                                            >
                                                                <Icon
                                                                    name='file image outline'
                                                                    size='large'
                                                                    style={{ color: '#75B9A5' }}
                                                                />
                                                                <label
                                                                    style={{
                                                                        color: '#283655',
                                                                        textDecoration: 'underline',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    {file.name}
                                                                </label>
                                                            </a>
                                                        ) : (
                                                            <a
                                                                href={URL.createObjectURL(file)}
                                                                target={'_blank'}
                                                                rel='noreferrer'
                                                            >
                                                                <Icon
                                                                    name='file alternate'
                                                                    size='large'
                                                                    style={{ color: '#D85F53' }}
                                                                />
                                                                <label
                                                                    style={{
                                                                        color: '#283655',
                                                                        textDecoration: 'underline',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    {file.name}
                                                                </label>
                                                            </a>
                                                        )}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            ))
                                        ) : (
                                            <Grid.Row>
                                                <Grid.Column computer='11'>
                                                    <label>-</label>
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column computer='1' only='computer' />
                            </Grid.Row>
                        )}
                    </Grid>
                </Container>
            </CustomSegment>

            <CustomSegment header='ข้อมูลคำร้อง' icon='user'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer='8' tablet='16' style={{ padding: 14 }}>
                            <Grid>
                                {field.includes('detail person') && (
                                    <Grid.Row>
                                        <Grid.Column computer='2' only='computer' />
                                        <Grid.Column computer='6' textAlign='right'>
                                            <h5>ชื่อ - สกุล</h5>
                                        </Grid.Column>
                                        <Grid.Column computer='8'>
                                            {data.prefix && data.first_name && data.last_name ? (
                                                <Fragment>
                                                    {data.prefix}
                                                    {data.first_name} {data.last_name}
                                                </Fragment>
                                            ) : (
                                                '-'
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                {field.includes('address person') && (
                                    <Grid.Row>
                                        <Grid.Column computer='2' only='computer' />
                                        <Grid.Column computer='6' textAlign='right'>
                                            <h5>ที่อยู่</h5>
                                        </Grid.Column>
                                        <Grid.Column computer='8'>
                                            บ้านเลขที่ {data.house_no || '-'} หมู่ที่ {data.village_no || '-'} <br />
                                            ซอย {data.alley || '-'} <br />
                                            ถนน {data.road || '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                {field.includes('address person') && (
                                    <Grid.Row>
                                        <Grid.Column computer='2' only='computer' />
                                        <Grid.Column computer='6' textAlign='right'>
                                            <h5>เขตที่ตั้ง</h5>
                                        </Grid.Column>
                                        <Grid.Column computer='8'>
                                            ตำบล{data.sub_district || ' -'} อำเภอ
                                            {data.district || ' -'} จังหวัด
                                            {data.province || ' -'} {data.zipcode && data.zipcode}
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </Grid>
                        </Grid.Column>
                        <Grid.Column computer='8' tablet='16' style={{ padding: 14 }}>
                            <Grid>
                                {field.includes('detail person') && (
                                    <Grid.Row>
                                        <Grid.Column computer='6' textAlign='right'>
                                            <h5>โทรศัพท์ (บ้าน)</h5>
                                        </Grid.Column>
                                        <Grid.Column computer='8'>{data.phone_number || '-'}</Grid.Column>
                                        <Grid.Column computer='2' only='computer' />
                                    </Grid.Row>
                                )}
                                {field.includes('detail person') && (
                                    <Grid.Row>
                                        <Grid.Column computer='6' textAlign='right'>
                                            <h5>โทรศัพท์ (มือถือ)</h5>
                                        </Grid.Column>
                                        <Grid.Column computer='8'>{data.mobile_number || '-'}</Grid.Column>
                                        <Grid.Column computer='2' only='computer' />
                                    </Grid.Row>
                                )}
                                {field.includes('detail person') && (
                                    <Grid.Row>
                                        <Grid.Column computer='6' textAlign='right'>
                                            <h5>อีเมล</h5>
                                        </Grid.Column>
                                        <Grid.Column computer='8'>{data.email || '-'}</Grid.Column>
                                        <Grid.Column computer='2' only='computer' />
                                    </Grid.Row>
                                )}
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </CustomSegment>

            {field.includes('position map') && Boolean(data.position.lat) && Boolean(data.position.lng) && (
                <CustomSegment header='ตำแหน่งที่ตั้ง' icon='marker'>
                    <GoogleMap
                        style={{ widht: 420, height: 420 }}
                        value={{ lat: Number(data.position.lat), lng: Number(data.position.lng) }}
                    />
                </CustomSegment>
            )}
        </Fragment>
    )
}

export default Step4
