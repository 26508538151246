import React, { Component, Fragment } from 'react'
import { Icon, Modal } from 'semantic-ui-react'
import './styles.css'

class CustomModal extends Component {
    render() {
        let {
            closeIcon = false,
            closeOnEscape = false,
            closeOnDimmerClick = false,
            onClose,
            icon,
            header,
            size,
            open = true,
            trigger,
            centered = true,
        } = this.props
        return (
            <Fragment>
                <Modal
                    centered={centered}
                    open={open}
                    className='custom-modal'
                    size={size}
                    closeIcon={closeIcon}
                    closeOnEscape={closeOnEscape}
                    closeOnDimmerClick={closeOnDimmerClick}
                    onClose={() => onClose && onClose()}
                    trigger={trigger}
                >
                    <Modal.Header style={{ padding: 0, borderRadius: 30 }}>
                        <div className='header-modal'>
                            <Icon name={icon} />
                            <label style={{ marginLeft: 10 }}>{header}</label>
                        </div>
                    </Modal.Header>
                    <Modal.Content style={{ borderRadius: 15 }}>
                        {Array.isArray(this.props.children)
                            ? this.props.children.map((child, index) => <Fragment key={index}>{child}</Fragment>)
                            : this.props.children}
                    </Modal.Content>
                </Modal>
            </Fragment>
        )
    }
}

export default CustomModal
