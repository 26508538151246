import { HOST } from '../router'

const COMPLAINT = `${HOST}/complaint`

export const GET_COMPLAINT_LIST = `${COMPLAINT}/list`
export const GET_COMPLAINT_DETAIL = `${COMPLAINT}/detail`
export const POST_COMPLAINT_CREATE_AND_EDIT = `${COMPLAINT}/create_and_edit`
export const GET_COMPLAINT_DEPARTMENT_NUMBER = `${COMPLAINT}/generate_department_no`
export const POST_COMPLAINT_UPLOAD_FILE_COMPLAINT = `${COMPLAINT}/upload_complaint`
export const GET_COMPLAINT_FIELD_FORM = `${COMPLAINT}/field_form`
export const GET_COMPLAINT_TECHNICIAN_MATCH_DIRECTORY = `${COMPLAINT}/technician_match_directory`
export const POST_COMPLAINT_REJECT_COMPLAINT = `${COMPLAINT}/reject_complaint`
export const POST_COMPLAINT_ACCEPT_COMPLAINT_FROM_CENTRAL = `${COMPLAINT}/accept_complaint_from_central`
export const POST_COMPLAINT_ACCEPT_COMPLAINT = `${COMPLAINT}/accept_complaint`
export const GET_COMPLAINT_STATUS_ALL = `${COMPLAINT}/status_all`
export const GET_COMPLAINT_STATUS_ACCEPT_ALL = `${COMPLAINT}/status_accept_all`
export const GET_COMPLAINT_STATUS_RESULT = `${COMPLAINT}/status_result`
export const POST_COMPLAINT_SAVE_RESULT = `${COMPLAINT}/save_results`
export const GET_COMPLAINT_DETAIL_RESULT = `${COMPLAINT}/detail_result`
export const POST_COMPLAINT_EVALUATE_COMPLAINT = `${COMPLAINT}/evaluate_complaint`
export const GET_COMPLAINT_EVALUATE_COMPLAINT_DETAIL = `${COMPLAINT}/evaluate_complaint_detail`
export const GET_COMPLAINT_HISTORY = `${COMPLAINT}/history`
export const GET_COMPLAINT_PRE_COMPLAINT_LIST = `${COMPLAINT}/pre_complaint_list`
export const GET_COMPLAINT_PRE_COMPLAINT_DETAIL = `${COMPLAINT}/pre_complaint_detail`
export const PUT_COMPLAINT_REJECT_PRE_COMPLAINT = `${COMPLAINT}/reject_pre_complaint`
export const PUT_COMPLAINT_POSITION_MAP = `${COMPLAINT}/position_map`
export const PUT_COMPLAINT_CHANGE_DIRECTORY = `${COMPLAINT}/change_directory`
export const POST_COMPLAINT_CHANGE_TECHNICIAN = `${COMPLAINT}/change_technician`
export const GET_COMPLAINT_COMMENT_LIST = `${COMPLAINT}/list_comments`
export const GET_COMPLAINT_MAP = `${COMPLAINT}/list_map`
export const GET_COMPLAINT_CHECK_ASSIGN_TECHNICIAN = `${COMPLAINT}/check_assign_technician`
export const POST_COMPLAINT_APPROVE_STATUS = `${COMPLAINT}/approve_status`
export const POST_COMPLAINT_REJECT_STATUS = `${COMPLAINT}/reject_status`
export const GET_COMPLAINT_GROUP_STATUS = `${COMPLAINT}/group_status`
export const GET_COMPLAINT_FISCAL_YEAR_ALL = `${COMPLAINT}/fiscal_year_all`
export const POST_COMPLAINT_CONTINUE_COMPLAINT = `${COMPLAINT}/continue_complaint`
