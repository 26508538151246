import React, { useEffect, useState } from 'react'
import { Grid, Segment, Icon } from 'semantic-ui-react'

import { GET_DASHBOARD_GRADE_RESULT, GET_DASHBOARD_GRADE_RESULT_TYPE } from '../../../config/api/dashboard'
import { axiosAuthen } from '../../../config/axiosAuthen'
import DashboardGraphSummary from './GraphSummary'

type DashboardProps = {
    year?: any
    start_date?: string
    end_date?: string
}

const MakeData = [
    {
        grade_name: 'ดีมาก',
        count_complanits: 0,
    },
    {
        grade_name: 'ดี',
        count_complanits: 0,
    },
    {
        grade_name: 'ปานกลาง',
        count_complanits: 0,
    },
    {
        grade_name: 'พอใช้',
        count_complanits: 0,
    },
    {
        grade_name: 'ปรับปรุง',
        count_complanits: 0,
    },
]

const DashboardAverageResults: React.FC<DashboardProps> = (props) => {
    let { year, start_date, end_date } = props
    let [list_data, setListData] = useState<any | undefined>([])
    let [list_grade, setListGrade] = useState<any[]>([])

    useEffect(() => {
        axiosAuthen
            .get(
                `${GET_DASHBOARD_GRADE_RESULT}?year=${
                    year === 'all' ? '' : year
                }&start_date=${start_date}&end_date=${end_date}`
            )
            .then((response) => {
                let { value } = response.data
                setListData(value)
            })
            .catch(() => {
                setListData({ grade: 0, count: 0, grade_name: 'ปรับปรุง', score: 0, percent: 0 })
            })
    }, [end_date, start_date, year])

    useEffect(() => {
        axiosAuthen
            .get(
                `${GET_DASHBOARD_GRADE_RESULT_TYPE}?year=${
                    year === 'all' ? '' : year
                }&start_date=${start_date}&end_date=${end_date}`
            )
            .then((response) => {
                let { value } = response.data
                setListGrade(value)
            })
            .catch(() => {
                setListGrade(MakeData)
            })
    }, [end_date, start_date, year])

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Segment style={{ boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.25)' }}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <h2 style={{ textAlign: 'center' }}>ผลการประเมินโดยเฉลี่ย </h2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <h3 style={{ textAlign: 'center', color: '#9D9FA1' }}>จากคะแนนเต็ม 5 คะแนน </h3>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <h1 style={{ textAlign: 'center', color: '#293757' }}> {list_data.grade} </h1>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ textAlign: 'center', fontSize: '18px' }}>
                                <Grid.Column>
                                    <span style={{ color: '#818281' }}> ผลประเมินอยู่ในเกณฑ์ : </span>
                                    <span style={{ color: '#67C39B' }}> {list_data.grade_name} </span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ fontSize: '14px' }}>
                                <Grid.Column computer='1' />
                                <Grid.Column computer='6'>
                                    <h4>คำร้องที่ถูกประเมิน</h4>
                                </Grid.Column>
                                <Grid.Column computer='2'>
                                    <h4>{list_data.count}</h4>
                                </Grid.Column>
                                <Grid.Column computer='3'>
                                    <h4>คำร้อง</h4>
                                </Grid.Column>
                                <Grid.Column computer='3' />
                            </Grid.Row>
                            <Grid.Row style={{ fontSize: '14px' }}>
                                <Grid.Column computer='1' />
                                <Grid.Column computer='6'>
                                    <h4>คะแนนที่ได้จากการประเมิน</h4>
                                </Grid.Column>
                                <Grid.Column computer='2'>
                                    <h4>{list_data.score}</h4>
                                </Grid.Column>
                                <Grid.Column computer='3'>
                                    <h4>คะแนน</h4>
                                </Grid.Column>
                                <Grid.Column computer='2'>
                                    <h4>({list_data.percent})</h4>
                                </Grid.Column>
                                <Grid.Column computer='1' />
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment style={{ boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.25)' }}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <h2 style={{ textAlign: 'center' }}>ผลการประเมิน</h2>
                                </Grid.Column>
                            </Grid.Row>
                            {list_grade.map((data: any, index: number) => (
                                <Grid.Row key={index} style={{ fontSize: '18px' }}>
                                    <Grid.Column computer='1' />
                                    <Grid.Column computer='5'>
                                        {data.grade_name === 'ดีมาก' && (
                                            <Icon
                                                name='star'
                                                style={{
                                                    color: '#70B982',
                                                }}
                                            />
                                        )}
                                        {data.grade_name === 'ดี' && (
                                            <Icon
                                                name='star'
                                                style={{
                                                    color: '#B5CC18',
                                                }}
                                            />
                                        )}
                                        {data.grade_name === 'ปานกลาง' && (
                                            <Icon
                                                name='star'
                                                style={{
                                                    color: '#FBBD08',
                                                }}
                                            />
                                        )}
                                        {data.grade_name === 'พอใช้' && (
                                            <Icon
                                                name='star'
                                                style={{
                                                    color: '#F2711C',
                                                }}
                                            />
                                        )}
                                        {data.grade_name === 'ปรับปรุง' && (
                                            <Icon
                                                name='star'
                                                style={{
                                                    color: '#B6392D',
                                                }}
                                            />
                                        )}

                                        <span>{data.grade_name}</span>
                                    </Grid.Column>
                                    <Grid.Column computer='3'>
                                        <h4>{data.count_complanits}</h4>
                                    </Grid.Column>
                                    <Grid.Column computer='3'>
                                        <h4>คำร้อง</h4>
                                    </Grid.Column>
                                    {/* <Grid.Column computer='3'>
                                        <h4>(99.99%)</h4>
                                    </Grid.Column> */}
                                    <Grid.Column computer='1' />
                                </Grid.Row>
                            ))}
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment style={{ boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.25)' }}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column computer='16'>
                                    <DashboardGraphSummary year={year} start_date={start_date} end_date={end_date} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default DashboardAverageResults
