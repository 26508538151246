import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    Button,
    Container,
    Form,
    Grid,
    Icon,
    Image,
    Rating,
    RatingProps,
    Segment,
    TextArea,
    TextAreaProps,
} from 'semantic-ui-react'
import { InterfacePreComplaintDetail } from '..'
import ImagePattern from '../../../../../../assets/images/pattern.png'
import { alert } from '../../../../../../components/alert'
import {
    GET_NOTIFICATION_ONE_CHAT_DETAIL_PRE_COMPLAINT,
    PUT_NOTIFICATION_ONE_CHAT_EVALUATE_PRE_COMPLAINT,
} from '../../../../../../config/api/notification'
import { HOST } from '../../../../../../config/router'

const OneChatComplaintEvaluate: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [complaint_detail, setComplaintDetail] = useState<InterfacePreComplaintDetail | undefined>(undefined)
    let [loading, setLoading] = useState<boolean>(true)
    let [rating, setRating] = useState<string | number | undefined>(1)
    let [suggestion, setSuggestion] = useState<string | number | undefined>('')
    let [go_back, setGoBack] = useState<boolean>(false)
    let [municipality_id, setMunicipalityId] = useState<string | null>('')
    let [pre_complaint_id, setPreComplaintId] = useState<string | null>('')

    useEffect(() => {
        const getComplaintDetail = (pre_complaint_id: string, municipality_id: string) => {
            axios
                .get(`${GET_NOTIFICATION_ONE_CHAT_DETAIL_PRE_COMPLAINT}/${pre_complaint_id}/${municipality_id}`)
                .then((response) => {
                    let { value } = response.data
                    setComplaintDetail(value)
                    setLoading(false)
                    if (value.grade) {
                        history.push(
                            `detail-evaluate?pre_complaint_id=${pre_complaint_id}&municipality_id=${municipality_id}`
                        )
                    }
                })
        }
        const query = new URLSearchParams(location.search)
        const municipality_id = query.get('municipality_id')
        const pre_complaint_id = query.get('pre_complaint_id')
        setMunicipalityId(municipality_id)
        setPreComplaintId(pre_complaint_id)
        if (pre_complaint_id && municipality_id) getComplaintDetail(pre_complaint_id, municipality_id)

        if (location.state) {
            setGoBack(true)
        }
    }, [history, location])

    const handleOnRate = (_: any, { rating }: RatingProps) => {
        setRating(rating)
    }

    const handleChangeSuggestion = (_: any, { value }: TextAreaProps) => {
        setSuggestion(value)
    }

    const handleSubmit = () => {
        alert({
            type: 'confirm',
            title: 'ประเมินความพึงพอใจ',
            text: 'ยืนยันการประเมินความพึงพอใจหรือไม่',
            confirmText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onConfirm: () => {
                let body = {
                    id: Number(pre_complaint_id),
                    municipality_id: Number(municipality_id),
                    grade: rating,
                    suggestion: suggestion,
                }
                axios.put(PUT_NOTIFICATION_ONE_CHAT_EVALUATE_PRE_COMPLAINT, body).then(() => {
                    alert({
                        type: 'success',
                        title: 'บันทึกเสร็จสิ้น',
                        timer: 1500,
                        onClosed: () => {
                            history.goBack()
                        },
                    })
                })
            },
        })
    }

    return (
        <Fragment>
            <div
                style={{
                    backgroundRepeat: 'repeat',
                    backgroundImage: `url(${ImagePattern})`,
                    position: 'absolute',
                    height: 240,
                    zIndex: 1,
                    width: '100%',
                }}
            />
            <div
                style={{
                    backgroundImage: 'linear-gradient(#3D97A7, #1D3853)',
                    position: 'absolute',
                    height: 240,
                    width: '100%',
                }}
            />
            <Container>
                <Form style={{ zIndex: 2 }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{
                                    color: 'white',
                                    paddingTop: 14,
                                }}
                            >
                                <h2>ประเมินความพึงพอใจ</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column tablet='16' mobile='16'>
                                <Segment loading={loading}>
                                    <Grid>
                                        {go_back && (
                                            <Grid.Row>
                                                <Grid.Column tablet='16'>
                                                    <h3
                                                        onClick={() => {
                                                            history.goBack()
                                                        }}
                                                    >
                                                        <Icon name='angle left' style={{ color: '#2B6379' }} /> กลับ
                                                    </h3>
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <h3>
                                                    <Icon name='clipboard check' style={{ color: '#54ABB5' }} />{' '}
                                                    ประเมินความพึงพอใจ
                                                </h3>
                                            </Grid.Column>
                                        </Grid.Row>
                                        {complaint_detail?.files_result && complaint_detail?.files_result.length > 0 && (
                                            <Grid.Row>
                                                <Grid.Column tablet='16'>
                                                    <h5>ภาพประกอบ</h5>
                                                    {complaint_detail.files_result.map((file, index) => (
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Segment
                                                                key={index}
                                                                style={{
                                                                    width: 200,
                                                                    height: 200,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    padding: 5,
                                                                    margin: 5,
                                                                }}
                                                            >
                                                                <Image
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        maxHeight: 190,
                                                                        maxWidth: 190,
                                                                    }}
                                                                    src={`${HOST}${file.path_file}`}
                                                                />
                                                            </Segment>
                                                        </div>
                                                    ))}
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <h5 style={{ color: 'red', textAlign: 'center' }}>
                                                    กรุณาเลือกดาวตามความพอใจของท่าน
                                                </h5>
                                                <Segment>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Rating
                                                            name='rating'
                                                            maxRating={5}
                                                            icon='star'
                                                            size='massive'
                                                            onRate={handleOnRate}
                                                            rating={rating}
                                                        />
                                                    </div>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                หมายเหตุ
                                                <br />
                                                5 ดาว = ดีมาก, 4 ดาว = ดี, 3 ดาว = ปานกลาง,
                                                <br />2 ดาว = พอใช้, 1 ดาว = ปรับปรุง
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                ข้อเสนอแนะ
                                                <TextArea
                                                    name='suggestion'
                                                    value={suggestion}
                                                    onChange={handleChangeSuggestion}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column
                                                tablet='16'
                                                mobile='16'
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        marginRight: 10,
                                                        backgroundColor: '#38869C',
                                                        color: 'white',
                                                        border: '3px solid #38869C',
                                                    }}
                                                    onClick={handleSubmit}
                                                >
                                                    ยืนยัน
                                                </Button>
                                                <Button
                                                    style={{
                                                        marginLeft: 10,
                                                        backgroundColor: 'white',
                                                        color: '#38869C',
                                                        border: '3px solid #38869C',
                                                    }}
                                                    onClick={() => {
                                                        history.goBack()
                                                    }}
                                                >
                                                    ยกเลิก
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Container>
        </Fragment>
    )
}
export default OneChatComplaintEvaluate
