import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
    Button,
    Checkbox,
    Container,
    Dropdown,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    Label,
    Pagination,
    Radio,
    Segment,
    Table,
} from 'semantic-ui-react'
import { alert } from '../../../../../components/alert'
import CustomSegment from '../../../../../components/CustomSegment'
import CustomStep from '../../../../../components/CustomStep'
import { GET_MENU_PAGE_ACTION_ACTIVE } from '../../../../../config/api/manageMenu'
import {
    GET_USER_HAS_PERMISSION_MENU_DETAIL,
    GET_USER_LIST,
    POST_CREATE_AND_EDIT_USER_HAS_PERMISSION_MENU,
} from '../../../../../config/api/user'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { HOST } from '../../../../../config/router'

interface InterfaceListMenu {
    id: number
    name_th: string
    name_en: string
    pages: InterfaceListPage[]
}

interface InterfaceListPage {
    id: number
    name_th: string
    name_en: string
    actions: InterfaceListAction[]
}

interface InterfaceListAction {
    id: number
    name_th: string
    name_en: string
}

interface InterfaceValueUser {
    id: number
    name_th: string
    name_en: string
    roles_name: string
    directory_of_municipalities_name: string
    image: string
}
interface InterfaceValueMenu {
    id: number
    name_th: string
    name_en: string
}
interface InterfaceValuePage {
    parent_id: number
    id: number
    name_th: string
    name_en: string
}
interface InterfaceValueAction {
    super_parent_id: number
    parent_id: number
    id: number
    name_th: string
    name_en: string
}

const ManagementUsageUserMapping: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [municipality_id, setMunicipalityId] = useState<string>()
    let [active_step, setActiveStep] = useState<number>(1)
    let [active_segment, setActiveSegment] = useState<number>(1)
    let [list_permission, setListPermission] = useState<InterfaceListMenu[]>([])
    let [list_menu, setListMenu] = useState<InterfaceValueMenu[]>([])
    let [list_page, setListPage] = useState<InterfaceValuePage[]>([])
    let [list_action, setListAction] = useState<InterfaceValueAction[]>([])
    let [list_checked_menu, setListCheckedMenu] = useState<InterfaceValueMenu[]>([])
    let [list_checked_page, setListCheckedPage] = useState<InterfaceValuePage[]>([])
    let [list_checked_action, setListCheckedAction] = useState<InterfaceValueAction[]>([])
    let [list_user, setListUser] = useState<InterfaceValueUser[]>([])
    let [user_selected, setUserSelected] = useState<InterfaceValueUser | undefined>(undefined)
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    const getUserList = useCallback(() => {
        axiosAuthen
            .get(`${GET_USER_LIST}?municipality_id=${municipality_id}&page=${current_page}&offset=${row_qty}`)
            .then((response) => {
                let { value } = response.data
                setRecordQty(value.count)
                setListUser(value.users)
            })
    }, [current_page, municipality_id, row_qty])

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id, user } = location_state
            setMunicipalityId(municipality_id)
            if (user) {
                setUserSelected(user)
                // TODO : Get muni_has_permission_all/${muni_id}
                axiosAuthen
                    .get(`${GET_USER_HAS_PERMISSION_MENU_DETAIL}/${user.id}/${municipality_id}`)
                    .then((response) => {
                        let { value } = response.data
                        let list_checked_menu = []
                        let list_checked_page = []
                        let list_checked_action = []
                        for (const user of value) {
                            for (const menu of user.menu) {
                                list_checked_menu.push({ id: menu.id, name_th: menu.name_th, name_en: menu.name_en })

                                for (const page of menu.page) {
                                    list_checked_page.push({
                                        parent_id: menu.id,
                                        id: page.id,
                                        name_th: page.name_th,
                                        name_en: page.name_en,
                                    })
                                    for (const action of page.action) {
                                        list_checked_action.push({
                                            super_parent_id: menu.id,
                                            parent_id: page.id,
                                            id: action.id,
                                            name_th: action.name_th,
                                            name_en: action.name_en,
                                        })
                                    }
                                }
                            }
                        }

                        setListCheckedMenu([...list_checked_menu])
                        setListCheckedPage([...list_checked_page])
                        setListCheckedAction([...list_checked_action])
                    })
            }
            axiosAuthen.get(`${GET_MENU_PAGE_ACTION_ACTIVE}?municipality_id=${municipality_id}`).then((response) => {
                let { value } = response.data
                setListPermission(value)
                setListMenu(
                    value.map((data: InterfaceListMenu) => ({
                        id: data.id,
                        name_th: data.name_th,
                        name_en: data.name_en,
                    }))
                )
            })
            getUserList()
        }
    }, [getUserList, location])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePageTable = (page: number) => {
        setCurrentPage(page)
    }

    const handleClickUser = (data: InterfaceValueUser) => {
        setUserSelected(data)
    }

    const handleSelectMenu = (id: number) => {
        let menu = list_permission.find((data: InterfaceListMenu) => data.id === id)
        if (menu && menu.pages)
            setListPage(
                menu.pages.map((data: InterfaceListPage) => ({
                    parent_id: id,
                    id: data.id,
                    name_th: data.name_th,
                    name_en: data.name_en,
                }))
            )
        setListAction([])
    }

    const handleChangeMenu = (data_select: InterfaceValueMenu, checked: boolean | undefined) => {
        if (checked) {
            list_checked_menu.push(data_select)
        } else {
            list_checked_menu = list_checked_menu.filter((check: InterfaceValueMenu) => check.id !== data_select.id)
            let menu = list_permission.find((data: InterfaceListMenu) => data.id === data_select.id)
            if (menu && menu.pages) {
                let page = menu.pages.find((data: InterfaceListPage) => data.id === data_select.id)
                list_checked_page = list_checked_page.filter(
                    (check: InterfaceValuePage) =>
                        !menu?.pages.map((data: InterfaceListPage) => data.id).includes(check.id)
                )
                setListCheckedPage([...list_checked_page])
                if (page && page.actions) {
                    list_checked_action = list_checked_action.filter(
                        (check: InterfaceValueAction) =>
                            !page?.actions.map((data: InterfaceListAction) => data.id).includes(check.id)
                    )
                    setListCheckedAction([...list_checked_action])
                }
            }
        }
        setListCheckedMenu([...list_checked_menu])
    }

    const handleSelectPage = (data_select: InterfaceValuePage) => {
        let menu = list_permission.find((data: InterfaceListMenu) => data.id === data_select.parent_id)
        if (menu && menu.pages) {
            let page = menu.pages.find((data: InterfaceListPage) => data.id === data_select.id)
            if (page && page.actions) {
                setListAction(
                    page.actions.map((data: InterfaceListAction) => ({
                        super_parent_id: data_select.parent_id,
                        parent_id: data_select.id,
                        id: data.id,
                        name_th: data.name_th,
                        name_en: data.name_en,
                    }))
                )
            }
        }
    }

    const handleChangePage = (data_select: InterfaceValuePage, checked: boolean | undefined) => {
        if (checked) {
            list_checked_page.push(data_select)
            let menu = list_checked_menu.find((data: InterfaceValueMenu) => data.id === data_select.parent_id)
            if (!menu) {
                let menu = list_permission.find((data: InterfaceListMenu) => data.id === data_select.parent_id)
                if (menu && menu.pages) {
                    list_checked_menu.push({
                        id: menu.id,
                        name_th: menu.name_th,
                        name_en: menu.name_en,
                    })
                    setListCheckedMenu([...list_checked_menu])
                }
            }
        } else {
            list_checked_page = list_checked_page.filter((check: InterfaceValueMenu) => check.id !== data_select.id)
            let menu = list_permission.find((data: InterfaceListMenu) => data.id === data_select.parent_id)
            if (menu && menu.pages) {
                let page = menu.pages.find((data: InterfaceListPage) => data.id === data_select.id)
                if (page && page.actions) {
                    list_checked_action = list_checked_action.filter(
                        (check: InterfaceValueAction) =>
                            !page?.actions.map((data: InterfaceListAction) => data.id).includes(check.id)
                    )
                    setListCheckedAction([...list_checked_action])
                }
            }
        }
        setListCheckedPage([...list_checked_page])
    }

    const handleChangeAction = (data_select: InterfaceValueAction, checked: boolean | undefined) => {
        if (checked) {
            list_checked_action.push(data_select)
            let menu = list_checked_menu.find((data: InterfaceValueMenu) => data.id === data_select.super_parent_id)
            let page = list_checked_page.find((data: InterfaceValuePage) => data.id === data_select.parent_id)
            if (!menu) {
                let menu = list_permission.find((data: InterfaceListMenu) => data.id === data_select.super_parent_id)
                if (menu && menu.pages) {
                    list_checked_menu.push({
                        id: menu.id,
                        name_th: menu.name_th,
                        name_en: menu.name_en,
                    })
                    setListCheckedMenu([...list_checked_menu])
                }
            }
            if (!page) {
                let menu = list_permission.find((data: InterfaceListMenu) => data.id === data_select.super_parent_id)
                if (menu && menu.pages) {
                    let page = menu.pages.find((data: InterfaceListPage) => data.id === data_select.parent_id)
                    if (page) {
                        list_checked_page.push({
                            parent_id: data_select.parent_id,
                            id: page.id,
                            name_th: page.name_th,
                            name_en: page.name_en,
                        })
                        setListCheckedPage([...list_checked_page])
                    }
                }
            }
        } else {
            list_checked_action = list_checked_action.filter((check: InterfaceValueMenu) => check.id !== data_select.id)
        }
        setListCheckedAction([...list_checked_action])
    }

    const handleSubmit = () => {
        let body = {
            users_id: user_selected?.id,
            municipalities_id: municipality_id,
            menus: list_checked_menu.map((data: InterfaceValueMenu) => data.id),
            pages: list_checked_page.map((data: InterfaceValuePage) => data.id),
            actions: list_checked_action.map((data: InterfaceValueAction) => data.id),
        }
        axiosAuthen.post(POST_CREATE_AND_EDIT_USER_HAS_PERMISSION_MENU, body).then((response) => {
            alert({
                type: 'success',
                title: 'บันทึกเสร็จสิ้น',
                timer: 1500,
                onClosed: () => {
                    history.goBack()
                },
            })
        })
    }

    const handleGoBack = () => {
        setActiveStep(active_step - 1)
        let element = document.getElementById('pusher')
        element?.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleForward = () => {
        setActiveStep(active_step + 1)
        let element = document.getElementById('pusher')
        element?.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>จัดการการใช้งานของสมาชิกในองค์กร</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <CustomStep activeStep={active_step}>
                                    <CustomStep.Step
                                        icon='users'
                                        label='เลือกสมาชิก'
                                        size='big'
                                        onClick={() => {
                                            setActiveStep(1)
                                        }}
                                    />
                                    <CustomStep.Step
                                        icon='list'
                                        label='กำหนดการใช้งาน'
                                        size='big'
                                        onClick={() => {
                                            setActiveStep(2)
                                        }}
                                    />
                                    <CustomStep.Step
                                        icon='check'
                                        label='ตรวจสอบข้อมูล'
                                        size='big'
                                        onClick={() => {
                                            setActiveStep(3)
                                        }}
                                    />
                                </CustomStep>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {active_step === 1 && (
                                    <CustomSegment icon='users' header='เลือกสมาชิก'>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Label basic className='header-input-form'>
                                                        สมาชิกทั้งหมด
                                                    </Label>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column style={{ zIndex: 1 }}>
                                                    <Form>
                                                        <Table attached='top' celled>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell textAlign='center' width='1'>
                                                                        เลือก
                                                                    </Table.HeaderCell>
                                                                    <Table.HeaderCell textAlign='center' width='1'>
                                                                        ลำดับ
                                                                    </Table.HeaderCell>
                                                                    <Table.HeaderCell textAlign='center' width='8'>
                                                                        ชื่อ-สกุล
                                                                    </Table.HeaderCell>
                                                                    <Table.HeaderCell textAlign='center' width='6'>
                                                                        สังกัด
                                                                    </Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>
                                                        </Table>
                                                        <Table attached celled>
                                                            <Table.Body>
                                                                {list_user.map(
                                                                    (user: InterfaceValueUser, index: number) => (
                                                                        <Table.Row
                                                                            key={user.id}
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleClickUser(user)}
                                                                        >
                                                                            <Table.Cell textAlign='center' width='1'>
                                                                                <Radio
                                                                                    checked={
                                                                                        user.id === user_selected?.id
                                                                                    }
                                                                                />
                                                                            </Table.Cell>
                                                                            <Table.Cell textAlign='center' width='1'>
                                                                                <Label
                                                                                    circular
                                                                                    style={{
                                                                                        backgroundColor: '#425679',
                                                                                        color: '#FFFFFF',
                                                                                    }}
                                                                                >
                                                                                    {row_qty * (current_page - 1) +
                                                                                        index +
                                                                                        1}
                                                                                </Label>
                                                                            </Table.Cell>
                                                                            <Table.Cell width='8'>
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <div style={{ marginLeft: 10 }}>
                                                                                        <Image
                                                                                            style={{
                                                                                                width: 60,
                                                                                                height: 60,
                                                                                                borderRadius: '100%',
                                                                                            }}
                                                                                            src={`${HOST}${user.image}`}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{ marginLeft: 25 }}>
                                                                                        <Label size='large' circular>
                                                                                            {user.roles_name}
                                                                                        </Label>
                                                                                        <div style={{ marginTop: 10 }}>
                                                                                            <label
                                                                                                style={{
                                                                                                    fontWeight: 'bold',
                                                                                                }}
                                                                                            >
                                                                                                {user.name_th}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Table.Cell>
                                                                            <Table.Cell width='6'>
                                                                                {user.directory_of_municipalities_name}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )
                                                                )}
                                                            </Table.Body>
                                                        </Table>
                                                    </Form>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column
                                                    computer='5'
                                                    mobile='16'
                                                    tablet='5'
                                                    style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <label>
                                                            แสดงข้อมูลลำดับที่{' '}
                                                            {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1}{' '}
                                                            ถึง{' '}
                                                            {row_qty * (current_page - 1) + row_qty < record_qty
                                                                ? row_qty * (current_page - 1) + row_qty
                                                                : record_qty}{' '}
                                                            จากทั้งหมด {record_qty} ข้อมูล
                                                        </label>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column
                                                    computer='11'
                                                    mobile='16'
                                                    tablet='11'
                                                    style={{
                                                        display: 'flex',
                                                        marginTop: 10,
                                                        justifyContent: 'flex-end',
                                                    }}
                                                    floated='right'
                                                >
                                                    <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column
                                                                computer='5'
                                                                tablet='16'
                                                                mobile='16'
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <label style={{ alignSelf: 'center' }}>แสดง</label>
                                                                <Dropdown
                                                                    placeholder='จำนวน'
                                                                    selection
                                                                    compact
                                                                    fluid
                                                                    name='row_qty'
                                                                    value={row_qty}
                                                                    options={size_row}
                                                                    onChange={handleChangeRowSize}
                                                                    style={{
                                                                        marginLeft: 10,
                                                                        marginRight: 10,
                                                                        width: 80,
                                                                        paddingRight: 40,
                                                                    }}
                                                                />
                                                                <label
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                        minWidth: 'fit-content',
                                                                    }}
                                                                >
                                                                    {' แถว '}
                                                                </label>
                                                            </Grid.Column>
                                                            <Grid.Column
                                                                computer='11'
                                                                tablet='16'
                                                                mobile='16'
                                                                style={{ display: 'flex', justifyContent: 'center' }}
                                                            >
                                                                <label
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                        minWidth: 'fit-content',
                                                                    }}
                                                                >
                                                                    หน้า
                                                                </label>
                                                                <Pagination
                                                                    boundaryRange={1}
                                                                    activePage={current_page}
                                                                    siblingRange={1}
                                                                    name='current_page'
                                                                    size='small'
                                                                    totalPages={page_qty}
                                                                    firstItem={null}
                                                                    lastItem={null}
                                                                    onPageChange={(e: any, { activePage }: any) =>
                                                                        handleChangePageTable(
                                                                            Math.ceil(Number(activePage))
                                                                        )
                                                                    }
                                                                    style={{ marginLeft: 10 }}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </CustomSegment>
                                )}
                                {active_step === 2 && (
                                    <CustomSegment icon='file alternate' header='การใช้งานของระบบ'>
                                        <Grid>
                                            <Grid.Row style={{ marginTop: 40 }}>
                                                <Grid.Column computer={active_segment === 1 ? '6' : '5'}>
                                                    <Segment
                                                        style={
                                                            active_segment === 1
                                                                ? {
                                                                      border: '3.5px solid #383655',
                                                                      transform: 'scale(1.11)',
                                                                      zIndex: 1,
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                                : {
                                                                      border: '1px solid #C4C4C4',
                                                                      transform: 'scale(0.95)',
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                        }
                                                        onClick={() => {
                                                            setActiveSegment(1)
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 50,
                                                                height: 50,
                                                                backgroundColor: '#293757',
                                                                borderRadius: 50,
                                                                textAlign: 'center',
                                                                top: -32,
                                                                left: '50%',
                                                                marginLeft: -30,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            <div>1</div>
                                                        </div>
                                                        <h3 style={{ textAlign: 'center' }}>เมนู</h3>
                                                        <Input fluid icon='search' placeholder='ค้นหา' />
                                                        <div
                                                            style={{
                                                                overflowX: 'auto',
                                                                height: 240,
                                                                paddingRight: 5,
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            {list_menu.map(
                                                                (data: InterfaceValueMenu, index: number) => (
                                                                    <Segment
                                                                        key={index}
                                                                        onClick={() => {
                                                                            handleSelectMenu(data.id)
                                                                        }}
                                                                        style={
                                                                            Boolean(
                                                                                list_checked_menu.find(
                                                                                    (check: InterfaceValueMenu) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )
                                                                                ? {
                                                                                      border: '2px solid #383655',
                                                                                  }
                                                                                : {
                                                                                      border: '1px solid #C4C4C4',
                                                                                  }
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={Boolean(
                                                                                list_checked_menu.find(
                                                                                    (check: InterfaceValueMenu) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )}
                                                                            label={data.name_th}
                                                                            onChange={(_, { checked }) =>
                                                                                handleChangeMenu(data, checked)
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <div style={{ marginLeft: 25 }}>
                                                                            <label
                                                                                style={{
                                                                                    fontSize: 12,
                                                                                    color: '#8E8F92',
                                                                                }}
                                                                            >
                                                                                {data.name_en}
                                                                            </label>
                                                                        </div>
                                                                    </Segment>
                                                                )
                                                            )}
                                                        </div>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column computer={active_segment === 2 ? '6' : '5'}>
                                                    <Segment
                                                        style={
                                                            active_segment === 2
                                                                ? {
                                                                      border: '3.5px solid #383655',
                                                                      transform: 'scale(1.11)',
                                                                      zIndex: 1,
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                                : {
                                                                      border: '1px solid #C4C4C4',
                                                                      transform: 'scale(0.95)',
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                        }
                                                        onClick={() => {
                                                            setActiveSegment(2)
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 50,
                                                                height: 50,
                                                                backgroundColor: '#293757',
                                                                borderRadius: 50,
                                                                textAlign: 'center',
                                                                top: -32,
                                                                left: '50%',
                                                                marginLeft: -30,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            <div>2</div>
                                                        </div>
                                                        <h3 style={{ textAlign: 'center' }}>หน้าเว็บไซต์</h3>
                                                        <Input fluid icon='search' placeholder='ค้นหา' />
                                                        <div
                                                            style={{
                                                                overflowX: 'auto',
                                                                height: 240,
                                                                paddingRight: 5,
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            {list_page.map(
                                                                (data: InterfaceValuePage, index: number) => (
                                                                    <Segment
                                                                        key={index}
                                                                        onClick={() => {
                                                                            handleSelectPage(data)
                                                                        }}
                                                                        style={
                                                                            Boolean(
                                                                                list_checked_page.find(
                                                                                    (check: InterfaceValuePage) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )
                                                                                ? {
                                                                                      border: '2px solid #383655',
                                                                                  }
                                                                                : {
                                                                                      border: '1px solid #C4C4C4',
                                                                                  }
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={Boolean(
                                                                                list_checked_page.find(
                                                                                    (check: InterfaceValuePage) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )}
                                                                            label={data.name_th}
                                                                            onChange={(_, { checked }) =>
                                                                                handleChangePage(data, checked)
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <div style={{ marginLeft: 25 }}>
                                                                            <label
                                                                                style={{
                                                                                    fontSize: 12,
                                                                                    color: '#8E8F92',
                                                                                }}
                                                                            >
                                                                                {data.name_en}
                                                                            </label>
                                                                        </div>
                                                                    </Segment>
                                                                )
                                                            )}
                                                        </div>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column computer={active_segment === 3 ? '6' : '5'}>
                                                    <Segment
                                                        style={
                                                            active_segment === 3
                                                                ? {
                                                                      border: '3.5px solid #383655',
                                                                      transform: 'scale(1.11)',
                                                                      zIndex: 1,
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                                : {
                                                                      border: '1px solid #C4C4C4',
                                                                      transform: 'scale(0.95)',
                                                                      transition: 'transform .5s, filter .3s ease-out',
                                                                  }
                                                        }
                                                        onClick={() => {
                                                            setActiveSegment(3)
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 50,
                                                                height: 50,
                                                                backgroundColor: '#293757',
                                                                borderRadius: 50,
                                                                textAlign: 'center',
                                                                top: -32,
                                                                left: '50%',
                                                                marginLeft: -30,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            <div>3</div>
                                                        </div>
                                                        <h3 style={{ textAlign: 'center' }}>การใช้งาน</h3>
                                                        <Input fluid icon='search' placeholder='ค้นหา' />
                                                        <div
                                                            style={{
                                                                overflowX: 'auto',
                                                                height: 240,
                                                                paddingRight: 5,
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            {list_action.map(
                                                                (data: InterfaceValueAction, index: number) => (
                                                                    <Segment
                                                                        key={index}
                                                                        style={
                                                                            Boolean(
                                                                                list_checked_action.find(
                                                                                    (check: InterfaceValueAction) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )
                                                                                ? {
                                                                                      border: '2px solid #383655',
                                                                                  }
                                                                                : {
                                                                                      border: '1px solid #C4C4C4',
                                                                                  }
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={Boolean(
                                                                                list_checked_action.find(
                                                                                    (check: InterfaceValueAction) =>
                                                                                        check.id === data.id
                                                                                )
                                                                            )}
                                                                            label={data.name_th}
                                                                            onChange={(_, { checked }) =>
                                                                                handleChangeAction(data, checked)
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <div style={{ marginLeft: 25 }}>
                                                                            <label
                                                                                style={{
                                                                                    fontSize: 12,
                                                                                    color: '#8E8F92',
                                                                                }}
                                                                            >
                                                                                {data.name_en}
                                                                            </label>
                                                                        </div>
                                                                    </Segment>
                                                                )
                                                            )}
                                                        </div>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </CustomSegment>
                                )}
                                {active_step === 3 && (
                                    <CustomSegment icon='file alternate' header='ตรวจสอบข้อมูล'>
                                        <Container>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='3'>
                                                        <label className='header-input-form'>สมาชิก</label>
                                                    </Grid.Column>
                                                    <Grid.Column computer='7'>{user_selected?.name_th}</Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='3'>
                                                        <label className='header-input-form'>หัวข้อ</label>
                                                    </Grid.Column>
                                                    <Grid.Column computer='7'>
                                                        <Grid>
                                                            <Grid.Row>
                                                                {list_checked_menu.map((data: InterfaceValueMenu) => (
                                                                    <Grid.Column computer='8' key={data.id}>
                                                                        <Icon name='list' />
                                                                        {data.name_th}
                                                                    </Grid.Column>
                                                                ))}
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='3'>
                                                        <label className='header-input-form'>หัวข้อย่อย</label>
                                                    </Grid.Column>
                                                    <Grid.Column computer='7'>
                                                        <Grid>
                                                            <Grid.Row>
                                                                {list_checked_page.map((data: InterfaceValuePage) => (
                                                                    <Grid.Column computer='8' key={data.id}>
                                                                        <Icon name='list' />
                                                                        {data.name_th}
                                                                    </Grid.Column>
                                                                ))}
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column computer='3' only='computer' />
                                                    <Grid.Column computer='3'>
                                                        <label className='header-input-form'>เนื้อหา</label>
                                                    </Grid.Column>
                                                    <Grid.Column computer='7'>
                                                        <Grid>
                                                            <Grid.Row>
                                                                {list_checked_action.map(
                                                                    (data: InterfaceValueAction) => (
                                                                        <Grid.Column computer='8' key={data.id}>
                                                                            <Icon name='list' />
                                                                            {data.name_th}
                                                                        </Grid.Column>
                                                                    )
                                                                )}
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                    <Grid.Column computer='3' only='computer' />
                                                </Grid.Row>
                                            </Grid>
                                        </Container>
                                    </CustomSegment>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {active_step > 1 && (
                                    <Button
                                        type='button'
                                        floated='left'
                                        style={{ backgroundColor: '#293757', color: 'white' }}
                                        icon
                                        onClick={handleGoBack}
                                    >
                                        <Icon name='arrow left' /> ก่อนหน้า
                                    </Button>
                                )}
                                {active_step < 3 && (
                                    <Button
                                        type='button'
                                        floated='right'
                                        style={{ backgroundColor: '#293757', color: 'white' }}
                                        icon
                                        onClick={handleForward}
                                    >
                                        ถัดไป <Icon name='arrow right' />
                                    </Button>
                                )}
                                {active_step === 3 && (
                                    <Button
                                        floated='right'
                                        style={{ backgroundColor: '#293757', color: 'white' }}
                                        onClick={handleSubmit}
                                    >
                                        บันทึก
                                    </Button>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default ManagementUsageUserMapping
