import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
    Breadcrumb,
    Container,
    Dropdown,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Label,
    Pagination,
    Radio,
    Table,
} from 'semantic-ui-react'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import { GET_USER_ACTIVE_LIST, PUT_USER_ACTIVE_USER } from '../../../../config/api/user'
import { HOST } from '../../../../config/router'
import IconUser from '../../../../assets/images/icon-user.png'

interface InterfaceUser {
    id: number
    image: string
    line_user_id: boolean
    municipalities_name: string
    name: string
    one_chat_user_id: boolean
    phone_number: string
    roles_name: string
    active: boolean
}

const ManagementUsers: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [user_list, setUserList] = useState<InterfaceUser[]>([])
    let [loading, setLoading] = useState<boolean>(false)
    let [keyword, setKeyword] = useState<string>('')
    let [municipality, setMunicipality] = useState<string | number | undefined>()
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            if (municipality_id) setMunicipality(municipality_id)
        }
    }, [location])

    const getUsersList = useCallback(() => {
        if (municipality) {
            setLoading(true)
            axiosAuthen
                .get(
                    `${GET_USER_ACTIVE_LIST}?municipality_id=${municipality}&keyword=${keyword}&page=${current_page}&offset=${row_qty}`
                )
                .then((response) => {
                    let { value } = response.data
                    setRecordQty(value.count)
                    setUserList(value.users)
                    setLoading(false)
                })
        }
    }, [municipality, keyword, current_page, row_qty])

    useEffect(() => {
        getUsersList()
    }, [getUsersList, setMunicipality])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    const handleChangeActive = (user_id: number, active: boolean) => {
        axiosAuthen.put(PUT_USER_ACTIVE_USER, { id: user_id, active: !active }).then((response) => {
            getUsersList()
        })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    {localStorage.permission_en === 'super_admin' && (
                                        <Fragment>
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities`)
                                                }}
                                            >
                                                รายการองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                        municipality_id: municipality,
                                                    })
                                                }}
                                            >
                                                กำหนดการใช้งานองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </Fragment>
                                    )}
                                    <Breadcrumb.Section active>รายการสมาชิก</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>รายการสมาชิก</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {/* <Grid.Column computer='3' tablet='5' mobile='8' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={() => history.push('users/form')}>
                                    <Icon name='plus' /> เพิ่มสมาชิก
                                </Button>
                            </Grid.Column> */}
                            <Grid.Column computer='12' only='computer' />

                            <Grid.Column computer='4' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    placeholder='ค้นหาชื่อ/หมายเลขโทรศัพท์'
                                    icon='search'
                                    onChange={handleChangeKeyword}
                                    value={keyword}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form loading={loading}>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='8'>
                                                    ข้อมูลเจ้าหน้าที่
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='4'>
                                                    สังกัดองค์กร
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='3'>
                                                    สถานะ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    <Table attached celled className='table-body'>
                                        <Table.Body className='table-body body'>
                                            {user_list.length > 0 ? (
                                                user_list.map((data: InterfaceUser, index: number) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell
                                                            textAlign='center'
                                                            width='1'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                history.push('users/detail', { user_id: data.id })
                                                            }}
                                                        >
                                                            <Label
                                                                circular
                                                                style={{
                                                                    backgroundColor: '#425679',
                                                                    color: '#FFFFFF',
                                                                }}
                                                            >
                                                                {row_qty * (current_page - 1) + index + 1}
                                                            </Label>
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            width='8'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                history.push('users/detail', { user_id: data.id })
                                                            }}
                                                        >
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column
                                                                        computer='4'
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                        }}
                                                                    >
                                                                        <Image
                                                                            fluid
                                                                            circular
                                                                            src={
                                                                                data.image
                                                                                    ? `${HOST}${data.image}`
                                                                                    : IconUser
                                                                            }
                                                                            style={{ height: 75, width: 75 }}
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column
                                                                        computer='12'
                                                                        style={{
                                                                            alignSelf: 'center',
                                                                        }}
                                                                    >
                                                                        <div style={{ margin: 5 }}>
                                                                            <Label
                                                                                color='red'
                                                                                style={{ borderRadius: 100 }}
                                                                            >
                                                                                {data.roles_name}
                                                                            </Label>
                                                                        </div>
                                                                        <div style={{ margin: 5 }}>
                                                                            <Icon name='user' /> {data.name}
                                                                        </div>
                                                                        <div style={{ margin: 5 }}>
                                                                            <Icon name='phone' flipped='horizontally' />{' '}
                                                                            {data.phone_number}
                                                                        </div>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            width='4'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                history.push('users/detail', { user_id: data.id })
                                                            }}
                                                        >
                                                            {data.municipalities_name ? data.municipalities_name : '-'}
                                                        </Table.Cell>
                                                        <Table.Cell width='3' textAlign='center'>
                                                            <Radio
                                                                toggle
                                                                checked={data.active}
                                                                onChange={() =>
                                                                    handleChangeActive(data.id, data.active)
                                                                }
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))
                                            ) : (
                                                <Table.Row>
                                                    <Table.Cell textAlign='center' width='16'>
                                                        {'ไม่พบข้อมูล'}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                    paddingRight: 40,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column
                                            computer='11'
                                            tablet='16'
                                            mobile='16'
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default ManagementUsers
