import React, { Fragment, useEffect, useState } from 'react'
import {
    Container,
    Dropdown,
    Grid,
    Segment,
    Input,
    Label,
    Pagination,
    Table,
    Breadcrumb,
    InputOnChangeData,
    Form,
    Button,
    Icon,
    Image,
    DropdownProps,
} from 'semantic-ui-react'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import { useHistory } from 'react-router'
import {
    DELETE_MATCH_USERS_AND_TOPIC_BY_USER,
    GET_MATCH_USERS_AND_TOPIC,
} from '../../../../../config/api/matchUsersAndTopic'
import { GET_ROLES_LIST } from '../../../../../config/api/roles'
import { GET_DIRECTORY_OF_MUNICIPALITY_LEVEL_DIRECTORY_ALL } from '../../../../../config/api/directoryOfMunicipality'

export interface InterfaceComplaint {
    municipalities_id: string
    directory_of_municipalities_id: number
    id: number
    name: string
    short_name: string
    updated_at?: string
    updated_by?: number
    directory_name: string
    complaint_types: InterfaceComplaintTypes[]
}

export interface InterfaceComplaintTypes {
    areas_id: number
    id: string
    name: string
    updated_at?: string
    updated_by?: number
    complaint_topics_id: number
    created_at?: string
    created_by?: number
}

const ListMember: React.FC = () => {
    let history = useHistory()
    let municipalities_id = localStorage.municipality_id
    let [roles_option, setRolesOption] = useState<any[]>([])
    let [roles, setRoles] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [keyword, setKeyword] = useState<string>('')
    let [loading, setLoading] = useState<boolean>(false)
    let [open_modal_edit] = useState<boolean>(false)
    let [show, setShow] = useState<boolean>(false)
    let [users_and_topic, setUsersAndTopic] = useState<any[]>([])
    let [level_directory_option, setLevelDirectoryOption] = useState<any[]>([])
    let [level_directory, setLevelDirectory] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >('')
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    useEffect(() => {
        getLevelDirectory()
    }, [])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    useEffect(() => {
        const getRolesList = () => {
            setLoading(true)
            axiosAuthen
                .get(
                    `${GET_ROLES_LIST}?keyword=${keyword}&page=${current_page}&offset=${row_qty}&municipality_id=${municipalities_id}`
                )
                .then((response) => {
                    let { value } = response.data
                    let option = value.roles_list.map((data: any) => ({
                        value: data.id,
                        text: data.name_th,
                    }))
                    setRolesOption(option)
                })
        }
        getRolesList()
    }, [keyword, current_page, row_qty, municipalities_id])

    const handleChangeRoles = (_: any, { value }: DropdownProps) => {
        setRoles(value)
    }

    useEffect(() => {
        const getListMatchUsersAndTopic = () => {
            setLoading(true)
            axiosAuthen
                .get(`${GET_MATCH_USERS_AND_TOPIC}?keyword=${keyword}&page=${current_page}&offset=${row_qty}`)
                .then((response) => {
                    let { value } = response.data

                    setRecordQty(value.count)
                    setUsersAndTopic(value.match_users_and_topics)
                    setLoading(false)
                })
        }
        getListMatchUsersAndTopic()
    }, [keyword, current_page, row_qty, open_modal_edit])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeLevelDirectory = (_: any, { value }: DropdownProps) => {
        setLevelDirectory(value)
    }

    const getLevelDirectory = () => {
        axiosAuthen.get(`${GET_DIRECTORY_OF_MUNICIPALITY_LEVEL_DIRECTORY_ALL}`).then((response) => {
            let { value } = response.data
            let option = value.map((data: any) => ({
                value: data.id,
                text: data.name,
            }))
            setLevelDirectoryOption(option)
        })
    }

    const handleClearSearch = () => {
        setLevelDirectory('')
        setRoles('')
    }

    const handleShow = (data: boolean) => {
        setShow(!data)
        setLevelDirectory('')
        setRoles('')
    }

    const handleCloesShow = () => {
        setShow(false)
        setLevelDirectory('')
        setRoles('')
    }

    const handleDelete = (data: any) => {
        axiosAuthen.delete(`${DELETE_MATCH_USERS_AND_TOPIC_BY_USER}?match_id=${data.users_id}`).then((response) => {
            setLoading(true)
            axiosAuthen
                .get(`${GET_MATCH_USERS_AND_TOPIC}?keyword=${keyword}&page=${current_page}&offset=${row_qty}`)
                .then((response) => {
                    let { value } = response.data

                    setRecordQty(value.count)
                    setUsersAndTopic(value.match_users_and_topics)
                    setLoading(false)
                })
        })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Breadcrumb>
                                    {localStorage.permission_en === 'super_admin' && (
                                        <Fragment>
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities`, {
                                                        municipality_id: municipalities_id,
                                                    })
                                                }}
                                            >
                                                รายการองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section
                                                link
                                                onClick={() => {
                                                    history.push(`/${localStorage.permission_en}/municipalities/menu`, {
                                                        municipality_id: municipalities_id,
                                                    })
                                                }}
                                            >
                                                กำหนดการใช้งานองค์กร
                                            </Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </Fragment>
                                    )}

                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/management/system`)
                                        }}
                                    >
                                        จัดการระบบ
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>รายการสมาชิก</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>รายการสมาชิก</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='10' only='computer' />
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={() => handleShow(show)}
                                >
                                    <Icon name='filter' /> กรองข้อมูล
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    placeholder='ค้นหา'
                                    icon='search'
                                    name='keyword'
                                    value={keyword}
                                    onChange={handleChangeKeyword}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16'>
                                {show === true && (
                                    <Segment style={{ border: '2px solid #293757', borderRadius: '20px' }}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='1' />
                                                <Grid.Column computer='14'>
                                                    <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column computer='5'>
                                                                <Form>
                                                                    <Form.Field>
                                                                        <label>สิทธิ์การใช้งาน</label>
                                                                        <Dropdown
                                                                            fluid
                                                                            selection
                                                                            search
                                                                            name='roles'
                                                                            value={roles}
                                                                            options={roles_option}
                                                                            onChange={handleChangeRoles}
                                                                        />
                                                                    </Form.Field>
                                                                </Form>
                                                            </Grid.Column>
                                                            <Grid.Column computer='5'>
                                                                <Form>
                                                                    <Form.Field>
                                                                        <label>หน่วยงาน</label>
                                                                        <Dropdown
                                                                            fluid
                                                                            selection
                                                                            search
                                                                            name='level_directory'
                                                                            value={level_directory}
                                                                            options={level_directory_option}
                                                                            onChange={handleChangeLevelDirectory}
                                                                        />
                                                                    </Form.Field>
                                                                </Form>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column computer='12' />
                                                            <Grid.Column computer='4'>
                                                                <Button type='submit' onClick={handleClearSearch}>
                                                                    ล้างการค้นหา
                                                                </Button>
                                                                <Button type='submit' onClick={handleCloesShow}>
                                                                    ปิด
                                                                </Button>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Grid.Column>
                                                <Grid.Column computer='1' />
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form loading={loading}>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='4' textAlign='center'>
                                                    ข้อมูลเจ้าหน้าที่
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='2' textAlign='center'>
                                                    หน่วยงาน
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='3' textAlign='center'>
                                                    งานที่รับผิดชอบ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='1' textAlign='center'>
                                                    จัดการ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    <Table attached celled className='table-body'>
                                        <Table.Body className='table-body body'>
                                            {users_and_topic === null ? (
                                                <Table.Row>
                                                    <Table.Cell textAlign='center' width='16'>
                                                        {'ไม่พบข้อมูล'}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ) : (
                                                users_and_topic.map((data: any, index: number) => (
                                                    <Table.Row key={data.id} style={{ color: '#1E1F26' }}>
                                                        <Table.Cell textAlign='center' width='1'>
                                                            <Label
                                                                circular
                                                                style={{ backgroundColor: '#425679', color: '#FFFFFF' }}
                                                            >
                                                                {row_qty * (current_page - 1) + index + 1}
                                                            </Label>
                                                        </Table.Cell>
                                                        <Table.Cell width='3'>
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column
                                                                        computer='4'
                                                                        style={{ textAlign: 'center' }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                            }}
                                                                        >
                                                                            <Image
                                                                                style={{
                                                                                    display: 'inline-block',
                                                                                    border: ' 3px solid #293757',
                                                                                    padding: 3,
                                                                                }}
                                                                                src={data.image}
                                                                                size='medium'
                                                                                circular
                                                                            />
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer='12'>
                                                                        <Label color='orange'>{data.role_th}</Label>
                                                                        <br />
                                                                        <br />
                                                                        <Icon name='user' /> {data.full_name_th}
                                                                        <br />
                                                                        <br />
                                                                        <Icon name='phone' /> {data.phone}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Table.Cell>
                                                        <Table.Cell width='2'>{data.directory_name}</Table.Cell>
                                                        <Table.Cell width='3'>
                                                            {data.match_users_and_topics.map(
                                                                (data: any, index: number) => (
                                                                    <Fragment>
                                                                        {data.complaint_topic_name} <br />
                                                                    </Fragment>
                                                                )
                                                            )}
                                                        </Table.Cell>
                                                        <Table.Cell width='1' textAlign='center'>
                                                            <Dropdown icon='ellipsis vertical' pointing='right'>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            history.push(
                                                                                `/${localStorage.permission_en}/management/system/member/detail`,
                                                                                localStorage.setItem(
                                                                                    'user_id',
                                                                                    data.users_id
                                                                                )
                                                                            )
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name='edit'
                                                                            circular
                                                                            style={{
                                                                                color: '#F2CC6B',
                                                                                border: '1px solid #F2CC6B',
                                                                                boxShadow: 'none',
                                                                            }}
                                                                        />
                                                                        <label style={{ cursor: 'pointer' }}>
                                                                            แก้ไข
                                                                        </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item onClick={() => handleDelete(data)}>
                                                                        <Icon
                                                                            name='trash alternate'
                                                                            circular
                                                                            style={{
                                                                                color: '#9F3A38',
                                                                                border: '1px solid #9F3A38',
                                                                                boxShadow: 'none',
                                                                            }}
                                                                        />
                                                                        <label style={{ cursor: 'pointer' }}>ลบ</label>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))
                                            )}
                                        </Table.Body>
                                    </Table>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row only='computer tablet'>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column computer='11' style={{ display: 'flex' }} only='computer'>
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default ListMember
