import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonIconOver from '../../../../../components/ButtonIconOver'

type FormProps = {
    complaint_id?: string | number
}

const ExportReport: React.FC<FormProps> = (props) => {
    let history = useHistory()
    const handleClick = () => {
        history.push(`detail/export`, { complaint_id: props.complaint_id })
    }
    return (
        <Fragment>
            <ButtonIconOver fluid label='ออกเอกสาร' color='#3897E0' icon='file pdf' basic onClick={handleClick} />
        </Fragment>
    )
}
export default ExportReport
