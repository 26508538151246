import React, { Fragment, useEffect, useState } from 'react'
import {
    Button,
    Checkbox,
    CheckboxProps,
    Container,
    Dropdown,
    DropdownOnSearchChangeData,
    DropdownProps,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    InputOnChangeData,
    Segment,
} from 'semantic-ui-react'
import ThaiAddress from '../../../../constants/ThaiAddress.json'
import IconMan from '../../../../assets/images/man.png'
import IconWoman from '../../../../assets/images/woman.png'
import IconWoman2 from '../../../../assets/images/woman1.png'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import { GET_NOTIFICATION_LINE_PROFILE, PUT_NOTIFICATION_LINE_EDIT_PROFILE } from '../../../../config/api/notification'
import { alert } from '../../../../components/alert'
import CustomSegment from '../../../../components/CustomSegment'
import { GET_MUNICIPALITY_PERSON_TYPES_BY_MUNICIPALITY_ID } from '../../../../config/api/municipality'

const LineEditProfile: React.FC = () => {
    let [loading, setLoading] = useState<boolean>(false)
    let [line_user_id, setLineUserId] = useState<string | null>('')
    let [prefix, setPrefix] = useState<string>('')
    let [first_name, setFirstName] = useState<string>('')
    let [last_name, setLastName] = useState<string>('')
    let [mobile_number, setMobileNumber] = useState<string>('')
    let [house_no, setHouseNo] = useState<string>('')
    let [village_no, setVillageNo] = useState<string>('')
    let [alley, setAlley] = useState<string>('')
    let [road, setRoad] = useState<string>('')
    let [sub_district, setSubDistrict] = useState<string>('')
    let [district, setDistrict] = useState<string>('')
    let [province, setProvince] = useState<string>('')
    let [zipcode, setZipCode] = useState<string>('')
    let [option_sub_district, setOptionSubDistrict] = useState<{ value: string; text: string }[]>()
    let [option_district, setOptionDistrict] = useState<{ value: string; text: string }[]>()
    let [option_province, setOptionProvince] = useState<{ value: string; text: string }[]>()
    let [option_zipcode, setOptionZipCode] = useState<{ value: string; text: string }[]>()
    let [prefix_error, setPrefixError] = useState<boolean>(false)
    let [first_name_error, setFirstNameError] = useState<boolean>(false)
    let [last_name_error, setLastNameError] = useState<boolean>(false)
    let [mobile_number_error, setMobileNumberError] = useState<boolean>(false)
    let [house_no_error, setHouseNoError] = useState<boolean>(false)
    let [village_no_error, setVillageNoError] = useState<boolean>(false)
    let [sub_district_error, setSubDistrictError] = useState<boolean>(false)
    let [district_error, setDistrictError] = useState<boolean>(false)
    let [province_error, setProvinceError] = useState<boolean>(false)
    let [zipcode_error, setZipCodeError] = useState<boolean>(false)
    let thai_address = ThaiAddress
    let location = useLocation()
    let history = useHistory()
    let [municipality, setMunicipality] = useState<string>('')
    let [line_display_name, setLineDisplayName] = useState<string>('')
    let [person_type, setPersonTypes] = useState<number>(0)
    let [use_line_display_name, setUseLineDisplayName] = useState<boolean | undefined>(false)

    useEffect(() => {
        const getProfile = (municipality_id: string, line_user_id: string) => {
            setLoading(true)
            axios.get(`${GET_NOTIFICATION_LINE_PROFILE}/${municipality_id}/${line_user_id}`).then((response) => {
                let { value } = response.data
                setPrefix(value.prefix_th)
                setFirstName(value.first_name_th)
                setLastName(value.last_name_th)
                setMobileNumber(value.phone_number)
                setHouseNo(value.house_no)
                setVillageNo(value.village_no)
                setAlley(value.alley)
                setRoad(value.road)
                setSubDistrict(value.sub_district)
                setDistrict(value.district)
                setProvince(value.province)
                setZipCode(value.zipcode)
                setOptionSubDistrict([{ value: value.sub_district, text: value.sub_district }])
                setOptionDistrict([{ value: value.district, text: value.district }])
                setOptionProvince([{ value: value.province, text: value.province }])
                setOptionZipCode([{ value: value.zipcode, text: value.zipcode }])
                setLoading(false)
            })
        }

        const query = new URLSearchParams(location.search)
        const municipality = query.get('municipality_id')
        municipality && setMunicipality(municipality)
        const line_user_id = query.get('line_user_id')
        setLineUserId(line_user_id)
        const line_display_name = query.get('line_display_name')
        line_display_name && setLineDisplayName(line_display_name)
        municipality && line_user_id && getProfile(municipality, line_user_id)
        const getPersonType = () => {
            axios.get(`${GET_MUNICIPALITY_PERSON_TYPES_BY_MUNICIPALITY_ID}/${municipality}`).then((response) => {
                let { value } = response.data
                setPersonTypes(value.id)
            })
        }
        getPersonType()
    }, [location])

    const handleChangeCheckBox = (_: any, { checked }: CheckboxProps) => {
        setUseLineDisplayName(checked)
        if (checked) {
        }
        setPrefixError(false)
        setFirstNameError(false)
        setLastNameError(false)
    }
    const handleChangePrefix = (prefix: string) => {
        setPrefix(prefix)
        setPrefixError(false)
    }

    const handleChangeFirstName = (_: any, { value }: InputOnChangeData) => {
        setFirstName(value)
        setFirstNameError(false)
    }
    const handleChangeLastname = (_: any, { value }: InputOnChangeData) => {
        setLastName(value)
        setLastNameError(false)
    }
    const handleChangeMobileNumber = (_: any, { value }: InputOnChangeData) => {
        setMobileNumber(value)
        setMobileNumberError(false)
    }
    const handleChangeHouseNo = (_: any, { value }: InputOnChangeData) => {
        setHouseNo(value)
        setHouseNoError(false)
    }
    const handleChangeVillageNo = (_: any, { value }: InputOnChangeData) => {
        setVillageNo(value)
        setVillageNoError(false)
    }
    const handleChangeAlley = (_: any, { value }: InputOnChangeData) => {
        setAlley(value)
    }
    const handleChangeRoad = (_: any, { value }: InputOnChangeData) => {
        setRoad(value)
    }

    const handleChangeAddress = (_: any, { name, value }: DropdownProps) => {
        let address = String(value).split(' >> ')
        setSubDistrict(address[0])
        setDistrict(address[1])
        setProvince(address[2])
        setZipCode(address[3])
        setOptionSubDistrict([{ value: address[0], text: address[0] }])
        setOptionDistrict([{ value: address[1], text: address[1] }])
        setOptionProvince([{ value: address[2], text: address[2] }])
        setOptionZipCode([{ value: address[3], text: address[3] }])
        setSubDistrictError(false)
        setDistrictError(false)
        setProvinceError(false)
        setZipCodeError(false)
    }

    const searchSubDistrict = (_: any, { searchQuery }: DropdownOnSearchChangeData) => {
        if (searchQuery) {
            let option: { value: string; text: string }[] = []
            let row = 0
            thai_address.forEach((value: any) => {
                if (value.sub_district.includes(searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode

                        option.push({
                            value: text_address,
                            text: text_address,
                        })

                        row++
                    }
                }
            })
            setOptionSubDistrict(option)
        }
    }

    const searchDistrict = (_: any, { searchQuery }: DropdownOnSearchChangeData) => {
        if (searchQuery) {
            let option: { value: string; text: string }[] = []
            let row = 0
            thai_address.forEach((value: any) => {
                if (value.district.includes(searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode

                        option.push({
                            text: text_address,
                            value: text_address,
                        })

                        row++
                    }
                }
            })
            setOptionDistrict(option)
        }
    }

    const searchProvince = (_: any, { searchQuery }: DropdownOnSearchChangeData) => {
        if (searchQuery) {
            let option: { value: string; text: string }[] = []
            let row = 0
            thai_address.forEach((value: any) => {
                if (value.province.includes(searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode

                        option.push({
                            text: text_address,
                            value: text_address,
                        })

                        row++
                    }
                }
            })
            setOptionProvince(option)
        }
    }

    const searchZipcode = (_: any, { searchQuery }: DropdownOnSearchChangeData) => {
        if (searchQuery) {
            let option: { value: string; text: string }[] = []
            let row = 0
            thai_address.forEach((value: any) => {
                if (String(value.zipcode).includes(searchQuery)) {
                    if (row < 50) {
                        let text_address =
                            value.sub_district +
                            ' >> ' +
                            value.district +
                            ' >> ' +
                            value.province +
                            ' >> ' +
                            value.zipcode

                        option.push({
                            text: text_address,
                            value: text_address,
                        })
                        row++
                    }
                }
            })
            setOptionZipCode(option)
        }
    }

    const validation = () => {
        let error = false
        if (person_type === 1) {
            if (!prefix) {
                setPrefixError(true)
                error = true
            }
            if (!first_name) {
                setFirstNameError(true)
                error = true
            }
            if (!last_name) {
                setLastNameError(true)
                error = true
            }
            if (!mobile_number || mobile_number.length !== 10 || isNaN(Number(mobile_number))) {
                setMobileNumberError(true)
                error = true
            }
            if (!house_no) {
                setHouseNoError(true)
                error = true
            }
            if (!sub_district) {
                setSubDistrictError(true)
                error = true
            }
            if (!district) {
                setDistrictError(true)
                error = true
            }
            if (!province) {
                setProvinceError(true)
                error = true
            }
            if (!zipcode) {
                setZipCodeError(true)
                error = true
            }
        }

        return error
    }

    const handleSubmit = () => {
        if (person_type === 1 && !validation()) {
            let body = {
                line_user_id: line_user_id,
                prefix: prefix,
                first_name: first_name,
                last_name: last_name,
                mobile_number: mobile_number,
                house_no: house_no,
                village_no: village_no,
                alley: alley,
                road: road,
                subdistrict: sub_district,
                district: district,
                province: province,
                zipcode: zipcode,
                municipality_id: Number(municipality),
            }
            axios.put(PUT_NOTIFICATION_LINE_EDIT_PROFILE, body).then(() => {
                alert({
                    type: 'success',
                    title: 'บันทึกเสร็จสิ้น',
                    timer: 1500,
                    onClosed: () => {
                        setLoading(false)
                        history.goBack()
                    },
                })
            })
        }
        if (person_type !== 1) {
            let body_first_name = first_name
            let body_last_name = last_name
            if (use_line_display_name) {
                body_first_name = line_display_name
                body_last_name = ''
            }
            let body = {
                line_user_id: line_user_id,
                prefix: prefix,
                first_name: body_first_name,
                last_name: body_last_name,
                mobile_number: mobile_number,
                house_no: house_no,
                village_no: village_no,
                alley: alley,
                road: road,
                subdistrict: sub_district,
                district: district,
                province: province,
                zipcode: zipcode,
                municipality_id: Number(municipality),
            }
            axios.put(PUT_NOTIFICATION_LINE_EDIT_PROFILE, body).then(() => {
                alert({
                    type: 'success',
                    title: 'บันทึกเสร็จสิ้น',
                    timer: 1500,
                    onClosed: () => {
                        setLoading(false)
                        history.goBack()
                    },
                })
            })
        }
    }
    return (
        <Fragment>
            <Container>
                <Form style={{ zIndex: 2 }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column tablet='16' mobile='16'>
                                <Button
                                    style={{ margin: 10 }}
                                    primary
                                    icon
                                    labelPosition='left'
                                    onClick={() => history.goBack()}
                                >
                                    <Icon name='chevron left' /> กลับ
                                </Button>
                                <CustomSegment loading={loading} icon='user circle' header='ข้อมูลผู้แจ้งคำร้อง'>
                                    <Grid>
                                        {person_type === 2 && (
                                            <Grid.Row>
                                                <Grid.Column tablet='16'>
                                                    <Checkbox
                                                        label={
                                                            <label style={{ fontWeight: 'bold' }}>ใช้ชื่อจากไลน์</label>
                                                        }
                                                        checked={use_line_display_name}
                                                        onChange={handleChangeCheckBox}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        {!use_line_display_name && (
                                            <Fragment>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <h5>คำนำหน้า</h5>
                                                        <Grid>
                                                            <Grid.Row columns='equal'>
                                                                <Grid.Column>
                                                                    <Segment
                                                                        style={
                                                                            prefix === 'นาย'
                                                                                ? {
                                                                                      border: '4px solid #3D97A7',
                                                                                      borderRadius: 10,
                                                                                  }
                                                                                : {}
                                                                        }
                                                                        onClick={() => {
                                                                            handleChangePrefix('นาย')
                                                                        }}
                                                                    >
                                                                        <Image fluid src={IconMan} />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 5,
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            นาย
                                                                        </div>
                                                                    </Segment>
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    <Segment
                                                                        style={
                                                                            prefix === 'นาง'
                                                                                ? {
                                                                                      border: '4px solid #3D97A7',
                                                                                      borderRadius: 10,
                                                                                  }
                                                                                : {}
                                                                        }
                                                                        onClick={() => {
                                                                            handleChangePrefix('นาง')
                                                                        }}
                                                                    >
                                                                        <Image fluid src={IconWoman2} />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 5,
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            นาง
                                                                        </div>
                                                                    </Segment>
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    <Segment
                                                                        style={
                                                                            prefix === 'นางสาว'
                                                                                ? {
                                                                                      border: '4px solid #3D97A7',
                                                                                      borderRadius: 10,
                                                                                  }
                                                                                : {}
                                                                        }
                                                                        onClick={() => {
                                                                            handleChangePrefix('นางสาว')
                                                                        }}
                                                                    >
                                                                        <Image fluid src={IconWoman} />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 5,
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            นางสาว
                                                                        </div>
                                                                    </Segment>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            {prefix_error && (
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <span style={{ color: '#db2828' }}>
                                                                            * โปรดเลือก
                                                                        </span>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )}
                                                        </Grid>
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <Form.Field error={first_name_error}>
                                                            <h5>
                                                                ชื่อ{' '}
                                                                <span style={{ color: '#db2828', fontSize: '20px' }}>
                                                                    *
                                                                </span>
                                                            </h5>
                                                            <Input
                                                                fluid
                                                                name='first_name'
                                                                value={first_name}
                                                                onChange={handleChangeFirstName}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <Form.Field error={last_name_error}>
                                                            <h5>
                                                                นามสกุล{' '}
                                                                <span style={{ color: '#db2828', fontSize: '20px' }}>
                                                                    *
                                                                </span>
                                                            </h5>
                                                            <Input
                                                                fluid
                                                                name='last_name'
                                                                value={last_name}
                                                                onChange={handleChangeLastname}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Fragment>
                                        )}
                                        <Grid.Row>
                                            <Grid.Column tablet='16'>
                                                <Form.Field error={mobile_number_error}>
                                                    <h5>
                                                        โทรศัพท์ (มือถือ){' '}
                                                        <span style={{ color: '#db2828', fontSize: '20px' }}>*</span>
                                                    </h5>
                                                    <Input
                                                        fluid
                                                        name='mobile_number'
                                                        value={mobile_number}
                                                        onChange={handleChangeMobileNumber}
                                                        type='tel'
                                                        maxLength={10}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </CustomSegment>
                            </Grid.Column>
                        </Grid.Row>
                        {person_type === 1 && (
                            <Fragment>
                                <Grid.Row>
                                    <Grid.Column tablet='16' mobile='16'>
                                        <CustomSegment
                                            loading={loading}
                                            icon='user circle'
                                            header='ที่อยู่ผู้แจ้งคำร้อง'
                                        >
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <Form.Field error={house_no_error}>
                                                            <h5>
                                                                บ้านเลขที่{' '}
                                                                <span style={{ color: '#db2828', fontSize: '20px' }}>
                                                                    *
                                                                </span>
                                                            </h5>
                                                            <Input
                                                                fluid
                                                                name='house_no'
                                                                value={house_no}
                                                                onChange={handleChangeHouseNo}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <Form.Field error={village_no_error}>
                                                            <h5>
                                                                หมู่ที่{' '}
                                                                {/* <span style={{ color: '#db2828', fontSize: '20px' }}>*</span> */}
                                                            </h5>
                                                            <Input
                                                                fluid
                                                                name='village_no'
                                                                value={village_no}
                                                                onChange={handleChangeVillageNo}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <h5>ซอย</h5>
                                                        <Input
                                                            fluid
                                                            name='alley'
                                                            value={alley}
                                                            onChange={handleChangeAlley}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <h5>ถนน</h5>
                                                        <Input
                                                            fluid
                                                            name='road'
                                                            value={road}
                                                            onChange={handleChangeRoad}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <Form.Field error={sub_district_error}>
                                                            <h5>
                                                                ตำบล{' '}
                                                                <span style={{ color: '#db2828', fontSize: '20px' }}>
                                                                    *
                                                                </span>
                                                            </h5>
                                                            <Dropdown
                                                                selection
                                                                search
                                                                fluid
                                                                id='sub_district'
                                                                name='sub_district'
                                                                onChange={handleChangeAddress}
                                                                options={option_sub_district}
                                                                value={sub_district}
                                                                onSearchChange={searchSubDistrict}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <Form.Field error={district_error}>
                                                            <h5>
                                                                อำเภอ{' '}
                                                                <span style={{ color: '#db2828', fontSize: '20px' }}>
                                                                    *
                                                                </span>
                                                            </h5>
                                                            <Dropdown
                                                                selection
                                                                search
                                                                fluid
                                                                id='district'
                                                                name='district'
                                                                onChange={handleChangeAddress}
                                                                options={option_district}
                                                                value={district}
                                                                onSearchChange={searchDistrict}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <Form.Field error={province_error}>
                                                            <h5>
                                                                จังหวัด{' '}
                                                                <span style={{ color: '#db2828', fontSize: '20px' }}>
                                                                    *
                                                                </span>
                                                            </h5>

                                                            <Dropdown
                                                                selection
                                                                search
                                                                fluid
                                                                id='province'
                                                                name='province'
                                                                onChange={handleChangeAddress}
                                                                options={option_province}
                                                                value={province}
                                                                onSearchChange={searchProvince}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column tablet='16'>
                                                        <Form.Field error={zipcode_error}>
                                                            <h5>
                                                                รหัสไปรษณีย์{' '}
                                                                <span style={{ color: '#db2828', fontSize: '20px' }}>
                                                                    *
                                                                </span>
                                                            </h5>
                                                            <Dropdown
                                                                selection
                                                                search
                                                                fluid
                                                                id='zipcode'
                                                                name='zipcode'
                                                                onChange={handleChangeAddress}
                                                                options={option_zipcode}
                                                                value={zipcode}
                                                                onSearchChange={searchZipcode}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </CustomSegment>
                                    </Grid.Column>
                                </Grid.Row>
                            </Fragment>
                        )}

                        <Grid.Row>
                            <Grid.Column
                                tablet='16'
                                mobile='16'
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Button
                                    style={{
                                        marginLeft: 10,
                                        backgroundColor: '#283655',
                                        color: 'white',
                                        border: '3px solid #283655',
                                    }}
                                    onClick={handleSubmit}
                                >
                                    ยืนยัน
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Container>
        </Fragment>
    )
}

export default LineEditProfile
