import React, { Fragment, useEffect, useState } from 'react'
import {
    Container,
    Dropdown,
    Grid,
    Radio,
    Input,
    Label,
    Pagination,
    Table,
    Breadcrumb,
    InputOnChangeData,
    Form,
} from 'semantic-ui-react'
import { GET_MANEGE_MENU_LIST_PAGES, POST_MANEGE_ACTIVE_PAGE } from '../../../../config/api/manageMenu'
import { axiosAuthen } from '../../../../config/axiosAuthen'

export interface InterfaceActions {
    id: string
    name_th: string
    name_en: string
    active: boolean
}

const Pages: React.FC = () => {
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [keyword, setKeyword] = useState<string>('')
    let [loading, setLoading] = useState<boolean>(false)
    let [pages, setPages] = useState<InterfaceActions[]>([])
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    useEffect(() => {
        const getListPages = () => {
            setLoading(true)
            axiosAuthen
                .get(`${GET_MANEGE_MENU_LIST_PAGES}?keyword=${keyword}&page=${current_page}&offset=${row_qty}`)
                .then((response) => {
                    let { value } = response.data
                    setRecordQty(value.count)
                    setPages(value.permission_pages)
                    setLoading(false)
                })
        }
        getListPages()
    }, [keyword, current_page, row_qty])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const postChangeStatus = (active: boolean, id: string) => {
        let body = {
            active: active,
            id: id,
        }
        axiosAuthen.post(POST_MANEGE_ACTIVE_PAGE, body).then((response) => {
            axiosAuthen
                .get(`${GET_MANEGE_MENU_LIST_PAGES}?keyword=${keyword}&page=${current_page}&offset=${row_qty}`)
                .then((response) => {
                    let { value } = response.data
                    setRecordQty(value.count)
                    setPages(value.permission_pages)
                    // setLoading(false);
                })
        })
    }

    const handleChangeStatus = (active: boolean, id: string, index: number) => {
        postChangeStatus(active, id)
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Breadcrumb>
                                    <Breadcrumb.Section active>รายการหน้าเว็บไซต์</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>รายการหน้าเว็บไซต์</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='13' only='computer' />
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    placeholder='ค้นหา'
                                    icon='search'
                                    name='keyword'
                                    value={keyword}
                                    onChange={handleChangeKeyword}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form loading={loading}>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='4' textAlign='center'>
                                                    ชื่อเว็บไซต์ (ภาษาไทย)
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='4' textAlign='center'>
                                                    ชื่อเว็บไซต์ (ภาษาอังกฤษ)
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='3' textAlign='center'>
                                                    สถานะ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    <Table attached celled className='table-body'>
                                        <Table.Body className='table-body body'>
                                            {pages.map((data: InterfaceActions, index: number) => (
                                                <Table.Row key={data.id} style={{ color: '#1E1F26' }}>
                                                    <Table.Cell textAlign='center' width='1'>
                                                        <Label
                                                            circular
                                                            style={{ backgroundColor: '#425679', color: '#FFFFFF' }}
                                                        >
                                                            {row_qty * (current_page - 1) + index + 1}
                                                        </Label>
                                                    </Table.Cell>
                                                    <Table.Cell width='4'>{data.name_th}</Table.Cell>
                                                    <Table.Cell width='4'>{data.name_en}</Table.Cell>
                                                    <Table.Cell width='3' textAlign='center'>
                                                        สถานะ :{' '}
                                                        {data.active === true ? <span>เปิด</span> : <span>ปิด</span>}
                                                        <br />
                                                        <Radio
                                                            toggle
                                                            checked={data.active}
                                                            onChange={() => {
                                                                handleChangeStatus(!data.active, data.id, index)
                                                            }}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only='computer tablet'>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column computer='11' style={{ display: 'flex' }} only='computer'>
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default Pages
