import React, { Fragment, useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Container,
    Dropdown,
    DropdownProps,
    Form,
    Grid,
    Icon,
    Input,
    InputOnChangeData,
    Label,
    Pagination,
    Segment,
    Table,
    Transition,
} from 'semantic-ui-react'
import DatePicker from '../../../components/DatePicker'
import { GET_DEFECTS_LIST } from '../../../config/api/defect'
import { axiosAuthen } from '../../../config/axiosAuthen'
import DefectModalDetail from './ModalDetail'
import DefectModalForm from './ModalForm'

export interface InterfaceDefect {
    id: number
    mucipalities_id: number
    created_at: string
    defect_status: string
    detail: string
    files_before?: object[]
    name_en: string
    name_th: string
    user_name: string
    approve_detail?: string
    approve_time?: string
    files_after?: object[]
}

const Defect: React.FC = () => {
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [defect_list, setDefectList] = useState<InterfaceDefect[]>([])
    let [open_modal_detail, setOpenModalDetail] = useState<boolean>(false)
    let [open_modal_form, setOpenModalForm] = useState<boolean>(false)
    let [data_defect, setDataDefect] = useState<InterfaceDefect>()
    let [loading, setLoading] = useState<boolean>(false)
    let [filter, setFilter] = useState<boolean>(false)
    let [name, setName] = useState<string>('')
    let [status, setStatus] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [status_option, setStatusOption] = useState<{ value: string; text: string }[]>([])
    let [date, setDate] = useState<string>('')
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    useEffect(() => {
        let option = [
            { value: '0', text: 'รอตอบรับ' },
            { value: '1', text: 'ตอบรับแล้ว' },
        ]
        setStatusOption(option)
    }, [])

    useEffect(() => {
        const getDefectList = () => {
            setLoading(true)
            axiosAuthen
                .get(
                    `${GET_DEFECTS_LIST}?name=${name}&status=${status}&date=${date}&page=${current_page}&offset=${row_qty}`
                )
                .then((response) => {
                    let { value } = response.data
                    setRecordQty(value.count)
                    setDefectList(value.defect_systems)
                    setLoading(false)
                })
        }
        getDefectList()
    }, [name, status, date, current_page, row_qty, open_modal_form, open_modal_detail])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const handleCloseModalDetail = () => setOpenModalDetail(false)

    const handleCloseModalForm = () => setOpenModalForm(false)

    const handleClickDetail = (data: InterfaceDefect) => {
        setDataDefect(data)
        setOpenModalDetail(true)
    }

    const handleClickForm = () => {
        setOpenModalForm(true)
    }

    const handleChangeName = (_: any, { value }: InputOnChangeData) => {
        setName(value)
    }

    const handleChangeStatus = (_: any, { value }: DropdownProps) => {
        setStatus(value)
    }

    const handleChangeDate = (_: any, { value }: InputOnChangeData) => {
        setDate(value)
    }

    const handleClickFilter = () => {
        setFilter(!filter)
    }

    const handleClearFilter = () => {
        setName('')
        setStatus('')
        setDate('')
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    {open_modal_detail && (
                        <DefectModalDetail
                            open={open_modal_detail}
                            onClose={handleCloseModalDetail}
                            data={data_defect}
                        />
                    )}
                    {open_modal_form && <DefectModalForm open={open_modal_form} onClose={handleCloseModalForm} />}
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section active>รายการแจ้งปัญหาการใช้งาน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>รายการแจ้งปัญหาการใช้งาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='4' mobile='16' style={{ marginTop: 10 }}>
                                <Button
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={handleClickForm}
                                >
                                    <Icon name='announcement' />
                                    สอบถามและแจ้งปัญหา
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='9' only='computer' />
                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        icon
                                        labelPosition='left'
                                        fluid
                                        style={{ backgroundColor: '#4D648D', color: 'white' }}
                                        onClick={handleClickFilter}
                                    >
                                        <Icon name='filter' /> กรองข้อมูล
                                    </Button>
                                    {(name || status || date) && (
                                        <Icon
                                            name='delete'
                                            style={{
                                                display: 'inline-flex',
                                                border: '1px solid rgb(182, 57, 45)',
                                                borderRadius: 10,
                                                height: 'auto',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgb(182, 57, 45)',
                                                zIndex: 1,
                                                position: 'absolute',
                                                right: 20,
                                                top: 7,
                                                paddingRight: 10,
                                                paddingLeft: 10,
                                                paddingBottom: 1,
                                                color: 'white',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleClearFilter}
                                        />
                                    )}
                                </div>
                            </Grid.Column>
                            <Grid.Column computer='16' mobile='16'>
                                <Transition.Group animation='slide down' duration='500'>
                                    {filter && (
                                        <Segment style={{ marginTop: 15 }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column computer='5'>
                                                        ชื่อองค์กร
                                                        <Input
                                                            name='name'
                                                            fluid
                                                            value={name}
                                                            onChange={handleChangeName}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='5'>
                                                        สถานะ
                                                        <Dropdown
                                                            name='status'
                                                            selection
                                                            fluid
                                                            value={status}
                                                            options={status_option}
                                                            onChange={handleChangeStatus}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer='5'>
                                                        วันที่แจ้งปัญหา
                                                        <DatePicker
                                                            name='date'
                                                            fluid
                                                            value={date}
                                                            onChange={handleChangeDate}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    )}
                                </Transition.Group>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form loading={loading}>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='2' textAlign='center'>
                                                    วัน / เดือน / ปี แจ้งปัญหา
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='5' textAlign='center'>
                                                    รายละเอียด
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='2' textAlign='center'>
                                                    สถานะ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='2' textAlign='center'>
                                                    จัดการ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    <Table attached celled className='table-body'>
                                        <Table.Body className='table-body body'>
                                            {defect_list.length > 0 ? (
                                                defect_list.map((data: InterfaceDefect, index: number) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell textAlign='center' width='1'>
                                                            <Label
                                                                circular
                                                                style={{
                                                                    backgroundColor: '#425679',
                                                                    color: '#FFFFFF',
                                                                }}
                                                            >
                                                                {row_qty * (current_page - 1) + index + 1}
                                                            </Label>
                                                        </Table.Cell>
                                                        <Table.Cell width='2'>{data.created_at}</Table.Cell>
                                                        <Table.Cell width='5'>
                                                            <p>องค์กร {data.name_th}</p>
                                                            <p>ผู้แจ้งปัญหา {data.user_name}</p>
                                                            <p>{data.detail}</p>
                                                        </Table.Cell>
                                                        <Table.Cell width='2' textAlign='center'>
                                                            <Label
                                                                color={
                                                                    data.defect_status === 'รอตอบรับ'
                                                                        ? 'yellow'
                                                                        : 'green'
                                                                }
                                                            >
                                                                <Icon name='info circle' />
                                                                {data.defect_status}
                                                            </Label>
                                                        </Table.Cell>
                                                        <Table.Cell width='2' textAlign='center'>
                                                            <Button
                                                                onClick={() => handleClickDetail(data)}
                                                                style={{
                                                                    backgroundColor: '#293757',
                                                                    color: '#ffffff',
                                                                }}
                                                            >
                                                                <Icon name='eye' style={{ color: '#ffffff' }} />
                                                                ดูข้อมูล
                                                            </Button>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))
                                            ) : (
                                                <Table.Row>
                                                    <Table.Cell textAlign='center' width='16'>
                                                        {'ไม่พบข้อมูล'}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                    paddingRight: 40,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column
                                            computer='11'
                                            tablet='16'
                                            mobile='16'
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default Defect
