import React, { Fragment, useEffect, useState } from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { Icon, Image, Segment, Rating, Button, Modal } from 'semantic-ui-react'
import Doing from '../../../../../../assets/images/working.png'
import Temporary from '../../../../../../assets/images/put off.png'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import { GET_NOTIFICATION_LINE_HISTORY_PRE_COMPLAINT } from '../../../../../../config/api/notification'
import { InterfacePreComplaintDetail } from '..'
import { HOST } from '../../../../../../config/router'

type TimelineProps = {
    pre_complaint_detail?: InterfacePreComplaintDetail
}

interface InterfaceHistoryDetail {
    pre_complaint_id: number
    created_at: string
    status_name: string
}
const Timeline: React.FC<TimelineProps> = (props) => {
    let { pre_complaint_detail } = props
    let location = useLocation()
    let history = useHistory()
    let [open, setOpen] = useState<boolean>(false)
    let [history_detail, setHistoryDetail] = useState<InterfaceHistoryDetail[]>([])
    let [municipality_id, setMunicipalityId] = useState<string | null>('')
    let [pre_complaint_id, setPreComplaintId] = useState<string | null>('')

    useEffect(() => {
        const getTimelinePreComplaint = (pre_complaint_id: string, municipality_id: string) => {
            axios
                .get(`${GET_NOTIFICATION_LINE_HISTORY_PRE_COMPLAINT}/${pre_complaint_id}/${municipality_id}`)
                .then((response) => {
                    let { value } = response.data
                    setHistoryDetail(value)
                })
        }
        const query = new URLSearchParams(location.search)
        const municipality_id = query.get('municipality_id')
        const pre_complaint_id = query.get('pre_complaint_id')
        setMunicipalityId(municipality_id)
        setPreComplaintId(pre_complaint_id)
        if (pre_complaint_id && municipality_id && open) getTimelinePreComplaint(pre_complaint_id, municipality_id)
    }, [location, open])

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClickEvaluate = () => {
        history.push(`evaluate?pre_complaint_id=${pre_complaint_id}&municipality_id=${municipality_id}`, {
            go_back: true,
        })
    }
    return (
        <Modal
            open={open}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            trigger={
                <Button
                    labelPosition='left'
                    icon
                    floated='right'
                    style={{ backgroundColor: '#1D3853', color: 'white' }}
                    onClick={handleOpen}
                >
                    <Icon name='history' />
                    ประวัติคำร้อง
                </Button>
            }
        >
            <Modal.Header>ประวัติคำร้องเลขที่ {pre_complaint_detail?.pre_complaint_no}</Modal.Header>

            <Modal.Content scrolling style={{ backgroundColor: '#1D3853' }}>
                <VerticalTimeline layout='1-column'>
                    {history_detail.map((history: InterfaceHistoryDetail, index: number) => (
                        <Fragment key={index}>
                            {history.status_name === 'รอรับเรื่อง' && (
                                <VerticalTimelineElement
                                    iconStyle={{
                                        backgroundColor: '#fff',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '2px solid #61ABE1',
                                    }}
                                    icon={
                                        <Icon
                                            name='hourglass half'
                                            size='large'
                                            style={{
                                                display: 'contents',
                                                color: '#61ABE1',
                                            }}
                                        />
                                    }
                                >
                                    <h3 className='vertical-timeline-element-title'>ยื่นคำร้องทุกข์</h3>
                                    <h5 className='vertical-timeline-element-subtitle'>{history.created_at}</h5>
                                </VerticalTimelineElement>
                            )}
                            {history.status_name === 'ยกเลิกคำร้อง' && (
                                <VerticalTimelineElement
                                    iconStyle={{
                                        backgroundColor: '#fff',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '2px solid #FF6161',
                                    }}
                                    icon={
                                        <Icon
                                            name='remove'
                                            size='large'
                                            style={{
                                                display: 'contents',
                                                color: '#FF6161',
                                            }}
                                        />
                                    }
                                >
                                    <h3 className='vertical-timeline-element-title'>ที่ทำการปฏิเสธการรับเรื่อง</h3>
                                    <h5 className='vertical-timeline-element-subtitle'>{history.created_at}</h5>
                                    <h5>ธุรการปฏิเสธคำร้อง เนื่องจาก{pre_complaint_detail?.reject_detail}</h5>
                                </VerticalTimelineElement>
                            )}
                            {history.status_name === 'รอดำเนินการ' && (
                                <VerticalTimelineElement
                                    iconStyle={{
                                        backgroundColor: '#fff',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '2px solid #767676',
                                    }}
                                    icon={
                                        <div
                                            style={{
                                                height: 30,
                                                width: 30,
                                                borderRadius: 100,
                                                display: 'flex',
                                                padding: 5,
                                            }}
                                        >
                                            <Image src={Temporary} fluid style={{ color: '#767676' }} />
                                        </div>
                                    }
                                >
                                    <h3 className='vertical-timeline-element-title'>ที่ทำการรับเรื่อง</h3>
                                    <h5 className='vertical-timeline-element-subtitle'>{history.created_at}</h5>
                                    <h5>
                                        รอดำเนินการ คาดว่าจะแล้วเสร็จวันที่ {pre_complaint_detail?.processing_end_date}
                                    </h5>
                                </VerticalTimelineElement>
                            )}
                            {history.status_name === 'กำลังดำเนินการ' && (
                                <VerticalTimelineElement
                                    iconStyle={{
                                        backgroundColor: '#fff',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '2px solid #2185D0',
                                    }}
                                    icon={
                                        <div
                                            style={{
                                                height: 30,
                                                width: 30,
                                                borderRadius: 100,
                                                display: 'flex',
                                                padding: 5,
                                            }}
                                        >
                                            <Image src={Doing} fluid />
                                        </div>
                                    }
                                >
                                    <h3 className='vertical-timeline-element-title'>เจ้าหน้าที่กำลังดำเนินการ</h3>
                                    <h5 className='vertical-timeline-element-subtitle'>{history.created_at}</h5>
                                    <h5>
                                        กำลังดำเนินการ คาดว่าจะดำเนินการเสร็จสิ้นภายในวันที่{' '}
                                        {pre_complaint_detail?.processing_end_date}
                                    </h5>
                                </VerticalTimelineElement>
                            )}
                            {history.status_name === 'ไม่สามารถดำเนินการได้' && (
                                <VerticalTimelineElement
                                    iconStyle={{
                                        backgroundColor: '#fff',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '2px solid orange',
                                    }}
                                    icon={
                                        <Icon
                                            name='exclamation'
                                            size='large'
                                            style={{
                                                display: 'contents',
                                                color: 'orange',
                                            }}
                                        />
                                    }
                                >
                                    <h3 className='vertical-timeline-element-title'>ไม่สามารถดำเนินการได้</h3>
                                    <h5 className='vertical-timeline-element-subtitle'>{history.created_at}</h5>
                                    <h5>เนื่องจาก{pre_complaint_detail?.processing_detail}</h5>
                                    {pre_complaint_detail?.files_result &&
                                        pre_complaint_detail?.files_result.length > 0 && (
                                            <Fragment>
                                                <h5>ภาพประกอบ</h5>
                                                {pre_complaint_detail?.files_result.map((file, index) => (
                                                    <Segment
                                                        key={index}
                                                        style={{
                                                            width: 200,
                                                            height: 200,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            padding: 5,
                                                            margin: 5,
                                                        }}
                                                    >
                                                        <Image
                                                            style={{
                                                                cursor: 'pointer',
                                                                maxHeight: 190,
                                                                maxWidth: 190,
                                                            }}
                                                            src={`${HOST}${file.path_file}`}
                                                            // onClick={() => {
                                                            // 	this.setState({
                                                            // 		show_image: true,
                                                            // 		image: file
                                                            // 	});
                                                            // }}
                                                        />
                                                    </Segment>
                                                ))}
                                            </Fragment>
                                        )}
                                </VerticalTimelineElement>
                            )}
                            {history.status_name === 'เสร็จสิ้น' && (
                                <Fragment>
                                    <VerticalTimelineElement
                                        iconStyle={{
                                            backgroundColor: '#fff',
                                            color: '#fff',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '2px solid #21BA45',
                                        }}
                                        icon={
                                            <Icon
                                                name='clipboard check'
                                                size='large'
                                                style={{
                                                    display: 'contents',
                                                    color: '#21BA45',
                                                }}
                                            />
                                        }
                                    >
                                        <h3 className='vertical-timeline-element-title'>ดำเนินการเสร็จสิ้น</h3>
                                        <h5 className='vertical-timeline-element-subtitle'>{history.created_at}</h5>
                                        {pre_complaint_detail?.processing_detail && (
                                            <h5>หมายเหตุ {pre_complaint_detail?.processing_detail}</h5>
                                        )}
                                        {pre_complaint_detail?.files_result &&
                                            pre_complaint_detail?.files_result.length > 0 && (
                                                <Fragment>
                                                    <h5>ภาพประกอบ</h5>
                                                    {pre_complaint_detail?.files_result.map((file, index) => (
                                                        <Segment
                                                            key={index}
                                                            style={{
                                                                width: 200,
                                                                height: 200,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                padding: 5,
                                                                margin: 5,
                                                            }}
                                                        >
                                                            <Image
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    maxHeight: 190,
                                                                    maxWidth: 190,
                                                                }}
                                                                src={`${HOST}${file.path_file}`}
                                                                // onClick={() => {
                                                                // 	this.setState({
                                                                // 		show_image: true,
                                                                // 		image: file
                                                                // 	});
                                                                // }}
                                                            />
                                                        </Segment>
                                                    ))}
                                                </Fragment>
                                            )}
                                    </VerticalTimelineElement>

                                    {pre_complaint_detail?.grade === 0 && (
                                        <VerticalTimelineElement
                                            iconStyle={{
                                                backgroundColor: '#fff',
                                                color: '#fff',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '2px solid #FFCC00',
                                            }}
                                            icon={
                                                <Icon
                                                    name='star'
                                                    size='large'
                                                    style={{
                                                        display: 'contents',
                                                        color: '#FFCC00',
                                                    }}
                                                />
                                            }
                                        >
                                            <h3 className='vertical-timeline-element-title'>การประเมินความพึงพอใจ</h3>
                                            <h5 className='vertical-timeline-element-subtitle'>{history.created_at}</h5>
                                            <h5>
                                                ที่ทำการได้ดำเนินการเสร็จสิ้นแล้ว
                                                ขอความกรุณาให้ท่านประเมินความพึงพอใจของคำร้องได้
                                                <label
                                                    style={{
                                                        textDecoration: 'underline',
                                                        color: '#4183c4',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={handleClickEvaluate}
                                                >
                                                    ที่นี่
                                                </label>{' '}
                                                (หากเกิน 2 วัน ระบบจะประเมินให้ 5 ดาวโดยอัตโนมัติ)
                                            </h5>
                                        </VerticalTimelineElement>
                                    )}
                                </Fragment>
                            )}

                            {history.status_name === 'ประเมินผลเสร็จสิ้น' && (
                                <VerticalTimelineElement
                                    iconStyle={{
                                        backgroundColor: '#fff',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '2px solid #FFCC00',
                                    }}
                                    icon={
                                        <Icon
                                            name='star'
                                            size='large'
                                            style={{
                                                display: 'contents',
                                                color: '#FFCC00',
                                            }}
                                        />
                                    }
                                >
                                    <h3 className='vertical-timeline-element-title'>การประเมินความพึงพอใจ</h3>
                                    <h5 className='vertical-timeline-element-subtitle'>{history.created_at}</h5>
                                    <Segment>
                                        <div>
                                            <Rating
                                                name='rating'
                                                maxRating={5}
                                                icon='star'
                                                size='massive'
                                                rating={pre_complaint_detail?.grade}
                                            />
                                        </div>
                                    </Segment>

                                    {pre_complaint_detail?.suggestion && (
                                        <Fragment>
                                            <h5>
                                                ข้อเสนอแนะ <br />
                                                {pre_complaint_detail?.suggestion}
                                            </h5>
                                        </Fragment>
                                    )}
                                </VerticalTimelineElement>
                            )}
                        </Fragment>
                    ))}
                </VerticalTimeline>
            </Modal.Content>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    style={{
                        width: 100,
                        backgroundColor: '#1D3954',
                        color: '#FFF',
                        margin: 10,
                    }}
                    onClick={handleClose}
                >
                    ปิด
                </Button>
            </div>
        </Modal>
    )
}

export default Timeline
