import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Container, Dimmer, Grid, Loader, Segment } from 'semantic-ui-react'
import CustomSegment from '../../../../components/CustomSegment'
import {
    GET_MANAGE_STATUS_APPROVE_LIST,
    PUT_MANAGE_STATUS_APPROVE_CREATE_AND_EDIT,
} from '../../../../config/api/manageStatusApprove'
import { axiosAuthen } from '../../../../config/axiosAuthen'

const ManagementStatusApprove: React.FC = () => {
    let [loading, setLoading] = useState<boolean>(false)
    let [list_checked_status, setListCheckedStatus] = useState<number[]>([])

    let option_status_result = [
        { value: 4, text: 'เสร็จสิ้น' },
        { value: 5, text: 'ติดตาม' },
        { value: 7, text: 'แก้ไขคำร้อง' },
        { value: 8, text: 'ไม่สามารถดำเนินการได้' },
    ]
    let option_status_accept = [
        { value: 3, text: 'ส่งกลับส่วนกลาง' },
        { value: 11, text: 'ส่งต่อหน่วยงานอื่น' },
        { value: 12, text: 'ชะลอเรื่อง' },
        { value: 20, text: 'ยกเลิกคำร้อง' },
        { value: 19, text: 'อื่นๆ' },
    ]

    useEffect(() => {
        setLoading(true)
        axiosAuthen.get(GET_MANAGE_STATUS_APPROVE_LIST).then((response) => {
            let { value } = response.data
            setListCheckedStatus(value.status_id)
            setLoading(false)
        })
    }, [])

    const handleChangeCheckbox = (status: number) => {
        if (list_checked_status.some((checked) => checked === status)) {
            setListCheckedStatus(list_checked_status.filter((checked) => checked !== status))
        } else {
            setListCheckedStatus([...list_checked_status, status])
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        axiosAuthen.put(PUT_MANAGE_STATUS_APPROVE_CREATE_AND_EDIT, { status_id: list_checked_status }).then(() => {
            setLoading(false)
        })
    }

    return (
        <div className='background-container'>
            <Container>
                <Dimmer page active={loading} inverted>
                    <Loader />
                </Dimmer>
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <h2>รายการสถานะที่ต้องผ่านการอนุมัติ</h2>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column computer='4'></Grid.Column>
                        <Grid.Column computer='8'>
                            <CustomSegment header='สถานะทั้งหมด' icon='file alternate'>
                                <div style={{ margin: 10, color: '#283655' }}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <h4>เลือกสถานะ</h4>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Segment>
                                                    <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <h4>สถานะสำหรับบันทึกผลการปฏิบัติงาน</h4>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        {option_status_result.map((status, index) => (
                                                            <Grid.Row key={index}>
                                                                <Grid.Column>
                                                                    <Checkbox
                                                                        label={`สถานะ : ${status.text}`}
                                                                        checked={list_checked_status.some(
                                                                            (checked) => checked === status.value
                                                                        )}
                                                                        onChange={() => {
                                                                            handleChangeCheckbox(status.value)
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        ))}
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <h4>สถานะอื่นๆ</h4>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        {option_status_accept.map((status, index) => (
                                                            <Grid.Row key={index}>
                                                                <Grid.Column>
                                                                    <Checkbox
                                                                        label={`สถานะ : ${status.text}`}
                                                                        checked={list_checked_status.some(
                                                                            (checked) => checked === status.value
                                                                        )}
                                                                        onChange={() => {
                                                                            handleChangeCheckbox(status.value)
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        ))}
                                                    </Grid>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='center'>
                                                <Button
                                                    style={{
                                                        backgroundColor: '#283655',
                                                        color: '#FFF',
                                                    }}
                                                    onClick={handleSubmit}>
                                                    บันทึก
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                            </CustomSegment>
                        </Grid.Column>
                        <Grid.Column computer='4'></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    )
}

export default ManagementStatusApprove
