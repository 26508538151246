import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Breadcrumb, Button, Container, Dropdown, Form, Grid, Icon, Label, Pagination, Table } from 'semantic-ui-react'
import { DELETE_HAS_USER_MANUAL, GET_HAS_USER_MANUAL_LIST } from '../../../../config/api/userManual'
import { axiosAuthen } from '../../../../config/axiosAuthen'

interface InterfaceListTopic {
    id: number
    name: string
    sub_topics: InterfaceListSubTopic[]
}

interface InterfaceListSubTopic {
    id: number
    name: string
    contents: InterfaceListContent[]
}

interface InterfaceListContent {
    id: number
    name: string
}

const ManagementUserManual: React.FC = () => {
    let history = useHistory()
    let location = useLocation()
    let [municipality_id, setMunicipality] = useState<number>()
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [loading, setLoading] = useState<boolean>(false)
    let [user_manual_list, setUserManualList] = useState<InterfaceListTopic[]>([])
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]

    const getUserManualList = useCallback(() => {
        if (municipality_id)
            axiosAuthen
                .get(
                    `${GET_HAS_USER_MANUAL_LIST}?municipality_id=${municipality_id}&page=${current_page}&offset=${row_qty}`
                )
                .then((response) => {
                    let { value } = response.data
                    setRecordQty(value.count)
                    setUserManualList(value.has_user_manual_list)
                    setLoading(false)
                })
    }, [current_page, municipality_id, row_qty])

    useEffect(() => {
        let { state } = location
        if (state) {
            let location_state = JSON.parse(JSON.stringify(state, null, ' '))
            let { municipality_id } = location_state
            setMunicipality(municipality_id)
            getUserManualList()
        }
    }, [getUserManualList, location])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const calculateTopicSpanRow = (topic: InterfaceListTopic) => {
        let row = topic.sub_topics
            .map((sub_topic: InterfaceListSubTopic) => (sub_topic.contents.length > 0 ? sub_topic.contents.length : 1))
            .reduce((prev: number, next: number) => prev + next, 0)
        return row
    }

    const calculateSubTopicSpanRow = (sub_topic: InterfaceListSubTopic) => {
        let row = sub_topic.contents.length > 0 ? sub_topic.contents.length : 1
        return row
    }

    const handleClickEdit = (topic: InterfaceListTopic) => {
        history.push('user-manual/mapping', { municipality_id: municipality_id, topic: topic })
    }

    const handleClickDelete = (topic_id: number) => {
        axiosAuthen.delete(`${DELETE_HAS_USER_MANUAL}/${topic_id}/${municipality_id}`).then(() => {
            getUserManualList()
        })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/municipalities`, {
                                                municipality_id: municipality_id,
                                            })
                                        }}
                                    >
                                        รายการองค์กร
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>รายการคู่มือการใช้งาน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>รายการคู่มือการใช้งาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='4' tablet='5' mobile='8' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={() =>
                                        history.push('user-manual/mapping', { municipality_id: municipality_id })
                                    }
                                >
                                    <Icon name='plus' />
                                    ผูกการแสดงคู่มือ
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='6' tablet='6' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form loading={loading}>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='4'>
                                                    หัวข้อ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='4'>
                                                    หัวข้อย่อย
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='4'>
                                                    เนื้อหา
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='3'>
                                                    จัดการ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    {user_manual_list.length > 0 &&
                                        user_manual_list.map((topic: InterfaceListTopic, index_topic: number) => (
                                            <Table attached celled className='table-body-merge' key={index_topic}>
                                                <Table.Body>
                                                    {calculateTopicSpanRow(topic) > 0 ? (
                                                        topic.sub_topics.map(
                                                            (
                                                                sub_topic: InterfaceListSubTopic,
                                                                index_sub_topic: number
                                                            ) =>
                                                                sub_topic.contents.length > 0 ? (
                                                                    sub_topic.contents.map(
                                                                        (
                                                                            content: InterfaceListContent,
                                                                            index_content: number
                                                                        ) => (
                                                                            <Table.Row key={index_content}>
                                                                                {index_sub_topic === 0 &&
                                                                                    index_content === 0 && (
                                                                                        <Table.Cell
                                                                                            rowSpan={calculateTopicSpanRow(
                                                                                                topic
                                                                                            )}
                                                                                            textAlign='center'
                                                                                            width='1'
                                                                                        >
                                                                                            <Label
                                                                                                circular
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#425679',
                                                                                                    color: '#FFFFFF',
                                                                                                }}
                                                                                            >
                                                                                                {row_qty *
                                                                                                    (current_page - 1) +
                                                                                                    index_topic +
                                                                                                    1}
                                                                                            </Label>
                                                                                        </Table.Cell>
                                                                                    )}
                                                                                {index_sub_topic === 0 &&
                                                                                    index_content === 0 && (
                                                                                        <Table.Cell
                                                                                            rowSpan={calculateTopicSpanRow(
                                                                                                topic
                                                                                            )}
                                                                                            width='4'
                                                                                            textAlign='center'
                                                                                        >
                                                                                            {topic.name}
                                                                                        </Table.Cell>
                                                                                    )}

                                                                                {index_content === 0 && (
                                                                                    <Table.Cell
                                                                                        rowSpan={calculateSubTopicSpanRow(
                                                                                            sub_topic
                                                                                        )}
                                                                                        width='4'
                                                                                        textAlign='center'
                                                                                    >
                                                                                        {sub_topic.name}
                                                                                    </Table.Cell>
                                                                                )}
                                                                                <Table.Cell
                                                                                    width='4'
                                                                                    textAlign='center'
                                                                                >
                                                                                    {content.name}
                                                                                </Table.Cell>
                                                                                {index_sub_topic === 0 &&
                                                                                    index_content === 0 && (
                                                                                        <Table.Cell
                                                                                            rowSpan={calculateTopicSpanRow(
                                                                                                topic
                                                                                            )}
                                                                                            width='3'
                                                                                            textAlign='center'
                                                                                        >
                                                                                            <Button
                                                                                                basic
                                                                                                style={{
                                                                                                    marginTop: 10,
                                                                                                }}
                                                                                                icon
                                                                                                color='yellow'
                                                                                                onClick={() =>
                                                                                                    handleClickEdit(
                                                                                                        topic
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <Icon name='edit' />{' '}
                                                                                                แก้ไข
                                                                                            </Button>
                                                                                            <Button
                                                                                                basic
                                                                                                style={{
                                                                                                    marginTop: 10,
                                                                                                }}
                                                                                                icon
                                                                                                color='red'
                                                                                                onClick={() =>
                                                                                                    handleClickDelete(
                                                                                                        topic.id
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <Icon name='trash alternate' />{' '}
                                                                                                ลบ
                                                                                            </Button>
                                                                                        </Table.Cell>
                                                                                    )}
                                                                            </Table.Row>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <Table.Row key={index_sub_topic}>
                                                                        {index_sub_topic === 0 && (
                                                                            <Table.Cell
                                                                                rowSpan={calculateTopicSpanRow(topic)}
                                                                                textAlign='center'
                                                                                width='1'
                                                                            >
                                                                                <Label
                                                                                    circular
                                                                                    style={{
                                                                                        backgroundColor: '#425679',
                                                                                        color: '#FFFFFF',
                                                                                    }}
                                                                                >
                                                                                    {row_qty * (current_page - 1) +
                                                                                        index_topic +
                                                                                        1}
                                                                                </Label>
                                                                            </Table.Cell>
                                                                        )}
                                                                        {index_sub_topic === 0 && (
                                                                            <Table.Cell
                                                                                rowSpan={calculateTopicSpanRow(topic)}
                                                                                width='4'
                                                                                textAlign='center'
                                                                            >
                                                                                {topic.name}
                                                                            </Table.Cell>
                                                                        )}

                                                                        <Table.Cell
                                                                            rowSpan={calculateSubTopicSpanRow(
                                                                                sub_topic
                                                                            )}
                                                                            width='4'
                                                                            textAlign='center'
                                                                        >
                                                                            {sub_topic.name}
                                                                        </Table.Cell>

                                                                        <Table.Cell width='4' textAlign='center'>
                                                                            -
                                                                        </Table.Cell>
                                                                        {index_sub_topic === 0 && (
                                                                            <Table.Cell
                                                                                rowSpan={calculateTopicSpanRow(topic)}
                                                                                width='3'
                                                                                textAlign='center'
                                                                            >
                                                                                <Button
                                                                                    basic
                                                                                    style={{
                                                                                        marginTop: 10,
                                                                                    }}
                                                                                    icon
                                                                                    color='yellow'
                                                                                    onClick={() =>
                                                                                        handleClickEdit(topic)
                                                                                    }
                                                                                >
                                                                                    <Icon name='edit' /> แก้ไข
                                                                                </Button>
                                                                                <Button
                                                                                    basic
                                                                                    style={{
                                                                                        marginTop: 10,
                                                                                    }}
                                                                                    icon
                                                                                    color='red'
                                                                                    onClick={() =>
                                                                                        handleClickDelete(topic.id)
                                                                                    }
                                                                                >
                                                                                    <Icon name='trash alternate' /> ลบ
                                                                                </Button>
                                                                            </Table.Cell>
                                                                        )}
                                                                    </Table.Row>
                                                                )
                                                        )
                                                    ) : (
                                                        <Table.Row>
                                                            <Table.Cell
                                                                rowSpan={calculateTopicSpanRow(topic)}
                                                                textAlign='center'
                                                                width='1'
                                                            >
                                                                <Label
                                                                    circular
                                                                    style={{
                                                                        backgroundColor: '#425679',
                                                                        color: '#FFFFFF',
                                                                    }}
                                                                >
                                                                    {row_qty * (current_page - 1) + index_topic + 1}
                                                                </Label>
                                                            </Table.Cell>

                                                            <Table.Cell
                                                                rowSpan={calculateTopicSpanRow(topic)}
                                                                width='4'
                                                                textAlign='center'
                                                            >
                                                                {topic.name}
                                                            </Table.Cell>

                                                            <Table.Cell width='4' textAlign='center'>
                                                                -
                                                            </Table.Cell>

                                                            <Table.Cell width='4' textAlign='center'>
                                                                -
                                                            </Table.Cell>

                                                            <Table.Cell
                                                                rowSpan={calculateTopicSpanRow(topic)}
                                                                width='3'
                                                                textAlign='center'
                                                            >
                                                                <Button
                                                                    basic
                                                                    style={{
                                                                        marginTop: 10,
                                                                    }}
                                                                    icon
                                                                    color='yellow'
                                                                    onClick={() => handleClickEdit(topic)}
                                                                >
                                                                    <Icon name='edit' /> แก้ไข
                                                                </Button>
                                                                <Button
                                                                    basic
                                                                    style={{
                                                                        marginTop: 10,
                                                                    }}
                                                                    icon
                                                                    color='red'
                                                                    onClick={() => handleClickDelete(topic.id)}
                                                                >
                                                                    <Icon name='trash alternate' /> ลบ
                                                                </Button>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                </Table.Body>
                                            </Table>
                                        ))}
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                    paddingRight: 40,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column
                                            computer='11'
                                            tablet='16'
                                            mobile='16'
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}
export default ManagementUserManual
