import { ActionInterface } from '../../../types'
import { AuthenMenuListState } from '../../../types/Menu/AuthenMenuList'

const initialState: AuthenMenuListState = {
    menu_list: [],
}

const AuthenMenuList = (state = initialState, action: ActionInterface): AuthenMenuListState => {
    switch (action.type) {
        case 'setStateByNameAndValueAuthenMenuList':
            return action.name
                ? {
                      ...state,
                      [action.name]: action.value,
                  }
                : { ...state }
        case 'appendFormAuthenMenuList':
            return {
                ...state,
                ...action.value,
            }
        case 'resetFormByReducerNameAuthenMenuList':
            return {
                ...state,
                ...{
                    menu_list: [],
                },
            }
        default:
            return state
    }
}

export default AuthenMenuList
