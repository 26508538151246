import { ActionInterface } from '../../types';
import { propsAlert } from '../../types/Alert';

const initialState: propsAlert = {
    type: 'success',
    icon: undefined,
    image: undefined,
    color: '',
    title: '',
    text: '',
    onConfirm: undefined,
    onCancel: undefined,
    onClosed: undefined,
    confirmText: '',
    cancelText: '',
    timer: undefined,
    alert: false,
};

const Alert = (state = initialState, action: ActionInterface): propsAlert => {
    switch (action.type) {
        case 'alert':
            return {
                ...state,
                ...action.value,
            };
        case 'alertClosed':
            return {
                ...state,
                ...{
                    type: 'success',
                    icon: undefined,
                    image: undefined,
                    color: '',
                    title: '',
                    text: '',
                    onConfirm: undefined,
                    onCancel: undefined,
                    onClosed: undefined,
                    confirmText: '',
                    cancelText: '',
                    timer: undefined,
                    alert: false,
                },
            };
        default:
            return state;
    }
};

export default Alert;
