import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Breadcrumb, Button, Container, Grid, Image, Input, InputOnChangeData, Segment } from 'semantic-ui-react'
import { GET_USER_MANUAL_CONTENT_ALL, GET_USER_MANUAL_SUB_TOPIC_ALL } from '../../../../config/api/userManual'
import { axiosAuthen } from '../../../../config/axiosAuthen'
import ImageSubTopic from '../../../../assets/images/user manual/sub-topic.png'
import ImageContent from '../../../../assets/images/user manual/content.png'
import { getFile } from '../../../../functions/GetFile'

export interface InterfaceSubTopic {
    id: number
    name: string
    path_file_name: string
    path_file: string
}

export interface InterfaceContent {
    id: number
    name: string
    path_file_name: string
    path_file: string
}

const UserManualDetail: React.FC = () => {
    let history = useHistory()
    let [sub_topic_all, setSupTopicAll] = useState<[]>([])
    let [content_all, setContentAll] = useState<[]>([])
    let [sub_topic_name, setSupTopicName] = useState<string>('')
    let [sub_topic_path_file_name, setSupTopicPathFileName] = useState<string>('')
    let [sub_topic_path_file, setSupTopicPathFile] = useState<string>('')
    let [sub_topic_id, setSupTopicId] = useState<number>(0)
    let location = useLocation()
    let { state } = location
    let obj = JSON.parse(JSON.stringify(state, null, ' '))
    let { topic_id, topic_name } = obj
    let [keyword, setKeyword] = useState<string>('')
    useEffect(() => {
        axiosAuthen.get(`${GET_USER_MANUAL_SUB_TOPIC_ALL}/${topic_id}`).then((response) => {
            setSupTopicAll(response.data.value)
        })
    }, [topic_id])

    const handleClickDetailSubTopic = (
        sub_topic_id: number,
        sub_topic_name: string,
        sub_topic_path_file_name: string,
        sub_topic_path_file: string
    ) => {
        axiosAuthen.get(`${GET_USER_MANUAL_CONTENT_ALL}/${sub_topic_id}`).then((response) => {
            setContentAll(response.data.value)
            setSupTopicName(sub_topic_name)
            setSupTopicId(sub_topic_id)
            setSupTopicPathFile(sub_topic_path_file)
            setSupTopicPathFileName(sub_topic_path_file_name)
        })
    }

    const handleChangeKeyword = (_: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    const handleClickSearch = (sub_topic_id: number, sub_topic_name: string) => {
        axiosAuthen.get(`${GET_USER_MANUAL_CONTENT_ALL}/${sub_topic_id}?keyword=${keyword}`).then((response) => {
            setContentAll(response.data.value)
            setSupTopicName(sub_topic_name)
        })
    }

    const handleClickDownload = async (path_file_name: string, path_file: string) => {
        let file = await getFile(path_file_name, path_file)
        if (file) {
            const url = window.URL.createObjectURL(file)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${path_file_name}`)

            // Append to html link element page
            document.body.appendChild(link)

            // Start download
            link.click()

            // Clean up and remove the link
            if (link.parentNode) link.parentNode.removeChild(link)
        }
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section
                                        link
                                        onClick={() => {
                                            history.push(`/${localStorage.permission_en}/user-manual`)
                                        }}>
                                        คู่มือการใช้งาน
                                    </Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>{topic_name}</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>{topic_name}</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='16' tablet='16' mobile='16'>
                                <Segment
                                    style={{
                                        boxShadow:
                                            '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
                                    }}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column computer='1' tablet='16' mobile='16'></Grid.Column>
                                            <Grid.Column computer='15' tablet='16' mobile='16'>
                                                <h3>หัวข้อย่อย</h3>
                                                <label>เลือกหัวข้อย่อยเพื่อดูรายละเอียดของคู่มือการใช้งาน</label>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            {sub_topic_all.length > 0 ? (
                                                sub_topic_all.map((data: InterfaceSubTopic, index: number) => (
                                                    <Grid.Column computer='5' tablet='16' mobile='16' key={index}>
                                                        <Segment
                                                            style={{
                                                                boxShadow: '0px 11px 24px rgba(0, 0, 0, 0.2)',
                                                                margin: 10,
                                                            }}>
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column computer='16' tablet='16' mobile='16'>
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                            }}>
                                                                            <Image
                                                                                src={ImageSubTopic}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                size={'small'}></Image>
                                                                        </div>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column
                                                                        computer='5'
                                                                        tablet='16'
                                                                        mobile='16'
                                                                        textAlign='right'>
                                                                        <label
                                                                            style={{
                                                                                background: 'rgba(40, 54, 85, 0.12)',
                                                                                borderRadius: 200,
                                                                                padding: '5px 15px',
                                                                            }}>
                                                                            {index + 1}
                                                                        </label>
                                                                    </Grid.Column>
                                                                    <Grid.Column
                                                                        computer='11'
                                                                        tablet='16'
                                                                        mobile='16'
                                                                        textAlign='left'>
                                                                        <h4>{data.name}</h4>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column
                                                                        computer='16'
                                                                        tablet='16'
                                                                        mobile='16'
                                                                        textAlign='center'>
                                                                        <Button
                                                                            style={{
                                                                                backgroundColor: '#293757',
                                                                                color: 'white',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleClickDetailSubTopic(
                                                                                    data.id,
                                                                                    data.name,
                                                                                    data.path_file_name,
                                                                                    data.path_file
                                                                                )
                                                                            }>
                                                                            ดูเพิ่มเติม
                                                                        </Button>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Segment>
                                                    </Grid.Column>
                                                ))
                                            ) : (
                                                <Grid.Column>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            color: 'rgba(30, 31, 38, 0.5)',
                                                        }}>
                                                        <h5>ไม่มีหัวข้อย่อย</h5>
                                                    </div>
                                                </Grid.Column>
                                            )}
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {content_all.length > 0 ? (
                                <Grid.Column computer='16' tablet='16' mobile='16'>
                                    <Segment
                                        style={{
                                            boxShadow:
                                                '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
                                        }}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column computer='1' tablet='16' mobile='16'></Grid.Column>

                                                <Grid.Column computer='9' tablet='16' mobile='16'>
                                                    <h3>{sub_topic_name}</h3>
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#293757',
                                                            color: 'white',
                                                        }}
                                                        onClick={() => {
                                                            handleClickDownload(
                                                                sub_topic_path_file_name,
                                                                sub_topic_path_file
                                                            )
                                                        }}>
                                                        DownLoad PDF
                                                    </Button>
                                                </Grid.Column>
                                                <Grid.Column
                                                    computer='4'
                                                    tablet='16'
                                                    mobile='16'
                                                    style={{ alignSelf: 'flex-end' }}>
                                                    <label>ค้นหาเอกสาร</label>

                                                    <Input
                                                        fluid
                                                        placeholder='ค้นหา'
                                                        icon='search'
                                                        onChange={handleChangeKeyword}
                                                        value={keyword}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column
                                                    computer='2'
                                                    tablet='16'
                                                    mobile='16'
                                                    style={{ alignSelf: 'flex-end' }}>
                                                    <Button
                                                        fluid
                                                        style={{ backgroundColor: '#293757', color: 'white' }}
                                                        onClick={() => {
                                                            handleClickSearch(sub_topic_id, sub_topic_name)
                                                        }}>
                                                        ค้นหา
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column computer='16' tablet='16' mobile='16' textAlign='center'>
                                                    <h2>รายละเอียด</h2>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                {content_all.length > 0 ? (
                                                    content_all.map((data: InterfaceContent, index: number) => (
                                                        <Grid.Column computer='5' tablet='16' mobile='16' key={index}>
                                                            <Segment
                                                                style={{
                                                                    boxShadow: '0px 11px 24px rgba(0, 0, 0, 0.2)',
                                                                    margin: 10,
                                                                }}>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column
                                                                            computer='16'
                                                                            tablet='16'
                                                                            mobile='16'>
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                }}>
                                                                                <Image
                                                                                    src={ImageContent}
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    size={'small'}></Image>
                                                                            </div>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column
                                                                            computer='5'
                                                                            tablet='16'
                                                                            mobile='16'
                                                                            textAlign='right'>
                                                                            <label
                                                                                style={{
                                                                                    background: '#293757',
                                                                                    color: 'white',
                                                                                    borderRadius: 200,
                                                                                    padding: '5px 15px',
                                                                                }}>
                                                                                {index + 1}
                                                                            </label>
                                                                        </Grid.Column>
                                                                        <Grid.Column
                                                                            computer='11'
                                                                            tablet='16'
                                                                            mobile='16'
                                                                            textAlign='left'>
                                                                            <h4>{data.name}</h4>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column
                                                                            computer='16'
                                                                            tablet='16'
                                                                            mobile='16'
                                                                            textAlign='center'>
                                                                            <Button
                                                                                style={{
                                                                                    backgroundColor: '#293757',
                                                                                    color: 'white',
                                                                                }}
                                                                                onClick={() => {
                                                                                    handleClickDownload(
                                                                                        data.path_file_name,
                                                                                        data.path_file
                                                                                    )
                                                                                }}>
                                                                                DownLoad PDF
                                                                            </Button>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Segment>
                                                        </Grid.Column>
                                                    ))
                                                ) : (
                                                    <Grid.Column>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                color: 'rgba(30, 31, 38, 0.5)',
                                                            }}>
                                                            <h5>ไม่มีเนื้อหา</h5>
                                                        </div>
                                                    </Grid.Column>
                                                )}
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            ) : (
                                <label></label>
                            )}
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default UserManualDetail
