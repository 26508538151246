import { RootState } from '..'

export function getComplaintFormStep1(state: RootState) {
    return state.complaintFormStep1
}
export function getComplaintFormStep2(state: RootState) {
    return state.complaintFormStep2
}
export function getComplaintFormStep3(state: RootState) {
    return state.complaintFormStep3
}

export function getManagementUsersFormUser(state: RootState) {
    return state.managementUsersFormUser
}
export function getManagementUsersFormPersonal(state: RootState) {
    return state.managementUsersFormPersonal
}
export function getManagementUsersFormAdditional(state: RootState) {
    return state.managementUsersFormAdditional
}

export function getAuthenMenuList(state: RootState) {
    return state.authenMenuList
}

export function getPropsAlert(state: RootState) {
    return state.alert
}

export function getComplaintFilterList(state: RootState) {
    return state.complaintFilterList
}

export function getToast(state: RootState) {
    return state.toast
}

export function getNotification(state: RootState) {
    return state.notification
}
