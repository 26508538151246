import { ReducerName } from '../types'

export const setStateByNameAndValue = (reducer_name: ReducerName, name: string, value: any) => {
    return {
        type: `setStateByNameAndValue${reducer_name}`,
        name: name,
        value: value,
    }
}

export const resetFormByReducerName = (reducer_name: ReducerName) => {
    return {
        type: `resetFormByReducerName${reducer_name}`,
    }
}

export const appendForm = (reducer_name: ReducerName, value: any) => {
    return {
        type: `appendForm${reducer_name}`,
        value: value,
    }
}

export const setAlert = (value: any) => {
    return {
        type: `alert`,
        value: { ...value, alert: true },
    }
}

export const alertClose = () => {
    return {
        type: `alertClosed`,
    }
}

export const setNotification = (value: any) => {
    return {
        type: `notification`,
        value: value,
    }
}

export const setToast = (value: any) => {
    return {
        type: `toast`,
        value: value,
    }
}
export const removeToast = () => {
    return {
        type: `removeToast`,
    }
}
