import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Grid, Input, InputOnChangeData } from 'semantic-ui-react'
import { InterfaceRoles } from '.'
import CustomModal from '../../../../components/CustomModal'
import { POST_ROLES_CREATE, POST_ROLES_EDIT } from '../../../../config/api/roles'
import { axiosAuthen } from '../../../../config/axiosAuthen'

type FormProps = {
    data?: InterfaceRoles
    open?: boolean
    onClose?: () => void
    municipality?: string | number | boolean | (string | number | boolean)[] | undefined
}

const ManagementRolesModalForm: React.FC<FormProps> = (props) => {
    let { data } = props
    let id: number | undefined = data?.id
    let [name_th, setNameTh] = useState<string | undefined>()
    let [name_en, setNameEn] = useState<string | undefined>()

    useEffect(() => {
        setNameTh(data?.name_th)
        setNameEn(data?.name_en)
    }, [data])

    const handleChangeNameTh = (e: any, { value }: InputOnChangeData) => {
        setNameTh(value)
    }

    const handleChangeNameEn = (e: any, { value }: InputOnChangeData) => {
        setNameEn(value)
    }

    const handleSubmit = () => {
        let body = {
            name_th: name_th,
            name_en: name_en,
            municipalities_id: props?.municipality,
        }

        if (id) {
            body = { ...body, ...{ id: id } }
            axiosAuthen.post(POST_ROLES_EDIT, body).then(() => {
                handleClose()
            })
        } else {
            axiosAuthen.post(POST_ROLES_CREATE, body).then(() => {
                handleClose()
            })
        }
        setNameTh('')
        setNameEn('')
    }

    const handleClose = () => {
        props.onClose && props.onClose()
    }

    return (
        <Fragment>
            <CustomModal icon='users' header='เพิ่มสิทธิ์' open={props.open}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>ชื่อสิทธิ์ (ภาษาไทย)</label>
                                <Form.Field>
                                    <Input fluid name='name_th' value={name_th} onChange={handleChangeNameTh} />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' only='computer' />
                            <Grid.Column computer='10' mobile='16'>
                                <label className='header-input-form'>ชื่อสิทธิ์ (ภาษาอังกฤษ)</label>
                                <Form.Field>
                                    <Input fluid name='name_en' value={name_en} onChange={handleChangeNameEn} />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer='3' only='computer' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    type='button'
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    type='button'
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default ManagementRolesModalForm
