import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Container,
    Dropdown,
    Form,
    Grid,
    Icon,
    Input,
    InputOnChangeData,
    Label,
    Pagination,
    Radio,
    Table,
} from 'semantic-ui-react'
import {
    DELETE_USER_MANUAL_DELETE_TOPIC,
    GET_USER_MANUAL_TOPIC_LIST,
    PUT_USER_MANUAL_ACTIVE_TOPIC,
} from '../../../../../config/api/userManual'
import { axiosAuthen } from '../../../../../config/axiosAuthen'
import UserManualTopicModalForm from './ModalForm'

export interface InterfaceUserManualTopic {
    active: boolean
    id: number
    name: string
}
const UserManualTopic: React.FC = () => {
    let [open_modal, setOpenModal] = useState<boolean>(false)
    let [keyword, setKeyword] = useState<string>('')
    let [record_qty, setRecordQty] = useState<number>(0)
    let [row_qty, setRowQty] = useState<number>(5)
    let [current_page, setCurrentPage] = useState<number>(1)
    let [page_qty, setPageQty] = useState<number>(1)
    let [topic, setTopic] = useState<InterfaceUserManualTopic[]>([])
    let [data_topic, setDataTopic] = useState<InterfaceUserManualTopic | undefined>()
    let size_row = [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
    ]
    const handleChangeKeyword = (e: any, { value }: InputOnChangeData) => {
        setKeyword(value)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        getTopicList()
    }

    const handleAddTopic = () => {
        setDataTopic(undefined)
        setOpenModal(true)
    }

    const getTopicList = useCallback(() => {
        axiosAuthen
            .get(`${GET_USER_MANUAL_TOPIC_LIST}?page=${current_page}&offset=${row_qty}&keyword=${keyword}`)
            .then((response) => {
                let { value } = response.data
                setRecordQty(value.count)
                setTopic(value.user_manual_topics_list)
            })
    }, [current_page, row_qty, keyword])

    useEffect(() => {
        getTopicList()
    }, [getTopicList])

    useEffect(() => {
        const calculatePageQty = () => {
            let lengthData = 1
            if (record_qty > 0) {
                lengthData = record_qty
            }
            let page_qty = lengthData / row_qty
            setPageQty(Math.ceil(page_qty))
        }
        calculatePageQty()
    }, [record_qty, row_qty])

    const handleChangeRowSize = (e: any, { value }: any) => {
        setRowQty(value)
        setCurrentPage(1)
        let page_qty = record_qty / value
        setPageQty(Math.ceil(page_qty))
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const handleChangeActiveTopic = (id: number, active: boolean) => {
        axiosAuthen
            .put(PUT_USER_MANUAL_ACTIVE_TOPIC, {
                id: id,
                active: !active,
            })
            .then(() => {
                getTopicList()
            })
    }

    const handleEditTopic = (data: InterfaceUserManualTopic) => {
        setDataTopic(data)
        setOpenModal(true)
    }

    const handleDeleteTopic = (id: number) => {
        axiosAuthen.delete(`${DELETE_USER_MANUAL_DELETE_TOPIC}/${id}`).then(() => {
            getTopicList()
        })
    }

    return (
        <Fragment>
            <div className='background-container'>
                <Container>
                    {open_modal && (
                        <UserManualTopicModalForm open={open_modal} onClose={handleCloseModal} data={data_topic} />
                    )}
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Breadcrumb>
                                    <Breadcrumb.Section active>รายการหัวข้อคู่มือการใช้งาน</Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <h2>รายการหัวข้อคู่มือการใช้งาน</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='3' tablet='5' mobile='8' style={{ marginTop: 10 }}>
                                <Button
                                    fluid
                                    icon
                                    style={{ backgroundColor: '#4D648D', color: 'white' }}
                                    labelPosition='left'
                                    onClick={handleAddTopic}
                                >
                                    <Icon name='plus' /> เพิ่มหัวข้อ
                                </Button>
                            </Grid.Column>
                            <Grid.Column computer='10' tablet='6' />

                            <Grid.Column computer='3' mobile='16' style={{ marginTop: 10 }}>
                                <Input
                                    fluid
                                    icon='search'
                                    placeholder='ค้นหา'
                                    onChange={handleChangeKeyword}
                                    value={keyword}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{ zIndex: 1 }}>
                                <Form>
                                    <Table attached='top' celled className='table-header'>
                                        <Table.Header className='table-header header'>
                                            <Table.Row>
                                                <Table.HeaderCell textAlign='center' width='1'>
                                                    ลำดับ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='10'>
                                                    หัวข้อ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center' width='3'>
                                                    สถานะ
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width='2' textAlign='center'>
                                                    จัดการ
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                    <Table attached celled className='table-body'>
                                        <Table.Body className='table-body body'>
                                            {topic.map((data: InterfaceUserManualTopic, index: number) => (
                                                <Table.Row key={data.id}>
                                                    <Table.Cell textAlign='center' width='1'>
                                                        <Label
                                                            circular
                                                            style={{
                                                                backgroundColor: '#425679',
                                                                color: '#FFFFFF',
                                                            }}
                                                        >
                                                            {row_qty * (current_page - 1) + index + 1}
                                                        </Label>
                                                    </Table.Cell>
                                                    <Table.Cell width='10'>{data.name}</Table.Cell>
                                                    <Table.Cell width='3' textAlign='center'>
                                                        <p>สถานะ : {data.active ? 'เปิด' : 'ปิด'}</p>
                                                        <Radio
                                                            toggle
                                                            checked={data.active}
                                                            onChange={() =>
                                                                handleChangeActiveTopic(data.id, data.active)
                                                            }
                                                        />
                                                    </Table.Cell>

                                                    <Table.Cell width='2' textAlign='center'>
                                                        <Dropdown icon='ellipsis vertical' pointing='right'>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleEditTopic(data)}>
                                                                    <Icon
                                                                        name='edit'
                                                                        circular
                                                                        style={{
                                                                            color: '#F2CC6B',
                                                                            border: '1px solid #F2CC6B',
                                                                            boxShadow: 'none',
                                                                        }}
                                                                    />
                                                                    <label style={{ cursor: 'pointer' }}>แก้ไข</label>
                                                                </Dropdown.Item>
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item
                                                                    onClick={() => handleDeleteTopic(data.id)}
                                                                >
                                                                    <Icon
                                                                        name='trash alternate outline'
                                                                        circular
                                                                        style={{
                                                                            color: '#9F3A38',
                                                                            border: '1px solid #9F3A38',
                                                                            boxShadow: 'none',
                                                                        }}
                                                                    />
                                                                    <label style={{ cursor: 'pointer' }}>ลบ</label>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                computer='5'
                                mobile='16'
                                tablet='5'
                                style={{ alignSelf: 'center', marginTop: 10, display: 'flex' }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label>
                                        แสดงข้อมูลลำดับที่ {record_qty === 0 ? 0 : row_qty * (current_page - 1) + 1} ถึง{' '}
                                        {row_qty * (current_page - 1) + row_qty < record_qty
                                            ? row_qty * (current_page - 1) + row_qty
                                            : record_qty}{' '}
                                        จากทั้งหมด {record_qty} ข้อมูล
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                computer='11'
                                mobile='16'
                                tablet='11'
                                style={{
                                    display: 'flex',
                                    marginTop: 10,
                                    justifyContent: 'flex-end',
                                }}
                                floated='right'
                            >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            computer='5'
                                            tablet='16'
                                            mobile='16'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <label style={{ alignSelf: 'center' }}>แสดง</label>
                                            <Dropdown
                                                placeholder='จำนวน'
                                                selection
                                                compact
                                                fluid
                                                name='row_qty'
                                                value={row_qty}
                                                options={size_row}
                                                onChange={handleChangeRowSize}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 80,
                                                    paddingRight: 40,
                                                }}
                                            />
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                {' แถว '}
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column
                                            computer='11'
                                            tablet='16'
                                            mobile='16'
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <label
                                                style={{
                                                    alignSelf: 'center',
                                                    minWidth: 'fit-content',
                                                }}
                                            >
                                                หน้า
                                            </label>
                                            <Pagination
                                                boundaryRange={1}
                                                activePage={current_page}
                                                siblingRange={1}
                                                name='current_page'
                                                size='small'
                                                totalPages={page_qty}
                                                firstItem={null}
                                                lastItem={null}
                                                onPageChange={(e: any, { activePage }: any) =>
                                                    handleChangePage(Math.ceil(Number(activePage)))
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}

export default UserManualTopic
