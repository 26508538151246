import React from 'react'
import { Form, Grid, Segment, Label, Icon, Input, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'

const CustomInputFile = ({
    error = false,
    loading = false,
    size = null,
    // TODO: NEW VALUE
    labelText = '',
    name = '',
    required = false,
    typeFile = '',
    onChange = (e, props) => {},
    value = [],
    disabled = false,
    refName = null,
    multiple = false,
    color = '',
}) => {
    return (
        <Grid.Row>
            <Grid.Column computer='3' only='computer' />
            <Grid.Column computer='10' tablet='16' mobile='16'>
                <Form.Field error={error} loading={loading.toString()} disabled={disabled}>
                    <Segment style={{ border: 0 }}>
                        <Label
                            attached='top'
                            // color={error ? 'red' : 'blue'}
                            as='label'
                            style={{
                                // height: 40,
                                backgroundColor: error ? '#DB2828' : color ? color : '#283655',
                                color: 'white',
                            }}
                            // htmlFor={refName ? refName : name}
                            size={size}
                        >
                            <Icon name='cloud upload' />
                            <span style={{ fontSize: '14px', fontWeight: 100 }}>เลือกไฟล์ {labelText}</span>
                            <Button icon style={{ margin: 0, padding: 4, backgroundColor: '#ffffff' }} floated='right'>
                                <Icon.Group
                                    onClick={() => {
                                        document.getElementById(refName ? refName : name).click()
                                    }}
                                >
                                    <Icon name='file alternate' />
                                    <Icon corner name='plus' />
                                </Icon.Group>
                            </Button>
                        </Label>

                        <div>
                            <Input
                                name={refName ? refName : name}
                                onChange={(event) => handleUploadFileChange(event, { name, onChange, multiple, value })}
                                // input
                                style={{ display: 'none' }}
                            >
                                <input
                                    type='file'
                                    id={refName ? refName : name}
                                    style={{ display: 'none' }}
                                    accept={typeFile}
                                    multiple={multiple}
                                />
                            </Input>
                            {multiple
                                ? value &&
                                  value.map((file, index) => (
                                      <div style={{ marginTop: 10 }} key={index}>
                                          <label>{file.name} </label>
                                          <Icon
                                              color='red'
                                              size={size}
                                              name='times circle outline'
                                              link
                                              onClick={(event) =>
                                                  handleUploadFileResetByIndex(event, {
                                                      name,
                                                      onChange,
                                                      refName,
                                                      required,
                                                      value,
                                                      index,
                                                  })
                                              }
                                          />
                                      </div>
                                  ))
                                : value && (
                                      <React.Fragment>
                                          {value.name}{' '}
                                          <Icon
                                              color='red'
                                              size={size}
                                              name='times circle outline'
                                              link
                                              onClick={(event) =>
                                                  handleUploadFileReset(event, { name, onChange, refName, required })
                                              }
                                          />
                                      </React.Fragment>
                                  )}
                        </div>
                    </Segment>
                    {error && <span style={{ color: '#db2828', fontSize: '14px' }}>โปรดเลือกไฟล์ </span>}
                </Form.Field>
            </Grid.Column>
        </Grid.Row>
    )
}

const handleUploadFileChange = (event, { name, onChange, multiple, value }) => {
    if (multiple) {
        let listFile = value ? value : []
        for (let i = 0; i < event.target.files.length; i++) {
            listFile.push(event.target.files[i])
        }
        value = listFile
    } else {
        value = event.target.files[0]
    }

    let error = false
    if (value && onChange) onChange(event, { name, value, error })
}

const handleUploadFileReset = (event, { name, onChange, refName, required }) => {
    let value = ''
    let error = required ? true : false
    let id = refName ? refName : name
    document.getElementById(`${id}`).value = null
    onChange(event, { name, value, error })
}

const handleUploadFileResetByIndex = (event, { name, onChange, refName, required, value, index }) => {
    value.splice(index, 1)
    let error = required ? true : false
    let id = refName ? refName : name
    document.getElementById(`${id}`).value = null
    onChange(event, { name, value, error })
}

export default connect()(CustomInputFile)
