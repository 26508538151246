import React from 'react'
import { Icon } from 'semantic-ui-react'

interface CustomCircleCheckboxProps {
    label?: string
    checked?: boolean
}
const CustomCircleCheckbox: React.FC<CustomCircleCheckboxProps> = (props) => {
    return (
        <div style={{ display: 'flex', color: props.checked ? '#000000' : '#BEC3CC' }}>
            <div>
                {props.checked ? <Icon name='check circle outline' color='green' /> : <Icon name='circle outline' />}
            </div>
            <div style={{ marginLeft: 5 }}>{props.label}</div>
        </div>
    )
}
export default CustomCircleCheckbox
