import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Grid, Checkbox, Dropdown, DropdownProps, Segment, Icon } from 'semantic-ui-react'

import CustomModal from '../../../../../components/CustomModal'
import { LIST_COMPALINT_TOPIC_AND_TYPES } from '../../../../../config/api/complaintTopicAndTypes'
import { CREATE_MATCH_USERS_AND_TOPIC_BY_USER } from '../../../../../config/api/matchUsersAndTopic'
import { axiosAuthen } from '../../../../../config/axiosAuthen'

type FormProps = {
    // data: InterfaceComplaint;
    open?: boolean
    onClose?: () => void
}

const AddMemberandComplaint: React.FC<FormProps> = (props) => {
    let id = localStorage.user_id

    let [row_qty] = useState<number>(5)
    let [current_page] = useState<number>(1)

    let [keyword] = useState<string>('')
    let [, setLoading] = useState<boolean>(false)
    let [, setErrors] = useState<boolean>(false)
    let [complaints_option, setComplaintsOption] = useState<[]>()
    let [complaint, setComplaint] = useState<string | number | boolean | (string | number | boolean)[] | undefined>('')
    let [index_select, setIndexSelect] = useState<any[]>([])
    let [index_unselect, setIndexUnSelect] = useState<any[]>([])
    let [list_complaints_types, setListComplaintsTypes] = useState<any[]>([])
    let [list_responsible_work, setListResponsibleWork] = useState<any[]>([])
    let [complaints_types_display] = useState<any[]>([])

    const handleCloseModalAdd = () => {
        props.onClose && props.onClose()
        setErrors(false)
    }

    useEffect(() => {
        const getListComplaint = () => {
            setLoading(true)
            axiosAuthen
                .get(`${LIST_COMPALINT_TOPIC_AND_TYPES}?keyword=${keyword}&page=${current_page}&offset=${row_qty}`)
                .then((response) => {
                    let { value } = response.data
                    let compalint_option = value.complaint_topics.map((data: any) => ({
                        value: data.id,
                        text: data.name,
                    }))
                    setComplaintsOption(compalint_option)
                    setLoading(false)
                })
        }
        getListComplaint()
    }, [keyword, current_page, row_qty])

    const handleChangeCompalint = (_: any, { value }: DropdownProps) => {
        axiosAuthen.get(`${LIST_COMPALINT_TOPIC_AND_TYPES}/?topic_id=${value}`).then((response) => {
            let { value } = response.data
            let list_complaint_type_selected_id = complaints_types_display.map((data: any) => data.id)
            let { complaint_types } = value
            complaint_types = complaint_types.filter((data: any) => !list_complaint_type_selected_id.includes(data.id))
            setListComplaintsTypes(complaint_types)
        })

        setComplaint(value)
    }

    const handleSelect = (user_id: number) => {
        if (index_select.some((select) => select === user_id)) {
            let select = index_select.filter((select) => select !== user_id)
            setIndexSelect(select)
        } else {
            setIndexSelect([...index_select, user_id])
        }
    }

    const handleUnSelect = (user_id: number) => {
        if (index_unselect.some((select) => select === user_id)) {
            let select = index_unselect.filter((select) => select !== user_id)
            setIndexUnSelect(select)
        } else {
            setIndexUnSelect([...index_unselect, user_id])
        }
    }

    const addComplaints = () => {
        let complaint = list_complaints_types.filter((user: any) => index_select.includes(user.id))
        list_complaints_types = list_complaints_types.filter((user: any) => !index_select.includes(user.id))
        setListResponsibleWork([...list_responsible_work, ...complaint])
        setListComplaintsTypes(list_complaints_types)
        setIndexSelect([])
    }

    const removeComplaints = () => {
        let complaint = list_responsible_work.filter((user: any) => index_unselect.includes(user.id))

        list_responsible_work = list_responsible_work.filter((user: any) => !index_unselect.includes(user.id))
        setListComplaintsTypes([...list_complaints_types, ...complaint])
        setListResponsibleWork(list_responsible_work)
        setIndexUnSelect([])
    }

    const handleSubmit = () => {
        let body = {
            match_topics_and_types: list_responsible_work.map((data) => ({
                complaint_types_id: data.complaint_topics_id,
                id: 0,
            })),
            complaint_topics_id: complaint,
            users_id: id,
            id: 0,
        }

        axiosAuthen.post(CREATE_MATCH_USERS_AND_TOPIC_BY_USER, body).then((response) => {
            handleCloseModalAdd()
        })
    }

    return (
        <Fragment>
            <CustomModal icon='file alternate' header='มอบงานที่รับผิดชอบ' open={props.open}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer='2' />
                            <Grid.Column computer='6'>
                                <Form.Field inline>
                                    <label style={{ color: '#283655', fontWeight: 'bold' }}>เรื่องร้องเรียน</label>
                                    <Dropdown
                                        options={complaints_option}
                                        onChange={handleChangeCompalint}
                                        search
                                        selection
                                        value={complaint}
                                    />
                                </Form.Field>

                                {/* <span style={{ color: '#283655', fontWeight: 'bold' }}>เรื่องร้องเรียน</span> */}
                            </Grid.Column>
                            <Grid.Column computer='1' />
                            <Grid.Column computer='5' style={{ paddingTop: '10px' }}>
                                <span style={{ color: '#283655', fontWeight: 'bold' }}>งานที่รับผิดชอบ</span>
                            </Grid.Column>
                            <Grid.Column computer='2' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer='2' />
                            <Grid.Column computer='5'>
                                {/* <Segment key={index} style={slideShowStyle}> */}

                                <Segment>
                                    {list_complaints_types.map(
                                        (data: any, index: number) =>
                                            data && (
                                                <Segment
                                                    // style={{ border: ' 3px solid #293757', cursor: 'pointer' }}
                                                    key={data.id}
                                                    onClick={() => handleSelect(data.id)}>
                                                    <Checkbox
                                                        label={data.name}
                                                        checked={index_select.some((select) => select === data.id)}
                                                    />
                                                </Segment>
                                            )
                                    )}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column
                                computer='2'
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid>
                                    <Grid.Row
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Grid.Column computer='8'>
                                            <Button
                                                primary={index_select.length > 0}
                                                disabled={index_select.length === 0}
                                                icon
                                                fluid
                                                size='mini'
                                                onClick={addComplaints}>
                                                <Icon name='angle double right' />
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Grid.Column computer='8'>
                                            <Button
                                                size='mini'
                                                primary={index_unselect.length > 0}
                                                disabled={index_unselect.length === 0}
                                                icon
                                                fluid
                                                onClick={removeComplaints}>
                                                <Icon name='angle double left' />
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>

                            <Grid.Column computer='5'>
                                <Segment>
                                    {list_responsible_work.map((data: any, index: number) => (
                                        <Segment
                                            key={data.id}
                                            index={index}
                                            // action={switchPosition}
                                            onClick={() => handleUnSelect(data.id)}>
                                            <Checkbox
                                                label={data.name}
                                                checked={index_unselect.some((select) => select === data.id)}
                                            />
                                        </Segment>
                                    ))}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer='2' />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#283655',
                                        color: '#FFF',
                                        marginRight: 15,
                                    }}
                                    onClick={handleSubmit}>
                                    บันทึก
                                </Button>
                                <Button
                                    style={{
                                        width: 100,
                                        backgroundColor: '#C54C40',
                                        color: '#FFF',
                                        marginLeft: 15,
                                    }}
                                    onClick={handleCloseModalAdd}>
                                    ยกเลิก
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </CustomModal>
        </Fragment>
    )
}

export default AddMemberandComplaint
