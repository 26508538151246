import axios from 'axios'
import { REFRESH_TOKEN } from './api/common'

const api = axios.create()
axios.interceptors.request.use((config) => {
    const jwtToken = localStorage.getItem('token')
    if (jwtToken != null) {
        config.headers = { Authorization: `Bearer ${jwtToken}` }
    }

    return config
})

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const original_request = error.config

        // Check if the error response status code is 401 (unauthorized) and if it's the original request
        if (error?.response?.status === 401 && !original_request._retry) {
            original_request._retry = true

            // Perform the refresh token request and update the access token
            return api
                .post(REFRESH_TOKEN, { refresh_token: localStorage.getItem('refresh_token') })
                .then((response) => {
                    const { value } = response.data
                    localStorage.setItem('token', value.access_token)
                    localStorage.setItem('refresh_token', value.refresh_token)
                    // Update the access token in the original request headers
                    original_request.headers['Authorization'] = `Bearer ${value.access_token}`

                    // Retry the original request with the updated access token
                    return api(original_request)
                })
                .catch((error) => {
                    // Handle the refresh token request error
                    console.log('Refresh token request failed', error)
                    window.location.href = '/'

                    // You can redirect the user to the login page or do other error handling here
                    throw error
                })
        }

        // For other types of errors, simply throw the error
        throw error
    }
)

export const axiosAuthen = axios
