import React from 'react'
import { useSelector } from 'react-redux'
import { Message, Transition } from 'semantic-ui-react'
import { getToast } from '../../store/selectors'
import { MessageFirebase } from '../../store/types/Notification'

const ToastMessage: React.FC = () => {
    let toast = useSelector(getToast)
    return (
        <div style={{ position: 'absolute', margin: 10, zIndex: 9999, width: 300, bottom: 0 }}>
            {toast.map((message: MessageFirebase, index: number) => (
                <Transition animation='fade up' duration='500' key={index}>
                    <Message compact style={{ padding: 20, margin: '5px 0px' }}>
                        <Message.Content>
                            <Message.Header>{message.action}</Message.Header>
                            {message.department_complaint_no && (
                                <div>เลขที่คำร้อง: {message.department_complaint_no}</div>
                            )}
                            {message.user_name && <div>เจ้าหน้าที่ : {message.user_name}</div>}
                            {message.addition_message && <div>{message.addition_message}</div>}
                        </Message.Content>
                    </Message>
                </Transition>
            ))}
        </div>
    )
}

export default ToastMessage
