import React, { useEffect, useState } from 'react'
import { Container, Dimmer, Divider, Loader, Segment } from 'semantic-ui-react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { axiosAuthen } from '../../../config/axiosAuthen'
import { GET_DASHBOARD_GRAPH_SUMMARY } from '../../../config/api/dashboard'
import drilldown from 'highcharts/modules/drilldown.js'

drilldown(Highcharts)

type DashboardProps = {
    year?: any
    start_date?: string
    end_date?: string
}

const DashboardComplaintSummary: React.FC<DashboardProps> = (props) => {
    let { year, start_date, end_date } = props
    let [loading, setLoading] = useState<boolean>(true)
    let [chart_options, setChartOptions] = useState<any>(undefined)

    useEffect(() => {
        setLoading(true)
        axiosAuthen
            .get(
                `${GET_DASHBOARD_GRAPH_SUMMARY}?year=${
                    year === 'all' ? '' : year
                }&start_date=${start_date}&end_date=${end_date}`
            )
            .then((response) => {
                let { value } = response.data
                let data_success: any[] = []
                let data_unsuccess: any[] = []
                let drilldown_success: any[] = []
                let drilldown_unsuccess: any[] = []
                value.forEach((data: any) => {
                    let success: any[] = []
                    let unsuccess: any[] = []
                    data.topics.forEach((topic: any) => {
                        success.push({
                            name: topic.name,
                            y: topic.success,
                            percent: topic.percent_success,
                        })

                        unsuccess.push({
                            name: topic.name,
                            y: topic.unsuccess,
                            percent: topic.percent_unsuccess,
                        })
                    })
                    data_success.push({
                        name: data.name,
                        y: data.success,
                        color: '#283655',
                        percent: data.percent_success,
                        drilldown: `${data.name}_success`,
                    })

                    data_unsuccess.push({
                        name: data.name,
                        y: data.unsuccess,
                        color: '#B6392D',
                        percent: data.percent_unsuccess,
                        drilldown: `${data.name}_unsuccess`,
                    })
                    drilldown_success.push({
                        id: `${data.name}_success`,
                        data: success,
                    })
                    drilldown_unsuccess.push({
                        id: `${data.name}_unsuccess`,
                        data: unsuccess,
                    })
                })
                let drilldown_series = drilldown_success.concat(drilldown_unsuccess)
                let chart_options = {
                    chart: {
                        type: 'column',
                        marginTop: 50,
                    },
                    accessibility: {
                        announceNewData: {
                            enabled: true,
                        },
                    },
                    lang: {
                        drillUpText: '<b>◁ ย้อนกลับ<b>',
                    },
                    title: {
                        text: null,
                    },

                    xAxis: {
                        type: 'category',
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'จำนวนคำร้องทุกข์',
                        },
                        labels: {
                            overflow: 'justify',
                        },
                    },
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true,
                                format: '{y} คำร้อง <b>( {point.percent:.2f}% )</b>',
                                allowOverlap: true,
                            },
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    credits: {
                        enabled: false,
                    },
                    legend: {
                        enabled: false,
                    },
                    series: [
                        {
                            name: 'รายการคำร้อง',
                            data: data_success,
                        },
                        {
                            name: 'รายการคำร้อง',
                            data: data_unsuccess,
                        },
                    ],
                    drilldown: {
                        drillUpButton: {
                            position: {
                                verticalAlign: 'top',
                                y: -50,
                                x: -20,
                            },
                        },
                        activeAxisLabelStyle: {
                            textDecoration: 'none',
                            color: 'black',
                        },
                        activeDataLabelStyle: {
                            textDecoration: 'none',
                            color: 'black',
                        },
                        series: drilldown_series,
                    },
                }

                setChartOptions(chart_options)
                setLoading(false)
            })
            .catch(() => {
                let data_success: any[] = []
                let data_unsuccess: any[] = []
                let drilldown_success: any[] = []
                let drilldown_unsuccess: any[] = []
                let drilldown_series = drilldown_success.concat(drilldown_unsuccess)
                let chart_options = {
                    chart: {
                        type: 'column',
                        marginTop: 50,
                    },
                    accessibility: {
                        announceNewData: {
                            enabled: true,
                        },
                    },
                    lang: {
                        drillUpText: '<b>◁ ย้อนกลับ<b>',
                    },
                    title: {
                        text: null,
                    },

                    xAxis: {
                        type: 'category',
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'จำนวนคำร้องทุกข์',
                        },
                        labels: {
                            overflow: 'justify',
                        },
                    },
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true,
                                format: '{y} คำร้อง <b>( {point.percent:.2f}% )</b>',
                                allowOverlap: true,
                            },
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    credits: {
                        enabled: false,
                    },
                    legend: {
                        enabled: false,
                    },
                    series: [
                        {
                            name: 'รายการคำร้อง',
                            data: data_success,
                        },
                        {
                            name: 'รายการคำร้อง',
                            data: data_unsuccess,
                        },
                    ],
                    drilldown: {
                        drillUpButton: {
                            position: {
                                verticalAlign: 'top',
                                y: -50,
                                x: -20,
                            },
                        },
                        activeAxisLabelStyle: {
                            textDecoration: 'none',
                            color: 'black',
                        },
                        activeDataLabelStyle: {
                            textDecoration: 'none',
                            color: 'black',
                        },
                        series: drilldown_series,
                    },
                }

                setChartOptions(chart_options)
                setLoading(false)
            })
    }, [end_date, start_date, year])

    if (loading) {
        return (
            <Segment style={{ height: 430 }}>
                <div>
                    <Container style={{ height: window.innerHeight }}>
                        <Dimmer active inverted>
                            <Loader size='huge' />
                        </Dimmer>
                    </Container>
                </div>
            </Segment>
        )
    } else {
        return (
            <Segment>
                {/* {chart_options.length > 0 && (
          <div> */}
                <div
                    style={{
                        padding: 15,
                    }}
                >
                    <h3>สรุปเรื่องร้องทุกข์ *คลิกที่แท่งกราฟเพื่อแสดงสรุปงานย่อย</h3>
                    <Divider />
                </div>
                <HighchartsReact highcharts={Highcharts} options={chart_options} />
                {/* </div>
        )} */}
            </Segment>
        )
    }
}
export default DashboardComplaintSummary
